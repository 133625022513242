import React, { useState, useEffect } from "react";
import "./../../../assets/css/navbar.css";
// reactstrap components
import {
    Container,
} from "reactstrap";

import { Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "../../../assets/css/style.css";
import { fetchEmployees } from "../../../services/api/Employee";
import { getUserByEmployeeId } from "../../../services/api/Users";
import { fetchDemands } from "../../../services/api/Demand";
import { getTeam } from "../../../services/api/Team";
import { fetchTerritorializations, getAllTypeTerritorializations } from "../../../services/api/territorialization";
import "./MainDashboard.css";
import TrapsOnMap from "../../TrapsOnMap";

const DashboardTrapsOnMap = () => {
    const [demandList, setDemandList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [territorializationList, setTerritorializationList] = useState([]);


    useEffect(() => {
        const reqEmployees = fetchEmployees();
        reqEmployees.then(async response => {
            const employeeList = new Array({
                value: "00000000-0000-0000-0000-000000000000",
                label: "Selecione"
            });

            for (let i = 0; i < response.data.length; i++) {
                const user = await getUserByEmployeeId(response.data[i].id);
                if (user.data.userId !== "00000000-0000-0000-0000-000000000000") {
                    employeeList.push({
                        value: user.data.userId,
                        label: response.data[i].name,
                    })
                }
            };
            setEmployeeList(employeeList);
        });

        const reqDemands = fetchDemands();
        reqDemands.then(response => {
            const demandList = [{ value: "00000000-0000-0000-0000-000000000000", label: "Selecione" }].concat(response.data.map(demand => { return { value: demand.id, label: demand.serviceOrder } }));
            setDemandList(demandList);
        });

        const reqTeams = getTeam();
        reqTeams.then(async response => {
            let teamList = [
                {
                    value: "00000000-0000-0000-0000-000000000000",
                    label: "Selecione"
                }
            ]

            for (const team of response.data) {
                for (const teamMember of team.members) {
                    const user = await getUserByEmployeeId(teamMember.employeeId);
                    if (user.data.userId !== "00000000-0000-0000-0000-000000000000") {
                        teamMember.userId = user.data.userId
                    }
                }
                teamList = teamList.concat({ value: team.id, label: team.name, members: team.members });
            }

            setTeamList(teamList);
        });

        const reqTypeTerritorializations = getAllTypeTerritorializations();
        reqTypeTerritorializations.then(async typeResponse => {
            const listTerritorialization = new Array();
            for (const type of typeResponse.data) {
                try {
                    if (type.typeGeometry === "Polygon") {
                        const territorializationRequest = await fetchTerritorializations(type.id);
                        listTerritorialization.push({
                            name: type.name,
                            label: type.name,
                            options: territorializationRequest.data.map(territorialization => { return { label: territorialization.name, value: territorialization.id } }),
                            territorializationList: territorializationRequest.data
                        })
                    }
                } catch (error) {
                }
            }
            setTerritorializationList(listTerritorialization)
        });

    }, []);

    return (
        <Tabs >
            <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8">
                <TabList className="tab-container">
                </TabList>
            </Container>
            <div className="mt--7">
                <TabPanel >
                    <TrapsOnMap
                        demandList={demandList}
                        teamList={teamList}
                        employeeList={employeeList}
                        territorializationList={territorializationList}
                    />
                </TabPanel>
            </div>
        </Tabs>
    );
}

export default DashboardTrapsOnMap;
