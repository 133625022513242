import { getUserData } from '../utils/auth';
import request from '../utils/request';

export function upload(file) {
  
  file.append("organizationId", getUserData("organizationId"));

  return request({
    url: '/Inmet',
    method: 'post',
    headers: new Headers({
      'Content-Type': 'multipart/form-data'
    }),
    data: file
  });
}
export function getRainNotifications(filter) {
  filter.organizationId = getUserData("organizationId");
  return request({
    url: '/Inmet/RainNotifications',
    method: 'post',
    data: filter
  });
}
