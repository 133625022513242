import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container, Row} from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";
import Login from "../views/pages/Login/Login";
import routes from "routes.js";

class Auth extends React.Component {
  componentDidMount() {
    var arbotech = false;
    if(window.location.href.includes("arbotech")){
      arbotech = true;
    } else {
      arbotech = false;
    }

    arbotech ? document.body.classList.add("bg-default-arbotech") : document.body.classList.add("bg-default");
    
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return (
          <Route path="/auth/login" component={Login} />
        );
      }
    });
  };
  render() {

    var arbotech = false;
    if(window.location.href.includes("arbotech")){
      arbotech = true;
    } else {
      arbotech = false;
    }

    const bgClass = arbotech ? 'bg-gradient-info-arbotech' : 'bg-gradient-info';
    const bgClassFill = arbotech ? 'fill-default-arbotech' : 'fill-default';

    return (
      <>
        <div className="main-content">
          <AuthNavbar />
          <div className={`header ${bgClass} py-7 py-lg-8`}>
            
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className={bgClassFill}
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>{this.getRoutes(routes)}</Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
