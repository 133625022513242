import React from "react";
import DemandsList from "./DemandsList";
import { Link } from "react-router-dom";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
  } from "reactstrap";
import {timeStampFormated} from "../../../services/utils/format";
import ReactTooltip from 'react-tooltip';
import { MDBDataTableV5 } from 'mdbreact';

import { deleteDemand, changeDemandStatus } from "../../../services/api/Demand";
import { toast, ToastContainer } from "react-toastify";
import ShowDemand from "../../../views/pages/Demand/ShowDemand"
import { getUserData } from "../../../services/utils/auth";
import { getUser } from "../../../services/api/Users";
import CardBody from "reactstrap/lib/CardBody";
import { checkPermissionComponent } from "../../../services/utils/permission";
import DataTable from "react-data-table-component";
class DemandsTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            SelectedDemand:null,
            Demand: props.Demand,
            discardModal:"",
            modal:"",
            modal2:"",
            reConfirmDelete:false,
            selectedDemand:"",
            selectedDemandId:"",
            userEmployeeId:"",
        };

    }

    confirmDelete (DemandName, DemandId) {
        this.setState({
            selectedDemandId:DemandId,
            selectedDemand:DemandName
        });
        this.toggle(false);
    }
    toggle = (willDeleteDemand, comingFromReCheck = false) => {
        if(comingFromReCheck){
            this.setState({
                reConfirmDelete: !this.state.reConfirmDelete
            });
        }else{
            this.setState({
                modal: !this.state.modal
            });
        }

        if(willDeleteDemand){
            let deleted = deleteDemand(this.state.selectedDemandId);
            deleted.then(response => {
                if(response){
                    let newDemands = this.state.Demand.filter( demand => demand.id != this.state.selectedDemandId);
                    this.setState({
                        Demand: newDemands,
                        reConfirmDelete: false,
                    });
                    toast.success("Demanda removida com sucesso!");
                }
            });
        }
    }
    toggle2 = (Demand) => {
        this.setState({
            modal2: !this.state.modal2,
            SelectedDemand: Demand
        }); 
        
    }

    toggleDiscard = (willDiscardDemand, Demand = '') => {
        if(Demand !== ''){
            this.setState({
                discardModal: !this.state.discardModal,
                selectedDemandId:Demand.id,
                selectedDemand: Demand.serviceOrder,
            });
        }else{
            this.setState({
                discardModal: !this.state.discardModal
            });
        }

        if(willDiscardDemand){
            var requestValidate = changeDemandStatus(this.state.selectedDemandId, 2);
            requestValidate.then((response) => {
                return response.data
            }).then((data) => {
                if(data.id) {
                    toast.warn("Demanda descartada com sucesso!");
                }
            })
        }
    }

    validateDemand = (Demand) => {
       
        if(Demand.teams.length == 0 && Demand.demandProblemEmployees.length == 0){
            toast.error("Erro: A demanda não pode ser validada sem uma equipe ou executores definidos.");
        }else{
            var requestValidate = changeDemandStatus(Demand.id,1);
            requestValidate.then((response) => {
                return response.data
            }).then((data) => {
                if(data.id) {
                    toast.success("Demanda validada com sucesso!");
                }
            })
        }
        
    }

    FinishDemand = (Demand) => {
        var requestValidate = changeDemandStatus(Demand.id,3);
        requestValidate.then((response) => {
            return response.data
        }).then((data) => {
            if(data.id) {
                toast.success("Demanda finalizada com sucesso!");
            }
        })
    }

    async componentDidMount(){
        var userId = getUserData('userId');
        const requestUser = getUser(userId);
        await requestUser.then(response => {
            if(response){
                return response.data;
            }
        }).then(user => {
            this.setState({userEmployeeId: user.employeeId});
        })
    }

    getPriorityName (problemPriority){
        switch(problemPriority){
            case 1:
                return "Imediato";
            case 2:
                return "Até 24 horas";
            case 3:
                return "Até 48 horas";
            case 4:
                return "Até 72 horas";
            case 5:
                return "Acima 72 horas";
            default:
                return "Sem prioridade";
                
        }
    }

    buildButtons(demand){
        return (
            <>
                {checkPermissionComponent("da149579-6cd3-425c-bdfa-04e968d44a69", "update") && 
                    <div className="column" style={{marginTop:'10px', marginBottom:'10px'}}>
                        {demand.situation === 'Aguardando validação' && 
                            <div className="column">
                                <button
                                    data-tip data-for="validateDemandTooltip"
                                    style={{'width':'60px', 'margin' : '5px'}}
                                    className="btn btn-primary"
                                    data-clipboard-text="check-bold"
                                    id="tooltip204269497"
                                    onClick={()=>this.validateDemand(demand)}
                                    type="button">
                                    <i className="fa fa-check" />                                
                                </button>
                                <ReactTooltip effect="solid" type="success" id="validateDemandTooltip">
                                    <span>Validar demanda</span>
                                </ReactTooltip>
                                <button
                                    data-tip data-for="discardDemandTooltip"
                                    style={{'width':'60px', 'margin' : '5px'}}
                                    className="btn btn-warning"
                                    data-clipboard-text="check-bold"
                                    id="tooltip204269497"
                                    onClick={()=>this.toggleDiscard(false, demand)}
                                    type="button">
                                    <i className="fa fa-times" />
                                    
                                </button>
                                <ReactTooltip effect="solid" type="warning" id="discardDemandTooltip">
                                    <span>Descartar demanda</span>
                                </ReactTooltip> 
                                <Link to={{
                                        pathname: "/admin/EditDemand",
                                        data: demand // your data array of objects
                                    }}>
                                    <button
                                        data-for="editDemandTooltip"
                                        style={{'width':'60px', 'margin' : '5px'}}
                                        className="btn btn-primary"
                                        data-clipboard-text="ni ni-fat-edit"
                                        id="tooltip204269497"
                                        data-tip="Alterar demanda"
                                        type="button">
                                        <div>
                                            <i className="ni ni-ruler-pencil" />
                                        </div>
                                    </button>                                                    
                                </Link> 
                                <ReactTooltip effect="solid" id="editDemandTooltip">
                                    <span>Editar demanda</span>
                                </ReactTooltip>
                            </div>
                        }

                        {demand.situation === 'Aguardando Execução' &&
                            <div>
                                <button
                                    data-tip data-for="finishDemandTooltip"
                                    style={{'width':'60px', 'margin' : '5px'}}
                                    className="btn btn-warning"
                                    data-clipboard-text="check-bold"
                                    id="tooltip204269497"
                                    onClick={()=>this.FinishDemand(demand)}
                                    type="button">
                                    <i className="fa fa-stopwatch" />
                                    <ReactTooltip effect="solid" type="warning" id="finishDemandTooltip">
                                        <span>Finalizar demanda</span>
                                    </ReactTooltip>
                                </button> 
                            </div>
                        }
                        <div>
                            <button
                                data-tip data-for="showDemandTooltip"                                                    
                                style={{'width':'60px', 'margin' : '5px'}}
                                className="btn btn-primary"
                                data-clipboard-text="fa fa-search"
                                onClick={()=>this.toggle2(demand)}
                                id="tooltip204269497"
                                type="button">
                                    <i className="fa fa-search" />

                            </button>
                            <ReactTooltip effect="solid" id="showDemandTooltip">
                                <span>Visualizar demanda</span>
                            </ReactTooltip>
                        </div>
                        {demand.responsibleId === this.state.userEmployeeId && 
                            <div>
                                <button
                                    data-tip data-for="deleteDemandTooltip"
                                    style={{'width':'60px', 'margin' : '5px'}}
                                    className="btn btn-warning"
                                    data-clipboard-text="fa fa-trash"
                                    onClick={()=>this.confirmDelete(demand.serviceOrder,demand.id)}
                                    id="tooltip204269497"
                                    type="button">
                                        <i className="fa fa-trash" />

                                </button>
                                <ReactTooltip effect="solid" id="deleteDemandTooltip">
                                    <span>Excluir demanda</span>
                                </ReactTooltip>
                            </div>
                        }
                    </div>
                }
            </>
        )
    }

    render() {

        var List = this.state.Demand.map(Demand => (
            <DemandsList Demand={Demand} />
        ))

        const columns = [
            {
                name:'N. da OS',
                selector: 'serviceOrder',
                sortable:true,
                
            },
            {
                name: 'DT Registro',
                selector: 'createDate',
                cell:(d => timeStampFormated(d.createDate)),
                sortable: true,
            },
            {
                name: 'Programas',
                selector: (d => d.problems.map((problem) => <div>{problem.program.name}</div>)),          
            },
            {
                name: 'Necessidades',
                selector: (d => d.problems.map((problem) => <div>{problem.name}</div>)),
            },
            {
                name: 'Prioridade',
                selector: (d => d.problems.map((problem) => (
                    <div>
                        <i className="fas fa-arrow-up text-success mr-3" />
                        {this.getPriorityName(problem.priority)}
                    </div>
                ))),
            },
            {
                name: 'Situação',
                selector: 'situation',
                sortable: true,
                            
            },
            {
                cell:(row)=> this.buildButtons(row),
                ignoreRowClick: true,
            },
        ];
        
        return (
            <Container className="mt--7" fluid>
                <ToastContainer/>
                <Modal size="lg" isOpen={this.state.modal} fade="true"  toggle={()=>this.toggle(false)}>
                    <ModalHeader toggle={()=>this.toggle(false)}>Remoção de demanda</ModalHeader>
                    <ModalBody>           
                        <div className="row"> 
                            <div className="col-9">
                                <span className="h2 text-black">Deseja remover a demanda {this.state.selectedDemand}?</span>
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row" >                          
                        
                            <div className="col-6">
                                <button onClick={()=>this.toggle(false)} className="form-control">CANCELAR</button>
                            </div>
                            <div className="col-6">
                                <button onClick={()=>{this.state.reConfirmDelete = true; this.toggle(false);}} className="btn btn-primary">REMOVER</button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal size="lg" isOpen={this.state.reConfirmDelete} fade="true"  toggle={()=>this.toggle(false, true)}>
                    <ModalHeader toggle={()=>this.toggle(false, true)}>Remoção de demanda</ModalHeader>
                    <ModalBody>           
                        <div className="row"> 
                            <div className="col-9">
                                <span className="h2 text-black">Tem certeza que deseja remover a demanda {this.state.selectedDemand}?</span>
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row" >                          
                        
                            <div className="col-6">
                                <button onClick={()=>this.toggle(false, true)} className="form-control">CANCELAR</button>
                            </div>
                            <div className="col-6">
                                <button onClick={()=> this.toggle(true, true)} className="btn btn-primary">REMOVER</button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal size="lg" isOpen={this.state.discardModal} fade="true"  toggle={()=>this.toggleDiscard(false)}>
                    <ModalHeader toggle={()=>this.toggleDiscard(false)}>Descarte de demanda</ModalHeader>
                    <ModalBody>           
                        <div className="row"> 
                            <div className="col-9">
                                <span className="h2 text-black">Tem certeza que deseja descartar a demanda {this.state.selectedDemand}?</span>
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row" >                          
                        
                            <div className="col-6">
                                <button onClick={()=>this.toggleDiscard(false)} className="form-control">CANCELAR</button>
                            </div>
                            <div className="col-6">
                                <button onClick={()=> this.toggleDiscard(true)} className="btn btn-primary">DESCARTAR</button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal size="lg" isOpen={this.state.modal2} fade="true"  toggle={()=>this.toggle2()}>
                    <ModalHeader toggle={()=>this.toggle2()}>Visualização de demanda</ModalHeader>
                    <ModalBody>           
                        <ShowDemand Demand={this.state.SelectedDemand}/>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row" >                          
                            
                            <button onClick={()=>this.toggle2()} className="form-control">Sair</button>
                            
                        </div>
                    </ModalFooter>
                </Modal>
                <Row className="mt-12">
                    <Col className="mb-12 mb-xl-0" xl="12">
                    <Card className="shadow">
                        <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                            <h3 className="mb-0">Demandas</h3>
                            </div>
                        </Row>
                        </CardHeader>
                        <CardBody className="pt-0 pb-0 ">
                            <DataTable
                                noHeader
                                defaultSortField="serviceOrder"
                                defaultSortAsc={false}
                                pagination
                                highlightOnHover
                                columns={columns}
                                data={this.props.Demand}
                                noDataComponent={'Nenhum registro encontrado.'}
                            />
                        </CardBody>    
                    </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default DemandsTable
