import React, {Component} from "react";
// reactstrap components
import { Card, CardBody, CardHeader, Row, CardText, Col} from "reactstrap";
import CloropleticMap from "../../views/pages/Reports/CloropleticMap";

class CardMapPresentational extends Component {
    state = {
        maxClusterRadius: 20,
        titles: null,
    }

    style={
        chartCol:{
            height:"32.3rem",
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.maxClusterRadius != prevProps.maxClusterRadius){
            this.setState({maxClusterRadius: this.props.maxClusterRadius})
        }

    }

    render(){
        return (
            <Card>
                <CardHeader>
                    <CardText className="mb-0 mt-0" style={this.props.style.cardHeaderText}>
                        {this.props.textCardHeader}
                    </CardText>
                </CardHeader>
                <CardBody className="pb-0 pt-0 pl-0 pr-0">
                    <Row>
                        <Col style={this.style.chartCol}> 
                            <CloropleticMap 
                                PointsGroup={this.props.mapList} 
                                PolygonsGroup = {this.props.territorializations}
                                typeLayerSubtypePolygonsGroup = {this.props.typeLayerSubtypeTerritorializations}
                                maxClusterRadius={this.state.maxClusterRadius}
                                buildPolygonPopup={this.props.buildPolygonPopup}
                                dataToPopup={this.props.dataToPopup}
                                changeCluster={this.props.changeCluster}
                                titles={this.props.titles}
                                trapTypeName={this.props.trapTypeName}
                                setTrapToCenterOnMapPosition={this.props.setTrapToCenterOnMapPosition}
                                trapToCenterOnMapPosition={this.props.trapToCenterOnMapPosition}
                                trapToCenterNumber={this.props.trapToCenterNumber}
                                setModal2={this.props.setModal2 ?
                                    this.props.setModal2.bind(this) : null}
                                modal2={this.props.modal2}
                                setTrapToUpdateData={this.props.setTrapToUpdateData ?
                                    this.props.setTrapToUpdateData.bind(this) : null}
                                trapToUpdateData={this.props.trapToUpdateData}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}


export default CardMapPresentational;