import { Component } from 'react';
import { getUserData, removePermissions, removeToken, removeUserData} from '../../../services/utils/auth';

export default class Logout extends Component {

    async componentWillMount() {
        const {motive} = this.props;
        var organizationId = getUserData('organizationId');

        await removeToken();
        await removeUserData();
        await removePermissions();

        if (organizationId == '276953b3-9488-41fb-b60a-a4b51f7e1732') {
            this.props.history.push({pathname: '/auth/acesso', state: {motive: "TokenExpiration"}});
        } else {
            this.props.history.push({pathname: '/auth/login', state: {motive: "TokenExpiration"}});
        }
    }

    render() {
        return null;
    }
}