import React, { Component, useEffect, useState } from 'react';
import { Select } from "../Wrappers/SelectAll";
import getWeeks from '../../services/utils/epidemiologicalWeek';

class EpidemiologicalWeekToWeekInput extends Component {

    state = {
        weeks1: [],
        weeks2: []
    }

    value = {
        value1: "",
        value2: ""
    }

    changeValue1 = (e) => {
        if (this.props.isSinan == true)
            this.props.changePeriod(e,"Week1SINAN");
        else if (this.props.isEntomologico == true)
            this.props.changePeriod(e,"Week1ENTOMOLOGICO");
        else if (this.props.isOperacional == true)
            this.props.changePeriod(e,"Week1OPERACIONAL");
        else
            this.props.changePeriod(e,"Week1");
        this.props.setChangedWeekToWeek();
    }

    calcularSemanas = (week2) => {
        let weeks = new Array();

        if((!week2 && !this.props.weeks1.value) || !week2){
            if (this.props.isSinan == true)
                this.props.changePeriod([],"EpiWeekSINAN");
            else if (this.props.isEntomologico == true)
                this.props.changePeriod([],"EpiWeekENTOMOLOGICO");
            else if (this.props.isOperacional == true)
                this.props.changePeriod([],"EpiWeekOPERACIONAL");
            else
                this.props.changePeriod([],"EpiWeek");
        } else{
            for (let i = this.props.weeks1.value; i < week2.value + 1; i++) {
                weeks.push({
                    label: "Semana " + (i + 1),
                    value: i
                })
            }
            if (this.props.isSinan == true)
                this.props.changePeriod(weeks,"EpiWeekSINAN");
            else if (this.props.isEntomologico == true)
                this.props.changePeriod(weeks,"EpiWeekENTOMOLOGICO");
            else if (this.props.isOperacional == true)
                this.props.changePeriod(weeks,"EpiWeekOPERACIONAL");
            else
                this.props.changePeriod(weeks,"EpiWeek");
        }
    }

    changeValue2 = (e) => {
        this.calcularSemanas(e);
        if (this.props.isSinan == true)
            this.props.changePeriod(e,"Week2SINAN");
        else if (this.props.isEntomologico == true)
            this.props.changePeriod(e,"Week2ENTOMOLOGICO");
        else if (this.props.isOperacional == true)
            this.props.changePeriod(e,"Week2OPERACIONAL");
        else
            this.props.changePeriod(e,"Week2");
    }

    componentDidMount() {
        let weeks1 = new Array();
        let weeks2 = new Array();

        for (let i = 0; i < 53; i++) {
            weeks1.push({
                label: "Semana " + (i + 1),
                value: i
            })
        }
        for (let i = 0; i < 53; i++) {
            weeks2.push({
                label: "Semana " + (i + 1),
                value: i
            })
        }

        this.setState({ weeks1 });
        this.setState({ weeks2 });
    }
    stringToDate(_date, _format, _delimiter) {
        var formatLowerCase = _format.toLowerCase();
        var formatItems = formatLowerCase.split(_delimiter);
        var dateItems = _date.split(_delimiter);
        var monthIndex = formatItems.indexOf("mm");
        var dayIndex = formatItems.indexOf("dd");
        var yearIndex = formatItems.indexOf("yyyy");
        var month = parseInt(dateItems[monthIndex]);
        month -= 1;
        var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
        return formatedDate;
    }
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "5%" }}>
                {/* <div className="h4 text-white" style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}><span>De</span></div> */}
                <div style={{ width: "45%" }}>

                    <Select
                        styles={{
                            control: styles => ({ ...styles, minHeight: "2.90rem", borderRadius: "0.370rem" }),
                            option: styles => ({ ...styles, fontSize: "0.875rem" }),
                            valueContainer: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                            singleValue: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                            placeholder: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                            menuPortal: styles => ({ ...styles, zIndex: 9999 })
                        }}
                        value={this.props.weeks1}
                        isMulti={false}
                        placeholder={"Selecione"}
                        isClearable={true}
                        isDisabled={this.props.isDisabled}
                        blurInputOnSelect={false}
                        closeMenuOnSelect={false}
                        onChange={this.changeValue1}
                        options={this.state.weeks1}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div className="h4 text-white" style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}><span>Até</span></div>
                <div style={{ width: "45%" }}>

                    <Select
                        styles={{
                            control: styles => ({ ...styles, minHeight: "2.90rem", borderRadius: "0.370rem" }),
                            option: styles => ({ ...styles, fontSize: "0.875rem" }),
                            valueContainer: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                            singleValue: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                            placeholder: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                            menuPortal: styles => ({ ...styles, zIndex: 9999 })
                        }}
                        value={this.props.weeks2}
                        isMulti={false}
                        placeholder={"Selecione"}
                        isClearable={true}
                        isDisabled={this.props.isDisabled}
                        blurInputOnSelect={false}
                        closeMenuOnSelect={false}
                        allOption={{ label: "Todas", value: "*" }}
                        onChange={this.changeValue2}
                        options={this.state.weeks2}
                        menuPortalTarget={document.body}
                    />
                </div>
            </div>
        );
    }
}

export default EpidemiologicalWeekToWeekInput;
