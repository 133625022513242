import React from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// reactstrap components

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import "../../../../assets/css/style.css";
import { postItem,getStocables,getLots } from "../../../../services/api/Itens";
import { getUserData } from "../../../../services/utils/auth";
import { checkPermissionComponent } from "../../../../services/utils/permission";
var today = new Date(),
 date = today.getFullYear() + '-' + ('00'+(today.getMonth() + 1)).slice(-2) + '-' + today.getDate();
class AddInventoryItem extends React.Component {
  constructor (props) {
    super(props);
    /*if(!checkPermissionComponent("217c876b-5f50-4bbf-bce4-325b83ea7787", "create")){
      alert("Você não tem permissão para acessar essa funcionalidade!");
      props.history.goBack(); 
    }*/
    this.state = {
      
        NameId : "",
        Quantity : 0,
        newLot:false,
        Lot:"",
        Expiration:date,
        hasExpiration:true,             
        organizationId: getUserData('organizationId'),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleQuantity = this.handleQuantity.bind(this);

  }

  componentDidMount() {
    var requestStocables = getStocables();
    requestStocables.then(response => {
            this.setState({stocables: response.data});
        })
    
    var requestLots = getLots();
    requestLots.then(response => {
      this.setState({lots: response.data});
    })
  }
  
 
  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if(name === "NameId"|| name ==='newLot'){
      this.setState({Lot:""});
    }
    this.setState({
      [name]: value
    });
  };

  handleQuantity(event) {
    this.setState({[event.target.name]  : parseInt(event.target.value,10) });
  };

  createItem = () => {
    let errors = false;

    if(!this.state.NameId){
      toast.error("Erro: é necessário selecionar um estocável!");
      errors = true;
    }
    if(!this.state.Lot){
      toast.error("Erro: é necessário selecionar um lote!");
      errors = true;
    }
    if(errors == false){
      const requestItem = postItem(this.state);

      requestItem.then(response => {
        if(response) {
            if (response.data.id) {
                toast.success("Estocável cadastrado com sucesso!");
              } else {
                toast.error("Não foi possível cadastrar o estocável");
              }     
        }
    
    })
   .catch(error => {
        toast.error("Não foi possível cadastrar o estocável: Item existente");
    });
    }
  }

  render() {

    return (
      <>
        <Header />

        <ToastContainer />

        <Container className="" fluid>
          <Row className="mt-12">
            <Col className="mb-12 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        <Link to="/admin/InventoryItens"> <i className=" ni ni-bold-left bnt-voltar" /></Link>
                        Entrada Direta</h3>
                    </div>
                  </Row>
                </CardHeader>

                <Container fluid>
                  <div className="header-body">
                    <div className="row linha-form">
                      <div className="col-4">
                        <input
                          name="newLot"
                          type="checkbox"
                          onChange={this.handleChange} />Novo lote?
                      </div>
                    </div>
                    <div className="row linha-form">
                      <div className="col-4">
                        <span className="h5 text-black">Nome*</span>
                        <select className="form-control" name="NameId" onChange={this.handleChange}>
                          <option>Nenhum</option>
                            {this.state.stocables!=null?this.state.stocables.map((item) => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            }):""}                           
                        </select>
                      </div>
                      
                        {this.state.lots!=null&&!this.state.newLot?this.state.lots.map((lotList) => {return(<>
                          {lotList.stocableId==this.state.NameId &&
                            <>
                            <div className="col-4">
                              <span className="h5 text-black">Lote*</span>
                              
                              <select  className="form-control" name="Lot" onChange={this.handleChange}>
                                <option>Nenhum</option>
                                {lotList.lotList.map((lote) => {
                                return (
                                      
                                          <option value={lote}>{lote}</option>
                                )
                                })}
                                                        
                              </select>
                              </div>
                            </>
                          }</>)})
                        :""}  
                        {this.state.newLot?<>
                          <div className="col-4">
                            <span className="h5 text-black">Lote*</span>
                            <input type="text" className="form-control" placeholder="Lote" name="Lot" onChange={this.handleChange} />
                          </div>
                       </>:""}
                     
                      
                      <div className="col-4">
                        <span className="h5 text-black">Quantidade</span>
                        <input type="number" className="form-control" placeholder="0" name="Quantity" onChange={this.handleQuantity} />
                      </div>
                      {this.state.newLot?<>
                        <div className="col-4">
                          <input
                            name="hasExpiration"
                            type="checkbox"
                            checked={this.state.hasExpiration}
                            onChange={this.handleChange} />Tem data de validade?
                          </div>
                          {this.state.hasExpiration?<>
                          <div className="col-4">
                            <span className="h5 text-black">Data de Validade*</span>
                            <input type="date" className="form-control" name="Expiration" onChange={this.handleChange} />
                          </div>
                          </>:""}
                       </>:""}
                     
                      
                    </div>


                    <div className="row">
                      <div className="linha-form col-4">
                        <button onClick={this.createItem} className="btn btn-primary">Cadastrar</button>
                      </div>
                      <div className="col-4">
                        <button className="form-control">Limpar</button>
                      </div>
                    </div>
                  </div>
                </Container>

              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AddInventoryItem;
