import React, { Component } from "react";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";
import { timeStampFormated } from "../../../services/utils/format";
import moment from "moment";

export function withBasicListMap(WrappedComponent) {
  return class extends Component {
    buildMapList(
      traps,
      type,
      color,
      key,
      checked = true,
      typeCheck = false,
      trapTypeName = null,
      changeColor = false,
      organizationId
    ) {
      const trapList = [];

      for (const trap of traps) {
        const todayDate = moment();
        const trapInstallDate = moment(trap.dateInstalled);
        const trapLastReadDate =
          !trap.lastReadDate || trap.lastReadDate === ""
            ? null
            : moment(trap.lastReadDate);
        const daysDifferenceBetweenTodayAndInstallDate = todayDate.diff(
          trapInstallDate,
          "days"
        );
        const daysDifferenceBetweenTodayAndLasReadDate =
          trapLastReadDate !== ""
            ? todayDate.diff(trapLastReadDate, "days")
            : "";

        const daysDifference =
          daysDifferenceBetweenTodayAndLasReadDate === ""
            ? daysDifferenceBetweenTodayAndInstallDate
            : daysDifferenceBetweenTodayAndLasReadDate <
              daysDifferenceBetweenTodayAndInstallDate
            ? daysDifferenceBetweenTodayAndLasReadDate
            : daysDifferenceBetweenTodayAndInstallDate;

        let dateTime = new Date(trap.dateInstalled);

        let name = ARMADILHA_INFO[trap.trapTypeName].apelido;
        if (trap.number) {
          name = name + " Nº " + trap.number;
        }

        name += `\n ${timeStampFormated(dateTime)}`;

        if (
          typeCheck &&
          trapTypeName == ARMADILHA_INFO[trap.trapTypeName].apelido
        ) {
          checked = true;
        }
        const position = [trap.latitude, trap.longitude];
        const icon = color + trap.trapTypeName + "Icon";
        var color = color;

        if (changeColor) {
          if (
            trap.trapTypeName === "armadilhaDisseminadoraInseticida" ||
            trap.trapTypeName === "estacaoDisseminadoraLarvicida"
          ) {
            if (daysDifference <= 30) color = "green";
            if (daysDifference > 30 && daysDifference <= 60) color = "yellow";
            if (daysDifference > 60) color = "red";
          }

          if (
            trap.trapTypeName === "armadilhaOvos" ||
            trap.trapTypeName === "armadilhaMosquitoAdulto2" ||
            trap.trapTypeName === "armadilhaMosquitoAdulto"
          ) {
            if (daysDifference <= 5) color = "green";
            if (daysDifference > 5) color = "red";
          }
        }

        const trapListObject = {
          name,
          position,
          draggableTrap: false,
          type,
          number: trap.number,
          incomingIcon: icon,
          intensity: 1,
          icon: ARMADILHA_INFO[trap.trapTypeName].icons[color],
          checkedIcon: ARMADILHA_INFO[trap.trapTypeName].icons["black"],
          organizationId,
        };

        trapList.push(trapListObject);
      }

      const mapListObject = {
        name: key,
        key,
        checked: checked,
        points: trapList,
        value: trapList,
      };

      return mapListObject;
    }

    render() {
      return (
        <WrappedComponent {...this.props} buildMapList={this.buildMapList} />
      );
    }
  };
}