import React from "react";
import "./../../../assets/css/navbar.css";
import { Container } from "reactstrap";
import { Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../../../assets/css/style.css";
import { getUserEmployeeDataByOrganization } from "../../../services/api/Users";
import { fetchDemands } from "../../../services/api/Demand";
import { getTeam } from "../../../services/api/Team";
import {
  fetchTerritorializations,
  fetchTerritorializationsByTypeLayerSubtypeId,
  getAllTypeTerritorializations,
} from "../../../services/api/territorialization";
import "./MainDashboard.css";
import Traps from "../../Traps";
import { SCHOOLS_DATA } from "../../../constants/SchoolsConstant";
import { getUserData } from "../../../services/utils/auth";

const MainTrapsDashboard = () => {
  const [demandsList, setDemandsList] = React.useState([]);
  const [employeesList, setEmployeesList] = React.useState([]);
  const [teamsList, setTeamsList] = React.useState([]);
  const [territorializationsList, setTerritorializationsList] = React.useState(
    []
  );
  const [typeLayerSubtypeTerritorializationsList, setTypeLayerSubtypeTerritorializationsList] = React.useState(
    []
  );

  React.useEffect(() => {
    fetchAllComponentData();
  }, []);

  const fetchTerritorializationsData = async () => {
    try {
      const { data: typeTerritorializationsData, status } =
        await getAllTypeTerritorializations();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dado das Territorializações"
        );

      const territorializations = [];

      for (const typeTerritorialization of typeTerritorializationsData) {
        if (typeTerritorialization.typeGeometry === "Polygon") {
          const { data } = await fetchTerritorializations(
            typeTerritorialization.id
          );

          territorializations.push({
            name: typeTerritorialization.name,
            label: typeTerritorialization.name,
            territorializationList: data,
            options: data.map((territorialization) => {
              return {
                label: territorialization.name,
                value: territorialization.id,
              };
            }),
          });
        }
      }

      setTerritorializationsList(territorializations);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSchoolsTerritorializationsData = async () => {
    if(getUserData("organizationId") !== "559a16ab-5da8-41ba-96b8-6e9f55feddd8")
      return [];

    try {
      const result = [];

      for (const typeTerritorialization of SCHOOLS_DATA) {
        
        const { data } = await fetchTerritorializationsByTypeLayerSubtypeId(
          typeTerritorialization.value
        );

        result.push({
          name: typeTerritorialization.label,
          label: typeTerritorialization.label,
          territorializationList: data,
          options: data.map((territorialization) => {
            return {
              label: territorialization.label,
              value: territorialization.value,
            };
          }),
        });
        
      }

      setTypeLayerSubtypeTerritorializationsList(result);

      return result;

    } catch (error) {
      console.error(error);
    }
  };

  const fetchDemandsData = async () => {
    try {
      const { data, status } = await fetchDemands();
      if (status !== 200)
        throw new Error("Ocorreu um erro ao buscar pelos dado de Demandas");

      const formatedDemands = data.map(({ id, serviceOrder }) => {
        return {
          label: serviceOrder,
          value: id,
        };
      });

      setDemandsList([
        {
          value: "00000000-0000-0000-0000-000000000000",
          label: "Selecione",
        },
        ...formatedDemands,
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEmployeesData = async () => {
    try {
      const { data, status } = await getUserEmployeeDataByOrganization();
      if (status !== 200)
        throw new Error("Ocorreu um erro ao buscar pela lista de funcionários");

      const formatedEmployees = data.map(
        ({ userId, employeeId, employeeName, uvis }) => {
          return {
            employeeId,
            employeeName,
            userId,
            label: employeeName,
            value: userId,
            uvis,
          };
        }
      );

      const employeesList = [
        {
          value: "00000000-0000-0000-0000-000000000000",
          label: "Selecione",
        },
        ...formatedEmployees,
      ];

      setEmployeesList(employeesList);

      return employeesList;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTeamsData = async (employeesData) => {
    try {
      const { data, status } = await getTeam();
      if (status !== 200)
        throw new Error("Ocorreu um erro ao buscar pela lista de funcionários");

      const formatedTeams = data.map(({ id, members, name }) => {
        const formatedTeamMembers = members.map((member) => {
          const userData = employeesData.find(
            (employee) => employee.employeeId === member.employeeId
          );

          return {
            ...member,
            userId: userData
              ? userData.userId
              : "00000000-0000-0000-0000-000000000000",
          };
        });

        return {
          value: id,
          label: name,
          members: formatedTeamMembers,
        };
      });

      setTeamsList([
        {
          value: "00000000-0000-0000-0000-000000000000",
          label: "Selecione",
        },
        ...formatedTeams,
      ]);

      return formatedTeams;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllEmployeesAndTeamsData = async () => {
    const employeesData = await fetchEmployeesData();
    const teamsData = await fetchTeamsData(employeesData);

    return [employeesData, teamsData];
  };

  const fetchAllComponentData = async () => {
    try {
      const demandsData = fetchDemandsData();
      const employeesAndTeamsData = fetchAllEmployeesAndTeamsData();
      const territorializationsData = fetchTerritorializationsData();
      const schoolsData = fetchSchoolsTerritorializationsData();

      const results = await Promise.all([
        demandsData,
        employeesAndTeamsData,
        territorializationsData,
        schoolsData,
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Tabs>
      <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8">
        <TabList className="tab-container"></TabList>
      </Container>
      <div className="mt--7">
        <TabPanel>
          {employeesList && employeesList.length !== 0 && (
            <Traps
              demandsList={demandsList}
              teamsList={teamsList}
              employeesList={employeesList}
              territorializationsList={territorializationsList}
              typeLayerSubtypeTerritorializationsList={typeLayerSubtypeTerritorializationsList}
            />
          )}
        </TabPanel>
      </div>
    </Tabs>
  );
};

export default MainTrapsDashboard;
