import React from "react";
// react component that copies the given text inside your clipboard
import 'react-tabs/style/react-tabs.css';
// reactstrap components
import {timeStampFormatedMongo} from "../../../services/utils/format";
import {
  Table,
} from "reactstrap";
// core components
import "../../../assets/css/style.css";
import {getActiveSearchByDemandId} from "../../../services/api/Covid"
import {getVisitsByDemandId, getVisitsVigilanciaByDemandId} from "../../../services/api/Visits"
import {fetchUsers} from "../../../services/api/Users";
import {fetchEmployeeData} from "../../../services/api/Employee";

class DemandVisits extends React.Component {
  state = {
      ActiveSearchList:null,
      VisitList:null,
      DemandId: this.props.DemandId,
      VisitVigilanciaList:null,
      loading:true
  }
  componentDidMount() {

    var requestActiveSearch = getActiveSearchByDemandId(this.state.DemandId);
    var requestVisit = getVisitsByDemandId(this.state.DemandId);
    var requestVigilancia = getVisitsVigilanciaByDemandId(this.state.DemandId);

    requestActiveSearch.then(response => {
        if(response) {
            return response.data
        }
        throw new Error("Erro ao contatar servidor");
    }).then(data => {
      data = this.getCollectorName(data);
      this.setState({ActiveSearchList : data});
      
    });

    requestVisit.then(response => {
        if(response) {
            return response.data
        }
        throw new Error("Erro ao contatar servidor");
    }).then(data => {
      data = this.getCollectorName(data);
      this.setState({VisitList : data});
      
    });

    requestVigilancia.then(response => {
      if(response) {
          return response.data
      }
      throw new Error("Erro ao contatar servidor");
    }).then(data => {
      data = this.getCollectorName(data);
      this.setState({VisitList : data});
      
    });
}
getCollectorName = (data) =>{
  var usersRequest = fetchUsers();
  var usersList = [];
  usersRequest.then(users => {
    usersList = users.data;
    
    data.map((user, id) =>{
      usersList.forEach(element => {

        if(user.collectorId == element.id){
          let employeeId = element.employeeId;
          
          let employeeRequest = fetchEmployeeData(employeeId);
          
          employeeRequest.then(response =>{
            data[id].collectorName = response.data.name;
            this.setState({loading:false});
          });
        }
      });
      
      
    });
  }).then(() => {
    this.setState({loading:false});
  })
  return data;
}

  render() {
    
    return (
      <>

            <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                        <tr>
                          <th scope="col">Tipo de formulário</th>
                          <th scope="col">Data</th>
                          <th scope="col">Territorilização</th>
                          <th scope="col">Nome responsável</th>
                        </tr>
                    </thead>
                    <tbody>
                    {(this.state.loading) ?
                      <span></span> :
                        this.state.ActiveSearchList != null ? this.state.ActiveSearchList.map((ActiveSearch, index) => {
                                return(
                                    <tr key={index}>
                
                                        <td >Formulário de busca ativa</td>
                                        <td>            
                                        {timeStampFormatedMongo(ActiveSearch.activityDate)}
                                        </td>
                                        <td>            
                                        {ActiveSearch.address.logradouro + ", "+ ActiveSearch.address.numero}
                                        </td>
                                        <td>
                                        {ActiveSearch.collectorName}
                                        </td>
                                        
                                    </tr>
                                )
                            }) : ""}
                            {(this.state.loading) ?
                            <span></span> :
                              this.state.VisitList != null ? this.state.VisitList.map((Visit, index) => {
                                      return(
                                          <tr key={index}>
                      
                                            <td >Formulário de vistoria ambiental</td>
                                            <td>            
                                            {timeStampFormatedMongo(Visit.activityDate)}
                                            </td>
                                            <td>            
                                            {Visit.address.logradouro + ", "+ Visit.address.numero}
                                            </td>
                                            <td>
                                            {Visit.collectorName}
                                            </td>
                                              
                                          </tr>
                                      )
                                  }) : ""}

                      {(this.state.loading) ?
                      <span></span> :
                        this.state.VisitVigilanciaList != null ? this.state.VisitVigilanciaList.map((Vigilancia, index) => {
                                return(
                                    <tr key={index}>
                
                                        <td >Formulário de Vigilância Entomológica</td>
                                        <td>            
                                        {timeStampFormatedMongo(Vigilancia.activityDate)}
                                        </td>
                                        <td>            
                                        {Vigilancia.address.logradouro + ", "+ Vigilancia.address.numero}
                                        </td>
                                        <td>
                                        {Vigilancia.collectorName}
                                        </td>
                                        
                                    </tr>
                                )
                            }) : ""}
                    </tbody>
            </Table>
        {(this.state.ActiveSearchList != null) ? 
            <div className="row linha-form">
                <div className="col-4">
                    <span className="h5 text-black">{this.state.ActiveSearchList.map((visita) => {                
                        return <div><i className="mr-3" />{}</div>
                    })}</span>
            </div>
            </div> : <span>Carregando informações</span>
    
    
        }
      </>
    );
  }
}

export default DemandVisits;