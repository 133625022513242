import React from "react";
// react component that copies the given text inside your clipboard
import { Link } from "react-router-dom";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// reactstrap components

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import "../../../assets/css/style.css";
import { putTeam, getTeamById } from "../../../services/api/Team";
import { fetchPrograms } from "../../../services/api/Program";
import { fetchEmployees } from "../../../services/api/Employee";
import { checkPermissionComponent } from "../../../services/utils/permission";
import "./Team.css";

class EditTeam extends React.Component {
  constructor (props) {
    super(props);
    if(!checkPermissionComponent("217c876b-5f50-4bbf-bce4-325b83ea7787", "update")){
      alert("Você não tem permissão para acessar essa funcionalidade!");
      props.history.goBack(); 
    }
    this.state = {
      teamId:"",
      OrganizationId:"",
      employees: [],
      programs: [],
      Name : "",
      Program : "",
      Superviser : null,
      Coordinator : null,
      Description : "",
      Members: null,
      Executioner: null
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.state.teamId = localStorage.getItem("TeamItem");
    var request = getTeamById(this.state.teamId);
    var comissioner = null;
    var executors = [];
    let coordinator = null;
    let superviser = null;
    request.then(response => {
      
        response.data.result.members.map((member) => {
          let fields = {
            value: member.employeeId,
            label: member.name,
          }
          if(member.isCoordinator){
            coordinator = fields;
          }
          if(member.isSuperviser){
            superviser = fields;
          }
          if(member.isComissioner){
            comissioner = fields;
          }
          if(member.isExecutor){
            executors.push(fields);
          }
        })
        this.setState({
          Program:response.data.result.programId,
          Description:response.data.result.description,
          state:response.data.result,
          Members: comissioner,
          Executioner: executors,
          OrganizationId: response.data.result.organizationId,
          Name: response.data.result.name,
          Coordinator:coordinator,
          Superviser: superviser
        });
        localStorage.removeItem("TeamItem");
    });
    var requestPrograms = fetchPrograms();
        requestPrograms.then(response => {
            this.setState({programs: response.data});
        })
    
    var requestEmployees = fetchEmployees();
        requestEmployees.then(response => {
          let employeesOpt = response.data;
          var employeesSelect = [];

          employeesOpt.forEach(element => {
            let employee = {
              label: element.name,
              value: element.id,
              EmployeeId: element.id,
              Hierarchy: element.function != null ? element.function.hierarchy: null,          
            }
            employeesSelect.push(employee);
          });

          this.setState({employees: employeesSelect});
        });
  }
  
 
  handleChange(event) {
    this.setState({[event.target.name]  : event.target.value });
  }; 
  handleChangeCoordinator = Coordinator => {
    this.setState({Coordinator});
  }
  handleChangeSuperviser = Superviser => {
    this.setState({Superviser});
  }
  handleChangeEncarregado = Members => {
    this.setState(
      { Members });
  };

  handleChangeExecutor = Executioner => {
    this.setState(
      { Executioner });
  };

  updateTeam = () => {
    let errors = false;
    if(!this.state.Program){
      toast.error("Erro: é necessário selecionar um programa!");
      errors = true;
    }
    if (!this.state.Name) {
      toast.error("Erro: O nome da equipe é obrigatório");
      errors=true;
    }
    if (!this.state.Executioner) {
      toast.error("Erro: É preciso ter ao menos 1 executor");
      errors=true;
    }

    if(!errors){
      const requestTeam = putTeam(this.state.teamId,this.state);
      requestTeam.then(response => {
        if(response) {
            return response.data
        }
        throw new Error("Erro de requisição...");
      })
      .then(data => {        
          if (data.id) {
            toast.success("Equipe alterada com sucesso!");
          } else {
            toast.error("Não foi possível cadastrar a equipe");
          }        
      });
    }
  }
  render() {

    const { Members } = this.state;
    const { Executioner } = this.state;
    const { Superviser } = this.state;
    const { Coordinator } = this.state;

    return (
      <>
        <Header />

        <ToastContainer />

        <Container className="container-flex-team" fluid>
          <Row className="mt-12">
            <Col className="mb-12 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        <Link to="/admin/equipes"> <i className=" ni ni-bold-left bnt-voltar" /></Link>
                        Editar Equipe</h3>
                    </div>
                  </Row>
                </CardHeader>

                <Container fluid>
                  <div className="row linha-form">
                    <div className="col-4 responsive-field-form-team-medium">
                      <span className="h5 text-black">Nome</span>
                      <input type="text" value={this.state.Name} className="form-control" placeholder="Nome" name="Name" onChange={this.handleChange} />
                    </div>
                    <div className="col-4 responsive-field-form-team-medium">
                      <span className="h5 text-black">Programa</span>
                      <select className="form-control" value={this.state.Program} name="Program" onChange={this.handleChange}>
                        <option>Programa</option>
                          {this.state.programs.map((program) => {
                              return (
                                  <option value={program.id}>{program.name}</option>
                              )
                          })}                           
                      </select>
                    </div>
                    <div className="col-4 responsive-field-form-team-medium">
                      <span className="h5 text-black">Coordenador</span>
                      <Select
                        value={Coordinator}
                        onChange={this.handleChangeCoordinator}
                        options={this.state.employees.filter(e => e.Hierarchy === 'Coordinator')}
                        placeholder="Coordenador"
                      />
                    </div>

                    <div className="col-4 responsive-field-form-team-medium">
                      <span className="h5 text-black">Supervisor</span>
                      <Select
                        value={Superviser}
                        onChange={this.handleChangeSuperviser}
                        options={this.state.employees.filter(e => e.Hierarchy === 'Superviser')}
                        placeholder="Supervisor"
                      />
                    </div>
                    
                    <div className="col-8 responsive-field-form-team-full">
                      <span className="h5 text-black">Descrição</span>
                      <textarea className="form-control"value={this.state.Description} placeholder="Descrição"name="Description"onChange={this.handleChange}></textarea>
                    </div>
                  </div>

                  <div className="row linha-form">
                    <div className="col-6 responsive-field-form-team-full">
                      <span className="h5 text-black">Encarregados</span>
                      <Select
                        value={Members}
                        onChange={this.handleChangeEncarregado}
                        options={this.state.employees.filter(e => e.Hierarchy === 'Responsible')}
                        placeholder="Encarregados"
                      />
                    </div>
                    <div className="col-6 responsive-field-form-team-full">
                      <span className="h5 text-black">Executores</span>
                      <Select
                        value={Executioner}
                        onChange={this.handleChangeExecutor}
                        options={this.state.employees}
                        isMulti
                        placeholder="Executores"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="linha-form col-4 responsive-field-form-team-full">
                      <button onClick={this.updateTeam} className="btn btn-primary">Salvar</button>
                    </div>
                  </div>
                </Container>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default EditTeam;