import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";

// specify props.allowSelectAll = true to enable!
export const Select = props => {
  if (props.allowSelectAll) {
    if(props.value.length === props.options.length){
        return (
            <ReactSelect
                {...props}
                styles={{
                  control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                  option: styles =>({...styles, fontSize:"0.875rem"}),
                  valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                  singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                  placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"})
                }}
                options={[...props.options]}
                onChange={selected => {
                if (
                    selected &&
                    selected.length > 0 &&
                    selected[selected.length - 1].value === props.allOption.value
                ) {
                    return props.onChange(props.options);
                }
                return props.onChange(selected);
                }}
            />
        )
    }

    return (
      <ReactSelect
        {...props}
        styles={{
          control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
          option: styles =>({...styles, fontSize:"0.875rem"}),
          valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
          singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
          placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"})
        }}
        options={[props.allOption, ...props.options]}
        onChange={selected => {
          if (
            selected &&
            selected.length > 0 &&
            selected[selected.length - 1].value === props.allOption.value
          ) {
            return props.onChange(props.options);
          }
          return props.onChange(selected);
        }}
      />
    );
  }

  return <ReactSelect {...props} 
    styles={{
      control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
      option: styles =>({...styles, fontSize:"0.875rem"}),
      valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
      singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
      placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"})
    }}
  />;
};

Select.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
};

Select.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*"
  }
};