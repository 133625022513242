import request from '../utils/request';

export function fetchExams(query) {
    return request({
      url: '/Exams',
      method: 'get',
    });
  }

  export function getDiagnosticsExam(){
    return request({
      url: '/Exams/Diagnostic',
      method: 'get',
    });
  }
 export function getIndentificationsExam(){
    return request({
      url: '/Exams/Identification',
      method: 'get',
    });
  }