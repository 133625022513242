import React from "react";
import { Card, CardHeader, Row, Col } from "reactstrap";
import DataTable from "react-data-table-component";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment";
import DemandsExportFilters from "./DemandsExportFilters";

const DemandsExportTable = ({ demandsToExport }) => {
  const [filteredDemandsToExport, setFilteredDemandsToExport] =
    React.useState(demandsToExport);
  const [demandsFilters, setDemandsFilters] = React.useState({
    number: "",
    beginInstallDate: "",
    endInstallDate: "",
    type: {
      label: "Todos",
      value: "all",
    },
    program: {
      label: "Todos",
      value: "all",
    },
    situation: {
      label: "Todas",
      value: "all",
    },
    origin: {
      label: "Todos",
      value: "all",
    },
  });

  const handleFiltersChange = (filterName, newValue) => {
    const newFiltersValues = { ...demandsFilters };

    newFiltersValues[filterName] = newValue;

    setDemandsFilters(newFiltersValues);
  };

  const filterDemands = () => {
    const filteredDemands = demandsToExport.filter((demand) => {
      if (
        demandsFilters.number !== "" &&
        !demand.NUMERO.includes(demandsFilters.number)
      ) 
        return false;

      if (
        demandsFilters.type.value !== "all" &&
        demand.TIPO !== demandsFilters.type.label
      ) 
        return false;
      
      if (
        demandsFilters.situation.value !== "all" &&
        demand.SITUACAO !== demandsFilters.situation.label
      ) 
        return false;
      
      if (
        demandsFilters.program.value !== "all" &&
        demand.PROGRAMA !== demandsFilters.program.label
      ) 
        return false;

      if (
        demandsFilters.origin.value !== "all" &&
        demand.ORIGEM_SOLICITACAO !== demandsFilters.origin.label
      ) 
        return false;
      
      return true;
    });

    setFilteredDemandsToExport(filteredDemands);
  };

  React.useEffect(() => {
    filterDemands();
  }, [demandsFilters]);

  const tableColumns = [
    {
      name: "Número",
      selector: "NUMERO",
      sortable: true,
    },
    {
      name: "Tipo",
      selector: "TIPO",
      sortable: true,
    },
    {
      name: "Data de criação",
      selector: "DATA_CRIACAO",
      sortable: true,
    },
    {
      name: "Origem",
      selector: "ORIGEM_SOLICITACAO",
      sortable: true,
    },
    {
      name: "Situação",
      selector: "SITUACAO",
      sortable: true,
    },
    {
      name: "Descrição",
      selector: "DESCRICAO",
      sortable: true,
    },
    {
      name: "Territorialização",
      selector: "TERRITORIALIZACAO",
      sortable: true,
    },
    {
      name: "Programa",
      selector: "PROGRAMA",
      sortable: true,
    },
    {
      name: "Previsão de início",
      selector: "PREVISAO_INICIO",
      sortable: true,
    },
    {
      name: "Previsão de fim",
      selector: "PREVISAO_FIM",
      sortable: true,
    },
    {
      name: "Equipe",
      selector: "NOME_EQUIPE",
      sortable: true,
    },
  ];

  return (
    <Row className="mt-12">
      <Col className="mb-12 mb-xl-0" xl="12">
        <Card className="shadow">
          <CardHeader className="border-0" style={{ paddingBottom: "0px" }}>
            <Row className="align-items-center">
              <div className="col">
                <h2 className="mb-0">Demandas</h2>
                <br />
              </div>
            </Row>
          </CardHeader>
          <DemandsExportFilters
            demandsFilters={demandsFilters}
            handleFiltersChange={handleFiltersChange}
          />
          <Row style={{ textAlign: "center", marginBottom: "1rem" }}>
            <Col>
              <CsvDownloadButton
                className="btn btn-primary"
                filename={`demandas${moment().format("YYYY-MM-DD")}`}
                data={filteredDemandsToExport}
                style={{ width: "330px" }}
              >
                <i className="fa fa-download" /> Baixar CSV das demandas
              </CsvDownloadButton>
            </Col>
          </Row>
          <DataTable
            noHeader
            defaultSortField="name"
            defaultSortAsc={true}
            pagination
            highlightOnHover
            responsive
            columns={tableColumns}
            data={filteredDemandsToExport}
            noDataComponent={"Nenhum registro encontrado."}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default DemandsExportTable;
