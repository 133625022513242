import React from "react";

import FiltroEquipes from "../components/Navbars/FiltroEquipes"
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

// core components

import Header from "components/Headers/Header.jsx";
import { getTeam, deleteTeam } from "../services/api/Team";
import { checkPermissionComponent } from "../services/utils/permission";
import { getPermissionsToken } from "../services/utils/auth";
import { timeStampFormated } from "../services/utils/format";
import DataTable from "react-data-table-component";

class Equipe extends React.Component {

    state = {
        teams: [],
        teamsFiltered: [],
        modal: "",
        selectedTeam: "",
        selectedTeamId: ""
    }

    constructor(props) {
        super(props);
        if (!checkPermissionComponent("217c876b-5f50-4bbf-bce4-325b83ea7787", "read")) {
            alert("Você não tem permissão para acessar essa funcionalidade!");
            props.history.goBack();
        }
    }

    componentDidMount() {

        let requestTeam = getTeam()
        requestTeam.then(response => {
            this.setState({
                teams: response.data,
                teamsFiltered: response.data
            });
        });
    }
    callbackFunction = (TeamFilteredArray) => {
        this.setState({ teamsFiltered: TeamFilteredArray });
    }
    confirmDelete(teamName, teamId) {
        this.setState({
            selectedTeamId: teamId,
            selectedTeam: teamName
        });
        this.toggle(false);
    }
    toggle = (willDeleteTeam) => {
        this.setState({
            modal: !this.state.modal
        });
        if (willDeleteTeam) {
            let deleted = deleteTeam(this.state.selectedTeamId);
            deleted.then(response => {
                if (response) {

                    let newTeams = this.state.teamsFiltered.filter(team => team.id != this.state.selectedTeamId);
                    this.setState({ teamsFiltered: newTeams });
                    this.setState({ teams: newTeams });

                    toast.success("Equipe removida com sucesso!");
                }
            });
        }
    }

    buildButtons(team) {
        const userPermissions = getPermissionsToken();
        return (
            <>
                {userPermissions["217c876b-5f50-4bbf-bce4-325b83ea7787"] &&
                    <>
                        {userPermissions["217c876b-5f50-4bbf-bce4-325b83ea7787"].update &&
                            <Link to="/admin/EditTeam">
                                <button
                                    className="btn btn-primary"
                                    style={{ 'width': '60px', 'margin': '5px' }}
                                    data-clipboard-text="ni ni-fat-edit"
                                    id="tooltip204269497"
                                    onClick={() => localStorage.setItem('TeamItem', team.id)}
                                    type="button">
                                    <i className="ni ni-ruler-pencil" />
                                </button>
                            </Link>
                        }

                        {userPermissions["217c876b-5f50-4bbf-bce4-325b83ea7787"].delete &&
                            <button
                                className="btn btn-warning"
                                style={{ 'width': '60px', 'margin': '5px' }}
                                data-clipboard-text=" ni ni-fat-remove"
                                id="tooltip204269497"
                                onClick={() => this.confirmDelete(team.name, team.id)}
                                type="button">
                                <i className="fa fa-trash" />
                            </button>
                        }
                    </>
                }
            </>
        )
    }
    render() {
        const columns = [
            {
                name: 'Dt. Criação',
                selector: 'createAt',
                cell: (t => timeStampFormated(t.createAt)),
                sortable: true,

            },
            {
                name: 'Nome',
                selector: 'name',
                sortable: true,
            },
            {
                name: 'Programas',
                selector: (t => t.program.name),
                sortable: true,
            },
            {
                name: 'Coordenador',
                selector: (t => t.members.filter(member => member.isCoordinator).map(member => <div style={{ marginTop: '5px', marginBottom: '5px' }}>{member.name}</div>)),
            }, {
                name: 'Membros',
                selector: (t => t.members.map(member => <div style={{ marginTop: '5px', marginBottom: '5px' }}>{member.name}</div>)),
            },
            {
                cell: (row) => this.buildButtons(row),
                ignoreRowClick: true,
            },
        ];
        return (
            <>
                <ToastContainer />
                <Modal size="lg" isOpen={this.state.modal} fade="true" toggle={() => this.toggle(false)}>
                    <ModalHeader toggle={() => this.toggle(false)}>Remoção de equipe</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-9">
                                <span className="h2 text-black">Deseja remover a equipe {this.state.selectedTeam}?</span>
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row" >

                            <div className="col-6">
                                <button onClick={() => this.toggle(false)} className="form-control">CANCELAR</button>
                            </div>
                            <div className="col-6">
                                <button onClick={() => this.toggle(true)} className="btn btn-primary">REMOVER</button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
                <Header />

                <FiltroEquipes Teams={this.state.teams} parentCallback={this.callbackFunction} />

                <Container className="mt--7" fluid>
                    <Row className="mt-12">
                        <Col className="mb-12 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Equipes</h3>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <DataTable
                                    noHeader
                                    defaultSortField="createAt"
                                    defaultSortAsc={false}
                                    pagination
                                    highlightOnHover
                                    columns={columns}
                                    data={this.state.teamsFiltered}
                                    noDataComponent={'Não há equipes para serem exibidas.'}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

}

export default Equipe;
