import React from "react";
import { getUserData } from "../auth";

const checkShowBiovec = () => {
    var userId = getUserData('userId');
    var organizationId = getUserData('organizationId');
    var showBiovec = false;
    if(userId == "b5dc0dc5-355a-4cc2-a2c5-2e5b77c9d9dd" || organizationId != "559a16ab-5da8-41ba-96b8-6e9f55feddd8")
    {
        showBiovec = true;
    }

    return showBiovec;
}

const sortInventoryPlaces = (array) => {
    array.sort((a, b) => {
        if (a.name === "Trabalho em campo") return -1;
        if (b.name === "Trabalho em campo") return 1;
        return a.name.localeCompare(b.name);
    })
    
    return array;
}

const filterInventoryPlaces = (array, showBiovec = true, filterByPlaceName = false, type = "element") => {
    return array.map((place) => {
        if(place.name == "BIOVEC" && !showBiovec) return;
          
        if(type == "obj") {
            if(filterByPlaceName) {
                return {
                    value: place.name,
                    label: place.name
                }
            }
    
            return {
                value: place.id,
                label: place.name
            }
        } else {
            if(filterByPlaceName) {
                return (
                    <option value={place.name}>{place.name}</option>
                )
            }
    
            return (
                <option value={place.id}>{place.name}</option>
            )
        }

        
    })
}

export { checkShowBiovec, sortInventoryPlaces, filterInventoryPlaces };