
import React from "react";
import { Col, Row } from "reactstrap";

// reactstrap components
const depositType = [
    {id:"", name: "Selecione"},
    {id: "A1", name: "A1"},
    {id: "A2", name: "A2"},
    {id: "B", name: "B"},
    {id: "C", name: "C"},
    {id: "D1", name: "D1"},
    {id: "D2", name: "D2"},
    {id: "E", name: "E"},
    {id: "POOL", name: "Pool"}
]


const DepositoFilterPresentational = (props) => {
    return (
        <>
            <Col className="col-4">
                <span className="h4 text-white">Data início</span>
                <input value={props.startDate} name={"startDate"} onChange={(e) => {props.handleChangeFilter(e.target.name, e.target.value)}} type="date" className="form-control" />
            </Col>
            <Col className="col-4">
                <span className="h4 text-white">Data fim</span>
                <input value={props.endDate} name={"endDate"} onChange={(e) => {props.handleChangeFilter(e.target.name, e.target.value)}} type="date" className="form-control" />
            </Col>
            <Col className="col-4">
                <span className="h4 text-white">Tipo de depósito</span>
                <select value={props.selectedDeposit} name={"depositType"} className="form-control" onChange={(e) => props.handleChangeFilter(e.target.name, e.target.value)}>
                    {depositType.map(depositType => {
                        return (
                            <option key={depositType.id} value={depositType.id}>{depositType.name}</option>
                        )
                    })}
                </select>
            </Col>
        </>    
    )
}


export default DepositoFilterPresentational;