import React from "react";
import CardPresentational from "../../../../Presentational/CardPresentational";

const InstalledTrapsCard = ({
  installedTraps,
  cardCustomText,
  cardCustomInstalledTrapsNumber,
  cardCustomNumber,
  reduceCustomNumberFromInstalledTraps,
}) => {
  const [installedTrapsNumber, setInstalledTrapsNumber] = React.useState(cardCustomInstalledTrapsNumber || 0);

  React.useEffect(() => {
    let installedTrapsYearsData;
    let lastYearInstalledTrapsNumber;

    if (!cardCustomInstalledTrapsNumber) {
      installedTrapsYearsData = installedTraps
        ? installedTraps[installedTraps.length - 1]
        : null;
      lastYearInstalledTrapsNumber = installedTrapsYearsData
        ? installedTrapsYearsData[installedTrapsYearsData.length - 1].actives
        : null;
    } else lastYearInstalledTrapsNumber = cardCustomInstalledTrapsNumber;

    if (cardCustomNumber && !reduceCustomNumberFromInstalledTraps)
      setInstalledTrapsNumber(cardCustomNumber);
    else if (cardCustomNumber && reduceCustomNumberFromInstalledTraps)
      setInstalledTrapsNumber(lastYearInstalledTrapsNumber - cardCustomNumber);
    else setInstalledTrapsNumber(lastYearInstalledTrapsNumber);
  }, [cardCustomInstalledTrapsNumber, cardCustomNumber]);

  return (
    <CardPresentational number={installedTrapsNumber} text={cardCustomText} />
  );
};

export default InstalledTrapsCard;
