import React from "react";
import SampleList from "./SampleList";
import { Link } from "react-router-dom";

import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
  } from "reactstrap";
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';
import SelectedSampleModal from '../../../views/pages/Sample/SelectedSampleModal.jsx';
import DiscardSampleModal from '../../../views/pages/Sample/DiscardSampleModal.jsx';
import { ToastContainer } from "react-toastify";
import ReactTooltip from 'react-tooltip';
import { getPermissionsToken } from "../../../services/utils/auth";
import {timeStampFormated} from "../../../services/utils/format";
import { getUserEmployeeDataByOrganization } from "../../../services/api/Users";
import { fetchDemands } from "../../../services/api/Demand";
  var data;
  class SampleTable extends React.Component {
    state = {
        modalDiscard:false,
        modal:false,
        SelectedSample:null,
        Demands:[],
        Employees:[],
    }
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        }); 
    }
    toggle2 = () => {
        this.setState({
            modal2: !this.state.modal2
        }); 
    }
    handleButtonClick = (sample,addResult) => {
        this.setState({SelectedSample:sample});
        if(addResult){
            this.toggle();
        }else{
            this.toggle2();
        }
        
      };

    fetchName(d){
        
    } 

    async componentDidMount(){
        let {Demands, Employees} = this.state;
        const demandsRequest = await fetchDemands();
        Demands = demandsRequest.data.reduce((acc, obj) => {
            acc[obj["id"]] = {"demandNumber": obj["serviceOrder"]};
            return acc;
        }, []);

        const userRequest = await getUserEmployeeDataByOrganization();
        Employees = userRequest.data.reduce((acc, obj) => {
            acc[obj["userId"]] = {"name": obj["employeeName"]};
            return acc;            
        }, [])  

        this.setState({Demands, Employees});
        
    }
      
    render() {
        var Samplelist = this.props.Samples.map(Sample => (
            <SampleList Sample={Sample} />
        ));
        data = this.props.Samples;

        const tableData = {
            columns,
            data
          };

        const userPermissions = getPermissionsToken();
        const columns = memoize(clickHandler => [
            {
                name:'N. da Demanda',
                selector: (d =>  this.state.Demands[d.demandId] !== undefined ? this.state.Demands[d.demandId]["demandNumber"] : ""),
                sortable:true,
            },
            {
                name: 'N. da Amostra',
                selector: 'number',
                sortable: true,
            },
            {
                name: 'Coletor',
                selector: (d => this.state.Employees[d.collectedBy] !== undefined ? this.state.Employees[d.collectedBy]["name"]: ""),            
                sortable: true,
            },
            {
                name: 'Dt. Coletada',
                selector: d =>timeStampFormated(d.dateCollected),
                sortable: true,
            },
            {
                name: 'Tipo do material',
                selector: 'typeMaterial',
                sortable: true,
            
            },
            {
                name: 'Armadilha',
                selector: 'trapNumber',
                sortable: true,
            },
            {
                name: 'Situação',
                selector: d => 
                (d.status == "Analyzed") ? "Análise realizada" : "Pendente de análise",
                sortable: true,
                            
            },
            {
                name: 'Status',
                selector:d => 
                (d.status=="Analyzed") ? "Analisada" :
                (d.status=="Created") ? "Criada" : 
                (d.status=="Discarded") ? "Descartada" :
                <div>        
                    Não passível de diagnóstico
                </div>,
                sortable: true,
                            
            },
            {
                cell:(row)=>(userPermissions["7d306cb1-0df5-4fdc-a658-8f7492292172"] && userPermissions["7d306cb1-0df5-4fdc-a658-8f7492292172"].create && (row.status == "Created" || row.status == "Analyzed")  ) ? 
                    <Link to={{pathname:"/admin/AddSampleResult", data:row}}>
                        <button
                            data-tip data-for="createResultTooltip"                                                    
                            style={{'width':'60px', 'margin' : '5px'}}
                            className="btn btn-primary"
                            data-clipboard-text="fa fa-search"
                            id="tooltip204269497"
                            type="button">
                                <i className="ni ni-ruler-pencil" />

                        </button>
                        <ReactTooltip effect="solid" id="createResultTooltip">
                            <span>Cadastrar Resultado</span>
                        </ReactTooltip>
                    </Link> : "",
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
            {
                cell:(row)=>(userPermissions["7d306cb1-0df5-4fdc-a658-8f7492292172"] && userPermissions["7d306cb1-0df5-4fdc-a658-8f7492292172"].delete && row.status !== "Discarded" && row.status !== "Diagnostic not possible" && row.status !== null) ?   
                <>
                    <button
                        data-tip data-for="discardSampleTooltip"
                        style={{'width':'60px', 'margin' : '5px'}}
                        className="btn btn-danger"
                        data-clipboard-text="fa fa-trash"
                        onClick={()=>clickHandler(row,false)}
                        id="tooltip204269497"
                        type="button">
                        <i className="fa fa-trash" /> 
                    </button>
                    <ReactTooltip effect="solid" id="discardSampleTooltip">
                        <span>Descartar amostra</span>
                    </ReactTooltip>
                </>: "",
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
        ]);
        return (
            <Container className="mt--7" fluid>
                <ToastContainer/>
                <DiscardSampleModal sample={this.state.SelectedSample} OpenSampleModal={this.state.modal2}
                    toggleCallback={this.toggle2}/>  
                <SelectedSampleModal sample={this.state.SelectedSample} OpenSampleModal={this.state.modal}
                    toggleCallback={this.toggle}/>  
                <Row className="mt-12">
                    <Col className="mb-12 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Coletadas</h3>
                                    </div>
                                </Row>
                            </CardHeader>
                            <DataTable
                                noHeader
                                defaultSortField="id"
                                defaultSortAsc={false}
                                pagination
                                highlightOnHover
                                columns={columns(this.handleButtonClick)}
                                data={data}
                                noDataComponent={'Nenhum registro encontrado.'}
                            />
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
  }

  export default SampleTable
