
import React from "react";
import { Col } from "reactstrap";
import { Select } from "../../Wrappers/SelectAll";
import "./UnitFilter.css";

// reactstrap components

const GeographicFilterPresentational = (props) => {
    return (
        <Col className="linha-form col-md-4 geo-responsive-filter">
            <span className="h4 text-white">Unidade Notificadora</span>
            <Select 
                styles={{
                    control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                    option: styles =>({...styles, fontSize:"0.875rem"}),
                    valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                    singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                    placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                    menuPortal: styles => ({...styles, zIndex:9999})
                }}
                placeholder={"Selecione"}
                isClearable={true}
                options={props.unityOptions}
                value={props.unitySelected}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                onChange={(e) => {props.changeUnitSelected(e)}}
                menuPortalTarget={document.body}
            />
        </Col>
                    
    )
}


export default GeographicFilterPresentational;