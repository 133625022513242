export const getVersion = (organizationId) => {
  var versionReturn = "normal_version";

  // var versionAsync = await AsyncStorage.getItem("vitec@version_teste");

  if (
    /*(versionAsync == "SP" && organizationId == "37601ec4-d2ef-4f86-aeac-fd261e8fded4") || */ 
    organizationId == "559a16ab-5da8-41ba-96b8-6e9f55feddd8" || organizationId == "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30"
  ) {
    versionReturn = "samp_version";
  }

  const getOrganizationName = {
    "559a16ab-5da8-41ba-96b8-6e9f55feddd8": "São Paulo",
    "42a4ea68-1e69-428c-9498-e4727d30dd20": "Foz.adm",
    "24d125ef-15e6-4bd9-9111-c0b3d50a8009": "Cotia",
    "8e34767e-3673-4606-9e47-dd67edd2677d": "Foz do Iguaçu",
    "37601ec4-d2ef-4f86-aeac-fd261e8fded4": "Monteiro",
    "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30": "Goiânia",
  }

  const getOrganizationVersion = {
    "24d125ef-15e6-4bd9-9111-c0b3d50a8009": "standard",
    "559a16ab-5da8-41ba-96b8-6e9f55feddd8": "standard",
    
  };

  if (
    organizationId === "24d125ef-15e6-4bd9-9111-c0b3d50a8009" 
    || organizationId === "42a4ea68-1e69-428c-9498-e4727d30dd20"
  )
    versionReturn = "standard";
    
  return versionReturn;
};
