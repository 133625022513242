import React from "react";
import { Link } from "react-router-dom";
// reactstrap components

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import "../../../assets/css/style.css";
import FormSampleCovid from "../../../components/Forms/FormSampleCovid";
import { checkPermissionComponent } from "../../../services/utils/permission";
import "./Sample.css";

class AddSampleCovid extends React.Component {

  constructor (props) {
    super(props);
    if(!checkPermissionComponent("7d306cb1-0df5-4fdc-a658-8f7492292172", "create")){
      alert("Você não tem permissão para acessar essa funcionalidade!");
      props.history.goBack(); 
    }
  }

  render() {

    return (
      <>
        <Container className="container-flex-result" fluid>
          <Row className="mt-12">
            <Col className="mb-12 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        <Link to="/admin/amostrasColetadas"> <i className=" ni ni-bold-left bnt-voltar" /></Link>
                        Nova Amostra</h3>
                    </div>
                  </Row>
                </CardHeader>

                {/* Formulario demandas */}
                <FormSampleCovid DemandId={this.props.location.data ? this.props.location.data.demandId : ""} VisitId={this.props.location.data ? this.props.location.data.visitId : ""} />

              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AddSampleCovid;