import Cookies from 'js-cookie';

const TokenKey = 'Admin-Token';
const Organization = 'Admin-Organization';
const UserKey = 'Admin-Data';
const PermissionKey = 'Access-Data';
const CryptoJS = require('crypto-js');
export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function setOrganization(organization) {
  return Cookies.set(Organization, organization);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export const isAuth = () => {
    if(Cookies.get(TokenKey)) {
        return true
    }
    return false;
};

export function setUserData(data) {
  let UserData = {
    name : data.userName,
    organizationId : data.organization,
    userId : data.userId,
    employeeId:data.employeeId
  };
  return Cookies.set(UserKey, UserData);
}

export function setPermissions(data){
  let Permissions = {};
  data.forEach(permission => {
    let permissionObj = {
      read:permission.read,
      create: permission.create,
      delete: permission.delete,
      update: permission.update,
    }
    Permissions[permission.moduleId] = permissionObj;
  })
  Permissions = CryptoJS.AES.encrypt(JSON.stringify(Permissions), "MySecretStrongPassword").toString();
  return Cookies.set(PermissionKey, Permissions);
} 

export function getPermissionsToken(){
  let encryptedPermissions = Cookies.get(PermissionKey);
  let decryptedPermissions = CryptoJS.AES.decrypt(encryptedPermissions, "MySecretStrongPassword").toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedPermissions);
}

export function removePermissions(){
  
  return Cookies.remove(PermissionKey);
}


export function removeUserData() {
  return Cookies.remove(UserKey);
}

export function getUserData(field = null) {
  if(Cookies.get(UserKey) !== undefined)
  {
    var data = JSON.parse(Cookies.get(UserKey));
    if(field) {
      return data[field];
    } else {
      return JSON.parse(Cookies.get(UserKey));
    }
  } 

}

export function resetRoutes(routes){
  const permissions = getPermissionsToken();
  routes = routes.filter((r) => {
    if(r.moduleId == "00000000-0000-0000-0000-000000000000"){
      return true;
    }

    if(permissions[r.moduleId] && permissions[r.moduleId].read){
      return true;
    }

    return false;
  });

  return routes;
}
