import React from "react";
import { Card, CardHeader, Col, Row } from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment";
import DataTable from "react-data-table-component";

const TeamsExportTable = ({ teamsToExport }) => {
  const [filteredTeamsToExport, setFilteredTeamsToExport] =
    React.useState(teamsToExport);
  const [teamsFilters, setTeamsFilters] = React.useState({
    type: {
      label: "Todos",
      value: "all",
    },
    program: {
      label: "Todos",
      value: "all",
    },
    situation: {
      label: "Todas",
      value: "all",
    },
    origin: {
      label: "Todos",
      value: "all",
    },
  });

  React.useEffect(() => {
    /* filterTeams(); */
  }, [teamsFilters]);

  const handleFiltersChange = (filterName, newValue) => {
    const newFiltersValues = { ...teamsFilters };

    newFiltersValues[filterName] = newValue;

    setTeamsFilters(newFiltersValues);
  };

  /* const filterTeams = () => {
    const filteredTeams = teamsToExport.filter((teams) => {
      if (
        filteredTeamsToExport.number !== "" &&
        !teams.NUMERO.includes(filteredTeamsToExport.number)
      ) 
        return false;

      if (
        filteredTeamsToExport.type.value !== "all" &&
        teams.TIPO !== filteredTeamsToExport.type.label
      ) 
        return false;
      
      if (
        filteredTeamsToExport.situation.value !== "all" &&
        teams.SITUACAO !== filteredTeamsToExport.situation.label
      ) 
        return false;
      
      if (
        filteredTeamsToExport.program.value !== "all" &&
        teams.PROGRAMA !== filteredTeamsToExport.program.label
      ) 
        return false;

      if (
        filteredTeamsToExport.origin.value !== "all" &&
        teams.ORIGEM_SOLICITACAO !== filteredTeamsToExport.origin.label
      ) 
        return false;
      
      return true;
    });

    setFilteredTeamsToExport(filteredTeams);
  }; */

  const tableColumns = [
    {
      name: "Equipe",
      selector: "NOME_EQUIPE",
      sortable: true,
    },
    {
      name: "Data de criação",
      selector: "DATA_CRIACAO",
      sortable: true,
    },
    {
      name: "Programa",
      selector: "PROGRAMA",
      sortable: true,
    },
    {
      name: "Coordenadores",
      selector: "COORDENADORES",
      sortable: true,
    },
    {
      name: "Supervisores",
      selector: "SUPERVISORES",
      sortable: true,
    },
    {
      name: "Encarregados",
      selector: "ENCARREGADOS",
      sortable: true,
    },
    {
      name: "Executores",
      selector: "EXECUTORES",
      sortable: true,
    },
    {
      name: "Descrição",
      selector: "DESCRICAO",
      sortable: true,
    },
  ];

  return (
    <Row className="mt-12">
      <Col className="mb-12 mb-xl-0" xl="12">
        <Card className="shadow">
          <CardHeader className="border-0" style={{ paddingBottom: "0px" }}>
            <Row className="align-items-center">
              <div className="col">
                <h2 className="mb-0">Equipes</h2>
                <br />
              </div>
            </Row>
          </CardHeader>
          {/* <DemandsExportFilters
            demandsFilters={demandsFilters}
            handleFiltersChange={handleFiltersChange}
          /> */}
          <Row style={{ textAlign: "center", marginBottom: "1rem" }}>
            <Col>
              <CsvDownloadButton
                className="btn btn-primary"
                filename={`equipes${moment().format("YYYY-MM-DD")}`}
                data={filteredTeamsToExport}
                style={{ width: "330px" }}
              >
                <i className="fa fa-download" /> Baixar CSV das equipes
              </CsvDownloadButton>
            </Col>
          </Row>
          <DataTable
            noHeader
            defaultSortField="name"
            defaultSortAsc={true}
            pagination
            highlightOnHover
            responsive
            columns={tableColumns}
            data={filteredTeamsToExport}
            noDataComponent={"Nenhum registro encontrado."}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TeamsExportTable;
