import React, {Component} from "react";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import CardPresentational from "../../../Presentational/CardPresentational";

class MediaOvosContainer extends Component {
    state = {
        eggsAverage:0,
    }
    
    async componentDidUpdate(prevProps, prevState){
        const {data} = this.props; 
        if(data !== undefined && data !== prevProps.data){
            this.buildDataToCard();
        }
    }

    componentDidMount(){
        const {data} = this.props;
        if(data !== undefined && data.length > 0){
            this.buildDataToCard();
        }
    }

    buildDataToCard = () => {
        const {data} = this.props;

        let positives = 0;
        let quantityEggs = 0;

        for(const j in data){
            for(const i in data[j]){
                if(i !== "label"){
                    positives += data[j][i].positive;
                    quantityEggs += data[j][i].ovos;
                }
            }
        }

        const eggsAverage = positives > 0 ? Math.round(quantityEggs/positives) : 0;
            
        this.setState({eggsAverage});
        
    }

    render(){
        //construindo propriedades do presentational
        const {eggsAverage} = this.state;
        let textCard = `Média de ${ARMADILHA_INFO[this.props.trapTypeName].principalColetant}`;
        
        return(
            <CardPresentational 
                number={eggsAverage}
                text={textCard}
            />
        );
    }
}

export default MediaOvosContainer;