import request from '../utils/request';

export function fetchModules(query) {
  return request({
    url: '/Modules',
    method: 'get',
    params: query,
  });
}

export function setPermissions(Permissions) {

  let PermissionsSend = Permissions;

  return request({
    url: '/Users/Permissions',
    method: 'post',
    data: PermissionsSend,
  });
}