import React, { Component } from "react";
import CardPresentational from "../../../Presentational/CardPresentational";
import { getNotificationCases } from "../../../../services/api/Sinan";

class ConfirmedMonthContainer extends Component{

    state = {
        casesNumber:0
    }

    async componentDidUpdate(prevProps){
        const {data} = this.props;
        if(data && prevProps.data !== data){
            this.getCases();
        }
    }

    async getCases () {
        const {data} = this.props;
        const monthData = data.find((element) => element.label === "1month")
        let casesNumber = 0;

        if(monthData){
            casesNumber = monthData.confirmedNotifications;
        }

        this.setState({casesNumber});
    }

    render(){
        const {casesNumber} = this.state
        return(
            <CardPresentational 
                number={casesNumber}
                text="Casos Confirmados - 30 dias"
            />
        )
    }
}

export default ConfirmedMonthContainer