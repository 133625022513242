import React from "react";
import Header from "components/Headers/Header.jsx";
import Mapa from "../components/Territorializacao/Mapa";
import { checkPermissionComponent } from "../services/utils/permission";

const NovaTerritorializacao = (props) => {
  React.useEffect(() => {
    if (!checkPermissionComponent("dbd03c99-ac3c-4dd4-8964-cc066d77d403", "create")) {
      alert("Você não tem permissão para acessar essa funcionalidade!");
      props.history.goBack(); 
    }
  }, []);

  return (
    <>
      <Header />
      <Mapa />
    </>
  );
};

export default NovaTerritorializacao;
