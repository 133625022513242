import React, { useEffect } from "react";
import { LayersControl, Map, Marker, TileLayer } from "react-leaflet";
import Leaflet from "leaflet";
import { putTrapByNumber } from "../../services/api/Trap";
import { getUserData } from "../../services/utils/auth";
import { toast, ToastContainer } from "react-toastify";
import { ARMADILHA_INFO } from "../../constants/ArmadilhaConstant";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { Select } from "../Wrappers/SelectAll";
import { getProjects, postTrapHistoryProject } from "../../services/api/project";

const UpdateTrapPositionModal = ({
  position,
  trap,
  trapTypeOptions,
  employeeList,
  showEmployeeFields,
  territorializationsList,
  modalOpened
}) => {
  const [trapPosition, setTrapPosition] = React.useState(position);
  const [address, setAddress] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [project, setProject] = React.useState(null);
  const [projectOptions, setProjectOptions] = React.useState("");
  const [mapZoom, setMapZoom] = React.useState(16);
  const [trapTypeName, setTrapTypeName] = React.useState("");
  const [insideTerritorialization, setInsideTerritorialization] = React.useState(trap.insideTerritorialization ? trap.insideTerritorialization : "");
  const [selectedTrapType, setSelectedTrapType] = React.useState(null);
  const [whoInstallId, setWhoInstallId] = React.useState(null);
  const [uvisOptions, setUvisOptions] = React.useState([]);
  const key = "AIzaSyCdkHjfiiztTeKSSuvfauQERlAJGFONeKo";
  const mapRef = React.useRef();

  useEffect(() => {
    if(modalOpened){
      if (!showEmployeeFields) return;
  
  
      var projects = getProjects();
  
      projects.then(response => {
        var projectOptionsFormated = [];
        if(response.data) {
          response.data.map((project) => {
            projectOptionsFormated.push({
              value: project.id,
              label: project.name
            })
          })
        }
        setProjectOptions(projectOptionsFormated);
      })
  
      console.log("trap", trap)
  
      setAddress(trap.address);
      setNumber(trap.number);
      setTrapTypeName(trap.trapTypeName);
      setSelectedTrapType(trap.trapTypeId);
      var projects = null;
      if(trap.projects && trap.projects.length > 0) {
        if(!projects) projects = [];
  
        trap.projects.map(project => {
          projects.push({
            value: project.id,
            label: project.name
          })
        })
        
      }
      setProject(projects)
      setWhoInstallId(trap.whoInstallId);
  
      const map = mapRef.current.leafletElement;
      map.on("zoomend", () => {
        setMapZoom(map.getZoom());
      });
    }
  }, [trap && (trap.address || trap.number || trap.trapTypeName || trap.whoInstallId || trap.trapTypeId)]);

  React.useEffect(() => {
    if(getUserData("organizationId") !== "559a16ab-5da8-41ba-96b8-6e9f55feddd8" || !territorializationsList || territorializationsList.length === 0 )
      return;

    const uvisTerritorializations = territorializationsList.find(({ name }) => name === "UVIS");

    if(uvisTerritorializations)
      setUvisOptions(uvisTerritorializations.options);

  }, [territorializationsList]);

  const updateLocation = async () => {
    const organizationId = getUserData("organizationId");

    const addressArray = address.split(",");

    const addressSubpremisse = addressArray.slice(0, -1).join(",").trim();
    const addressNumber = addressArray[addressArray.length - 1].trim();

    var arrayProjects = [];

    if(project && project.length > 0) {
      project.map((objProject) => {
        arrayProjects.push(objProject.value)
      })
    }

    const projectsToPostInHistory = {
      OrganizationId: organizationId,
      TrapHistoryId: trap.lastHistorie.id,
      ProjectsId: arrayProjects
    }

    postTrapHistoryProject(projectsToPostInHistory)

    const trapToUpdateData = {
      OrganizationId: organizationId,
      TrapNumber: trap.number,
      Latitude: trapPosition[0],
      Longitude: trapPosition[1],
      WhoInstallId: whoInstallId,
      TrapNumberToUpdate: number,
      AddressNumber: addressNumber,
      AddressSubpremisse: addressSubpremisse,
      TrapTypeId: selectedTrapType,
      GeoreferencedByAddress: false,
      insideTerritorialization,
    };

    await putTrapByNumber(trapToUpdateData);

    toast.info(
      "Alterado com sucesso! Recarregue a página para ver a modificação."
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-9">
          {showEmployeeFields ? (
            <>
              <p className="h2">
                <span className="text-black">Atividade: </span>
                <Select
                    styles={{
                        control: styles => ({ ...styles, minHeight: "2.90rem", borderRadius: "0.370rem" }),
                        option: styles => ({ ...styles, fontSize: "0.875rem" }),
                        valueContainer: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        singleValue: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        placeholder: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        menuPortal: styles => ({ ...styles, zIndex: 9999 })
                    }}
                    value={project}
                    isMulti={true}
                    placeholder={"Selecione"}
                    isClearable={true}
                    blurInputOnSelect={false}
                    closeMenuOnSelect={false}
                    onChange={(e) => setProject(e)}
                    options={projectOptions}
                />
              </p>
              <p className="h2">
                <span className="text-black">Tipo de Armadilha: </span>
                <select
                  value={selectedTrapType ? selectedTrapType : ""}
                  name={"trapType"}
                  className="form-control"
                  onChange={(e) => setSelectedTrapType(e.target.value)}
                >
                  if(!selectedTrapType)
                  {
                    <option key={"Selecione"} value={""}>
                      Selecione
                    </option>
                  }
                  {trapTypeOptions.map((trapType) => {
                    if (trapType.name) {
                      return (
                        <option key={trapType.id} value={trapType.id}>
                          {ARMADILHA_INFO[trapType.name].apelido}
                        </option>
                      );
                    }
                  })}
                </select>
              </p>
              {/* <Select/> */}
              <p className="h2">
                <span className="text-black">Número: </span>
                <input
                  className="form-control"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  type="text"
                />
              </p>
              <p className="h2">
                <span className="text-black">Endereço: </span>
                <input
                  className="form-control"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                />
              </p>
              <p className="h2 text-black">
                <span className="text-black">Quem instalou: </span>
                <select
                  value={whoInstallId ? whoInstallId : ""}
                  name={"whoInstallId"}
                  className="form-control"
                  onChange={(e) => setWhoInstallId(e.target.value)}
                >
                  {employeeList.map((employee) => {
                    if (employee.value) {
                      return (
                        <option key={employee.value} value={employee.value}>
                          {employee.label}
                        </option>
                      );
                    }
                  })}
                </select>
              </p>
              {getUserData("organizationId") === "559a16ab-5da8-41ba-96b8-6e9f55feddd8" ?
                <p className="h2 text-black">
                  <span className="text-black">UVIS de pertencimento:</span>
                  <select
                    value={insideTerritorialization}
                    name="insideTerritorialization"
                    className="form-control"
                    onChange={({ target }) => setInsideTerritorialization(target.value)}
                  >
                    <option value="">Selecione uma opção</option>
                    {uvisOptions.map(({ label }, index) => {
                      return (
                        <option value={label} key={index}>{label}</option>
                      )
                    })}
                  </select>
                </p>
                :
                null
              }
            </>
          ) : null}
        </div>
      </div>
      <div>{`Lat: ${trapPosition[0]}, Long: ${trapPosition[1]}`}</div>
      <Map center={trapPosition} zoom={mapZoom} ref={mapRef}>
        <LayersControl>
          <LayersControl.BaseLayer checked name="Normal">
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satélite">
            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{ KEY: key }}
              type={"hybrid"}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <DraggableMarker
          initalPosition={trapPosition}
          setPosition={setTrapPosition}
        />
      </Map>
      <button
        className="btn mt-2"
        onClick={() => {
          updateLocation();
        }}
      >
        Salvar
      </button>
    </>
  );
};

const DraggableMarker = ({ initalPosition, setPosition }) => {
  const markerRef = React.useRef(null);

  const handleMarkerOnDragEnd = (target) => {
    if (!target) return;

    const newLatitude = target._latlng.lat.toFixed(7);
    const newLongitude = target._latlng.lng.toFixed(7);

    setPosition([newLatitude, newLongitude]);
  };

  const trapIcon = Leaflet.icon({
    iconUrl: "https://i.ibb.co/q09c9H7/g-int2care.png",
    iconSize: [20, 20],
  });

  return (
    <Marker
      draggable={true}
      ondragend={({ target }) => handleMarkerOnDragEnd(target)}
      position={initalPosition}
      ref={markerRef}
      icon={trapIcon}
    />
  );
};

export default UpdateTrapPositionModal;
