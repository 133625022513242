import React, {useState, useEffect, useRef} from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import PieCardPresentational from "../../Presentational/PieCardPresentational";

const LineIBChartContainer  = ({data}) => {
    const [chartDataSets, setChartDataSets] = useState([{}]);
    const textCardHeader = useRef(`${data.label}`);

    useEffect(() => {
        if(data){
            buildDataSets(data);
        }
    }, [data])

    async function buildDataSets (data){
    
        const colors = ["#5351F0", "#F0905D", "#469DF0", "#F0CD2E", "#3AF0E7", "#F57D51", "#C4F56A", "#F5BC5D"];
        const dataset = [];

        let i = 0;
        for(let name in data){

            if(name !== "label"){
                const datasetObj = {
                    name: name.toUpperCase(),
                    value: parseFloat(data[name].toFixed(2)),
                    color:colors[i]
                }

                dataset.push(datasetObj);
            }

            i++;
        }
        setChartDataSets(dataset)
    }

    const style={
        cardHeaderText: {
            color:"black",
            fontWeight:"bold",
            fontSize:"0.97rem"
        },
    }

    const buildChartElement = () => (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Pie 
                    dataKey="value" 
                    data={chartDataSets}
                    labelLine={false}
                >
                    {chartDataSets.map((entry, index) => (
                        <Cell key={`deposityCell-${index}`} fill={entry.color}/>
                    ))}
                </Pie>
                <Tooltip/>
                <Legend align="center" verticalAlign="top"/>
            </PieChart>
        </ResponsiveContainer>
    )
    
    return(
        <PieCardPresentational
            CardId = {"ITRChartContainer"}
            style={style}
            textCardHeader={textCardHeader.current}
            element={buildChartElement()}
        />
    );
}


export default LineIBChartContainer;