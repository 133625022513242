/* Todas as validações devem ser definidas aqui */

/**
 * Validar uma URL valida
 * @param {String} textval
 * @return {Boolean}
 */
export function validateURL(textval) {
    const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    return urlregex.test(textval);
  }
  
  /**
   * Validar uma string toda minuscula
   * @return {Boolean}
   * @param {String} str
   */
  export function validateLowerCase(str) {
    const reg = /^[a-z]+$/;
    return reg.test(str);
  }
  
  /**
   * Validar uma string toda maiscula
   * @return {Boolean}
   * @param {String} str
   */
  export function validateUpperCase(str) {
    const reg = /^[A-Z]+$/;
    return reg.test(str);
  }
  
  /**
   * Checar se uma string possui somente letra
   * @param {String} str
   * @param {Boolean}
   */
  export function validatAlphabets(str) {
    const reg = /^[A-Za-z]+$/;
    return reg.test(str);
  }
  
  /**
   * Validar um endereco de email
   * @param {String} email
   * @return {Boolean}
   */
  export function isEmailValid(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  