import request from "../utils/request";
//import request from "../utils/requestTests/requestTestDemand";

import { getUserData } from "../../services/utils/auth";

export function getProjects() {
    var organizationId = getUserData("organizationId");

    return request({
        url: "/Projects/" + organizationId,
        method: "get",
        headers: new Headers({
        "Content-Type": "application/json",
        }),
    });
}

export function postTrapHistoryProject(body) {
    return request({
        url: "/Projects/TrapHistory",
        method: "post",
        data: body,
        headers: new Headers({
        "Content-Type": "application/json",
        }),
    });
}