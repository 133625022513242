import React from "react";

import { Button, Col, Row } from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";
import LoadingSpin from "react-loading-spin";

const ManagementFilters = ({
  filtersValues,
  handleFiltersChange,
  trapTypes,
  territorializations,
  fetchData,
  isTerritorializationsReportDataLoading,
  loadingProgress,
}) => {
  const handleTypeLayerChange = (value) => {
    handleFiltersChange(
      "typeLayers",
      !value || value.length === 0 ? [] : value
    );

    if (!value || value.length === 0)
      handleFiltersChange("territorializations", []);
    else
      handleFiltersChange("territorializations", value[0].territorializations);
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
  };

  const typeLayersList =
    territorializations && territorializations.length > 0
      ? territorializations.map(({ label, name, territorializations }) => {
          const formatedTerritorializations = territorializations.map(
            ({ id, name }) => ({ label: name, value: id })
          );

          return {
            label,
            name,
            territorializations: formatedTerritorializations,
          };
        })
      : [];

  return (
    <>
      <Row className="mb-2">
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Tipo de Armadilha</span>
          <Select
            styles={selectStyles}
            placeholder={"Selecione"}
            isClearable={true}
            value={filtersValues.trapType}
            options={trapTypes}
            onChange={(e) =>
              handleFiltersChange(
                "trapType",
                !e
                  ? {
                      label: "Selecione",
                      value: "",
                    }
                  : e
              )
            }
          />
        </Col>
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Data (Período)</span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
            }}
          >
            <div style={{ width: "45%" }}>
              <input
                type="date"
                name="beginDate"
                id="beginDate"
                className="form-control"
                value={filtersValues.beginDate}
                onChange={({ target }) =>
                  handleFiltersChange("beginDate", target.value)
                }
              />
            </div>
            <div
              className="h4 text-white"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span>Até</span>
            </div>
            <div style={{ width: "45%" }}>
              <input
                type="date"
                name="endDate"
                id="endDate"
                className="form-control"
                value={filtersValues.finalDate}
                onChange={({ target }) =>
                  handleFiltersChange("finalDate", target.value)
                }
              />
            </div>
          </div>
        </Col>
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Territorialização</span>
          <Select
            styles={selectStyles}
            placeholder={"Selecione"}
            isClearable={true}
            options={territorializations}
            value={filtersValues.territorializations}
            isMulti={true}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            onChange={(e) =>
              handleFiltersChange(
                "territorializations",
                !e || e.length === 0 ? [] : e
              )
            }
            menuPortalTarget={document.body}
          />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Delimitadores de Área</span>
          <Select
            styles={selectStyles}
            placeholder={"Selecione"}
            isClearable={true}
            options={typeLayersList}
            value={filtersValues.typeLayers}
            isMulti={true}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            onChange={(e) => handleTypeLayerChange(e)}
            menuPortalTarget={document.body}
          />
        </Col>
        <Col className="col-md-4 visit-responsive-filter"></Col>
        <Col className="col-md-4 visit-responsive-filter"></Col>
      </Row>
      <Row>
        <Col className="col-md-4 visit-responsive-filter"></Col>
        <Col className="col-md-4 visit-responsive-filter">
          {isTerritorializationsReportDataLoading ? (
            <Button color="primary" disabled={true}>
              <span>
                Calculando dados, por favor, aguarde...{" "}
                {Math.round(loadingProgress)}%
              </span>{" "}
              <LoadingSpin primaryColor="#fff" size={17} />
            </Button>
          ) : (
            <Button color="primary" onClick={() => fetchData()}>
              Filtrar
            </Button>
          )}
        </Col>
        <Col className="col-md-4 visit-responsive-filter"></Col>
      </Row>
    </>
  );
};

export default ManagementFilters;
