import React from 'react'
import Cookies from 'js-cookie';
import {
  CircleMarker,
  Map,
  Popup,
  TileLayer,
  FeatureGroup,
  LayersControl,
  withLeaflet,
  Marker,
  Polygon
} from'react-leaflet';
import Control from 'react-leaflet-control';
import FullscreenControl from 'react-leaflet-fullscreen';
import PrintControlDefault  from 'react-leaflet-easyprint';
import 'react-leaflet-fullscreen/dist/styles.css';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';
const key = 'AIzaSyCdkHjfiiztTeKSSuvfauQERlAJGFONeKo';
const { BaseLayer, Overlay } = LayersControl;
const PrintControl = withLeaflet(PrintControlDefault);
var keyCount = 0;
function getKey(){
    return keyCount++;
}

const MyMarkersList = ({markers }) => {
    const items = markers.map(({ key, ...props }) => (
      <MyPopupMarker key={getKey()} {...props} />
    ))
    return <React.Fragment>{items}</React.Fragment>
  }

const MyPopupMarker = ({ name, position, path, ReadDate, type, incomingIcon}) => {
    var icon = null;
    if(ReadDate!=null || ReadDate != "" && ReadDate !== undefined ){
        var dataAtual = new Date();
        var date = new Date(ReadDate);
        var diff = Math.abs(dataAtual.getTime() - date.getTime());
        const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        //console.log("Quantidade de dias eh " + days);
        if (days > 100) {
            icon = RedIn2CareIcon;
        } else if (days > 10 && days < 100) {
            icon = BlackIn2CareIcon;
        } else {
            icon = GreenIn2CareIcon;
        }  
    }

    switch(incomingIcon){
      case "RedestacaoDisseminadoraLarvicidaIcon":
        icon = RedIn2CareIcon;
      break;
      case "GreenestacaoDisseminadoraLarvicidaIcon":
        icon = GreenIn2CareIcon;
      break;
      case "BlackestacaoDisseminadoraLarvicidaIcon":
        icon = BlackIn2CareIcon;
      break;
      case "RedarmadilhaMosquitoAdulto2Icon":
        icon = RedIn2MonitorIcon;
      break;
      case "GreenarmadilhaMosquitoAdulto2Icon":
        icon = GreenIn2MonitorIcon;
      break;
      case "BlackarmadilhaMosquitoAdulto2Icon":
        icon = BlackIn2MonitorIcon;
      break;
      case "RedarmadilhaOvosIcon":
        icon = RedOvitrampaIcon;
      break;
      case "GreenarmadilhaOvosIcon":
        icon = GreenOvitrampaIcon
      break;
      case "BlackarmadilhaOvosIcon":
        icon = BlackOvitrampaIcon;
      break;
      case "BlackarmadilhaMosquitoAdultoIcon":
        icon = BlackAdultrapIcon;
      break;
      case "GreenarmadilhaMosquitoAdultoIcon":
        icon = GreenAdultrapIcon;
      break;
      case "RedarmadilhaMosquitoAdultoIcon":
        icon = RedAdultrapIcon;
      break;
      
    }

    if(icon == null){
      switch(type){
        case "basictrap":
          icon = BlackOvitrampaIcon;
          break;
        case "visit":
          icon = VisitMarker;
          break;
        case "resident":
          icon = ResidentMarker;
          break
      }
    }
    

  return <Marker icon={icon} position={position} title={name}>
        <Popup>{name}</Popup>
    </Marker>
}  


const L = require('leaflet');
const BlackAdultrapIcon = L.icon({
  iconUrl: require('./Traps/TrapIcons/Adultrap/k-adultrap.png'),
  iconSize: [20,20]
});
const GreenAdultrapIcon = L.icon({
  iconUrl: require('./Traps/TrapIcons/Adultrap/g-adultrap.png'),
  iconSize: [20,20]
});
const RedAdultrapIcon = L.icon({
  iconUrl: require('./Traps/TrapIcons/Adultrap/r-adultrap.png'),
  iconSize: [20,20]
});
const GreenIn2CareIcon = L.icon({
    iconUrl: require('./Traps/TrapIcons/Int2care/g-int2care.png'),
    iconSize: [20,20]
  });
  
  const RedIn2CareIcon = L.icon({
    iconUrl: require('./Traps/TrapIcons/Int2care/r-int2care.png'),
    iconSize: [20,20]
  });

  const BlackIn2CareIcon = L.icon({
    iconUrl: require('./Traps/TrapIcons/Int2care/k-int2care.png'),
    iconSize: [20,20]
  })

  const GreenIn2MonitorIcon = L.icon({
    iconUrl: require('./Traps/TrapIcons/Int2caremonitor/g-in2monitor.png'),
    iconSize: [20,20]
  });
  
  const RedIn2MonitorIcon = L.icon({
    iconUrl: require('./Traps/TrapIcons/Int2caremonitor/r-in2monitor.png'),
    iconSize: [20,20]
  });

  const BlackIn2MonitorIcon = L.icon({
    iconUrl: require('./Traps/TrapIcons/Int2caremonitor/k-in2monitor.png'),
    iconSize: [20,20]
  })

  const GreenOvitrampaIcon = L.icon({
    iconUrl: require('./Traps/TrapIcons/Ovitrampa/g-ovitrampa.png'),
    iconSize: [20,20]
  });
  const RedOvitrampaIcon = L.icon({
    iconUrl: require('./Traps/TrapIcons/Ovitrampa/r-ovitrampa.png'),
    iconSize: [20,20]
  });
  
  const VisitMarker = L.icon({
    iconUrl: require('./Traps/TrapIcons/visita.png'),
    iconSize: [38,95],
    iconAnchor: [22,94],
    popupAnchor: [-3, -76],
    shadowUrl: require('./Traps/TrapIcons/sombra.png'),
    shadowSize: [50, 64],
    shadowAnchor: [4, 62]
  });
  
  const ResidentMarker = L.icon({
    iconUrl: require('./Traps/MiscIcons/residentIcon.png'),
    iconSize: [38,95],
    iconAnchor: [22,94],
    popupAnchor: [-3, -76],
    shadowUrl: require('./Traps/TrapIcons/sombra.png'),
    shadowSize: [50, 64],
    shadowAnchor: [4, 62]
  });
  const BlackOvitrampaIcon = L.icon({
    iconUrl: require('./Traps/TrapIcons/Ovitrampa/ovitrampa1.png'),
    iconSize: [20,20]
  });
export default class GenericMap extends React.Component{
  state  = {
     PointList : [],
     center: null,
    }

  constructor(props){
    super(props);
  }    

  componentDidMount(){
    const Organization = 'Admin-Organization';

    var centerLocation = [];

    if(Cookies.get(Organization)){
        var organization = JSON.parse(Cookies.get(Organization));
        var centerLocation = organization.stateSub.replace(/\s/g, '').split(",");
    }

    if(centerLocation.length > 0){
        var validCenterLocation = this.validLatLong(centerLocation[0], centerLocation[1]);
        if(validCenterLocation){
            this.setState({center: [centerLocation[0], centerLocation[1]]});
        }
    }
    
    this.setState({PointList:this.props.List});
  }

  validLatLong(lat, long) {
    if (lat === null || long === null || lat === undefined || long === undefined) {
        return false;
    }

    lat = Number(lat);
    long = Number(long);

    if (!(lat >= -90 && lat <= 90)) {
        return false;
    }

    if (!(long >= -180 && long <= 180)) {
        return false;
    }

    return true;
  }

  componentDidUpdate(prevProps){
    const {List, territorializations} = this.props;
    const {center} = this.state;

    //criando lógica para definir como centro do mapa o primeiro polígono
    if(!center && territorializations && territorializations.length > 0){
      for(const index in territorializations){
          if(territorializations[index].territorializationList.length > 0){
            let firstPolygonCoordinate = territorializations[index].territorializationList[0].coordinate;
            this.setState({center: [firstPolygonCoordinate.y, firstPolygonCoordinate.x]});
            break;
          }
      }
    }

    //criando lógica para definir como centro do mapa o primeiro ponto do primeiro grupo de pontos
    if(!center && List && List.length > 0){  
      if(List[0].value.length > 0){
        let lastIndex = List[0].value.length - 1;
        let lastPoint = List[0].value[lastIndex].position;
        this.setState({center: lastPoint});
      }
    }

    if(List && List !== prevProps.List) {
      this.setState({PointList:this.props.List});
    }
  }

  renderPolygonsGroup = ({LayerTitleText = "", PolygonFillColor = true, PolygonColor = true}) => (
    <LayersControl position="topright">
        {LayerTitleText !== "" && 
            <BaseLayer checked name={LayerTitleText}>
                <TileLayer
                    attribution=''
                    url=""
                />
            </BaseLayer>
        }
        
        {this.props.territorializations.map((Group) => (
            <Overlay name={Group.name} >
                <FeatureGroup fillOpacity={0.2} >
                    {Group.territorializationList.map(polygon => {
                        const coordinates = polygon.coordinates.map(({x, y}) => [y, x])
                        return(
                            <Polygon 
                              color={PolygonColor ? polygon.color || "#03a1fc" : "#000"} 
                              opacity={1} fillColor={PolygonFillColor ? polygon.color || "#03a1fc" : "transparent"} 
                              positions={coordinates} 
                            >
                              {polygon.popup}
                            </Polygon>
                        )
                    })}
                </FeatureGroup>
            </Overlay>
        ))}
    </LayersControl>
  )

  buildLegend = () => (
      <Control position={"bottomleft"}>
          <div style={{ maxWidth: 250, maxHeight: 300, backgroundColor: "#fff", paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}>
              <div style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>
                  <span style={{ fontSize: '17px' }}>{this.props.Legend}</span>
              </div>
          </div>
      </Control>
  );

  render() {
    var count = 0;
    return (
      <div id="mapid">
          <Map style={{'height' : '32.3rem', "borderRadius":4}} onZoomEnd={this.props.changeCluster} center={this.state.center || [-22.848046, -43.321953]} zoom={12}>
            <FullscreenControl 
              position="topleft"
              title="Tela Cheia"
              titleCancel="Sair da Tela Cheia"
            />
            <PrintControl position="topleft" sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={false} title="Export as PNG" exportOnly />

            <LayersControl position="topright">
              <BaseLayer checked name="Normal">
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </BaseLayer>
              <BaseLayer name="Satélite">
                <ReactLeafletGoogleLayer googleMapsLoaderConf={{KEY: key}} type={'hybrid'} />
              </BaseLayer>

              {this.props.hasHeatMap !== undefined && this.state.PointList.length > 0 && this.state.PointList[0] ? 
                <Overlay name="Mapa de Calor" checked>
                  <HeatmapLayer
                    points = {this.state.PointList[0].value}
                    longitudeExtractor = {m=> m.position[1]}
                    latitudeExtractor = {m=> m.position[0]}
                    intensityExtractor={m => parseFloat(m.intensity)}
                  />
                </Overlay>
              :null}

              {this.state.PointList.length > 0 && this.state.PointList[0] && this.state.PointList.map(Set =>{
                  return(
                      <Overlay key={`overlay-${Set.key}-${this.props.maxClusterRadius}`} checked={Set.checked} name={Set.key} >
                          <MarkerClusterGroup color="black" maxClusterRadius={this.props.maxClusterRadius || 20}>
                              <MyMarkersList markers={Set.value} />
                          </MarkerClusterGroup>
                      </Overlay>
                  )
              })}
            </LayersControl>

            {/* Colocando layer delimitador */}
            {this.props.territorializations !== undefined && this.props.territorializations.length > 0 && this.renderPolygonsGroup({
              LayerTitleText:"Delimitador de Áreas",
              PolygonFillColor:false,
              PolygonColor:false,
            })}

            {this.props.Legend && this.buildLegend()}
        </Map>
      </div>
    );
  }
}
