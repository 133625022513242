import React, {memo, useState} from "react";
import { useEffect } from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, LabelList } from "recharts";
import { useCurrentPng } from "recharts-to-png";
import CardPresentational from "../../../../Presentational/Sinan/CardPresentational";
import CustomTooltip from "../Tooltip/CustomTooltip";

const GenderNotificationsBar = ({data}) => {
    const [dataSet, setDataSet] = useState();
    const [getpng, {ref}] = useCurrentPng();

    useEffect(() => {
        if(data){
            buildChartDataSet(data).then(responseBuild => {
                setDataSet(responseBuild);
            });
        }
    }, [data])


    async function buildChartDataSet(data){
        const dataSet = [];
        for (let ano in data){
            let menNotificatedNumbers = 0;
            let womenNotificatedNumbers = 0;
            let undefinedNotificatedNumbers = 0;

            for(let index in data[ano]){
                if(index !== "label" && index !== "checked"){
                    menNotificatedNumbers += data[ano][index].menNotifications;
                    womenNotificatedNumbers += data[ano][index].womenNotifications;
                    undefinedNotificatedNumbers += data[ano][index].undefinedGenderNotifications;
                }
            }

            const dataObject = {
                label: data[ano]["label"] || "Período estipulado",
                Masculino: menNotificatedNumbers,
                Feminino: womenNotificatedNumbers,
                "Não Binarie": undefinedNotificatedNumbers
            }
            
            dataSet.push(dataObject);
        }

        return dataSet;
    }

    const buildChartElement = () => (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={dataSet}
                margin={{
                    top:30,
                    right:15
                }}
                ref={ref}
            >
                <text x={"50%"} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
                    <tspan fontSize="16" fontWeight={"bolder"}>Casos Notificados </tspan>
                </text>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" style={{fontSize:12}}/>
                <YAxis style={{fontSize:12}} interval={0}/>
                <Tooltip content={<CustomTooltip/>}/>
                <Legend align="center" verticalAlign="top" />
                <Bar dataKey="Feminino" fill="#c7d489" />
                <Bar dataKey="Masculino" fill="#6ba0a0"/>                       
            </BarChart>
        </ResponsiveContainer>
    )

    //construindo propriedades do presentational

    const style={
        cardHeaderText: {
            color:"black",
            fontWeight:"bold",
            fontSize:"0.97rem"
        },
    }

    const textCardHeader = "Número de notificações por sexo";
    return(
        <CardPresentational
            CardId="GenderNotificationsBar"
            style={style}
            textCardHeader={textCardHeader}
            element={buildChartElement()}
            getpng={getpng}
        />
    );
}

export default GenderNotificationsBar;