import React from "react";

// core components
import Header from "components/Headers/Header.jsx";
import "../../../assets/css/style.css";
import FilterReportCovid from "../../../components/Navbars/FilterReportCovid";
import CovidTable from "./CovidTable";
import {fetchResidents,getSymptomaticsByDay} from "../../../services/api/Covid";
import {fetchUsers} from "../../../services/api/Users";
import {fetchEmployeeData} from "../../../services/api/Employee";
import {getUserData} from "../../../services/utils/auth";
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import SymptomaticsByDayChart from "./CovidCharts/SymptomaticsByDayChart";
import ResidentMap from './MapsComponents/ResidentMap.jsx';
import { checkPermissionComponent } from "../../../services/utils/permission";
import Loader from "../../../components/Misc/loader";
class CovidReport extends React.Component {
  constructor(props){
    super(props);
    if(!checkPermissionComponent("2a309995-15bb-4a85-af67-f50eb6642d89", "read")){
      alert("Você não tem permissão para acessar essa funcionalidade!");
      props.history.goBack(); 
    }
  }
  state={
    loading:true,
    Residents:[],
    ResidentsFilter:[],
    CollectorsList:[],
    isOpen1 : false,
    isOpen2 : false,
    isOpen3 : false,
    filter:{
      onlySymptomatics:false,
      typeReport:"",
      startDate:null,
      endDate:null,
      typeForm:"",
      organizationId:getUserData("organizationId"),
      TeamId:"00000000-0000-0000-0000-000000000000",
      CollectorId:"00000000-0000-0000-0000-000000000000"
    },
  }
  componentDidMount() {
    const ResidentRequest = fetchResidents(this.state.filter);
    ResidentRequest.then(response => {
        if(response) {
            return response.data
        }
        throw new Error("Erro ao contatar servidor");
      }).then(data => {
        data = this.getCollectorName(data,true);
        this.setState({Residents : data});
        this.setState({ResidentsFilter : data});
        
      });
    const getSymptomaticsByDayRequest = getSymptomaticsByDay(this.state.filter);
    getSymptomaticsByDayRequest.then(response => {
      if(response) {
          return response.data
      }
      throw new Error("Erro ao contatar servidor");
    }).then(data => {
      this.setState({SymptomaticsByDay:data.symptomaticsByDayData});
      this.setState({loading2:false});
    });
      
 }

  getCollectorName = (data,loadPage) =>{

    var usersRequest = fetchUsers();
    var usersList = [];
    var CollectorData = [];
    var Collector;
    usersRequest.then(users =>{
      usersList = users.data;

      
      data.map((user, id) =>{
        usersList.forEach(element => {
          if(user.collectorId == element.id){
            let employeeId = element.employeeId;
            let employeeRequest = fetchEmployeeData(employeeId);
            
            employeeRequest.then(response =>{
              data[id].collectorName = response.data.name;
              Collector = {id:element.id, name:response.data.name}
              if(!CollectorData.some(v=> v.id ===Collector.id)){
                CollectorData = CollectorData.concat(Collector);
              }
              this.setState({loading:false});
              if(loadPage){
                this.setState({CollectorsList:CollectorData});
              }
              
            });
          }
        });
        
        
      });
    }).then(() => {
      this.setState({});
    })
    return data;
  }
 callbackFunction = (ResidentFilteredArray)=>{
    this.getCollectorName(ResidentFilteredArray,false);
    this.setState({ResidentsFilter: ResidentFilteredArray})
  }
  callbackChartFunction = (chartFiltered)=>{
    this.setState({SymptomaticsByDay: chartFiltered})
  }
  toggle1 = () => this.setState({isOpen1 : !this.state.isOpen1,
                                isOpen2:false,
                                isOpen3:false});
  toggle2 = () => this.setState({isOpen2 : !this.state.isOpen2,
                                isOpen1:false,
                                isOpen3:false});
  toggle3 = () => this.setState({isOpen3 : !this.state.isOpen3,
                                isOpen2:false,
                                isOpen1:false});
  render() {
    

    return (      
      <>
        <Header />
        
          {(this.state.loading || this.state.loading2) ? 
            <Loader /> :
            <div>                 
              
              <FilterReportCovid CollectorList={this.state.CollectorsList} Residents={this.state.Residents} 
              parentCallback = {this.callbackFunction} parentChartCallback = {this.callbackChartFunction}/>
              
              <Button color="primary" onClick={this.toggle1} style={{ marginBottom: '1rem' }}>Exportar Visitas</Button>
              <Collapse isOpen={this.state.isOpen1}>
                <Card>
                  <CardBody>
                    <CovidTable Residents={this.state.ResidentsFilter}/>    
                  </CardBody>
                </Card>
              </Collapse>
              <Button color="primary" onClick={this.toggle2} style={{ marginBottom: '1rem' }}>Gráficos</Button>
              <Collapse isOpen={this.state.isOpen2}>
                <Card>
                  <CardBody>
                  {(this.state.SymptomaticsByDay && this.state.SymptomaticsByDay.length>0) ? 
                  <>                 
                     <div className="w-100 text-center">
                      <span className="h1 ">Sintomáticos por dia</span>                   
                      <SymptomaticsByDayChart dataCovid={this.state.SymptomaticsByDay}/>    
                      </div>                    
                  </>:
                    <span>No data is loaded...</span>     
                                                   
                  }
                  </CardBody>
                </Card>
              </Collapse>
              <Collapse isOpen={this.state.isOpen3}>
              
                <Card>
                  
                  <CardBody>
                   
                    
                  </CardBody>
                </Card>
              </Collapse>
              <hr style={{"border-top": "3px dotted #bbb"}}/>
              <ResidentMap residents={this.state.ResidentsFilter}/>
              {/* <div className="d-flex justify-content-center">
                {this.state.ResidentsFilter.length > 0 ? 
                  <div className="w-100 text-center">
                  
                    <span className="h1 ">Mapa de Residentes</span>
                    <ResidentMap residents={this.state.ResidentsFilter}/>
                  </div>
                :null}
              </div> */}
              
            </div>                                    
          }
      </>
    );
  }
}

export default CovidReport;
