import React from "react";
import DataTable from "react-data-table-component";
import { Card, CardHeader, Col, Row } from "reactstrap";
import TableLoadingSkeleton from "../../../../components/ui/Loading/TableLoadingSkeleton";

const GlobalVisionTable = ({ trapsByColorStatusData, organizationsData }) => {
  const [tableData, setTableData] = React.useState([]);

  React.useEffect(() => {
    if (!trapsByColorStatusData || trapsByColorStatusData.length === 0) return;

    const formatedTableData = trapsByColorStatusData.map(
      ({
        organizationId,
        afterSixtyDays,
        betweenThirtyOneAndSixtyDays,
        upToThirtyDays,
      }) => {
        const organizationData = organizationsData.find(
          (organization) => organization.organizationId === organizationId
        );

        const installedTraps =
          upToThirtyDays + betweenThirtyOneAndSixtyDays + afterSixtyDays;

        return {
          city: organizationData.name,
          totalSoldTraps: organizationData.totalSoldTraps,
          totalInstalledTraps: installedTraps,
          afterSixtyDays,
          betweenThirtyOneAndSixtyDays,
          upToThirtyDays,
          availableForInstall: organizationData.totalSoldTraps - installedTraps,
        };
      }
    );

    setTableData(formatedTableData)
  }, [trapsByColorStatusData]);

  const tableColumns = [
    {
      name: "Município",
      selector: "city",
      sortable: true,
    },
    {
      name: "Total de ADI's comercializadas",
      selector: "totalSoldTraps",
      sortable: true,
    },
    {
      name: "Total de ADI's instaladas",
      selector: "totalInstalledTraps",
      sortable: true,
    },
    {
      name: "Total de ADI's disponíveis para instalação",
      selector: "availableForInstall",
      sortable: true,
    },
    {
      name: "Total instalação/manutenção recém realizada",
      selector: "upToThirtyDays",
      sortable: true,
    },
    {
      name: "Total em atenção para próxima manutenção",
      selector: "betweenThirtyOneAndSixtyDays",
      sortable: true,
    },
    {
      name: "Total que ultrapassou o prazo de manutenção",
      selector: "afterSixtyDays",
      sortable: true,
    },
  ];

  return (
    <>
      {!tableData || tableData.length === 0 ? (
        <TableLoadingSkeleton length={5} />
      ) : (
        <Row className="mt-12">
          <Col className="mb-12 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0" style={{ paddingBottom: "0px" }}>
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="mb-0">Situação por município</h2>
                    <br />
                  </div>
                </Row>
              </CardHeader>
              <DataTable
                noHeader
                defaultSortField="name"
                defaultSortAsc={true}
                pagination
                highlightOnHover
                responsive
                columns={tableColumns}
                data={tableData}
                noDataComponent={"Nenhum registro encontrado."}
              />
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default GlobalVisionTable;
