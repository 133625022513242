import React from "react";
import { Container, Row, Col } from "reactstrap";
import { fetchOrganizations } from "../../../services/api/Organization";
import { getTrapsTypes } from "../../../services/api/Trap";
import TicketModel from "./TicketModel";
import StylesToTicket from "./StylesToTicket";
//import { toPng } from "html-to-image";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";

const CreateQrCodeTicket = ({setStyleTicketModel,styleTicketModel,showImage,selectedTrapType,selectedOrganization, formatedOrganizationName}) => {
  const divToPrintRef = React.useRef([]);

  const handleButtonClick = async () => {
    const etiquetas = document.querySelectorAll(".etiqueta");
  
    if (etiquetas.length > 0) {
      let etiquetaNumber = 3000001;
  
      for (const etiqueta of etiquetas) {
        try {
          const tagImageString = await generateTagImage(etiqueta);
  
          const link = document.createElement("a");
          link.download = `${etiquetaNumber}.png`;
          link.href = tagImageString;
          link.click();
  
          etiquetaNumber += 1;
        } catch (error) {
          console.error("Erro ao gerar imagem:", error);
        }
      }
    } else {
      console.warn("Nenhum elemento .etiqueta encontrado para download.");
    }
  };
  
  const generateTagImage = async (nodeElement) => {
    // try {
    //   console.log("nodeElement", nodeElement)
    //   const imageString = await toPng(nodeElement, {
    //     quality: 1,
    //     pixelRatio: 6,
    //   });
    //   return imageString;
    // } catch (error) {
    //   console.error("Erro ao gerar imagem:", error);
    //   throw error; // Lançar o erro para tratamento superior
    // }
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col xl={12} className="mt-4">

          {
            showImage
            ?
            <TicketModel 
              divToPrintRef={divToPrintRef} 
              styleTicketModel={styleTicketModel} 
              formatedOrganizationName={formatedOrganizationName} 
              trapType={selectedTrapType}
              organization={selectedOrganization}
            />
            :
            <></>
          }
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            {
              showImage
              ?
              <StylesToTicket  setStyleTicketModel={setStyleTicketModel} styleTicketModel={styleTicketModel} formatedOrganizationName={formatedOrganizationName} trapType={selectedTrapType} />
              :
              <></>
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <button className="btn btn-primary mt-4" onClick={handleButtonClick}>teste de impressão</button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateQrCodeTicket;
