import React from "react";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import { fetchDemands } from "../../../../services/api/Demand";
import DemandsExportTable from "./DemandsExportTable";
import { toast } from "react-toastify";
import { getTeam } from "../../../../services/api/Team";
import { Container } from "reactstrap";
import TableLoadingSkeleton from "../../../../components/ui/Loading/TableLoadingSkeleton";

const DemandsExport = () => {
  const [demandsData, setDemandsData] = React.useState(null);
  const [teamsData, setTeamsData] = React.useState(null);
  const [filteredDemandsData, setFilteredDemandsData] = React.useState(null);
  const [isDemandsDataLoading, setIsDemandsDataLoading] = React.useState(false);
  const [isTeamsDataLoading, setIsTeamsDataLoading] = React.useState(false);

  const formatDateToSaoPauloTimezone = (dateToFormat, displayHours) => {
    if (!dateToFormat) return;

    let dateFormatOptions;

    if (displayHours)
      dateFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone: "America/Sao_Paulo",
      };
    else
      dateFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour12: false,
        timeZone: "America/Sao_Paulo",
      };

    const date = new Date(dateToFormat);
    const dateFormated = date.toLocaleString("pt-BR", dateFormatOptions);

    return dateFormated;
  };

  const fetchDemandsData = async () => {
    setIsDemandsDataLoading(true);

    try {
      const { data, status } = await fetchDemands();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados das demandas. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      setDemandsData(data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    } finally {
      setIsDemandsDataLoading(false);
    }
  };

  const fetchTeamsData = async () => {
    setIsTeamsDataLoading(true);

    try {
      const { data, status } = await getTeam();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados das equipes. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      setTeamsData(data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    } finally {
      setIsTeamsDataLoading(false);
    }
  };

  React.useEffect(() => {
    fetchDemandsData();
    fetchTeamsData();
  }, []);

  React.useEffect(() => {
    if (!demandsData || !teamsData || filteredDemandsData) return;

    const filteredDemands = demandsData.map((demand) => {
      const problemsText = generateProblemsText(demand.problems);
      const teamInfos =
        demand.teams && demand.teams.length > 0
          ? generateTeamInfos(demand.teams[0].id)
          : generateTeamInfos();

      return {
        DATA_CRIACAO: formatDateToSaoPauloTimezone(demand.createDate, true),
        TIPO: demand.type,
        NUMERO: demand.serviceOrder,
        QUEM_CRIOU: "-",
        DESCRICAO:
          !demand.description || demand.description === ""
            ? "Não informado"
            : demand.description,
        SITUACAO: demand.situation,
        TERRITORIALIZACAO: generateDemandTerritorializationText(
          demand.territorializations
        ),
        ORIGEM_SOLICITACAO: demand.originName,
        PROGRAMA:
          problemsText === "Não informado"
            ? problemsText
            : problemsText.programsText,
        NECESSIDADE:
          problemsText === "Não informado"
            ? problemsText
            : problemsText.problemsText,
        ESPECIFICACAO_SERVICO:
          problemsText === "Não informado"
            ? problemsText
            : problemsText.servicesText,
        TEMPO_ATENDIMENTO: "-",
        PREVISAO_INICIO: formatDateToSaoPauloTimezone(demand.startDate),
        PREVISAO_FIM: formatDateToSaoPauloTimezone(demand.endDate),
        NOME_EQUIPE:
          demand.teams && demand.teams.length > 0
            ? demand.teams[0].name
            : "Não informado",
        EXECUTORES_EQUIPE:
          typeof teamInfos === "string" ? teamInfos : teamInfos.executors,
        ENCARREGADOS_EQUIPE:
          typeof teamInfos === "string"
            ? teamInfos
            : teamInfos.comissioners,
        COORDENADORES_EQUIPE:
          typeof teamInfos === "string"
            ? teamInfos
            : teamInfos.coordinators,
        QUEM_VALIDOU: demand.attendant,
        DATA_VALIDACAO: formatDateToSaoPauloTimezone(demand.aprovedDate, true),
      };
    });

    setFilteredDemandsData(filteredDemands);
  }, [demandsData, teamsData]);

  const generateTeamInfos = (teamId) => {
    if (!teamId) return "Não informado";

    const filteredTeam = teamsData.find((team) => team.id === teamId);

    if (!filteredTeam || filteredTeam.length === 0)
      return "Equipe não encontrada";

    if (!filteredTeam.members || filteredTeam.members.length === 0)
      return "A equipe não possui funcionários";

    const executors = [],
      coordinators = [],
      comissioners = [],
      supervisors = [];

    filteredTeam.members.forEach(
      ({ isComissioner, isCoordinator, isExecutor, isSuperviser, name }) => {
        if (isComissioner) comissioners.push(name);
        if (isCoordinator) coordinators.push(name);
        if (isExecutor) executors.push(name);
        if (isSuperviser) supervisors.push(name);
      }
    );

    return {
      executors: executors.join(", "),
      coordinators: coordinators.join(", "),
      comissioners: comissioners.join(", "),
      supervisors: supervisors.join(", "),
    };
  };

  const generateDemandTerritorializationText = (territorializations) => {
    if (!territorializations || territorializations.length === 0)
      return "Não informado";

    const territorializationsText = [];

    territorializations.forEach((territorialization) => {
      territorializationsText.push(territorialization.name);
    });

    return territorializationsText;
  };

  const generateProblemsText = (problems) => {
    if (!problems || problems.length === 0) return "Não informado";

    const problemsText = [],
      programsText = [],
      servicesText = [];

    problems.forEach((problem) => {
      problemsText.push(problem.name);
      programsText.push(problem.program.name);

      if (problem.services.length > 0) {
        problem.services.forEach((service) => {
          servicesText.push(service.name);
        });
      }
    });

    return {
      problemsText: problemsText.join(", "),
      programsText: programsText.join(", "),
      servicesText: servicesText.join(", "),
    };
  };

  return (
    <>
      <Container fluid style={{ marginTop: "2rem" }}>
        {isDemandsDataLoading || isTeamsDataLoading ? (
          <TableLoadingSkeleton />
        ) : (
          filteredDemandsData && (
            <DemandsExportTable demandsToExport={filteredDemandsData} />
          )
        )}
      </Container>
    </>
  );
};

export default DemandsExport;
