import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import EpidemiologicalWeekToWeekInput from "./EpidemiologicalWeekToWeek";
import EpidemiologicalWeekInput from "./EpidemiologicalWeek";
import MonthsInput from "./Months";
import YearsInput from "./Years";
import "./MainPeriod.css";

class MainPeriodComponent extends Component {    
    state = {
        startDate: "",
        endDate: "",
        months: [],
        years: [],
        weeks: [],
        WeekToWeekState: false,
        WeekState: false,
        weekValue1: [],
        weekValue2: [],
    }

    componentDidMount() {
        this.setInState();
    }

    componentDidUpdate(prevProps) {
        const { months, years, weeks, weekValue1, weekValue2 } = this.props;

        if (months !== prevProps.months || weekValue1 !== prevProps.weekValue1 || weekValue2 !== prevProps.weekValue2 || years !== prevProps.years || weeks !== prevProps.weeks) {
            this.setInState();
        }
    }

    // Função para Dizer que foik Habilitado o input de WeekToWeek
    setChangedWeekToWeek = () => {
        this.setState({ WeekToWeekState: true });
    }

    // Função para Dizer que foik Habilitado o input de Week
    setChangedWeek = () => {
        this.setState({ WeekState: true });
    }

    setInState() {
        const months = this.props.months !== undefined ? this.props.months : [];
        const weekValue1 = this.props.weekValue1 !== undefined ? this.props.weekValue1 : [];
        const weekValue2 = this.props.weekValue2 !== undefined ? this.props.weekValue2 : [];
        const years = this.props.years !== undefined ? this.props.years : [];
        const weeks = this.props.weeks !== undefined ? this.props.weeks : [];

        this.setState({ months, years, weeks, weekValue1, weekValue2 });
    }

    render() {
        return (
            <>
                <div className="col-md-4 mp-responsive-filter">
                    <YearsInput isSinan={this.props.isSinan} isEntomologico={this.props.isEntomologico} isOperacional={this.props.isOperacional} changePeriod={this.props.changePeriod} years={this.props.years !== undefined ? this.props.years : []} />
                </div>
                {this.props.showWeekToWeek ?
                <div className="col-md-4 mp-responsive-filter">
                    <div>
                        <span className="h4 text-white">Semana Epidemiológica (Período)</span>
                        <EpidemiologicalWeekToWeekInput isSinan={this.props.isSinan} isEntomologico={this.props.isEntomologico} isOperacional={this.props.isOperacional} setChangedWeekToWeek={this.setChangedWeekToWeek}  changeToTrueWTW={this.changeToTrueWTW} isDisabled={this.state.years.length === 0} changePeriod={this.props.changePeriod} weeks1={this.props.weekValue1 !== undefined ? this.props.weekValue1 : []} weeks2={this.props.weekValue2 !== undefined ? this.props.weekValue2 : []} />
                    </div>
                </div>
                :
                <></>
                }    
                
                <div className="col-md-4 mp-responsive-filter">
                <EpidemiologicalWeekInput isSinan={this.props.isSinan} isEntomologico={this.props.isEntomologico} isOperacional={this.props.isOperacional} setChangedWeek={this.setChangedWeek} WeekToWeekState = {this.state.WeekToWeekState} isDisabled={this.state.years.length === 0} changePeriod={this.props.changePeriod} weeks={this.props.weeks !== undefined ? this.props.weeks : []}/>
                </div>
                <div className="col-md-4 mp-responsive-filter">
                    <MonthsInput  isSinan={this.props.isSinan} isEntomologico={this.props.isEntomologico} isOperacional={this.props.isOperacional} isDisabled={this.state.years.length === 0} changePeriod={this.props.changePeriod} months={this.props.months !== undefined ? this.props.months : []} />
                </div>                              
                
            </>
        );
    }
}

export default MainPeriodComponent;
