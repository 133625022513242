import request from '../utils/request';
export function getVistoriaArmadilhasByDemandId(DemandId){
  return request({
    url: '/VistoriaArmadilhas/GetByDemand/'+DemandId,
    method: 'get'
  });
}

export function updatePositionVistoriaArmadilhas(vistoriaArmadilhas){
  return request({
    url: '/VistoriaArmadilhas/UpdatePosition',
    method: 'put',
    data:vistoriaArmadilhas
  });
}