import React, { useEffect, useState, useRef } from "react";
import Header from "components/Headers/Header.jsx";
import {  Container} from "reactstrap";
import Select from "react-select";
import { fetchDemands } from "../../../services/api/Demand";
import { getVigilanciaEntomologicaByDemandId } from "../../../services/api/VigilanciaEntomologica";
import { getVistoriaArmadilhasByDemandId } from "../../../services/api/VistoriaArmadilhas";
import { getInstalledTrapsByVisitId, updateHistories } from "../../../services/api/Trap";
import { LayersControl, Map, Marker, Popup, TileLayer } from "react-leaflet";
import FullscreenControl from 'react-leaflet-fullscreen';
import { toast, ToastContainer } from "react-toastify";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";
import L from "leaflet";
import { checkPermissionComponent } from "../../../services/utils/permission";
import { UpdateVisit } from "../../../services/utils/VisitsManager";
import Geocode from "react-geocode";
import "./OfflineContingency.css";

const { BaseLayer } = LayersControl;

Geocode.setLanguage("pt-BR");
Geocode.setRegion("br");
Geocode.setApiKey("AIzaSyCdkHjfiiztTeKSSuvfauQERlAJGFONeKo");
Geocode.setLocationType("GEOMETRIC_CENTER");

const OfflineContingency = ({history}) => {
    const [demands, setDemands] = useState([]);
    const [demandSelected, setDemandSelected] = useState(null);

    const allVisits = useRef(null);

    const [visits, setVisits] = useState([]);
    const [visitSelected, setVisitSelected] = useState(null);
    const [showingVisit, setShowingVisit] = useState(null);

    const [traps, setTraps] = useState(null);
    const [zoom, setZoom] = useState(3);
    const [center, setCenter] = useState([-16.112517, -48.996124]);

    useEffect(() => {
        if(!checkPermissionComponent("50e2f05b-b6d3-46a6-81b0-34875882afab", "read")){
            alert("Você não tem permissão para acessar essa funcionalidade!");
            history.goBack(); 
            return;
        }

        fetchDemands().then((result) => {
            const {data} = result;
            if(data){
                const demandsList = data.map(({serviceOrder, id}) => ({label: serviceOrder, value:id}))
                setDemands(demandsList)
            }
        })
    },  [])

    useEffect(() => {
        if(demandSelected){
            getAllVisits().then((allVisits) => {
                setVisits(allVisits);
            })
    
            setVisitSelected(null);
            setShowingVisit(null);
            setTraps(null);
        }
    }, [demandSelected])

    useEffect(() => {
        if(visitSelected){
            const {idField, latitude, longitude} = visitSelected;
            getInstalledTrapsByVisitId(visitSelected[idField]).then((result) => {
                setTraps(result.data.length > 0 ? result.data : null );
            });
            
            setZoom(17);
            setCenter([latitude, longitude])
            setShowingVisit({...visitSelected});
        }
    }, [visitSelected]);

    const getAllVisits = async () => {
        const demandId = demandSelected.value || "00000000-0000-0000-0000-000000000000";
        const vigilanciasEntomologicas = await getVigilanciaEntomologicaByDemandId(demandId);
        const vistoriaArmadilhas = await getVistoriaArmadilhasByDemandId(demandId);

        let visits = vigilanciasEntomologicas.data.map(({vigilanciaId, address, typeForm, manuallyModified}) => (
            {
                idField:"vigilanciaId", 
                value:vigilanciaId, 
                label: `${typeForm}: ${address.localDoImovel} ${address.logradouro} - ${address.numero} - ${address.sequencial}`,
                manuallyModified
            }
        )).reverse(); 

        visits = visits.concat(vistoriaArmadilhas.data.map(({vistoriaArmadilhaId, address, typeForm, manuallyModified})=> (
            {
                idField:"vistoriaArmadilhaId", 
                value:vistoriaArmadilhaId,
                label: `${typeForm}: ${address.localDoImovel} ${address.logradouro} - ${address.numero} - ${address.sequencial}`,
                manuallyModified
            }
        )).reverse())
        
        allVisits.current = vigilanciasEntomologicas.data.reverse().concat(vistoriaArmadilhas.data.reverse());
        return Promise.resolve(visits);
    }

    const changeSelectedVisit = (visit) => {
        const {idField, value} = visit;
        const selectedVisit = allVisits.current.find(v => v[idField] && v[idField] === value)
        
        setVisitSelected({...selectedVisit, idField:idField});
    }

    const dragMarker = (obj, index) => {
        const {lat, lng} = obj.target._latlng
        const trap = {...traps[index], latitude: lat, longitude:lng, manuallyChanged:true};
        const newTraps = [...traps];
        newTraps.splice(index, 1)
        newTraps.push(trap);

        setTraps(newTraps);
    }

    const saveData = async () => {
        try{
            await UpdateVisit(showingVisit)
            if(traps){
                await updateHistories(traps)
            }

            getAllVisits().then((allVisits) => {
                setVisits(allVisits);
            });

            toast.success("Pontos atualizados com sucesso!");
        }catch(error){
            toast.error("Aconteceu algum erro ao atualizar os pontos!");
        }        
    }

    const getTrapIcon = (trap) => {
        const trapType = trap.trap.trapType.name;
        const blackIconUrl = ARMADILHA_INFO[trapType].icons.black;

        return (
            L.icon({
                iconUrl: blackIconUrl,
                iconSize: [38,38],
                iconAnchor: [22,38],
                popupAnchor: [-3, -30],
                shadowUrl: null,
                shadowSize: [50, 64],
                shadowAnchor: [4, 62]
            })
        )
    }

    const getVisitIcon = () => {
        return (
            L.icon({
                iconUrl: require('../../../assets/img/icons/visita.png'),
                iconSize: [38,38],
                iconAnchor: [22,38],
                popupAnchor: [-3, -30],
                shadowUrl: null,
                shadowSize: [50, 64],
                shadowAnchor: [4, 62]
            })
        )
    }

    const dragVisit = (obj) =>{
        const {lat, lng} = obj.target._latlng
        let newVisit = {...showingVisit, latitude: lat, longitude:lng}
        setShowingVisit(newVisit);
    }

    const movePoints = async () => {
        const {localDoImovel, logradouro, numero, geographicNumber} = showingVisit.address;
        try{
            const geoRequest = await Geocode.fromAddress(`${localDoImovel} ${logradouro}, ${numero}, ${geographicNumber}`)
            const obj = {target: {_latlng:{...geoRequest.results[0].geometry.location}}}
            const {lat, lng} = obj.target._latlng;

            dragVisit(obj);
            dragAllMarkers(obj);

            setCenter([lat, lng]);

        }catch(error){
            toast.error("Não foi possível mover os pontos para o endereço da visita.");
        }
    }

    const dragAllMarkers = (obj) => {
        const {lat, lng} = obj.target._latlng;
        let newMarkers = [];

        for(const index in traps){
            let trap = {...traps[index], latitude: lat, longitude:lng, manuallyChanged: true}
            newMarkers.push(trap);
        }

        setTraps(newMarkers);
    }


    return (
        <>
            <Header />
            <ToastContainer/>
            <Container className="header bg-gradient-info pt-5 pt-md-8" fluid>
                <div className="row linha-form">
                    <div className="col-4 responsive-form-field-offlineContingency-medium">
                        <span className="h4 text-white">Demanda</span>
                        <Select
                            placeholder="Selecionar a demanda"
                            options={demands}
                            onChange={setDemandSelected}
                        />
                    </div>
                    <div className="col-4 responsive-form-field-offlineContingency-medium">
                        <span className="h4 text-white">Visita</span>
                        <Select
                            placeholder="Selecionar a visita"
                            value={visitSelected && visits.filter(({value}) => value === visitSelected[visitSelected.idField])}
                            options={visits}
                            isDisabled={!demandSelected}
                            onChange={changeSelectedVisit}
                            styles={{option: (styles, {data, isSelected})  => {
                                if(!isSelected){
                                    let bgColor = data.manuallyModified ? "green" : null
                                    let color = data.manuallyModified ? "white" : "black";
                                    return{
                                        ...styles,
                                        backgroundColor: bgColor,
                                        color
                                    }
                                }else{
                                    return {
                                        ...styles,
                                    }
                                }
                                
                            }}}
                        />
                    </div>
                </div> 
                <div className="row linha-form">
                    <div className="col-4 responsive-form-field-offlineContingency-full">
                        <button className="btn btn-primary" onClick={saveData}>Salvar Alterações</button>
                    </div><div className="col-4 responsive-form-field-offlineContingency-full">
                        <button className="btn btn-primary" disabled={!showingVisit} onClick={movePoints}>Mover Localizações</button>
                    </div>
                </div> 
            </Container>
            <Map style={{zIndex:0}} center={center} zoom={zoom}>
                <FullscreenControl 
                    position="topleft"
                    title="Tela Cheia"
                    titleCancel="Sair da Tela Cheia"
                />
                <LayersControl position="topright">
                    <BaseLayer checked name="Normal">
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </BaseLayer>
                    <BaseLayer name="Preto e Branco">
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                        />
                    </BaseLayer>
                </LayersControl>
                {traps && traps.map(({id, latitude, longitude, trap}, index) => (
                    <Marker 
                        key={id}
                        position={[latitude, longitude]}
                        draggable={true}
                        ondragend={(obj) => {dragMarker(obj, index)}}
                        icon={getTrapIcon(traps[index])}
                    >
                        <Popup>
                            {`${ARMADILHA_INFO[trap.trapType.name].apelido} - ${trap.number}`} <br/>
                        </Popup>
                    </Marker>
                ))}

                {showingVisit && 
                    <Marker 
                        position={[showingVisit.latitude, showingVisit.longitude]}
                        icon={getVisitIcon()}
                        draggable={true}
                        ondragend={dragVisit}
                    >

                    </Marker>
                }
            </Map>
        </>
    )
}

export default OfflineContingency