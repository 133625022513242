import React from 'react';
import { Line } from "recharts";


const LineContent = ({ titleElementData, colors }) => {
  return titleElementData.map((ano, index) => {
        return(
            <Line
                dataKey={ano.label || "Notificações"}
                stroke={colors[index]}
                hide={ano.checked ? false : true}
            />
        )
    });
};

export default LineContent;



