import React from "react";
import FiltroArmadilhas from "../components/Navbars/FiltroArmadilhas"

import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

// core components
import { getTraps } from "../services/api/Trap";
import Header from "components/Headers/Header.jsx";
import { Link } from "react-router-dom";
class Armadilhas extends React.Component {
    state = {
        Traps: [],
        Trapsfilter:[],
        modal:"",
        selectedTrap:"",
        selectedTrapId:""
    }
    componentDidMount() {
        
        let requestTrap = getTraps();
        requestTrap.then(response => {
            this.setState({ Traps: response.data});
            this.setState({ Trapsfilter: response.data});
        })
    }
    callbackFunction = (EmployeeFilteredArray)=>{
        this.setState({EmployeesFilter: EmployeeFilteredArray})
      }
    confirmDelete (TrapName, TrapId) {
        this.setState({
            selectedTrapId:TrapId,
            selectedTrap:TrapName
        });
        this.toggle(false);
    }
    toggle = (willDeleteTrap) => {
        this.setState({
            modal: !this.state.modal
        }); 
    }
    render() {
        return (
            <>
                <Modal size="lg" isOpen={this.state.modal} fade="true"  toggle={()=>this.toggle(false)}>
                    <ModalHeader toggle={()=>this.toggle(false)}>Remoção de armadilha</ModalHeader>
                    <ModalBody>           
                        <div className="row"> 
                            <div className="col-9">
                                <span className="h2 text-black">Deseja desinstalar a armadilha {this.state.selectedTrap}?</span>
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row" >                          
                        
                            <div className="col-6">
                                <button onClick={()=>this.toggle(false)} className="form-control">CANCELAR</button>
                            </div>
                            <div className="col-6">
                                <button onClick={()=>this.toggle(true)} className="btn btn-primary">REMOVER</button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
                <Header />

                <FiltroArmadilhas Traps={this.state.Traps} parentCallback = {this.callbackFunction}/>

                <Container className="mt--7" fluid>
                    <Row className="mt-12">
                        <Col className="mb-12 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Armadilhas</h3>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Descrição</th>
                                            <th scope="col">Tipo</th>
                                            <th scope="col">Imóvel</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.Trapsfilter.map((trap, index) => {
                                            return(
                                                <tr key={index}>
                                                    <th scope="row">{trap.name}</th>
                                                    <td>{trap.description}</td>
                                                    <td>{trap.typeName}</td>
                                                    <td>
                                                        -
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="btn-icon-clipboard btn-acao-amostra"
                                                            data-clipboard-text="check-bold"
                                                            id="tooltip204269497"
                                                            type="button">
                                                            <div>
                                                                <i className="ni ni-check-bold" />
                                                            </div>
                                                        </button>

                                                        <button
                                                            className="btn-icon-clipboard btn-acao-amostra"
                                                            data-clipboard-text=" ni ni-fat-remove"
                                                            id="tooltip204269497"
                                                            onClick={()=>this.confirmDelete(trap.name, trap.id)}
                                                            type="button">
                                                            <div>
                                                                <i className=" ni ni-fat-remove" />
                                                            </div>
                                                        </button>
                                                        <Link to="/admin/EditTrap">
                                                            <button
                                                                className="btn-icon-clipboard btn-acao-amostra"
                                                                data-clipboard-text="ni ni-fat-edit"
                                                                id="tooltip204269497"
                                                                onClick={()=>localStorage.setItem('TrapItem', trap.id)}
                                                                type="button">
                                                                <div>
                                                                    <i className="ni ni-ruler-pencil" />
                                                                </div>
                                                            </button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                                                               
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

}

export default Armadilhas;