import request from '../utils/request';

export function getPerson(personId){
  return request({
    url: '/Persons/'+personId,
    method: 'get',
  })
}

export function deletePerson(personId){
  return request({
    url: '/Persons/'+personId,
    method: 'delete',
  })
}

