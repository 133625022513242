import { polygon } from "@turf/turf";

export const cloropleticInfo = [
  {
    text: "até 1500 / km²",
    min: 0,
    max: 1500,
    color: "red",
  },
  {
    text: "de 1501 até 2399 / km²",
    min: 1501,
    max: 2399,
    color: "yellow",
  },
  {
    text: " mais de 2400 / km²",
    min: 2400,
    max: undefined,
    color: "green",
  },
];

export const createCloropleticGroups = (typeLayers, territorializationFiltered) => {
  if(!typeLayers || typeLayers.length === 0)
    return;
  
  const result = [];

  typeLayers.forEach(({ label, territorializationList }) => {
    const cloropleticGroup = {
      name: label,
      Polygons: [],
    };

    let groupTerritorializations = territorializationList;

    if (
      territorializationFiltered &&
      territorializationFiltered.length > 0
    ) {
      groupTerritorializations = territorializationList.filter((territorialization) =>
        territorializationFiltered.some(
          ({ value }) => territorialization.id === value
        )
      );
    }

      if(groupTerritorializations && groupTerritorializations.length == 0) {
        groupTerritorializations = territorializationList;
      }

      groupTerritorializations.map((territorialization) => {
        const turfPolygon = polygon([
          territorialization.coordinates.map(({ x, y }) => [x, y]),
        ]);
        turfPolygon.name = territorialization.name;
        turfPolygon.territorialization = territorialization;
        cloropleticGroup.Polygons.push(turfPolygon);
      });

      cloropleticGroup.legends = cloropleticInfo;
      
      result.push(cloropleticGroup);
    
  });

  return result;
}