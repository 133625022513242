import React from "react";
import { toast } from "react-toastify";
import { Container } from "reactstrap";
import { getTeam } from "../../../../services/api/Team";
import TableLoadingSkeleton from "../../../../components/ui/Loading/TableLoadingSkeleton";
import TeamsExportTable from "./TeamsExportTable";

const TeamsExport = () => {
  const [teamsData, setTeamsData] = React.useState(null);
  const [filteredTeamsData, setFilteredTeamsData] = React.useState(null);
  const [isTeamsDataLoading, setIsTeamsDataLoading] = React.useState(false);

  React.useEffect(() => {
    fetchTeamsData();
  }, []);

  React.useEffect(() => {
    if (!teamsData || filteredTeamsData) return;

    const filteredTeams = teamsData.map((team) => {
      const teamMembersRoles = getTeamMembersByRoles(team.members);

      return {
        NOME_EQUIPE: team.name,
        DATA_CRIACAO: formatDateToSaoPauloTimezone(team.createAt, true),
        PROGRAMA: team.program.name,
        COORDENADORES:
          typeof teamMembersRoles === "string"
            ? teamMembersRoles
            : teamMembersRoles.coordinators,
        SUPERVISORES:
          typeof teamMembersRoles === "string"
            ? teamMembersRoles
            : teamMembersRoles.supervisors,
        ENCARREGADOS:
          typeof teamMembersRoles === "string"
            ? teamMembersRoles
            : teamMembersRoles.comissioners,
        EXECUTORES:
          typeof teamMembersRoles === "string"
            ? teamMembersRoles
            : teamMembersRoles.executors,
        DESCRICAO:
          team.description === "" || !team.description
            ? "Não informado"
            : team.description,
      };
    });

    setFilteredTeamsData(filteredTeams);
  }, [teamsData]);

  const fetchTeamsData = async () => {
    setIsTeamsDataLoading(true);

    try {
      const { data, status } = await getTeam();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados das EQUIPES. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      setTeamsData(data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    } finally {
      setIsTeamsDataLoading(false);
    }
  };

  const formatDateToSaoPauloTimezone = (dateToFormat, displayHours) => {
    if (!dateToFormat) return;

    let dateFormatOptions;

    if (displayHours)
      dateFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone: "America/Sao_Paulo",
      };
    else
      dateFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour12: false,
        timeZone: "America/Sao_Paulo",
      };

    const date = new Date(dateToFormat);
    const dateFormated = date.toLocaleString("pt-BR", dateFormatOptions);

    return dateFormated;
  };

  const getTeamMembersByRoles = (teamMembers) => {
    if (!teamMembers || teamMembers.length === 0) return "Equipe sem membros";

    const executors = [],
      coordinators = [],
      comissioners = [],
      supervisors = [];

    teamMembers.forEach(
      ({ isComissioner, isCoordinator, isExecutor, isSuperviser, name }) => {
        if (isComissioner) comissioners.push(name);
        if (isCoordinator) coordinators.push(name);
        if (isExecutor) executors.push(name);
        if (isSuperviser) supervisors.push(name);
      }
    );

    return {
      executors: executors.join(", "),
      coordinators: coordinators.join(", "),
      comissioners: comissioners.join(", "),
      supervisors: supervisors.join(", "),
    };
  };

  return (
    <Container fluid style={{ marginTop: "2rem" }}>
      {isTeamsDataLoading || !filteredTeamsData ? (
        <TableLoadingSkeleton />
      ) : (
        <TeamsExportTable teamsToExport={filteredTeamsData} />
      )}
    </Container>
  );
};

export default TeamsExport;
