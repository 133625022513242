import React from "react";

import { toast, ToastContainer } from "react-toastify";
import { Container } from "reactstrap";
import VisitsPageFilters from "./Filters/VisitsPageFilters";
import VisitsPageTable from "./Tables/VisitsPageTable";
import moment from "moment";
import { getUserEmployeeDataByOrganization } from "../../../services/api/Users";
import { getTeam } from "../../../services/api/Team";
import { fetchDemands } from "../../../services/api/Demand";
import { getAllVigilanciasByFilter } from "../../../services/api/Visits";
import { getUserData } from "../../../services/utils/auth";

const VisitsPage = () => {
  const [demandsList, setDemandsList] = React.useState([]);
  const [employeesList, setEmployeesList] = React.useState([]);
  const [isVisitsDataLoading, setIsVisistsDataLoading] = React.useState(false);
  const [teamsList, setTeamsList] = React.useState([]);
  const [visitsData, setVisitsData] = React.useState([]);
  const [visitsFilters, setVisitsFilters] = React.useState({
    beginDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    demand: {
      label: "Todas",
      value: "00000000-0000-0000-0000-000000000000",
    },
    employees: [],
    finalDate: moment().format("YYYY-MM-DD"),
    situation: {
      label: "Todas",
      value: "",
    },
    team: {
      label: "Todas",
      value: "00000000-0000-0000-0000-000000000000",
    },
  });

  React.useEffect(() => {
    fetchEmployeesAndTeamsData();
    fetchDemandsData();
    fetchVisistsData();
  }, []);

  const fetchAllComponentData = async () => {
    await Promise.all([fetchVisistsData()]);
  };

  const fetchEmployeesAndTeamsData = async () => {
    const employeesData = await fetchEmployeesData();
    const teamsData = await fetchTeamsData(employeesData);

    return [employeesData, teamsData];
  };

  const fetchEmployeesData = async () => {
    try {
      const { data, status } = await getUserEmployeeDataByOrganization();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pela lista de funcionários. Verifique sua conexão com a internet e caso o erro persista entre em contato consco."
        );

      const formatedEmployees = data.map(
        ({ userId, employeeId, employeeName }) => {
          return {
            employeeId,
            employeeName,
            userId,
            label: employeeName,
            value: userId,
          };
        }
      );

      setEmployeesList(formatedEmployees);

      return formatedEmployees;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        toast.error(error.message);
      }
    }
  };

  const fetchTeamsData = async (employeesData) => {
    try {
      const { data, status } = await getTeam();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pela lista de equipes. Verifique sua conexão com a internet e caso o erro persista entre em contato consco."
        );

      const formatedTeams = data.map(({ id, members, name }) => {
        const formatedTeamMembers = members.map((member) => {
          const userData = employeesData.find(
            (employee) => employee.employeeId === member.employeeId
          );

          if (!userData)
            return {
              employeeId: "00000000-0000-0000-0000-000000000000",
              employeeName: "Usuário não encontrado",
              label: "Usuário não encontrado",
              userId: "00000000-0000-0000-0000-000000000000",
              uvis: null,
              value: "00000000-0000-0000-0000-000000000000",
            };

          return {
            ...member,
            userId: userData.userId
              ? userData.userId
              : "00000000-0000-0000-0000-000000000000",
            employeeId: userData.employeeId,
          };
        });

        return {
          value: id,
          label: name,
          members: formatedTeamMembers,
        };
      });

      setTeamsList([
        {
          label: "Todos",
          value: "00000000-0000-0000-0000-000000000000",
        },
        ...formatedTeams,
      ]);

      return formatedTeams;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        toast.error(error.message);
      }
    }
  };

  const fetchDemandsData = async () => {
    try {
      const { data, status } = await fetchDemands();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dado de demandas. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com nossa equipe"
        );

      const formatedDemands = data.map(({ id, serviceOrder, type }) => {
        return {
          label: `${serviceOrder} - ${type}`,
          value: id,
        };
      });

      setDemandsList(formatedDemands);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        toast.error(error.message);
      }
    }
  };

  const fetchVisistsData = async () => {
    const usersIdsToSend = [];

    if (visitsFilters.team.label !== "Todas")
      visitsFilters.team.members.forEach(({ userId }) =>
        usersIdsToSend.push(userId)
      );

    if (visitsFilters.employees && visitsFilters.employees.length > 0)
      visitsFilters.employees.forEach(({ value }) =>
        usersIdsToSend.push(value)
      );

    const filtersToSend = {
      demandId: visitsFilters.demand.value,
      organizationId: getUserData("organizationId"),
      periods: {
        beginDate: visitsFilters.beginDate,
        finalDate: visitsFilters.finalDate,
      },
      usersIds: usersIdsToSend,
      situation:
        visitsFilters.situation.label === "Todas"
          ? ""
          : visitsFilters.situation.label,
    };

    setIsVisistsDataLoading(true);

    try {
      const { data, status } = await getAllVigilanciasByFilter(filtersToSend);

      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados de Visitas. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com nossa equipe."
        );

      console.log(data);

      setVisitsData(data);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    } finally {
      setIsVisistsDataLoading(false);
    }
  };

  const handleFiltersChange = (filterName, newValue) => {
    const newFiltersValues = { ...visitsFilters };

    newFiltersValues[filterName] = newValue;

    setVisitsFilters(newFiltersValues);
  };

  const situationsList = [
    {
      label: "Aberto",
      value: "Aberto",
    },
    {
      label: "Endereço Não Encontrado",
      value: "Endereço Não Encontrado",
    },
    {
      label: "Fechado",
      value: "Fechado",
    },
    {
      label: "Orientação",
      value: "Orientação",
    },
    {
      label: "Recuperação",
      value: "Recuperação",
    },
    {
      label: "Recusa",
      value: "Recusa",
    },
  ];

  return (
    <main>
      <ToastContainer />
      <Container fluid className="bg-gradient-info pt-5 pb-2 mb-5">
        <div style={{ paddingTop: "10px !important" }}>&nbsp;</div>
        <VisitsPageFilters
          employeesList={employeesList}
          demandsList={demandsList}
          fetchAllComponentData={fetchAllComponentData}
          filtersValues={visitsFilters}
          handleFiltersChange={handleFiltersChange}
          isVisitsDataLoading={isVisitsDataLoading}
          teamsList={teamsList}
          situationsList={situationsList}
        />
      </Container>
      <Container fluid className="pb-2 mt-2">
        <VisitsPageTable
          data={visitsData}
          employeesList={employeesList}
          filtersValues={visitsFilters}
          setVisitsList={setVisitsData}
          headerText="Visitas"
        />
      </Container>
    </main>
  );
};

export default VisitsPage;
