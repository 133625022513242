import request from "../utils/request";
//import request from "../utils/requestTests/requestTestDemand";

export function fetchGoalsByFilters(filter) {
  return request({
    url: "/Goal/FilteredSearch",
    method: "post",
    data: filter,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function fetchTypeGoalsByFilters(filters) {
  return request({
    url: "/Goal/TypeGoal/FilteredSearch",
    method: "post",
    data: filters,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function insertMultiplesGoals(goals) {
  return request({
    url: "/Goal/InsertMultiples",
    method: "post",
    data: goals,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function updateGoal(goal) {
  return request({
    url: "/Goal",
    method: "put",
    data: goal,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}
