import React from "react";
import { Link } from "react-router-dom";
// reactstrap components

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import "../../../assets/css/style.css";
import FormDemanda from "../../../components/Forms/FormDemanda";
import "./AddDemand.css";

class EditDemand extends React.Component {
  render() {

    return (
      <>
        <Container className="responsive-container" fluid>
          <Row className="mt-12">
            <Col className="mb-12 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        <Link to="/admin/index"> <i className=" ni ni-bold-left bnt-voltar" /></Link>
                        Editar Demanda</h3>
                    </div>
                  </Row>
                </CardHeader>

                {/* Formulario demandas */}
                <FormDemanda demand={this.props.location.data}/>

              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default EditDemand;