import React from "react";

import { ToastContainer, toast } from "react-toastify";
import { Container } from "reactstrap";
import ProductivityGraphicsWrapper from "./Graphics/ProductivityGraphicsWrapper";
import ProductivityCardsWrapper from "./Cards/ProductivityCardsWrapper";
import ProductivityFiltersWrapper from "./Filters/ProductivityFiltersWrapper";
//import { fetchProductivity } from "../../../services/api/VigilanciaEntomologica";
import { getUserData } from "../../../services/utils/auth";
import { fetchTotalProductivity } from "../../../services/api/Trap";
import { getUserEmployeeDataByOrganization } from "../../../services/api/Users";
import {
  fetchConsolidatedVisits,
  fetchVisitsByStatus,
} from "../../../services/api/Visits";
import moment from "moment";
import ProductivityVisitsTable from "./Tables/ProductivityVisitsTable";
import { getTeam } from "../../../services/api/Team";
import {
  fetchTerritorializations,
  getAllTypeTerritorializations,
} from "../../../services/api/territorialization";
//import ConsolidatedTable from "./Tables/ConsolidatedProductivityTable";
import ConsolidatedProductivityTable from "./Tables/ConsolidatedProductivityTable";
import { fetchGoalsByFilters } from "../../../services/api/Goals";

const ProductivityPage = () => {
  /*   const [usersProductivityData, setUsersProductivityData] = React.useState([]);
  const [isUsersProductivityLoading, setIsUsersProductivityLoading] =
    React.useState(false); */
  const [totalProductivityData, setTotalProductivityData] =
    React.useState(null);
  const [territorializationsData, setTerritorializationsData] = React.useState(
    []
  );
  const [employeesList, setEmployeesList] = React.useState([]);
  const [teamsList, setTeamsList] = React.useState([]);
  const [visitsStatusData, setVisitsStatusData] = React.useState(null);
  const [consolidatedVisitsData, setConsolidatedVisitsData] =
    React.useState(null);
  const [goalsData, setGoalsData] = React.useState([]);
  const [productivityFilters, setProductivityFilters] = React.useState({
    demand: {
      label: "Todas",
      value: "all",
    },
    team: {
      label: "Todos",
      value: "00000000-0000-0000-0000-000000000000",
    },
    employees: [],
    trapType: {
      label: "Todos",
      value: "all",
    },
    beginDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    finalDate: moment().add(1, "day").format("YYYY-MM-DD"),
    territorializations: [],
  });

  React.useEffect(() => {
    fetchAllComponentsData();
    fetchEmployeesAndTeamsData();
  }, []);

  const fetchAllComponentsData = async () => {
    await Promise.all([
      fetchTotalProductivityData(),
      fetchVisitsStatusData(),
      fetchTerritorializationsData(),
      fetchConsolidatedVisitsData(),
      fetchGoalsData(),
      //fetchProductivityData(),
    ]);
  };

  /* const fetchProductivityData = async () => {
    const requestFilters = {
      periods: {
        beginDate: productivityFilters.beginDate,
        finalDate: productivityFilters.finalDate,
      },
      organizationId: getUserData("organizationId"),
    };

    setIsUsersProductivityLoading(true);

    try {
      const { data, status } = await fetchProductivity(requestFilters);
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pela produtividade dos usuários. Verifique sua conexão com a internet e caso persista entre em contato consco."
        );

      setUsersProductivityData(data);

      return data;
    } catch (error) {
      console.error(error);
      toast.error(
        "Ocorreu um erro ao buscar pela produtividade dos usuários. Verifique sua conexão com a internet e caso persista entre em contato consco."
      );
    } finally {
      setIsUsersProductivityLoading(false);
    }
  }; */

  const fetchEmployeesAndTeamsData = async () => {
    const employeesData = await fetchEmployeesData();
    const teamsData = await fetchTeamsData(employeesData);

    return [employeesData, teamsData];
  };

  const fetchEmployeesData = async () => {
    try {
      const { data, status } = await getUserEmployeeDataByOrganization();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pela lista de funcionários. Verifique sua conexão com a internet e caso o erro persista entre em contato consco."
        );

      const formatedEmployees = data.map(
        ({ userId, employeeId, employeeName }) => {
          return {
            employeeId,
            employeeName,
            userId,
            label: employeeName,
            value: userId,
          };
        }
      );

      setEmployeesList(formatedEmployees);

      return formatedEmployees;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        toast.error(error.message);
      }
    }
  };

  const fetchTeamsData = async (employeesData) => {
    try {
      const { data, status } = await getTeam();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pela lista de equipes. Verifique sua conexão com a internet e caso o erro persista entre em contato consco."
        );

      const formatedTeams = data.map(({ id, members, name }) => {
        const formatedTeamMembers = members.map((member) => {
          const userData = employeesData.find(
            (employee) => employee.employeeId === member.employeeId
          );

          if (!userData)
            return {
              employeeId: "00000000-0000-0000-0000-000000000000",
              employeeName: "Usuário não encontrado",
              label: "Usuário não encontrado",
              userId: "00000000-0000-0000-0000-000000000000",
              uvis: null,
              value: "00000000-0000-0000-0000-000000000000",
            };

          return {
            ...member,
            userId: userData.userId
              ? userData.userId
              : "00000000-0000-0000-0000-000000000000",
            employeeId: userData.employeeId,
          };
        });

        return {
          value: id,
          label: name,
          members: formatedTeamMembers,
        };
      });

      setTeamsList([
        {
          label: "Todos",
          value: "00000000-0000-0000-0000-000000000000",
        },
        ...formatedTeams,
      ]);

      return formatedTeams;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        toast.error(error.message);
      }
    }
  };

  const fetchTerritorializationsData = async () => {
    try {
      const { data: typeTerritorializationsData, status } =
        await getAllTypeTerritorializations();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dado das Territorializações"
        );

      const territorializations = [];

      for (const typeTerritorialization of typeTerritorializationsData) {
        if (typeTerritorialization.typeGeometry === "Polygon") {
          const { data } = await fetchTerritorializations(
            typeTerritorialization.id
          );

          territorializations.push({
            name: typeTerritorialization.name,
            label: typeTerritorialization.name,
            territorializations: data,
            options: data.map((territorialization) => {
              return {
                label: territorialization.name,
                value: territorialization.id,
              };
            }),
          });
        }
      }

      setTerritorializationsData(territorializations);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTotalProductivityData = async () => {
    const usersIdsToSend = [];

    if (productivityFilters.team.label !== "Todos")
      productivityFilters.team.members.forEach(({ userId }) =>
        usersIdsToSend.push(userId)
      );

    if (
      productivityFilters.employees &&
      productivityFilters.employees.length > 0
    )
      productivityFilters.employees.forEach(({ value }) =>
        usersIdsToSend.push(value)
      );

    const requestFilters = {
      periods: {
        beginDate: productivityFilters.beginDate,
        finalDate: productivityFilters.finalDate,
      },
      organizationId: getUserData("organizationId"),
      usersIds: usersIdsToSend,
      territorializationsIds: productivityFilters.territorializations.map(
        ({ value }) => value
      ),
    };

    try {
      const { data, status } = await fetchTotalProductivity(requestFilters);
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pela produtividade das digitações. Verifique sua conexão com a internet e caso persista entre em contato consco."
        );

      setTotalProductivityData(data);

      return data;
    } catch (error) {
      console.error(error.message);
      toast.error(
        "Ocorreu um erro ao buscar pela produtividade das digitações. Verifique sua conexão com a internet e caso persista entre em contato consco."
      );
    }
  };

  const fetchVisitsStatusData = async () => {
    const usersIdsToSend = [];

    if (productivityFilters.team.label !== "Todos")
      productivityFilters.team.members.forEach(({ userId }) =>
        usersIdsToSend.push(userId)
      );

    if (
      productivityFilters.employees &&
      productivityFilters.employees.length > 0
    )
      productivityFilters.employees.forEach(({ value }) =>
        usersIdsToSend.push(value)
      );

    const requestFilters = {
      periods: {
        beginDate: productivityFilters.beginDate,
        finalDate: productivityFilters.finalDate,
      },
      organizationId: getUserData("organizationId"),
      usersIds: usersIdsToSend,
      territorializations: productivityFilters.territorializations.map(
        ({ value }) => value
      ),
    };

    try {
      const { data, status } = await fetchVisitsByStatus(requestFilters);
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados das visitas. Verifique sua conexão com a internet e caso persista entre em contato consco."
        );

      setVisitsStatusData(data);

      return data;
    } catch (error) {
      console.error(error.message);
      toast.error(
        "Ocorreu um erro ao buscar pelos dados das visitas. Verifique sua conexão com a internet e caso persista entre em contato consco."
      );
    } finally {
    }
  };

  const fetchConsolidatedVisitsData = async () => {
    const usersIdsToSend = [];

    if (productivityFilters.team.label !== "Todos")
      productivityFilters.team.members.forEach(({ userId }) =>
        usersIdsToSend.push(userId)
      );

    if (
      productivityFilters.employees &&
      productivityFilters.employees.length > 0
    )
      productivityFilters.employees.forEach(({ value }) =>
        usersIdsToSend.push(value)
      );

    const requestFilters = {
      periods: {
        beginDate: productivityFilters.beginDate,
        finalDate: productivityFilters.finalDate,
      },
      organizationId: getUserData("organizationId"),
      usersIds: usersIdsToSend,
    };

    try {
      const { data, status } = await fetchConsolidatedVisits(requestFilters);
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados das visitas consolidadas. Verifique sua conexão com a internet e caso persista entre em contato consco."
        );

      setConsolidatedVisitsData(data);

      return data;
    } catch (error) {
      console.error(error.message);
      toast.error(
        "Ocorreu um erro ao buscar pelos dados das visitas consolidadas. Verifique sua conexão com a internet e caso persista entre em contato consco."
      );
    }
  };

  const fetchGoalsData = async () => {
    const usersIdsToSend = [];

    if (productivityFilters.team.label !== "Todos")
      productivityFilters.team.members.forEach(({ userId }) =>
        usersIdsToSend.push(userId)
      );

    if (
      productivityFilters.employees &&
      productivityFilters.employees.length > 0
    )
      productivityFilters.employees.forEach(({ value }) =>
        usersIdsToSend.push(value)
      );

    const filtersToSend = {
      includeEmployee: true,
      includeConsolidatedResult: true,
      organizationId: getUserData("organizationId"),
      periods: {
        beginDate: productivityFilters.beginDate,
        finalDate: moment(productivityFilters.finalDate)
          .add(1, "day")
          .format("YYYY-MM-DD"),
      },
      usersIds: usersIdsToSend,
    };

    try {
      const { data, status } = await fetchGoalsByFilters(filtersToSend);

      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados de metas. Verifique sua conexão com a internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      setGoalsData(data);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  const handleFiltersChange = (filterName, newValue) => {
    const newFiltersValues = { ...productivityFilters };

    newFiltersValues[filterName] = newValue;

    setProductivityFilters(newFiltersValues);
  };

  const handleFilterButtonClick = () => {
    fetchAllComponentsData();
  };

  return (
    <main>
      <ToastContainer />
      <Container fluid className="bg-gradient-info pt-5 pt-md-8 mb-5">
        <div style={{ paddingTop: "10px !important" }}>&nbsp;</div>
        <ProductivityFiltersWrapper
          filtersValues={productivityFilters}
          handleFilterChange={handleFiltersChange}
          employees={employeesList}
          teams={teamsList}
          territorializations={territorializationsData}
          handleFilterButtonClick={handleFilterButtonClick}
        />
        <ProductivityCardsWrapper
          fieldProductivityData={
            totalProductivityData && totalProductivityData.fieldProductivity
          }
          typingProductivityData={
            totalProductivityData && totalProductivityData.typingProductivity
          }
          visitsByStatusData={visitsStatusData && visitsStatusData.visits}
        />
      </Container>
      <Container fluid className="pb-6 mb-5">
        <ProductivityGraphicsWrapper
          fieldProductivityData={
            totalProductivityData && totalProductivityData.fieldProductivity
          }
          typingProductivityData={
            totalProductivityData && totalProductivityData.typingProductivity
          }
          visitsByStatusData={visitsStatusData && visitsStatusData.visits}
          consolidatedVisits={
            consolidatedVisitsData && consolidatedVisitsData.consolidatedVisits
          }
          employeesList={employeesList}
          territorializationsData={territorializationsData}
          productivityFilters={productivityFilters}
          goalsData={goalsData}
        />
        <ProductivityVisitsTable
          visitsData={visitsStatusData && visitsStatusData.visits}
          employees={employeesList}
          productivityFilters={productivityFilters}
        />
        <ConsolidatedProductivityTable
          visitsData={
            consolidatedVisitsData && consolidatedVisitsData.consolidatedVisits
          }
          employees={employeesList}
          productivityFilters={productivityFilters}
        />
        {/* {isUsersProductivityLoading ? (
          <TableLoadingSkeleton length={10} />
        ) : (
          <UsersProductivityTable productivityData={usersProductivityData} />
        )} */}
      </Container>
    </main>
  );
};

export default ProductivityPage;
