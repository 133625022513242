import request from '../utils/request';
// import request from '../utils/requestTest';
import { getUserData } from '../utils/auth';
export function verifyEmail(email){

  let _email = {
    'email' : email
  }

  return request({
    url: '/Emails',
    method: 'post',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    data:_email
  });
}
export function fetchEmployees(query) {

  var OrganizationId = getUserData('organizationId');

  return request({
    url: '/Employees/Organization/' + OrganizationId,
    method: 'get',
  });
}

export async function fetchPersons() {
  const organizationId = await getUserData("organizationId");

  return request({
    url: '/Persons/PersonsByOrganizations/'+ organizationId,
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });
}

export function fetchEmployee(id) {
  return request({
    url: '/Persons/'+id,
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });
}
export function fetchEmployeeData(id) {
  return request({
    url: '/Employees/'+id,
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });
}
export function deleteEmployee(id) {
  return request({
    url: '/Employees/'+id,
    method: 'delete',
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });
}
export function postEmployee(Employee, Person, User) {

  var personpost = Person;

  personpost['Employee'] = Employee;

  if(Employee.userPermission == 'Sim') {
    personpost['Users'] = User;
  }
  
  return request({
    url: '/Persons',
    method: 'post',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    data: personpost
    
  });
}

export function getSampleCounter(query) {

  var UserId = getUserData('userId');

  return request({
    url: '/Employees/GetCounterByUserId/' + UserId,
    method: 'get',
  });
}

export function addSampleCounter(query) {

  var UserId = getUserData('userId');

  return request({
    url: '/Employees/AddCounterByUserId/' + UserId,
    method: 'get',
  });
}



export function editEmployee(Employee, Person) {

  var personedit = Person;

  personedit['Employee'] = Employee;
  
  return request({
    url: '/Persons/'+Person.id,
    method: 'put',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    data: personedit
    
  });
}

export function fetchEmployeeFunctions(id) {
  return request({
    url: '/Employees/Functions/'+id,
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });
}
