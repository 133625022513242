import React from "react";
import {
    Col,
} from "reactstrap";


const TodayEpidemiologicalWeekComponent = (props) => {
    return (
        <Col className="col-4 linha-form col-md-4 geo-responsive-filter col"  style={{ display: "flex", alignItems: "center", justifyContent:"center", paddingTop: "10px" }}>
               <span className="h4 text-white" style={{marginBottom: 0}}> {`Semana epidemiológica atual: ${props.todayEpidemiologicalWeek}`} </span>
        </Col >
    );
}

export default TodayEpidemiologicalWeekComponent;