import React, { useEffect, useState } from "react";
import { LayersControl, Map, Marker, TileLayer } from "react-leaflet";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import Leaflet from "leaflet";
import { getUserEmployeeDataByOrganization } from "../../../services/api/Users";
import { updateVigilanciaEntomologica } from "../../../services/api/VigilanciaEntomologica";
import { checkPermissionComponent } from "../../../services/utils/permission";
import { toast, ToastContainer } from "react-toastify";
import { changeSample, fetchSamples, updateSample } from "../../../services/api/Sample";

const EditVisit = () => {
    const [visitPosition, setVisitPosition] = useState([0, 0]); 
    const [visit, setVisit] = useState(null); 
    const [employeeList, setEmployeeList] = useState(null); 
    const [samples, setSamples] = useState(null); 
    const [sampleChanged, setSampleChanged] = useState(false); 

    const key = 'AIzaSyCdkHjfiiztTeKSSuvfauQERlAJGFONeKo';

    useEffect(() => {
        const fetchData = async () => {
            const visitStorage = localStorage.getItem('VisitItem');
            const visit = JSON.parse(visitStorage);
            const employeeListResponse = await getUserEmployeeDataByOrganization();
            var filterSample = {
                "VisitId": visit.vigilanciaId
            }
            const samples = await fetchSamples(filterSample);

            setSamples(samples.data);
            setVisit(visit);

            if (visit && visit.latitude && visit.longitude) {
                setVisitPosition([visit.latitude, visit.longitude]);
            }
            
            if (employeeListResponse.data) {
                setEmployeeList(employeeListResponse.data);
            }
        };

        fetchData();
    }, []);

    const verificarTipoImovel = (tipoImovel) => {
        if(!tipoImovel) {
            return ""
        } else if (tipoImovel === "residencia") {
            return "Residência";
        } else if (tipoImovel === "comercio") {
            return "Comércio";
        } else if (tipoImovel === "terreno baldio") {
            return "Terreno Baldio";
        } else if (tipoImovel === "ponto estrategico") {
            return "Ponto Estratégico";
        } else if (tipoImovel === "proprio publico") {
            return "Próprio Público";
        } else if (tipoImovel === "imovel especial") {
            return "Imóvel Especial";
        } else if (tipoImovel === "via publica") {
            return "Via Pública";
        } else if (tipoImovel === "outros") {
            return "Outros";
        } else {
            return tipoImovel;
        }
    }

    const handleChangeSample = (field, index, value, sampleNumber) => {
        const newData = [...samples]
        const newDataVisit = {...visit}

        newData[index][field] = value;

        var optionsSample = {};

        Object.keys(visit.armadilha).map((key) => {
            if(key != "id" && key != "outra" && key != "createAt" && key != "updateAt" && key != "armadilhas" && key != "armadilhaParaMosquito") {
                visit.armadilha[key].map((trap, indexTrap) => {
                    trap.amostrasColetadas.map((amostraColetada, indexSample) => {
                        if(amostraColetada.numeroDaAmostra == sampleNumber) {
                            optionsSample =  {trapType: key, indexTrap: indexTrap, indexSample: indexSample}
                        }
                    })
                })
            }
        })

        var fieldVisit = field;

        if(field == "quantity") {
            fieldVisit = "quantidade";
        } else if(field == "number") {
            fieldVisit = "numeroDaAmostra";
        } else if(field == "typeMaterial") {
            fieldVisit = "tipoDeAmostra";
        }

        newDataVisit.armadilha[optionsSample.trapType][optionsSample.indexTrap].amostrasColetadas[optionsSample.indexSample][fieldVisit] = value;

        setSampleChanged(true);
        setSamples(newData);
        setVisit(newDataVisit);
    }

    const handleChange = (field, secondField, value) => {
        const newData = { ...visit };
    
        if (!secondField) {
            newData[field] = value;
        } else {
            if (typeof newData[field] === 'object' && newData[field] !== null) {
                newData[field][secondField] = value;
            } else {
                newData[field] = { [secondField]: value };
            }
        }
    
        setVisit(newData);
    };

    const saveVisit = async () => {
        const visitUpdate = visit;
        if(visitPosition && visitPosition.length > 0) {
            visitUpdate.latitude = visitPosition[0];
            visitUpdate.longitude = visitPosition[1];
        }
        if(sampleChanged) {
            await Promise.all([samples.map(async (sample) => {
                sample.updateSampleAnalysed = false;
                await changeSample(sample);
            })])
            toast.success("Amostra(s) alterada(s) com sucesso!")
        }

        await updateVigilanciaEntomologica(visitUpdate)

        toast.success("Visita alterada com sucesso!")
    }

    const handleMarkerOnDragEnd = (event) => {
        const { lat, lng } = event.target.getLatLng(); 
        setVisitPosition([lat, lng]);
    };

    return (
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
            {checkPermissionComponent("2d31ebee-53e3-4297-b498-4730e9b9e656", "update") 
            ?
                <div style={{margin: 30}}>  
                    <ToastContainer/>
                    <div className="map-container">
                        {
                            visit
                            ?
                            <>
                                <p className="h4 title-edit-visit">Localização Visita:</p>
                                    <Map center={visitPosition} zoom={16}>
                                        <LayersControl>
                                            <LayersControl.BaseLayer checked name="Normal">
                                                <TileLayer
                                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                            </LayersControl.BaseLayer>
                                            <LayersControl.BaseLayer name="Satélite">
                                                <ReactLeafletGoogleLayer googleMapsLoaderConf={{ KEY: key }} type={'hybrid'} />
                                            </LayersControl.BaseLayer>
                                        </LayersControl>
                                        <Marker
                                            draggable={true}
                                            position={visitPosition}
                                            icon={Leaflet.icon({
                                                iconUrl: require('../../../assets/img/icons/visita.png'),
                                                iconSize: [20, 20],
                                            })}
                                            onDragend={handleMarkerOnDragEnd}
                                        />
                                    </Map>
                                    <p className="h4 title-edit-visit" style={{marginTop: 20}}>Endereço:</p>
                                    <div className="row">
                                        <div className="col-4 content-input">
                                            <p className="h5">
                                                <span className="text-white">Complemento: </span>
                                                <input className="form-control" value={visit.address.geographicNumber} onChange={(e) => handleChange("address","geographicNumber" ,e.target.value)}  type="text" />
                                            </p>
                                        </div>
                                        <div className="col-4 content-input">
                                            <p className="h5">
                                                <span className="text-white">Local Do Imovel: </span>
                                                <select value={visit.address.localDoImovel ? visit.address.localDoImovel : ""} name={"localDoImovel"} className="form-control" onChange={(e) => handleChange("address", "localDoImovel", e.target.value)}>
                                                    <option value="Rua">Rua</option>
                                                    <option value="Avenida">Avenida</option>
                                                    <option value="Beco">Beco</option>
                                                    <option value="Viela">Viela</option>
                                                    <option value="Travessa">Travessa</option>
                                                    <option value="Marginal">Marginal</option>
                                                    <option value="Estrada">Estrada</option>
                                                    <option value="Via">Via</option>
                                                    <option value="Autoestrada">Autoestrada</option>
                                                    <option value="Trilha">Trilha</option>
                                                    <option value="Calçadão">Calçadão</option>
                                                    <option value="Alameda">Alameda</option>
                                                    <option value="Largo">Largo</option>
                                                    <option value="Picada">Picada</option>
                                                    <option value="Boulevard">Boulevard</option>
                                                    <option value="Corredor">Corredor</option>
                                                </select>
                                            </p>
                                        </div>
                                        <div className="col-4 content-input">
                                            <p className="h5">                                    
                                                <span className="text-white">Logradouro: </span>
                                                <input className="form-control" value={visit.address.logradouro} onChange={(e) => handleChange("address","logradouro" ,e.target.value)}  type="text" />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 content-input">
                                            <p className="h5">                                    
                                                <span className="text-white">Número: </span>
                                                <input className="form-control" value={visit.address.numero} onChange={(e) => handleChange("address","numero" ,e.target.value)}  type="text" />
                                            </p>
                                        </div>
                                        <div className="col-4 content-input">
                                            <p className="h5">                                    
                                                <span className="text-white">Quadra: </span>
                                                <input className="form-control" value={visit.address.quadra} onChange={(e) => handleChange("address","quadra" ,e.target.value)}  type="text" />
                                            </p>
                                        </div>
                                        <div className="col-4 content-input">

                                            <p className="h5">                                    
                                                <span className="text-white">Tipo do Imóvel: </span>
                                                
                                                <select value={verificarTipoImovel(visit.address.tipoImovel)} name={"tipoImovel"} className="form-control" onChange={(e) => handleChange("address", "tipoImovel", e.target.value)}>
                                                    <option value=""></option>

                                                    <option value="Residência">Residência (R)</option>
                                                    <option value="Comércio">Comércio (C)</option>
                                                    <option value="Terreno Baldio">Terreno Baldio (TB)</option>
                                                    <option value="Ponto Estratégico">Ponto Estratégico (PE)</option>
                                                    <option value="Próprio Público">Próprio Público (PP)</option>
                                                    <option value="Imóvel Especial">Imóvel Especial (IE)</option>
                                                    <option value="Via Pública">Via Pública (VP)</option>
                                                    <option value="Unidade de Saúde">Unidade de Saúde (US)</option>
                                                    <option value="Escola">Escola (E)</option>
                                                    <option value="Outros">Outros (O)</option>
                                                </select>
                                            </p>
                                        </div>
                                    </div>
                                    <p className="h4 title-edit-visit" style={{marginTop: 20}}>Funcionário:</p>
                                    <select value={visit.collectorId ? visit.collectorId : ""} name={"collectorId"} className="form-control" onChange={(e) => handleChange("collectorId", null, e.target.value)}>
                                        {employeeList && employeeList.map(employee => {
                                            if(employee.userId){
                                                return (
                                                    <option key={employee.userId} value={employee.userId}>{employee.employeeName}</option>
                                                )
                                            }
                                        })}
                                    </select>

                                    {
                                        samples && samples.length > 0
                                        ?
                                        <>
                                            <p className="h4 title-edit-visit" style={{marginTop: 20}}>Amostras Coletadas:</p>

                                            {
                                                samples.map((sample, index) => (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-4 content-input">
                                                                <span className="text-white">Número:</span>
                                                                {
                                                                    sample.status == "Analyzed"
                                                                    ?
                                                                    <input className="form-control" disabled={true} value={"Essa amostra já foi analisada"} />
                                                                    :
                                                                    <input className="form-control" value={sample.number} onChange={(e) => handleChangeSample("number", index, e.target.value, sample.number)} type="text" />
                                                                }
                                                            </div>
                                                            <div className="col-4 content-input">
                                                                <span className="text-white">Tipo de amostra:</span>
                                                                {
                                                                    sample.status == "Analyzed"
                                                                    ?
                                                                    <select className="form-control" disabled={true} value={"Essa amostra já foi analisada"} >
                                                                        <option value="Essa amostra já foi analisada">Essa amostra já foi analisada</option>
                                                                    </select>
                                                                    :
                                                                    <select value={sample.typeMaterial} name={"typeMaterial"} className="form-control"  onChange={(e) => handleChangeSample("typeMaterial",index,  e.target.value, sample.number)} >
                                                                        <option value="Água">Água</option>
                                                                        <option value="Mosquito Adulto">Mosquitos Adultos</option>
                                                                        <option value="Larvas">Larvas</option>
                                                                        <option value="Larvas e Pupas">Larvas e Pupas</option>
                                                                        <option value="Pupas">Pupas</option>
                                                                        <option value="Palheta">Palheta</option>
                                                                    </select>
                                                                }
                                                                
                                                            </div>
                                                            <div className="col-4 content-input">
                                                                <span className="text-white">Quantidade:</span>
                                                                {
                                                                    sample.status == "Analyzed"
                                                                    ?
                                                                    <input className="form-control" disabled={true} value={"Essa amostra já foi analisada"} />
                                                                    :
                                                                    <input className="form-control" value={sample.quantity} onChange={(e) => handleChangeSample("quantity",index,  parseInt(e.target.value), sample.number)} type="number" />
                                                                }
                                                            </div>
                                                        </div>
                                                        

                                                    </>
                                                ))
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                                    
                                    <div className="col-4" style={{marginTop: 30, padding: 0}}>
                                        <button className="btn btn-primary" onClick={() => saveVisit()} >Salvar</button>
                                    </div> 
                            </>
                            :
                            <></>
                        }
                        
                    </div>
                </div>
            :
                <div style={{margin: 30, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column', gap: '1rem'}}>
                    <i class="fa fa-exclamation-triangle" style={{color: '#ffffff', fontSize : "100px"}} ></i>
                    <p className="h3 text-white">Atenção! Você não tem permissão para editar os dados nesta tela!</p>
                </div>
            }
        </div>
    );
};

export default EditVisit;
