import React, { Component } from "react";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import CardPresentational from "../../../Presentational/CardPresentational";
import { getUserData } from "../../../../services/utils/auth";

class VistoriasArmadilhasContainer extends Component{

    state = {
        vistoryNumber:0,
        textCard:"Total de vistorias realizadas no período",
    }

    componentDidMount(){
        const {data} = this.props;
        if(data !== undefined && data.length > 0){
            this.buildDataToCard()
        }
        
    }

    async buildDataToCard(){
        const {trapTypeName} = this.props;
        const {data} = this.props;

        let textCard;
        
        const organizationId = getUserData("organizationId");
        
        if(organizationId === "24d125ef-15e6-4bd9-9111-c0b3d50a8009")
            textCard = "Total de ADI em processo de instalação"
        else textCard = `Total vistorias realizadas em ${ARMADILHA_INFO[trapTypeName].apelido}`;

        let vistoryNumber = 0;
        for(const i in data){
            for(const j in data[i]){ 
                if(j !== "label"){
                    vistoryNumber += data[i][j].inspections;
                }
            }
        }

        this.setState({vistoryNumber, textCard});
    }

    componentDidUpdate(prevProps){
        const {data} = this.props; 
        if(data !== undefined && data !== prevProps.data){
            this.buildDataToCard();
        }
    }

    

    render(){
        const {vistoryNumber, textCard} = this.state
        return(
            <CardPresentational 
                number={vistoryNumber}
                text={textCard}
            />
        )
    }
}

export default VistoriasArmadilhasContainer