import React, { useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import { fetchDemands } from "../../../services/api/Demand";

import { Card, CardHeader, Container, Row, Col, Table } from "reactstrap";

// core components

import Header from "components/Headers/Header.jsx";
import { timeStampFormatedMongo } from "../../../services/utils/format";
import { fetchUsers } from "../../../services/api/Users";
import { fetchEmployeeData } from "../../../services/api/Employee";
import { getVisitsVigilanciaByDemandId } from "../../../services/api/Visits";
import { Select } from "../../../components/Wrappers/SelectAll";
import LoadingSpin from "react-loading-spin";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";
import exportVisitToCsv from "../../../services/utils/exportVisitToCsv";

const VisitExport = (React.FC = () => {
  const [visits, setVisits] = useState([]);
  const [users, setUsers] = useState([]);
  const [demandId, setDemandId] = useState([]);
  const [demands, setDemands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetchDemands().then((response) => {
      const demandsSelect = [];

      for (const element of response.data) {
        demandsSelect.push({
          label: element.serviceOrder + " - " + element.type,
          value: element.id,
          DemandId: element.id,
        });
      }

      setDemands(demandsSelect);

      fetchUsers().then((response) => {
        setUsers(response.data);
      });
    });
  }, []);

  const pesquisar = async () => {
    setLoading(true);

    let visits = [];

    for (const opt of demandId) {
      const { data } = await getVisitsVigilanciaByDemandId(opt.DemandId);
      visits = [...visits, ...data];
    }

    var filteredTraps = await getCollectorName(visits);

    setVisits(filteredTraps);
    setLoaded(true);
  };

  const getCollectorName = async (data) => {
    const collectors = [];

    //data eh a visita
    for (const obj of data) {
      obj.activityDate = timeStampFormatedMongo(obj.activityDate);

      for (const element of users) {
        if (obj.collectorId == element.id) {
          const employeeId = element.employeeId;

          if (!collectors.some((collector) => collector.id === employeeId)) {
            let { data } = await fetchEmployeeData(employeeId);
            collectors.push({ id: data.id, name: data.name });
          }

          obj.funcionario = collectors.filter(
            (collector) => collector.id === element.employeeId
          )[0].name;
          obj.demanda = demands.filter(
            (demand) => demand.DemandId == obj.demandId
          )[0].label;
          delete obj.midias;
        }
      }
    }

    setLoading(false);

    return data;
  };

  return (
    <>
      <ToastContainer />
      <Header />
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <div className="row linha-form">
              <div className="linha-form col-4 responsive-filter-field-sample-medium">
                <Select
                  onChange={(e) => setDemandId(e === null ? [] : e)}
                  value={demandId}
                  options={demands}
                  placeholder="Demanda"
                  isMulti={true}
                  allowSelectAll={true}
                  allOption={{ label: "Todos", value: "*" }}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      minHeight: "2.90rem",
                      borderRadius: "0.370rem",
                    }),
                  }}
                />
              </div>

              <div className="col-4 responsive-filter-field-sample-medium">
                <button onClick={pesquisar} className="btn btn-primary">
                  {loading === false ? (
                    <>
                      <i className="fa fa-search" /> Pesquisar
                    </>
                  ) : (
                    <>
                      <LoadingSpin primaryColor="#fff" size={22} /> buscando
                      dados..
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Container className="mt--7" fluid>
        <Row className="mt-12">
          {!loading && (
            <Col className="mb-12 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">{visits.length} Visitas</h3>
                    </div>
                  </Row>
                </CardHeader>

                {loaded && (
                  <button
                    onClick={() => exportVisitToCsv(visits)}
                    className="btn btn-primary"
                  >
                    {" "}
                    <i className="fa fa-download" /> Baixar CSV das visitas
                  </button>
                )}

                <br />

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" colSpan={2}>
                        Consumo manual:
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loaded &&
                      demandId.map((demand) => (
                        <tr key={demand.DemandId}>
                          <td>{demand.label}</td>
                          <td>
                            <a
                              href={
                                "https://api.vitecweb.com.br/api/VigilanciaEntomologica/GetByDemand/" +
                                demand.DemandId
                              }
                            >
                              {" "}
                              {"https://api.vitecweb.com.br/api/VigilanciaEntomologica/GetByDemand/" +
                                demand.DemandId}{" "}
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>

                <br />

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Dt. Atividade</th>
                      <th>Demanda</th>
                      <th>Formulário</th>
                      <th>Agente</th>
                      <th>Situação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {visits.map((visit) => (
                      <tr key={visit.vigilanciaId}>
                        <td scope="row">{visit.activityDate}</td>
                        <td>{visit.demanda}</td>
                        <td>{visit.typeForm}</td>
                        <td>{visit.funcionario}</td>
                        <td>{visit.visitSituation}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
});
export default VisitExport;
