import React from "react";

// reactstrap components
import { Card, CardBody, } from "reactstrap";
import CardText from "reactstrap/lib/CardText";

const CardPresentational = (props) => {
    const textDefaultStyle = {
        fontSize:"0.95rem",
        color:"black",
    }

    const titleDefaultStyle = {
        fontSize:"1rem",
        fontWeight:"bold",
        color:"black",
    }

    const style = {
        cardBody:{
            height:"8rem",
            display:"flex",
            flexDirection:"column",
            justifyContent:"center",
            textAlign:"center",
        },
        title: props.titleStyle ? props.titleStyle : titleDefaultStyle,
        text:props.textStyle ? props.textStyle : textDefaultStyle,
    };

    return (
        <Card className="card-stats mb-4">
            <CardBody height="100%" style={style.cardBody}>
                <CardText style={style.title} className="mb-0 mt-0">
                    {props.number}
                </CardText>
                <CardText style={style.text}>
                    {props.text}
                </CardText>
            </CardBody>
        </Card>
    )
}


export default CardPresentational;