import React from "react";
import { fetchDemands } from "../../../../services/api/Demand";
import { toast } from "react-toastify";
import { Button, Col, Container, Row } from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";
import { getAllVigilanciasByFilter } from "../../../../services/api/Visits";
import TableLoadingSkeleton from "../../../../components/ui/Loading/TableLoadingSkeleton";
import {
  fetchUsers,
  getUserEmployeeDataByOrganization,
} from "../../../../services/api/Users";
import VisitsExportTable from "./VisitsExportTable";
import { fetchEmployeeData } from "../../../../services/api/Employee";
import { timeStampFormatedMongo } from "../../../../services/utils/format";
import { getUserData } from "../../../../services/utils/auth";
import {
  getMonthsOptions,
  getYearsOptions,
} from "../../../../services/utils/date";
import { fetchTerritorializations } from "../../../../services/api/territorialization";

const VisitsExport = () => {
  const [visitsData, setVisitsData] = React.useState(null);
  const [employeesData, setEmployeesData] = React.useState(null);
  const [filteredVisitsData, setFilteredVisitsData] = React.useState(null);
  const [isFilteredVisitsDataLoading, setIsFilteredVisitsDataLoading] =
    React.useState(false);
  const [dataForTable, setDataForTable] = React.useState(null);
  const [organizationId, setOrganizationId] = React.useState(null);
  const [demandsOptions, setDemandsOptions] = React.useState([]);
  const [isVisitsDataLoading, setIsVisitsDataLoading] = React.useState(false);
  const [uvisOptions, setUvisOptions] = React.useState([]);
  const [visitsFilters, setVisitsFilters] = React.useState({
    demand: {
      label: "Selecione uma demanda",
      value: "",
    },
    year: {
      label: "Selecione um ano",
      value: "",
    },
    month: {
      label: "Selecione um mês",
      value: "00",
      periods: {
        beginDate: "00-00",
        finalDate: "00-00",
      },
    },
    uvis: {
      label: "Selecione uma uvis",
      value: "",
    },
  });

  const monthsOptions = [
    {
      label: "Selecione um mês",
      value: "",
      periods: {
        beginDate: "00-00",
        finalDate: "00-00",
      },
    },
    ...getMonthsOptions(),
  ];

  const yearsOptions = [
    {
      label: "Selecione um ano",
      value: "",
    },
    ...getYearsOptions(),
  ];

  React.useEffect(() => {
    fetchDemandsData();
    fetchEmployeesData();
    var organizationId = getUserData("organizationId");
    setOrganizationId(organizationId);

    fetchTerritorializations("553b3f0a-7e72-4e07-b170-ad042f595bc2").then(
      (uvis) => {
        var uvisOptionsAux = [];
        uvisOptionsAux.push(...uvisOptions);
        if (uvis && uvis.data.length > 0) {
          uvis.data.map((uvisContent) => {
            uvisOptionsAux.push({
              label: uvisContent.name,
              value: uvisContent.id,
            });
          });
        }

        setUvisOptions(uvisOptionsAux);
      }
    );
  }, []);

  React.useEffect(() => {
    if (!visitsData || filteredVisitsData) return;

    setIsFilteredVisitsDataLoading(true);

    const filteredTrapsData = {
      armadilhaDisseminadoraInseticida: [],
      armadilhaOvos: [],
      armadilhaMosquitoAdulto: [],
      armadilhaMosquitoAdulto2: [],
      estacaoDisseminadoraLarvicida: [],
    };
    const filteredDepositsData = [];
    const formatedDataForTable = [];

    /* const fetchCompleteVD = async () => {
      setCompleteVisitData(await getCollectorName(visitsData));
    };

    fetchCompleteVD(); */

    visitsData.forEach((visit) => {
      Object.keys(getTrapTypeSurname).forEach((trapType) => {
        if (visit.armadilha[trapType]) {
          visit.armadilha[trapType].forEach((_, index) => {
            const trapData = formatTrapData(
              visit,
              visitsFilters.demand.label,
              trapType,
              index
            );

            if (trapData) filteredTrapsData[trapType].push(trapData);
          });
        }
      });

      filteredDepositsData.push(
        formatDepositsData(visit, visitsFilters.demand.label)
      );

      const dataForTable = {
        activityDate: formatDateToSaoPauloTimezone(visit.activityDate),
        demand: visitsFilters.demand.label,
        uvis: visit.uvis,
        typeForm: visit.typeForm,
        collector: getEmployeeNameById(visit.collectorId),
        situation: visit.visitSituation,
      };

      formatedDataForTable.push(dataForTable);
    });

    setDataForTable(formatedDataForTable);
    setFilteredVisitsData({
      traps: filteredTrapsData,
      deposits: filteredDepositsData,
    });

    setIsFilteredVisitsDataLoading(false);
  }, [visitsData]);

  const handleFiltersChange = (filterName, newValue) => {
    setVisitsFilters((previousValue) => {
      const newValues = { ...previousValue };
      newValues[filterName] = newValue;

      return newValues;
    });
  };

  const fetchVisitsData = async () => {
    if (
      visitsFilters.demand.value === "" ||
      visitsFilters.month.value === "" ||
      visitsFilters.year.value === ""
    ) {
      toast.error("É necessário selecionar a demanda, o mês e o ano.");
      return;
    }

    if (
      organizationId == "553b3f0a-7e72-4e07-b170-ad042f595bc2" &&
      visitsFilters.uvis.value === ""
    ) {
      toast.error("É necessário selecionar uma uvis.");
      return;
    }

    setIsVisitsDataLoading(true);

    const requestParams = {
      organizationId: organizationId,
      demandId: visitsFilters.demand.value,
      periods: {
        beginDate: `${visitsFilters.year.value}-${visitsFilters.month.periods.beginDate}`,
        finalDate: `${visitsFilters.year.value}-${visitsFilters.month.periods.finalDate}`,
      },
      territorializations: visitsFilters.uvis.value
        ? [visitsFilters.uvis.value]
        : [],
    };

    try {
      const { data, status } = await getAllVigilanciasByFilter(requestParams);
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados das VISITAS. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      setFilteredVisitsData(null);
      setVisitsData(data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    } finally {
      setIsVisitsDataLoading(false);
    }
  };

  /* const getCollectorName = async (data) => {
    const collectors = [];

    for (const obj of data) {
      obj.activityDate = timeStampFormatedMongo(obj.activityDate);

      for (const element of users) {
        if (obj.collectorId == element.id) {
          const employeeId = element.employeeId;

          if (!collectors.some((collector) => collector.id === employeeId)) {
            let { data } = await fetchEmployeeData(employeeId);
            collectors.push({ id: data.id, name: data.name });
          }

          obj.funcionario = collectors.filter(
            (collector) => collector.id === element.employeeId
          )[0].name;
          obj.demanda = demandsOptions.filter(
            (demand) => demand.value == obj.demandId
          )[0].label;
          delete obj.midias;
        }
      }
    }

    return data;
  };
 */
  const fetchDemandsData = async () => {
    try {
      const { data, status } = await fetchDemands();

      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados das demandas. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      const demandsOptions = data.map((demand) => {
        return {
          label: `${demand.serviceOrder} - ${demand.type}`,
          value: demand.id,
        };
      });

      setDemandsOptions([
        {
          label: "Selecione uma demanda",
          value: "",
        },
        ...demandsOptions,
      ]);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const fetchEmployeesData = async () => {
    try {
      const { data, status } = await getUserEmployeeDataByOrganization();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados dos USUÁRIOS. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      setEmployeesData(data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const formatAddressData = (visit, demandNumber) => {
    if (!visit || !demandNumber) return;

    return {
      VISITA_ID:
        visit.vigilanciaId === "" || !visit.vigilanciaId
          ? "VisitId not found"
          : visit.vigilanciaId,
      NUMERO_DEMANDA: demandNumber,
      DATA_ATIVIDADE: visit.activityDate,
      AGENTE: getEmployeeNameById(visit.collectorId),
      QUARTEIRAO:
        visit.address.quadra === "" || !visit.address.quadra
          ? "Näo informado"
          : visit.address.quadra,
      RUA: visit.address.logradouro,
      NUMERO: visit.address.numero,
      TIPO_IMOVEL: visit.address.tipoImovel,
      FORMULARIO: visit.typeForm,
      NOME_RESPONSAVEL:
        !visit.residents ||
        visit.residents.dadosPrincipais.name === "" ||
        !visit.residents.dadosPrincipais.name
          ? "Não informado"
          : visit.residents.dadosPrincipais.name,
      NUMERO_RESPONSAVEL:
        !visit.residents ||
        visit.residents.dadosPrincipais.phone === "" ||
        !visit.residents.dadosPrincipais.phone
          ? "Não informado"
          : visit.residents.dadosPrincipais.phone,
      HORA_INICIO:
        visit.initialTime === "" || !visit.initialTime
          ? "Não obtida"
          : formatDateToSaoPauloTimezone(visit.initialTime, true),
      HORA_FIM:
        visit.finalTime === "" || !visit.finalTime
          ? "Não obtida"
          : formatDateToSaoPauloTimezone(visit.finalTime, true),
      LATITUDE: visit.latitude,
      LONGITUDE: visit.longitude,
      SITUACAO_VISITA: visit.visitSituation,
    };
  };

  const formatDepositsData = (visit, demandNumber) => {
    if (!visit || !demandNumber) return;

    const depositsType = ["a1", "a2", "b", "c", "d1", "d2", "e"];
    const formatedDeposits = { ...formatAddressData(visit, demandNumber) };

    depositsType.forEach((deposit) => {
      const depositIdentifier = deposit.toUpperCase();

      formatedDeposits[`${depositIdentifier}_POSITIVO`] = visit.mosquitos
        .depositosPositivos[deposit]
        ? "Sim"
        : "Não";

      formatedDeposits[`${depositIdentifier}_QTD_TOTAL_DEPOSITOS`] =
        visit.mosquitos[deposit].quantidadeDepositos === "" ||
        visit.mosquitos[deposit].quantidadeDepositos === null
          ? 0
          : visit.mosquitos[deposit].quantidadeDepositos;

      formatedDeposits[`${depositIdentifier}_QTD_DEPOSITOS_POSITIVOS`] =
        visit.mosquitos[deposit].quantidadeDepositoMosquitoImaturo === "" ||
        visit.mosquitos[deposit].quantidadeDepositoMosquitoImaturo === null
          ? 0
          : visit.mosquitos[deposit].quantidadeDepositoMosquitoImaturo;

      formatedDeposits[`${depositIdentifier}_QTD_DEPOSITOS_ELIMINADOS`] =
        visit.mosquitos[deposit].quantidadeDepositoEliminado === "" ||
        visit.mosquitos[deposit].quantidadeDepositoEliminado === null
          ? 0
          : visit.mosquitos[deposit].quantidadeDepositoEliminado;

      formatedDeposits[`${depositIdentifier}_TRATAMENTO_LARVICIDA`] = visit
        .mosquitos[deposit].larvicidaL1
        ? "Sim"
        : "Não";

      formatedDeposits[`${depositIdentifier}_L1`] =
        visit.mosquitos[deposit].quantidadeDepositosL1 === "" ||
        visit.mosquitos[deposit].quantidadeDepositosL1 === null
          ? "Não"
          : "Sim";

      formatedDeposits[`${depositIdentifier}_QTD_DEPOSITOS_TRATADOS_L1`] =
        visit.mosquitos[deposit].quantidadeDepositosL1 === "" ||
        visit.mosquitos[deposit].quantidadeDepositosL1 === null
          ? 0
          : visit.mosquitos[deposit].quantidadeDepositosL1;

      formatedDeposits[`${depositIdentifier}_LARVICIDA_UTILIZADO_L1`] =
        visit.mosquitos[deposit].larvicidaL1Utilizado === "" ||
        visit.mosquitos[deposit].larvicidaL1Utilizado === null
          ? "Não utilizado"
          : visit.mosquitos[deposit].larvicidaL1Utilizado;

      formatedDeposits[`${depositIdentifier}_QTD_LARVICIDA_UTILIZADO_L1`] =
        visit.mosquitos[deposit].quantidadeProdutosL1 === "" ||
        visit.mosquitos[deposit].quantidadeProdutosL1 === null
          ? 0
          : visit.mosquitos[deposit].quantidadeProdutosL1;

      formatedDeposits[`${depositIdentifier}_L2`] =
        visit.mosquitos[deposit].quantidadeDepositosL2 === "" ||
        visit.mosquitos[deposit].quantidadeDepositosL2 === null
          ? "Não"
          : "Sim";

      formatedDeposits[`${depositIdentifier}_QTD_DEPOSITOS_TRATADOS_L2`] =
        visit.mosquitos[deposit].quantidadeDepositosL2 === "" ||
        visit.mosquitos[deposit].quantidadeDepositosL2 === null
          ? 0
          : visit.mosquitos[deposit].quantidadeDepositosL2;

      formatedDeposits[`${depositIdentifier}_LARVICIDA_UTILIZADO_L2`] =
        visit.mosquitos[deposit].larvicidaL2Utilizado === "" ||
        visit.mosquitos[deposit].larvicidaL2Utilizado === null
          ? "Não utilizado"
          : visit.mosquitos[deposit].larvicidaL2Utilizado;

      formatedDeposits[`${depositIdentifier}_QTD_LARVICIDA_UTILIZADO_L2`] =
        visit.mosquitos[deposit].quantidadeProdutosL2 === "" ||
        visit.mosquitos[deposit].quantidadeProdutosL2 === null
          ? 0
          : visit.mosquitos[deposit].quantidadeProdutosL2;

      formatedDeposits[
        `${depositIdentifier}_DEPOSITO_NAO_POSSIVEL_INSPECIONAR`
      ] = visit.mosquitos[deposit].depositoNaoInspecionado ? "Sim" : "Não";
    });

    return formatedDeposits;
  };

  const formatTrapData = (visit, demandNumber, trapType, index) => {
    if (!visit || !demandNumber || !trapType) return;
    if (
      !visit.armadilha[trapType] ||
      !visit.armadilha[trapType][index].numeroArmadilha ||
      visit.armadilha[trapType][index].numeroArmadilha === ""
    )
      return;

    const trap = visit.armadilha[trapType][index];

    if (trap.numeroArmadilha !== "" || trap.numeroArmadilha !== null) {
      const formatedTrap = {
        ...formatAddressData(visit, demandNumber),
        NUMERO_ARMADILHA: trap.numeroArmadilha,
        TIPO_ARMADILHA: getTrapTypeSurname[trapType],
        LATITUDE: trap.latitude,
        LONGITUDE: trap.longitude,
        ARMADILHA_INSTALADA: trap.armadilhaInstaladaNaVisita ? "Sim" : "Não",
        ARMADILHA_LIDA: trap.armadilhaLidaNaVisita ? "Sim" : "Não",
        ARMADILHA_DESINSTALADA: trap.armadilhaRetirada ? "Sim" : "Não",
        ARMADILHA_DESINSTALADA_MOTIVO:
          trap.armadilhaRetiradaMotivo === "" || !trap.armadilhaRetiradaMotivo
            ? "Não informado"
            : trap.armadilhaRetiradaMotivo,
        ARMADILHA_EM_CONDICOES_USO: trap.isReadableCondition ? "Sim" : "Não",
        ARMADILHA_SEM_CONDICOES_USO_MOTIVO:
          trap.readableConditionMotive === "" || !trap.readableConditionMotive
            ? "Não informado"
            : trap.readableConditionMotive,
        ESTADO_ETIQUETA:
          trap.estadoEtiqueta === "" || !trap.estadoEtiqueta
            ? "Não informado"
            : trap.readableConditionMotive,
        PREDADORES: generateTrapPredatorsText(trap),
        MANUTENCOES_REALIZADA: generateTrapMaintenancesText(trap),
        DEFEITOS: generateTrapDefectsText(trap),
        PECAS_TROCADA: generateTrapDefectsText(trap),
        EMERGENCIA_MOSQUITOS: generateMosquitoesEmergencyText(trap),
        GERADAS_AMOSTRAS: trap.amostras ? "Sim" : "Não",
        DADOS_AMOSTRAS: trap.amostras
          ? generateSamplesText(trap.amostrasColetadas)
          : "Amostras não coletadas",
      };

      return formatedTrap;
    }
  };

  const generateTrapPredatorsText = (trap) => {
    if (!trap) return;

    const predators = [];

    if (trap.aranha) predators.push("Aranha");
    if (trap.formiga) predators.push("Formiga");
    if (trap.lagartixa) predators.push("Lagartixa");
    if (
      trap.outroPredadorDescrever !== "" ||
      trap.outroPredadorDescrever !== null
    )
      predators.push(trap.outroPredadorDescrever);

    if (predators.length === 0) return "Não informado";

    return predators.join(", ");
  };

  const generateTrapMaintenancesText = (trap) => {
    if (!trap) return;

    if (!trap.manutencao.manutencao) return "Não realizada";

    const maintenances = [];

    Object.keys(trap.manutencao).forEach((maintenance) => {
      if (
        trap.manutencao[maintenance] &&
        maintenance !== "defeito" &&
        maintenance !== "defeitoTroca" &&
        maintenance !== "manutencao"
      )
        maintenances.push(maintenance);
    });

    return maintenances.join(", ");
  };

  const generateTrapDefectsText = (trap) => {
    if (!trap) return;

    if (
      trap.manutencao.defeito === false ||
      !trap.manutencao.defeito ||
      !trap.manutencao.defeito.defeito
    )
      return "Não informado";

    const defects = [];

    Object.keys(trap.manutencao.defeito).forEach((defect) => {
      if (trap.manutencao.defeito[defect]) defects.push(defect);
    });

    return defects.join(", ");
  };

  const generateMosquitoesEmergencyText = (trap) => {
    if (
      !trap ||
      !trap.emergenciaMosquitoArmadilha ||
      trap.emergenciaMosquitoArmadilha === ""
    )
      return "Não informado";

    const defects = [];

    Object.keys(trap.emergenciaMosquitoArmadilha).forEach((defect) => {
      if (trap.emergenciaMosquitoArmadilha[defect]) defects.push(defect);
    });

    if (defects.length === 0) return "Não informado";

    return defects.join(", ");
  };

  const generateSamplesText = (samples) => {
    if (!samples || samples.length === 0) return;

    const samplesData = [];

    samples.forEach((sample) => {
      const sampleType = sample.tipoDeAmostra;
      const sampleNumber =
        sample.numeroDaAmostra === ""
          ? "Não informado"
          : sample.numeroDaAmostra;
      const sampleQuantity =
        sample.quantidade === "" ? "Não informado" : sample.quantidade;

      const sampleText = `Tipo: ${sampleType} / Número: ${sampleNumber} / Quantidade: ${sampleQuantity}`;

      samplesData.push(sampleText);
    });

    return samplesData.join(", ");
  };

  const getEmployeeNameById = (id) => {
    if (!id || id === "00000000-0000-0000-0000-000000000000")
      return "Funcionário não encontrado";

    const employeeByUserId = employeesData.find(
      (employee) => employee.userId === id
    );
    if (employeeByUserId) return employeeByUserId.employeeName;

    const employeeByEmployeeId = employeesData.find(
      (employee) => employee.employeeId === id
    );
    if (employeeByEmployeeId) return employeeByEmployeeId.employeeName;

    return "Funcionário não encontrado";
  };

  const formatDateToSaoPauloTimezone = (dateToFormat, displayHours) => {
    if (!dateToFormat) return;

    let dateFormatOptions;

    if (displayHours)
      dateFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone: "America/Sao_Paulo",
      };
    else
      dateFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour12: false,
        timeZone: "America/Sao_Paulo",
      };

    const date = new Date(dateToFormat);
    const dateFormated = date.toLocaleString("pt-BR", dateFormatOptions);

    return dateFormated;
  };

  const getTrapTypeSurname = {
    armadilhaDisseminadoraInseticida: "ADI",
    armadilhaOvos: "OVITRAMPA",
    armadilhaMosquitoAdulto: "ADULTRAP",
    armadilhaMosquitoAdulto2: "BG-GAT",
    estacaoDisseminadoraLarvicida: "EDL",
  };

  const selectComponentStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
  };

  return (
    <Container fluid style={{ marginTop: "2rem" }}>
      <Row>
        <Col xl={4} style={{ marginBottom: "2rem" }}>
          <span style={{ fontWeight: "bold", color: "white" }}>Demandas</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            options={demandsOptions}
            value={visitsFilters.demand}
            onChange={(selectedOptionObject) =>
              handleFiltersChange("demand", selectedOptionObject)
            }
          />
        </Col>
        <Col xl={4} style={{ marginBottom: "2rem" }}>
          <span style={{ fontWeight: "bold", color: "white" }}>Mês</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            options={monthsOptions}
            value={visitsFilters.month}
            onChange={(selectedOptionObject) =>
              handleFiltersChange("month", selectedOptionObject)
            }
          />
        </Col>
        <Col xl={4} style={{ marginBottom: "2rem" }}>
          <span style={{ fontWeight: "bold", color: "white" }}>Ano</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            options={yearsOptions}
            value={visitsFilters.year}
            onChange={(selectedOptionObject) =>
              handleFiltersChange("year", selectedOptionObject)
            }
          />
        </Col>
        {organizationId == "559a16ab-5da8-41ba-96b8-6e9f55feddd8" ? (
          <Col xl={4} style={{ marginBottom: "2rem" }}>
            <span style={{ fontWeight: "bold", color: "white" }}>UVIS</span>
            <Select
              styles={selectComponentStyles}
              placeholder={"Selecione"}
              isClearable={false}
              options={uvisOptions}
              value={visitsFilters.uvis}
              onChange={(selectedOptionObject) =>
                handleFiltersChange("uvis", selectedOptionObject)
              }
            />
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <Row>
        <Col xl={4} style={{ marginBottom: "2rem" }}></Col>
        <Col xl={4} style={{ marginBottom: "2rem" }}>
          <Button color="primary" onClick={() => fetchVisitsData()}>
            Buscar histórico de visitas
          </Button>
        </Col>
        <Col xl={4} style={{ marginBottom: "2rem" }}></Col>
      </Row>
      {isFilteredVisitsDataLoading || isVisitsDataLoading ? (
        <TableLoadingSkeleton />
      ) : dataForTable && filteredVisitsData ? (
        <VisitsExportTable
          visitsForTable={dataForTable}
          visitsToExport={filteredVisitsData}
        />
      ) : null}
    </Container>
  );
};

export default VisitsExport;
