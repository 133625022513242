import request from '../utils/request';
//import request from "../utils/requestTests/requestTestVisits"

export function getVigilanciaEntomologicaByDemandId(DemandId){
  return request({
    url: '/VigilanciaEntomologica/GetByDemand/'+DemandId,
    method: 'get'
  });
}

export function getVigilanciaEntomologicaById(Id){
  return request({
    url: '/VigilanciaEntomologica/'+ Id,
    method: 'get'
  });
}

export function updatePositionVigilanciaEntomologica(vigilanciaEntomologica){
  return request({
    url: '/VigilanciaEntomologica/UpdatePosition',
    method: 'post',
    data:vigilanciaEntomologica
  });
}

export function updateVigilanciaEntomologica(vigilanciaEntomologica){
  return request({
    url: '/VigilanciaEntomologica/Update',
    method: 'put',
    data:vigilanciaEntomologica
  });
}

export function postVigilanciaEntomologica(form){
  return request({
    url: '/VigilanciaEntomologica',
    method: 'post',
    data: form,
  });
}

export function fetchProductivity(filters){
  return request({
    url: '/VigilanciaEntomologica/Productivity',
    method: 'post',
    data: filters,
  });
}
