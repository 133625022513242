
import { getPermissionsToken, resetRoutes } from "./auth";
import routes from "../../routes.js";

export function getFirstPermissionRoute(){
    let routesFiltered = resetRoutes(routes);
    let posicao = 0;
    for(let i = 0; i < routesFiltered.length; i++){
        if(routesFiltered[i].moduleId != "00000000-0000-0000-0000-000000000000"){
        posicao = i;
        break;
        }
    }
    return routesFiltered[posicao]["layout"] + routesFiltered[posicao]["path"];
}

export function checkPermissionComponent(moduleId, operation){
    const permissions = getPermissionsToken();
    if(!(permissions[moduleId] && permissions[moduleId][operation])){
        return false;
    }else{
        return true;
    }
}