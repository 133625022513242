import request from '../utils/request';
export function getIIP(filter){
    return request({
      url: '/DepositReport/IIP',
      method: 'post',
      data:filter
    });
  }

  export function getIB(filter){
    return request({
      url: '/DepositReport/IB',
      method: 'post',
      data:filter
    });
  }
  export function getITR(filter){
    return request({
      url: '/DepositReport/ITR',
      method: 'post',
      data:filter
    });
  }
  export function getDepositMapInfo(filter){
    return request({
      url: '/DepositReport/MapInfo',
      method: 'post',
      data:filter
    });
  }