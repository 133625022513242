import React, {useState} from "react";

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="recharts-default-tooltip" style={{margin: '0px', padding: '10px',backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap'}}>
          {label != 'Período estipulado' ?           
          <p className="recharts-tooltip-label" style={{margin: '0px'}}>{`${label}`}</p>
          : <></>}
          <p className="recharts-tooltip-label" style={{margin: '0px', color: "#c7d489", fontWeight: '400'}}>{`Feminino : ${payload[0].value}`}</p>
          <p className="recharts-tooltip-label" style={{margin: '0px', color: "#6ba0a0", fontWeight: '400'}}>{`Masculino : ${payload[1].value}`}</p>
          <p className="recharts-tooltip-label" style={{margin: '0px', fontWeight: '900'}}>{`Total : ${payload[0].value + payload[1].value}`}</p>            
        </div>
      );
    }
  
    return null;
  };

export default CustomTooltip