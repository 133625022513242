import React, { useState } from "react";

const returnFormula = (pl, type) => {
    if(type == "IPO") {
        return `${pl.name}: ${pl.payload.positives}/${pl.payload.examinated} x 100`;
    } else if(type == "IDO") {
        return `${pl.payload.eggs}/${pl.payload.positives}`;
    } else if(type == "IMO") {
        return `${pl.payload.eggs}/${pl.payload.inspected}`;
    } else if(type == "IMA") {
        return `${pl.payload.eggs}/${pl.payload.inspected}`;
    } else if(type == "IPA") {
        return `${pl.payload.positives}/${pl.payload.vistories} x 100`;
    } else if(type == "IDA") {
        return `${pl.payload.eggs}/${pl.payload.positives}`;
    }  else if (type == "Pendents") {
        return `(${pl.payload.vistories}/${pl.payload.actives}) x 100`;
    }

    
}

const CustomTooltip = ({ active, payload, label, type}) => {
    var formula = "";
    var trapStatus = null;
    if(type == "IPO") {
        formula = "Armadilhas diagnosticadas positivas / Armadilhas diagnosticadas x 100";
    } else if (type == "IDO") {
        formula = "Número de ovos / Armadilhas diagnosticadas positivas";
    } else if (type == "IMO") {
        formula = "Ovos / Armadilhas diagnosticadas";
    } else if (type == "IMA") {
        formula = "Mosquitos / Armadilhas diagnosticadas";
    } else if (type == "IPA") {
        formula = "Armadilhas diagnosticadas positivas / Armadilhas vistoriadas x 100";
    } else if (type == "IDA") {
        formula = "Número de mosquitos / Armadilhas diagnosticadas positivas";
    } else if (type == "Pendents") {
        formula = "(Armadilhas vistoriadas / Armadilhas instaladas) x 100";
    }

    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: "#ffffff", border: "1px solid black", padding: "5px" }}>
                <p className="label">{`${label}`}</p>
                
                <p>
                    {payload.map((pl, index) => (
                        <>
                        {
                            index > 0 
                            ? 
                            " /// "
                            :
                            <></>
                        }
                        <span className="intro" style={{ color: "red", fontWeight: "600" }}>
                            {`${pl.name}: ${pl.value}`}
                        </span> 
                        </>
                    ))}
                </p>

                {
                    payload.length == 1
                    ?
                    <>     
                        <p>
                            {formula}
                        </p>
                        <p>
                            {payload.map((pl, index) => (
                                <>
                                {
                                    index > 0 
                                    ? 
                                    " /// "
                                    :
                                    <></>
                                }
                                <span className="desc" style={{ color: "red", fontWeight: "600" }}>
                                    {returnFormula(pl, type)}
                                </span>
                                </>
                            ))}
                        </p>
                    </>
                    :
                    <></>
                }
            </div>
        )
    }
  
    return null;
};

export default CustomTooltip;