import request from '../utils/request';
export function postSampleResult(SampleResultData) {
    
    let sampleResultSend = {
        ExamId: SampleResultData.selectedExam,
        IdentificationId: SampleResultData.selectedIdentification,
        TechniqueId: SampleResultData.selectedTechnique,
        SampleId: SampleResultData.sampleId,
        Result: SampleResultData.result,
    }

    return request({
        url: '/SampleResults',
        method: 'post',
        data: sampleResultSend,
    });
}

export function getSampleResult(){
    return request({
        url: '/SampleResults',
        method: 'get',
    })
}
