import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

const PgsWeb = React.FC = () => {
    return (
        <h1>
            Pgs Web
        </h1>
    )
}

export default PgsWeb;