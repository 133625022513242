import React from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
// reactstrap components
import DemandsTable from "../components/Lists/Demands/DemandsTable";

import FiltroDemandas from "../components/Navbars/FiltroDemandas";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import {fetchDemands} from "../services/api/Demand";

// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.jsx";

import Header from "components/Headers/Header.jsx";

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    Demand : [],
    DemandFilter:[]
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
    const DemandsRequest = fetchDemands();       
            DemandsRequest.then(response => {
            if(response) {
                return response.data
            }
            throw new Error("Erro ao contatar servidor");
        }).then(data => {
            this.setState({Demand : data});
            this.setState({DemandFilter : data});
        })
  }
  callbackFunction = (DemandFilteredArray)=>{
    this.setState({DemandFilter: DemandFilteredArray})
  }
  render() {
    return (
      <>
        <Header />
        
        <FiltroDemandas Demands={this.state.Demand} parentCallback = {this.callbackFunction} />
        {/* Page content */}
        <DemandsTable Demand={this.state.DemandFilter}/>
      </>
    );
  }
}

export default Index;
