import React from "react";
import GoalByDayComposedChartGraphic from "./Composed/GoalByDayComposedChartGraphic";
import moment from "moment";

const GoalByDayGraphicWrapper = ({ goalsData, productivityFilters }) => {
  const [formatedGoalsByDay, setFormatedGoalsByDay] = React.useState([]);
  const [formatedGoalsByUser, setFormatedGoalsByUser] = React.useState(null);

  React.useEffect(() => {
    if(!goalsData || goalsData.lenght === 0)
      return;

    const goalsByDay = formatGoalsByDays(goalsData);
    const goalsByUsers = formatGoalsByUsers(goalsData);

    setFormatedGoalsByDay(goalsByDay);
    setFormatedGoalsByUser(goalsByUsers);

  }, [goalsData]);

  const calculateGoalConcludedPercentual = (total, concluded) => {
    return total ? ((concluded / total) * 100).toFixed(2) : 0;
  };

  const formatGoalsByDays = (goalsData) => {
    if (!goalsData || goalsData.length === 0) return;

    const result = goalsData.map(({ dailyGoals, day }) => {
      let trapsGoalByDay = 0;
      let visitsGoalByDay = 0;
      let concludedTrapsByDay = 0;
      let concludedVisitsByDay = 0;

      dailyGoals.forEach(({ goals, results }) => {
        concludedTrapsByDay += results.installedTraps;
        concludedVisitsByDay += results.openVisits;

        goals.forEach(({ type, value }) => {
          if (type.name === "Armadilhas Instaladas") trapsGoalByDay += value;
          else if (type.name === "Visitas") visitsGoalByDay += value;
        });
      });

      return {
        date: moment(day).format("DD/MM/YYYY"),
        Meta: 100,
        "Meta - Armadilhas Instaladas": trapsGoalByDay,
        "Meta - Visitas": visitsGoalByDay,
        "Realizado - Armadilhas Instaladas (%)":
          calculateGoalConcludedPercentual(trapsGoalByDay, concludedTrapsByDay),
        "Realizado - Armadilhas Instaladas": concludedTrapsByDay,
        "Realizado - Visitas (%)": calculateGoalConcludedPercentual(
          visitsGoalByDay,
          concludedVisitsByDay
        ),
        "Realizado - Visitas": concludedVisitsByDay,
      };
    });

    return result;
  };

  const formatGoalsByUsers = (goalsData) => {
    if (!goalsData || goalsData.length === 0) return;

    const finalResult = {};

    goalsData.forEach(({ day, dailyGoals }) => {
      dailyGoals.forEach(({ goals, name, results }) => {
        const actualDate = moment(day).format("DD/MM/YYYY");
        let visitsGoal = 0;
        let installTrapsGoal = 0;

        goals.forEach(({ value, type }) => {
          if (type.name === "Armadilhas Instaladas") installTrapsGoal += value;
          else if (type.name === "Visitas") visitsGoal += value;
        });

        const result = {
          firstName: name.split(" ")[0],
          fullName: name,
          data: actualDate,
          "Meta - Armadilhas Instaladas": installTrapsGoal,
          "Meta - Visitas": visitsGoal,
          "Realizado - Armadilhas Instaladas (%)":
            calculateGoalConcludedPercentual(
              installTrapsGoal,
              results.installedTraps
            ),
          "Realizado - Visitas (%)": calculateGoalConcludedPercentual(
            visitsGoal,
            results.openVisits
          ),
          "Realizado - Armadilhas Instaladas": results.installedTraps,
          "Realizado - Visitas": results.openVisits,
          Meta: 100,
        };

        if (!finalResult[actualDate]) finalResult[actualDate] = [];

        const previousResultValues = finalResult[actualDate];

        finalResult[actualDate] = [...previousResultValues, result];
      });
    });

    return finalResult;
  };

  return (
    <GoalByDayComposedChartGraphic
      headerText={`Meta de equipes por dia - ${moment(
        productivityFilters.beginDate
      ).format("DD/MM/YYYY")} a ${moment(productivityFilters.finalDate).format(
        "DD/MM/YYYY"
      )}`}
      goalsByDayData={formatedGoalsByDay}
      goalsByUserData={formatedGoalsByUser}
      productivityFilters={productivityFilters}
    />
  );
};

export default GoalByDayGraphicWrapper;
