import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";


const FormTerritorializationPreviewApp = React.FC = () => {
    return (
        <div style={{margin: "1rem"}}>
            <div style={{backgroundColor: "#669999", padding: "10px"}}>
                <img src={require("../../../../assets/img/others/date-time-preview.png")} width="100%" style={{borderRadius: "5px"}} />
                <input className="form-control input-preview" placeholder="Digite Campo Extra 1"  />
                <input className="form-control input-preview" placeholder="Digite Campo Extra 2" />
                <input className="form-control input-preview" placeholder="Digite Campo Extra 3" />
                <input className="form-control input-preview" placeholder="Digite o CEP" />
                <input className="form-control input-preview" placeholder="Aqui fica o select de rua, avenida" />
                <input className="form-control input-preview" placeholder="Digite o Logradouro (Nome da rua)" />
                <input className="form-control input-preview" placeholder="Digite o número do imóvel" />
                <input className="form-control input-preview" placeholder="Digite o Complemento" />
                <input className="form-control input-preview" placeholder="Digite o Sequencial" />
                <input className="form-control input-preview" placeholder="Aqui tem o Select de Tipo de imóvel" />
            </div>
            
            <button className="btn btn-app-advance" style={{backgroundColor: "#346666"}}>Avançar</button>
        </div>
    );
}

export default FormTerritorializationPreviewApp;