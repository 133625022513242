import React, { memo, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import CreateQrCodeTicket from "./CreateQrCodeTicket";
import FilterTicket from "./FilterTicket";
import PrintQrCode from "./PrintQrCode";

const QrCodeGenerator = (props) => {
  const [page, setPage] = useState();
  const [selectedTrapType, setSelectedTrapType] = React.useState("armadilhaDisseminadoraInseticida");
  const [selectedOrganization, setSelectedOrganization] = React.useState("");
  const [formatedOrganizationName, setFormatedOrganizationName] = React.useState("");
  const [showImage, setShowImage] = React.useState(false);
  const [styleTicketModel, setStyleTicketModel] = React.useState();

  React.useEffect(() => {
    var styleTicketModel = {
      qrCodeSize: 250,
      qrCodeHeight: 25,
      qrCodeWidth: 15,
      textBottom: 30,
      textWidth: 33,
      textOrientation: "left",
      showOnlyNumber: false,
      pageBreakBeforeNumber: true,
      pageBreakAfterTrapType: false,
      orientation: "right",
      textCenter: true,
      textFontSize: 22
    };
    
    var nameStorage = "styleTicketModel_" + formatedOrganizationName + "_" + selectedTrapType;
    var styleTicketModelStorage = localStorage.getItem(nameStorage);
    console.log("nameStorage, styleTicketModelStorage", nameStorage, styleTicketModelStorage);
    if(styleTicketModelStorage && styleTicketModelStorage != "") {
      styleTicketModel = JSON.parse(styleTicketModelStorage);
    }

    setStyleTicketModel(styleTicketModel);
  }, [selectedTrapType,formatedOrganizationName, page])

  return (
    <>
      {/* Page content */}
      <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8">
        <Row>
          <Col xl="6">
            <button className="btn btn-info m-2" onClick={() => setPage("CreateQrCode")}>
              Criar/Atualizar Etiqueta Qrcode
            </button>
          </Col>
          <Col xl="6">
            <button className="btn btn-info m-2" onClick={() => setPage("PrintQrCode")}>
              Imprimir Etiqueta Qrcode
            </button>
          </Col>
        </Row>
        {
          page && <FilterTicket 
            setShowImage={setShowImage}
            selectedOrganization={selectedOrganization} 
            setSelectedOrganization={setSelectedOrganization}
            setSelectedTrapType={setSelectedTrapType} 
            setFormatedOrganizationName={setFormatedOrganizationName}
          />
        }
        
        {
          page && page == "CreateQrCode"
          ?
          <CreateQrCodeTicket 
            selectedOrganization={selectedOrganization} 
            selectedTrapType={selectedTrapType} 
            showImage={showImage} 
            formatedOrganizationName={formatedOrganizationName}
            setStyleTicketModel={setStyleTicketModel}
            styleTicketModel={styleTicketModel}
          />
          :
          <></>
        }
        {
          page && page == "PrintQrCode"
          ?
          <PrintQrCode
            styleTicketModel={styleTicketModel}
            showImage={showImage}
            selectedTrapType={selectedTrapType}
            selectedOrganization={selectedOrganization}
            formatedOrganizationName={formatedOrganizationName}
          />
          :
          <></>
        }
      </Container>
    </>
  );
};

export default QrCodeGenerator;
