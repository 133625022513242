import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";
import { Select } from "../../Wrappers/SelectAll";
import "./EntomologicoFilter.css";

// reactstrap components

const EntomologicoFilterPresentational = (props) => {
  const [startDateType, setStartDateType] = useState("");
  const [endDateType, setEndDateType] = useState("");
  const [trapTypeDefinition, setTrapTypeDefinition] = useState("");

  useEffect(() => {
    setStartDateType("startDate");
    setEndDateType("endDate");
    switch (props.type) {
      case "Entomologico":
        setStartDateType("startDateENTOMOLOGICO");
        setEndDateType("endDateENTOMOLOGICO");
        setTrapTypeDefinition("trapTypeENTOMOLOGICO");
        break;
      case "Operacional":
        setStartDateType("startDateOPERACIONAL");
        setEndDateType("endDateOPERACIONAL");
        setTrapTypeDefinition("trapTypeOPERACIONAL");
        break;
    }
  }, []);

  return (
    <>
      <Col className="linha-form ent-responsive-filter col-md-4">
        <span className="h4 text-white">Data início</span>
        <input
          value={props.startDate}
          name={startDateType}
          onChange={(e) => {
            props.handleChangeFilter(e.target.name, e.target.value);
          }}
          type="date"
          className="form-control"
        />
      </Col>
      <Col className="linha-form ent-responsive-filter col-md-4">
        <span className="h4 text-white">Data fim</span>
        <input
          value={props.endDate}
          name={endDateType}
          onChange={(e) => {
            props.handleChangeFilter(e.target.name, e.target.value);
          }}
          type="date"
          className="form-control"
        />
      </Col>
      {props.hideTrapRelatedInputs ? null : (
        <>
          <Col className="linha-form ent-responsive-filter col-md-4">
            <span className="h4 text-white">Tipo de armadilha</span>
            <select
              value={
                props.trapTypeIdAUX === "00000000-0000-0000-0000-000000000000"
                  ? props.trapTypeId
                  : props.trapTypeIdAUX
              }
              name={"trapType"}
              className="form-control"
              onChange={(e) =>
                props.handleChangeFilter(e.target.name, e.target.value)
              }
            >
              {props.trapTypeOptions.map((trapType) => {
                //Coloquei isto, pois tinha armadilhas sem nome e isso tava quebrando
                if (trapType.name) {
                  return (
                    <option key={trapType.id} value={trapType.id}>
                      {ARMADILHA_INFO[trapType.name].apelido}
                    </option>
                  );
                }
              })}
            </select>
          </Col>
          <Col className="linha-form ent-responsive-filter col-md-4">
            <span className="h4 text-white">Espécie</span>
            <Select
              styles={{
                control: (styles) => ({
                  ...styles,
                  minHeight: "2.90rem",
                  borderRadius: "0.370rem",
                }),
                option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
                valueContainer: (styles) => ({
                  ...styles,
                  fontSize: "0.875rem",
                  color: "#8898aa",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  fontSize: "0.875rem",
                  color: "#8898aa",
                }),
                placeholder: (styles) => ({
                  ...styles,
                  fontSize: "0.875rem",
                  color: "#8898aa",
                }),
              }}
              placeholder={"Selecione"}
              isClearable={true}
              options={props.speciesOptions}
              value={
                props.speciesOptions
                  ? props.speciesOptions.filter(
                      (specie) => specie.value === props.selectedSpecie
                    )
                  : []
              }
              onChange={(e) => {
                props.handleChangeFilter("specie", e ? e.value : null);
              }}
            />
          </Col>
        </>
      )}
    </>
  );
};

export default EntomologicoFilterPresentational;
