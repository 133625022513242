import React from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// reactstrap components

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import "../../../assets/css/style.css";
import { postTeam } from "../../../services/api/Team";
import { fetchPrograms } from "../../../services/api/Program";
import { fetchEmployees } from "../../../services/api/Employee";
import { getUserData } from "../../../services/utils/auth";
import { checkPermissionComponent } from "../../../services/utils/permission";
import "./Team.css";

class AddTeam extends React.Component {
  constructor (props) {
    super(props);
    if(!checkPermissionComponent("217c876b-5f50-4bbf-bce4-325b83ea7787", "create")){
      alert("Você não tem permissão para acessar essa funcionalidade!");
      props.history.goBack(); 
    }
    this.state = {
      employees: [],
      programs: [],
      Name : "",
      Program : "",
      Superviser : null,
      Coordinator : null,
      Description : "",
      Members: null,
      Executioner: null,
      organizationId: getUserData('organizationId'),
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    var requestPrograms = fetchPrograms();
        requestPrograms.then(response => {
            this.setState({programs: response.data});
        })
    
    var requestEmployees = fetchEmployees();
        requestEmployees.then(response => {
          let employeesOpt = response.data;
          var employeesSelect = [];

          employeesOpt.forEach(element => {
            let employee = {
              label: element.name,
              value: element.id,
              EmployeeId: element.id,     
              Hierarchy: element.function != null ? element.function.hierarchy: null,     
            }
            employeesSelect.push(employee);
          });

          this.setState({employees: employeesSelect});
        });
  }
  
 
  handleChange(event) {
    this.setState({[event.target.name]  : event.target.value });
  };

  handleChangeCoordinator = Coordinator => {
    this.setState({Coordinator});
  }
  handleChangeSuperviser = Superviser => {
    this.setState({Superviser});
  }

  handleChangeEncarregado = Members => {
    this.setState(
      { Members }
    );
  };

  handleChangeExecutor = Executioner => {
    this.setState(
      { Executioner }
    );
  };

  createTeam = () => {
    let errors = false;

    if(!this.state.Program){
      toast.error("Erro: é necessário selecionar um programa!");
      errors = true;
    }
    if (!this.state.Name) {
      toast.error("Erro: O nome da equipe é obrigatório");
      errors=true;
    }
    if (!this.state.Executioner) {
      toast.error("Erro: É preciso ter ao menos 1 executor");
      errors=true;
    }

    if(errors == false){
      const requestTeam = postTeam(this.state);
      requestTeam.then(response => {
      if(response) {
          return response.data
      }
      throw new Error("Erro de requisição...");
    })
    .then(data => {        
        if (data.id) {
          toast.success("Equipe cadastrada com sucesso!");
        } else {
          toast.error("Não foi possível cadastrar a equipe");
        }        
    });
    }
  }

  render() {

    const { Members } = this.state;
    const { Executioner } = this.state;
    const { Superviser } = this.state;
    const { Coordinator } = this.state;

    return (
      <>
        <Header />

        <ToastContainer />

        <Container className="container-flex-team" fluid>
          <Row className="mt-12">
            <Col className="mb-12 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        <Link to="/admin/equipes"> <i className=" ni ni-bold-left bnt-voltar" /></Link>
                        Nova Equipe</h3>
                    </div>
                  </Row>
                </CardHeader>

                <Container fluid>
                  <div className="header-body">

                    <div className="row linha-form">
                      <div className="col-4 responsive-field-form-team-medium">
                        <span className="h5 text-black">Nome*</span>
                        <input type="text" className="form-control" placeholder="Nome" name="Name" onChange={this.handleChange} />
                      </div>
                      <div className="col-4 responsive-field-form-team-medium">
                        <span className="h5 text-black">Programa</span>
                        <select className="form-control" name="Program" onChange={this.handleChange}>
                          <option>Programa</option>
                            {this.state.programs.map((program) => {
                                return (
                                    <option value={program.id}>{program.name}</option>
                                )
                            })}                           
                        </select>
                      </div>
                      <div className="col-4 responsive-field-form-team-medium">
                        <span className="h5 text-black">Coordenador</span>
                        <Select
                          value={Coordinator}
                          onChange={this.handleChangeCoordinator}
                          options={this.state.employees.filter(e => e.Hierarchy === 'Coordinator')}
                          placeholder="Coordenador"
                        />
                      </div>
                    
                      <div className="col-4 responsive-field-form-team-medium">
                        <span className="h5 text-black">Supervisor</span>
                        <Select
                          value={Superviser}
                          onChange={this.handleChangeSuperviser}
                          options={this.state.employees.filter(e => e.Hierarchy === 'Superviser')}
                          placeholder="Supervisor"
                        />
                      </div>
                      
                      <div className="col-8 responsive-field-form-team-full">
                        <span className="h5 text-black">Descrição</span>
                        <textarea className="form-control" placeholder="Descrição"name="Description"onChange={this.handleChange}></textarea>
                      </div>
                      <div className="col-4">
                      </div>
                    </div>

                    <div className="row linha-form">
                      <div className="col-6 responsive-field-form-team-full">
                        <span className="h5 text-black">Encarregado*</span>
                        <Select
                          value={Members}
                          onChange={this.handleChangeEncarregado}
                          options={this.state.employees.filter(e => e.Hierarchy === 'Responsible')}
                          placeholder="Encarregado"
                        />
                      </div>
                      <div className="col-6 responsive-field-form-team-full">
                        <span className="h5 text-black">Executores*</span>
                        <Select
                          value={Executioner}
                          onChange={this.handleChangeExecutor}
                          options={this.state.employees}
                          isMulti
                          placeholder="Executores"
                        />
                      </div>
                      <div className="col-4">
                      </div>
                    </div>


                    <div className="row">
                      <div className="linha-form col-4 responsive-field-form-team-medium">
                        <button onClick={this.createTeam} className="btn btn-primary">Cadastrar</button>
                      </div>
                      <div className="col-4 responsive-field-form-team-medium">
                        <button className="form-control" onClick={() => window.location.reload()}>Limpar</button>
                      </div>
                    </div>
                  </div>
                </Container>

              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AddTeam;
