import React from "react";
import { Link } from "react-router-dom";
import "../../../../assets/css/navbar.css";
import {getStocables, getInventoryPlaces} from "../../../../services/api/Itens.js";

// reactstrap components
import {
    Container,
} from "reactstrap";
import "./InventoryItens.css";
import { checkPermissionComponent } from "../../../../services/utils/permission";
import {checkShowBiovec} from "../../../../services/utils/Inventory/checkShowBiovec.js";
import { filterInventoryPlaces } from "../../../../services/utils/Inventory/checkShowBiovec.js";
import { sortInventoryPlaces } from "../../../../services/utils/Inventory/checkShowBiovec.js";
import { Select } from "../../../../components/Wrappers/SelectAll.jsx";

class FilterInventoryItens extends React.Component {
    state ={
        filterName:"",
        filterModule:"",
        filterPlace: [],
        filterTypeExpiration: "All",
        filterStatus:"",
        filterManufacturerName:"",
        filterDateStart:"",
        filterDateEnd:"",
        filterHasExpiration:"0",
        stocables: null,
        inventoryPlacesList: null,
        showBiovec: false
    }

    componentDidMount() {
        var showBiovec =checkShowBiovec();
        
        this.setState({showBiovec})

        getStocables().then((response) => {
            this.setState({stocables: response.data})
        })

        var requestInventoryPlaces = getInventoryPlaces();
        requestInventoryPlaces.then(response => {
            this.setState({inventoryPlacesList: response.data})
        })
    }

    handleChange = e => {
        let {name,value} = e.target;
        this.setState({
            [name]: value
        });
      };
    render() {
        let filteredItens2 = this.props.Itens;
        let filteredItens = filteredItens2.filter(
            (Item)=>{

                var expirationTest = new Date(Item.expiration);
                expirationTest = expirationTest.getFullYear() + '-' + ('00'+(expirationTest.getMonth() + 1)).slice(-2) + '-' + expirationTest.getDate();

                var filterExpiration = true;
                var hoje = new Date();

                var trintaDiasDepois = new Date();
                trintaDiasDepois.setDate(trintaDiasDepois.getDate() + 30);

                var sessentaDiasDepois = new Date();
                sessentaDiasDepois.setDate(sessentaDiasDepois.getDate() + 60);
                var expirationDate = new Date(Item.expiration);

                
                if (this.state.filterTypeExpiration == "green") {
                    filterExpiration =expirationDate > sessentaDiasDepois;

                    if(!Item.hasExpiration){
                        filterExpiration = true;
                    }
                } else if (this.state.filterTypeExpiration == "yellow") {
                    filterExpiration = expirationDate <= sessentaDiasDepois && expirationDate > trintaDiasDepois;
                } else if (this.state.filterTypeExpiration == "red") {
                    filterExpiration = expirationDate <= trintaDiasDepois && expirationDate > hoje;                    
                }

                var onlyValidatedItens = this.state.onlyValidatedItens == "true";
                var filterValidated = false;

                if(!onlyValidatedItens || (onlyValidatedItens && Item.quantity > 0)) {
                    filterValidated = true;
                }

                var insidePlaces = true;
                if(this.state.filterPlace && this.state.filterPlace.length > 0) {
                    insidePlaces = this.state.filterPlace.some(fp => {if(fp.value.toLowerCase() == Item.place.toLowerCase()) return true});
                }

                return insidePlaces && filterValidated && filterExpiration && Item.name.toLowerCase().indexOf(
                    this.state.filterName.toLowerCase()) !== -1 &&
                    Item.manufacturerName.toLowerCase().indexOf(
                        this.state.filterManufacturerName.toLowerCase()) !== -1 &&
                    ((this.state.filterDateStart <= Item.expiration && this.state.filterDateEnd === "")||
                    (this.state.filterDateStart === "" && this.state.filterDateEnd >= Item.expiration)||
                    (this.state.filterDateStart <= Item.expiration && this.state.filterDateEnd >= Item.expiration)&&
                    ((this.state.filterDateStart!=""||this.state.filterDateEnd!="")&&Item.hasExpiration)) && 
                    ((this.state.filterHasExpiration === "0") ||(this.state.filterHasExpiration === "1" && Item.hasExpiration) ||
                    (this.state.filterHasExpiration === "2" && !Item.hasExpiration))
                        ;
            }
        );
        
        return (
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" >
                <Container fluid>
                    <div className="header-body">

                        <div className="row linha-form">
                         
                            <div className="col-4 responsive-filter-field-inventory-full">
                                <span className="h5 text-white">Nome do estocável</span>
                                <select name="filterName" className="form-control" placeholder="Nome" onChange={this.handleChange}>
                                    <option value="">Selecione</option>
                                    {
                                        this.state.stocables
                                        ?
                                        this.state.stocables.map((stocable) => (
                                            <option value={stocable.name}>{stocable.name}</option>
                                        ))
                                        :
                                        <></>
                                    }
                                </select>
                            </div>
                       
                        
                            <div className="col-4 responsive-filter-field-inventory-full">
                                <span className="h5 text-white">Nome do fabricante</span> 
                                <input type="text" name="filterManufacturerName" onChange={this.handleChange} className="form-control" placeholder="Nome" />
                            </div>
                            <div className="col-4 responsive-filter-field-inventory-full">
                                <span className="h5 text-white">Local de armazenamento</span> 
{/*                                 
                                <select multiple type="text" name="filterPlace" onChange={this.handleChange} className="form-control" placeholder="Local">
                                    <option value="">Selecione</option>
                                    {
                                        this.state.inventoryPlacesList
                                        ?
                                        filterInventoryPlaces(sortInventoryPlaces(this.state.inventoryPlacesList), this.state.showBiovec, true, "obj")
                                        :
                                        <></>
                                    }
                                </select> */}
                                <Select
                                    styles={{
                                        control: styles => ({ ...styles, minHeight: "2.90rem", borderRadius: "0.370rem" }),
                                        option: styles => ({ ...styles, fontSize: "0.875rem" }),
                                        valueContainer: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                                        singleValue: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                                        placeholder: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                                        menuPortal: styles => ({ ...styles, zIndex: 9999 })
                                    }}
                                    value={this.state.filterPlace ? this.state.filterPlace : []}
                                    isMulti={true}
                                    placeholder={"Selecione"}
                                    isClearable={true}
                                    blurInputOnSelect={false}
                                    closeMenuOnSelect={false}
                                    allowSelectAll={true}
                                    allOption={{ label: "Todas", value: "*" }}
                                    onChange={(e) => {
                                        this.setState({filterPlace: e})
                                    }}
                                    options={
                                        this.state.inventoryPlacesList
                                        ? 
                                            filterInventoryPlaces(sortInventoryPlaces(this.state.inventoryPlacesList), this.state.showBiovec, true, "obj") 
                                        : 
                                            []
                                    }
                                    menuPortalTarget={document.body}
                                />
                            </div>
                        </div>
                        <div className="row linha-form">
                            <div className="col-4 responsive-filter-field-inventory-full">
                                <span className="h5 text-white">Tem Validade?</span>
                                <select className="form-control" name="filterHasExpiration" onChange={this.handleChange}>
                                    <option value={"0"}>Ambos</option>
                                    <option value={"1"}>Sim</option>
                                    <option value={"2"}>Não</option>
                                                            
                                </select>
                            </div>
                            <div className="col-4 responsive-filter-field-inventory-medium">
                                <span className="h5 text-white">Validade inicial</span>
                                <input type="date" name="filterDateStart" onChange={this.handleChange} className="form-control" />
                            </div>
                       
                        
                            <div className="col-4 responsive-filter-field-inventory-medium">
                                <span className="h5 text-white">Validade final</span> 
                                <input type="date" name="filterDateEnd" onChange={this.handleChange} className="form-control" />
                            </div>
                            
                        </div>
                        
                        <div className="row linha-form">
                            <div className="col-4 responsive-filter-field-inventory-medium">
                                <span className="h5 text-white">Tipo de expiração (Em Desenvolvimento)</span> 
                                <select type="text" name="filterTypeExpiration" onChange={this.handleChange} className="form-control" >
                                    <option value="*">Todos</option>
                                    <option value="green">Verde</option>
                                    <option value="yellow">Amarelo</option>
                                    <option value="red">Vermelho</option>
                                </select>
                            </div>
                            <div className="col-4 responsive-filter-field-inventory-medium">
                                <span className="h5 text-white">Apenas com quantidades válidas</span> 
                                <select type="text" name="onlyValidatedItens" defaultValue={false} onChange={(e) => this.handleChange(e)} className="form-control" >
                                    <option value={true}>Sim</option>
                                    <option value={false}>Não</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 responsive-filter-field-inventory-full">
                                <button className="btn btn-primary" onClick={()=> this.props.parentCallback(filteredItens)}>Pesquisar</button>
                            </div>
                            {checkPermissionComponent("2af11e8b-f884-40c8-b3a2-360d1f1bb95d", "create") ?
                            <div className="col-4 responsive-filter-field-inventory-full">
                                    <Link to="/admin/AddInventoryMovementRecord">
                                        <button className="float-right btn btn-default">Nova Movimentação</button>
                                    </Link>
                                </div>
                            : null}
                            <div className="col-4 responsive-filter-field-inventory-full">
                                <button className="btn btn-primary" onClick={()=> this.props.setFilterByPrefix()}>
                                    {
                                        !this.props.filterByPrefix
                                        ?
                                        "Filtrar por tipo de local"
                                        :
                                        "Filtrar por nome do local"                                     
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );

    }

}

export default FilterInventoryItens;