import React from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import CsvDownloadButton from 'react-json-to-csv';
import {
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CardHeader,
    Container,
    Row,
    Col,
    Table,
  } from "reactstrap";
  import {deleteEmployee} from "../../../services/api/Employee";
import { getPermissionsToken, getUserData } from "../../../services/utils/auth";
import { deletePerson } from "../../../services/api/Person";
import DataTable from "react-data-table-component";
import { postTrapHistory } from "../../../services/api/Trap";
import { formatTodayDateBody } from "../../../services/utils/format";
class TrapsTable extends React.Component {
    state = {
        selectedTrapId: null,
        selectedTrap: null,
        modal: false,
    }

    confirmDelete (trap, trapId) {
        this.setState({
            selectedTrapId:trapId,
            selectedTrap:trap
        });
        this.toggle(false);
    }

    toggle = (willDeleteTrap) => {
        this.setState({
            modal: !this.state.modal
        }); 

        if (willDeleteTrap) {
            var selectedTrap = this.state.selectedTrap;
            var lastHistory = selectedTrap.histories.sort((a, b) => new Date(a.statusDate) - new Date(b.statusDate));
      
            if(lastHistory && lastHistory.length > 0){
              lastHistory = lastHistory[lastHistory.length - 1];
            } else {
              return
            }
      
            var today = new Date();
      
            var statusDateBody = formatTodayDateBody(today);
      
            var organizationId = getUserData("organizationId");
            
            var body = {
              "demandId": lastHistory.demandId,
              "trapId": lastHistory.trapId,
              "userId": lastHistory.userId,
              "trap": null,
              "samples": null,
              "statusDate": statusDateBody,
              "organizationId": organizationId,
              "wasUnableToRead": false,
              "trapWasInstalled": false,
              "trapWasRead": false,
              "trapWasRemoved": true,
              "trapNumber": selectedTrap.number,
              "wasCollectedSample": false,
              "wasInviable": false,
              "manuallyChanged": false,
              "latitude": lastHistory.latitude,
              "longitude": lastHistory.longitude,
              "visitId": lastHistory.visitId,
              "typistId": null,
              "georeferencedByAddress": null
            };
      
            postTrapHistory(body).then((response) => {
              if (response) {
                toast.success("Armadilha removida com sucesso!");
              }
            });
          }
    }

    buildButtons(trap){
        const userPermissions = getPermissionsToken();
        return (
            <>
                {userPermissions["ba56119d-ac43-4ec1-858e-31f2e12235e8"] && 
                    <>
                        {userPermissions["ba56119d-ac43-4ec1-858e-31f2e12235e8"].update && 
                            <Link to="/admin/EditTrap">
                                <button
                                    className="btn btn-primary"
                                    style={{'width':'60px', 'margin' : '5px'}}
                                    data-clipboard-text="ni ni-fat-edit"
                                    onClick={() => {localStorage.setItem("trapUnicId",trap.id);}}
                                    id="tooltip204269497"
                                    type="button"
                                >                                            
                                <i className="ni ni-ruler-pencil" />
                                    
                                </button>
                            </Link>
                        }

                        {userPermissions["ba56119d-ac43-4ec1-858e-31f2e12235e8"].delete && 
                            <button
                                className="btn btn-warning"
                                style={{'width':'60px', 'margin' : '5px'}}
                                data-clipboard-text=" ni ni-fat-remove"
                                id="tooltip204269497"
                                onClick={()=>this.confirmDelete(`${trap.name} Nº${trap.number}`,trap.id)}
                                type="button">
                                <i className=" fa fa-trash"/>
                            </button>
                        }   
                    </>
                }
            </>
        )
    }
    
    render() {      
        const columns = [
            {
                name:'Nome',
                selector: 'name',
                sortable:true,
            },
            {
                name:'Número',
                selector: 'number',
                sortable:true,
                sortFunction: (a, b) => {
                    const valorA = parseFloat(a.number);
                    const valorB = parseFloat(b.number);
            
                    if (isNaN(valorA) && isNaN(valorB)) {
                      // Ambos são valores não numéricos, mantenha a ordem original
                      return 0;
                    } else if (isNaN(valorA)) {
                      // A é não numérico, move para o final
                      return 1;
                    } else if (isNaN(valorB)) {
                      // B é não numérico, move para o final
                      return -1;
                    } else {
                      // Ambos são valores numéricos, realiza a comparação normalmente
                      return valorA - valorB;
                    }
                },          
            },
            {
                name:'Endereço',
                selector: 'address',
                sortable:true,   
            },
            {
                cell:(row)=> this.buildButtons(row),
                ignoreRowClick: true,
            },
        ];
        
        return (

            <Container className="mt--7" fluid>
                <ToastContainer />
                <Modal size="lg" fade="true" isOpen={this.state.modal} toggle={()=>this.toggle(false)}> 
                    <ModalHeader >Remoção de Armadilha</ModalHeader>
                    <ModalBody>           
                        <div className="row"> 
                            <div className="col-9">
                                <span className="h2 text-black">Deseja desinstalar a armadilha {this.state.selectedTrap.number}?</span>
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row" >                          
                        
                            <div className="col-6">
                                <button  className="form-control" onClick={()=>this.toggle(false)}>
                                    CANCELAR
                                </button>
                            </div>
                            <div className="col-6">
                                <button  className="btn btn-primary" onClick={()=>this.toggle(true)}>
                                    REMOVER
                                </button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
            <Row className="mt-12">
                <Col className="mb-12 mb-xl-0" xl="12">
                <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                        <h3 className="mb-0">Armadilhas</h3>
                        </div>
                    <CsvDownloadButton className="btn btn-primary" data={this.props.traps} filename={'armadilhas'}> <i className="fa fa-download" /> Baixar CSV das armadilhas</CsvDownloadButton>
                    </Row>
                    </CardHeader>
                    <DataTable
                        noHeader
                        defaultSortField="name"
                        defaultSortAsc={true}
                        pagination
                        highlightOnHover
                        columns={columns}
                        data={this.props.traps}
                        noDataComponent={'Nenhum registro encontrado.'}
                    />
                </Card>
                </Col>
            </Row>
            </Container>
        )
    }
}

export default TrapsTable