import { getUserData } from './auth';

export function setTheme() {
    const organizationId = getUserData('organizationId');
    const testeVitec = "37601ec4-d2ef-4f86-aeac-fd261e8fded4";
    
    switch (organizationId) {
        case testeVitec:
            // desabilitando tema MCS
            // require('../../assets/css/style_mcs.css');
            require('../../assets/css/style.css');
            break;
    
        default:            
            require('../../assets/css/style.css');
            break;
    }
}