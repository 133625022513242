import React, { useState } from "react";
import { useEffect } from "react";
import { Brush, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Surface, Symbols, Tooltip, XAxis, YAxis } from "recharts";
import { useCurrentPng } from "recharts-to-png";
import CardPresentational from "../../../../Presentational/Sinan/CardPresentational";
import CustomTooltip from "../../../../Utils/CustomTooltip";
import RenderCustomizedLegend from "../../../../Utils/RenderCustomizedLegend";
import loadGraphicInformations from "../../../../Utils/LoadGraphicInformations";

const colors = ["#E60017", "#FC6D0D", "#B10FFF", "#0C28F2", "#D44D13"];

const IDOChartContainer = ({ chartDataSets, data }) => {
    const [getpng, { ref }] = useCurrentPng();

    const [elementData, setElementData] = useState();
    
    const renderCustomizedLegend  = () => {
        var centerStyle = {display: "flex", justifyContent: "center", alignItems: "center"};
        return (
            <div style={centerStyle}>
                {elementData && elementData.map((elementContent, index) => {
                    const color = colors[index];
                    const active = elementContent.checked;
                    const style = {
                        marginRight: 10,
                        color: active ? "#000" : "#AAA",
                        cursor: "pointer",
                        gap: "10px",
                        ...centerStyle
                    };

                    return (
                        <div

                            className="legend-item"
                            onClick={() => {
                                var elementDataAux = [...elementData];

                                elementDataAux.map((valueElementContent, indexElementContent) => {
                                    if(indexElementContent == index) {
                                        valueElementContent.checked = !valueElementContent.checked;
                                    }
                                })

                                setElementData(elementDataAux);
                            }}
                            style={style}
                        >
                            <Surface width={10} height={10} viewBox="0 0 10 10">
                            <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                            {!active && (
                                <Symbols
                                cx={5}
                                cy={5}
                                type="circle"
                                size={25}
                                fill={"#FFF"}
                                />
                            )}
                            </Surface>
                            <span>{elementContent.label}</span>
                        </div>
                    );
                })}
            </div>
        )
    };

    useEffect(() => {
        var graphicInfo = loadGraphicInformations({data: data});

        setElementData(graphicInfo ? graphicInfo.elementData : null);
    }, [chartDataSets,data])

    const buildChartElement = () => {
        var hasYear = false;
        data.map((ano, index) => {
            if(ano.label) {
                hasYear = true;
            }
        })
        return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                id={"IdoChartContainerChart"}
                data={chartDataSets}
                margin={{ top: 30, right: 15 }}
                ref={ref}
            >
                <text x={"50%"} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
                    <tspan fontSize="16" fontWeight={"bolder"}>IDO </tspan>
                </text>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" style={{ fontSize: 12 }} />
                <YAxis style={{ fontSize: 12 }} tickCount={101} domain={[0, 100]}  />
                <Tooltip content={<CustomTooltip type={"IDO"} />} />
                {
                    hasYear
                    ?
                    <Legend 
                        align="center" 
                        verticalAlign="top" 
                        content={() => RenderCustomizedLegend({
                            elementData: elementData,
                            colors: colors, 
                            setElementData: setElementData,
                            type: "line"
                        })}
                    />
                    :
                    <Legend 
                        align="center" 
                        verticalAlign="top" 
                    />
                }
                
                <Brush dataKey={"label"} />
                {elementData && elementData.map((ano, index) => (
                    !hasYear  || (hasYear && ano.checked)
                    ?
                    <Line dataKey={ano.label || "ID"} stroke={colors[index]} />
                    :
                    <></>
                ))}
            </LineChart>
        </ResponsiveContainer>
        )
    }

    //construindo propriedades do presentational
    const style = {
        cardHeaderText: {
            color: "black",
            fontWeight: "bold",
            fontSize: "0.97rem"
        },
    }

    const textCardHeader = "Índice de Densidade";

    return (
        <CardPresentational
            CardId={"IdoChartContainer"}
            style={style}
            textCardHeader={textCardHeader}
            element={buildChartElement()}
            getpng={getpng}
        />
    );
}

export default IDOChartContainer;
