import React from "react";
import "./../../assets/css/navbar.css";
import { getUploadHistory, upload } from "../../services/api/Sinan";
import moment from "moment";
// reactstrap components
import {
    Container,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "./SinanImporter.css";
import { Select } from "../Wrappers/SelectAll";
import { SINAN_DESEASES } from "../../constants/SinanDeseasesConstant";
import getWeeks from "../../services/utils/epidemiologicalWeek";
class SinanImporter extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            selectedFile: null,
            selectedDesease: "DENGUE",
            yearsOptions: [],
            selectedYear: new Date().getFullYear().toString(),
            hasClicked: false,
            uploadHistory: {uploadDate : '', status : '', displayUploadDate : ''},
            DisplayDate : ''
        }
        this.onClickHandler = this.onClickHandler.bind(this)
    }

    componentDidMount() {
        const epidemiologicalWeeks = getWeeks();
        const yearsOptions = epidemiologicalWeeks.map(obj => ({ label: obj.year, value: obj.year }));
        this.setState({ yearsOptions });

        const uploadHistoryRequest = getUploadHistory(this.state.selectedDesease, this.state.selectedYear);
        uploadHistoryRequest.then(
            (response) => {
                if (response.data.length == 1)
                {
                    this.setState({ uploadHistory: response.data[0] })
                    this.setState({ DisplayDate : moment(this.state.uploadHistory.uploadDate).subtract(3, 'hours').format('DD/MM/YYYY - HH:mm')});
                }                    
            }
        );
    }

    onChangeHandler = event => {
        this.setState({
            selectedFile: event.target.files[0]
        })
    }
    async onClickHandler(e) {
        e.preventDefault();

        if (this.state.selectedFile == null) {
            toast.error("Erro: Nenhum arquivo selecionado!")
            return false;
        }

        const data = new FormData();
        data.append('file', this.state.selectedFile);
        data.append('desease', this.state.selectedDesease);
        data.append('year', this.state.selectedYear);

        this.setState({ hasClicked: true });

        toast.success("Arquivo enviado com sucesso!");
        const requestSinan = upload(data);
        requestSinan.then((response) => {
        }).catch((error) => {
            console.log(error);
        });
    }
    render() {

        return (
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" >
                <ToastContainer />
                <Container fluid>
                    <div className="header-body">
                        <form onSubmit={this.onClickHandler} method="post" enctype="application/octet-stream">
                            <div className="row">
                                <div className="col-6 responsive-form-field-sinan-medium">
                                    <span className="h4 text-white">Doença</span>
                                    <Select
                                        options={SINAN_DESEASES}
                                        value={SINAN_DESEASES.filter(({ value }) => value === this.state.selectedDesease)}
                                        onChange={({ value }) => { this.setState({ selectedDesease: value }) }}
                                        menuPortalTarget={document.body}
                                        placeholder={"Selecione"}
                                    />
                                </div>
                                <div className="col-6 responsive-form-field-sinan-medium">
                                    <span className="h4 text-white">Ano</span>
                                    <Select
                                        options={this.state.yearsOptions}
                                        value={this.state.yearsOptions.filter(({ value }) => value === this.state.selectedYear)}
                                        onChange={({ value }) => { this.setState({ selectedYear: value }) }}
                                        menuPortalTarget={document.body}
                                        placeholder={"Selecione"}
                                    />
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-12 responsive-form-field-sinan-medium">
                                    <span className="h4 text-white">Data do último envio: {this.state.DisplayDate}</span><br></br>
                                    <span className="h4 text-white">Status: <span style={{
                                        color:
                                            this.state.uploadHistory.status == 'Processado' ? '#35fd35' : 'yellow'
                                    }}>{this.state.uploadHistory.status}</span></span>
                                </div>
                            </div>

                            {this.state.selectedDesease && this.state.hasClicked == false && this.state.selectedYear && this.state.uploadHistory.status != 'E processamento' &&
                                <>
                                    <div className="form-group files color mt-4">
                                        <input type="file" name="file" onChange={this.onChangeHandler} />
                                    </div>
                                    <div className="row">
                                        <div className="col-4 center responsive-form-field-sinan-full">
                                            <button disabled={this.state.hasClicked} type="submit" className="btn btn-primary">Enviar arquivo</button>
                                        </div>
                                    </div>
                                </>
                            }

                            {this.state.uploadHistory.status == 'Em processamento' &&
                                <>
                                    <div className="row">
                                        <div style={{textAlign: 'center'}} className="col-12 responsive-form-field-sinan-medium">
                                            <span className="h2 text-white">Atenção!</span>
                                            <br></br>
                                            <span className="h4 text-white">Um arquivo está sendo processado. Tente novamente mais tarde.</span>
                                            <br></br><br></br>
                                            <span style={{fontSize: '70px'}} className="h1 text-white"><i className="fa fa-hourglass-start"/></span>                                            
                                        </div>
                                    </div>
                                </>
                            }

                            {this.state.hasClicked == true &&
                            <>
                            <div className="row">
                                <div style={{textAlign: 'center'}} className="col-12 responsive-form-field-sinan-medium">
                                    <span className="h2 text-white">Atenção!</span>
                                    <br></br>
                                    <span className="h4 text-white">Seu arquivo foi enviado. Aguarde o término do processamento.</span>
                                    <br></br><br></br>
                                    <span style={{fontSize: '70px'}} className="h1 text-white"><i className="fa fa-file-export"/></span>                                            
                                </div>
                            </div>
                        </>
                            } 
                        </form>
                    </div>
                </Container>
            </div>
        );

    }

}

export default SinanImporter;