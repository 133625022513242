import React from "react";
// nodejs library to set properties for components
import {forgotPassword} from "../../../services/api/login.js"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpin  from "react-loading-spin";

// reactstrap components
import {
  Button,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

class ForgotPassword extends React.Component {
  state={
    email:"",
    codVerification:"",
    newPassword:"",
    confirmPassword:"",
    loading: false
  }
  onChange = (e)=>{
    let {name,value} = e.target;
    this.setState({[name]:value});
  }
  sendMail = () =>{
      this.setState({loading: true});
      var request = forgotPassword(this.state);
      request.then(response=>{
        if(response.data){
          toast.success("E-mail enviado.");
          this.setState({loading: false});
        }else{
          this.setState({loading: false});
          toast.error("Seu e-mail não foi encontrado ou não há um usuário associado.");
        }
      })
  }
  render() {

    return (
      <>
        <FormGroup className="mb-3">
            <span className="h5 text-black">Digite o seu e-mail cadastrado para enviarmos o código de verificação para a troca da senha</span>
            <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
                <InputGroupText>
                <i className="ni ni-email-83" />
                </InputGroupText>
            </InputGroupAddon>
            <Input placeholder="E-mail"  onKeyDown={(e)=>this.keyPress}  onChange={e => this.onChange(e)} name ="email" type="text" />
            </InputGroup>
        </FormGroup>
        <div className="text-center">
            {!this.state.loading ?
              <Button className="my-4" onClick={this.sendMail} color="primary" type="button">
              Enviar e-mail
              </Button>
              :
              <LoadingSpin primaryColor="#fff" size={28}/>
            }
            
        </div>
      </>
    );
  }
}

export default ForgotPassword;
