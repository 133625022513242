import React from "react";

import { toast } from "react-toastify";
import { Container } from "reactstrap";
import { getActiveTraps } from "../../../../services/api/Trap";
import moment from "moment";
import { getUserEmployeeDataByOrganization } from "../../../../services/api/Users";
import { getUserData } from "../../../../services/utils/auth";
import TableLoadingSkeleton from "../../../../components/ui/Loading/TableLoadingSkeleton";
import TrapsExportTable from "./TrapsExportTable";

const TrapsExport = () => {
  const [trapsData, setTrapsData] = React.useState(null);
  const [trapsFilteredData, setTrapsFilteredData] = React.useState(null);
  const [employeesData, setEmployeesData] = React.useState(null);
  const [isTrapsDataLoading, setIsTrapsDataLoading] = React.useState(false);
  const [isEmployeesDataLoading, setIsEmployeesDataLoading] =
    React.useState(false);

  React.useEffect(() => {
    fetchActiveTraps();
    fetchEmployeesData();
  }, []);

  React.useEffect(() => {
    if (!trapsData || !employeesData || trapsFilteredData) return;

    const filteredTraps =
      trapsData &&
      employeesData &&
      trapsData.map((trap) => {
        const installDate = formatDateToSaoPauloTimezone(trap.dateInstalled);
        const trapSituation = getTrapSituation(trap.histories);
        const histories = trap.histories;

        let visitsId = [];
        let lastVisitId = null;
        if(histories && histories.length > 0) {
          histories.map((situation, index) => {
            visitsId.push(situation.visitId)
            if(index == (histories.length - 1)) {
              lastVisitId = situation.visitId;
            }
          })
        }

        return {
          ULTIMA_VISITA_ID: lastVisitId,
          VISITAS_HISTORICO_ARMADILHA_ID: visitsId.join(', '),
          TIPO_ARMADILHA: getTrapTypeSurname[trap.trapTypeName]
            ? getTrapTypeSurname[trap.trapTypeName]
            : trap.trapTypeName,
          NUMERO_ARMADILHA: trap.number,
          LATITUDE: trap.latitude.toFixed(7),
          LONGITUDE: trap.longitude.toFixed(7),
          DATA_INSTALACAO: installDate,
          QUEM_INSTALOU: getEmployeeName(trap.whoInstallId),
          SITUACAO_ARMADILHA: trapSituation.situation,
          DATA_RETIRADA:
            trapSituation.situation === "Desativada"
              ? trapSituation.statusDate
              : "-",
          QUEM_RETIROU:
            trapSituation.situation === "Desativada"
              ? trapSituation.employeeResponsableForSituation
              : "-",
          ENDERECO: `${trap.realty.subpremise}, ${trap.realty.number}`,
          TIPO_IMOVEL: trap.realty.activityType
            ? trap.realty.activityType
            : "Não encontrado",
          NOME_MORADOR: "",
          TELEFONE_MORADOR: "",
        };
      });

    setTrapsFilteredData(filteredTraps);
  }, [trapsData, employeesData]);

  const getEmployeeName = (id) => {
    if (!id || !employeesData) return;

    const responsableByUserIdData = employeesData.find(
      (employee) => employee.userId === id
    );
    if (responsableByUserIdData) return responsableByUserIdData.employeeName;

    const responsableByEmployeeIdData = employeesData.find(
      (employee) => employee.employeeId === id
    );
    if (responsableByEmployeeIdData)
      return responsableByEmployeeIdData.employeeName;

    return "Funcionário não encontrado";
  };

  const formatDateToSaoPauloTimezone = (dateToFormat) => {
    if (!dateToFormat) return;

    const date = new Date(dateToFormat);
    const dateFormated = date.toLocaleString("pt-BR", dateFormatOptions);

    return dateFormated;
  };

  const generateTrapSituationObject = (trapHistory) => {
    if (trapHistory.trapWasRemoved)
      return {
        situation: "Desativada",
        employeeResponsableForSituation: getEmployeeName(trapHistory.userId),
        statusDate: formatDateToSaoPauloTimezone(trapHistory.statusDate),
      };

    if (trapHistory.trapWasInstalled || trapHistory.trapWasRead)
      return {
        situation: "Ativa",
        employeeResponsableForSituation: getEmployeeName(trapHistory.userId),
        statusDate: formatDateToSaoPauloTimezone(trapHistory.statusDate),
      };

    return;
  };

  const getTrapSituation = (trapHistories) => {
    if (!trapHistories) return;

    const lastTrapHistory = trapHistories[trapHistories.length - 1];
    const situation = generateTrapSituationObject(lastTrapHistory);

    if (!situation) {
      const invertedTrapHistories = [...trapHistories].reverse();

      const lastFilledHistory = invertedTrapHistories.find(
        ({ trapWasInstalled, trapWasRead, trapWasRemoved }) =>
          trapWasInstalled || trapWasRead || trapWasRemoved
      );

      const lastFilledHistorySituation =
        generateTrapSituationObject(lastFilledHistory);

      return lastFilledHistorySituation;
    }

    return situation;
  };

  const fetchActiveTraps = async () => {
    setIsTrapsDataLoading(true);

    const filter = {
      organizationId: getUserData("organizationId"),
      endDate: moment().toISOString(),
      showRemovedTraps: true,
      usersIds: [],
      demandId: "00000000-0000-0000-0000-000000000000",
      territorializations: [],
    };

    try {
      const { data, status } = await getActiveTraps(filter);
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados da armadilha. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      setTrapsData(data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    } finally {
      setIsTrapsDataLoading(false);
    }
  };

  const fetchEmployeesData = async () => {
    setIsEmployeesDataLoading(true);

    try {
      const { data, status } = await getUserEmployeeDataByOrganization();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados dos funcionários. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      const formatedEmployeesList = data.map((employee) => {
        return {
          ...employee,
          label: employee.employeeName,
          value: employee.userId,
        };
      });

      setEmployeesData(formatedEmployeesList);
    } catch (error) {
      console.error(error);
      toast.error(error);
    } finally {
      setIsEmployeesDataLoading(false);
    }
  };

  const getTrapTypeSurname = {
    armadilhaDisseminadoraInseticida: "ADI",
    armadilhaOvos: "OVITRAMPA",
    armadilhaMosquitoAdulto: "ADULTRAP",
    armadilhaMosquitoAdulto2: "BG-GAT",
    estacaoDisseminadoraLarvicida: "EDL",
  };

  const dateFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone: "America/Sao_Paulo",
  };

  return (
    <>
      <Container fluid style={{ marginTop: "2rem" }}>
        {isEmployeesDataLoading || isTrapsDataLoading ? (
          <TableLoadingSkeleton />
        ) : (
          trapsFilteredData && (
            <TrapsExportTable
              trapsToExport={trapsFilteredData}
              employeesList={employeesData}
            />
          )
        )}
      </Container>
    </>
  );
};

export default TrapsExport;
