import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { getAllTypeTerritorializations } from "../../../../services/api/territorialization";
import "./_Options.css";

const AppScreenPgs = React.FC = () => {
    const [syncByTypeLayer, setSyncByTypeLayer] = useState(false)
    const [typeLayers, setTypeLayers] = useState(null)

    useEffect(() => {
        getAllTypeTerritorializations().then(response => {
            setTypeLayers(response.data);
        })
    }, [])

    return (
        <div>
            <p className="h4 text-white">Lista de armadilhas ativada</p>
            <select className="form-control" >
                <option value={false}>Não</option>
                <option value={true}>Sim</option>
            </select>
            <p className="h4 text-white">Sincronização por tipo de mapa</p>
            <select className="form-control" value={syncByTypeLayer} onChange={(e) => setSyncByTypeLayer(e.target.value == "true")} >
                <option value={false}>Não</option>
                <option value={true}>Sim</option>
            </select>
            {
                syncByTypeLayer
                ?
                <>
                    <p className="h4 text-white">Selecione o tipo de mapa</p>
                    <select className="form-control">
                        {typeLayers && typeLayers.map((typeLayer) => (
                            <option value={typeLayer.id}>{typeLayer.name}</option>
                        ))}
                    </select>
                </>
                :
                <></>
            }
        </div>
    );
}

export default AppScreenPgs;