import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fetchActivityTypes } from '../../services/api/Realty';

class Loader extends Component {

    render() {

        return (
            <div className="row">
              <div className="col-12" style={{"textAlign" : "center"}}>
                <img src={require('../../assets/img/icons/common/loader.gif')}/>
              </div>              
            </div>
        );
    }
}


export default Loader;

