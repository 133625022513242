import React from "react";
import { toast, ToastContainer } from "react-toastify";
import tokml from "geojson-to-kml";

import { Card, CardHeader, Container, Row, Col, Table } from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import "../../../assets/css/style.css";
import "leaflet/dist/leaflet.css";
import FiltroTerrializacao from "../../../components/Navbars/FiltroTerritorializacao";
import TerritorializationUpdateModalComponent from "./TerritorializationUpdateModalComponent";
import TerritorializationViewModalComponent from "./TerritorializationViewModalComponent";
import {
  fetchTerritorializations,
  getAllTypeTerritorializations,
  getTypeLayer,
  putTerritorialization,
} from "../../../services/api/territorialization";
import { getPermissionsToken } from "../../../services/utils/auth";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import CardBody from "reactstrap/lib/CardBody";
import DataTable from "react-data-table-component";

const shpwrite = require("shp-write");

class Territorializacao extends React.Component {
  state = {
    lat: -5.812757,
    lng: -35.255127,
    zoom: 13,
    territorializations: [],
    nameToFilter: "",
    territorializationsFiltered: [],
    modal: false,
    modal2: false,
    TerritorializationType: "",
    TerritorializationTypeName: "",
    TerritorializationGeometry: "Polygon",
    kmlData: null,
  };

  async componentDidMount() {
    const typeTerritorializationRequest = await getAllTypeTerritorializations();
    var requestTerritorialization = fetchTerritorializations(
      typeTerritorializationRequest.data[0].id
    );
    requestTerritorialization.then((response) => {
      this.setState({
        territorializations: response.data,
        TerritorializationType: typeTerritorializationRequest.data[0].id,
        TerritorializationTypeName: typeTerritorializationRequest.data[0].name,
        TerritorializationGeometry:
          typeTerritorializationRequest.data[0].typeGeometry,
        territorializationsFiltered: response.data,
      });
    });
  }

  nameFilter = (name) => {
    this.setState({ nameToFilter: name });
  };

  toggleTerritorializationType = (type) => {
    this.setState({ TerritorializationType: type });
    let typeLayerRequest = getTypeLayer(type);
    typeLayerRequest.then((response) => {
      this.setState({
        TerritorializationGeometry: response.data.typeGeometry,
        TerritorializationTypeName: response.data.name,
      });
      let territorializationsRequest = fetchTerritorializations(type);
      territorializationsRequest.then((response) => {
        this.setState({
          territorializations: response.data,
          territorializationsFiltered: response.data,
        });
      });
    });
  };
  callbackFunction = (TerritorializationsFilteredArray) => {
    this.setState({
      territorializationsFiltered: TerritorializationsFilteredArray,
    });
  };

  selectTerritorializationToEdit = (territorialization) => {
    this.setState({ SelectedTerritorialization: territorialization });
    this.toggle();
  };

  selectTerritorializationToView = (territorialization) => {
    this.setState({ SelectedTerritorialization: territorialization });
    this.toggle2();
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2,
    });
  };

  changeStatus = async (territorialization, status) => {
    territorialization.status = status;
    const updateRequest = await putTerritorialization(territorialization);
    if (updateRequest.data.status == status) {
      toast.success("Alteração feita com sucesso!");
      const territorializations = await fetchTerritorializations(
        this.state.TerritorializationType
      );
      this.setState({
        territorializations: territorializations.data,
        territorializationsFiltered: territorializations.data,
      });
    } else {
      toast.error("Ocorreu algum erro, entre em contato com o suporte!");
    }
  };

  exportTerritorializationInKML = () => {
    const {
      TerritorializationTypeName,
      territorializationsFiltered,
      TerritorializationGeometry,
    } = this.state;

    const territorializationsToExport = {
      type: "FeatureCollection",
      features: [],
    };

    for (const territorialization of territorializationsFiltered) {
      let coordinates = [];

      if (TerritorializationGeometry === "Point") {
        coordinates = [
          territorialization.coordinate.x,
          territorialization.coordinate.y,
        ];
      } else {
        coordinates.push(
          territorialization.coordinates.map(({ x, y }) => [x, y])
        );
      }

      const featureObject = {
        type: "Feature",
        geometry: {
          type: TerritorializationGeometry,
          coordinates: coordinates,
        },
        properties: {
          nome: territorialization.name,
          numero: territorialization.number,
          codigoPostal: territorialization.postalCode,
          status: territorialization.status,
          endereco: territorialization.subpremisse,
        },
      };

      territorializationsToExport.features.push(featureObject);
    }

    const convertedTerritorializationToKml = tokml(territorializationsToExport);

    const blob = new Blob([convertedTerritorializationToKml], {
      type: "application/vnd.google-earth.kml+xml",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `${TerritorializationTypeName.replaceAll(" ", "")}.kml`;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  exportTerritorializations = () => {
    const {
      TerritorializationTypeName,
      territorializationsFiltered,
      TerritorializationGeometry,
    } = this.state;
    const exportDateString = moment().format("DD-MM-YYYY");
    const exportOptions = {
      folder: `VITEC-${TerritorializationTypeName}-${exportDateString}`,
      types: {
        point: "Pontos",
        polygon: "Territorializações",
      },
    };

    const territorializationsToExport = {
      type: "FeatureCollection",
      features: [],
    };

    for (const territorialization of territorializationsFiltered) {
      const coordinates = [];

      if (TerritorializationGeometry === "Point") {
        coordinates = [
          territorialization.coordinate.x,
          territorialization.coordinate.y,
        ];
      } else {
        coordinates.push(
          territorialization.coordinates.map(({ x, y }) => [x, y])
        );
      }

      const featureObject = {
        type: "Feature",
        geometry: {
          type: TerritorializationGeometry,
          coordinates: coordinates,
        },
        properties: {
          nome: territorialization.name,
          numero: territorialization.number,
          codigoPostal: territorialization.postalCode,
          status: territorialization.status,
          endereco: territorialization.subpremisse,
        },
      };

      territorializationsToExport.features.push(featureObject);
    }

    shpwrite.download(territorializationsToExport, exportOptions);
  };

  render() {
    const userPermissions = getPermissionsToken();

    const columns = [
      {
        name: "Nome",
        selector: "name",
        sortable: true,
      },
      {
        name: "Visualizar",
        cell: (t) => (
          <button
            onClick={() => this.selectTerritorializationToView(t)}
            className="btn btn-primary"
          >
            <i className="fa fa-search" />
          </button>
        ),
      },
      {
        name: "Editar",
        cell: (t) => (
          <button
            disabled={
              !userPermissions["dbd03c99-ac3c-4dd4-8964-cc066d77d403"].update
            }
            onClick={() => this.selectTerritorializationToEdit(t)}
            className="btn btn-primary"
          >
            <i className="ni ni-ruler-pencil" />
          </button>
        ),
      },
      /* {
        name: "Baixar em KML",
        cell: (t) => (
          <button
            onClick={(t) => this.exportTerritorializationInKML(t)}
            className="btn btn-primary"
          >
            <i className="fa fa-map" />
          </button>
        ),
      }, */
      {
        name: "",
        cell: (t) =>
          t.status === "Ativa" ? (
            <div
              style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
            >
              <button
                data-tip
                data-for="deactivateTerritorialization"
                className="btn btn-warning"
                data-clipboard-text="check-bold"
                disabled={
                  !userPermissions["dbd03c99-ac3c-4dd4-8964-cc066d77d403"]
                    .update
                }
                id="tooltip204269497"
                onClick={() => this.changeStatus(t, "Inativa")}
                type="button"
              >
                <i className="fa fa-times" />
              </button>
              <ReactTooltip
                effect="solid"
                type="warning"
                id="deactivateTerritorialization"
              >
                <span>Desativar Territorialização</span>
              </ReactTooltip>
            </div>
          ) : (
            <div
              style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
            >
              <button
                data-tip
                data-for="activateTerritorialization"
                className="btn btn-primary"
                data-clipboard-text="check-bold"
                disabled={
                  !userPermissions["dbd03c99-ac3c-4dd4-8964-cc066d77d403"]
                    .update
                }
                id="tooltip204269497"
                onClick={() => this.changeStatus(t, "Ativa")}
                type="button"
              >
                <i className="fa fa-check" />
              </button>
              <ReactTooltip
                effect="solid"
                type="success"
                id="activateTerritorialization"
              >
                <span>Ativar Territorialização</span>
              </ReactTooltip>
            </div>
          ),
      },
    ];

    if (
      !(
        userPermissions["dbd03c99-ac3c-4dd4-8964-cc066d77d403"] &&
        userPermissions["dbd03c99-ac3c-4dd4-8964-cc066d77d403"].read
      )
    ) {
      alert("Você não tem permissão para acessar essa funcionalidade!");
      this.props.history.goBack();
    }
    return (
      <>
        <Header />
        <ToastContainer />
        <FiltroTerrializacao
          parentCallback={this.callbackFunction}
          toggleTerritorializationType={this.toggleTerritorializationType}
          Territorializations={this.state.territorializations}
          exportTerritorializations={this.exportTerritorializations}
          exportTerritorializationInKML={this.exportTerritorializationInKML}
        />
        <TerritorializationUpdateModalComponent
          SelectedTerritorialization={this.state.SelectedTerritorialization}
          toggleCallback={this.toggle}
          openModal={this.state.modal}
          TerritorializationType={this.state.TerritorializationType}
          redoTerritorializationRequest={this.toggleTerritorializationType}
        />
        <TerritorializationViewModalComponent
          SelectedTerritorialization={this.state.SelectedTerritorialization}
          toggleCallback={this.toggle2}
          openModal={this.state.modal2}
          TerritorializationType={this.state.TerritorializationType}
          redoTerritorializationRequest={this.toggleTerritorializationType}
        />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-12">
            <Col className="mb-12 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Territorialização</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <DataTable
                    noHeader
                    defaultSortField="name"
                    defaultSortAsc={true}
                    pagination
                    highlightOnHover
                    columns={columns}
                    data={this.state.territorializationsFiltered}
                    noDataComponent={"Nenhum registro encontrado."}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Territorializacao;
