import React from "react";
import { Pie } from "react-chartjs-2";

// reactstrap components
import { Card, CardBody, CardHeader, Row, CardText, Col} from "reactstrap";

const PieCardPresentational = (props) => {
    const style={
        chartCol:{
            height:"25rem",
        }
    }
    return (
        <Card>
            <CardHeader>
                <CardText className="mb-0 mt-0" style={props.style.cardHeaderText}>
                    {props.textCardHeader}
                </CardText>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xl="12" style={style.chartCol}>
                        {/* <Pie data={props.data} options={{legend:{display:true, position:"top"}}}/> */}
                        {props.element}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}


export default PieCardPresentational;