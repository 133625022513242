import request from '../utils/request';

export function getSISPNCDNumbers(filter){
  return request({
    url: '/Report/SISPNCDNumbers',
    method: 'post',
    data: filter,
  });
}

export function getVisitGeoRef(filter){
  return request({
    url: '/Report/ReportGeoRef',
    method: 'post',
    data: filter,
  });
}