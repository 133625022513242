import { getUserData } from "../services/utils/auth";
import { ARMADILHA_INFO } from "./ArmadilhaConstant";

export const getTrapRange = (trapName) => {
  var organizationId = getUserData("organizationId");

  var qtt = 3;
  var response = {beggining: 0, middle: 30, end: 60};

  var name = "";

  if(ARMADILHA_INFO[trapName]) {
    name = ARMADILHA_INFO[trapName].apelido;
  }

  if(organizationId == "8e34767e-3673-4606-9e47-dd67edd2677d") {
    if (name === "ADI") {
      response = {beggining: 0, middle: 30, end: 60};
      qtt = 3;
    }

    if (name === "Estação Disseminadora") {
      response = {beggining: 0, middle: 15, end: 30};
      qtt = 3;
    }

    if (name === "Ovitrampa" || name === "BG-GAT" || name === "Adultrap") {
      response = {beggining: 0, end: 7};
      qtt = 2;
    }
  } else {
    if (name === "ADI" || name === "Estação Disseminadora") {
      response = {beggining: 0, middle: 30, end: 60};
      qtt = 3;
    }

    if(organizationId == "24a480de-51af-45a0-93c9-12499d329310")
    {
      if (name === "BG-GAT")
      {
        response = {beggining: 0, end: 15};
        qtt = 2;
      }
    } else {
      if (name === "BG-GAT")
      {
        response = {beggining: 0, end: 5};
        qtt = 2;
      }
    }

    if (name === "Ovitrampa" || name === "Adultrap") {
      response = {beggining: 0, end: 5};
      qtt = 2;
    }
  }

  return {quantityRange: qtt, data: response}

};
