import request from '../utils/request';

export function signup(person) {
  return request({
    url: '/crm/Person',
    method: 'post',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    data: person,
  });
}