import React, { useEffect, useState } from "react";
import FilterTraps from "../components/Navbars/FilterTraps";
import TrapsTable from "../components/Lists/Traps/TrapsTable";
import { ARMADILHA_INFO } from "../constants/ArmadilhaConstant";
import { getTrapsTypes, getActiveTraps } from "../services/api/Trap";
import { getUserData } from "../services/utils/auth";
import LoadingSpin from "react-loading-spin";
import "./DashboardTraps.css";


const DashboardTraps = (props) => {
  const [trapTypeOptions, setTrapTypeOptions] = useState([]);
  const [trapType, setTrapType] = useState({ trapTypeId: "00000000-0000-0000-0000-000000000000", trapTypeName: "Ovitrampa", trapName: "armadilhaOvos" });
  const [traps, setTraps] = useState([]);
  const [loadingTraps, setLoadingTraps] = useState(false);

  function handleChangeFilter(field, value) {
    switch (field) {
      case "trapType":
        if (value != null) {
          setTrapType(value);
        }
        break;
    }
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    const reqTrapTypes = getTrapsTypes();
    reqTrapTypes.then(response => {
      setTrapTypeOptions(response.data);
      if (response.data.length > 0) {
        let index = response.data.findIndex(tpType => tpType.name === 'armadilhaOvos');
        if (index !== -1) {
          setTrapType({ trapTypeId: response.data[index].id, trapTypeName: ARMADILHA_INFO[response.data[index].name].apelido, trapName: response.data[index].name })
        } else {
          setTrapType({ trapTypeId: response.data[index].id, trapTypeName: ARMADILHA_INFO[response.data[index].name].apelido, trapName: response.data[index].name })
        }
      }
    });

    const organizationId = getUserData("organizationId");

    const filter = {
      endDate: formatDate(new Date()),
      organizationId: organizationId
    };

    setLoadingTraps(true);

    getActiveTraps(filter).then((responseActiveTraps) => {
      var traps = responseActiveTraps.data;

      var allTrapsFiltered = traps.map((trap) => {
        return {
          name: ARMADILHA_INFO[trap.trapTypeName].apelido,
          id: trap.id,
          trapTypeId: trap.trapTypeId,
          number: trap.number,
          address: `${trap.realty.subpremisse ? trap.realty.subpremisse : trap.realty.subpremise}, ${trap.realty.number}`,
          latitude: trap.latitude,
          longitude: trap.longitude
        }
      })

      
      setTraps(allTrapsFiltered);
      setLoadingTraps(false);
    });
    
  }, [])

  return (
    <>
      <FilterTraps trapTypeId={trapType} trapTypeOptions={trapTypeOptions} handleChangeFilter={handleChangeFilter}/>
      
      {loadingTraps 
      ? 
      <div className="mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <LoadingSpin primaryColor="#000" size={28} />
      </div>
      :
      <TrapsTable traps={traps} loadingTraps={loadingTraps} />
      }
    </>
  );
};

export default DashboardTraps;
