import moment from "moment";
import getWeeks from "../epidemiologicalWeek";

export async function buildLabels({startDate, endDate}){
    const labels = new Array();
    let beginDate = moment(moment(startDate).format("YYYY-MM-01"));
    let finalDate = moment(moment(endDate).format("YYYY-MM-02"));

    while(beginDate.isBefore(finalDate)){
        labels.push(beginDate.format("MM/YYYY"));
        beginDate.add(1, 'month');
    }

    return labels;
}

export async function buildEpidemiologicalLabels({startDate, endDate}){
    const labels = new Array();
    let epidemiologicalWeeks = getWeeks();

    let beginDate = moment(startDate);
    let finalDate = moment(endDate);

    while(beginDate.isSameOrBefore(finalDate)){
        let year = beginDate.year();
        let yearIndex = epidemiologicalWeeks.findIndex(ew => ew.year === year.toString());
        for(let i = 0 ; i < epidemiologicalWeeks[yearIndex].weeks.length; i++){
            const epidemiologicalBeginDate = moment(epidemiologicalWeeks[yearIndex].weeks[i].beginDate, "DD-MM-YYYY");
            const epidemiologicalEndDate = moment(epidemiologicalWeeks[yearIndex].weeks[i].endDate, "DD-MM-YYYY");
            if(beginDate.isBetween(epidemiologicalBeginDate, epidemiologicalEndDate, undefined, '[]')){
                let yearLabelIndex = labels.findIndex(l => l.year === year.toString());
                if(yearLabelIndex === -1){
                    labels.push({year:year.toString(), labels:[]});
                    yearLabelIndex = labels.length - 1;
                }

                labels[yearLabelIndex].labels.push(i+1);
                break;
            }
        }

        beginDate.add(7, 'days');
    }


    return labels;
}