export const ARMADILHA_INFO = {
  estacaoDisseminadoraLarvicida: {
    apelido: "Estação Disseminadora",
    principalColetant: "",
    icons: {
      black: require("../assets/img/icons/EstacaoDisseminadora/k-estacaodisseminadora.png"),
      green: require("../assets/img/icons/EstacaoDisseminadora/g-estacaodisseminadora.png"),
      red: require("../assets/img/icons/EstacaoDisseminadora/r-estacaodisseminadora.png"),
      yellow: require("../assets/img/icons/EstacaoDisseminadora/y-estacaodisseminadora.png"),
      blue: require("../assets/img/icons/EstacaoDisseminadora/y-estacaodisseminadora.png"),
      orange: require("../assets/img/icons/EstacaoDisseminadora/l-estacaodisseminadora.png"),
    },
    principal_icon: require("../assets/img/icons/PrincipalIcons/estacaoDisseminadoraLarvicida.png"),
  },
  armadilhaMosquitoAdulto: {
    apelido: "Adultrap",
    principalColetant: "Mosquitos",
    icons: {
      black: require("../assets/img/icons/Adultrap/k-adultrap.png"),
      green: require("../assets/img/icons/Adultrap/g-adultrap.png"),
      red: require("../assets/img/icons/Adultrap/r-adultrap.png"),
      yellow: require("../assets/img/icons/Adultrap/y-adultrap.png"),
      blue: require("../assets/img/icons/Adultrap/y-adultrap.png"),
      orange: require("../assets/img/icons/Adultrap/l-adultrap.png"),
    },
    principal_icon: require("../assets/img/icons/PrincipalIcons/armadilhaMosquitoAdulto.png"),
  },
  armadilhaMosquitoAdulto2: {
    apelido: "BG-GAT",
    principalColetant: "Mosquitos",
    icons: {
      black: require("../assets/img/icons/BG-GAT/g-gat.png"),
      green: require("../assets/img/icons/BG-GAT/g-gat.png"),
      red: require("../assets/img/icons/BG-GAT/r-gat.png"),
      yellow: require("../assets/img/icons/BG-GAT/y-gat.png"),
      blue: require("../assets/img/icons/BG-GAT/y-gat.png"),
      orange: require("../assets/img/icons/BG-GAT/l-gat.png"),
    },
    principal_icon: require("../assets/img/icons/PrincipalIcons/armadilhaMosquitoAdulto2.png"),
  },
  armadilhaLarvas: {
    apelido: "Larvitrampa",
    principalColetant: "",
    icons: {
      black: require("../assets/img/icons/Larvitrampa/k-larvitrampa.png"),
      green: require("../assets/img/icons/Larvitrampa/g-larvitrampa.png"),
      red: require("../assets/img/icons/Larvitrampa/r-larvitrampa.png"),
      blue: require("../assets/img/icons/Larvitrampa/r-larvitrampa.png"),
    },
    principal_icon: require("../assets/img/icons/PrincipalIcons/armadilhaLarvas.png"),
  },
  armadilhaOvos: {
    apelido: "Ovitrampa",
    principalColetant: "Ovos",
    icons: {
      black: require("../assets/img/icons/Ovitrampa/k-ovitrampa.png"),
      green: require("../assets/img/icons/Ovitrampa/g-ovitrampa.png"),
      red: require("../assets/img/icons/Ovitrampa/r-ovitrampa.png"),
      yellow: require("../assets/img/icons/Ovitrampa/y-ovitrampa.png"),
      blue: require("../assets/img/icons/Ovitrampa/b-ovitrampa.png"),
      orange: require("../assets/img/icons/Ovitrampa/l-ovitrampa.png"),
    },
    principal_icon: require("../assets/img/icons/PrincipalIcons/armadilhaOvos.png"),
  },
  armadilhaDisseminadoraInseticida: {
    apelido: "ADI",
    principalColetant: "Inseticida",
    icons: {
      black: require("../views/pages/Reports/Traps/TrapIcons/Int2care/k-int2care.png"),
      green: require("../views/pages/Reports/Traps/TrapIcons/Int2care/g-int2care.png"),
      red: require("../views/pages/Reports/Traps/TrapIcons/Int2care/r-int2care.png"),
      yellow: require("../assets/img/icons/Int2caremonitor/y-in2monitor.png"),
      blue: require("../assets/img/icons/Int2caremonitor/y-in2monitor.png"),
      orange: require("../views/pages/Reports/Traps/TrapIcons/Int2care/l-int2care.png"),
    },
    principal_icon: require("../assets/img/icons/PrincipalIcons/armadilhaDisseminadoraInseticida.png"),
  },
};
