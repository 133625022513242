import React from "react";
function SampleList({Sample}){
    return(
        <tr>
            <th scope="row">{Sample.number}</th>
            {/* <td>{Sample.os}</td> */}
            <td>{Sample.typeMaterial}</td>
            {/* <td>
            <i className="fas fa-arrow-up text-success mr-3" />{" "}
            {Sample.type}
            </td> */}
            <td>
            {/* <i className="fas fa-arrow-up text-success mr-3" />{" "} */}
            Pendente de análise
            </td>
            {/* <td>
            <i className="fas fa-arrow-down text-danger mr-3" />{" "}
            {Sample.situation}
            </td> */}
            {/* <td> */}


                {/* <button
                    className="btn-icon-clipboard btn-acao-amostra"
                    data-clipboard-text="check-bold"
                    id="tooltip204269497"
                    type="button"
                > <div>
                    <i className="ni ni-check-bold" />
                    </div>
                </button> */}
                {/* Permissão */}
                
                {/* <button
                className="btn-icon-clipboard btn-acao-amostra"
                data-clipboard-text=" ni ni-fat-remove"
                id="tooltip204269497"
                type="button"
                >
                <div>
                    <i className=" ni ni-fat-remove" />
                </div>
                </button>
                <button
                className="btn-icon-clipboard btn-acao-amostra"
                data-clipboard-text="ni ni-fat-edit"
                id="tooltip204269497"
                type="button"
                >
                <div>
                    <i className="ni ni-ruler-pencil" />
                </div>
                </button> */}

            {/* </td> */}
        </tr>
    )
}

export default SampleList;