import React from "react";
import { Container, Row, Col } from "reactstrap";
import { fetchOrganizations } from "../../../services/api/Organization";
import { getTrapsTypes } from "../../../services/api/Trap";
import TicketModel from "./TicketModel";

const StylesToTicket = ({formatedOrganizationName, trapType,styleTicketModel, setStyleTicketModel}) => {
  const handleChangeStyle = (key, value) => {
    var copyOfStyleTicket = JSON.parse(JSON.stringify(styleTicketModel));

    copyOfStyleTicket[key] = value;

    setStyleTicketModel(copyOfStyleTicket);
    var nameStorage = "styleTicketModel_" + formatedOrganizationName + "_" + trapType;
    localStorage.setItem(nameStorage, JSON.stringify(copyOfStyleTicket));
  }

  return (
    <>
      <Container fluid>
        {
          styleTicketModel &&
          <>
            <Row>
              <Col>
                <span className="h2 text-white">
                  Definições de estilo do QrCode:
                </span>
              </Col>
            </Row>
            <Row>
              <Col xl={4}>
                <span className="h4 text-white">
                  Tamanho:
                </span>
                <input value={styleTicketModel.qrCodeSize} type="number" min={100} max={350} onChange={(e) => handleChangeStyle("qrCodeSize", e.target.value)} className="form-control"  />
              </Col>
              <Col xl={4}>
                <span className="h4 text-white">
                  Altura:
                </span>
                <input value={styleTicketModel.qrCodeHeight} type="number" min={0} max={250} onChange={(e) => handleChangeStyle("qrCodeHeight", e.target.value)} className="form-control"  />
              </Col>
              <Col xl={4}>
                <span className="h4 text-white">
                  Comprimento:
                </span>
                <input value={styleTicketModel.qrCodeWidth} type="number" min={0} max={250} onChange={(e) => handleChangeStyle("qrCodeWidth", e.target.value)} className="form-control"  />
              </Col>
              <Col xl={4}>
                <span className="h4 text-white">
                  Orientação:
                </span>
                <select value={styleTicketModel.orientation} name="" id="" className="form-control" onChange={(e) => {handleChangeStyle("orientation", e.target.value)}}>
                  <option value="left">Esquerda</option>
                  <option value="right">Direita</option>
                </select>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="h2 text-white">
                  Definições de estilo do Texto:
                </span>
              </Col>
            </Row>
            <Row>
              <Col xl={4}>
                <span className="h4 text-white">
                  Tamanho:
                </span>
                <input value={styleTicketModel.textFontSize} type="number" min={13} max={35} onChange={(e) => handleChangeStyle("textFontSize", e.target.value)} className="form-control"  />
              </Col>

              <Col xl={4}>
                <span className="h4 text-white">
                  Distância de acordo com o lado:
                </span>
                <input value={styleTicketModel.textWidth} type="number" min={0} max={100} onChange={(e) => handleChangeStyle("textWidth", e.target.value)} className="form-control"  />
              </Col>
              <Col xl={4}>
                <span className="h4 text-white">
                  Distância de acordo com a altura:
                </span>
                <input value={styleTicketModel.textBottom} type="number" min={0} max={100} onChange={(e) => handleChangeStyle("textBottom", e.target.value)} className="form-control"  />
              </Col>
              <Col xl={4}>
                <span className="h4 text-white">
                  Mostrar apenas o número:
                </span>
                <select value={styleTicketModel.showOnlyNumber} name="" id="" className="form-control" onChange={(e) => {handleChangeStyle("showOnlyNumber", e.target.value == "true")}}>
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </select>
              </Col>
              {
                styleTicketModel.showOnlyNumber
                ?
                <Col xl={4}>
                  <span className="h4 text-white">
                    Número cresce para:
                  </span>
                  <select value={styleTicketModel.textOrientation} name="" id="" className="form-control" onChange={(e) => {handleChangeStyle("textOrientation", e.target.value)}}>
                    <option value={"right"}>Direita</option>
                    <option value={"left"}>Esquerda</option>
                  </select>
                </Col>
                :
                <></>
              }
              <Col xl={4}>
                <span className="h4 text-white">
                  Quebra de linha:
                </span>
                <select name="" id="" className="form-control" onChange={(e) => {
                  var copyOfStyleTicket = JSON.parse(JSON.stringify(styleTicketModel));

                  copyOfStyleTicket["pageBreakBeforeNumber"] = false;
                  copyOfStyleTicket["pageBreakAfterTrapType"] = false;
                  if(e.target.value != "without") {
                    copyOfStyleTicket[e.target.value] = true;
                  }

                  setStyleTicketModel(copyOfStyleTicket);
                  
                }}>
                  <option value="pageBreakBeforeNumber">Antes do número</option>
                  <option value="pageBreakAfterTrapType">Depois do tipo de armadilha</option>
                  <option value="without">Sem quebra</option>
                </select>
              </Col>
            </Row>
          </>
        }
      </Container>
    </>
  );
};

export default StylesToTicket;
