import React, {memo, useEffect, useState} from "react";
import { BarChart, CartesianGrid, Legend, ReferenceLine, Tooltip, XAxis, YAxis, Bar, ResponsiveContainer, Brush } from "recharts";
import { useCurrentPng } from "recharts-to-png";
import CardPresentational from "../../../../Presentational/Sinan/CardPresentational";

const ConfirmedAgedContainer = ({data}) => {
    const [dataSet, setDataSet] = useState();
    const [getpng, {ref}] = useCurrentPng();

    useEffect(() => {
        if(data){
            buildChartDataSet(data).then(responseBuild => {
                setDataSet(responseBuild);
            });
        }
    }, [data])


    async function buildChartDataSet(data){
        const dataSet = [];

        for (let notificationNumbers of data){
            const dataObject = {
                idade: notificationNumbers.ageRange,
                Masculino: notificationNumbers.confirmedMenNotifications,
                Feminino: -notificationNumbers.confirmedWomenNotifications,
            }
            dataSet.push(dataObject)
        }

        return dataSet;
    }

    const buildChartElement = () => (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={dataSet}
                layout={"vertical"}
                stackOffset="sign"
                margin={{
                    top:30,
                    right:15
                }}
                ref={ref}
            >
                <text x={"50%"} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
                    <tspan fontSize="16" fontWeight={"bolder"}>Casos Confirmados </tspan>
                </text>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type={"number"} style={{fontSize:12}} tickFormatter={(v) => v < 0 ? -v : v}/>
                <YAxis dataKey="idade" type={"category"} style={{fontSize:12}} interval={0}/>
                <Tooltip formatter={(v) => v < 0 ? -v : v}/>
                <Legend align="center" verticalAlign="top" />
                <ReferenceLine x={0} stroke="#000" />                            
                <Bar dataKey="Feminino" stackId='a' fill="#c7d489" />
                <Bar dataKey="Masculino" stackId='a' fill="#6ba0a0" />                    
            </BarChart>
        </ResponsiveContainer>
    )

    const style={
        cardHeaderText: {
            color:"black",
            fontWeight:"bold",
            fontSize:"0.97rem"
        },
    }

    const textCardHeader = "Confirmações por faixa etária";
    return(
        <CardPresentational
            CardId="ConfirmedAgedChart"
            style={style}
            textCardHeader={textCardHeader}
            element={buildChartElement()}
            getpng={getpng}
        />
    );
}

export default ConfirmedAgedContainer