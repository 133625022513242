import React from "react";
import CsvDownloadButton from "react-json-to-csv";
import { Container } from "reactstrap";
import {
  fetchEmployee,
  fetchEmployeeData,
} from "../../../../services/api/Employee";
import { fetchModules } from "../../../../services/api/Modules";
import {
  getPermissions,
  getUserByEmployeeId,
} from "../../../../services/api/Users";

import "../../../../assets/css/navbar.css";
import "../../../../components/Navbars/FilterEmployees.css";

class EmployeesExportFilters extends React.Component {
  state = {
    filterName: "",
    filterModule: "",
    filterStatus: "",
    EmployeesFormated: [],
    Modules: null,
  };
  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  formatDate = (dataString) => {
    const dataObjeto = new Date(dataString);

    const ano = dataObjeto.getFullYear();
    const mes = (dataObjeto.getMonth() + 1).toString().padStart(2, "0");
    const dia = dataObjeto.getDate().toString().padStart(2, "0");

    const dataFormatada = `${dia}/${mes}/${ano}`;

    return dataFormatada;
  };

  formatEmployees = (modules) => {
    var Employees = this.props.Employees;

    var promises = Employees.map((employee) => {
      return Promise.all([
        fetchEmployee(employee.personId).then((response) => {
          var requestPerson = response.data;

          employee.dadosFuncionario = {
            nome: requestPerson.name,
            dataAniversario: this.formatDate(
              requestPerson.birthDate.substring(0, 10)
            ),
            email: requestPerson.email,
            cpf: requestPerson.cpf,
            cnh: requestPerson.cnh,
            categoriaCnh: requestPerson.cnhCategory,
            celular: requestPerson.mobile,
            telefone: requestPerson.telephone,
            rg: requestPerson.rg,
            dataCriacao: this.formatDate(
              requestPerson.createAt.substring(0, 10)
            ),
          };
        }),
        fetchEmployeeData(employee.id).then((response) => {
          var requestEmployeeData = response.data;
          employee.formation = requestEmployeeData.formation;
          employee.admissionDate = requestEmployeeData.admissionDate.substring(
            0,
            10
          );
        }),
        getUserByEmployeeId(employee.id).then((response) => {
          if (response.data.userId !== "00000000-0000-0000-0000-000000000000") {
            employee.login = response.data.username;

            return getPermissions(response.data.userId).then(
              (responsePermissions) => {
                var permissionsFormated = responsePermissions.data.reduce(
                  function (acc, obj) {
                    var actualModule = modules.filter(
                      (module) => module.id == obj["moduleId"]
                    );

                    let key = actualModule[0].name;

                    var objToInsert = {
                      atualizar: obj.update,
                      deletar: obj.delete,
                      criar: obj.create,
                      ler: obj.read,
                    };

                    var stringCRUD = "";
                    Object.keys(objToInsert).map((object) => {
                      if (objToInsert[object] == true) {
                        stringCRUD += ` ${object},`;
                        objToInsert[object] = "Sim";
                      } else {
                        objToInsert[object] = "Não";
                      }
                    });

                    if (stringCRUD.length > 0) {
                      stringCRUD = stringCRUD.substring(
                        0,
                        stringCRUD.length - 1
                      );
                    }

                    obj = stringCRUD;

                    if (!acc[key]) {
                      acc[key] = [];
                    }
                    acc[key] = obj;
                    return acc;
                  },
                  {}
                );

                if (Object.keys(permissionsFormated).length === 0) {
                  employee.permissoes = "Nenhuma permissão";
                } else {
                  var stringPermissoes = "";
                  Object.keys(permissionsFormated).map((obj) => {
                    stringPermissoes += `${obj} => ${permissionsFormated[obj]} ; `;
                  });
                  employee.permissoes = stringPermissoes;
                }
              }
            );
          } else {
            employee.login = "";
            employee.permissoes = "";
          }
        }),
      ]).then(() => {});
    });

    Promise.all(promises).then(() => {
      var newEmployeesArray = Employees.map((objetos) => {
        return {
          identificador: objetos.id,
          nome: objetos.name,
          cargo: objetos.place,
          matricula: objetos.registration,
          dataAdmissao: this.formatDate(objetos.admissionDate),
          formacao: objetos.formation,
          funcao: objetos.function ? objetos.function.name : objetos.function,
          ...Object.keys(objetos.dadosFuncionario).reduce((acc, obj) => {
            acc[obj] = objetos.dadosFuncionario[obj];
            return acc;
          }, {}),
          login: objetos.login,
          permissoes: objetos.permissoes,
        };
      });

      this.setState({ EmployeesFormated: newEmployeesArray });
      this.setState({ loadingCsv: false });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.Employees != this.props.Employees) {
      this.setState({ loadingCsv: true });
      var modules = fetchModules();

      modules.then((response) => {
        this.formatEmployees(response.data);
      });
    }
  }

  render() {
    let filteredEmployee2 = this.props.Employees;
    let filteredEmployee = filteredEmployee2.filter((Employee) => {
      return (
        Employee.name
          .toLowerCase()
          .indexOf(this.state.filterName.toLowerCase()) !== -1
      );
    });
    return (
      <>
        {this.state.loadingCsv ? null : (
          <CsvDownloadButton
            className="btn btn-primary"
            data={this.state.EmployeesFormated}
            filename={"funcionarios_arquivo"}
            style={{ width: "330px" }}
          >
            {" "}
            <i className="fa fa-download" /> Baixar CSV de Funcionários
          </CsvDownloadButton>
        )}
      </>
    );
  }
}

export default EmployeesExportFilters;
