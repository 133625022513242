import React from "react";
import { Link } from "react-router-dom";
import 'react-select-2/dist/css/react-select-2.css';
import { fetchDemands } from "../../services/api/Demand";



import {
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
// core components
import "../../assets/css/style.css";
import { toast, ToastContainer } from "react-toastify";
import { postSample } from "../../services/api/Sample";
import {timeStampFormated} from "../../services/utils/format";
import { getUserData } from "../../services/utils/auth";
import { addSampleCounter, getSampleCounter } from "../../services/api/Employee";
import "./FormSampleCovid.css";

class FormSampleCovid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            wasCreated: false,
            modal: "", 
            OrganizationId: getUserData('organizationId'),
            Number: "",
            CollectorId: getUserData('userId'),
            TypeMaterial: "",
            DemandId: "",
            Demands: [],
            VisitId: "00000000-0000-0000-0000-000000000000",
        };
      }

    changeSample = e => {
        let field = e.target.name;
        let value = e.target.value;
        this.setState({ [field]: value });
    }

    submitSample = () => {

        if (this.state.Number == "")
        {
            toast.error("Erro: Amostra sem número");
            return;
        }

        if (this.state.wasCreated)
        {
            toast.error("Amostra já cadastrada!");
            return;
        }

        if (this.state.TypeMaterial == "")
        {
            toast.error("É necessário inserir o tipo de material");
            return;
        }
        
        const requestCovid = postSample(this.state);
        requestCovid.then((response) => {
            return response.data
        }).then((data) => {
            if (data.id) {
                this.setState({wasCreated:true});
                let requestAddCounter = addSampleCounter();
                toast.success("Amostra cadastrada com sucesso!");
                this.toggle(false);
            }
        })
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        }); 
    }

    handleChangeDemand = (event) => {
        this.setState({DemandId : event.target.value});
    }

    componentDidMount() {

       var requestCounter = getSampleCounter();
       requestCounter.then(response => {
            this.setState({Number: String(response.data.nextSampleNumber)});
       })

       var requestDemands = fetchDemands();
       requestDemands.then(response =>{
            this.setState({Demands: response.data});            
       });

       if (this.props.VisitId !== '') {
           this.setState({VisitId: this.props.VisitId});
       }

       if (this.props.DemandId !== '') {
           this.setState({DemandId: this.props.DemandId});
       }
    }

    render() {       
      
        return (
            <>  
            
            <ToastContainer />

                <Container className="" fluid>

                <Modal size="lg" isOpen={this.state.modal} fade="true"  toggle={()=>this.toggle(false)}>
                    <ModalHeader toggle={()=>this.toggle()}>Cadastrar resultado da amostra</ModalHeader>
                    <ModalBody>           
                        <div className="row"> 
                            <div className="col-9">
                                <span className="h2 text-black">Deseja avançar para o resultado da amostra cadastrada?</span>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row" >                          
                        
                            <div className="col-6">
                                <button onClick={()=>this.toggle()} className="form-control">VOLTAR</button>
                            </div>
                            <div className="col-6">
                                <Link to={{pathname:"/admin/AddSampleResult", data: {number: this.state.Number}}}> 
                                <button className="btn btn-primary">AVANÇAR</button>
                                </Link>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>

                    <div className="row">
                        <div className="linha-form col-4 responsive-form-field-sample-full">
                                <span className="h5 text-black">Número da amostra</span>
                                <input type="text" name="Number" defaultValue={this.state.Number} onChange={this.changeSample.bind(this)} className="form-control" />
                        </div>

                        <div className="linha-form col-4 responsive-form-field-sample-medium">                  
                                <span className="h5 text-black">Tipo do material</span>
                                
                                <select className="form-control" name="TypeMaterial" onChange={this.changeSample.bind(this)}>
                                    <option>Selecione</option>
                                    <option value='Água'>Água</option>
                                    <option value='Palhetas'>Palheta</option>
                                    <option value='Larvas'>Larvas</option>
                                    <option value='Pupas'>Pupas</option>
                                    <option value='Larvas e Pupas'>Larvas e Pupas</option>
                                    <option value='Mosquitos Adultos'>Mosquitos Adultos</option>
                                </select>
                        </div>

                        <div className="col-4 responsive-form-field-sample-medium">
                        <span className="h5 text-black">Demanda</span>
                        <select className="form-control" name="DemandId" onChange={this.handleChangeDemand}>
                          <option>Selecione</option>
                          {this.state.Demands.map((demand => {
                              
                              var demandaDateFormated = timeStampFormated(demand.createDate);
                              var problemDisplay = "";
                              var programDisplay = "";
                              demand.problems.forEach((problem) =>{
                                  problemDisplay = problemDisplay + problem.name + " ";
                                  programDisplay = programDisplay + problem.program.name + " ";
                              })
                              
                            return (
                                <option value={demand.id} selected={this.state.DemandId == demand.id}>{demandaDateFormated} - {problemDisplay}</option>
                            )
                              
                          }))}                           
                        </select>
                      </div>
                    </div>

        
                    <div style={{ display: (typeof(this.props.changeSample) === 'function') ? 'none' : 'block' }}>
                        <div className="row">
                            <div className="linha-form col-4 responsive-form-field-sample-medium">
                                <button className="btn btn-primary" onClick={() => {this.submitSample()}} >Cadastrar</button>
                            </div>
                            <div className="col-4 responsive-form-field-sample-medium">
                                <Link to="/admin/amostrasColetadas">                                
                                <button className="form-control btn btn-warning">Voltar</button>
                                </Link>
                            </div>
                            <div className="col-4 responsive-form-field-sample-medium">                                                             
                                <button onClick={ () => {window.location.reload()} } className="form-control btn btn-info">Limpar</button>                                
                            </div>
                        </div>
                    </div>

                    
                </Container>

            </>
        );
    }
}

export default FormSampleCovid;
