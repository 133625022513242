import React from "react";
// react component that copies the given text inside your clipboard
import { Link } from "react-router-dom";
// reactstrap components
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import "../../../assets/css/style.css";
import { fetchEmployeeFunctions, postEmployee, verifyEmail } from "../../../services/api/Employee";
import { getUserData } from "../../../services/utils/auth";
import { fetchModules, setPermissions } from "../../../services/api/Modules";
import { checkPermissionComponent } from "../../../services/utils/permission";
import { Select } from "../../../components/Wrappers/SelectAll";
import "./Employee.css";
import LoadingSpin from "react-loading-spin";

const educationOptions = [
  {label: "Ensino fundamental incompleto", value: "Fundamental Incompleto"},
  {label: "Ensino fundamental completo", value: "Fundamental Completo"},
  {label: "Ensino médio incompleto", value: "Médio Incompleto"},
  {label: "Ensino médio completo", value: "Médio Completo"},
  {label: "Graduação incompleto", value: "Graduação Incompleto"},
  {label: "Graduação completo", value: "Graduação Completo"},
  {label: "Mestrado", value: "Mestrado"},
  {label: "Doutorado", value: "Doutorado"},
  {label: "Pós Doutorado", value:"Pós Doutorado"},
]
const placeOptions = [
  {label: "Médico Veterinário", value: "Médico Veterinário"},
  {label: "Agente de Endemias", value: "Agente de Endemias"},
  {label: "Analista de Sistemas", value: "Analista de Sistemas"},
  {label: "Agente de Endemias Educador", value: "Agente de Endemias Educador"},
  {label: "Fiscal de Vigilância Sanitária", value: "Fiscal de Vigilância Sanitária"},
  {label: "Técnico em Zoonoses", value: "Técnico em Zoonoses"},
  {label: "Outro", value: "Outro"}
]

const cnhCategory = [
  {label: "A", value: "A"},
  {label: "B", value: "B"},
  {label: "AB", value: "AB"},
  {label: "ABD", value: "ABD"},
  {label: "C", value: "C"},
  {label: "D", value: "D"},
  {label: "E", value: "E"}
]


class AddEmployee extends React.Component {
 
  constructor(props){
    super(props);
    if(!checkPermissionComponent("ba56119d-ac43-4ec1-858e-31f2e12235e8", "create")){
      alert("Você não tem permissão para acessar essa funcionalidade!");
      props.history.goBack(); 
    }
    this.state = this.getInitialState();
  }
  getInitialState = () => ({
    loading: false,
    createUser: false,
    isEmailValid: false,
    EmployeeFunctions : [],
    Employee : {
      organizationId: getUserData("organizationId"),
      registration:"",
      place:"",
      formation:"",
      admissionDate:"",
      userPermission:"",
      perfil:"",
      functionId:"00000000-0000-0000-0000-000000000000",
    },
    Person : {
      name:"",
      email:"",
      cpf: "",
      rg:"",
      birthDate:"",
      telephone:"",
      mobile:"",
      cnh:"",
      cnhCategory: "",
    },
    User : [{
      username:"",
      Password:""
    }],
    Permissions: []
  })
  reset(){
    this.setState(this.getInitialState());
  }

  handlePersonChange = (name, e) => {
    let {value} = e;
    let p = this.state.Person;
    p[name] = value;
    this.setState({
      Person: p
    });
  };
  handleUserChange = e => {
    
    let {name,value} = e.target;
    if(name!="Password"){
      this.handlePersonChange(name, e.target);
    }
    if(name === "name"){
      //name = "username";
    }

    let u = this.state.User;
    u[0][name] = value;
    this.setState({
      User: u
    });
  };
  handlePermissionChange = (ModuleId, TypePermission, Value) => {
    let Permissions = this.state.Permissions;
    
    if(!Permissions[ModuleId]){
      Permissions[ModuleId] = {
        create:false,
        read:false,
        update:false,
        delete:false,
        id: "00000000-0000-0000-0000-000000000000",
        ModuleId: ModuleId,
        userId: this.state.UserId
      }
    }
    
    Permissions[ModuleId][TypePermission] = Value;

    if(Value === true){
      Permissions[ModuleId].read = true;
    }
      
      this.setState({Permissions: Permissions});    

    var create = false ;
    var read = false;
    var update = false;
    var delete2 = false;
    switch (TypePermission) {
      case 'Read':
        read = true;
        delete2 = false;
        update = false;
        create = false;
        break;
      case 'Delete':
        delete2 = true;
        read = true;
        update = false;
        create = false;
        break;
      case 'Update':
        update = true;
        read = true;
        delete2 = false;
        create = false;
        break;
      case 'Create':
        create = true;
        read = true;
        delete2 = false;
        update = false;
        break;
    }
    var hasPermission = false;
    Permissions.map(permission=>{
      if(permission.ModuleId == ModuleId){
        hasPermission = true;
      }
    })
    if(!hasPermission){
      let permissionPush = {
        'ModuleId': ModuleId,
        create:create,
        read:read,
        update:update,
        delete:delete2,
        userId: this.state.UserId
      }
      Permissions.push(permissionPush);
      this.setState(Permissions);
    }
    Permissions.map(permission=>{
      if(permission.ModuleId == ModuleId){
        permission[TypePermission] = Value;
        if(Value === true){
          permission.read = true;
        }
      }
    })
    this.setState({Permissions: Permissions});
  }
  
  handleAllPermissionChange = (ModuleId, Value) => {
    let Permissions = this.state.Permissions;

    if(!Permissions[ModuleId]){
      Permissions[ModuleId] = {
        create:false,
        read:false,
        update:false,
        delete:false,
        id: "00000000-0000-0000-0000-000000000000",
        ModuleId: ModuleId,
        userId: this.state.UserId
      }
    }
    
    Permissions[ModuleId]['read'] = Value;
    Permissions[ModuleId]['delete'] = Value;
    Permissions[ModuleId]['update'] = Value;
    Permissions[ModuleId]['create'] = Value;

      
    this.setState({Permissions: Permissions});   


    let hasPermission = false;
    var SelectedPermission; 
    Permissions.forEach(Permission => {
      if(Permission['ModuleId'] == ModuleId) {
        hasPermission = true;
        SelectedPermission = Permission;
        Permission['read'] = Value;
        Permission['delete'] = Value;
        Permission['update'] = Value;
        Permission['create'] = Value;
        this.setState(Permissions);
        return;
      }
    });

    if (hasPermission == false) {
      let permissionPush = {
        'ModuleId': ModuleId,
      }
  
      
      permissionPush['read'] = Value;
      permissionPush['delete'] = Value;
      permissionPush['update'] = Value;
      permissionPush['create'] = Value;
          
  
      
      Permissions.push(permissionPush);
      this.setState(Permissions);
    }    
  }
  handleEmployeeChange = (name, e) => {
    let {value} = e;
    if(name === "userPermission"){
      this.toggle(value);
    }
    let em = this.state.Employee;
    em[name] = value;
    this.setState({
      Employee: em
    });
  };

  changeLogin = (e) => {
    let us = this.state.User;
    us[0]['username'] = e.target.value;
    this.setState({ User: us});
  }

  toggle = e => {
    if(e === "Sim"){

      this.setState({
        createUser: true
      });
    }else{
      this.setState({
        createUser: false
      });
    }
    
  }
  createEmployee = () => {
    this.setState({loading: true});
    let error = false;

    if (!this.state.Person.birthDate) {
      toast.error("Erro: A data de nascimento é obrigatória");
      error=true;
    }
    if (!this.state.Employee.admissionDate) {
      toast.error("Erro: A data de admissão é obrigatória");
      error=true;
    }
    if (!this.state.Person.email) {
      toast.error("Erro: O e-mail é obrigatório");
      error=true;
    } else if (this.state.isEmailValid == false) {
      toast.error("Erro: O e-mail não é válido");
      error=true;
    }
    
    if (!this.state.User[0].username && this.state.Employee.userPermission == 'Sim') {
      toast.error("Erro: O Login é obrigatório");
      error=true;
    }

    if (!this.state.Employee.place) {
      toast.error("Erro: O cargo é obrigatório");
      error=true;
    }
    if (!this.state.Person.name) {
        toast.error("Erro: É necessário preencher o nome");
        error=true;
    }

    if (!this.state.Person.cpf) {
        toast.error("Erro: É necessário preencher o CPF");
        error=true;
    }

    if (!this.state.Employee.registration) {
      toast.error("Erro: É necessário preencher a Matrícula");
      error=true;
    }

    if (error == true) {
      this.setState({loading: false});
      return;
    }

    const requestEmployee = postEmployee(this.state.Employee, this.state.Person,  this.state.User);
 
    requestEmployee.then(response => {
      if(response) {
          return response.data
      }
      throw new Error("Erro de requisição...");
    })
    .then(data => {        
        if (data.id != null) {
          let permissionsSend = this.state.Permissions;
          permissionsSend.forEach(permission => {
            permission['UserId'] = data.userId;
          });
          const requestPermissions = setPermissions(permissionsSend);
          this.setState({loading: false});
        } else {
          toast.error("Não foi possível cadastrar o funcionário");
        }        
      if (data.id != null) {
        toast.success("Funcionário cadastrado com sucesso!");
      } else {
        toast.error("Não foi possível cadastrar o funcionário");
      }        
    }).catch(e => {
      if(e.response.status == 426){
        toast.error("E-mail já cadastrado.\nEntre em contato com o suporte!");
      }
    });
  }
  checkEmail = (email) =>{

      if (email == '') return;

      // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
      // if ( re.test("'" + email + "'") ) {
      //   toast.error("Digite um email válido!")
      //   return;
      // }
    
      var verifyRequest = verifyEmail(email);
      verifyRequest.then(response =>{
        if(response) {
          return response.data
        }
      throw new Error("Erro de requisição...");
      })
      .then(data =>{
        if(data == true){
          toast.error("Esse email já está em uso!");
          this.state.isEmailValid = false;
        }else{
          toast.success("Email disponível!");
          this.state.isEmailValid = true;
        }
      })
  }

  componentDidMount() {
    var requestModules = fetchModules();
        requestModules.then(response => {
            this.setState({modules: response.data});
        })
        
    let employeeFunctionsRequest = fetchEmployeeFunctions(getUserData("organizationId"));
    employeeFunctionsRequest.then(response => {
      this.setState({EmployeeFunctions: response.data.map(({name, id}) => ({label: name, value:id}))});
    })
  }

  render() {
    return (
      <>
        <Header />

        <ToastContainer />

        <Container className="container-flex-employee" fluid>
          <Row className="mt-12">
            <Col className="mb-12 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        <Link to="/admin/employees"> <i className=" ni ni-bold-left bnt-voltar" /></Link>
                        Novo Funcion&aacute;rio</h3>
                    </div>
                  </Row>
                </CardHeader>

                <Container fluid>
                  <div className="header-body">

                    <div className="row linha-form">
                      <div className="col-4 responsive-field-form-employee-medium">
                        <span className="h5 text-black"><b>Nome*</b></span>
                        <input type="text" value={this.state.Person.name}className="form-control" name="name"onChange={this.handleUserChange} placeholder="Nome" />
                      </div>
                      <div className="col-4 responsive-field-form-employee-medium">
                        <span className="h5 text-black"><b>Matrícula*</b></span>
                        <input type="text" className="form-control" value={this.state.Employee.registration}name="registration"onChange={(e) => {this.handleEmployeeChange("registration", e.target)}} placeholder="Matrícula" />
                      </div>
                      <div className="col-2 responsive-field-form-employee-medium">
                        <span className="h5 text-black"><b>Cargo*</b></span>
                        <Select
                          styles={{
                              control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                              option: styles =>({...styles, fontSize:"0.875rem"}),
                              valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"})
                          }}
                          value={placeOptions.filter(t => this.state.Employee.place === t.value)}
                          placeholder={"Selecione"}
                          onChange={(e) => {this.handleEmployeeChange("place", e)}}
                          options={placeOptions}
                        />
                      </div>
                      <div className="col-2 responsive-field-form-employee-medium">
                        <span className="h5 text-black"><b>Função*</b></span>
                        <Select
                          styles={{
                              control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                              option: styles =>({...styles, fontSize:"0.875rem"}),
                              valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"})
                          }}
                          value={this.state.EmployeeFunctions.filter(t => this.state.Employee.functionId === t.value)}
                          placeholder={"Selecione"}
                          onChange={(e) => {this.handleEmployeeChange("functionId", e)}}
                          options={this.state.EmployeeFunctions}
                        />
                      </div>
                    </div>

                    <div className="row linha-form">
                      <div className="col-4 responsive-field-form-employee-medium">
                        <span className="h5 text-black">Formação</span>
                        <Select
                          styles={{
                              control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                              option: styles =>({...styles, fontSize:"0.875rem"}),
                              valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"})
                          }}
                          value={educationOptions.filter(t => this.state.Employee.formation === t.value)}
                          placeholder={"Selecione"}
                          onChange={(e) => {this.handleEmployeeChange("formation", e)}}
                          options={educationOptions}
                        />
                      </div>
                      <div className="col-4 responsive-field-form-employee-medium">
                        <span className="h5 text-black">RG (Sem pontuação)</span>
                        <input type="text" className="form-control"value={this.state.Person.rg} name="rg"onChange={(e) => {this.handlePersonChange("rg", e.target)}}  placeholder="RG" maxlength="9"/>
                      </div>
                      <div className="col-4 responsive-field-form-employee-full">
                        <span className="h5 text-black"><b>CPF (Sem pontuação)*</b></span>
                        <input type="text" className="form-control"value={this.state.Person.cpf} name="cpf"onChange={(e) => {this.handlePersonChange("cpf", e.target)}} placeholder="CPF" maxlength="11" />
                      </div>
                    </div>

                    <div className="row">
                      <div className="linha-form col-4 responsive-field-form-employee-full">
                        <span className="h5 text-black">Data de Admissão</span>
                        <input type="date" className="form-control"value={this.state.Employee.admissionDate} name="admissionDate"onChange={(e) => {this.handleEmployeeChange("admissionDate", e.target)}} placeholder="Data de Admissão" />
                      </div>
                      <div className="linha-form col-4 responsive-field-form-employee-full">
                        <span className="h5 text-black"><b>Data de Nascimento*</b></span>
                        <input type="date" className="form-control"value={this.state.Person.birthDate} name="birthDate"onChange={(e) => {this.handlePersonChange("birthDate", e.target)}} />
                      </div>

                      <div className="linha-form col-2 responsive-field-form-employee-medium">
                        <span className="h5 text-black">CNH</span>
                        <input type="text" className="form-control" value={this.state.Person.cnh}name="cnh"onChange={(e) => {this.handlePersonChange("cnh", e.target)}} placeholder="Número da CNH" />
                      </div> 

                      <div className="linha-form col-2 responsive-field-form-employee-medium">
                        <span className="h5 text-black">Categoria da CNH</span>
                        <Select
                          styles={{
                              control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                              option: styles =>({...styles, fontSize:"0.875rem"}),
                              valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"})
                          }}
                          value={cnhCategory.filter(t => this.state.Person.cnhCategory === t.value)}
                          placeholder={"Selecione"}
                          onChange={(e) => {this.handlePersonChange("cnhCategory", e)}}
                          options={cnhCategory}
                        />
                      </div>

                    </div>

                    <div className="row">

                      <div className="linha-form col-4 responsive-field-form-employee-medium">
                        <span className="h5 text-black">Telefone</span>
                        <input type="text" className="form-control"value={this.state.Person.telephone} name="telephone"onChange={(e) => {this.handlePersonChange("telephone", e.target)}} placeholder="Telefone" />
                      </div>

                      <div className="linha-form col-4 responsive-field-form-employee-medium">
                        <span className="h5 text-black">Celular</span>
                        <input type="text" className="form-control"value={this.state.Person.mobile} name="mobile"onChange={(e) => {this.handlePersonChange("mobile", e.target)}} placeholder="Celular" />
                      </div>                                

                    
                      <div className="linha-form col-4 responsive-field-form-employee-full">
                        <span className="h5 text-black">E-mail*</span>
                        <input type="text" className="form-control" onBlur={ () => this.checkEmail(this.state.Person.email)} value={this.state.Person.email} name="email" onChange={this.handleUserChange} placeholder="E-mail" />
                      </div>

                    </div>                   

                    <div className="row">
                      <div className="linha-form col-4 responsive-field-form-employee-full">
                        <span className="h5 text-black">O funcionário terá acesso ao Sistema?</span>
                        <Select
                          styles={{
                              control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                              option: styles =>({...styles, fontSize:"0.875rem"}),
                              valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"})
                          }}
                          value={[{label:"Sim", value:"Sim"}, {label:"Não", value: "Não"}].filter(t => this.state.Person.userPermission === t.value)}
                          placeholder={"Selecione"}
                          onChange={(e) => {this.handleEmployeeChange("userPermission", e); this.handlePersonChange("userPermission", e)}}
                          options={[{label:"Sim", value:"Sim"}, {label:"Não", value: "Não"}]}
                        />
                      </div>
                      
                    </div>
                    {this.state.createUser ? 
                    <>
                    <div className="row">

                    <div className="linha-form col-4 responsive-field-form-employee-full">
                      <span className="h5 text-black">Login</span>
                      <input type="text" className="form-control" name="username" value={this.state.User.username}onChange={this.changeLogin} placeholder="Login" />
                    </div>

                    </div>

                    <div className="row">
                      <div className="linha-form col-12">
                        <span className="h5 text-black">Permissões</span>    
                        <div className="table_specification">
                          {this.state.modules.map((module) => {                            
                              return (
                                <dl>
                                  <dd>{module.name}</dd>
                                  <dd><input type="checkbox" onChange={(e) => this.handleAllPermissionChange(module.id,e.target.checked)} name="" id=""/> Todos</dd>
                                  <dd><input type="checkbox" checked={this.state.Permissions[module.id] ? this.state.Permissions[module.id]['read'] :false} onChange={() => this.handlePermissionChange(module.id, 'read', this.state.Permissions[module.id] ? !this.state.Permissions[module.id]['read'] : true)} name="" id=""/> Ler</dd>
                                  <dd><input type="checkbox" checked={this.state.Permissions[module.id] ? this.state.Permissions[module.id]['create'] :false} onChange={() => this.handlePermissionChange(module.id, 'create', this.state.Permissions[module.id] ? !this.state.Permissions[module.id]['create'] : true)} name="" id=""/> Cadastrar</dd>
                                  <dd><input type="checkbox" checked={this.state.Permissions[module.id] ? this.state.Permissions[module.id]['update'] :false} onChange={() => this.handlePermissionChange(module.id, 'update', this.state.Permissions[module.id] ? !this.state.Permissions[module.id]['update'] : true)} name="" id=""/> Alterar</dd>
                                  <dd><input type="checkbox" checked={this.state.Permissions[module.id] ? this.state.Permissions[module.id]['delete'] :false} onChange={() => this.handlePermissionChange(module.id, 'delete', this.state.Permissions[module.id] ? !this.state.Permissions[module.id]['delete'] : true)} name="" id=""/> Excluir</dd>                              
                                </dl>
                              )
                            }
                          )}   
                        </div>                                       
                      </div>
                    </div>               
                    </> : null}

                    <div className="row">
                      <div className="linha-form col-4 responsive-field-form-employee-full">                      
                      <small>Campos com (*) são obrigatórios</small>
                      </div>
                    </div>

                    <div className="row">
                      <div className="linha-form col-4 responsive-field-form-employee-medium">
                      {!this.state.loading ? 
                        <button className="btn btn-primary" onClick={this.createEmployee}>  
                          Salvar
                        </button>
                        :
                        <button className="btn btn-primary">  
                          <LoadingSpin primaryColor="#fff" size={22}/>
                        </button>
                      }
                      </div>
                      <div className="col-4 responsive-field-form-employee-medium">
                        <button className="btn" onClick={()=>this.reset()}>Limpar</button>
                      </div>
                    </div>
                  </div>
                </Container>

              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AddEmployee;