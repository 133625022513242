import React from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer } from "react-toastify";
import UninstallGatForm from "./pages/FormsWeb/UninstallTrap/UninstallGatForm";
import UninstallAdiForm from "./pages/FormsWeb/UninstallTrap/UninstallAdiForm";
import UninstallOvitrampaForm from "./pages/FormsWeb/UninstallTrap/UninstallOvitrampaForm";
import MaintenanceAdiForm from "./pages/FormsWeb/Maintenance/MaintenanceAdiForm";
import { getUserData } from "../services/utils/auth";
import { fetchEmployees } from "../services/api/Employee";
//import { getUserEmployeeDataByOrganization } from "../services/api/Users";
import InstallForm from "./pages/FormsWeb/Install/InstallForm";
import "./FormularioVisitaWeb.css";

const FormularioVisitaWeb = () => {
  const [navBar, setNavBar] = React.useState(true);
  const [selectedActivity, setSelectedActivity] = React.useState("");
  const [selectedFormType, setSelectedFormType] = React.useState("");
  const [selectedTrapType, setSelectedTrapType] = React.useState("");
  const [employeesOptions, setEmployeesOptions] = React.useState(null);

  React.useEffect(() => {
    fetchEmployeesOptions();
  }, []);

  // const fetchEmployeesOptions = async () => {
  //   const { data: employeesData } = await getUserEmployeeDataByOrganization();
  //   const userOrganizationId = getUserData("organizationId");

  //   const filteredEmployeesData = employeesData.map(
  //     ({ employeeName, registration, employeeId, uvis }) => {
  //       return {
  //         employeeId,
  //         uvis,
  //         label:
  //           userOrganizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8"
  //             ? `${employeeName} - RF: ${registration} `
  //             : `${employeeName} - Matrícula: ${registration}`,
  //         value: employeeId,
  //       };
  //     }
  //   );

  //   setEmployeesOptions(filteredEmployeesData);
  // };

  const fetchEmployeesOptions = async () => {
    const { data: employeesData } = await fetchEmployees();
    const userOrganizationId = getUserData("organizationId");

    const filteredEmployeesData = employeesData.map(
      ({ name, registration, id }) => {
        return {
          id,
          label:
            userOrganizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8"
              ? `${name} - RF: ${registration}`
              : `${name} - Matrícula: ${registration}`,
          value: id,
        };
      }
    );

    setEmployeesOptions(filteredEmployeesData);
  };

  const handleActivityClick = (activityName) => {
    setSelectedActivity(activityName);
  };

  const handleFormTypeClick = (formType) => {
    setSelectedFormType(formType);
  };

  const handleTrapTypeClick = (trapTypeName) => {
    setSelectedTrapType(trapTypeName);
  };

  function changeMarginLeftByClass(className, newMarginLeft) {
    var elements = document.querySelectorAll("." + className);
    elements.forEach(function (element) {
      element.style.marginLeft = newMarginLeft;
    });
  }

  function hideElementById(elementId) {
    var element = document.getElementById(elementId);
    if (element) {
      element.style.display = "none";
    } else {
      console.error("Elemento com o ID " + elementId + " não encontrado.");
    }
  }

  function showElementById(elementId) {
    var element = document.getElementById(elementId);
    if (element) {
      element.style.display = "block";
    } else {
      console.error("Elemento com o ID " + elementId + " não encontrado.");
    }
  }

  const showNavBar = () => {
    if (navBar) {
      hideElementById("sidenav-main");
      changeMarginLeftByClass("main-content", "0");
      setNavBar(false);
    } else {
      showElementById("sidenav-main");
      changeMarginLeftByClass("main-content", "250px");
      setNavBar(true);
    }
  };

  const formTypes = {
    INSTALAR: () => (
      <InstallForm
        trapToInstall={selectedTrapType ? selectedTrapType : selectedActivity}
        employeesOptions={employeesOptions}
        organizationId={getUserData("organizationId")}
      />
    ),
    MANUTENÇÃO: () => {
      if (selectedActivity === "ADI")
        return (
          <MaintenanceAdiForm
            employeesOptions={employeesOptions}
            organizationId={getUserData("organizationId")}
          />
        );
    },
    DESINSTALAR: () => {
      if (selectedActivity === "ADI")
        return (
          <UninstallAdiForm
            employeesOptions={employeesOptions}
            organizationId={getUserData("organizationId")}
          />
        );
      if (selectedTrapType === "BG-GAT")
        return (
          <UninstallGatForm
            employeesOptions={employeesOptions}
            organizationId={getUserData("organizationId")}
          />
        );
      if (selectedTrapType === "OVITRAMPA")
        return (
          <UninstallOvitrampaForm
            employeesOptions={employeesOptions}
            organizationId={getUserData("organizationId")}
          />
        );
    },
  };

  const renderForm = () => {
    if (selectedActivity === "ADI" && selectedFormType)
      return formTypes[selectedFormType]();

    if (
      selectedActivity === "ENTOMOLÓGICO" &&
      selectedFormType &&
      selectedTrapType
    )
      return formTypes[selectedFormType]();
  };

  return (
    <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8 ">
      <ToastContainer />
      <Row>
        <Col>
          <button
            className="btn_bars"
            onClick={showNavBar}
            style={{ cursor: "pointer" }}
          >
            {navBar ? (
              <i
                className="fa fa-arrow-left"
                style={{ color: "white", fontSize: "35px" }}
              ></i>
            ) : (
              <i
                className="fa fa-arrow-right"
                style={{ color: "white", fontSize: "35px" }}
              ></i>
            )}
          </button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <span className="h5 text-white">Selecione a atividade:</span>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
            }}
          >
            <button
              onClick={() => handleActivityClick("ADI")}
              className="btn_card"
              style={{
                backgroundColor:
                  selectedActivity === "ADI" ? "#66999a" : "#577979",
              }}
            >
              <img
                src={require("../assets/img/icons/PrincipalIcons/armadilhaDisseminadoraInseticida.png")}
                width={100}
                alt=""
              />
              <p>ATIVIDADES COM ADI</p>
            </button>
            <button
              onClick={() => handleActivityClick("ENTOMOLÓGICO")}
              className="btn_card"
              style={{
                backgroundColor:
                  selectedActivity === "ENTOMOLÓGICO" ? "#66999a" : "#577979",
              }}
            >
              <img
                src={require("../assets/img/icons/PrincipalIcons/mosquito.png")}
                width={100}
                alt=""
              />
              <p>ATIVIDADES DE MONITORAMENTO ENTOMOLÓGICO</p>
            </button>
          </div>
        </Col>
      </Row>
      {selectedActivity && (
        <Row className="mt-4">
          <Col>
            <span className="h5 text-white">Selecione o formulário:</span>
            <div
              style={{
                display: "grid",
                gridTemplateColumns:
                  selectedActivity === "ENTOMOLÓGICO"
                    ? "repeat(2, 1fr)"
                    : "repeat(3, 1fr)",
                gap: "1rem",
              }}
            >
              <button
                onClick={() => handleFormTypeClick("INSTALAR")}
                className="btn_card"
                style={{
                  backgroundColor:
                    selectedFormType === "INSTALAR" ? "#66999a" : "#577979",
                }}
              >
                <i className="fa fa-map" style={{ fontSize: "80px" }}></i>
                <p>INSTALAR {selectedActivity}</p>
              </button>
              {selectedActivity !== "ENTOMOLÓGICO" && (
                <button
                  onClick={() => handleFormTypeClick("MANUTENÇÃO")}
                  className="btn_card"
                  style={{
                    backgroundColor:
                      selectedFormType === "MANUTENÇÃO" ? "#66999a" : "#577979",
                  }}
                >
                  <i className="fa fa-eye" style={{ fontSize: "80px" }}></i>
                  <p>MANUTENÇÃO {selectedActivity}</p>
                </button>
              )}
              {selectedActivity === "ENTOMOLÓGICO" &&
              getUserData("organizationId") ===
                "559a16ab-5da8-41ba-96b8-6e9f55feddd8" ? (
                <button
                  onClick={() => handleFormTypeClick("DESINSTALAR")}
                  className="btn_card"
                  style={{
                    backgroundColor:
                      selectedFormType === "DESINSTALAR"
                        ? "#66999a"
                        : "#577979",
                  }}
                >
                  <i className="fa fa-trash" style={{ fontSize: "80px" }}></i>
                  <p>DESINSTALAR {selectedActivity}</p>
                </button>
              ) : selectedActivity === "ENTOMOLÓGICO" &&
                getUserData("organizationId") !==
                  "559a16ab-5da8-41ba-96b8-6e9f55feddd8" ? (
                <button
                  onClick={() => handleFormTypeClick("DESINSTALAR")}
                  className="btn_card"
                  style={{
                    backgroundColor:
                      selectedFormType === "DESINSTALAR"
                        ? "#66999a"
                        : "#577979",
                  }}
                >
                  <i className="fa fa-trash" style={{ fontSize: "80px" }}></i>
                  <p>MANUTENÇÃO/DESINSTALAR {selectedActivity}</p>
                </button>
              ) : (
                <button
                  onClick={() => handleFormTypeClick("DESINSTALAR")}
                  className="btn_card"
                  style={{
                    backgroundColor:
                      selectedFormType === "DESINSTALAR"
                        ? "#66999a"
                        : "#577979",
                  }}
                >
                  <i className="fa fa-trash" style={{ fontSize: "80px" }}></i>
                  <p>DESINSTALAR {selectedActivity}</p>
                </button>
              )}
            </div>
          </Col>
        </Row>
      )}

      {selectedActivity === "ENTOMOLÓGICO" && selectedFormType && (
        <Row className="mt-4">
          <Col>
            <span className="h5 text-white">
              Selecione o tipo de armadilha:
            </span>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "1rem",
              }}
            >
              <button
                onClick={() => handleTrapTypeClick("OVITRAMPA")}
                className="btn_card"
                style={{
                  backgroundColor:
                    selectedTrapType === "OVITRAMPA" ? "#66999a" : "#577979",
                }}
              >
                <img
                  src={require("../assets/img/icons/PrincipalIcons/armadilhaOvos.png")}
                  width={150}
                  alt=""
                />
                <p>OVITRAMPA</p>
              </button>
              <button
                onClick={() => handleTrapTypeClick("BG-GAT")}
                className="btn_card"
                style={{
                  backgroundColor:
                    selectedTrapType === "BG-GAT" ? "#66999a" : "#577979",
                }}
              >
                <img
                  src={require("../assets/img/icons/PrincipalIcons/armadilhaMosquitoAdulto2.png")}
                  width={150}
                  alt=""
                />
                <p>BG-GAT</p>
              </button>
            </div>
          </Col>
        </Row>
      )}

      {renderForm()}
    </Container>
  );
};

export default FormularioVisitaWeb;
