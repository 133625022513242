import React from "react";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
  } from "reactstrap";
import {
    Map,
    TileLayer,
    FeatureGroup,
    LayersControl,
    withLeaflet,
    Polygon,
    Marker
  } from'react-leaflet';
  import FullscreenControl from 'react-leaflet-fullscreen';
  import PrintControlDefault  from 'react-leaflet-easyprint';
  import 'react-leaflet-fullscreen/dist/styles.css'
  import L from 'leaflet';
  import { EditControl } from "react-leaflet-draw";
import { getTypeLayer, putTerritorialization } from "../../../services/api/territorialization";
import { toast } from "react-toastify";
import FiltroNovaTerritorializacao from "../../../components/Navbars/FiltroNovaTerritorializacao";
import { getRealty } from "../../../services/api/Realty";



  const { BaseLayer } = LayersControl;
  const PrintControl = withLeaflet(PrintControlDefault);

  const key = 'AIzaSyCdkHjfiiztTeKSSuvfauQERlAJGFONeKo';


class TerritorializationUpdateModalComponent extends React.Component {

    state ={
        Center:null,
        baseTerritorialization:null,
        BaseCoordinates:[],
        camada: "00000000-0000-0000-0000-000000000000",
        tipoImovel: "",
        id:"",
        cep: "",
        numero: "",
        economia: "",
        rua: "",
        nome: "",
        GeoJson: "",
        ActualLat: "",
        ActualLong: "",
        unsavedTerritorialization:false,
        CityId: "00000000-0000-0000-0000-000000000000",
        CountryId: "00000000-0000-0000-0000-000000000000",
        NeighbourhoodId: "00000000-0000-0000-0000-000000000000",
        StateId:"00000000-0000-0000-0000-000000000000",
        SelectedTerritorialization:'',
        TypeGeometry:"",

    }

    _onFeatureGroupReady = (ref) => {
        
        if(ref===null) {
            return;
        }
        this._editableFG = ref; 
        let leafletFG = ref.leafletElement;

        //removendo os layers para depois adicionar e não ficar duplicado no mapa
        leafletFG.eachLayer((layer) => {
            leafletFG.removeLayer(layer)
        })
        
        // populate the leaflet FeatureGroup with the geoJson layers
        if(this.state.SelectedTerritorialization!=''){
            var type = this.state.TypeGeometry;
            var geoJson = this.layerToGeoJson(this.state.SelectedTerritorialization, type);
            let leafletGeoJSON = new L.GeoJSON(geoJson);
    
            leafletGeoJSON.eachLayer( layer =>leafletFG.addLayer(layer)); 
        }     
    }
    _onEdited = (e) => {
        var type = "";
        if(localStorage.getItem('drawtype').toLowerCase() =="polygon"){
            type = "polygon";
        }else{
            type = "marker";
        }
        e.layers.eachLayer( (layer) => {
            let geojson = this.layerToGeoJson(layer, type);
            localStorage.removeItem('geoUpdateJson');
            localStorage.setItem('geoUpdateJson', JSON.stringify(geojson));
            layer.editing.disable();
        });
      }

    _onCreated = (e) => {
        var type = e.layerType,
            layer = e.layer;
        this.setState({FeatureId:layer._leaflet_id})
        let geojson = this.layerToGeoJson(layer, type);

        localStorage.setItem('geoUpdateJson', JSON.stringify(geojson));
    }
    _onDeleted = (e) => {
        localStorage.removeItem('geoUpdateJson');
    }
    
    onChangeLocationId(e){
        let {name,value} = e;
        this.setState({[name]:value});
    }
    onChangeCamada(camadaNova, drawType) {
        localStorage.setItem('drawtype', drawType);
        this.setState({camada : camadaNova});
    }

    onChangeCep(cepNovo) {
        this.setState({cep : cepNovo});
    }

    onChangeNumero(numeroNovo) {
        this.setState({numero : numeroNovo});
    }
    onChangeEconomia(economiaNovo) {
        this.setState({economia : economiaNovo});
    }

    onChangeRua(ruaNova) {
        this.setState({rua : ruaNova});
    }

    onChangeNome(nomeNova) {
        this.setState({nome : nomeNova});
    }
    onChangeComplemento(complementoNova) {
        this.setState({complemento : complementoNova});
    }
    onChangeSequencial(sequencialNova) {
        this.setState({sequencial : sequencialNova});
    }
    SaveData = () => {
        //tratando o geoJson armazenado para que possa ser enviado
        //junto com o state
        
    }
    setBaseTerritorialization(territorialization){
        this.setState({baseTerritorialization:territorialization});
    }
    renderMap = () => {
        if(this.state.baseTerritorialization){
            
            let baseCoordinates = [];
            for(const territorialization of this.state.baseTerritorialization){
                let coordinates = [];

                for(const coordinate of territorialization.coordinates){
                    coordinates.push([coordinate.y, coordinate.x])
                }

                baseCoordinates.push(coordinates);
                
            }
            this.setState({BaseCoordinates:baseCoordinates});
        }
    }
    layerToGeoJson(layer, type) {
        // estruturando o geojson
        var geojson = {};
        geojson['type'] = 'FeatureCollection';
        var geometry = {};
        if (type.toLowerCase() === 'polygon' || type.toLowerCase() === 'rectangle') {
            geometry['type'] = "Polygon";

            // Pega as coordenadas do Layer desenhado
            var coordinates = [];
            var latlngs = layer._latlngs !== undefined ? layer._latlngs[0] : layer.coordinates
            for (var i = 0; i < latlngs.length; i++) {
                let latlng = latlngs[i].lat !== undefined ? {x: latlngs[i].lng, y: latlngs[i].lat} : {x:latlngs[i].x, y: latlngs[i].y}
                coordinates.push([latlng.x, latlng.y]);
                
            }
                // O Geojson obrigatoriamente precisa terminar com o mesmo ponto de partida
            var first_coord = coordinates[0];
            coordinates[coordinates.length] = first_coord;
            geometry['coordinates'] = [coordinates];
        }else if(type.toLowerCase() === 'point' || type.toLowerCase() === 'marker'){
            geometry['type'] = "Point";
            // Pega as coordenadas do Layer desenhado
            var coordinates = [];
            if(type.toLowerCase() === 'point'){
                coordinates.push(layer.coordinate.x, layer.coordinate.y);
            }else{
                let latlng = layer._latlng;
                coordinates.push(latlng.lng, latlng.lat);
            }

            geometry['coordinates'] = coordinates;
        }

        //montando a feature
        geojson['features'] = [];

        var Feature = {
            "type": "Feature",
            "geometry": geometry,
            "properties":{}
        };

        geojson['features'][0] = Feature;

        return geojson;
    }
    SaveUpdatedTerritorialization=()=>{

        var geoStorage = localStorage.getItem('geoUpdateJson');
        this.setState({GeoJson : geoStorage}, () => {
            localStorage.removeItem('geoUpdateJson');
            var putSave = putTerritorialization(this.state);
            putSave.then(response => {          
                if(response.data.id) {                    
                    toast.success("Territorialização atualizada com sucesso!");
                    this.props.toggleCallback();
                    this.props.redoTerritorializationRequest(this.props.TerritorializationType);
                }
            });
        });      

        
    }
    toggleModal(){
        this.setTerritorializationData();
        var layerControlElement = document.getElementsByClassName('leaflet-control-layers')[0];
            layerControlElement.getElementsByTagName('input')[0].click();
        this.props.toggleCallback();
    }
    setTerritorializationData(){
        if(this.state.SelectedTerritorialization != '' && this.props.TerritorializationType != ''){
            localStorage.setItem('drawtype', this.state.SelectedTerritorialization.geometryType);
            this.setState({
                nome:this.state.SelectedTerritorialization.name,
                id:this.state.SelectedTerritorialization.id,
                camada:this.props.TerritorializationType});
        }
    }
    async componentDidUpdate(prevProps, prevState){
        if(prevProps.SelectedTerritorialization != this.props.SelectedTerritorialization){
            var selectedTerritorialization = this.props.SelectedTerritorialization;
            let coords = selectedTerritorialization.coordinate;
            const TypeLayer = await getTypeLayer(this.props.SelectedTerritorialization.typeLayerId);
            let Center = coords != undefined ? [coords.y, coords.x] : this.state.Center;
            const rua = selectedTerritorialization.subpremisse;
            const economia = selectedTerritorialization.economy;
            const cep = selectedTerritorialization.postalCode;
            const numero = selectedTerritorialization.number;

            localStorage.setItem('drawtype', TypeLayer.data.typeGeometry);               
           
            this.setState({
                SelectedTerritorialization: selectedTerritorialization, 
                TypeGeometry: TypeLayer.data.typeGeometry, 
                Center,
                rua,
                economia,
                cep,
                numero,
            });
            this.setTerritorializationData();
        }

        if(prevState.baseTerritorialization !== this.state.baseTerritorialization){
            this.renderMap();
        }       
    }

    render() {
      return (
        <>
         
         <Modal size="lg" isOpen={this.props.openModal} fade="true" onOpened={()=>this.setTerritorializationData()} toggle={()=>this.toggleModal()}>
                <ModalHeader toggle={()=>this.toggleModal()}>Territorialização</ModalHeader>
                <ModalBody>    
                          
                    <FiltroNovaTerritorializacao
                        key={this.state.SelectedTerritorialization.id}
                        SetBaseTerritorialization={this.setBaseTerritorialization.bind(this)}
                        Save={this.SaveData}
                        changeNome={this.onChangeNome.bind(this)}
                        changeRua={this.onChangeRua.bind(this)} 
                        changeNumero={this.onChangeNumero.bind(this)}
                        changeCep={this.onChangeCep.bind(this)}
                        changeCamada={this.onChangeCamada.bind(this)}
                        changeIds={this.onChangeLocationId.bind(this)}
                        changeEconomia={this.onChangeEconomia.bind(this)}
                        changeComplemento={this.onChangeComplemento.bind(this)}
                        changeSequencial={this.onChangeSequencial.bind(this)}
                        isEdit={true}
                        TerritorializationType={this.props.TerritorializationType}
                        SelectedTerritorialization={this.state.SelectedTerritorialization}
                    />




                    <div id="mapid">
                        {this.state.Center !=null ?
                            <Map center={this.state.Center} zoom={13}>
                                <FullscreenControl 
                                    position="topleft"
                                    title="Tela Cheia"
                                    titleCancel="Sair da Tela Cheia"
                                />
                                <LayersControl position="topright">
                                    <BaseLayer checked name="Normal">
                                        <TileLayer
                                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                    </BaseLayer>
                                    <BaseLayer name="Preto e Branco">
                                        <TileLayer
                                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                                        />
                                    </BaseLayer>
                                    
                                    <BaseLayer name="Satélite">
                                        <ReactLeafletGoogleLayer googleMapsLoaderConf={{KEY: key}} type={'hybrid'} />
                                    </BaseLayer>
                                </LayersControl>
                                <PrintControl position="topleft" sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={false} title="Export as PNG" exportOnly />
                                <FeatureGroup ref={ (reactFGref) => {this._onFeatureGroupReady(reactFGref)}}>
                                    <EditControl
                                        position='topright'
                                        onEdited={this._onEdited}
                                        onCreated={this._onCreated}
                                        onDeleted={this._onDeleted}
                                        draw={{
                                            rectangle: /*localStorage.getItem('drawtype')=="Polygon"?true:*/false,
                                            circle:false,
                                            circlemarker:false,
                                            polyline:false,
                                            marker:/*localStorage.getItem('drawtype')=="Point"?true:*/false,
                                            polygon: /*localStorage.getItem('drawtype')=="Polygon"?true:*/false
                                        }}
                                    />
                                </FeatureGroup>

                                {this.state.BaseCoordinates.map((baseCoordinates)=>{
                                    if(baseCoordinates.length == 1){
                                        return (
                                            <Marker position={baseCoordinates[0]}/>
                                        )
                                    }
                                    return(
                                        <Polygon color="black" positions={baseCoordinates} />
                                    )
                                })}
                            </Map>
                        :null}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="row" >                           
                        <div className="col-6">
                            <button onClick={()=>this.toggleModal()} className="form-control">CANCELAR</button>
                        </div>
                        <div className="col-6">
                            <button onClick={()=>this.SaveUpdatedTerritorialization()} className="btn btn-primary">Salvar</button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </>
      );
    };
  }
  
  export default TerritorializationUpdateModalComponent;