import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import routes from "routes.js";

class Admin extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      route: routes.find((rt) => rt.layout + rt.path === props.location.pathname)
    };
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = path => {
    const route = this.state.route;
    if(route){
      return route.name;
    }
    return "Brand";
  };
  
  render() {
    var arbotech = false;
    if(window.location.href.includes("arbotech")){
      arbotech = true;
    } else {
      arbotech = false;
    }
    
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: arbotech ? require("assets/img/brand/arbotech.png") : require("assets/img/brand/vitec.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
            redirectRoute={this.state.route ? `/admin${this.state.route.path}`: "/"}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
        
      </>
    );
  }
}

export default Admin;
