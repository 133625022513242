import React from "react";
import { Link } from "react-router-dom";
import {deleteEmployee} from "../../../services/api/Employee";
function EmployeesList({Employee}) {

    
    return (
        
        <tr>
            
            <th scope="row">{Employee.registration}</th>
            <td>            
            {Employee.name}
            </td>
            <td>            
            {Employee.place}
            </td>
            <td>
            -
            </td>
            <td>
            <button
                className="btn-icon-clipboard btn-acao-amostra"
                data-clipboard-text="check-bold"
                id="tooltip204269497"
                type="button"
            >
                <div>
                <i className="ni ni-check-bold" />
                </div>
            </button>

            <button
                className="btn-icon-clipboard btn-acao-amostra"
                data-clipboard-text=" ni ni-fat-remove"
                id="tooltip204269497"
                type="button"
            >
                <div>
                <i className=" ni ni-fat-remove" />
                </div>
            </button>
            <Link to="/admin/EditEmployee">
                <button
                    className="btn-icon-clipboard btn-acao-amostra"
                    data-clipboard-text="ni ni-fat-edit"
                    onClick={() => localStorage.setItem("EmployeeItem",Employee.personId)}
                    id="tooltip204269497"
                    type="button"
                >
                
                    <div>
                    <i className="ni ni-ruler-pencil" />
                    </div>
                </button>
            </Link>
            </td>
        </tr>
    )
}

export default EmployeesList;