import Index from "views/Index.jsx";
import ChangePassword from "./views/pages/Signup/ChangePassword.jsx";
import AmostrasColetadas from "views/AmostrasColetadas.jsx";
import Equipe from "views/Equipe.jsx";
import Armadilhas from "./views/Armadilhas.jsx";
import EditTeam from "views/pages/Team/EditTeam.jsx";
import Territorializacao from "./views/pages/Territorialization/Territorializacao";
import AddEmployee from "./views/pages/Employee/AddEmployee";
import EditEmployee from "./views/pages/Employee/EditEmployee";
import AddDemand from "./views/pages/Demand/AddDemand";
import EditDemand from "./views/pages/Demand/EditDemand";
import AddTeam from "./views/pages/Team/AddTeam";
import NovaTerritorializacao from "./views/NovaTerritorializacao";
import Logout from "./views/pages/Logout/index";
import SignUp from "./views/pages/Signup";
import Employee from "./views/pages/Employee/Employee";
import ImportSinan from "./views/pages/Sinan/ImportSinan";
import ImportInmet from "./views/pages/Inmet/ImportInmet";
import AddSample from "./views/pages/Sample/AddSample";
import AddSampleResult from "./views/pages/Sample/AddSampleResult";
import CovidReport from "./views/pages/Reports/CovidReport";
import AddSampleCovid from "./views/pages/Sample/AddSampleCovid";
import TrapReport from "./views/pages/Reports/Traps/TrapReport";
import LoginArbotech from "./views/pages/Login/Login_Arbotech.jsx";
import DashboardSinan from "./views/DashboardSinan.jsx";
import DashboardTraps from "./views/DashboardTraps.jsx";
import DashboardInmet from "./views/DashboardInmet.jsx";
import InventoryItens from "./views/pages/Inventory/InventoryItens/InventoryItens.jsx";
import AddInventoryItem from "./views/pages/Inventory/InventoryItens/AddInventoryItem.jsx";
import InventoryMovementRecord from "./views/pages/Inventory/InventoryMovement/InventoryMovementRecord.jsx";
import AddInventoryMovementRecord from "./views/pages/Inventory/InventoryMovement/AddInventoryMovementRecord.jsx";
import Dashboard from "./views/Dashboard.jsx";
import importTerritorialization from "./components/Territorializacao/importTerritorialization.js";
import MainDashboard from "./views/pages/Dashboards/MainDashboard.jsx";
import MainDashboardIntegrado from "./views/pages/Dashboards/MainDashboardIntegrado.jsx";
import OfflineContingency from "./views/pages/OfflineContingency/OfflineContingency.jsx";
import DashboardSISPNCD from "./views/pages/Dashboards/DashboardSISPNCD.jsx";
import VisitExport from "./views/pages/VisitsExport/VisitsExport.jsx";
import Visits from "./views/pages/Visits/Visits.jsx";
import { getUserData } from "./services/utils/auth.js";
import FormularioVisitaWeb from "./views/FormularioVisitaWeb.jsx";
import { getVersion } from "./constants/VersionConstant.js";
import MainTrapsDashboard from "./views/pages/Dashboards/MainTrapsDashboard.jsx";
import DashboardTrapsOnMap from "./views/pages/Dashboards/DashboardTrapsOnMap.jsx";
import Export from "./views/pages/Export/Export.jsx";
import EditVisit from "./views/pages/Visits/EditVisit.jsx";
import Pgs from "./views/pages/Pgs/Pgs.jsx";
import ContactUs from "./views/pages/ContactUs/ContactUs.jsx";
import GlobalVisionPageWrapper from "./views/pages/GlobalVision/GlobalVisionPageWrapper.jsx";
import QrCodeGenerator from "./views/pages/QrCodeGenerator/QrCodeGenerator.jsx";
import ProductivityPageWrapper from "./views/pages/Productivity/ProductivityPageWrapper.jsx";
import PlanningPage from "./views/pages/Planning/PlanningPage.jsx";
import ManagementPage from "./views/pages/Management/ManagementPage.jsx";
import VisitsPage from "./views/pages/Visits/VisitsPage.jsx";
import LoginReportPage from "./views/pages/UsersReport/LoginReportPage.jsx";

//AO ADICIONAR AS ROTAS COLOCAR AS QUE NÃO POSSUEM MÓDULO EM CIMA;
//AO CRIAR NOVOS MÓDULOS INSERIR PRIMEIRO OS DE VISUALIZAÇÃO;

var routes;

const userOrganizationId = getUserData("organizationId");
// const webVersion = getVersion(userOrganizationId);

const webVersion = "standard";

const getRoutesByVersionType = {
  standard: () => [
    {
      path: "/logout",
      name: "Logout",
      icon: "ni ni-key-25 text-info",
      component: Logout,
      layout: "/admin",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000",
    },
    {
      path: "/signup",
      name: "Signup",
      icon: "ni ni-key-25 text-info",
      component: SignUp,
      layout: "/admin",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000",
    },
    {
      path: "/qrCode",
      name: "Geração de QrCode",
      icon: "ni ni-chart-pie-35 text-info",
      component: QrCodeGenerator,
      layout: "/admin",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000",
    },
    {
      path: "/changePassword",
      name: "Mudar senha",
      component: ChangePassword,
      layout: "/auth",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000",
    },
    // {
    //   path: "/trapsOnMap",
    //   name: "Armadilhas no mapa",
    //   icon: "ni ni-chart-pie-35 text-info",
    //   component: DashboardTrapsOnMap,
    //   layout: "/admin",
    //   show: getUserData("organizationId") === "559a16ab-5da8-41ba-96b8-6e9f55feddd8" ? true : false,
    //   moduleId: "a8318169-2dbe-4f91-b247-cb3fc05207c8"
    // },
    {
      path: "/dashboardTraps",
      name: "Armadilhas",
      icon: "ni ni-chart-pie-35 text-info",
      component: MainTrapsDashboard,
      layout: "/admin",
      show: true,
      moduleId: "bb5b6faa-ba61-4e67-87d9-bb30dfe5c8ab",
    },
    {
      path: "/globalVision",
      name: "Visão Global",
      icon: "ni ni-chart-pie-35 text-info",
      component: GlobalVisionPageWrapper,
      layout: "/admin",
      show: true,
      moduleId: "56f7c7a2-4dbd-4dfb-8d93-59b2b38d2256",
    },
    // {
    //   path: "/dashboardIntegrado ",
    //   name: "Relatório Integrado",
    //   icon: "ni ni-chart-pie-35 text-info",
    //   component: MainDashboardIntegrado,
    //   layout: "/admin",
    //   show: false,
    //   moduleId: "2a309995-15bb-4a85-af67-f50eb6642d89"
    // },
    {
      path: "/dashboardSinan",
      name: "Relatório SINAN",
      icon: "ni ni-chart-pie-35 text-info",
      component: DashboardSinan,
      layout: "/admin",
      show: true,
      moduleId: "2a309995-15bb-4a85-af67-f50eb6642d89",
    },
    {
      path: "/productivity",
      name: "Produtividade",
      icon: "ni ni-chart-pie-35 text-info",
      component: ProductivityPageWrapper,
      layout: "/admin",
      show: true,
      moduleId: "36d1a388-2bca-4557-8ccf-487917e03f5f",
    },
    {
      path: "/managementReport",
      name: "Gerencial",
      icon: "ni ni-chart-pie-35 text-info",
      component: ManagementPage,
      layout: "/admin",
      show: true,
      moduleId: "33852b83-be1b-4671-8cdc-782e4d1a55bf",
    },
    {
      path: "/loginsReport",
      name: "Logins",
      icon: "ni ni-chart-pie-35 text-info",
      component: LoginReportPage,
      layout: "/admin",
      show: true,
      moduleId: "3f09e102-f7e4-496a-accc-1b11cdf1801d",
    },
    {
      path: "/goalPlanning",
      name: "Planejamento de Metas",
      icon: "ni ni-ruler-pencil text-info",
      component: PlanningPage,
      layout: "/admin",
      show: true,
      moduleId: "ddb29431-a51f-4d52-99da-bcc865b16c73",
    },
    {
      path: "/dashboard",
      name: "Entomologia",
      icon: "ni ni-chart-pie-35 text-info",
      component: MainDashboard,
      layout: "/admin",
      show: true,
      moduleId: "a8318169-2dbe-4f91-b247-cb3fc05207c8",
    },
    {
      path: "/trapsList",
      name: "Tabela de Armadilhas",
      icon: "ni ni-pin-3 text-info",
      component: DashboardTraps,
      layout: "/admin",
      show: false,
      moduleId: "2a309995-15bb-4a85-af67-f50eb6642d89",
    },
    {
      path: "/webForm",
      name: "Digitar Visitas",
      icon: "ni ni-ruler-pencil text-info",
      component: FormularioVisitaWeb,
      layout: "/admin",
      moduleId: "c1e67c2e-f454-47f1-b98d-7b3ed1abca69",
      show: true,
    },
    {
      path: "/sispncd",
      name: "Relatório Operacional",
      icon: "ni ni-chart-pie-35 text-info",
      component: DashboardSISPNCD,
      layout: "/admin",
      show: false,
      moduleId: "7905288f-ce39-4628-a3e4-5e931f642b32"
    },
    {
      path: "/index",
      name: "Demandas",
      icon: "ni ni-tv-2 text-primary",
      component: Index,
      layout: "/admin",
      show: true,
      moduleId: "da149579-6cd3-425c-bdfa-04e968d44a69",
    },
    {
      path: "/AddDemand",
      icon: "ni ni-badge text-info",
      component: AddDemand,
      layout: "/admin",
      show: false,
      moduleId: "da149579-6cd3-425c-bdfa-04e968d44a69",
    },
    {
      path: "/EditDemand",
      icon: "ni ni-badge text-info",
      component: EditDemand,
      layout: "/admin",
      show: false,
      moduleId: "da149579-6cd3-425c-bdfa-04e968d44a69",
    },
    {
      path: "/amostrasColetadas",
      name: "Amostras Coletadas",
      icon: "ni ni-atom text-blue",
      component: AmostrasColetadas,
      layout: "/admin",
      show: true,
      moduleId: "7d306cb1-0df5-4fdc-a658-8f7492292172",
    },
    {
      path: "/acesso",
      name: "Login Arbotech",
      component: LoginArbotech,
      layout: "/auth",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000",
    },
    {
      path: "/pgs",
      name: "Paramêtros Gerais do Sistema",
      icon: "ni ni-tv-2 text-primary",
      component: Pgs,
      layout: "/admin",
      show: false,
    },
    {
      path: "/AddSample",
      icon: "ni ni-badge text-info",
      component: AddSample,
      layout: "/admin",
      show: false,
      moduleId: "7d306cb1-0df5-4fdc-a658-8f7492292172",
    },
    {
      path: "/AddSampleCovid",
      icon: "ni ni-badge text-info",
      component: AddSampleCovid,
      layout: "/admin",
      show: false,
      moduleId: "7d306cb1-0df5-4fdc-a658-8f7492292172",
    },
    {
      path: "/AddSampleResult",
      icon: "ni ni-bullet-list-67 text-red",
      component: AddSampleResult,
      layout: "/admin",
      show: false,
      moduleId: "7d306cb1-0df5-4fdc-a658-8f7492292172",
    },

    {
      path: "/EditTeam",
      icon: "ni ni-single-02 text-yellow",
      component: EditTeam,
      layout: "/admin",
      show: false,
      moduleId: "217c876b-5f50-4bbf-bce4-325b83ea7787",
    },
    {
      path: "/AddTeam",
      icon: "ni ni-badge text-info",
      component: AddTeam,
      layout: "/admin",
      show: false,
      moduleId: "217c876b-5f50-4bbf-bce4-325b83ea7787",
    },
    {
      path: "/AddEmployee",
      icon: "ni ni-badge text-info",
      component: AddEmployee,
      layout: "/admin",
      show: false,
      moduleId: "ba56119d-ac43-4ec1-858e-31f2e12235e8",
    },
    {
      path: "/employees",
      name: "Funcionários",
      icon: "ni ni-circle-08 text-info",
      component: Employee,
      layout: "/admin",
      show: true,
      moduleId: "ba56119d-ac43-4ec1-858e-31f2e12235e8",
    },
    {
      path: "/equipes",
      name: "Equipes",
      icon: "ni ni-single-02 text-yellow",
      component: Equipe,
      layout: "/admin",
      show: true,
      moduleId: "217c876b-5f50-4bbf-bce4-325b83ea7787",
    },
    {
      path: "/territorializacao",
      name: "Mapas",
      icon: "ni ni-pin-3 text-info",
      component: Territorializacao,
      layout: "/admin",
      show: true,
      moduleId: "dbd03c99-ac3c-4dd4-8964-cc066d77d403",
    },
    {
      path: "/EditEmployee",
      icon: "ni ni-badge text-info",
      component: EditEmployee,
      layout: "/admin",
      show: false,
      moduleId: "ba56119d-ac43-4ec1-858e-31f2e12235e8",
    },
    {
      path: "/InventoryItens",
      icon: "ni ni-shop text-info",
      name: "Controle de estoque",
      component: InventoryItens,
      layout: "/admin",
      show: true,
      moduleId: "2af11e8b-f884-40c8-b3a2-360d1f1bb95d",
    },
    {
      path: "/AddInventoryItem",
      icon: "ni ni-badge text-info",
      component: AddInventoryItem,
      layout: "/admin",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000"
    },    
    {
      path: "/InventoryMovementRecord",
      icon: "ni ni-delivery-fast text-info",
      name: "Movimento de estoque",
      component: InventoryMovementRecord,
      layout: "/admin",
      show: true,
      moduleId: "2af11e8b-f884-40c8-b3a2-360d1f1bb95d"
    },
    {
      path: "/AddInventoryMovementRecord",
      icon: "ni ni-badge text-info",
      component: AddInventoryMovementRecord,
      layout: "/admin",
      show: false,
      moduleId: "2af11e8b-f884-40c8-b3a2-360d1f1bb95d",
    },
    {
      path: "/exportData",
      icon: "ni ni-curved-next text-info",
      name: "Exportar Dados",
      component: Export,
      layout: "/admin",
      show: true,
      moduleId: "598ada97-d673-483a-bb33-ff52564ba0f1"
    },
    {
      path: "/VisitExport",
      icon: "ni ni-badge text-info",
      component: VisitExport,
      name: "Exportação Visitas",
      layout: "/admin",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000"
    },
    {
      path: "/Visits",
      icon: "ni ni-mobile-button text-info",
      component: Visits,
      name: "Visitas",
      layout: "/admin",
      show: true,
      moduleId: "2d31ebee-53e3-4297-b498-4730e9b9e656",
    },
    {
      path: "/VisitsPage",
      icon: "ni ni-mobile-button text-info",
      component: VisitsPage,
      name: "Visitas",
      layout: "/admin",
      show: true,
      moduleId: "3d8555ef-ddab-4c9c-9799-eedacaa2dc66",
    },
    {
      path: "/EditVisit",
      icon: "ni ni-badge text-info",
      component: EditVisit,
      name: "Edição Visitas",
      layout: "/admin",
      show: false,
      moduleId: "2d31ebee-53e3-4297-b498-4730e9b9e656",
    },
    {
      path: "/NovaTerritorializacao",
      name: "Nova territorialização",
      icon: "ni ni-pin-3 text-info",
      component: NovaTerritorializacao,
      layout: "/admin",
      show: false,
      moduleId: "dbd03c99-ac3c-4dd4-8964-cc066d77d403",
    },
    {
      path: "/importSinan",
      name: "Importação SINAN",
      icon: "ni ni-archive-2 text-blue",
      component: ImportSinan,
      layout: "/admin",
      show: true,
      moduleId: "fde5db7d-0cf3-4ddd-8737-708f259d4e1c",
    },
    {
      path: "/importInmet",
      name: "Importação INMET",
      icon: "ni ni-archive-2 text-blue",
      component: ImportInmet,
      layout: "/admin",
      show: false,
      moduleId: "fde5db7d-0cf3-4ddd-8737-708f259d4e1c",
    },
    {
      path: "/dashboardGerencial",
      name: "Relatório Gerencial",
      icon: "ni ni-sound-wave text-info",
      component: Dashboard,
      layout: "/admin",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000",
    },
    {
      path: "/importTerritorialization",
      name: "Importar Territorialização",
      icon: "ni ni-sound-wave text-info",
      component: importTerritorialization,
      layout: "/admin",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000",
    },
    {
      path: "/CovidReport",
      name: "Relatórios Visitas",
      icon: "ni ni-chart-bar-32 text-blue",
      component: CovidReport,
      layout: "/admin",
      show: false,
      moduleId: "2a309995-15bb-4a85-af67-f50eb6642d89",
    },
    {
      path: "/TrapReport",
      name: "Relatório Mapas",
      icon: "ni ni-square-pin text-red",
      component: TrapReport,
      layout: "/admin",
      show: false,
      moduleId: "a8318169-2dbe-4f91-b247-cb3fc05207c8",
    },
    {
      path: "/OfflineContingency",
      name: "Contingência Offline",
      icon: "ni ni-square-pin text-red",
      component: OfflineContingency,
      layout: "/admin",
      show: true,
      moduleId: "50e2f05b-b6d3-46a6-81b0-34875882afab",
    },
    {
      path: "/contactUs",
      name: "Fale conosco",
      icon: "ni ni-chat-round text-info",
      component: ContactUs,
      layout: "/admin",
      show: true,
      moduleId: "37cdb183-51b6-487a-a13e-630049617798",
    },
  ],
};

if (webVersion === "standard") routes = getRoutesByVersionType[webVersion]();
else
  routes = [
    {
      path: "/logout",
      name: "Logout",
      icon: "ni ni-key-25 text-info",
      component: Logout,
      layout: "/admin",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000",
    },
    {
      path: "/signup",
      name: "Signup",
      icon: "ni ni-key-25 text-info",
      component: SignUp,
      layout: "/admin",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000",
    },
    {
      path: "/changePassword",
      name: "Mudar senha",
      component: ChangePassword,
      layout: "/auth",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000",
    },
    {
      path: "/dashboardSinan",
      name: "Relatório Sinan",
      icon: "ni ni-chart-pie-35 text-info",
      component: DashboardSinan,
      layout: "/admin",
      show:
        userOrganizationId == "559a16ab-5da8-41ba-96b8-6e9f55feddd8"
          ? false
          : true,
      moduleId: "2a309995-15bb-4a85-af67-f50eb6642d89",
    },
    {
      path: "/qrCode",
      name: "Geração de QrCode",
      icon: "ni ni-chart-pie-35 text-info",
      component: QrCodeGenerator,
      layout: "/admin",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000",
    },
    {
      path: "/dashboardIntegrado ",
      name: "Relatório Integrado",
      icon: "ni ni-chart-pie-35 text-info",
      component: MainDashboardIntegrado,
      layout: "/admin",
      show:
        userOrganizationId == "559a16ab-5da8-41ba-96b8-6e9f55feddd8" ||
        "24d125ef-15e6-4bd9-9111-c0b3d50a8009"
          ? false
          : true,
      moduleId: "2a309995-15bb-4a85-af67-f50eb6642d89",
    },
    {
      path: "/dashboard",
      name: userOrganizationId == '559a16ab-5da8-41ba-96b8-6e9f55feddd8' || "24d125ef-15e6-4bd9-9111-c0b3d50a8009" ? "Armadilhas no Mapa" : "Entomologia" ,
      icon: "ni ni-chart-pie-35 text-info",
      component: MainDashboard,
      layout: "/admin",
      show: true,
      moduleId: "a8318169-2dbe-4f91-b247-cb3fc05207c8",
    },
    {
      path: "/sispncd",
      name: "Relatório Operacional",
      icon: "ni ni-chart-pie-35 text-info",
      component: DashboardSISPNCD,
      layout: "/admin",
      show: false,
      moduleId: "2a309995-15bb-4a85-af67-f50eb6642d89",
    },
    {
      path: "/index",
      name: "Demandas",
      icon: "ni ni-tv-2 text-primary",
      component: Index,
      layout: "/admin",
      show:
        userOrganizationId === "24d125ef-15e6-4bd9-9111-c0b3d50a8009"
          ? false
          : true,
      moduleId: "da149579-6cd3-425c-bdfa-04e968d44a69",
    },
    {
      path: "/dashboardTraps",
      name: "Tabela de Armadilhas",
      icon: "ni ni-pin-3 text-info",
      component: MainTrapsDashboard,
      layout: "/admin",
      show: true,
      moduleId: "2a309995-15bb-4a85-af67-f50eb6642d89",
    },
    {
      path: "/AddDemand",
      icon: "ni ni-badge text-info",
      component: AddDemand,
      layout: "/admin",
      show: false,
      moduleId: "da149579-6cd3-425c-bdfa-04e968d44a69",
    },
    {
      path: "/EditDemand",
      icon: "ni ni-badge text-info",
      component: EditDemand,
      layout: "/admin",
      show: false,
      moduleId: "da149579-6cd3-425c-bdfa-04e968d44a69",
    },
    {
      path: "/amostrasColetadas",
      name: "Amostras Coletadas",
      icon: "ni ni-atom text-blue",
      component: AmostrasColetadas,
      layout: "/admin",
      show: true,
      moduleId: "7d306cb1-0df5-4fdc-a658-8f7492292172",
    },
    {
      path: "/acesso",
      name: "Login Arbotech",
      component: LoginArbotech,
      layout: "/auth",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000",
    },
    {
      path: "/AddSample",
      icon: "ni ni-badge text-info",
      component: AddSample,
      layout: "/admin",
      show: false,
      moduleId: "7d306cb1-0df5-4fdc-a658-8f7492292172",
    },
    {
      path: "/AddSampleCovid",
      icon: "ni ni-badge text-info",
      component: AddSampleCovid,
      layout: "/admin",
      show: false,
      moduleId: "7d306cb1-0df5-4fdc-a658-8f7492292172",
    },
    {
      path: "/AddSampleResult",
      icon: "ni ni-bullet-list-67 text-red",
      component: AddSampleResult,
      layout: "/admin",
      show: false,
      moduleId: "7d306cb1-0df5-4fdc-a658-8f7492292172",
    },

    {
      path: "/EditTeam",
      icon: "ni ni-single-02 text-yellow",
      component: EditTeam,
      layout: "/admin",
      show: false,
      moduleId: "217c876b-5f50-4bbf-bce4-325b83ea7787",
    },
    {
      path: "/AddTeam",
      icon: "ni ni-badge text-info",
      component: AddTeam,
      layout: "/admin",
      show: false,
      moduleId: "217c876b-5f50-4bbf-bce4-325b83ea7787",
    },

    {
      path: "/AddEmployee",
      icon: "ni ni-badge text-info",
      component: AddEmployee,
      layout: "/admin",
      show: false,
      moduleId: "ba56119d-ac43-4ec1-858e-31f2e12235e8",
    },
    {
      path: "/employees",
      name: "Funcionários",
      icon: "ni ni-circle-08 text-info",
      component: Employee,
      layout: "/admin",
      show: true,
      moduleId: "ba56119d-ac43-4ec1-858e-31f2e12235e8",
    },
    {
      path: "/equipes",
      name: "Equipes",
      icon: "ni ni-single-02 text-yellow",
      component: Equipe,
      layout: "/admin",
      show:
        userOrganizationId == "559a16ab-5da8-41ba-96b8-6e9f55feddd8"
          ? false
          : true,
      moduleId: "217c876b-5f50-4bbf-bce4-325b83ea7787",
    },
    {
      path: "/territorializacao",
      name:
        userOrganizationId == "559a16ab-5da8-41ba-96b8-6e9f55feddd8" ||
        "24d125ef-15e6-4bd9-9111-c0b3d50a8009"
          ? "Mapas"
          : "Territorialização",
      icon: "ni ni-pin-3 text-info",
      component: Territorializacao,
      layout: "/admin",
      show: true,
      moduleId: "dbd03c99-ac3c-4dd4-8964-cc066d77d403",
    },
    {
      path: "/EditEmployee",
      icon: "ni ni-badge text-info",
      component: EditEmployee,
      layout: "/admin",
      show: false,
      moduleId: "ba56119d-ac43-4ec1-858e-31f2e12235e8",
    },
    {
      path: "/InventoryItens",
      icon: "ni ni-shop text-info",
      name: "Inventário",
      component: InventoryItens,
      layout: "/admin",
      show:
        userOrganizationId === "24d125ef-15e6-4bd9-9111-c0b3d50a8009"
          ? false
          : true,
      moduleId: "2af11e8b-f884-40c8-b3a2-360d1f1bb95d",
    },
    {
      path: "/AddInventoryItem",
      icon: "ni ni-badge text-info",
      component: AddInventoryItem,
      layout: "/admin",
      show: false,
      moduleId: "2af11e8b-f884-40c8-b3a2-360d1f1bb95d",
    },
    {
      path: "/InventoryMovementRecord",
      icon: "ni ni-delivery-fast text-info",
      name: "Movimento de estoque",
      component: InventoryMovementRecord,
      layout: "/admin",
      show:
        userOrganizationId === "24d125ef-15e6-4bd9-9111-c0b3d50a8009"
          ? false
          : true,
      moduleId: "2af11e8b-f884-40c8-b3a2-360d1f1bb95d",
    },
    {
      path: "/AddInventoryMovementRecord",
      icon: "ni ni-badge text-info",
      component: AddInventoryMovementRecord,
      layout: "/admin",
      show: false,
      moduleId: "2af11e8b-f884-40c8-b3a2-360d1f1bb95d",
    },
    {
      path: "/VisitExport",
      icon: "ni ni-badge text-info",
      component: VisitExport,
      name: "Exportação Visitas",
      layout: "/admin",
      show:
        userOrganizationId == "559a16ab-5da8-41ba-96b8-6e9f55feddd8"
          ? false
          : true,
      moduleId: "00000000-0000-0000-0000-000000000000",
    },
    // {
    //   path: "/AddTrap",
    //   name: "Adicionar Armadilha",
    //   icon: "ni ni-badge text-info",
    //   component: AddTrap,
    //   layout: "/admin",
    //   show: false,
    // },
    // {
    //   path: "/EditTrap",
    //   name: "Editar Armadilha",
    //   icon: "ni ni-badge text-info",
    //   component: EditTrap,
    //   layout: "/admin",
    //   show: false,
    // },

    // {
    //   path: "/animais",
    //   name: "Animais Diversos",
    //   icon: "ni ni-bullet-list-67 text-info",
    //   component: Animais,
    //   layout: "/admin",
    //   show: false,
    // },

    // {
    //   path: "/questionarios",
    //   name: "Questionários",
    //   icon: "ni ni-badge text-info",
    //   component: Questionario,
    //   layout: "/admin",
    //   show: false,
    // },

    {
      path: "/NovaTerritorializacao",
      name: "Nova territorialização",
      icon: "ni ni-pin-3 text-info",
      component: NovaTerritorializacao,
      layout: "/admin",
      show: false,
      moduleId: "dbd03c99-ac3c-4dd4-8964-cc066d77d403",
    },

    /*{
      path: "/dashboardInmet",
      name: "Relatório Pluviométrico",
      icon: "ni ni-chart-pie-35 text-info",
      component: DashboardInmet,
      layout: "/admin",
      show: true,
      moduleId: "00000000-0000-0000-0000-000000000000"
    },*/
    {
      path: "/importSinan",
      name: "Importação SINAN",
      icon: "ni ni-archive-2 text-blue",
      component: ImportSinan,
      layout: "/admin",
      show: true,
      moduleId: "fde5db7d-0cf3-4ddd-8737-708f259d4e1c",
    },
    {
      path: "/importInmet",
      name: "Importação INMET",
      icon: "ni ni-archive-2 text-blue",
      component: ImportInmet,
      layout: "/admin",
      show: false,
      moduleId: "fde5db7d-0cf3-4ddd-8737-708f259d4e1c",
    },
    {
      path: "/dashboardGerencial",
      name: "Relatório Gerencial",
      icon: "ni ni-sound-wave text-info",
      component: Dashboard,
      layout: "/admin",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000",
    },
    {
      path: "/importTerritorialization",
      name: "Importar Territorialização",
      icon: "ni ni-sound-wave text-info",
      component: importTerritorialization,
      layout: "/admin",
      show: false,
      moduleId: "00000000-0000-0000-0000-000000000000",
    },
    {
      path: "/CovidReport",
      name: "Relatórios Visitas",
      icon: "ni ni-chart-bar-32 text-blue",
      component: CovidReport,
      layout: "/admin",
      show: false,
      moduleId: "2a309995-15bb-4a85-af67-f50eb6642d89",
    },

    // {
    //   path: "/reports",
    //   name: "Relatórios",
    //   icon: "ni ni-chart-bar-32 text-blue",
    //   component: ReportsIndex,
    //   layout: "/admin",
    //   show: false,
    // },
    {
      path: "/TrapReport",
      name: "Relatório Mapas",
      icon: "ni ni-square-pin text-red",
      component: TrapReport,
      layout: "/admin",
      show: false,
      moduleId: "a8318169-2dbe-4f91-b247-cb3fc05207c8",
    },
    {
      path: "/OfflineContingency",
      name: "Contingência Offline",
      icon: "ni ni-square-pin text-red",
      component: OfflineContingency,
      layout: "/admin",
      show: true,
      moduleId: "50e2f05b-b6d3-46a6-81b0-34875882afab",
    },

    // {
    //   path: "/AddRealty",
    //   icon: "ni ni-badge text-info",
    //   name: "Adicionar Imóvel",
    //   component: AddRealty,
    //   layout: "/admin",
    //   show: false,
    // },

    // {
    //   path: "/CovidChart",
    //   icon: "ni ni-bullet-list-67 text-red",
    //   component: CovidChart,
    //   layout: "/admin",
    //   show: false,
    // },
    // {
    //   path: "/CovidResidentDetails",
    //   icon: "ni ni-bullet-list-67 text-red",
    //   component: CovidResidentDetails,
    //   layout: "/admin",
    //   show: false,
    // },
  ];

export default routes;
