import React, {useEffect, useState} from "react";
import { withBasicListGraficMap } from "../../../../HOC/Entomologico/Map/BasicListGraficMap";
import { timeStampFormated } from "../../../../services/utils/format";
import {ARMADILHA_INFO} from "../../../../constants/ArmadilhaConstant";
import CloropleticMap from "../../../../views/pages/Reports/CloropleticMap";
import {points as CreatePoints, polygon as CreatePolygon, pointsWithinPolygon} from "@turf/turf";
import moment from "moment";

const PositivesMapContainer = ({
    buildGraficMapList,
    data= undefined, 
    labels= undefined, 
    trapMapInfo, 
    previousTrapMapInfo = undefined, 
    territorializationList, 
    territorializationFiltered, 
    trapTypeName, 
    dataGeoRef = null, 
    dataGeoRefConfirmed = null
}) => {
    const [DataSets, setDataSets] = useState("");
    const [pointsGroup, setPointsGroup] = useState(null)
    const [CloropleticPolygonGroups, setCloropleticPolygonGroups] = useState(null);
    const [HeatMapPoints, setHeatMapPoints] = useState(null);
    const [lastIpo, setLastIpo] = useState();

    const [updatedSinanNotifications,setUpdatedSinanNotifications] = useState(0);
    
    useEffect(() => {
        if(trapMapInfo && lastIpo != undefined){
            checkData(); 
        }
        
    }, [trapMapInfo, territorializationList, previousTrapMapInfo, lastIpo])   

    
    useEffect(() => {
        if(dataGeoRef && pointsGroup && updatedSinanNotifications != 2){
            checkDataSinan(updatedSinanNotifications);
        }
    }, [dataGeoRef, territorializationList, pointsGroup]);   

    
    useEffect(() => {
        if(data){
            getDataSets(data,  previousTrapMapInfo)
        }
    }, [data, previousTrapMapInfo])

    const getDataSets = async (data, previousTrapMapInfo) => {
        const dataset = [];
        let labelDayDataSet = "";
        let lastIpo;

        for(const index in labels){
            const dataSetObject = {
                label: labels[index]
            }

            for(let ano in data){
                if(data[ano][index]){
                    var positives = data[ano][index].positive;
                    var examinated = data[ano][index].examinated;

                    const ipo = examinated != 0 ? ((positives/examinated)*100).toFixed(1) : 0; //indice de positividade de ovos;
                    lastIpo = ipo;

                    if(data[ano].label){
                        dataSetObject[data[ano].label] = ipo;
                    }else{
                        dataSetObject["IP"] = ipo;
                    }

                }
            }
            
            dataset.push(dataSetObject);            
            
            labelDayDataSet = dataset[index].label;
            
            if (lastIpo == 0 && previousTrapMapInfo != undefined && index != 0){
                labelDayDataSet = dataset[index-1].label;
            }
            else if (labelDayDataSet == 53 && data[data.length - 1].length == 52){
                labelDayDataSet = dataset[index-1].label;
            }
        }

        setLastIpo(lastIpo);
        setDataSets(labelDayDataSet);
    }

    const checkDataSinan = async (typeSinan) => {
        if(typeSinan == 0){
            await buildMapListSinan(dataGeoRef, "Notificações").then(result => {
                var pointsGroupAux = [...pointsGroup];
                pointsGroupAux.push(result);
                setUpdatedSinanNotifications(1);
                setPointsGroup(pointsGroupAux);
            });
        } else if (typeSinan == 1) {
            await buildMapListSinan(dataGeoRefConfirmed, "Confirmados").then(result => {
                var pointsGroupAux = [...pointsGroup];
                pointsGroupAux.push(result);
                setUpdatedSinanNotifications(2);
                setPointsGroup(pointsGroupAux);
            });
        }        
    }

    const checkData = async () => {
        const pointsGroupped = new Array();
        
        if (lastIpo == 0 && previousTrapMapInfo != undefined){
            trapMapInfo.positives = previousTrapMapInfo.positives;
            trapMapInfo.actives = previousTrapMapInfo.actives;
            trapMapInfo.negatives = previousTrapMapInfo.negatives;
        }

        if(trapMapInfo && trapMapInfo.actives !== undefined){
            const activeList = await buildGraficMapList(trapMapInfo.actives, "Armadilhas instaladas", "black");
            pointsGroupped.push(activeList);
        }        

        if(trapMapInfo && trapMapInfo.positives !== undefined){
            const positiveList = await buildGraficMapList(trapMapInfo.positives, "Armadilhas positivas", "red");
            if(positiveList.points.length > 0){
                setHeatMapPoints(positiveList.points)
            }

            pointsGroupped.push(positiveList);
        }

        if(trapMapInfo && trapMapInfo.negatives !== undefined){
            const negativeList = await buildGraficMapList(trapMapInfo.negatives, "Armadilhas negativas", "green");
            pointsGroupped.push(negativeList);
        }

        buildCloropleticInfo();
        setPointsGroup(pointsGroupped);

    }

    const buildMapListSinan = async (register, key) => {
        const mapList = new Array();
        const heatMapTitle = `Mapa de Calor de ${key}`;

        for(let i = 0; i < register.length; i++){
            const name = `Data da Notificação: ${moment(register[i].dt_notific).format("DD/MM/YYYY")}`;
            const position = [register[i].latitude, register[i].longitude];
            const icon = require('../../../../assets/img/icons/visita.png');
            const intensity = 1;

            const mapListObject = {
                name,
                position,
                intensity,
                icon
            }
            
            mapList.push(mapListObject);
        }

        const pointsGroupObject = {
            name: key,
            heatMapTitle,
            points: mapList,
        }

        return pointsGroupObject;
    }

    // const buildMapList = async (traps, key, colorIcon) => {
    //     const trapList = new Array();

    //     for(let i = 0; i < traps.length; i++){
    //         // const name = `${ARMADILHA_INFO[traps[i].trapTypeName].apelido}  Nº ${traps[i].number} \n ${timeStampFormated(traps[i].dateInstalled)} \n\n ${traps[i].realty.subpremisse ? traps[i].realty.subpremisse : null} ${traps[i].realty.number ? traps[i].realty.number : null}`;
    //         const name = `${ARMADILHA_INFO[traps[i].trapTypeName].apelido} \n ${timeStampFormated(traps[i].dateInstalled)}`;
    //         const position = [traps[i].latitude, traps[i].longitude];
    //         const intensity = 1;
    //         const type = "basictrap";
    //         const Icon = ARMADILHA_INFO[traps[i].trapTypeName].icons[colorIcon];
    //         const trapListObject = {
    //             name,
    //             position,
    //             type,
    //             intensity,
    //             icon:Icon,
    //         }
            
    //         trapList.push(trapListObject);
    //     }

    //     const pointsGroupObject = {
    //         name: key,
    //         checked: false,
    //         points: trapList,
    //     }

    //     return pointsGroupObject;
    // }

    const buildCloropleticInfo = () => {

        range = [19.99, 59.99];   
        
        const cloropleticGroups = new Array();
        const allPositivesTraps = CreatePoints(trapMapInfo.positives.map(({latitude, longitude}) => ([longitude, latitude])));
        const allExaminatedTraps = CreatePoints(trapMapInfo.inspected.map(({latitude,longitude}) => [longitude, latitude]))

        for(const group of territorializationList){
            let list = [...group.territorializationList];
            if(territorializationFiltered.length > 0){
                list = list.filter((territorialization) => territorializationFiltered.some(({value}) => territorialization.id === value));
            }

            const cloropleticGroup = {
                name: group.name,
                Polygons: new Array()
            };


            let legends = [];

            for(const territorialization of list){
                if(territorialization.geometryType == "POLYGON"){
                    const turfPolygon = CreatePolygon([territorialization.coordinates.map(({x, y}) => [x, y])])
                    const filteredExaminatedTraps = pointsWithinPolygon(allExaminatedTraps, turfPolygon).features.length

                    if(filteredExaminatedTraps > 0){
                        const filteredPositivesTraps = pointsWithinPolygon(allPositivesTraps, turfPolygon).features.length
                        const ipo = (filteredPositivesTraps / filteredExaminatedTraps) * 100;

                        var range = [];

                        switch (trapTypeName) {
                            case "Ovitrampa":
                                range = [19.99, 59.99];
                                break;
                        
                            case "Adultrap":
                                range = [9.99, 19.99];
                                break;

                            default:
                                range = [19.99, 59.99];
                                break;
                        }

                        if(ipo <= range[0]){
                            turfPolygon.color = "green";
                        }else if( ipo <= range[1]){
                            turfPolygon.color = "yellow";
                        }else{
                            turfPolygon.color = "red";
                        }
                    }else{
                        turfPolygon.color = "transparent";
                    }
                    cloropleticGroup.Polygons.push(turfPolygon)
                }
            }

            legends.push({
                firstItem: 4,
                lastItem: null,
                color: "red",
                text: range[1] + "+"
            });

            legends.push({
                firstItem: 1,
                lastItem: 3.99,
                color: "yellow",
                text: range[0] + " - " + range[1]
            });

            legends.push({
                firstItem: 0.0,
                lastItem: 0.99,
                color: "green",
                text: `0 - ` + range[0]
            });

            cloropleticGroup.legends = legends;

            cloropleticGroups.push(cloropleticGroup)
        }

        setCloropleticPolygonGroups(cloropleticGroups)
    }

    return(
        <div>
        <CloropleticMap
            PointsGroup={pointsGroup}
            PolygonsGroup = {CloropleticPolygonGroups}
            HeatMap
            QuantityLegend={
                <div>
                    Exibindo Dados da <br />
                    Semana {typeof(DataSets) === "string" ? DataSets.replace("Semana", "") : DataSets.toString().replace("Semana", "")}
                </div>
            }
            showCloropletic
            PointsHeatMap={HeatMapPoints}
        />
        
        </div>
    );
    
}

export default withBasicListGraficMap(PositivesMapContainer);