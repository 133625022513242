import moment from "moment";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";
import { getTrapRange } from "../../../constants/RangeTrapConstant";

export const filterTrapsByColorStatus = (traps, trapNameToFilter) => {
  const INVALID_DATE = 10000;

  let green = 0;
  let yellow = 0;
  let red = 0;

  traps.forEach(({ trapName, installDate, lastReadDate, trapWasRemoved }) => {
    if (trapName === trapNameToFilter && !trapWasRemoved) {
      let daysDifference = Math.min(
        daysDifferenceBetweenTodayAndDate(installDate),
        daysDifferenceBetweenTodayAndDate(lastReadDate) || INVALID_DATE
      );

      var trapRange = getTrapRange(trapName);

      if (trapRange.quantityRange === 3) {
        if (daysDifference <= trapRange.data.middle) green += 1;
        else if (daysDifference <= trapRange.data.end) yellow += 1;
        else red += 1;
      } else if (trapRange.quantityRange === 2) {
        if (daysDifference <= trapRange.data.end) green += 1;
        else red += 1;
      }
    }
  });

  return {
    green,
    yellow,
    red,
  };
};

export const calculateDaysDifferenceBetweenDates = (firstDate, secondDate) => {
  const differenceInMiliseconds = Math.abs(firstDate - secondDate);
  const differenceInDays = Math.ceil(
    differenceInMiliseconds / (1000 * 60 * 60 * 24)
  );

  return differenceInDays;
};

export const daysDifferenceBetweenTodayAndDate = (date) => {
  if (!date || date === "") return;
  const todayDate = moment().startOf('day');
  const informedDate = moment(date).startOf('day');

  return todayDate.diff(informedDate, "days");
};

export const getTrapColorStatus = (trapType, installDate, lastReadDate) => {
  let daysDifference;
  let status;

  const daysDifferenceBetweenTodayAndInstallDate =
    daysDifferenceBetweenTodayAndDate(installDate);
  const daysDifferenceBetweenTodayAndLastReadDate =
    daysDifferenceBetweenTodayAndDate(lastReadDate);

  !daysDifferenceBetweenTodayAndLastReadDate
    ? (daysDifference = daysDifferenceBetweenTodayAndInstallDate)
    : daysDifferenceBetweenTodayAndLastReadDate <
      daysDifferenceBetweenTodayAndInstallDate
    ? (daysDifference = daysDifferenceBetweenTodayAndLastReadDate)
    : (daysDifference = daysDifferenceBetweenTodayAndInstallDate);

  if (
    ARMADILHA_INFO[trapType].apelido === "ADI" ||
    ARMADILHA_INFO[trapType].apelido === "Estação Disseminadora"
  ) {
    if (daysDifference <= 30) status = "green";
    if (daysDifference > 30 && daysDifference <= 60) status = "yellow";
    if (daysDifference > 60) status = "red";
  }

  if (
    ARMADILHA_INFO[trapType].apelido === "Ovitrampa" ||
    ARMADILHA_INFO[trapType].apelido === "BG-GAT" ||
    ARMADILHA_INFO[trapType].apelido === "Adultrap"
  ) {
    if (daysDifference <= 7) status = "green";
    if (daysDifference > 7) status = "red";
  }

  return status;
};

export const calculateTrapColorStatus = (trapData) => {
  if (!trapData) return;

  const INVALID_DATE = 10000;

  const daysDifference = Math.min(
    daysDifferenceBetweenTodayAndDate(trapData.lastInstallDate),
    daysDifferenceBetweenTodayAndDate(trapData.lastReadDate) || INVALID_DATE
  );

  const trapRange = getTrapRange(trapData.trapType);

  if (trapRange.quantityRange === 3) {
    if (daysDifference <= trapRange.data.middle) return "green";

    if (daysDifference <= trapRange.data.end) return "yellow";

    return "red";
  }

  if (trapRange.quantityRange === 2) {
    if (daysDifference <= trapRange.data.end) return "green";

    return "red";
  }
};

export const calculateAbsoluteNumbersForTrapsColorsStatus = (
  traps,
  trapType
) => {
  if (!traps || traps.length === 0 || !trapType || trapType === "") return;

  const INVALID_DATE = 10000;

  let green = 0;
  let yellow = 0;
  let red = 0;

  for (let i = 0; i < traps.length; i++) {
    const {
      trapTypeName,
      lastInstallHistoryStatusDate,
      lastReadHistoryStatusDate,
      trapWasRemoved,
    } = traps[i];

    if (trapWasRemoved || trapTypeName !== trapType) continue;

    let daysDifference;

    const daysDifferenceBetweenTodayAndInstallDate =
      daysDifferenceBetweenTodayAndDate(lastInstallHistoryStatusDate);
    const daysDifferenceBetweenTodayAndLastReadDate =
      daysDifferenceBetweenTodayAndDate(lastReadHistoryStatusDate);

    !daysDifferenceBetweenTodayAndLastReadDate
      ? (daysDifference = daysDifferenceBetweenTodayAndInstallDate)
      : daysDifferenceBetweenTodayAndLastReadDate <
        daysDifferenceBetweenTodayAndInstallDate
      ? (daysDifference = daysDifferenceBetweenTodayAndLastReadDate)
      : (daysDifference = daysDifferenceBetweenTodayAndInstallDate);

    /* let daysDifference = Math.min(
      daysDifferenceBetweenTodayAndDate(lastInstallHistoryStatusDate),
      daysDifferenceBetweenTodayAndDate(lastReadHistoryStatusDate) ||
        INVALID_DATE
    ); */

    const trapColorRange = getTrapRange(trapTypeName);

    if (trapColorRange.quantityRange === 3) {
      if (daysDifference <= trapColorRange.data.middle) {
        green++;
        continue;
      }

      if (daysDifference <= trapColorRange.data.end) {
        yellow++;
        continue;
      }

      red++;
      continue;
    }

    if (trapColorRange.quantityRange === 2) {
      if (daysDifference <= trapColorRange.data.end) {
        green++;
        continue;
      }

      red += 1;
    }
  }

  return {
    green,
    yellow,
    red,
  };
};
