import React from "react";
import { Link } from "react-router-dom";
import "./../../assets/css/navbar.css";

// reactstrap components
import {
    Container,
} from "reactstrap";
import { getAllTypeTerritorializations } from "../../services/api/territorialization";
import { getPermissionsToken } from "../../services/utils/auth";
import "./FiltroTerritorializacao.css";

class FiltroTerritorializacao extends React.Component {
    state = {
        filterName:"",
        typeOptions:[],
        filteredTerritorializations:[],
      }
    async componentDidMount(){
        const typeTerritorializationRequest = await getAllTypeTerritorializations();
        let typeOptions = typeTerritorializationRequest.data;
        this.setState({typeOptions: typeOptions}, () => {
            if(typeTerritorializationRequest.data.length > 0){
                this.props.toggleTerritorializationType(typeTerritorializationRequest.data[0].id);
            }
        });
    }

    componentDidUpdate(prevprops){
        if(this.props.Territorializations !== prevprops.Territorializations){
            this.setState({filteredTerritorializations: this.props.Territorializations})
        }
    }

    filter = () => {
        let filterNames = this.state.filterName.split(",")
        let filteredTerritorializations = this.props.Territorializations.filter(
            (Territorialization)=>{
                if(filterNames.length === 1 && filterNames[0] === ''){
                    return true;
                }else{
                    if(Territorialization.geometryType !== 'POINT'){
                        return filterNames.findIndex((filterName) => filterName.trim() !== '' && Territorialization.name.toLowerCase().trim().includes(filterName.trim())) !== -1
                    }else{
                        return filterNames.findIndex((filterName) => filterName.trim() !== '' && `${Territorialization.subpremisse}, ${Territorialization.number} - ${Territorialization.postalCode}`.toLowerCase().trim().includes(filterName.trim())) !== -1
                    }
                    
                }
            }
        );
        this.setState(filteredTerritorializations);
        this.props.parentCallback(filteredTerritorializations);
    }

    handleKeyDown = (e) => {
        if(e.key === 'Enter'){
            this.filter();
        }
    }

    render() {
        
        const userPermissions = getPermissionsToken();
        return (
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" >
                <Container fluid>
                    <div className="header-body">

                        <div className="row linha-form">
                            <div className="col-12 responsive-field-filter-territorializacao-full">
                                <input onKeyDown={this.handleKeyDown} onChange={e=> this.setState({filterName:e.nativeEvent.target.value})} type="text" className="form-control" placeholder="Pesquisar" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="linha-form col-4 responsive-field-filter-territorializacao-full">
                                <select onChange={e=> this.props.toggleTerritorializationType(e.nativeEvent.target.value)} className="form-control">
                                    {this.state.typeOptions.map(opt => {
                                        return (
                                            <option value={opt.id}>{opt.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4 responsive-field-filter-territorializacao-full">
                                <button onClick={()=> this.filter()} className="btn btn-primary">Pesquisar</button>
                            </div>

                            {userPermissions["dbd03c99-ac3c-4dd4-8964-cc066d77d403"] && userPermissions["dbd03c99-ac3c-4dd4-8964-cc066d77d403"].create ? 
                                <div className="col-4 responsive-field-filter-territorializacao-full">
                                    <Link to="NovaTerritorializacao">
                                        <button  className="float-right btn btn-default">Nova territorialização</button>
                                    </Link>
                                </div>
                            :null}
                                <div className="col-4 responsive-field-filter-territorializacao-full">
                                    <button disabled={this.state.filteredTerritorializations.length === 0} className="float-right btn btn-primary" onClick={this.props.exportTerritorializations}> 
                                    <i class="fa fa-download" aria-hidden="true" style={{paddingRight: 10}}/>Exportar Shapes em SHP</button>
                                </div>
                        </div>
                        <div className="row" style={{paddingTop: ".8rem", justifyContent: "center"}}>
                            <div className="col-4 responsive-field-filter-territorializacao-full">
                                <button disabled={this.state.filteredTerritorializations.length === 0} className="float-right btn btn-primary" onClick={this.props.exportTerritorializationInKML}> 
                                <i class="fa fa-download" aria-hidden="true" style={{paddingRight: 10}}/>Exportar Shapes em KML</button>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );

    }

}

export default FiltroTerritorializacao;