import React from "react";
import { Col } from "reactstrap";
import StatusTrapCard from "../../../Containers/Entomologico/Cards/StatusTrapCard";
import { filterTrapsByColorStatus } from "../../../../services/utils/TrapStatus/trapStatus";
import { toast } from "react-toastify";
import { getTrapRange } from "../../../../constants/RangeTrapConstant";

const StatusColorTrapsCardsWrapper = ({
  variant,
  trapsList,
  selectedTrapTypeData,
}) => {
  const [statusColorTrapsNumber, setStatusColorTrapsNumber] =
    React.useState(null);
  const [trapsLength, setTrapsLength] = React.useState(null);

  const getTrapsByStatusColor = () => {
    if (
      !trapsList ||
      trapsList.length === 0 ||
      selectedTrapTypeData.trapTypeId === "00000000-0000-0000-0000-000000000000"
    )
      return;

    try {
      const filteredTrapsQuantityByColorStatus = filterTrapsByColorStatus(
        trapsList,
        selectedTrapTypeData.trapName
      );

      setStatusColorTrapsNumber(filteredTrapsQuantityByColorStatus);
    } catch (error) {
      console.error(error);
      toast.error(
        "Ocorreu um erro ao calcular os status por cores das armadilhas"
      );
    }
  };

  const generateCard = (trapName) => {
    const trapRange = getTrapRange(trapName);
    let card;

    if (trapRange.quantityRange === 2) {
      card = (
        <>
          <Col style={styles.col} xl="3">
            <StatusTrapCard
              cardText={
                <span>
                  Instalação ou manutenção recém realizada
                  <br />
                  (até {trapRange.data.end} dias)
                  <span
                    className="badge badge-success"
                    style={{
                      width: "50px",
                      marginLeft: "5px",
                      backgroundColor: "#76ea79",
                    }}
                  >
                    &nbsp;
                  </span>
                </span>
              }
              cardValue={
                statusColorTrapsNumber ? statusColorTrapsNumber.green : 0
              }
            />
          </Col>
          <Col style={styles.col} xl="3">
            <StatusTrapCard
              cardText={
                <span>
                  Ultrapassou o prazo de manutenção
                  <br />
                  (acima de {trapRange.data.end} dias)
                  <span
                    className="badge badge-danger"
                    style={{
                      width: "50px",
                      marginLeft: "5px",
                      backgroundColor: "#ff0000",
                    }}
                  >
                    &nbsp;
                  </span>
                </span>
              }
              cardValue={
                statusColorTrapsNumber ? statusColorTrapsNumber.red : 0
              }
            />
          </Col>
        </>
      );
    }
    if (trapRange.quantityRange === 3) {
      card = (
        <>
          <Col style={styles.col} xl="3">
            <StatusTrapCard
              cardText={
                <span>
                  Instalação ou manutenção recém realizada
                  <br />
                  (até {trapRange.data.middle} dias)
                  <span
                    className="badge badge-success"
                    style={{
                      width: "50px",
                      marginLeft: "5px",
                      backgroundColor: "#76ea79",
                    }}
                  >
                    &nbsp;
                  </span>
                </span>
              }
              cardValue={
                statusColorTrapsNumber ? statusColorTrapsNumber.green : 0
              }
            />
          </Col>
          <Col style={styles.col} xl="3">
            <StatusTrapCard
              cardText={
                <span>
                  Atenção para próxima manutenção
                  <br />({trapRange.data.middle + 1} a {trapRange.data.end}{" "}
                  dias)
                  <span
                    className="badge badge-success"
                    style={{
                      width: "50px",
                      marginLeft: "5px",
                      backgroundColor: "#fff200",
                    }}
                  >
                    &nbsp;
                  </span>
                </span>
              }
              cardValue={
                statusColorTrapsNumber ? statusColorTrapsNumber.yellow : 0
              }
            />
          </Col>
          <Col style={styles.col} xl="3">
            <StatusTrapCard
              cardText={
                <span>
                  Ultrapassou o prazo de manutenção
                  <br />
                  (acima de {trapRange.data.end} dias)
                  <span
                    className="badge badge-danger"
                    style={{
                      width: "50px",
                      marginLeft: "5px",
                      backgroundColor: "#ff0000",
                    }}
                  >
                    &nbsp;
                  </span>
                </span>
              }
              cardValue={
                statusColorTrapsNumber ? statusColorTrapsNumber.red : 0
              }
            />
          </Col>
        </>
      );
    }

    return card;
  };

  React.useEffect(() => {
    // if (statusColorTrapsNumber) return;

    if (trapsLength !== trapsList.length) {
      getTrapsByStatusColor();
      setTrapsLength(trapsList.length);
    }
  }, [trapsList && trapsList.length]);

  const styles = {
    row: {
      marginLeft: 0,
      marginRight: 0,
    },
    col: {
      paddingLeft: 0,
      paddingRight: "0.2vw",
    },
  };

  return (
    <>
      {trapsList &&
        trapsList.length > 0 &&
        statusColorTrapsNumber &&
        generateCard(variant)}
    </>
  );
};

export default StatusColorTrapsCardsWrapper;
