import { updatePositionVigilanciaEntomologica } from "../api/VigilanciaEntomologica";
import { updatePositionVistoriaArmadilhas } from "../api/VistoriaArmadilhas";

export async function UpdateVisit(visit){
    switch(visit.typeForm){
        case 'Vigilância Entomológica':
            return updatePositionVigilanciaEntomologica(visit);
        break;
        case 'Vistoria de Armadilhas':
            return updatePositionVistoriaArmadilhas(visit);
        break;
        default:
            return new Promise.reject("Not a valid form!")
        break;
    }
}