import React, {useState, useEffect, useRef} from "react";
import { Brush, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useCurrentPng } from "recharts-to-png";
import CardPresentational from "../../Presentational/Sinan/CardPresentational";

const LineIBChartContainer  = ({data, labels}) => {
    const [chartDataSets, setChartDataSets] = useState([{}]);
    const textCardHeader = useRef("IB");
    const [getpng, {ref}] = useCurrentPng();
    const colors = ["#E60017", "#FC6D0D", "#B10FFF", "#0C28F2", "#D44D13"];

    useEffect(() => {
        if(data){
            buildDataSets(data);
        }
    }, [data])

    async function buildDataSets (data){

        const dataset = [];

        for(const index in labels){
            const dataSetObject = {
                label: labels[index]
            }

            for(let ano in data){
                if(data[ano][index] !== undefined && data[ano][index] !== null){
                    if(data[ano]["label"]){
                        dataSetObject[data[ano]["label"]] = data[ano][index];
                    }else{
                        dataSetObject["IB"] = data[ano][index];
                    }
                }
            }
            dataset.push(dataSetObject);
        }

        setChartDataSets(dataset);
    }

    const style={
        cardHeaderText: {
            color:"black",
            fontWeight:"bold",
            fontSize:"0.97rem"
        },
    }
    
    const buildChartElement = () => (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                id={"IBChartContainerChart"}
                data={chartDataSets}
                margin={{
                    top:30,
                    right:15
                }}
                ref={ref}
            >
                <text x={"50%"} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
                    <tspan fontSize="16" fontWeight={"bolder"}>IB </tspan>
                </text>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" style={{fontSize:12}}/>
                <YAxis style={{fontSize:12}} />
                <Tooltip/>
                <Legend align="center" verticalAlign="top" />
                <Brush dataKey={"label"}/>
                {data.map((ano, index) => (
                    <Line dataKey={ano["label"] || "IB"} stroke={colors[index]} />
                ))}
            </LineChart>
        </ResponsiveContainer>
    )
    
    return(
        <CardPresentational
            CardId="IBChartContainer"
            style={style}
            textCardHeader={textCardHeader.current}
            element={buildChartElement()}
            getpng={getpng}
        />
    );
}


export default LineIBChartContainer;