import React from "react";
import { Col, Row } from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";
import { getTrapsTypes } from "../../../../services/api/Trap";
import { toast } from "react-toastify";

const TrapsExportFilters = ({
  employeesList,
  trapsFilters,
  handleFiltersChange,
}) => {
  const [trapTypeOptions, setTrapTypeOptions] = React.useState([]);

  const fetchTrapTypes = async () => {
    try {
      const { data, status } = await getTrapsTypes();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos tipos de armadilhas. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      const formatedTrapTypes = data.map(({ name }) => {
        return {
          label: getTrapTypeSurname[name],
          value: getTrapTypeSurname[name],
        };
      });

      setTrapTypeOptions([
        {
          label: "Todas",
          value: "all",
        },
        ...formatedTrapTypes,
      ]);
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
    }
  };

  React.useEffect(() => {
    fetchTrapTypes();
  }, []);

  const getTrapTypeSurname = {
    armadilhaDisseminadoraInseticida: "ADI",
    armadilhaOvos: "OVITRAMPA",
    armadilhaMosquitoAdulto: "ADULTRAP",
    armadilhaMosquitoAdulto2: "BG-GAT",
    estacaoDisseminadoraLarvicida: "EDL",
  };

  const trapSituationOptions = [
    {
      label: "Todas",
      value: "all",
    },
    {
      label: "Ativa",
      value: "active",
    },
    {
      label: "Desativada",
      value: "disabled",
    },
  ];

  const selectComponentStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
  };

  return (
    <>
      <Row
        className="align-items-center"
        style={{ marginBottom: "1rem", padding: "0px 1.5rem" }}
      >
        <Col xl="4">
          <span style={{ fontWeight: "bold" }}>Número</span>
          <input
            type="text"
            className="form-control"
            placeholder="Digite o número da armadilha"
            value={trapsFilters.number}
            onChange={({ target }) =>
              handleFiltersChange("number", target.value)
            }
          />
        </Col>
        <Col xl="4">
          <span style={{ fontWeight: "bold" }}>Situação</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            options={trapSituationOptions}
            value={trapsFilters.situation}
            onChange={(selectedOptionObject) =>
              handleFiltersChange("situation", selectedOptionObject)
            }
          />
        </Col>
        <Col xl="4">
          <span style={{ fontWeight: "bold" }}>Tipo de Armadilha</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            options={trapTypeOptions}
            value={trapsFilters.trapType}
            onChange={(selectedOptionObject) =>
              handleFiltersChange("trapType", selectedOptionObject)
            }
          />
        </Col>
      </Row>
      <Row
        className="align-items-center"
        style={{ marginBottom: "2rem", padding: "0px 1.5rem" }}
      >
        <Col xl="4">
          <span style={{ fontWeight: "bold" }}>Quem Instalou</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            options={[
              {
                label: "Todos",
                value: "all",
              },
              ...employeesList,
            ]}
            value={trapsFilters.installer}
            onChange={(selectedOptionObject) =>
              handleFiltersChange("installer", selectedOptionObject)
            }
          />
        </Col>
        <Col xl="4">
          <span style={{ fontWeight: "bold" }}>
            Data de instalação (Período)
          </span>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5%",
              }}
            >
              <div style={{ width: "45%" }}>
                <input
                  type="date"
                  value={trapsFilters.beginInstallDate}
                  onChange={({ target }) =>
                    handleFiltersChange("beginInstallDate", target.value)
                  }
                  className="form-control"
                  name="beginInstallDate"
                  id="beginInstallDate"
                />
              </div>
              <div
                className="h4 text-white"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={{ fontWeight: "bold", color: "#525f7f" }}>
                  Até
                </span>
              </div>
              <div style={{ width: "45%" }}>
                <input
                  type="date"
                  value={trapsFilters.endInstallDate}
                  onChange={({ target }) =>
                    handleFiltersChange("endInstallDate", target.value)
                  }
                  className="form-control"
                  name="endInstallDate"
                  id="endInstallDate"
                />
              </div>
            </div>
          </div>
        </Col>
        <Col xl="4">
          <span style={{ fontWeight: "bold" }}>Quem Removeu</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            options={[
              {
                label: "Todos",
                value: "all",
              },
              ...employeesList,
            ]}
            value={trapsFilters.uninstaller}
            onChange={(selectedOptionObject) =>
              handleFiltersChange("uninstaller", selectedOptionObject)
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default TrapsExportFilters;
