import moment from "moment";
import React from "react";
import DataTable from "react-data-table-component";
import CsvDownloadButton from "react-json-to-csv";
import { Card, CardHeader, Col, Row } from "reactstrap";
import TrapsExportFilters from "./TrapsExportFilters";

const TrapsExportTable = ({ trapsToExport, employeesList }) => {
  const [filteredTrapsToExport, setFilteredTrapsToExport] =
    React.useState(trapsToExport);
  const [trapsFilters, setTrapsFilters] = React.useState({
    number: "",
    beginInstallDate: "",
    endInstallDate: "",
    situation: {
      label: "Todas",
      value: "all",
    },
    trapType: {
      label: "Todas",
      value: "all",
    },
    installer: {
      label: "Todos",
      value: "all",
    },
    uninstaller: {
      label: "Todos",
      value: "all",
    },
  });

  React.useEffect(() => {
    filterTraps();
  }, [trapsFilters]);

  const filterTraps = () => {
    const filteredTrapsByNumber =
      trapsFilters.number !== "" &&
      filteredTrapsToExport.filter((trap) =>
        trap.NUMERO_ARMADILHA.includes(trapsFilters.number)
      );

    let newFilteredTraps = filteredTrapsByNumber || trapsToExport;

    if (trapsFilters.situation.value !== "all")
      newFilteredTraps = newFilteredTraps.filter(
        (trap) => trap.SITUACAO_ARMADILHA === trapsFilters.situation.label
      );

    if (trapsFilters.trapType.value !== "all")
      newFilteredTraps = newFilteredTraps.filter(
        (trap) => trap.TIPO_ARMADILHA === trapsFilters.trapType.label
      );

    if (trapsFilters.installer.value !== "all")
      newFilteredTraps = newFilteredTraps.filter(
        (trap) => trap.QUEM_INSTALOU === trapsFilters.installer.label
      );

    if (trapsFilters.uninstaller.value !== "all")
      newFilteredTraps = newFilteredTraps.filter(
        (trap) => trap.QUEM_RETIROU === trapsFilters.uninstaller.label
      );

    if (
      trapsFilters.beginInstallDate !== "" &&
      trapsFilters.endInstallDate !== ""
    ) {
      const beginInstallDate = moment(trapsFilters.beginInstallDate);
      const endInstallDate = moment(trapsFilters.endInstallDate);

      newFilteredTraps = newFilteredTraps.filter((trap) => {
        const trapInstallDate = moment(trap.DATA_INSTALACAO, "DD/MM/YYYY");

        return trapInstallDate.isBetween(
          beginInstallDate,
          endInstallDate,
          null,
          "[]"
        );
      });
    }

    setFilteredTrapsToExport(newFilteredTraps);
  };

  const handleFiltersChange = (filterName, newValue) => {
    const newFiltersValues = { ...trapsFilters };

    newFiltersValues[filterName] = newValue;

    setTrapsFilters(newFiltersValues);
  };

  const tableColumns = [
    {
      name: "Tipo da armadilha",
      selector: "TIPO_ARMADILHA",
      sortable: true,
      width: "100px",
    },
    {
      name: "Número",
      selector: "NUMERO_ARMADILHA",
      sortable: true,
      sortFunction: (a, b) => {
        const valorA = a.NUMERO_ARMADILHA.replace(/\D/g, "");
        const valorB = b.NUMERO_ARMADILHA.replace(/\D/g, "");

        return valorA - valorB;
      },
    },
    {
      name: "Data da instalação",
      selector: "DATA_INSTALACAO",
      sortable: true,
    },
    {
      name: "Quem instalou",
      selector: "QUEM_INSTALOU",
      sortable: true,
    },
    {
      name: "Situação",
      selector: "SITUACAO_ARMADILHA",
      sortable: true,
    },
    {
      name: "Data da retirada",
      selector: "DATA_RETIRADA",
      sortable: true,
    },
    {
      name: "Quem retirou",
      selector: "QUEM_RETIROU",
      sortable: true,
    },
    {
      name: "Latitude",
      selector: "LATITUDE",
      sortable: false,
    },
    {
      name: "Longitude",
      selector: "LONGITUDE",
      sortable: false,
    },
    {
      name: "Endereço",
      selector: "ENDERECO",
      sortable: true,
    },
    {
      name: "Tipo de Imóvel",
      selector: "TIPO_IMOVEL",
      sortable: true,
    },
    {
      name: "Nome do morador",
      selector: "NOME_MORADOR",
      sortable: true,
    },
    {
      name: "Telefone do morador",
      selector: "TELEFONE_MORADOR",
      sortable: true,
    },
  ];

  return (
    <Row className="mt-12">
      <Col className="mb-12 mb-xl-0" xl="12">
        <Card className="shadow">
          <CardHeader className="border-0" style={{ paddingBottom: "0px" }}>
            <Row className="align-items-center">
              <div className="col">
                <h2 className="mb-0">Armadilhas</h2>
                <br />
              </div>
            </Row>
          </CardHeader>
          <TrapsExportFilters
            employeesList={employeesList}
            trapsFilters={trapsFilters}
            handleFiltersChange={handleFiltersChange}
          />
          <Row style={{ textAlign: "center", marginBottom: "1rem" }}>
            <Col>
              <CsvDownloadButton
                className="btn btn-primary"
                filename={`armadilhas${moment().format("YYYY-MM-DD")}`}
                data={filteredTrapsToExport}
                style={{ width: "330px" }}
              >
                <i className="fa fa-download" /> Baixar CSV das armadilhas
              </CsvDownloadButton>
            </Col>
          </Row>
          <DataTable
            noHeader
            defaultSortField="name"
            defaultSortAsc={true}
            pagination
            highlightOnHover
            responsive
            columns={tableColumns}
            data={filteredTrapsToExport}
            noDataComponent={"Nenhum registro encontrado."}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TrapsExportTable;
