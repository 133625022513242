import React from "react";
import { Link } from "react-router-dom";
import "./../../assets/css/navbar.css";

// reactstrap components
import {
    Container,
} from "reactstrap";

class FiltroArmadilhas extends React.Component {
    state ={
        filter:""

    }
    handleChange = e => {
        let {name,value} = e.target;
        this.setState({
            [name]: value
        });
      };
    render() {
        let filteredTrap2 = this.props.Traps;
        let filteredTrap = filteredTrap2.filter(
            (Trap)=>{
                return Trap.name.toLowerCase().indexOf(
                    this.state.filter.toLowerCase()) !== -1
                    ||Trap.description.toLowerCase().indexOf(
                        this.state.filter.toLowerCase()) !== -1;
            }
        );
        return (
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" >
                <Container fluid>
                    <div className="header-body">

                        <div className="row linha-form">
                            <div className="col-8">
                                <input type="text"name="filter" onChange={this.handleChange} className="form-control" placeholder="Pesquisar por nome ou descrição" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <button onClick={()=> this.props.parentCallback(filteredTrap)}className="btn btn-primary">Pesquisar</button>
                            </div>
                            <div className="col-4">
                                <Link to="AddTrap">
                                    <button className="float-right btn btn-default col-8">Nova armadilha</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );

    }

}

export default FiltroArmadilhas;