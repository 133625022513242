import React, {Component, useEffect, useState} from "react";
import GenericMap from "../../../../views/pages/Reports/GenericMap";
import {point as CreatePoint, polygon as CreatePolygon, pointsWithinPolygon} from "@turf/turf"
import { withBasicListGraficMap } from "../../../../HOC/Entomologico/Map/BasicListGraficMap";
import CloropleticMap from "../../../../views/pages/Reports/CloropleticMap";

let territorializations = [];

const PendentsVistoriedMapContainer = ({data, trapData, labels, trapMapInfo, territorializationList, previousTrapMapInfo, buildGraficMapList, trapTypeName}) => {
    const [DataSets, setDataSets] = useState("");
    const [pointsGroup, setPointsGroup] = useState(null)
    const [CloropleticPolygonGroups, setCloropleticPolygonGroups] = useState(null);
    const [HeatMapPoints, setHeatMapPoints] = useState(null);
    const [labelData, setLabelData] = useState("");
    const [inspectionsEqualZero, setInspectionsEqualZero] = useState("");
    const [eggsEqualZero, setEggsEqualZero] = useState();

    useEffect(() => {
        if(trapMapInfo && eggsEqualZero != undefined){
            checkData(); 
        }
        
    }, [trapMapInfo, territorializationList, eggsEqualZero])   

    useEffect(() => {
        if(data && data.length){
            getDataSets(data,previousTrapMapInfo)
        }
    }, [data, previousTrapMapInfo])

    const buildCloropleticInfo  = () => {
        const cloropleticGroups = [];

        const allVistoriedTraps = {
            type: "FeatureCollection",
            features: []
        };

        for(const vistoriesTrap of trapMapInfo.vistories){
            allVistoriedTraps.features.push(CreatePoint([vistoriesTrap.longitude, vistoriesTrap.latitude], {eggs: vistoriesTrap.eggs}))
        }

        for(const group of territorializationList){
            var range = [99.99, 199.99];

            const cloropleticGroup = {
                name: group.name,
                Polygons: new Array()
            };

            let legends = [];
            for(const territorialization of group.territorializationList){
                if(territorialization.geometryType == "POLYGON"){
                    
                    const turfPolygon = CreatePolygon([territorialization.coordinates.map(({x, y}) => [x, y])])
                    const filteredVistoriedTraps = pointsWithinPolygon(allVistoriedTraps, turfPolygon).features

                    if(filteredVistoriedTraps.length > 0){
                        const eggsSum = filteredVistoriedTraps.reduce((acumulador, valorAtual) => {
                            return acumulador + valorAtual.properties.eggs
                        }, 0);

                        const ido =  eggsSum / filteredVistoriedTraps.length;
                        switch (trapTypeName) {
                            case "Ovitrampa":
                                range = [99.99, 199.99];
                                break;
                        
                            case "Adultrap":
                                range = [1, 3];
                                break;

                            default:
                                range = [19.99, 59.99];
                                break;
                        }

                        if(ido <= range[0]){
                            turfPolygon.color = "green";
                            
                        }else if( ido <= range[1]){
                            turfPolygon.color = "yellow";
                            
                        }else{
                            turfPolygon.color = "red";
                            
                        }
                    }else{
                        turfPolygon.color = "transparent";
                    }

                    cloropleticGroup.Polygons.push(turfPolygon)
                }
            }

            legends.push({
                firstItem: 4,
                lastItem: null,
                color: "red",
                text: range[1] + "+"
            });

            legends.push({
                firstItem: 1,
                lastItem: 3.99,
                color: "yellow",
                text: range[0] + " - " + range[1]
            });

            legends.push({
                firstItem: 0.0,
                lastItem: 0.99,
                color: "green",
                text: `0 - ` + range[0]
            });

            cloropleticGroup.legends = legends;

            cloropleticGroups.push(cloropleticGroup)
        }

        setCloropleticPolygonGroups(cloropleticGroups);
    }

    const getDataSets = () => {
        let inspections;
        let eggs;
        const dataset = [];
        let labelDayDataSet = "";

        for(const index in labels){
            const dataSetObject = {
                label: labels[index]
            }

            for(let ano in data){
                if(data[ano][index]){
                    eggs = data[ano][index].ovos;
                    inspections = trapData[ano][index].inspections;
                    let actives = trapData[ano][index].actives;

                    // (Armadilhas vistoriadas / Armadilhas instaladas) * 100
                    const vistoryToChart = actives > 0 ? parseFloat(((inspections / actives) * 100).toFixed(1)) : 0;

                    if(data[ano].label){
                        dataSetObject[data[ano].label] = vistoryToChart;
                    }else{
                        dataSetObject["IM"] = vistoryToChart;
                    }
                }
            }
            dataset.push(dataSetObject);

            labelDayDataSet = dataset[index].label;

            if (eggs === 0 && previousTrapMapInfo != undefined && index != 0){
                labelDayDataSet = dataset[index-1].label;
            }
            else if (labelDayDataSet == 53 && data[data.length - 1].length == 52){
                labelDayDataSet = dataset[index-1].label;
            }
        }

        setEggsEqualZero(eggs === 0);
        setLabelData(labelDayDataSet);
    }

    const checkData = async () => {
        const pointsGroupped = new Array();
        
        if ((trapMapInfo.vistories.length == 0 || eggsEqualZero) && previousTrapMapInfo != undefined){
            trapMapInfo.actives = previousTrapMapInfo.actives;
            trapMapInfo.vistories = previousTrapMapInfo.vistories;
        }

        if(trapMapInfo && trapMapInfo.actives !== undefined){
            const activeList = await buildGraficMapList(trapMapInfo.actives, "Armadilhas instaladas", "black");
            pointsGroupped.push(activeList);
        }        

        if(trapMapInfo && trapMapInfo.vistories !== undefined){
            const vistoriesList = await buildGraficMapList(trapMapInfo.vistories, "Armadilhas vistoriadas", "blue");
            if(vistoriesList.points.length > 0){
                setHeatMapPoints(vistoriesList.points)
            }

            pointsGroupped.push(vistoriesList);
        }

        buildCloropleticInfo();
        setPointsGroup(pointsGroupped);
    }

    return (
        <CloropleticMap
            PointsGroup={pointsGroup}
            PolygonsGroup = {CloropleticPolygonGroups}
            HeatMap
            QuantityLegend={
                <div>
                    Exibindo Dados da <br />
                    Semana {typeof(labelData) === "string" ? labelData.replace("Semana", "") : labelData.toString().replace("Semana", "")}
                </div>
            }
            PointsHeatMap={HeatMapPoints}
        />
    )
}

export default withBasicListGraficMap(PendentsVistoriedMapContainer);