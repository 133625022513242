import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";

const ProductivityFiltersWrapper = ({
  filtersValues,
  handleFilterChange,
  employees,
  teams,
  territorializations,
  handleFilterButtonClick,
}) => {
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
  };

  return (
    <>
      <Row className="mb-2">
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">
            Demanda <span style={{ color: "red" }}>(em construção)</span>
          </span>
          <Select
            styles={selectStyles}
            placeholder={"Selecione"}
            isClearable={true}
            options={[]}
          />
        </Col>
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Equipe</span>
          <Select
            styles={selectStyles}
            placeholder={"Selecione"}
            isClearable={true}
            options={teams}
            value={filtersValues.team}
            onChange={(e) =>
              handleFilterChange(
                "team",
                !e || e.length === 0
                  ? {
                      label: "Todos",
                      value: "00000000-0000-0000-0000-000000000000",
                    }
                  : e
              )
            }
          />
        </Col>
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Funcionário</span>
          <Select
            styles={selectStyles}
            isMulti={true}
            placeholder={"Selecione"}
            isClearable={true}
            options={employees}
            value={filtersValues.employee}
            onChange={(e) =>
              handleFilterChange("employees", !e || e.length === 0 ? [] : e)
            }
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">
            Armadilha <span style={{ color: "red" }}>(em construção)</span>
          </span>
          <Select
            styles={selectStyles}
            placeholder={"Selecione"}
            isClearable={true}
            options={[]}
          />
        </Col>
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Data (Período)</span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
            }}
          >
            <div style={{ width: "45%" }}>
              <input
                type="date"
                name="beginDate"
                id="beginDate"
                className="form-control"
                value={filtersValues.beginDate}
                onChange={({ target }) =>
                  handleFilterChange("beginDate", target.value)
                }
              />
            </div>
            <div
              className="h4 text-white"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span>Até</span>
            </div>
            <div style={{ width: "45%" }}>
              <input
                type="date"
                name="endDate"
                id="endDate"
                className="form-control"
                value={filtersValues.finalDate}
                onChange={({ target }) =>
                  handleFilterChange("finalDate", target.value)
                }
              />
            </div>
          </div>
        </Col>
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Territorialização</span>
          <Select
            styles={selectStyles}
            placeholder={"Selecione"}
            isClearable={true}
            options={territorializations}
            value={filtersValues.territorializations}
            isMulti={true}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            onChange={(e) =>
              handleFilterChange(
                "territorializations",
                !e || e.length === 0 ? [] : e
              )
            }
            menuPortalTarget={document.body}
          />
        </Col>
      </Row>
      <Row style={{paddingBottom: "2rem"}}>
        <Col className="col-md-4 visit-responsive-filter"></Col>
        <Col className="col-md-4 visit-responsive-filter">
          <Button color="primary" onClick={() => handleFilterButtonClick()}>
            Filtrar
          </Button>
        </Col>
        <Col className="col-md-4 visit-responsive-filter"></Col>
      </Row>
    </>
  );
};

export default ProductivityFiltersWrapper;
