import React from "react";
import "./Skeleton.css";

const Skeleton = ({ variant, height, width, padding }) => {
  const skeletonStyles = {
    animation: "skeletonAnimation 1.5s linear infinite",
    background:
      "linear-gradient(to right, #b1c8d1 0%, #cce1e9 50%, #b1c8d1 100%)",
    backgroundSize: "200% 100%",
    borderRadius: "4px",
    height: height,
    width: width,
  };

  const skeletonVariantToRender = {
    circle: <div style={{ ...skeletonStyles, borderRadius: "100%" }}></div>,
    rectangle: <div style={skeletonStyles}></div>,
  };

  return (
    <div
      style={{padding: padding ? padding : "10px",}}
    >
      {skeletonVariantToRender[variant]
        ? skeletonVariantToRender[variant]
        : skeletonVariantToRender["rectangle"]}
    </div>
  );
};

export default Skeleton;
