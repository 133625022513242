import React from "react";
import { Col, Row } from "reactstrap";
import { Select } from "../../../components/Wrappers/SelectAll";
import { fetchTerritorializations } from "../../../services/api/territorialization";
import { getUserData } from "../../../services/utils/auth";

const HeaderFormInputs = ({
  headerInputs,
  handleHeaderInputsChange,
  employeesOptions,
  organizationId,
}) => {
  const [uvisOptions, setUvisOptions] = React.useState(null);
  const [administrativeDistrictOptions, setAdministrativeDistrictOptions] =
    React.useState(null);
  const [selectedUvis, setSelectedUvis] = React.useState(null);

  React.useEffect(() => {
    fetchAllComponentData();
  }, []);

  const fetchAllComponentData = async () => {
    if (
      getUserData("organizationId") !== "559a16ab-5da8-41ba-96b8-6e9f55feddd8"
    )
      return;

    const uvisOptions = getTypeLayerTerritorializationsOptions(
      "553b3f0a-7e72-4e07-b170-ad042f595bc2"
    );
    const administrativeDistrictOptions =
      getTypeLayerTerritorializationsOptions(
        "f70ecdbb-d390-4155-9ab0-1d008de10784"
      );

    const [uvisData, administrativeDistrictData] = await Promise.all([
      uvisOptions,
      administrativeDistrictOptions,
    ]);

    setUvisOptions([
      {
        label: "Selecione a UVIS",
        value: "",
      },
      ...uvisData,
    ]);
    setAdministrativeDistrictOptions([
      {
        label: "Selecione o Distrito Administrativo",
        value: "",
      },
      ...administrativeDistrictData,
    ]);
  };

  const getTypeLayerTerritorializationsOptions = async (typeLayerId) => {
    try {
      const { data, status } = await fetchTerritorializations(typeLayerId);
      if (status !== 200)
        throw new Error("Ocorreu um erro ao buscar pelos dados do Layer");

      const formatedTerritorializationsOptions = data.map(({ id, name }) => {
        return {
          label: name,
          value: id,
        };
      });

      formatedTerritorializationsOptions.sort((a, b) => {
        if (a.label < b.label) return -1;

        if (a.label > b.label) return 1;

        return 0;
      });

      return formatedTerritorializationsOptions;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectedTeamsAndEmployeesChange = (e) => {
    if (e === null) e = [];

    handleHeaderInputsChange("teamsAndEmployees", e);
  };

  // const filteredEmployeesOptions = selectedUvis
  //   ? employeesOptions.filter((employee) => employee.uvis === selectedUvis)
  //   : employeesOptions;

  return (
    <>
      <Row className="mt-4">
        {organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8" && (
          <Col className="col-3">
            <div>
              <span className="h5 text-white">
                (*) Região do Monitoramento (Nome da UVIS):
              </span>
              <Select
                onChange={(e) => {
                  handleHeaderInputsChange("uvisName", e);
                  //setSelectedUvis(e.label);
                }}
                value={headerInputs.uvisName}
                options={uvisOptions ? uvisOptions : []}
                placeholder="Selecione a UVIS"
                isMulti={false}
                allowSelectAll={false}
                blurInputOnSelect={true}
                closeMenuOnSelect={false}
                isClearable={false}
              />
            </div>
          </Col>
        )}
        {organizationId === "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30" && (
          <Col className="col-3">
            <div>
              <span className="h5 text-white">Distrito Sanitário:</span>
              <input
                type="text"
                className="form-control"
                placeholder="Informe o Distrito Sanitário"
                name="sanitaryDistrict"
                value={headerInputs.sanitaryDistrict}
                onChange={({ target }) =>
                  handleHeaderInputsChange("sanitaryDistrict", target.value)
                }
              />
            </div>
          </Col>
        )}
        {organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8" && (
          <Col className="col-3">
            <div>
              <span className="h5 text-white">(*) Distrito Administrativo</span>
              <Select
                onChange={(e) =>
                  handleHeaderInputsChange("administrativeDistrict", e)
                }
                value={headerInputs.administrativeDistrict}
                options={
                  administrativeDistrictOptions
                    ? administrativeDistrictOptions
                    : []
                }
                placeholder="Selecione o Distrito Administrativo"
                isMulti={false}
                allowSelectAll={false}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                isClearable={false}
              />
            </div>
          </Col>
        )}
        {organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8" && (
          <Col className="col-3">
            <div>
              <span className="h5 text-white">Setor Ambiental</span>
              <input
                type="text"
                className="form-control"
                placeholder="Informe o código do Setor Ambiental"
                name="environmentalSector"
                value={headerInputs.environmentalSector}
                onChange={({ target }) =>
                  handleHeaderInputsChange("environmentalSector", target.value)
                }
              />
            </div>
          </Col>
        )}
        {organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8" && (
          <Col className="col-3">
            <div>
              <span className="h5 text-white">Quadra</span>
              <input
                type="text"
                className="form-control"
                placeholder="Informe o código da Quadra"
                name="block"
                value={headerInputs.block}
                onChange={({ target }) =>
                  handleHeaderInputsChange("block", target.value)
                }
              />
            </div>
          </Col>
        )}
        {organizationId !== "559a16ab-5da8-41ba-96b8-6e9f55feddd8" && (
          <Col className="col-3">
            <div>
              <span className="h5 text-white">Quarteirão</span>
              <input
                type="text"
                className="form-control"
                placeholder="Informe o Quarteirão"
                name="block"
                value={headerInputs.block}
                onChange={({ target }) =>
                  handleHeaderInputsChange("block", target.value)
                }
              />
            </div>
          </Col>
        )}
        <Col className="col-3">
          <div>
            <span className="h5 text-white">(*) Funcionários</span>
            <Select
              onChange={(e) => handleSelectedTeamsAndEmployeesChange(e)}
              value={headerInputs.teamsAndEmployees}
              //options={filteredEmployeesOptions ? filteredEmployeesOptions : []} // Usa a lista filtrada
              options={employeesOptions ? employeesOptions : []}
              placeholder="Selecione os funcionários da atividade"
              isMulti={true}
              allowSelectAll={false}
              blurInputOnSelect={false}
              closeMenuOnSelect={false}
              isClearable={true}
            />
          </div>
        </Col>
        <Col className="col-3">
          <div>
            <span className="h5 text-white">(*) Data da atividade</span>
            <input
              type="datetime-local"
              className="form-control"
              placeholder="Equipe de Monitoramento"
              name="activityDate"
              value={headerInputs.activityDate}
              onChange={({ target }) =>
                handleHeaderInputsChange("activityDate", target.value)
              }
              max={new Date().toISOString().slice(0, 16)}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="col-3 ">
          <h2 style={{ color: "white", fontSize: "14px" }}>
            Os campos com a sinalização (*) são OBRIGATÓRIOS
          </h2>
        </Col>
      </Row>
    </>
  );
};

export default HeaderFormInputs;
