import React from "react";

import { LayersControl, Map, Marker, TileLayer } from "react-leaflet";
import Leaflet from "leaflet";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { GOOGLE_MAPS_API_KEY } from "../../constants/WebFormConstants";
import { toast } from "react-toastify";

const ConfirmTrapPositionModal = ({
  isOpen,
  toggleModal,
  closeModal,
  trapInformations,
  setUpdatedPosition,
}) => {
  const [mapZoom, setMapZoom] = React.useState(16);

  const mapRef = React.useRef(null);

  React.useEffect(() => {
    const map = mapRef.current ? mapRef.current.leafletElement : null;

    map &&
      map.on("zoomend", () => {
        setMapZoom(map.getZoom());
      });
  }, []);

  return (
    <>
      <Modal
        size="lg"
        fade
        isOpen={isOpen}
        toggle={() => toggleModal()}
        onClosed={() => closeModal()}
      >
        <ModalHeader>
          <h3>Alterar posição da armadilha</h3>
        </ModalHeader>
        <ModalBody>
          <p style={{textAlign: "center"}}>Caso queira alterar as coordenadas de instalação da armadilha, arraste o ícone para a posição correta e clique em "Fechar"</p>
          {trapInformations && (
            <Map
              center={[trapInformations.latitude, trapInformations.longitude]}
              zoom={mapZoom}
              ref={mapRef}
            >
              <LayersControl>
                <LayersControl.BaseLayer checked name="Normal">
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Satélite">
                  <ReactLeafletGoogleLayer
                    googleMapsLoaderConf={{ KEY: GOOGLE_MAPS_API_KEY }}
                    type={"hybrid"}
                  />
                </LayersControl.BaseLayer>
              </LayersControl>
              <DraggableMarker
                initalPosition={[
                  trapInformations.latitude,
                  trapInformations.longitude,
                ]}
                trapInformations={trapInformations}
                setPosition={setUpdatedPosition}
              />
            </Map>
          )}
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col>
            <button
                className="btn btn-primary"
                onClick={() => {
                  toast.info("Posição da armadilha salva com sucesso.")
                }}
                style={{ cursor: "pointer" }}
              >
                SALVAR
              </button>
            </Col>
            <Col>
            <button
                className="btn btn-secondary"
                onClick={() => toggleModal()}
                style={{ cursor: "pointer" }}
              >
                FECHAR
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  );
};

const DraggableMarker = ({ initalPosition, trapInformations, setPosition }) => {
  const [actualPosition, setActualPosition] = React.useState(initalPosition);

  const markerRef = React.useRef(null);

  const handleMarkerOnDragEnd = (target) => {
    if (!target) return;

    const newLatitude = target._latlng.lat.toFixed(7);
    const newLongitude = target._latlng.lng.toFixed(7);

    setPosition("trapLatitude", newLatitude, trapInformations.rowIndex);
    setPosition("trapLongitude", newLongitude, trapInformations.rowIndex);
    setActualPosition([newLatitude, newLongitude]);
  };

  const trapIcon = Leaflet.icon({
    iconUrl: "https://i.ibb.co/q09c9H7/g-int2care.png",
    iconSize: [20, 20],
  });

  return (
    <Marker
      draggable={true}
      ondragend={({ target }) => handleMarkerOnDragEnd(target)}
      position={actualPosition}
      ref={markerRef}
      icon={trapIcon}
    />
  );
};

export default ConfirmTrapPositionModal;
