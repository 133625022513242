import React from "react";
import "./../../assets/css/navbar.css";
import {getUserData} from "../../services/utils/auth";
import {getTraps, getAllTrapHistory} from "../../services/api/Trap";
// reactstrap components
import {
    Container,
} from "reactstrap";
import { getVisitsForReport } from ".../../services/api/Visits";
import { getTeam } from "../../services/api/Team";
import {fetchDemands} from "../../services/api/Demand";
import { fetchEmployeeData } from "../../services/api/Employee";
import { fetchUsers } from "../../services/api/Users";
import { fetchResidents } from "../../services/api/Covid";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "../../assets/css/style.css";
class FilterTrapReport extends React.Component {

    state = {
        filterName:"",
        filteredTrap2 :this.props.Traps,
        Identifications: [],
        trapSampleResults: [],
        VisitsFiltered:this.props.Visits,
        Teams:null,
        Demands:null,
        Collectors:null,
        CollectorLoaded:false,
        filter:{
            startDate:null,
            endDate:null,
            startVisitDate:null,
            endVisitDate:null,
            trapStatusType:"",
            organizationId:getUserData("organizationId"),
            InstallerId:"00000000-0000-0000-0000-000000000000",
            TrapTypeId:"00000000-0000-0000-0000-000000000000",
            identificationId: "00000000-0000-0000-0000-000000000000",
        },
        filterVisit:{
            organizationId:getUserData("organizationId"),
            collectorId: "00000000-0000-0000-0000-000000000000",
            teamId: "00000000-0000-0000-0000-000000000000",
            demandId: "00000000-0000-0000-0000-000000000000"
        },
        filterResidents:{
            onlySymptomatics:false,
            typeReport:"",
            startDate:null,
            endDate:null,
            typeForm:"",
            organizationId:getUserData("organizationId"),
            TeamId:"00000000-0000-0000-0000-000000000000",
            CollectorId:"00000000-0000-0000-0000-000000000000"
        }
    }
    constructor (props){
        super(props);
        let requestTeam = getTeam()
        requestTeam.then(response => {
            this.setState({
                Teams: response.data
            });
        });

        let DemandsRequest = fetchDemands();       
        DemandsRequest.then(response => {
            this.setState({Demands : response.data});
        });


        var userrequest = fetchUsers();
        userrequest.then(response => {
            response.data.map(user =>{
                var employeerequest = fetchEmployeeData(user.employeeId);
                employeerequest.then(employee => {
                    user.personName = employee.data.name;
                    user.collectedBy = employee.data.id;
                    this.setState({Collectors : response.data,CollectorLoaded : true});
                })
            })
       })
    }
    componentDidMount(){
       
    }

    handleChange = e => {
        let {name,value} = e.target;
        this.setState({
            [name]: value
        });
      };
    applyFilter = async () => {
        let traps = await this.fetchTraps();
        let trapsHistory = await this.fetchTrapHistory();

        let filteredArray = {
            traps:traps,
            trapsHistory: trapsHistory,
        }

        this.props.parentCallback(filteredArray);

        
        var visitsForReportRequest = getVisitsForReport(this.state.filterVisit);
        visitsForReportRequest.then(response=>{
          
          var visits = [];
          response.data.map(visit =>{
            visits.push({date:visit.activityDate,situation:visit.visitSituation,
                            position:[visit.latitude,visit.longitude]})
          });
          this.setState({VisitsFiltered:visits});
          this.props.parentCallbackVisit(this.state.VisitsFiltered);
        });
        const ResidentRequest = fetchResidents(this.state.filterResidents);
        ResidentRequest.then(response => {
            var residents = [];
            response.data.map(resident =>{
              residents.push({data:resident,date:resident.activityDate,
                              position:[resident.latitude,resident.longitude]})
            });  
            this.setState({ResidentsFiltered : residents});
            this.props.parentCallbackResident(this.state.ResidentsFiltered);
            if(response) {
                return response.data
            }
            throw new Error("Erro ao contatar servidor");
          });
        
    }

    fetchTraps = async () => {
        let traps = [];
        const Request = getTraps(this.state.filter)
        await Request.then(response => {
            if(response) {
                return response.data
            }
            throw new Error("Erro ao contatar servidor");
        }).then(data => {
            let filteredTrap = data.filter(
                (Trap)=>{
                    return Trap.trapTypeName.toLowerCase().indexOf(
                        this.state.filterName.toLowerCase()) !== -1;
                }
            );

            traps = filteredTrap;
            
        });

        return traps;
    }

    fetchTrapHistory = async () => {
        let trapsHistory = [];
        const request = getAllTrapHistory(this.state.filter);
        await request.then((response) => {
          if(response){
            return response.data
          }
        }).then((results) => {
          trapsHistory = results;
        });
  
        return trapsHistory;
      }
    
    render() {
        
        return (
            <div className="header  pt-5 pb-1" style={{"background" : "linear-gradient(87deg, #336666 0, #336666 100%)"}}>
                <Tabs style={{'margin-top' : '1%'}} >
                    <TabList>
                        <Tab className='btn btn-primary' style={{'width' : '200px', 'marginLeft' : '1%'}}>Armadilhas</Tab>
                        <Tab className='btn btn-primary' style={{'width' : '200px'}}>Visitas</Tab>
                        <Tab className='btn btn-primary' style={{'width' : '200px'}}>Residentes</Tab>
                    </TabList>

                    <TabPanel style={{'margin' : '1%'}}>
                        <div className="row">
                            <div className="col-4">
                                <span className="h5 text-white">DATA DE INÍCIO DE INSTALAÇÃO</span>                                
                                <input type="date" className="form-control"onChange ={e=>this.state.filter.startDate = e.target.value} name="" id="" placeholder="Período de início" />                                
                            </div>
                            <div className="col-4">
                                <span className="h5 text-white">DATA DE TÉRMINO DE INSTALAÇÃO</span> 
                                <input type="date" className="form-control" onChange ={e=>this.state.filter.endDate = e.target.value}name="" id="" placeholder="Período de término" />
                            </div>
                            
                                <div className="col-4">
                                <span className="h5 text-white">Tipo de armadilha</span> 
                                <select className="form-control" defaultValue={"00000000-0000-0000-0000-000000000000"} onChange ={e=>this.state.filter.TrapTypeId = e.target.value}name="" id="">
                                    <option  value={"00000000-0000-0000-0000-000000000000"}>{"Todos"}</option>
                                    {this.props.TrapTypes.map((type) => {
                                        return (
                                            <option value={type.id}>{type.name}</option>
                                        )
                                    })}      
                                </select>
                            
                            </div> 

                            {/*} <div className="col-4">
                                <span className="h5 text-white">Armadilhas com presença de </span> 
                                <select className="form-control" defaultValue={""} onChange ={e=>this.state.filter.identificationId = e.target.value}name="" id="">
                                    <option value={""} >Selecione</option>
                                    {this.props.Identifications.map((type) => {
                                        return (
                                            <option value={type.id}>{type.name}</option>
                                        )
                                    })}      
                                </select>
                            
                            </div> */}
                            
                                        
                        </div>
                        <div className="row">
                                <div className="col-4">
                                    <span className="h5 text-white">DATA DE INÍCIO DE VISITAS</span>                                
                                    <input type="date" className="form-control"onChange ={e=>this.state.filter.startVisitDate = e.target.value} name="" id="" placeholder="Período de início" />                                
                                </div>
                                <div className="col-4">
                                    <span className="h5 text-white">DATA DE TÉRMINO DE VISITAS</span> 
                                    <input type="date" className="form-control" onChange ={e=>this.state.filter.endVisitDate = e.target.value}name="" id="" placeholder="Período de término" />
                                </div>
                                <div className="col-4">
                                    <span className="h5 text-white">Situação de armadilha</span> 
                                    <select className="form-control" defaultValue={""} onChange ={e=>this.state.filter.trapStatusType = e.target.value}name="" id="">
                                        <option  value={""}>{"Todas"}</option>
                                        <option  value={"Installed"}>{"Instaladas"}</option>
                                        <option  value={"Reads"}>{"Lidas"}</option>
                                        <option  value={"Removed"}>{"Removidas"}</option> 
                                    </select>
                                </div>
                        </div>
                    </TabPanel>
                    <TabPanel style={{'margin' : '1%'}}>
                        <div className="row">
                            <div className="col-4">
                                <span className="h5 text-white">Demandas</span> 
                                <select className="form-control" defaultValue={"00000000-0000-0000-0000-000000000000"} onChange ={e=>this.state.filterVisit.demandId = e.target.value}name="" id="">
                                    <option  value={"00000000-0000-0000-0000-000000000000"}>{"Todos"}</option>
                                    {this.state.Demands!=null?this.state.Demands.map((Demand) => {
                                        return (
                                            <option value={Demand.id}>{Demand.serviceOrder}</option>
                                        )
                                    }):""}      
                                </select>
                            </div>
                            <div className="col-4">
                                <span className="h5 text-white">Equipes</span> 
                                <select className="form-control" defaultValue={"00000000-0000-0000-0000-000000000000"} onChange ={e=>this.state.filterVisit.teamId = e.target.value}name="" id="">
                                    <option  value={"00000000-0000-0000-0000-000000000000"}>{"Todos"}</option>
                                    {this.state.Teams!=null?this.state.Teams.map((team) => {
                                        return (
                                            <option value={team.id}>{team.name}</option>
                                        )
                                    }):""}      
                                </select>
                            </div>
                            
                            <div className="col-4">
                        
                            {this.state.CollectorLoaded?<>
                                <span className="h5 text-white">Funcionário</span> 
                                <select className="form-control" defaultValue={"00000000-0000-0000-0000-000000000000"} onChange ={e=>this.state.filterVisit.collectorId = e.target.value}name="" id="">
                                    <option  value={"00000000-0000-0000-0000-000000000000"}>{"Todos"}</option>
                                    {this.state.Collectors!=null?this.state.Collectors.map((Collector) => {
    
                                        return (
                                            <option value={Collector.id}>{Collector.personName}</option>
                                        )
                                    }):""}      
                                </select></>:""}
                            </div>
                        </div>
                        
                    </TabPanel>
                    <TabPanel style={{'margin' : '1%'}}>
                        <div className="row">
                            <div className="col-4">
                                <span className="h5 text-white">DATA DE INÍCIO</span>                                
                                <input type="date" className="form-control"onChange ={e=>this.state.filterResidents.startDate = e.target.value} name="" id="" placeholder="Período de início" />                                
                            </div>
                            <div className="col-4">
                                <span className="h5 text-white">DATA DE TÉRMINO</span> 
                                <input type="date" className="form-control" onChange ={e=>this.state.filterResidents.endDate = e.target.value}name="" id="" placeholder="Período de término" />
                            </div>
                            <div className="col-4">
                                <span className="h5 text-white">Somente Sintomáticos?</span> 
                                <select className="form-control" onChange ={e=>this.state.filterResidents.onlySymptomatics = e.target.value}name="" id="">
                                    <option value={true}>Sim</option>
                                    <option value={false} selected>Não</option>
                                </select>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
              
                    
                    <div className="row">
                        <div className="col-4 mt-4" style={{'margin' : '1%'}}>
                            <button  onClick={()=>this.applyFilter()} className="btn btn-primary">Pesquisar</button>
                        </div> 

                    </div>
                   
           
            </div>
        );

    }

}

export default FilterTrapReport;
