import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import PgsWeb from "./PgsWeb";
import PgsApp from "./PgsApp";

const Pgs = React.FC = () => {
    const [pgsWeb, setPgsWeb] = useState(false);
    const [pgsApp, setPgsApp] = useState(false);

    return (
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
            <Row style={{padding: "1rem"}}>
                <Col className="col-6 linha-form col-md-6">
                    <button className="btn btn-primary" onClick={() => {setPgsApp(false); setPgsWeb(true);}}>
                        <i className="fas fa-cog" />
                        <span style={{marginLeft: "5px"}} >Parâmetros Web</span>

                    </button>
                </Col>
                <Col className="col-6 linha-form col-md-6">
                    <button className="btn btn-primary" onClick={() => {setPgsApp(true); setPgsWeb(false);}}> 
                        <i className="fas fa-cog" />
                        <span style={{marginLeft: "5px"}} >Parâmetros App</span>
                    </button>
                </Col>
            </Row>
            <Row style={{padding: "1rem"}}>
                <Col>
                    <Col className="col-12 linha-form col-md-12">
                            {
                                pgsWeb
                                ?
                                <PgsWeb/>
                                :
                                <></>
                            }
                            {
                                pgsApp
                                ?
                                <PgsApp/>
                                :
                                <></>
                            }
                    </Col>
                </Col>
            </Row>
        </div>
    )
}

export default Pgs;