import moment from "moment";
import { ARMADILHA_INFO } from "../../constants/ArmadilhaConstant";

export const formatTrapDataFromVisitsToExport = (
  visit,
  demandNumber,
  trapType,
  index,
  employeesList
) => {
  if (
    !visit ||
    !demandNumber ||
    !trapType ||
    !employeesList ||
    employeesList.length === 0
  )
    return;
  if (
    !visit.armadilha[trapType] ||
    !visit.armadilha[trapType][index].numeroArmadilha ||
    visit.armadilha[trapType][index].numeroArmadilha === ""
  )
    return;

  const trap = visit.armadilha[trapType][index];

  if (trap.numeroArmadilha !== "" || trap.numeroArmadilha !== null) {
    const formatedTrap = {
      ...formatAddressData(visit, demandNumber),
      NUMERO_ARMADILHA: trap.numeroArmadilha,
      TIPO_ARMADILHA: ARMADILHA_INFO[trapType]
        ? ARMADILHA_INFO[trapType].apelido
        : "Não encontrado",
      LATITUDE: trap.latitude,
      LONGITUDE: trap.longitude,
      ARMADILHA_INSTALADA: trap.armadilhaInstaladaNaVisita ? "Sim" : "Não",
      ARMADILHA_LIDA: trap.armadilhaLidaNaVisita ? "Sim" : "Não",
      ARMADILHA_DESINSTALADA: trap.armadilhaRetirada ? "Sim" : "Não",
      ARMADILHA_DESINSTALADA_MOTIVO:
        trap.armadilhaRetiradaMotivo === "" || !trap.armadilhaRetiradaMotivo
          ? "Não informado"
          : trap.armadilhaRetiradaMotivo,
      ARMADILHA_EM_CONDICOES_USO: trap.isReadableCondition ? "Sim" : "Não",
      ARMADILHA_SEM_CONDICOES_USO_MOTIVO:
        trap.readableConditionMotive === "" || !trap.readableConditionMotive
          ? "Não informado"
          : trap.readableConditionMotive,
      ESTADO_ETIQUETA:
        trap.estadoEtiqueta === "" || !trap.estadoEtiqueta
          ? "Não informado"
          : trap.readableConditionMotive,
      PREDADORES: generateTrapPredatorsText(trap),
      MANUTENCOES_REALIZADA: generateTrapMaintenancesText(trap),
      DEFEITOS: generateTrapDefectsText(trap),
      PECAS_TROCADA: generateTrapDefectsText(trap),
      EMERGENCIA_MOSQUITOS: generateMosquitoesEmergencyText(trap),
      ENCONTROU_LARVAS: !trap.resultadoCampoAmostras
        ? "Não informado"
        : !trap.resultadoCampoAmostras.larva
        ? "Não"
        : "Sim",
      QTD_LARVAS_VIVAS:
        !trap.resultadoCampoAmostras ||
        !trap.resultadoCampoAmostras.larvaEstimativaVivas
          ? "Não informado"
          : trap.resultadoCampoAmostras.larvaEstimativaVivas.estimativa,
      QTD_LARVAS_MORTAS:
        !trap.resultadoCampoAmostras ||
        !trap.resultadoCampoAmostras.larvaEstimativaMortas
          ? "Não informado"
          : trap.resultadoCampoAmostras.larvaEstimativaMortas.estimativa,
      ENCONTROU_PUPAS: !trap.resultadoCampoAmostras
        ? "Não informado"
        : !trap.resultadoCampoAmostras.pupas
        ? "Não"
        : "Sim",
      QTD_PUPAS_VIVAS:
        !trap.resultadoCampoAmostras ||
        !trap.resultadoCampoAmostras.pupaEstimativaVivas
          ? "Não informado"
          : trap.resultadoCampoAmostras.pupaEstimativaVivas.estimativa,
      QTD_PUPAS_MORTAS:
        !trap.resultadoCampoAmostras ||
        !trap.resultadoCampoAmostras.pupaEstimativaMortas
          ? "Não informado"
          : trap.resultadoCampoAmostras.pupaEstimativaMortas.estimativa,
      ENCONTROU_MOSQUITOS:
        trap.resultadoCampoAmostras &&
        trap.resultadoCampoAmostras.aladosEstimativaVivas &&
        trap.resultadoCampoAmostras.aladosEstimativaVivas.valor !== 0
          ? "Sim"
          : "Não",
      QTD_MOSQUITOS_VIVOS:
        trap.resultadoCampoAmostras &&
        trap.resultadoCampoAmostras.aladosEstimativaVivas &&
        trap.resultadoCampoAmostras.aladosEstimativaVivas.estimativa
          ? trap.resultadoCampoAmostras.aladosEstimativaVivas.estimativa
          : "",
      QTD_MOSQUITOS_MORTOS:
        trap.resultadoCampoAmostras &&
        trap.resultadoCampoAmostras.aladosEstimativaMortas &&
        trap.resultadoCampoAmostras.aladosEstimativaMortas.estimativa
          ? trap.resultadoCampoAmostras.aladosEstimativaMortas.estimativa
          : "",
      ENCONTROU_OUTROS_MOSQUITOS:
        trap.resultadoCampoAmostras &&
        trap.resultadoCampoAmostras.outrosMosquitos
          ? "Sim"
          : "Não",
      QTD_OUTROS_MOSQUITOS:
        trap.resultadoCampoAmostras &&
        trap.resultadoCampoAmostras.outrosMosquitosDados &&
        trap.resultadoCampoAmostras.outrosMosquitosDados.quantidade
          ? trap.resultadoCampoAmostras.outrosMosquitosDados.quantidade
          : "",
      ENCONTROU_AEDES_AEGYPTI:
        trap.resultadoCampoAmostras && trap.resultadoCampoAmostras.aedesAegypti
          ? "Sim"
          : "Não",
      QTD_AEDES_AEGYPTI:
        trap.resultadoCampoAmostras &&
        trap.resultadoCampoAmostras.aedesAegyptiDados &&
        trap.resultadoCampoAmostras.aedesAegyptiDados.quantidade
          ? trap.resultadoCampoAmostras.aedesAegyptiDados.quantidade
          : "",
      ENCONTROU_AEDES_ALBOPICTUS:
        trap.resultadoCampoAmostras &&
        trap.resultadoCampoAmostras.aedesAlbopictus
          ? "Sim"
          : "Não",
      QTD_AEDES_ALBOPICTUS:
        trap.resultadoCampoAmostras &&
        trap.resultadoCampoAmostras.aedesAlbopictusDados &&
        trap.resultadoCampoAmostras.aedesAlbopictusDados.quantidade
          ? trap.resultadoCampoAmostras.aedesAlbopictusDados.quantidade
          : "",
      GERADAS_AMOSTRAS: trap.amostras ? "Sim" : "Não",
      DADOS_AMOSTRAS: trap.amostras
        ? generateSamplesText(trap.amostrasColetadas)
        : "Amostras não coletadas",
    };

    return formatedTrap;
  }
};

const formatAddressData = (visit, demandNumber, employeesList) => {
  if (!visit || !demandNumber) return;

  const getUserName = (id) => {
    if (
      id === "00000000-0000-0000-0000-000000000000" ||
      id === null ||
      id === undefined ||
      !employeesList ||
      employeesList.length === 0
    )
      return "Não encontrado";

    const userData = employeesList.find(
      (employee) => employee.employeeId === id || employee.userId === id
    );

    return userData ? userData.employeeName : "Não encontrado";
  };

  return {
    VISITA_ID:
      visit.vigilanciaId === "" || !visit.vigilanciaId
        ? "VisitId not found"
        : visit.vigilanciaId,
    NUMERO_DEMANDA: demandNumber,
    DATA_ATIVIDADE: moment(visit.activityDate).format("DD/MM/YYYY"),
    HORA_INICIO:
      visit.initialTime === "" || !visit.initialTime
        ? "Não obtida"
        : moment(visit.initialTime).add(3, "hours").format("HH:mm"),
    HORA_FIM:
      visit.finalTime === "" || !visit.finalTime
        ? "Não obtida"
        : moment(visit.finalTime).add(3, "hours").format("HH:mm"),
    AGENTE: getUserName(visit.collectorId),
    QUARTEIRAO:
      visit.address.quadra === "" || !visit.address.quadra
        ? "Näo informado"
        : visit.address.quadra,
    LOGRADOURO: `${visit.address.localDoImovel} ${visit.address.logradouro}`,
    NUMERO: visit.address.numero,
    TIPO_IMOVEL: visit.address.tipoImovel,
    TIPO_RUA: visit.address.localDoImovel,
    FORMULARIO: visit.typeForm,
    NOME_RESPONSAVEL:
      !visit.residents ||
      visit.residents.dadosPrincipais.name === "" ||
      !visit.residents.dadosPrincipais.name
        ? "Não informado"
        : visit.residents.dadosPrincipais.name,
    NUMERO_RESPONSAVEL:
      !visit.residents ||
      visit.residents.dadosPrincipais.phone === "" ||
      !visit.residents.dadosPrincipais.phone
        ? "Não informado"
        : visit.residents.dadosPrincipais.phone,
    LATITUDE: visit.latitude,
    LONGITUDE: visit.longitude,
    SITUACAO_VISITA: visit.visitSituation,
  };
};

const generateTrapPredatorsText = (trap) => {
  if (!trap) return;

  const predators = [];

  if (trap.aranha) predators.push("Aranha");
  if (trap.formiga) predators.push("Formiga");
  if (trap.lagartixa) predators.push("Lagartixa");
  if (
    trap.outroPredadorDescrever !== "" ||
    trap.outroPredadorDescrever !== null
  )
    predators.push(trap.outroPredadorDescrever);

  if (predators.length === 0) return "Não informado";

  return predators.join(", ");
};

const generateTrapMaintenancesText = (trap) => {
  if (!trap) return;

  if (!trap.manutencao.manutencao) return "Não realizada";

  const maintenances = [];

  Object.keys(trap.manutencao).forEach((maintenance) => {
    if (
      trap.manutencao[maintenance] &&
      maintenance !== "defeito" &&
      maintenance !== "defeitoTroca" &&
      maintenance !== "manutencao"
    )
      maintenances.push(maintenance);
  });

  return maintenances.join(", ");
};

const generateTrapDefectsText = (trap) => {
  if (!trap) return;

  if (
    trap.manutencao.defeito === false ||
    !trap.manutencao.defeito ||
    !trap.manutencao.defeito.defeito
  )
    return "Não informado";

  const defects = [];

  Object.keys(trap.manutencao.defeito).forEach((defect) => {
    if (trap.manutencao.defeito[defect]) defects.push(defect);
  });

  return defects.join(", ");
};

const generateMosquitoesEmergencyText = (trap) => {
  if (
    !trap ||
    !trap.emergenciaMosquitoArmadilha ||
    trap.emergenciaMosquitoArmadilha === ""
  )
    return "Não informado";

  const defects = [];

  Object.keys(trap.emergenciaMosquitoArmadilha).forEach((defect) => {
    if (trap.emergenciaMosquitoArmadilha[defect]) defects.push(defect);
  });

  if (defects.length === 0) return "Não informado";

  return defects.join(", ");
};

const generateSamplesText = (samples) => {
  if (!samples || samples.length === 0) return;

  const samplesData = [];

  samples.forEach((sample) => {
    const sampleType = sample.tipoDeAmostra;
    const sampleNumber =
      sample.numeroDaAmostra === "" ? "Não informado" : sample.numeroDaAmostra;
    const sampleQuantity =
      sample.quantidade === "" ? "Não informado" : sample.quantidade;

    const sampleText = `Tipo: ${sampleType} / Número: ${sampleNumber} / Quantidade: ${sampleQuantity}`;

    samplesData.push(sampleText);
  });

  return samplesData.join(", ");
};
