import React, {Component} from "react";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import CardPresentational from "../../../Presentational/CardPresentational";

class TotalOvosColetadosContainer extends Component {
    state = {
        eggQuantity:0,
    }

    async componentDidUpdate(prevProps, prevState){
        const {data} = this.props; 
        if(data !== undefined && data !== prevProps.data){
            this.buildDataToCard();
        }
    }

    componentDidMount(){
        const {data} = this.props;
        if(data !== undefined && data.length > 0){
            this.buildDataToCard();
        }
    }

    buildDataToCard = () => {
        const {data} = this.props;
        let eggQuantity = 0;

        for(const j in data){
            for(const i in data[j]){
                if(i !== "label"){
                    eggQuantity += data[j][i].ovos;
                }
            }
        }
            
        this.setState({eggQuantity});
        
    }

    render(){
        //construindo propriedades do presentational
        const {eggQuantity} = this.state;
        let textCard = `Total de ${ARMADILHA_INFO[this.props.trapTypeName].principalColetant}`;
        
        return(
            <CardPresentational 
                number={eggQuantity}
                text={textCard}
            />
        );
    }
}

export default TotalOvosColetadosContainer;