import axios from 'axios';
import { getToken } from '../../utils/auth';

const service = axios.create({
  baseURL: 'http://localhost:5001/api/',
  timeout: 1000000, 
});

service.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + getToken(); 
    }

    return config;
  },
  error => {
    console.log(error);
    Promise.reject(error);
  }
);

export default service;
