import React from "react";

import "./InstallFormPrint.styles.css";

const InstallFormPrint = React.forwardRef(
  ({ organizationId, trapTypeName }, ref) => {
    const getFormLogoInformations = {
      "559a16ab-5da8-41ba-96b8-6e9f55feddd8": {
        path: "https://i.ibb.co/0MT2jbZ/sp.png",
        styles: { width: "150px" },
      },
      "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30": {
        path: "https://i.ibb.co/m014LrJ/goiania.png",
        styles: { width: "150px" },
      },
      "24d125ef-15e6-4bd9-9111-c0b3d50a8009": {
        path: "https://i.ibb.co/W6PfRCG/cotia-logo.png",
        styles: { width: "150px" },
      },
      "d67ef2b0-92b1-40bf-aba6-0e06070e6091": {
        path: "https://i.ibb.co/wdRDctz/dracena-logo.png",
        styles: { width: "90px" },
      },
      "4d4b0460-c8de-474a-9bb9-44e2ffa272b6": {
        path: "https://i.ibb.co/cx4yhw3/ribeirao-pires-logo.png",
        styles: { width: "80px" },
      },
    };

    const logoInformations = getFormLogoInformations[organizationId];

    return (
      <section className="document--container--install" ref={ref}>
        <header className="document__header--install">
          <div className="document__header--company--install">
            <p>FORMULÁRIO DE INSTALAÇÃO DE {trapTypeName}</p>
            <div>
              <img
                src={logoInformations ? logoInformations.path : ""}
                style={logoInformations ? logoInformations.styles : {}}
                alt="Logo do município"
              />
            </div>
          </div>
          <div className="document__header--data--install">
            <table className="document__table--install">
              <tbody>
                <tr>
                  <td colSpan={2} className="bold">
                    TIPOS DE IMÓVEIS
                  </td>
                </tr>
                <tr>
                  <td>R - Residência</td>
                  <td>C - Comércio</td>
                </tr>
                <tr>
                  <td>TR - Terreno Baldio</td>
                  <td>PE - Ponto Estratégico</td>
                </tr>
                <tr>
                  <td>PP - Próprio Público</td>
                  <td>IE - Imóvel Especial</td>
                </tr>
                <tr>
                  <td>VP - Via Pública</td>
                  <td>E - Escola</td>
                </tr>
                <tr>
                  <td>US - Unidade de Saúde</td>
                  <td>O - Outros</td>
                </tr>
              </tbody>
            </table>
            <table className="document__table--install document__table--employees--install">
              <tbody>
                <tr>
                  <td colSpan={2} className="bold">
                    SERVIDOR DE SAÚDE
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Matrícula:</span>
                    <span>Nome:</span>
                  </td>
                  <td>
                    <span>Matrícula:</span>
                    <span>Nome:</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Matrícula:</span>
                    <span>Nome:</span>
                  </td>
                  <td>
                    <span>Matrícula:</span>
                    <span>Nome:</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Matrícula:</span>
                    <span>Nome:</span>
                  </td>
                  <td>
                    <span>Matrícula:</span>
                    <span>Nome:</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="document__table--install">
              <tbody>
                <tr>
                  <td colSpan={2} className="bold">
                    DATA DA ATIVIDADE
                  </td>
                </tr>
                <tr>
                  <td>_____/_____/_____</td>
                </tr>
              </tbody>
            </table>
          </div>
        </header>
        <main>
          <table className="document__table--install document__table--traps">
            <thead>
              <tr>
                <th rowSpan={2}></th>
                <th colSpan={4}>ENDEREÇO</th>
                <th>ARMADILHA</th>
                <th colSpan={3}>RESPONSÁVEL PELO IMÓVEL</th>
              </tr>
              <tr>
                <th style={{ width: "250px" }}>Logradouro</th>
                <th>Número</th>
                <th>Complemento</th>
                <th>
                  Tipo <br />
                  do Imóvel
                </th>
                <th>
                  Número da <br />
                  Armadilha
                </th>
                <th style={{ width: "250px" }}>
                  Nome do <br />
                  Responsável
                </th>
                <th style={{ width: "150px" }}>
                  Telefone de <br />
                  Contato
                </th>
                <th style={{ width: "300px" }}> Concordo</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 20 }).map((_, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center", color: "black" }}>
                    {index + 1}
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              ))}
            </tbody>
          </table>
        </main>
      </section>
    );
  }
);

export default InstallFormPrint;
