import { getUserData } from '../utils/auth';

import request from '../utils/request';
//import request from "../utils/requestTests/requestTestGeoReferencing";


export function fetchTerritorializations(type) {
  return request({
    url: '/Territorializations/GetByTypeLayer/'+type,
    method: 'get',
  });
}

export function fetchTerritorializationsGeneric() {
  return request({
    url: '/Territorializations',
    method: 'get',
  });
}

export function fetchTerritorializationsByTypeLayerSubtypeId(typeLayerSubtypeId) {
  return request({
    url: '/Territorializations/TypeLayerSubtype/' + typeLayerSubtypeId,
    method: 'get',
  });
}

export function postTerritorialization(TerritorializationData) {
   
  let dataSend = {
    geo: TerritorializationData.GeoJson,
    name: TerritorializationData.nome,
    TypeLayerId : TerritorializationData.camada,
    OrganizationId : getUserData('organizationId'),
    Location : TerritorializationData.GeoJson,
    postalCode : TerritorializationData.cep,
    number : TerritorializationData.numero,
    subpremisse : TerritorializationData.rua,
    economy : TerritorializationData.economia,
    complement:TerritorializationData.complemento,
    sequence:TerritorializationData.sequencial
  };

  return request({
    url: '/Territorializations',
    method: 'post',
    data: dataSend,
  });

}

export function getAllTypeTerritorializations (){
  return request({
    url:'/Territorializations/GetAllTypeLayers/'+ getUserData('organizationId'),
    method: 'get',
  })
}

export function getTypeLayer(typeLayerId){
  return request({
    url:"/Territorializations/GetTypeLayer/"+ typeLayerId,
    method: 'get',
  })
}

export function putTerritorialization(TerritorializationData) {
  let dataSend = {
    geo: TerritorializationData.GeoJson,
    id:TerritorializationData.id,
    name: TerritorializationData.nome,
    TypeLayerId : TerritorializationData.camada,
    OrganizationId: getUserData('organizationId'),
    postalCode : TerritorializationData.cep,
    number : TerritorializationData.numero,
    subpremisse : TerritorializationData.rua,
    economy : TerritorializationData.economia,
    Status: TerritorializationData.status == null ? TerritorializationData.SelectedTerritorialization.status : TerritorializationData.status,
  };

  return request({
    url: '/Territorializations',
    method: 'put',
    data: dataSend,
  });
}