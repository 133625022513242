import React from "react";

// core components
import "../../../../assets/css/style.css";
import {timeStampFormated} from "../../../../services/utils/format";
import {Line} from "react-chartjs-2";

function SymptomaticsByDayChart({dataCovid}) {
    var chartReference = React.createRef();
    const dateLabels = dataCovid.map(item => {
        return timeStampFormated(item.x);
         
        });
    var data = {
        title: {
            text: "Casos Por dia"
        },
        datasets: [{
            label: 'Casos Por dia',
            data: dataCovid,
            tension: 0,
            borderColor: "rgb(248,169,113)",
            backgroundColor: "rgba(0,0,0,0)",
            radius: 0,
            borderWidth: 1,
            pointHitRadius: 5
        }]
    };
    const dataC = {
        labels:dateLabels,
        datasets: [
          {
            label: 'Sintomáticos por dia',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: dataCovid
          }
        ]
      };
    var axes = React.useMemo(
        () => [
            { primary: true, type: 'linear', position: 'bottom' },
            { type: 'linear', position: 'left' },
        ],
        []
      );
      var options = {
            title: {text: "Casos Por dia"},
            scales: {
               

                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        min: 0
                    }    
                }]

            }
        }
    return (
        <div className="col-6" style={{"margin": "auto"}}>
             <Line data={dataC}options={options} />
        </div>
       
        /*<Chart data={data} axes={axes} options={options}/>*/
    )
}

export default SymptomaticsByDayChart;
