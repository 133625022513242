
import React from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
  } from "reactstrap";
import AddSampleResult from './AddSampleResult.jsx';






export default class SelectedSampleModal extends React.Component {
state={
    Sample:this.props.Sample,
    histories:[]
}

componentDidMount = () =>{
    

}

render (){

    return(
        <>
        {}
            <Modal size="lg" isOpen={this.props.OpenSampleModal} fade="true"  toggle={()=>this.props.toggleCallback()}
            >
                <ModalHeader toggle={()=>this.props.toggleCallback()}>Cadastrar resultado</ModalHeader>
                <ModalBody>           
                    <AddSampleResult sample = {this.props.sample}/>
                </ModalBody>
                <ModalFooter>
                    <div className="row" >                          
                        <div className="col-12">
                            <button onClick={()=>this.props.toggleCallback()} className="btn btn-primary">Sair</button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
            

        </>
    )
}


}