export default function getWeeks() {
    var weeks = [
        {
            year: "2017",
            weeks: [
                { beginDate: '01/01/2017', endDate: '07/01/2017', label: "Semana 1" },
                { beginDate: '08/01/2017', endDate: '14/01/2017', label: "Semana 2" },
                { beginDate: '15/01/2017', endDate: '21/01/2017', label: "Semana 3" },
                { beginDate: '22/01/2017', endDate: '28/01/2017', label: "Semana 4" },
                { beginDate: '29/01/2017', endDate: '04/02/2017', label: "Semana 5" },
                { beginDate: '05/02/2017', endDate: '11/02/2017', label: "Semana 6" },
                { beginDate: '12/02/2017', endDate: '18/02/2017', label: "Semana 7" },
                { beginDate: '19/02/2017', endDate: '25/02/2017', label: "Semana 8" },
                { beginDate: '26/02/2017', endDate: '04/03/2017', label: "Semana 9" },
                { beginDate: '05/03/2017', endDate: '11/03/2017', label: "Semana 10" },
                { beginDate: '12/03/2017', endDate: '18/03/2017', label: "Semana 11" },
                { beginDate: '19/03/2017', endDate: '25/03/2017', label: "Semana 12" },
                { beginDate: '26/03/2017', endDate: '01/04/2017', label: "Semana 13" },
                { beginDate: '02/04/2017', endDate: '08/04/2017', label: "Semana 14" },
                { beginDate: '09/04/2017', endDate: '15/04/2017', label: "Semana 15" },
                { beginDate: '16/04/2017', endDate: '22/04/2017', label: "Semana 16" },
                { beginDate: '23/04/2017', endDate: '29/04/2017', label: "Semana 17" },
                { beginDate: '30/04/2017', endDate: '06/05/2017', label: "Semana 18" },
                { beginDate: '07/05/2017', endDate: '13/05/2017', label: "Semana 19" },
                { beginDate: '14/05/2017', endDate: '20/05/2017', label: "Semana 20" },
                { beginDate: '21/05/2017', endDate: '27/05/2017', label: "Semana 21" },
                { beginDate: '28/05/2017', endDate: '03/06/2017', label: "Semana 22" },
                { beginDate: '04/06/2017', endDate: '10/06/2017', label: "Semana 23" },
                { beginDate: '11/06/2017', endDate: '17/06/2017', label: "Semana 24" },
                { beginDate: '18/06/2017', endDate: '24/06/2017', label: "Semana 25" },
                { beginDate: '25/06/2017', endDate: '01/07/2017', label: "Semana 26" },
                { beginDate: '02/07/2017', endDate: '08/07/2017', label: "Semana 27" },
                { beginDate: '09/07/2017', endDate: '15/07/2017', label: "Semana 28" },
                { beginDate: '16/07/2017', endDate: '22/07/2017', label: "Semana 29" },
                { beginDate: '23/07/2017', endDate: '29/07/2017', label: "Semana 30" },
                { beginDate: '30/07/2017', endDate: '05/08/2017', label: "Semana 31" },
                { beginDate: '06/08/2017', endDate: '12/08/2017', label: "Semana 32" },
                { beginDate: '13/08/2017', endDate: '19/08/2017', label: "Semana 33" },
                { beginDate: '20/08/2017', endDate: '26/08/2017', label: "Semana 34" },
                { beginDate: '27/08/2017', endDate: '02/09/2017', label: "Semana 35" },
                { beginDate: '03/09/2017', endDate: '09/09/2017', label: "Semana 36" },
                { beginDate: '10/09/2017', endDate: '16/09/2017', label: "Semana 37" },
                { beginDate: '17/09/2017', endDate: '23/09/2017', label: "Semana 38" },
                { beginDate: '24/09/2017', endDate: '30/09/2017', label: "Semana 39" },
                { beginDate: '01/10/2017', endDate: '07/10/2017', label: "Semana 40" },
                { beginDate: '08/10/2017', endDate: '14/10/2017', label: "Semana 41" },
                { beginDate: '15/10/2017', endDate: '21/10/2017', label: "Semana 42" },
                { beginDate: '22/10/2017', endDate: '28/10/2017', label: "Semana 43" },
                { beginDate: '29/10/2017', endDate: '04/11/2017', label: "Semana 44" },
                { beginDate: '05/11/2017', endDate: '11/11/2017', label: "Semana 45" },
                { beginDate: '12/11/2017', endDate: '18/11/2017', label: "Semana 46" },
                { beginDate: '19/11/2017', endDate: '25/11/2017', label: "Semana 47" },
                { beginDate: '26/11/2017', endDate: '02/12/2017', label: "Semana 48" },
                { beginDate: '03/12/2017', endDate: '09/12/2017', label: "Semana 49" },
                { beginDate: '10/12/2017', endDate: '16/12/2017', label: "Semana 50" },
                { beginDate: '17/12/2017', endDate: '23/12/2017', label: "Semana 51" },
                { beginDate: '24/12/2017', endDate: '30/12/2017', label: "Semana 52" }
            ]
        },
        {
            year: "2018",
            weeks: [
                { beginDate: '31/12/2017', endDate: '06/01/2018', label: "Semana 1" },
                { beginDate: '07/01/2018', endDate: '13/01/2018', label: "Semana 2" },
                { beginDate: '14/01/2018', endDate: '20/01/2018', label: "Semana 3" },
                { beginDate: '21/01/2018', endDate: '27/01/2018', label: "Semana 4" },
                { beginDate: '28/01/2018', endDate: '03/02/2018', label: "Semana 5" },
                { beginDate: '04/02/2018', endDate: '10/02/2018', label: "Semana 6" },
                { beginDate: '11/02/2018', endDate: '17/02/2018', label: "Semana 7" },
                { beginDate: '18/02/2018', endDate: '24/02/2018', label: "Semana 8" },
                { beginDate: '25/02/2018', endDate: '03/03/2018', label: "Semana 9" },
                { beginDate: '04/03/2018', endDate: '10/03/2018', label: "Semana 10" },
                { beginDate: '11/03/2018', endDate: '17/03/2018', label: "Semana 11" },
                { beginDate: '18/03/2018', endDate: '24/03/2018', label: "Semana 12" },
                { beginDate: '25/03/2018', endDate: '31/03/2018', label: "Semana 13" },
                { beginDate: '01/04/2018', endDate: '07/04/2018', label: "Semana 14" },
                { beginDate: '04/04/2018', endDate: '14/04/2018', label: "Semana 15" },
                { beginDate: '15/04/2018', endDate: '21/04/2018', label: "Semana 16" },
                { beginDate: '22/04/2018', endDate: '28/04/2018', label: "Semana 17" },
                { beginDate: '29/04/2018', endDate: '05/05/2018', label: "Semana 18" },
                { beginDate: '06/05/2018', endDate: '12/05/2018', label: "Semana 19" },
                { beginDate: '13/05/2018', endDate: '19/05/2018', label: "Semana 20" },
                { beginDate: '20/05/2018', endDate: '26/05/2018', label: "Semana 21" },
                { beginDate: '27/05/2018', endDate: '02/06/2018', label: "Semana 22" },
                { beginDate: '03/06/2018', endDate: '09/06/2018', label: "Semana 23" },
                { beginDate: '10/06/2018', endDate: '16/06/2018', label: "Semana 24" },
                { beginDate: '17/06/2018', endDate: '23/06/2018', label: "Semana 25" },
                { beginDate: '24/06/2018', endDate: '30/06/2018', label: "Semana 26" },
                { beginDate: '01/07/2018', endDate: '07/07/2018', label: "Semana 27" },
                { beginDate: '08/07/2018', endDate: '14/07/2018', label: "Semana 28" },
                { beginDate: '15/07/2018', endDate: '21/07/2018', label: "Semana 29" },
                { beginDate: '22/07/2018', endDate: '28/07/2018', label: "Semana 30" },
                { beginDate: '29/07/2018', endDate: '04/08/2018', label: "Semana 31" },
                { beginDate: '05/08/2018', endDate: '11/08/2018', label: "Semana 32" },
                { beginDate: '12/08/2018', endDate: '18/08/2018', label: "Semana 33" },
                { beginDate: '19/08/2018', endDate: '25/08/2018', label: "Semana 34" },
                { beginDate: '26/08/2018', endDate: '01/09/2018', label: "Semana 35" },
                { beginDate: '02/09/2018', endDate: '08/09/2018', label: "Semana 36" },
                { beginDate: '09/09/2018', endDate: '15/09/2018', label: "Semana 37" },
                { beginDate: '16/09/2018', endDate: '22/09/2018', label: "Semana 38" },
                { beginDate: '23/09/2018', endDate: '29/09/2018', label: "Semana 39" },
                { beginDate: '30/09/2018', endDate: '06/10/2018', label: "Semana 40" },
                { beginDate: '07/10/2018', endDate: '13/10/2018', label: "Semana 41" },
                { beginDate: '14/10/2018', endDate: '20/10/2018', label: "Semana 42" },
                { beginDate: '21/10/2018', endDate: '27/10/2018', label: "Semana 43" },
                { beginDate: '28/10/2018', endDate: '03/11/2018', label: "Semana 44" },
                { beginDate: '04/11/2018', endDate: '10/11/2018', label: "Semana 45" },
                { beginDate: '11/11/2018', endDate: '17/11/2018', label: "Semana 46" },
                { beginDate: '18/11/2018', endDate: '24/11/2018', label: "Semana 47" },
                { beginDate: '25/11/2018', endDate: '01/12/2018', label: "Semana 48" },
                { beginDate: '02/12/2018', endDate: '08/12/2018', label: "Semana 49" },
                { beginDate: '09/12/2018', endDate: '15/12/2018', label: "Semana 50" },
                { beginDate: '16/12/2018', endDate: '22/12/2018', label: "Semana 51" },
                { beginDate: '23/12/2018', endDate: '29/12/2018', label: "Semana 52" }
            ]
        },
        {
            year: "2019",
            weeks: [
                { beginDate: '30/12/2018', endDate: '05/01/2019', label: "Semana 1" },
                { beginDate: '06/01/2019', endDate: '12/01/2019', label: "Semana 2" },
                { beginDate: '13/01/2019', endDate: '19/01/2019', label: "Semana 3" },
                { beginDate: '20/01/2019', endDate: '26/01/2019', label: "Semana 4" },
                { beginDate: '27/01/2019', endDate: '02/02/2019', label: "Semana 5" },
                { beginDate: '03/02/2019', endDate: '09/02/2019', label: "Semana 6" },
                { beginDate: '10/02/2019', endDate: '16/02/2019', label: "Semana 7" },
                { beginDate: '17/02/2019', endDate: '23/02/2019', label: "Semana 8" },
                { beginDate: '24/02/2019', endDate: '02/03/2019', label: "Semana 9" },
                { beginDate: '03/03/2019', endDate: '09/03/2019', label: "Semana 10" },
                { beginDate: '10/03/2019', endDate: '16/03/2019', label: "Semana 11" },
                { beginDate: '17/03/2019', endDate: '23/03/2019', label: "Semana 12" },
                { beginDate: '24/03/2019', endDate: '30/03/2019', label: "Semana 13" },
                { beginDate: '31/03/2019', endDate: '06/04/2019', label: "Semana 14" },
                { beginDate: '07/04/2019', endDate: '13/04/2019', label: "Semana 15" },
                { beginDate: '14/04/2019', endDate: '20/04/2019', label: "Semana 16" },
                { beginDate: '21/04/2019', endDate: '27/04/2019', label: "Semana 17" },
                { beginDate: '28/04/2019', endDate: '04/05/2019', label: "Semana 18" },
                { beginDate: '05/05/2019', endDate: '11/05/2019', label: "Semana 19" },
                { beginDate: '12/05/2019', endDate: '18/05/2019', label: "Semana 20" },
                { beginDate: '19/05/2019', endDate: '25/05/2019', label: "Semana 21" },
                { beginDate: '26/05/2019', endDate: '01/06/2019', label: "Semana 22" },
                { beginDate: '02/06/2019', endDate: '08/06/2019', label: "Semana 23" },
                { beginDate: '09/06/2019', endDate: '15/06/2019', label: "Semana 24" },
                { beginDate: '16/06/2019', endDate: '22/06/2019', label: "Semana 25" },
                { beginDate: '23/06/2019', endDate: '29/06/2019', label: "Semana 26" },
                { beginDate: '30/06/2019', endDate: '06/07/2019', label: "Semana 27" },
                { beginDate: '07/07/2019', endDate: '13/07/2019', label: "Semana 28" },
                { beginDate: '14/07/2019', endDate: '20/07/2019', label: "Semana 29" },
                { beginDate: '21/07/2019', endDate: '27/07/2019', label: "Semana 30" },
                { beginDate: '28/07/2019', endDate: '03/08/2019', label: "Semana 31" },
                { beginDate: '04/08/2019', endDate: '10/08/2019', label: "Semana 32" },
                { beginDate: '11/08/2019', endDate: '17/08/2019', label: "Semana 33" },
                { beginDate: '18/08/2019', endDate: '24/08/2019', label: "Semana 34" },
                { beginDate: '25/08/2019', endDate: '31/08/2019', label: "Semana 35" },
                { beginDate: '01/09/2019', endDate: '07/09/2019', label: "Semana 36" },
                { beginDate: '08/09/2019', endDate: '14/09/2019', label: "Semana 37" },
                { beginDate: '15/09/2019', endDate: '21/09/2019', label: "Semana 38" },
                { beginDate: '22/09/2019', endDate: '28/09/2019', label: "Semana 39" },
                { beginDate: '29/09/2019', endDate: '05/10/2019', label: "Semana 40" },
                { beginDate: '06/10/2019', endDate: '12/10/2019', label: "Semana 41" },
                { beginDate: '13/10/2019', endDate: '19/10/2019', label: "Semana 42" },
                { beginDate: '20/10/2019', endDate: '26/10/2019', label: "Semana 43" },
                { beginDate: '27/10/2019', endDate: '02/11/2019', label: "Semana 44" },
                { beginDate: '03/11/2019', endDate: '09/11/2019', label: "Semana 45" },
                { beginDate: '10/11/2019', endDate: '16/11/2019', label: "Semana 46" },
                { beginDate: '17/11/2019', endDate: '23/11/2019', label: "Semana 47" },
                { beginDate: '24/11/2019', endDate: '30/11/2019', label: "Semana 48" },
                { beginDate: '01/12/2019', endDate: '07/12/2019', label: "Semana 49" },
                { beginDate: '08/12/2019', endDate: '14/12/2019', label: "Semana 50" },
                { beginDate: '15/12/2019', endDate: '21/12/2019', label: "Semana 51" },
                { beginDate: '22/12/2019', endDate: '28/12/2019', label: "Semana 52" }

            ]
        },
        {
            year: "2020",
            weeks: [
                { beginDate: '29/12/2019', endDate: '04/01/2020', label: "Semana 1" },
                { beginDate: '05/01/2020', endDate: '11/01/2020', label: "Semana 2" },
                { beginDate: '12/01/2020', endDate: '18/01/2020', label: "Semana 3" },
                { beginDate: '19/01/2020', endDate: '25/01/2020', label: "Semana 4" },
                { beginDate: '26/01/2020', endDate: '01/02/2020', label: "Semana 5" },
                { beginDate: '02/02/2020', endDate: '08/02/2020', label: "Semana 6" },
                { beginDate: '09/02/2020', endDate: '15/02/2020', label: "Semana 7" },
                { beginDate: '16/02/2020', endDate: '22/02/2020', label: "Semana 8" },
                { beginDate: '23/02/2020', endDate: '29/02/2020', label: "Semana 9" },
                { beginDate: '01/03/2020', endDate: '07/03/2020', label: "Semana 10" },
                { beginDate: '08/03/2020', endDate: '14/03/2020', label: "Semana 11" },
                { beginDate: '15/03/2020', endDate: '21/03/2020', label: "Semana 12" },
                { beginDate: '22/03/2020', endDate: '28/03/2020', label: "Semana 13" },
                { beginDate: '29/03/2020', endDate: '04/04/2020', label: "Semana 14" },
                { beginDate: '05/04/2020', endDate: '11/04/2020', label: "Semana 15" },
                { beginDate: '12/04/2020', endDate: '18/04/2020', label: "Semana 16" },
                { beginDate: '19/04/2020', endDate: '25/04/2020', label: "Semana 17" },
                { beginDate: '26/04/2020', endDate: '02/05/2020', label: "Semana 18" },
                { beginDate: '03/05/2020', endDate: '09/05/2020', label: "Semana 19" },
                { beginDate: '10/05/2020', endDate: '16/05/2020', label: "Semana 20" },
                { beginDate: '17/05/2020', endDate: '23/05/2020', label: "Semana 21" },
                { beginDate: '24/05/2020', endDate: '30/05/2020', label: "Semana 22" },
                { beginDate: '31/05/2020', endDate: '06/06/2020', label: "Semana 23" },
                { beginDate: '07/06/2020', endDate: '13/06/2020', label: "Semana 24" },
                { beginDate: '14/06/2020', endDate: '20/06/2020', label: "Semana 25" },
                { beginDate: '21/06/2020', endDate: '27/06/2020', label: "Semana 26" },
                { beginDate: '28/06/2020', endDate: '04/07/2020', label: "Semana 27" },
                { beginDate: '05/07/2020', endDate: '11/07/2020', label: "Semana 28" },
                { beginDate: '12/07/2020', endDate: '18/07/2020', label: "Semana 29" },
                { beginDate: '19/07/2020', endDate: '25/07/2020', label: "Semana 30" },
                { beginDate: '26/07/2020', endDate: '01/08/2020', label: "Semana 31" },
                { beginDate: '02/08/2020', endDate: '08/08/2020', label: "Semana 32" },
                { beginDate: '09/08/2020', endDate: '15/08/2020', label: "Semana 33" },
                { beginDate: '16/08/2020', endDate: '22/08/2020', label: "Semana 34" },
                { beginDate: '23/08/2020', endDate: '29/08/2020', label: "Semana 35" },
                { beginDate: '30/08/2020', endDate: '05/09/2020', label: "Semana 36" },
                { beginDate: '06/09/2020', endDate: '12/09/2020', label: "Semana 37" },
                { beginDate: '13/09/2020', endDate: '19/09/2020', label: "Semana 38" },
                { beginDate: '20/09/2020', endDate: '26/09/2020', label: "Semana 39" },
                { beginDate: '27/09/2020', endDate: '03/10/2020', label: "Semana 40" },
                { beginDate: '04/10/2020', endDate: '10/10/2020', label: "Semana 41" },
                { beginDate: '11/10/2020', endDate: '17/10/2020', label: "Semana 42" },
                { beginDate: '18/10/2020', endDate: '24/10/2020', label: "Semana 43" },
                { beginDate: '25/10/2020', endDate: '31/10/2020', label: "Semana 44" },
                { beginDate: '01/11/2020', endDate: '07/11/2020', label: "Semana 45" },
                { beginDate: '08/11/2020', endDate: '14/11/2020', label: "Semana 46" },
                { beginDate: '15/11/2020', endDate: '21/11/2020', label: "Semana 47" },
                { beginDate: '22/11/2020', endDate: '28/11/2020', label: "Semana 48" },
                { beginDate: '29/11/2020', endDate: '05/12/2020', label: "Semana 49" },
                { beginDate: '06/12/2020', endDate: '12/12/2020', label: "Semana 50" },
                { beginDate: '13/12/2020', endDate: '19/12/2020', label: "Semana 51" },
                { beginDate: '20/12/2020', endDate: '26/12/2020', label: "Semana 52" },
                { beginDate: '27/12/2020', endDate: '02/01/2021', label: "Semana 53" }
            ]
        },
        {
            year: "2021",
            weeks: [
                { beginDate: '03/01/2021', endDate: '09/01/2021', label: "Semana 1" },
                { beginDate: '10/01/2021', endDate: '16/01/2021', label: "Semana 2" },
                { beginDate: '17/01/2021', endDate: '23/01/2021', label: "Semana 3" },
                { beginDate: '24/01/2021', endDate: '30/01/2021', label: "Semana 4" },
                { beginDate: '31/01/2021', endDate: '06/02/2021', label: "Semana 5" },
                { beginDate: '07/02/2021', endDate: '13/02/2021', label: "Semana 6" },
                { beginDate: '14/02/2021', endDate: '20/02/2021', label: "Semana 7" },
                { beginDate: '21/02/2021', endDate: '27/02/2021', label: "Semana 8" },
                { beginDate: '28/02/2021', endDate: '06/03/2021', label: "Semana 9" },
                { beginDate: '07/03/2021', endDate: '13/03/2021', label: "Semana 10" },
                { beginDate: '14/03/2021', endDate: '20/03/2021', label: "Semana 11" },
                { beginDate: '21/03/2021', endDate: '27/03/2021', label: "Semana 12" },
                { beginDate: '28/03/2021', endDate: '03/04/2021', label: "Semana 13" },
                { beginDate: '04/04/2021', endDate: '10/04/2021', label: "Semana 14" },
                { beginDate: '11/04/2021', endDate: '17/04/2021', label: "Semana 15" },
                { beginDate: '18/04/2021', endDate: '24/04/2021', label: "Semana 16" },
                { beginDate: '25/04/2021', endDate: '01/05/2021', label: "Semana 17" },
                { beginDate: '02/05/2021', endDate: '08/05/2021', label: "Semana 18" },
                { beginDate: '09/05/2021', endDate: '15/05/2021', label: "Semana 19" },
                { beginDate: '16/05/2021', endDate: '22/05/2021', label: "Semana 20" },
                { beginDate: '23/05/2021', endDate: '29/05/2021', label: "Semana 21" },
                { beginDate: '30/05/2021', endDate: '05/06/2021', label: "Semana 22" },
                { beginDate: '06/06/2021', endDate: '12/06/2021', label: "Semana 23" },
                { beginDate: '13/06/2021', endDate: '19/06/2021', label: "Semana 24" },
                { beginDate: '20/06/2021', endDate: '26/06/2021', label: "Semana 25" },
                { beginDate: '27/06/2021', endDate: '03/07/2021', label: "Semana 26" },
                { beginDate: '04/07/2021', endDate: '10/07/2021', label: "Semana 27" },
                { beginDate: '11/07/2021', endDate: '17/07/2021', label: "Semana 28" },
                { beginDate: '18/07/2021', endDate: '24/07/2021', label: "Semana 29" },
                { beginDate: '25/07/2021', endDate: '31/07/2021', label: "Semana 30" },
                { beginDate: '01/08/2021', endDate: '07/08/2021', label: "Semana 31" },
                { beginDate: '08/08/2021', endDate: '14/08/2021', label: "Semana 32" },
                { beginDate: '15/08/2021', endDate: '21/08/2021', label: "Semana 33" },
                { beginDate: '22/08/2021', endDate: '28/08/2021', label: "Semana 34" },
                { beginDate: '29/08/2021', endDate: '04/09/2021', label: "Semana 35" },
                { beginDate: '05/09/2021', endDate: '11/09/2021', label: "Semana 36" },
                { beginDate: '12/09/2021', endDate: '18/09/2021', label: "Semana 37" },
                { beginDate: '19/09/2021', endDate: '25/09/2021', label: "Semana 38" },
                { beginDate: '26/09/2021', endDate: '02/10/2021', label: "Semana 39" },
                { beginDate: '03/10/2021', endDate: '09/10/2021', label: "Semana 40" },
                { beginDate: '10/10/2021', endDate: '16/10/2021', label: "Semana 41" },
                { beginDate: '17/10/2021', endDate: '23/10/2021', label: "Semana 42" },
                { beginDate: '24/10/2021', endDate: '30/10/2021', label: "Semana 43" },
                { beginDate: '31/10/2021', endDate: '06/11/2021', label: "Semana 44" },
                { beginDate: '07/11/2021', endDate: '13/11/2021', label: "Semana 45" },
                { beginDate: '14/11/2021', endDate: '20/11/2021', label: "Semana 46" },
                { beginDate: '21/11/2021', endDate: '27/11/2021', label: "Semana 47" },
                { beginDate: '28/11/2021', endDate: '04/12/2021', label: "Semana 48" },
                { beginDate: '05/12/2021', endDate: '11/12/2021', label: "Semana 49" },
                { beginDate: '12/12/2021', endDate: '18/12/2021', label: "Semana 50" },
                { beginDate: '19/12/2021', endDate: '25/12/2021', label: "Semana 51" },
                { beginDate: '26/12/2021', endDate: '01/01/2022', label: "Semana 52" }
            ]
        },
        {

            year: '2022',
            weeks: [
                { beginDate: '02/01/2022', endDate: '08/01/2022', label: "Semana 1" },
                { beginDate: '09/01/2022', endDate: '15/01/2022', label: "Semana 2" },
                { beginDate: '16/01/2022', endDate: '22/01/2022', label: "Semana 3" },
                { beginDate: '23/01/2022', endDate: '29/01/2022', label: "Semana 4" },
                { beginDate: '30/01/2022', endDate: '05/02/2022', label: "Semana 5" },
                { beginDate: '06/02/2022', endDate: '12/02/2022', label: "Semana 6" },
                { beginDate: '13/02/2022', endDate: '19/02/2022', label: "Semana 7" },
                { beginDate: '20/02/2022', endDate: '26/02/2022', label: "Semana 8" },
                { beginDate: '27/02/2022', endDate: '05/03/2022', label: "Semana 9" },
                { beginDate: '06/03/2022', endDate: '12/03/2022', label: "Semana 10" },
                { beginDate: '13/03/2022', endDate: '19/03/2022', label: "Semana 11" },
                { beginDate: '20/03/2022', endDate: '26/03/2022', label: "Semana 12" },
                { beginDate: '27/03/2022', endDate: '02/04/2022', label: "Semana 13" },
                { beginDate: '03/04/2022', endDate: '09/04/2022', label: "Semana 14" },
                { beginDate: '10/04/2022', endDate: '16/04/2022', label: "Semana 15" },
                { beginDate: '17/04/2022', endDate: '23/04/2022', label: "Semana 16" },
                { beginDate: '24/04/2022', endDate: '30/04/2022', label: "Semana 17" },
                { beginDate: '01/05/2022', endDate: '07/05/2022', label: "Semana 18" },
                { beginDate: '08/05/2022', endDate: '14/05/2022', label: "Semana 19" },
                { beginDate: '15/05/2022', endDate: '21/05/2022', label: "Semana 20" },
                { beginDate: '22/05/2022', endDate: '28/05/2022', label: "Semana 21" },
                { beginDate: '29/05/2022', endDate: '04/06/2022', label: "Semana 22" },
                { beginDate: '05/06/2022', endDate: '11/06/2022', label: "Semana 23" },
                { beginDate: '12/06/2022', endDate: '18/06/2022', label: "Semana 24" },
                { beginDate: '19/06/2022', endDate: '25/06/2022', label: "Semana 25" },
                { beginDate: '26/06/2022', endDate: '02/07/2022', label: "Semana 26" },
                { beginDate: '03/07/2022', endDate: '09/07/2022', label: "Semana 27" },
                { beginDate: '10/07/2022', endDate: '16/07/2022', label: "Semana 28" },
                { beginDate: '17/07/2022', endDate: '23/07/2022', label: "Semana 29" },
                { beginDate: '24/07/2022', endDate: '30/07/2022', label: "Semana 30" },
                { beginDate: '31/07/2022', endDate: '06/08/2022', label: "Semana 31" },
                { beginDate: '07/08/2022', endDate: '13/08/2022', label: "Semana 32" },
                { beginDate: '14/08/2022', endDate: '20/08/2022', label: "Semana 33" },
                { beginDate: '21/08/2022', endDate: '27/08/2022', label: "Semana 34" },
                { beginDate: '28/08/2022', endDate: '03/09/2022', label: "Semana 35" },
                { beginDate: '04/09/2022', endDate: '10/09/2022', label: "Semana 36" },
                { beginDate: '11/09/2022', endDate: '17/09/2022', label: "Semana 37" },
                { beginDate: '18/09/2022', endDate: '24/09/2022', label: "Semana 38" },
                { beginDate: '25/09/2022', endDate: '01/10/2022', label: "Semana 39" },
                { beginDate: '02/10/2022', endDate: '08/10/2022', label: "Semana 40" },
                { beginDate: '09/10/2022', endDate: '15/10/2022', label: "Semana 41" },
                { beginDate: '16/10/2022', endDate: '22/10/2022', label: "Semana 42" },
                { beginDate: '23/10/2022', endDate: '29/10/2022', label: "Semana 43" },
                { beginDate: '30/10/2022', endDate: '05/11/2022', label: "Semana 44" },
                { beginDate: '06/11/2022', endDate: '12/11/2022', label: "Semana 45" },
                { beginDate: '13/11/2022', endDate: '19/11/2022', label: "Semana 46" },
                { beginDate: '20/11/2022', endDate: '26/11/2022', label: "Semana 47" },
                { beginDate: '27/11/2022', endDate: '03/12/2022', label: "Semana 48" },
                { beginDate: '04/12/2022', endDate: '10/12/2022', label: "Semana 49" },
                { beginDate: '11/12/2022', endDate: '17/12/2022', label: "Semana 50" },
                { beginDate: '18/12/2022', endDate: '24/12/2022', label: "Semana 51" },
                { beginDate: '25/12/2022', endDate: '31/12/2022', label: "Semana 52" }
            ]
        },
        {
            year: '2023',
            weeks: [
                { beginDate: '01/01/2023', endDate: '07/01/2023' },
                { beginDate: '08/01/2023', endDate: '14/01/2023' },
                { beginDate: '15/01/2023', endDate: '21/01/2023' },
                { beginDate: '22/01/2023', endDate: '28/01/2023' },
                { beginDate: '29/01/2023', endDate: '04/02/2023' },
                { beginDate: '05/02/2023', endDate: '11/02/2023' },
                { beginDate: '12/02/2023', endDate: '18/02/2023' },
                { beginDate: '19/02/2023', endDate: '25/02/2023' },
                { beginDate: '26/02/2023', endDate: '04/03/2023' },
                { beginDate: '05/03/2023', endDate: '11/03/2023' },
                { beginDate: '12/03/2023', endDate: '18/03/2023' },
                { beginDate: '19/03/2023', endDate: '25/03/2023' },
                { beginDate: '26/03/2023', endDate: '01/04/2023' },
                { beginDate: '02/04/2023', endDate: '08/04/2023' },
                { beginDate: '09/04/2023', endDate: '15/04/2023' },
                { beginDate: '16/04/2023', endDate: '22/04/2023' },
                { beginDate: '23/04/2023', endDate: '29/04/2023' },
                { beginDate: '30/04/2023', endDate: '06/05/2023' },
                { beginDate: '07/05/2023', endDate: '13/05/2023' },
                { beginDate: '14/05/2023', endDate: '20/05/2023' },
                { beginDate: '21/05/2023', endDate: '27/05/2023' },
                { beginDate: '28/05/2023', endDate: '03/06/2023' },
                { beginDate: '04/06/2023', endDate: '10/06/2023' },
                { beginDate: '11/06/2023', endDate: '17/06/2023' },
                { beginDate: '18/06/2023', endDate: '24/06/2023' },
                { beginDate: '25/06/2023', endDate: '01/07/2023' },
                { beginDate: '02/07/2023', endDate: '08/07/2023' },
                { beginDate: '09/07/2023', endDate: '15/07/2023' },
                { beginDate: '16/07/2023', endDate: '22/07/2023' },
                { beginDate: '23/07/2023', endDate: '29/07/2023' },
                { beginDate: '30/07/2023', endDate: '05/08/2023' },
                { beginDate: '06/08/2023', endDate: '12/08/2023' },
                { beginDate: '13/08/2023', endDate: '19/08/2023' },
                { beginDate: '20/08/2023', endDate: '26/08/2023' },
                { beginDate: '27/08/2023', endDate: '02/09/2023' },
                { beginDate: '03/09/2023', endDate: '09/09/2023' },
                { beginDate: '10/09/2023', endDate: '16/09/2023' },
                { beginDate: '17/09/2023', endDate: '23/09/2023' },
                { beginDate: '24/09/2023', endDate: '30/09/2023' },
                { beginDate: '01/10/2023', endDate: '07/10/2023' },
                { beginDate: '08/10/2023', endDate: '14/10/2023' },
                { beginDate: '15/10/2023', endDate: '21/10/2023' },
                { beginDate: '22/10/2023', endDate: '28/10/2023' },
                { beginDate: '29/10/2023', endDate: '04/11/2023' },
                { beginDate: '05/11/2023', endDate: '11/11/2023' },
                { beginDate: '12/11/2023', endDate: '18/11/2023' },
                { beginDate: '19/11/2023', endDate: '25/11/2023' },
                { beginDate: '26/11/2023', endDate: '02/12/2023' },
                { beginDate: '03/12/2023', endDate: '09/12/2023' },
                { beginDate: '10/12/2023', endDate: '16/12/2023' },
                { beginDate: '17/12/2023', endDate: '23/12/2023' },
                { beginDate: '24/12/2023', endDate: '30/12/2023' },

            ]
        },
        {
            year: "2024",
            weeks: [
                {beginDate:  '31/12/2023',endDate: '06/01/2024'} , 
                {beginDate:  '07/01/2024',endDate: '13/01/2024'} , 
                {beginDate:  '14/01/2024',endDate: '20/01/2024'} , 
                {beginDate:  '21/01/2024',endDate: '27/01/2024'} , 
                {beginDate:  '28/01/2024',endDate: '03/02/2024'} , 
                {beginDate:  '04/02/2024',endDate: '10/02/2024'} , 
                {beginDate:  '11/02/2024',endDate: '17/02/2024'} , 
                {beginDate:  '18/02/2024',endDate: '24/02/2024'} , 
                {beginDate:  '25/02/2024',endDate: '02/03/2024'} , 
                {beginDate:  '03/03/2024',endDate: '09/03/2024'} , 
                {beginDate:  '10/03/2024',endDate: '16/03/2024'} , 
                {beginDate:  '17/03/2024',endDate: '23/03/2024'} , 
                {beginDate:  '24/03/2024',endDate: '30/03/2024'} , 
                {beginDate:  '31/03/2024',endDate: '06/04/2024'} , 
                {beginDate:  '07/04/2024',endDate: '13/04/2024'} , 
                {beginDate:  '14/04/2024',endDate: '20/04/2024'} , 
                {beginDate:  '21/04/2024',endDate: '27/04/2024'} , 
                {beginDate:  '28/04/2024',endDate: '04/05/2024'} , 
                {beginDate:  '05/05/2024',endDate: '11/05/2024'} , 
                {beginDate:  '12/05/2024',endDate: '18/05/2024'} , 
                {beginDate:  '19/05/2024',endDate: '25/05/2024'} , 
                {beginDate:  '26/05/2024',endDate: '01/06/2024'} , 
                {beginDate:  '02/06/2024',endDate: '08/06/2024'} , 
                {beginDate:  '09/06/2024',endDate: '15/06/2024'} , 
                {beginDate:  '16/06/2024',endDate: '22/06/2024'} , 
                {beginDate:  '23/06/2024',endDate: '29/06/2024'} , 
                {beginDate:  '30/06/2024',endDate: '06/07/2024'} , 
                {beginDate:  '07/07/2024',endDate: '13/07/2024'} , 
                {beginDate:  '14/07/2024',endDate: '20/07/2024'} , 
                {beginDate:  '21/07/2024',endDate: '27/07/2024'} , 
                {beginDate:  '28/07/2024',endDate: '03/08/2024'} , 
                {beginDate:  '04/08/2024',endDate: '10/08/2024'} , 
                {beginDate:  '11/08/2024',endDate: '17/08/2024'} , 
                {beginDate:  '18/08/2024',endDate: '24/08/2024'} , 
                {beginDate:  '25/08/2024',endDate: '31/08/2024'} , 
                {beginDate:  '01/09/2024',endDate: '07/09/2024'} , 
                {beginDate:  '08/09/2024',endDate: '14/09/2024'} , 
                {beginDate:  '15/09/2024',endDate: '21/09/2024'} , 
                {beginDate:  '22/09/2024',endDate: '28/09/2024'} , 
                {beginDate:  '29/09/2024',endDate: '05/10/2024'} , 
                {beginDate:  '06/10/2024',endDate: '12/10/2024'} , 
                {beginDate:  '13/10/2024',endDate: '19/10/2024'} , 
                {beginDate:  '20/10/2024',endDate: '26/10/2024'} , 
                {beginDate:  '27/10/2024',endDate: '02/11/2024'} , 
                {beginDate:  '03/11/2024',endDate: '09/11/2024'} , 
                {beginDate:  '10/11/2024',endDate: '16/11/2024'} , 
                {beginDate:  '17/11/2024',endDate: '23/11/2024'} , 
                {beginDate:  '24/11/2024',endDate: '30/11/2024'} , 
                {beginDate:  '01/12/2024',endDate: '07/12/2024'} , 
                {beginDate:  '08/12/2024',endDate: '14/12/2024'} , 
                {beginDate:  '15/12/2024',endDate: '21/12/2024'} , 
                {beginDate:  '22/12/2024',endDate: '28/12/2024'} , 
                {beginDate:  '29/12/2024',endDate: '04/01/2025'} , 
            ]
        }
    ]

    return weeks;
}
