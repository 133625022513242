import React from "react";
import EmployeesList from "./EmployeesList";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CardHeader,
    Container,
    Row,
    Col,
    Table,
  } from "reactstrap";
  import {deleteEmployee} from "../../../services/api/Employee";
import { getPermissionsToken, getUserData } from "../../../services/utils/auth";
import { deletePerson } from "../../../services/api/Person";
import DataTable from "react-data-table-component";
class EmployeesTable extends React.Component {
    state = {
        modal:"",
        selectedEmployee:"",
        selectedEmployeeId:"",
        loggedEmployeeId: getUserData("employeeId"),
    }

    confirmDelete (EmployeeName, EmployeeId) {
        this.setState({
            selectedEmployeeId:EmployeeId,
            selectedEmployee:EmployeeName
        });
        this.toggle(false);
    }
    toggle = (willDeleteEmployee) => {
        this.setState({
            modal: !this.state.modal
        }); 

        if(willDeleteEmployee){

            let deleted = deletePerson(this.state.selectedEmployeeId);     
            deleted.then(response => {
                if(response){
                    toast.success("Funcionário removido com sucesso!");
                }
            });      
        }
    }

    buildButtons(employee){
        const userPermissions = getPermissionsToken();
        return (
            <>
                {userPermissions["ba56119d-ac43-4ec1-858e-31f2e12235e8"] && 
                    <>
                        {userPermissions["ba56119d-ac43-4ec1-858e-31f2e12235e8"].update && 
                            <Link to="/admin/EditEmployee">
                                <button
                                    className="btn btn-primary"
                                    style={{'width':'60px', 'margin' : '5px'}}
                                    data-clipboard-text="ni ni-fat-edit"
                                    onClick={() => {localStorage.setItem("EmployeeItem",employee.id); localStorage.setItem("PersonItem",employee.personId);}}
                                    id="tooltip204269497"
                                    type="button"
                                >                                            
                                <i className="ni ni-ruler-pencil" />
                                    
                                </button>
                            </Link>
                        }

                        {userPermissions["ba56119d-ac43-4ec1-858e-31f2e12235e8"].delete && 
                            <button
                                className="btn btn-warning"
                                style={{'width':'60px', 'margin' : '5px'}}
                                data-clipboard-text=" ni ni-fat-remove"
                                disabled={this.state.loggedEmployeeId === employee.id}
                                id="tooltip204269497"
                                onClick={()=>this.confirmDelete(employee.name,employee.personId)}
                                type="button">
                                <i className=" fa fa-trash"/>
                            </button>
                        }   
                    </>
                }
            </>
        )
    }

    render() {      
        const columns = [
            {
                name:'Matricula',
                selector: 'registration',
                sortable:true,
                
            },
            {
                name: 'Nome',
                selector: 'name',
                sortable: true,
            },
            {
                name: 'Cargo',
                selector: 'place',   
                sortable: true,       
            },
            {
                name: 'Função',
                selector: (e => e.function ? e.function.name : '-'),
                sortable: true,
            },
            {
                cell:(row)=> this.buildButtons(row),
                ignoreRowClick: true,
            },
        ];
        
        return (

            <Container className="mt--7" fluid>
                <ToastContainer />
                <Modal size="lg" isOpen={this.state.modal} fade="true"  toggle={()=>this.toggle(false)}>
                    <ModalHeader toggle={()=>this.toggle(false)}>Remoção de funcionario</ModalHeader>
                    <ModalBody>           
                        <div className="row"> 
                            <div className="col-9">
                                <span className="h2 text-black">Deseja remover o funcionario {this.state.selectedEmployee}?</span>
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row" >                          
                        
                            <div className="col-6">
                                <button onClick={()=>this.toggle(false)} className="form-control">CANCELAR</button>
                            </div>
                            <div className="col-6">
                                <button onClick={()=>this.toggle(true)} className="btn btn-primary">REMOVER</button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
            <Row className="mt-12">
                <Col className="mb-12 mb-xl-0" xl="12">
                <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                        <h3 className="mb-0">Funcion&aacute;rios</h3>
                        </div>
                    </Row>
                    </CardHeader>
                    <DataTable
                        noHeader
                        defaultSortField="name"
                        defaultSortAsc={true}
                        pagination
                        highlightOnHover
                        columns={columns}
                        data={this.props.Employees}
                        noDataComponent={'Carregando lista...'}
                    />
                </Card>
                </Col>
            </Row>
            </Container>
        )
    }
}

export default EmployeesTable