import request from '../utils/request';
//import request from '../utils/requestTests/requestTestGateway';

export function login(username, password, loginHistoryData) {
  return request({
    url: '/Login',
    method: 'post',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    data: {
      username,
      password,
      ...loginHistoryData,
    },
  });
}

export function getInfo(token) {
  return request({
    url: '/auth/user',
    method: 'get',
  });
}

export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post',
  });
}
export function putPassword(user) {
  return request({
    url: '/Users',
    method: 'put',
    data: {
      "email": user.email,
      "codVerification": user.codVerification,
      "newPassword": user.newPassword,
    }
  });
}
export function forgotPassword(email) {
  return request({
    url: '/Users/ForgotPassword',
    method: 'post',
    data: email
  });
}
