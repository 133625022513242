import request from '../utils/request';
// import request from '../utils/requestTest';
import { getUserData } from '../utils/auth';

export async function getInventoryItens() {
    var OrganizationId = getUserData('organizationId');
  
    return request({
      url: '/Inventory/Organization/' + OrganizationId,
      method: 'get',
    });
  }

export async function getStocables() {
  return request({
    url: '/Stocables',
    method: 'get',
  });
}
export function postItem(Item) {

  return request({
    url: '/Inventory',
    method: 'post',
    data:Item,
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });
}

export function getLots() {
  var OrganizationId = getUserData('organizationId');

  return request({
    url: '/Inventory/Lots/'+OrganizationId,
    method: 'get',
  });
}

export async function getInventoryPlaces() {
  var OrganizationId = getUserData('organizationId');

  return request({
    url: '/Inventory/Places/'+OrganizationId,
    method: 'get',
  });
}