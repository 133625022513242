import React from "react";

import {
  CartesianGrid,
  Legend,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Row,
} from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment";
import { toPng } from "html-to-image";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";

const LineChartGraphic = ({
  customLegend,
  data,
  dataToDownload,
  headerText,
  lines,
  xAxisDataKey,
}) => {
  const [generatedAt, setGeneratedAt] = React.useState("");

  const contentToPrintRef = React.useRef(null);

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const splitedActualDate = moment().format("DD/MM/YYYY HH:mm").split(" ");

    setGeneratedAt(`${splitedActualDate[0]} às ${splitedActualDate[1]}`);
  }, [data]);

  const handleDownloadGraphicImage = async () => {
    try {
      if (contentToPrintRef.current === null) return;

      const imageToDownloadString = await toPng(contentToPrintRef.current, {
        quality: 1,
        pixelRatio: 2,
      });

      if (!imageToDownloadString || imageToDownloadString === "")
        throw new Error("Ocorreu um erro ao gerar a imagem do gráfico.");

      const link = document.createElement("a");
      link.download = `${headerText.replaceAll(
        " ",
        ""
      )}GeneratedAt${generatedAt}.png`;
      link.href = imageToDownloadString;
      link.click();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  const style = {
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    cardBody: {
      padding: "1rem",
    },
    cardHeaderText: {
      color: "black",
      fontWeight: "bold",
      fontSize: "0.97rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };

  return (
    <div ref={contentToPrintRef}>
      <Card className="shadow">
        <CardHeader>
          <CardText className="mb-0 mt-0" style={style.cardHeaderText}>
            <div>
              <span>{headerText}</span>
            </div>
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <span style={{ fontSize: "13px", fontWeight: "normal" }}>
                Gerado em {generatedAt}
              </span>
              <span>
                <CsvDownloadButton
                  className="btn btn-primary"
                  data={!dataToDownload ? data : dataToDownload}
                  filename={`${headerText.replaceAll(" ", "")}${generatedAt}`}
                  style={{ width: "55px", marginRight: "0px" }}
                  data-tip
                  data-for="dataDownload"
                >
                  <i className="fa fa-download" />
                </CsvDownloadButton>
                <ReactTooltip effect="solid" type="info" id="dataDownload">
                  Baixar dados
                </ReactTooltip>
              </span>
              <span>
                <Button
                  onClick={() => handleDownloadGraphicImage()}
                  color="primary"
                  data-tip
                  data-for="viewDownload"
                  style={{ width: "55px", marginRight: "0px" }}
                >
                  <i className={"fa fa-image"} />
                </Button>
                <ReactTooltip effect="solid" type="info" id="viewDownload">
                  Baixar visualização
                </ReactTooltip>
              </span>
            </div>
          </CardText>
        </CardHeader>
        <CardBody style={style.cardBody}>
          <Row>
            <Col style={style.col}>
              <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={xAxisDataKey} />
                <YAxis />
                <Tooltip />
                <Legend content={customLegend} />
                {!lines || lines.length === 0 ? null : lines.map((bar) => bar)}
              </LineChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default LineChartGraphic;
