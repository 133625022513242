import React, { useState } from "react";

const loadGraphicInformations = ({data}) => {
    if(data && data.length > 0) {
        var elementData = [...data];

        elementData.map((contentElement) => {
            contentElement.checked = true;
        })

        return {
            elementData: elementData,
        }
    } else {
        return;
    }
}

export default loadGraphicInformations;