import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import Skeleton from '../Skeleton'

const HorizontalBarGraphicLoadingSkeleton = () => {
  return (
    <Card>
      <CardHeader style={{display: "flex", justifyContent: "space-between"}}>
        <Skeleton variant="rectangle" height="1rem" width="100px" />
        <Skeleton variant="rectangle" height="1rem" width="50px" />
      </CardHeader>
      <CardBody className="pb-0 pt-0 pl-0 pr-0">
        <div style={{height: "430px", display: "flex", gap: "1rem", alignItems: "center", paddingLeft: "5rem"}}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <Skeleton variant="rectangle" height="1rem" width="5rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="5rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="5rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="5rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="5rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="5rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="5rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="5rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="5rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="5rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="5rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="5rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="5rem" padding="4px 0px" />
          </div>
          <div style={{display: "flex", flexDirection: "column"}}>
            <Skeleton variant="rectangle" height="1rem" width="15rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="20rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="13rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="25rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="20rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="18rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="30rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="27rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="19rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="32rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="27rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="16rem" padding="4px 0px" />
            <Skeleton variant="rectangle" height="1rem" width="8rem" padding="4px 0px" />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default HorizontalBarGraphicLoadingSkeleton