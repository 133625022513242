import React from "react";
import CardPresentational from "../../../../Presentational/CardPresentational";

const TotalTrapsInstalledCard = ({
  totalInstalledTrapsNumber,
  cardCustomText,
}) => {
  return (
    <CardPresentational
      number={totalInstalledTrapsNumber}
      text={cardCustomText}
    />
  );
};

export default TotalTrapsInstalledCard;
