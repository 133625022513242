import React, {useState, useEffect} from "react";
import CloropleticMap from "../../../views/pages/Reports/CloropleticMap";
import {points as CreatePoints, polygon as CreatePolygon, pointsWithinPolygon} from "@turf/turf"

const IIPMapContainer  = ({DepositMapInfo, territorializationList}) => {
    const [CloropleticPolygonGroups, setCloropleticPolygonGroups] = useState(null);
    const [HeatMapPoints, setHeatMapPoints] = useState(null);


    useEffect(() => {
        if(DepositMapInfo){
            buildCloropleticInfo();
        }
    }, [territorializationList, DepositMapInfo])

    const buildCloropleticInfo = () => {
        const cloropleticGroups = new Array();
        const imoveisVistoriadosPoints = CreatePoints(DepositMapInfo.imoveisVistoriados.map(({latitude, longitude}) => ([longitude, latitude])));
        const imoveisPositivosPoints = CreatePoints(DepositMapInfo.imoveisPositivos.map(({latitude,longitude}) => [longitude, latitude]))

        const HeatMapPoints = imoveisPositivosPoints.features.map(({geometry}) => ({position: [geometry.coordinates[1], geometry.coordinates[0]], intensity:1}))
        setHeatMapPoints(HeatMapPoints)

        for(const group of territorializationList){

            const cloropleticGroup = {
                name: group.name,
                Polygons: new Array()
            };

            let legends = [];

            for(const territorialization of group.territorializationList){
                if(territorialization.geometryType == "POLYGON"){
                    const turfPolygon = CreatePolygon([territorialization.coordinates.map(({x, y}) => [x, y])])
                    const imoveisVistoriados = pointsWithinPolygon(imoveisVistoriadosPoints, turfPolygon).features.length

                    if(imoveisVistoriados > 0){
                        const imoveisPositivos = pointsWithinPolygon(imoveisPositivosPoints, turfPolygon).features.length
                        const iip = (imoveisPositivos / imoveisVistoriados) * 100;
                        if(iip <= 0.99){
                            turfPolygon.color = "green";
                        }else if( iip <= 3.99){
                            turfPolygon.color = "yellow";
                        }else{
                            turfPolygon.color = "orange";
                        }
                    }else{
                        turfPolygon.color = "transparent";
                    }
                    cloropleticGroup.Polygons.push(turfPolygon)
                }
            }

            legends.push({
                firstItem: 4,
                lastItem: null,
                color: "orange",
                text: `4+`
            });

            legends.push({
                firstItem: 1,
                lastItem: 3.99,
                color: "yellow",
                text: `1 - 3.99`
            });

            legends.push({
                firstItem: 0.0,
                lastItem: 0.99,
                color: "green",
                text: `0 - 0.99`
            });

            cloropleticGroup.legends = legends;

            cloropleticGroups.push(cloropleticGroup)
        }

        setCloropleticPolygonGroups(cloropleticGroups)

    }
    
    return(
        <CloropleticMap
            PolygonsGroup={CloropleticPolygonGroups}
            HeatMap
            PointsHeatMap={HeatMapPoints}
        />
    );
}


export default IIPMapContainer;