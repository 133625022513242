import React from "react";
import { ToastContainer } from "react-toastify";
import { Card, CardHeader, Container, Row, Col } from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";

import DataTable from "react-data-table-component";
import EmployeesExportFilters from "./EmployeesExportFilters";
import moment from "moment";

const EmployeesExportTable = ({
  employees,
  employeesFetch,
  parentCallback,
}) => {
  const columns = [
    {
      name: "Matricula",
      selector: "registration",
      sortable: true,
    },
    {
      name: "Nome",
      selector: "name",
      sortable: true,
    },
    {
      name: "Cargo",
      selector: "place",
      sortable: true,
    },
    {
      name: "Função",
      selector: (e) => (e.function ? e.function.name : "-"),
      sortable: true,
    },
  ];

  return (
    <Row className="mt-12">
      <Col className="mb-12 mb-xl-0" xl="12">
        <Card className="shadow">
          <CardHeader className="border-0" style={{ paddingBottom: "0px" }}>
            <Row className="align-items-center">
              <div className="col">
                <h2 className="mb-0">Funcionários</h2>
                <br />
              </div>
            </Row>
          </CardHeader>
          <Row style={{ textAlign: "center", marginBottom: "1rem" }}>
            <Col>
              <EmployeesExportFilters
                Employees={employeesFetch}
                parentCallback={parentCallback}
              />
            </Col>
          </Row>
          {employees && (
            <DataTable
              noHeader
              defaultSortField="name"
              defaultSortAsc={true}
              pagination
              highlightOnHover
              columns={columns}
              data={employees}
              noDataComponent={"Carregando lista..."}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default EmployeesExportTable;
