import React, { Component } from "react";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import CardPresentational from "../../../Presentational/CardPresentational";
import {
    Col,
} from "reactstrap";

class ArmadilhasNaoReferenciadasContainer extends Component{

    styles = {
        col: {
            paddingLeft: 0,
            paddingRight: "0.2vw"
        }
    }
    state = {
        trapNumber:0,
        textCard:"Total de armadilhas ativas no período",
        trapArray: null,
    }

    componentDidMount(){
        const {data} = this.props;
        const {dataActiveTraps} = this.props;

        if(data !== undefined && data.length > 0){
            this.buildDataToCard();
        }

        if(dataActiveTraps && dataActiveTraps.length > 0){
            var trapArray = [];
            dataActiveTraps.map((activeTrap) => {
                trapArray.push({
                    quantity: activeTrap.data.actives.length,
                    trapType: activeTrap.trapType
                })
            })

            this.setState({trapArray: trapArray})
        }
    }

    async buildDataToCard(){
        //comentário para commit
        const {trapTypeName} = this.props;

        const {data} = this.props;
        const textCard = `Total de ${ARMADILHA_INFO[trapTypeName].apelido} em processo de georreferenciamento`;
        const lastYearData = data[data.length - 1];
        // const trapNumber = (20000 - (lastYearData[lastYearData.length - 1].actives/2));
        const trapNumber = 3331;

        this.setState({trapNumber, textCard});
    }

    componentDidUpdate(prevProps){
        const {data} = this.props; 
        if(data !== undefined && data !== prevProps.data){
            this.buildDataToCard();
        }
    }

    render(){
        const {trapNumber, textCard,trapArray} = this.state
        return(
            trapArray
            ?
            trapArray.map((trap) => (
            <Col style={this.styles.col} xl="4">
                    <CardPresentational 
                        number={trap.quantity}
                        text={`Total de ${trap.trapType} não georeferenciada no período`}
                    />
                </Col>
            ))
            :
            <Col style={this.styles.col} xl="3">
                <CardPresentational 
                    number={trapNumber}
                    text={textCard}
                />
            </Col>
        )
    }
}

export default ArmadilhasNaoReferenciadasContainer;