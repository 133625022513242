import React, { useEffect, useRef, useState } from "react";
import VisitOperacionalFilterPresentational from "../../../components/Presentational/Operacional/VisitOperacionalFilterPresentational";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { Col, Container, Row } from "reactstrap";
import { fetchEmployees } from "../../../services/api/Employee";
import { getUserByEmployeeId } from "../../../services/api/Users";
import { fetchDemands } from "../../../services/api/Demand";
import { getTeam } from "../../../services/api/Team";
import { fetchTerritorializations, getAllTypeTerritorializations } from "../../../services/api/territorialization";
import MainPeriodComponent from "../../../components/Inputs/MainPeriodComponent";
import GeographicFilterPresentational from "../../../components/Presentational/Filters/GeographicFilterPresentational";
import { getEpidemiologicalPeriod } from "../../../services/utils/Entomologico/Period";
import { buildEpidemiologicalLabels } from "../../../services/utils/Entomologico/Labels";
import getWeeks from "../../../services/utils/epidemiologicalWeek";
import { getUserData } from "../../../services/utils/auth";
import { getSISPNCDNumbers, getVisitGeoRef } from "../../../services/api/Operacional";
import {CSVLink} from "react-csv";
import LoadingSpin from "react-loading-spin";
import "./DashboardSISPNCD.css";
import DepositSISPNCDBar from "../../../components/Containers/SISPNCD/DepositSISPNCDBar";
import ImoveisSISPNCDBar from "../../../components/Containers/SISPNCD/ImoveisSISPNCDBar";
import CardPresentational from "../../../components/Presentational/CardPresentational";
import PendenciasSISPNCDBar from "../../../components/Containers/SISPNCD/PendenciasSISPNCDBar";
import DepositoLarvicidaSISPNCDBar from "../../../components/Containers/SISPNCD/DepositoLarvicidaSISPNCDBar";
import OperacionalMapContainer from "../../../components/Containers/SISPNCD/Maps/MapaOperacional";
import RealtyTypeFilterPresentational from "../../../components/Presentational/Filters/RealtyTypeFilterPresentational";
import { REALTY_TYPES } from "../../../constants/RealtyTypeConstant";
import VisitSituationFilterPresentational from "../../../components/Presentational/Filters/VisitSituationsFilterPresentational";
import { VISIT_SITUATIONS } from "../../../constants/VisitSituationsConstant";
import { getTodayEpidemiologicalWeek } from "../../../services/utils/todayEpidemiologicalWeek";
import TodayEpidemiologicalWeekComponent from "../../../components/Containers/Entomologico/todayEpidemiologicalWeek/TodayEpidemiologicalWeekComponent";

const DashboardSISPNCD = () => {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [demandIdList, setDemandIdList] = useState([]);
    const [teamIdList, setTeamIdList] = useState([]);
    const [employeeIdList, setEmployeeIdList] = useState([]);
    const [demandId, setDemandId] = useState("00000000-0000-0000-0000-000000000000");
    const [teamId, setTeamId] = useState("00000000-0000-0000-0000-000000000000");
    const [employeeId, setEmployeeId] = useState("00000000-0000-0000-0000-000000000000");
    const [territorializationList, setTerritorializationList] = useState([]);
    const [selectedTerritorializations, setSelectedTerritorializations] = useState([]);
    const [selectedRealties, setSelectedRealties] = useState([]);
    const [selectedVisitSituations, setSelectedVisitSituations] = useState([]);
    const teamEmployees = useRef([]);
    const [months, setFilterMonths] = useState([]);
    const [year, setFilterYear] = useState([]);
    const [weeks, setFilterWeeks] = useState([]);
    const [weekValue1, setWeekValue1] = useState([]);
    const [weekValue2, setWeekValue2] = useState([]);
    const [isFilterVisible, setFilterVisibility] = useState(true);
    const [chartLabels, setChartLabels] = useState([]);
    const [chartPeriods, setChartPeriods] = useState([]);
    const [visitData, setVisitData] = useState(null);
    const [visitGeoRef, setVisitGeoRef] = useState(null);
    const [csvHeaders, setCSVHeaders] = useState(null);
    const [csvData, setCSVData] = useState(null);
    const [isCSVLoading, setCSVLoading] = useState(true);
    const [geoRefLoading, setGeoRefLoading] = useState(true);
    const [chartsLoading, setChartsLoading] = useState(true);
    const [todayEpidemiologicalWeek, setTodayEpidemiologicalWeek] = useState("1");


    useEffect(() => {
        
        const actualYear = moment().format("YYYY");
        const endDateString = moment().format("YYYY-MM-DD");
        const startDateString = moment().subtract(3, 'months').format("YYYY-MM-DD");
        
        setStartDate(startDateString);

        setTodayEpidemiologicalWeek(getTodayEpidemiologicalWeek());

        setEndDate(endDateString);

        const year = [{label:actualYear, value:actualYear}];
        //setFilterYear(year);
        buildYearsLabelsAndPeriods(year);

        const reqEmployees = fetchEmployees();
        reqEmployees.then(async response => {
            const employeeList = new Array({
                value:"00000000-0000-0000-0000-000000000000",
                label:"Selecione"
            });

            for(let i = 0; i < response.data.length; i++){
                const user = await getUserByEmployeeId(response.data[i].id);
                if(user.data.userId !== "00000000-0000-0000-0000-000000000000"){
                    employeeList.push({
                        value:user.data.userId,
                        label:response.data[i].name,
                    })
                }
            };
            setEmployeeIdList(employeeList);
        });

        const reqDemands = fetchDemands();
        reqDemands.then(response => {
            const demandList = [{value:"00000000-0000-0000-0000-000000000000", label:"Selecione"}].concat(response.data.map(demand => {return {value:demand.id, label:demand.serviceOrder}}));
            setDemandIdList(demandList);
        });

        const reqTeams = getTeam();
        reqTeams.then(async response => {
            let teamList = [
                {value:"00000000-0000-0000-0000-000000000000",
                label:"Selecione"}
            ]

            for(const team of response.data){
                for(const teamMember of team.members){
                    const user = await getUserByEmployeeId(teamMember.employeeId);
                    if(user.data.userId !== "00000000-0000-0000-0000-000000000000"){
                        teamMember.userId = user.data.userId
                    }
                }
                teamList = teamList.concat({value: team.id, label: team.name, members:team.members});
            }

            setTeamIdList(teamList);
        });

        const reqTypeTerritorializations = getAllTypeTerritorializations();
        reqTypeTerritorializations.then( async typeResponse => {
            const listTerritorialization = new Array();
            for(const type of typeResponse.data){
                try{
                    if(type.typeGeometry === "Polygon"){
                        const territorializationRequest = await fetchTerritorializations(type.id);
                        listTerritorialization.push({
                            name:type.name,
                            label:type.name, 
                            options: territorializationRequest.data.map(territorialization => {return {label:territorialization.name, value:territorialization.id}}),
                            territorializationList: territorializationRequest.data
                        })
                    }
                }catch(error){
                }
            }
            setTerritorializationList(listTerritorialization)
        });

    }, [])

    function handleChangeFilter(field, value){
        switch(field){
            case "startDate":
                if(moment(value).isAfter(endDate)){
                  toast.error("Período de data inválido!");
                }else{
                  setStartDate(value);
                }
            break;
            case "endDate":
              if(moment(value).isBefore(startDate)){
                toast.error("Período de data inválido!");
              }else{
                setEndDate(value);
              }
            break;
            case"demandId":
              if(value){
                setDemandId(value.value);
              }else{
                setDemandId("00000000-0000-0000-0000-000000000000")
              }
            break;
            case "teamId":
              if(value){
                let employeeUserIds = new Array();
                for(const member of value.members){
                  if(member.userId){
                    employeeUserIds.push(member.userId);
                  }
                }
                teamEmployees.current = employeeUserIds;
              }else{
                teamEmployees.current = new Array();
              }
      
              setTeamId(value);
            break;
            case "employeeId":
              if(value){
                setEmployeeId(value.value);
              }else{
                setEmployeeId("00000000-0000-0000-0000-000000000000")
              }
            break;
            case "territorialization":
              if(value !== null){
                //colocando as territorializações para ficarem selecionadas no filtro.
                setSelectedTerritorializations(value);
              }else{
                setSelectedTerritorializations([]);
              }
            break;
            case "realtyType":
              if(value !== null){
                setSelectedRealties(value);
              }else{
                setSelectedRealties([]);
              }
            break;
            case "visitSituation":
              if(value !== null){
                setSelectedVisitSituations(value);
              }else{
                setSelectedVisitSituations([]);
              }
            break;
        }
    }

    function changePeriod(e, field){
        switch(field){
          case 'Year': 
            if(e === null){
              setFilterYear([]);
              setFilterMonths([]);
              setFilterWeeks([]);
              break;
            }else{
              e = e.sort((a,b) => parseInt(a.value) - parseInt(b.value));
            }
    
            setFilterYear(e !== null ? e : []);
          break;
    
          case 'Month':
            if(e !== null){
              setFilterWeeks([]);
              e = e.sort((a,b) => parseInt(a.value) - parseInt(b.value));
            }
    
            setFilterMonths(e !== null ? e : []);
          break;
    
          case 'EpiWeek':
            if(e !== null){
              setFilterMonths([]);
              e = e.sort((a,b) => parseInt(a.value) - parseInt(b.value));
            }
            
            setFilterWeeks(e !== null ? e : []);
          break;

          case 'Week1':
            if (e !== null) {
              setFilterMonths([]);
            }
            setWeekValue1(e !== null ? e : []);
          break;

          case 'Week2':
            if (e !== null) {
              setFilterMonths([]);
            }
            setWeekValue2(e !== null ? e : []);
          break;
        }
    }

    useEffect(() => {
        if(chartLabels.length > 0){
          fetchData();
        }
    }, [chartLabels]);

    useEffect(() => {
        prepareCSVData();
    }, [visitData])

    function fetchData(){
      setCSVLoading(true);
      setChartsLoading(true);
      setGeoRefLoading(true);
      const organizationId = getUserData("organizationId");
      const employees = teamEmployees.current;

      if(employeeId != "00000000-0000-0000-0000-000000000000" && !employees.includes(employeeId)){
        employees.push(employeeId)
      }

      let realtyTypes = selectedRealties.map(({value}) => value);
      let visitSituations = selectedVisitSituations.map(({value}) => value);
      let territorializationsToFilter = selectedTerritorializations.map(({value}) => value);

      for(const period of chartPeriods){
        period.organizationId = organizationId;
        period.demandId = demandId;
        period.territorializations = territorializationsToFilter
        period.usersIds = employees;
        period.realtyTypes = realtyTypes
        period.visitSituations = visitSituations
      }

      getSISPNCDNumbersData();
      getVisitsGeoRef();
    }

    async function getSISPNCDNumbersData(){
        const visitData = await getSISPNCDNumbers(chartPeriods);
        setVisitData(visitData.data);
        setChartsLoading(false);
    }

    async function getVisitsGeoRef(){
      const visitGeoRef = await getVisitGeoRef(chartPeriods);
      setVisitGeoRef(visitGeoRef.data);
      setGeoRefLoading(false);
    }

    async function prepareCSVData(){
      if(visitData){
        if(!csvHeaders){
          const csvHeaders = [
            {label: "Total quart. Concluídos", key:"quarteiroes"},
            {label: "Residências", key: "residencia"},
            {label: "Comércios", key: "comercio"},
            {label:"TB", key: "tb"},
            {label: "P.E", key: "pe"},
            {label: "Outros Imóveis", key: "outros"},
            {label: "Total de Imóveis", key: "totalVisitas"},
            {label: "Amostras Coletadas", key: "amostrasColetadas"},
            {label: "Recusas", key: "recusa"},
            {label: "Fechados", key: "fechado"},
            {label: "Recuperados", key: "recuperacao"},
            {label: "Depósitos A1", key: "a1"},
            {label: "Depósitos A2", key: "a2"},
            {label: "Depósitos B", key: "b"},
            {label: "Depósitos C", key: "c"},
            {label: "Depósitos D1", key: "d1"},
            {label: "Depósitos D2", key: "d2"},
            {label: "Depósitos E", key: "e"},
            {label: "Total de Depósitos", key: "totalDepositos"},
            {label: "Depósitos Eliminados", key: "depositosEliminados"},
            {label: "Qtd Larvicida L1", key: "quantidadeL1"},
            {label: "Qtd Dep. Trat. Larvicida L1", key: "depositosL1"},
            {label: "Qtd Larvicida L2", key: "quantidadeL2"},
            {label: "Qtd Dep. Trat. Larvicida L2", key: "depositosL2"},
            {label: "Total de agentes no período", key: "totalAgentes"},
            {label: "Total de dias trab. no período", key: "diasTrabalhados"},
          ]

          setCSVHeaders(csvHeaders);
        }

        const csvData = [visitData];

        setCSVData(csvData);
        setCSVLoading(false);
      }
    }

    function applyFilter(){
        if(year.length > 0){
          buildYearsLabelsAndPeriods(year);
        }else{
          buildLabelsAndPeriods(startDate, endDate);
        }
    }

    async function buildLabelsAndPeriods (startDate, endDate){
        const labels = await buildEpidemiologicalLabels({startDate, endDate}); //semana epidemiológica
        const periods = new Array({year:"", periods:[]});
        let labelsToCharts = new Array();
        for(let i = 0; i < labels.length; i++){
          for(let labelIndex = 0; labelIndex < labels[i].labels.length; labelIndex++){
            const {finalDate, beginDate} = await getEpidemiologicalPeriod({labels:labels[i].labels, labelIndex, startDate, endDate, year:labels[i].year, lastYear: labels[labels.length - 1].year, firstYear:labels[0].year}); //semana epidemiológica
            labelsToCharts.push(labels[i].labels[labelIndex] + "/"+ labels[i].year);
            periods[0].periods.push({finalDate, beginDate});
          }
        }
    
        setChartPeriods(periods);
        setChartLabels(labelsToCharts);
    }
    
    async function buildYearsLabelsAndPeriods(years = new Array()){
        const labels = new Array();
        const periods = new Array();
    
        if(months.length === 0 && weeks.length ===0){
          for(let i = 1; i <= 53; i++){
            labels.push(i);
          }
          
          for(let i = 0; i < years.length; i++){
            const epidemiologicalWeek = getWeeks().filter(ew => ew.year === years[i].value);
            periods.push({year:years[i].value, periods: epidemiologicalWeek[0].weeks.map((ew) => {return {finalDate:moment(ew.endDate, "DD/MM/YYYY").format("YYYY-MM-DD"), beginDate:moment(ew.beginDate, "DD/MM/YYYY").format("YYYY-MM-DD")}})});  
          }
        }else if(weeks.length === 0){
          months.forEach(month => {
            labels.push(month.label);
          })
    
          for(let i = 0; i < years.length; i++){
            const monthPeriods = [];
    
            for(let j = 0; j < months.length; j++){
              const beginDate = moment(new Date(years[i].value, months[j].value, 1)).format("YYYY-MM-DD");
              const finalDate = moment(beginDate).add(1, 'month').subtract(1, 'day').format("YYYY-MM-DD");
              monthPeriods.push({beginDate, finalDate});
            }
    
            periods.push({year:years[i].value, periods:monthPeriods});
          }
        }else{
          weeks.forEach(weeks => {
            labels.push(weeks.label);
          })
    
          for(let i = 0; i < years.length; i++){
            const weeksPeriods = [];
            const epiWeeks = getWeeks().filter(ew => ew.year === years[i].value);
    
            for(let j = 0; j < weeks.length; j++){
              if(epiWeeks[0].weeks[weeks[j].value] !== undefined){
                const beginDate = moment(epiWeeks[0].weeks[weeks[j].value].beginDate, "DD/MM/YYYY").format("YYYY-MM-DD");
                const finalDate = moment(epiWeeks[0].weeks[weeks[j].value].endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
                weeksPeriods.push({beginDate, finalDate});
              }
            }
            periods.push({year:years[i].value, periods:weeksPeriods});
          }
        }
    
        setChartPeriods(periods);
        setChartLabels(labels);
    }

    return (
        <>
          <ToastContainer/>
          <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8 mb-5">
            <Container className="filter-container-sispncd">
            
                <button className="btn btn-primary hide-btn-sispncd" onClick={() => {setFilterVisibility(!isFilterVisible)}}>{isFilterVisible ? "Esconder Filtro" : "Mostrar Filtro"}</button>
                
                {isFilterVisible ? 
                    <button  className="btn btn-primary filter-btn-sispncd" onClick={applyFilter}>Filtrar</button>
                :null}
            </Container>

            {isFilterVisible && 
              <>
                <VisitOperacionalFilterPresentational
                    handleChangeFilter = {handleChangeFilter}
                    employeeId = {employeeId}
                    employeeIdList={employeeIdList}
                    demandId = {demandId}
                    demandIdList = {demandIdList}
                    teamId = {teamId}
                    teamIdList = {teamIdList}
                />
                <Row>
                <MainPeriodComponent 
                    months={months}
                    years={year}
                    weeks={weeks}
                    weekValue1={weekValue1}
                    weekValue2={weekValue2}
                    showWeekToWeek
                    changePeriod={changePeriod}
                />
                
                  <Col className="col-4 responsive-field-filter-sispncd-medium">
                      <span className="h4 text-white">Data início</span>
                      <input value={startDate} name={"startDate"} onChange={(e) => {handleChangeFilter(e.target.name, e.target.value)}} type="date" className="form-control" />
                  </Col>
                  <Col className="col-4 responsive-field-filter-sispncd-medium">
                      <span className="h4 text-white">Data fim</span>
                      <input value={endDate} name={"endDate"} onChange={(e) => {handleChangeFilter(e.target.name, e.target.value)}} type="date" className="form-control" />
                  </Col>
                  <GeographicFilterPresentational
                      Territorializations={territorializationList}
                      handleChangeFilter={handleChangeFilter}
                      SelectedTerritorializations={selectedTerritorializations}
                  />
                  <RealtyTypeFilterPresentational
                    RealtyTypes={REALTY_TYPES}
                    SelectedRealtyType={selectedRealties}
                    handleChangeFilter={handleChangeFilter}
                  />
                  <VisitSituationFilterPresentational
                    VisitSituations={VISIT_SITUATIONS}
                    SelectedRealtyType={selectedVisitSituations}
                    handleChangeFilter={handleChangeFilter}
                  />
                </Row>
                <Row>
                  <Col className="col-4 col-md-4 col">
                  </Col>
                  <TodayEpidemiologicalWeekComponent 
                    todayEpidemiologicalWeek={todayEpidemiologicalWeek}
                  />
                  <Col className="col-4 col-md-4 col">
                  </Col>
                </Row>
              </>
            }
            <Row>
                <Col className="col-4"></Col>
                <Col className="responsive-field-filter-sispncd-full" style={{marginTop:10, marginBottom: 20}}>
                  <CSVLink data={csvData || []} headers={csvHeaders || []} filename={`relatorio_sispncd_${moment().format("YYYY_MM_DD")}`} separator=";" aria-disabled={isCSVLoading}>
                    <button className="btn btn-primary" disabled={isCSVLoading}> 
                      {isCSVLoading ? 
                        <LoadingSpin primaryColor="#fff" size={24}/>
                      :
                        <>
                          <i class="fa fa-download" aria-hidden="true" style={{paddingRight: 10}}/> Exportar dados SISPNCD em CSV
                        </>
                      }
                    </button>
                  </CSVLink>
                </Col>
                <Col className="col-4"></Col>
              </Row>
              <Row >
                <Col className="col-3 responsive-field-filter-sispncd-full">
                  {chartsLoading ? 
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                      <LoadingSpin primaryColor="#fff" size={28}/>
                    </div>
                  : 
                    <CardPresentational
                      number={visitData ? visitData.quarteiroes : 0}
                      text = {"Quarteirões Concluídos"}
                    />
                  }
                </Col>
                <Col className="col-3 responsive-field-filter-sispncd-full">
                  {chartsLoading ? 
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                      <LoadingSpin primaryColor="#fff" size={28}/>
                    </div>
                  : 
                    <CardPresentational
                      number={visitData ? visitData.amostrasColetadas : 0}
                      text = {"Amostras Coletadas"}
                    />
                  }
                </Col>
                <Col className="col-3 responsive-field-filter-sispncd-full">
                  {chartsLoading ? 
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                      <LoadingSpin primaryColor="#fff" size={28}/>
                    </div>
                  : 
                    <CardPresentational
                      number={visitData ? visitData.depositosEliminados : 0}
                      text = {"Depósitos Eliminados"}
                    />
                  }
                </Col>
                <Col className="col-3 responsive-field-filter-sispncd-full">
                  {chartsLoading ? 
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                      <LoadingSpin primaryColor="#fff" size={28}/>
                    </div>
                  : 
                    <CardPresentational
                      number={visitData ? visitData.totalAgentes : 0}
                      text = {"Total de Agentes"}
                    />
                  }
                </Col>
                <Col className="col-3 responsive-field-filter-sispncd-full">
                  {chartsLoading ? 
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                      <LoadingSpin primaryColor="#fff" size={28}/>
                    </div>
                  : 
                    <CardPresentational
                      number={visitData ? visitData.diasTrabalhados : 0}
                      text = {"Dias Trabalhados"}
                    />
                  }
                </Col>
                <Col className="col-3 responsive-field-filter-sispncd-full">
                  {chartsLoading ? 
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                      <LoadingSpin primaryColor="#fff" size={28}/>
                    </div>
                  : 
                    <CardPresentational
                      number={visitData ? visitData.quantidadeL1 : 0}
                      text = {"Qtd. Larvicida L1"}
                    />
                  }
                </Col>
                <Col className="col-3 responsive-field-filter-sispncd-full">
                  {chartsLoading ? 
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                      <LoadingSpin primaryColor="#fff" size={28}/>
                    </div>
                  : 
                    <CardPresentational
                      number={visitData ? visitData.quantidadeL2 : 0}
                      text = {"Qtd. Larvicida L2"}
                    />
                  }
                </Col>
              </Row>
          </Container>
          <Container fluid className="mt--7">
            <Row>
              <Col className="col-12 mb-4 responsive-field-filter-sispncd-full">
                {geoRefLoading ? 
                  <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <LoadingSpin primaryColor="#fff" size={28}/>
                  </div>
                : 
                  <OperacionalMapContainer
                    data={visitGeoRef}
                    territorializationList={territorializationList}
                    territorializationFiltered={selectedTerritorializations}
                  />
                }
              </Col>
              <Col className="col-12 mb-4 responsive-field-filter-sispncd-full">
                {chartsLoading ? 
                  <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <LoadingSpin primaryColor="#000" size={28}/>
                  </div>
                : 
                  <ImoveisSISPNCDBar
                    data={visitData}
                  />
                }
              </Col>
              <Col className="col-12 mb-4 responsive-field-filter-sispncd-full">
                {chartsLoading ? 
                  <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <LoadingSpin primaryColor="#000" size={28}/>
                  </div>
                : 
                  <PendenciasSISPNCDBar
                    data={visitData}
                  />
                }
              </Col>
              <Col className="col-12 mb-4 responsive-field-filter-sispncd-full">
                {chartsLoading ? 
                  <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <LoadingSpin primaryColor="#000" size={28}/>
                  </div>
                : 
                  <DepositSISPNCDBar
                    data={visitData}
                  />
                }
              </Col>
              <Col className="col-12 mb-4 responsive-field-filter-sispncd-full">
                {chartsLoading ? 
                  <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <LoadingSpin primaryColor="#000" size={28}/>
                  </div>
                : 
                  <DepositoLarvicidaSISPNCDBar
                    data={visitData}
                  />
                }
              </Col>
            </Row>
          </Container>
        </>
    )
}

export default DashboardSISPNCD;