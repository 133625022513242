import request from '../utils/request';
import { getUserData } from '../utils/auth';
//import request from '../utils/requestTests/requestTestSinan';

export function upload(file) {
  
  file.append("organizationId", getUserData("organizationId"));

  return request({

    url: '/Sinan',
    method: 'post',
    headers: new Headers({
      'Content-Type': 'multipart/form-data'
    }),
    data: file
  });
}

export function getNotificationCases(filter) {
  return request({
    url: '/Sinan/Notifications',
    method: 'post',
    data: filter
  });
}

export async function getNotificationByTerritorialization(filter) {
  return request({
    url: '/Sinan/NotificationsByTerritorialization',
    method: 'post',
    data: filter
  });
}

export function getNotificationsByReportingUnity(filter) {
  return request({
    url: '/Sinan/NotificationsByReportingUnity',
    method: 'post',
    data: filter
  });
}

export function getNotificationsGeoRef(filter) {
  return request({
    url: '/Sinan/NotificationsGeoRef',
    method: 'post',
    data: filter
  });
}

export function getNotificationNumbersAged(filter) {
  return request({
    url: '/Sinan/NotificationsAged',
    method: 'post',
    data: filter
  });
}

export function getUnidadesNotificadoras() {
  var organizationId = getUserData("organizationId");

  return request({
    url: '/Sinan/NotifyingUnit/' + organizationId,
    method: 'get'
  });
  
}

export function getUploadHistory(Desease, Year) {
  return request({
    url: '/Sinan/UploadStatus/' + getUserData("organizationId") + '/' + Desease + '/' + Year,
    method: 'get'
  });
}


