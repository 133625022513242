import { MDBDataTableV5 } from "mdbreact";
import React from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Card,
  } from "reactstrap";
import { getAllTypeTerritorializations, fetchTerritorializations } from "../../../services/api/territorialization";
import {Select} from "../../../components/Wrappers/SelectAll";
import "./Territorializacao.css";

class TerritorializationModalComponent extends React.Component {
    
  state={
    territorializations:this.props.territorializations,
    typeOptions:[],
    selecteds:this.props.selecteds || [],
    typeTerritorialization:"",
    territorializationsOptions: []
  }

  changeOptions = (optionsSelected) => {
    let {territorializations, selecteds} = this.state

    if(optionsSelected === null){
        selecteds = selecteds.filter(s => !territorializations.some((territorialization) => territorialization.id === s.value));
    }else{
        selecteds = selecteds.filter(s => !territorializations.some((territorialization) => territorialization.id === s.value)).concat(optionsSelected)
    }

    this.setState({selecteds})
    this.props.checkOpt(selecteds);  
}

async componentDidMount(){
    const typeTerritorializationRequest = await getAllTypeTerritorializations();
    let typeOptions = typeTerritorializationRequest.data;

    this.setState({typeOptions: typeOptions}, async () => {
        if(typeTerritorializationRequest.data.length > 0){
            if(this.state.territorializations.length == 0){
                const territorializationsRequest = await fetchTerritorializations(typeTerritorializationRequest.data[0].id);
                let territorializations = territorializationsRequest.data.filter(t => t.status == "Ativa");
                const territorializationsOptions = this.buildOptions(territorializations);
                this.setState({territorializations, territorializationsOptions});
            }
            
            this.setState({typeTerritorialization: typeTerritorializationRequest.data[0].id});
            
        }
    });
}

    changeTerritorialization = async (e) =>{
        var type = e.nativeEvent.target.value;
        this.setState({typeTerritorialization:type});
        const territorializationsRequest = await fetchTerritorializations(type);
        let territorializations = territorializationsRequest.data.filter(t => t.status == "Ativa");
        const territorializationsOptions = this.buildOptions(territorializations);
        this.setState({territorializations, territorializationsOptions});
        
        this.props.toggleCallback(type, true);
    }


    buildOptions(territorializations){
        const options = [];

        for(const territorialization of territorializations){

            const opt = {...territorialization, value: territorialization.id, label:territorialization.name}

            options.push(opt);
        }

        return options;
    }

    changeFilter(e){
        const {territorializations} = this.state
        const filterValue = e.target.value.toLowerCase().trim()
        const filteredTerritorializations = territorializations.filter(({name}) => name.toLowerCase().trim().includes(filterValue))
        const territorializationsOptions = this.buildOptions(filteredTerritorializations)
        this.setState({territorializationsOptions})
    }

    render() {
        const {selecteds, territorializationsOptions} = this.state
      return (
        <>
         
         <Modal size="lg" isOpen={this.props.openModal} fade="true"  toggle={()=>this.props.toggleCallback(this.state.typeTerritorialization)}>
                <ModalHeader toggle={()=>this.props.toggleCallback(this.state.typeTerritorialization, false)}>Territorialização</ModalHeader>
                <ModalBody>           
                    <div className="row"> 
                        <div className="col-12">
                            <span className="h6 text-black">Nível</span>
                            <select onChange={e=>this.changeTerritorialization(e)} className="form-control">
                                {this.state.typeOptions.map(opt => {
                                    return (
                                        <option value={opt.id}>{opt.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <span className="h6 text-black">Territorializações</span>
                            <Select
                                styles={{
                                    control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                                    option: styles =>({...styles, fontSize:"0.875rem"}),
                                    valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                                    singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                                    placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"})
                                }}
                                value={territorializationsOptions.filter(t => selecteds.some(ts => ts.value === t.value))}
                                isMulti={true}
                                placeholder={"Selecione"}
                                isClearable={true}
                                isDisabled={this.state.typeTerritorialization === ''}
                                blurInputOnSelect={false}
                                closeMenuOnSelect={false}
                                allowSelectAll={true}
                                noOptionsMessage={() => "Não há territorializações"}
                                allOption={{label: "Todas", value: "*"}}
                                onChange={this.changeOptions}
                                options={territorializationsOptions}
                            />
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <div className="col-4 responsive-field-form-territorializacao-full">
                        <button className="btn btn-primary" onClick={()=>this.props.toggleCallback(this.state.typeTerritorialization, false)} >Finalizado</button>
                    </div> 
                </ModalFooter>
            </Modal>
        </>
      );
    };
  }
  
  export default TerritorializationModalComponent;