import React from "react";
import { Col, Row } from "reactstrap";
import StatusTrapCard from "../../../../components/Containers/Entomologico/Cards/StatusTrapCard";
import CardLoadingSkeleton from "../../../../components/ui/Loading/CardLoadingSkeleton";

const GlobalTrapsByColorStatusCards = ({trapsByColorStatusData}) => {
  const [trapsData, setTrapsData] = React.useState(null);

  React.useEffect(() => {
    if (!trapsByColorStatusData || trapsByColorStatusData.length === 0) return;

    const { upToThirtyDays, betweenThirtyOneAndSixtyDays, afterSixtyDays } =
      trapsByColorStatusData.reduce(
        (accumulator, current) => {
          return {
            upToThirtyDays: accumulator.upToThirtyDays + current.upToThirtyDays,
            betweenThirtyOneAndSixtyDays:
              accumulator.betweenThirtyOneAndSixtyDays +
              current.betweenThirtyOneAndSixtyDays,
            afterSixtyDays: accumulator.afterSixtyDays + current.afterSixtyDays,
          };
        },
        {
          upToThirtyDays: 0,
          betweenThirtyOneAndSixtyDays: 0,
          afterSixtyDays: 0,
        }
      );

    setTrapsData({
      upToThirtyDays,
      betweenThirtyOneAndSixtyDays,
      afterSixtyDays,
    });
  }, [trapsByColorStatusData]);

  const styles = {
    grid: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    row: {
      marginLeft: 0,
      marginRight: 0,
    },
    col: {
      paddingLeft: 0,
      paddingRight: "0.2vw",
    },
  };

  return (
    <>
    {
      !trapsData ?

      <Row style={{ ...styles.row, justifyContent: "center" }}>
        <Col style={styles.col} xl={3}>
          <CardLoadingSkeleton />
        </Col>
        <Col style={styles.col} xl={3}>
          <CardLoadingSkeleton />
        </Col>
        <Col style={styles.col} xl={3}>
          <CardLoadingSkeleton />
        </Col>
      </Row>
      :
      <Row style={{ ...styles.row, justifyContent: "center" }}>
        <Col style={styles.col} xl={3}>
          <StatusTrapCard
            cardText={
              <span>
                Total instalação/manutenção recém realizada <br />
                (até 30 dias)
                <span
                  className="badge badge-danger"
                  style={{
                    width: "50px",
                    marginLeft: "5px",
                    backgroundColor: "#76ea79",
                  }}
                >
                  &nbsp;
                </span>
              </span>
            }
            cardValue={trapsData ? trapsData.upToThirtyDays : 0}
          />
        </Col>
        <Col style={styles.col} xl={3}>
          <StatusTrapCard
            cardText={
              <span>
                Total em atenção para próxima manutenção <br />
                (31 a 60 dias)
                <span
                  className="badge badge-danger"
                  style={{
                    width: "50px",
                    marginLeft: "5px",
                    backgroundColor: "#fff200",
                  }}
                >
                  &nbsp;
                </span>
              </span>
            }
            cardValue={trapsData ? trapsData.betweenThirtyOneAndSixtyDays : 0}
          />
        </Col>
        <Col style={styles.col} xl={3}>
          <StatusTrapCard
            cardText={
              <span>
                Total que ultrapassou o prazo de manutenção <br />
                (acima de 60 dias)
                <span
                  className="badge badge-danger"
                  style={{
                    width: "50px",
                    marginLeft: "5px",
                    backgroundColor: "#ff0000",
                  }}
                >
                  &nbsp;
                </span>
              </span>
            }
            cardValue={trapsData ? trapsData.afterSixtyDays : 0}
          />
        </Col>
      </Row>
    }      
    </>
  );
};

export default GlobalTrapsByColorStatusCards;
