import React from "react";

import "../Maintenance/MaintenanceAdiFormPrint.styles.css";

const UninstallAdiFormPrint = React.forwardRef(
  ({ organizationId, trapTypeName }, ref) => {
    const getFormLogoInformations = {
      "559a16ab-5da8-41ba-96b8-6e9f55feddd8": {
        path: "https://i.ibb.co/0MT2jbZ/sp.png",
        styles: { width: "150px" },
      },
      "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30": {
        path: "https://i.ibb.co/m014LrJ/goiania.png",
        styles: { width: "150px" },
      },
      "24d125ef-15e6-4bd9-9111-c0b3d50a8009": {
        path: "https://i.ibb.co/W6PfRCG/cotia-logo.png",
        styles: { width: "150px" },
      },
      "d67ef2b0-92b1-40bf-aba6-0e06070e6091": {
        path: "https://i.ibb.co/wdRDctz/dracena-logo.png",
        styles: { width: "70px" },
      },
      "4d4b0460-c8de-474a-9bb9-44e2ffa272b6": {
        path: "https://i.ibb.co/cx4yhw3/ribeirao-pires-logo.png",
        styles: { width: "60px" },
      },
    };

    const logoInformations = getFormLogoInformations[organizationId];

    return (
      <section className="document--container--maintenance" ref={ref}>
        <header className="document__header--maintenance">
          <div className="document__header--maintenance--image">
            <p>FORMULÁRIO DE DESINSTALAÇÃO DE {trapTypeName}</p>
            <img
              src={logoInformations ? logoInformations.path : ""}
              style={logoInformations ? logoInformations.styles : {}}
              alt="Logo do município"
            />
          </div>
          <div className="document__header--maintenance--content">
            <table class="document__table document__table--territorialization">
              <thead>
                <tr>
                  <th colspan="2">INFORMAÇÕES</th>
                </tr>
              </thead>
              <tbody>
                {organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8" ?
                  <>
                    <tr>
                      <td class="detalhes">
                        Região do Monitoramento (Nome da UVIS):
                        <br />
                        <br />
                        &nbsp;
                      </td>
                      <td class="detalhes">
                        Setor Ambiental:
                        <br />
                        <br />
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td class="detalhes">
                        Quadra:
                        <br />
                        <br />
                        &nbsp;
                      </td>
                      <td class="detalhes">
                        Distrito Administrativo:
                        <br />
                        <br />
                        &nbsp;
                      </td>
                    </tr>
                  </>
                  : organizationId === "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30" ?
                    <>
                      <tr>
                        <td class="detalhes">
                          Distrito Sanitário:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                        <td class="detalhes">
                          Setor Ambiental:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td class="detalhes">
                          Quarteirão:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                        <td class="detalhes">
                          Distrito Administrativo:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                      </tr>
                    </>
                    :
                    <>
                      <tr>
                        <td class="detalhes">
                          Distrito Sanitário:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                        <td class="detalhes">
                          Setor Ambiental:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td class="detalhes">
                          Quarteirão:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                        <td class="detalhes">
                          Distrito Administrativo:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                      </tr>
                    </>
                }
              </tbody>
            </table>
            <table class="document__table document__table--agents">
              <thead>
                <tr>
                  <th colspan="2">SERVIDOR DE SAÚDE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Matrícula: <div style={{ marginRight: "20px" }}></div>
                    Nome:
                  </td>
                  <td>
                    Matrícula: <div style={{ marginRight: "20px" }}></div> Nome:
                  </td>
                </tr>
                <tr>
                  <td>
                    Matrícula: <div style={{ marginRight: "20px" }}></div> Nome:
                  </td>
                  <td>
                    Matrícula: <div style={{ marginRight: "20px" }}></div> Nome:
                  </td>
                </tr>
                <tr>
                  <td>
                    Matrícula: <div style={{ marginRight: "20px" }}></div> Nome:
                  </td>
                  <td>
                    Matrícula: <div style={{ marginRight: "20px" }}></div> Nome:
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="document__table document__table--date">
              <thead>
                <tr>
                  <th colSpan="2">DATA DA ATIVIDADE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>_____/_____/_____</td>
                </tr>
              </tbody>
            </table>
          </div>
        </header>
        <main>
          <table class="document__table document__table--visit">
            <thead>
              <tr>
                <th></th>
                <th style={{ marginRight: "100px", width: "140px" }}>
                  (*) Número da armadilha
                </th>
                <th style={{ marginRight: "200px", width: "210px" }}>
                  (*) Logradouro
                </th>
                <th style={{ marginRight: "70px" }}>(*) Número</th>
                <th style={{ marginRight: "80px" }}>Complemento</th>
                <th style={{ marginRight: "35px" }}>(*) Tipo do Imóvel</th>
                <th style={{ marginRight: "200px", width: "205px" }}>Nome</th>
                <th style={{ marginRight: "150px", width: "100px" }}>
                  Telefone
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 20 }).map((_, index) => (
                <tr class="tbody__row">
                  <td
                    class="numeration_column"
                    style={{ textAlign: "center", color: "black" }}
                  >
                    {index + 1}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Continua no verso</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                paddingTop: "10px",
                rowGap: "10px",
              }}
            >
              <div class="item">
                <table className="document__table--legend">
                  <thead>
                    <tr>
                      <th colSpan={2}>Tipo de imóvel</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>R - Residência</td>
                      <td>C - Comércio</td>
                    </tr>
                    <tr>
                      <td>TB - Terreno Baldio</td>
                      <td>PE - Ponto Estratégico</td>
                    </tr>
                    <tr>
                      <td>PP - Próprio Público</td>
                      <td>IE - Imóvel Especial</td>
                    </tr>
                    <tr>
                      <td>VP - Via Pública</td>
                      <td>E - Escola</td>
                    </tr>
                    <tr>
                      <td>US - Unidade de Saúde</td>
                      <td>O - Outros</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="item">
                <table className="document__table--legend">
                  <thead>
                    <tr>
                      <th>Status da Visita</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>T - Trabalhado</td>
                    </tr>
                    <tr>
                      <td>F - Fechado</td>
                    </tr>
                    <tr>
                      <td>R - Recusa</td>
                    </tr>
                    <tr>
                      <td>O - Orientação</td>
                    </tr>
                    <tr>
                      <td>EN - Endereço Não Encontrado</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="item">
                <table className="document__table--legend">
                  <thead>
                    <tr>
                      <th>Condições de uso</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>FPA - Faltam partes da armadilha</td>
                    </tr>
                    <tr>
                      <td>ANL - Armadilha não localizada no local</td>
                    </tr>
                    <tr>
                      <td>E - Extraviada</td>
                    </tr>
                    <tr>
                      <td>AD - Armadilha danificada</td>
                    </tr>
                    <tr>
                      <td>PD - Peça com defeito</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="item">
                <table className="document__table--legend">
                  <thead>
                    <tr>
                      <th>Peças com defeito</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>IT - Indicador de tempo</td>
                    </tr>
                    <tr>
                      <td>T - Tampa</td>
                    </tr>
                    <tr>
                      <td>F - Flutuador</td>
                    </tr>
                    <tr>
                      <td>B - Balde</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="item">
                <table className="document__table--legend">
                  <thead>
                    <tr>
                      <th>Amostras</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>AM1 - Água</td>
                    </tr>
                    <tr>
                      <td>AM2 - Larva</td>
                    </tr>
                    <tr>
                      <td>AM3 - Pupa</td>
                    </tr>
                    <tr>
                      <td>AM4 - Lurva e Pupa</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="item">
                <table className="document__table--legend">
                  <thead>
                    <tr>
                      <th>Motivo desinstalação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>FPS - Fim do período de supressão de Aedes Aegypti</td>
                    </tr>
                    <tr>
                      <td>SPM - Solicitado pelo morador/responsável</td>
                    </tr>
                    <tr>
                      <td>PAC - Presença constante de animais domésticos ou crianças</td>
                    </tr>
                    <tr>
                      <td>ADP - A armadilha foi danificada ou está faltando partes</td>
                    </tr>
                    <tr>
                      <td>ADM - A armadilha foi descartada pelo morador/responsável</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <table
              className="document__table--visit--verse"
              style={{ marginTop: "16px" }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th class="">
                    Selecione o status da visita
                  </th>
                  <th class="">
                    A armadilha está em condições de uso?
                  </th>
                  <th class="">
                    Informe o motivo de NÃO estar em condições de uso
                  </th>
                  <th class="">
                    Se for "Peça com defeito", informe o componente comprometido
                  </th>
                  <th class="">
                    Foi gerada amostra?
                  </th>
                  <th class="">
                    Informe o número da amostra
                  </th>
                  <th class="">
                    Selecione o tipo de amostra
                  </th>
                  <th class="">
                    Motivo da desintalação
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 20 }).map((_, index) => (
                  <tr class="tbody__row">
                    <td
                      class="numeration_column"
                      style={{ textAlign: "center", color: "black" }}
                    >
                      {index + 1}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </section>
    );
  }
);

export default UninstallAdiFormPrint;
