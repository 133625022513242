import React from "react";
import { Container, Row, Col } from "reactstrap";
import { fetchOrganizations } from "../../../services/api/Organization";
//import { toPng } from "html-to-image";
import TicketModel from "./TicketModel";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { ProgressBar } from "react-bootstrap";

const PrintQrCode = ({
  styleTicketModel,
  showImage,
  selectedTrapType,
  selectedOrganization,
  formatedOrganizationName,
}) => {
  const [beginNumber, setBeginNumber] = React.useState(10);
  const [finalNumber, setFinalNumber] = React.useState(110);
  const [numberInsideTicket, setNumberInsideTicket] = React.useState(110);
  const [progress, setProgress] = React.useState(0);
  const [isGenerating, setIsGenerating] = React.useState(false);
  const [qrCodePrefix, setQrCodePrefix] = React.useState("");
  const [pixelRation, setPixelRatio] = React.useState(4);

  const divToPrintRef = React.useRef([]);

  const handleButtonClick = async () => {
    const tickets = document.querySelectorAll(".etiqueta");
    if (tickets.length > 0) {
      setIsGenerating(true);
      setProgress(0);
      await generateTickets(tickets[0]);
    } else {
      console.warn("Nenhum elemento .etiqueta encontrado para download.");
    }
  };

  const generateTickets = async (ticket) => {
    const zip = new JSZip();
    const totalTickets = finalNumber - beginNumber + 1;

    for (
      let numberTicket = beginNumber;
      numberTicket <= finalNumber;
      numberTicket++
    ) {
      try {
        setNumberInsideTicket(`${qrCodePrefix}${numberTicket}`);

        await new Promise((resolve) => {
          setTimeout(resolve, 0);
        });

        const tagImageString = await generateTagImage(ticket);

        const link = document.createElement("a");
        link.download = `${qrCodePrefix}${numberTicket}.png`;
        link.href = tagImageString;
        link.click();

        /* const imgData = tagImageString.split(",")[1];

        zip.file(`${qrCodePrefix}${numberTicket}.png`, imgData, { base64: true }); */


        setProgress(((numberTicket - beginNumber + 1) / totalTickets) * 100);
      } catch (error) {
        console.error("Erro ao gerar imagem:", error);
      }
    }

    /* zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "tickets.zip");
      setIsGenerating(false);
    }); */
  };

  const generateTagImage = async (nodeElement) => {
    try {
      console.log("nodeElement", nodeElement);
      // const imageString = await toPng(nodeElement, {
      //   quality: 1,
      //   pixelRatio: pixelRation,
      // });
      //return imageString;
    } catch (error) {
      console.error("Erro ao gerar imagem:", error);
      throw error;
    }
  };

  return (
    <>
      <Container fluid>
        {isGenerating && (
          <Row className="mt-3">
            <Col>
              <ProgressBar
                style={{ height: "25px", borderRadius: "10px" }}
                now={progress}
                label={`${Math.round(progress)}%`}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col xl={12} className="mt-4">
            {showImage ? (
              <TicketModel
                divToPrintRef={divToPrintRef}
                styleTicketModel={styleTicketModel}
                formatedOrganizationName={formatedOrganizationName}
                trapType={selectedTrapType}
                numberInsideTicket={numberInsideTicket}
                organization={selectedOrganization}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row styles={{ marginTop: "1rem" }}>
          <Col xl={3}>
            <span className="h4 text-white">Prefixo</span>
            <input
              type="text"
              className="form-control"
              value={qrCodePrefix}
              onChange={({ target }) => setQrCodePrefix(target.value)}
            />
          </Col>
          <Col xl={3}>
            <span className="h4 text-white">Do número:</span>
            <input
              type="number"
              className="form-control"
              value={beginNumber}
              onChange={(e) => setBeginNumber(e.target.value)}
            />
          </Col>
          <Col xl={3}>
            <span className="h4 text-white">Até o número:</span>
            <input
              type="number"
              className="form-control"
              value={finalNumber}
              onChange={(e) => setFinalNumber(e.target.value)}
            />
          </Col>
          <Col xl={3}>
            <span className="h4 text-white">Pixel Ratio:</span>
            <input
              type="number"
              className="form-control"
              value={pixelRation}
              onChange={({ target }) => setPixelRatio(target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={3} style={{ display: "flex", alignItems: "flex-end" }}>
            <button className="btn btn-primary" onClick={handleButtonClick}>
              imprimir
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PrintQrCode;
