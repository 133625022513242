import React from "react";
import { signup } from "../../../services/api/signup";
// nodejs library to set properties for components
import { isEmailValid } from "../../../services/utils/validate";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

class SignUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        person : 
        { 
          Name: "",
          Cpf: "",
          Rg: "",
          BirthDate: "",
          Email: "",
          Nickname: "",
          Password: "",
          Telephone: "",
          Mobile: "",
          Occupation: ""
        }      
    };
  }

  signUp = () => {
    if(isEmailValid(this.state.person.Email)) {
        const SignUpRequest = signup(this.state.person);
        SignUpRequest.then(response => {
          if(response) {
              return response.data
          }
          throw new Error("Login inválido...");
      })         
    } else {
        toast.error("Email inválido!");
    }
  }

  render() {

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">

          <ToastContainer />

            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>
                  <img alt="" className="navbar-brand-img"
                    src="/static/media/vitec.9bd71d52.png" width="200" />
                </small>
              </div>
              <Form role="form">
              <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Nome" onChange={e => this.state.person.Name = e.target.value} type="email" />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-archive-2" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="CPF" onChange={e => this.state.person.Cpf = e.target.value} type="text" />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-badge" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="RG" onChange={e => this.state.person.Rg = e.target.value} type="text" />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Data de Aniversário" onChange={e => this.state.person.BirthDate = e.target.value} type="date" />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Telefone" onChange={e => this.state.person.Telephone = e.target.value} type="text" />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-mobile-button" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Celular" onChange={e => this.state.person.Mobile = e.target.value} type="text" />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-building" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Ocupação" onChange={e => this.state.person.Occupation = e.target.value} type="text" />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" onChange={e => this.state.person.Email = e.target.value} type="email" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Senha" onChange={e => this.state.person.Password = e.target.value} type="password" />
                  </InputGroup>
                </FormGroup>                                
                <div className="text-center">
                  <Button className="my-4" onClick={this.signUp} color="primary" type="button">
                    Criar conta
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                
              >
                <small>Esqueci minha senha</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Me ajuda!</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default SignUp;
