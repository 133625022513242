import request from '../utils/request';
//import request from "../utils/requestTests/requestTestDemand"

import { getUserData } from '../utils/auth';

export function fetchDemands(query) {
  const DemandRequest = request({
    url: '/Demands',
    method: 'get',
    params: query,
  }); 

  DemandRequest.catch(error => {
    console.log(error.response);
    if(error.response && error.response.status === 401){
      window.location.href = "/admin/logout";
    }
  })

  return DemandRequest; 
}
export function fetchDemand(id) {
  return request({
    url: '/Demands/'+id,
    method: 'get',
  });
}
export function deleteDemand(id) {
  return request({
    url: '/Demands/'+id,
    method: 'delete',
  });
}
export function postDemand(DemandData) {
    let DemandSend = getDemandRequestObject(DemandData);

    return request({
        url: '/Demands',
        method: 'post',
        data: DemandSend,
    });
}

export function putDemand(id,DemandData) {
  let DemandSend = getDemandRequestObject(DemandData)

  return request({
      url: '/Demands/'+id,
      method: 'put',
      data: DemandSend,
  });  
}

export function changeDemandStatus(id, status) {

  var userId = getUserData('userId');

  return request({
    url: '/Demands/changeSituation/' + id + '/' + status + '/' + userId,
    method: 'post',
});
}
function buildProblemSendData(programs){
  let problemSendData = [];
  programs.forEach(program => {
    program.problems.forEach(problem => {
      let problemDto = {
        Id: problem.id,
        ProgramId: program.programId,
        ServiceId: problem.serviceId,
        Priority: parseInt(problem.priority, 10),
        Observation: problem.observation
      }

      problemSendData.push(problemDto);
    })
  })

  return problemSendData;
}

function getDemandRequestObject(DemandData){
  return {
    type: DemandData.Type.charAt(0).toUpperCase() + DemandData.Type.slice(1),
    name: DemandData.DemandName,
    description: DemandData.Description,
    serviceOrder: DemandData.OrderNumber,
    serviceNumber: DemandData.OrderNumber == "" ? 0 : parseInt(DemandData.OrderNumber, 10) ,
    route: DemandData.Address.Route,
    streetNumber: DemandData.Address.StreetNumber,
    subpremisse: DemandData.Address.SubPremise,
    postalCode: DemandData.Address.postalCode,
    subLocality: DemandData.Address.SubLocality,
    area: DemandData.Address.Area,
    block: DemandData.Address.Block,
    referencePoint: DemandData.Address.ReferencePoint,
    requesterName: DemandData.Person.Name,
    userId: getUserData('userId'),
    originId: DemandData.Origin,
    problems: buildProblemSendData(DemandData.programs),
    organizationId: getUserData('organizationId'),
    territorializationId: DemandData.territorializationId,
    SamplesObservations: DemandData.SampleDescription,
    TeamId: DemandData.teamId,
    DemandProblemEmployeesId: DemandData.DemandProblemEmployeesId,
    ResponsibleId: DemandData.responsibleId,
    solicitationType: DemandData.SolicitationType,
    StartDate: DemandData.beginDate,
    EndDate: DemandData.endDate,
    personName: DemandData.Person.Name,
    personPhone: DemandData.Person.Phone,
    personTypeDocument: DemandData.Person.TypeDocument,
    personDocument: DemandData.Person.Document,
    personNacionality: DemandData.Person.Nacionality,
    personNaturality: DemandData.Person.Naturality,
    personOtherNacionality: DemandData.Person.OtherNacionality,
  }
}