import React, { useEffect } from "react";
// reactstrap components
import { Brush, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Surface, Symbols } from "recharts";
import { Card, CardBody, CardHeader, Row, CardText, Col, Button} from "reactstrap";
import { saveAs } from 'file-saver';
import moment from "moment";

const CardPresentational = (props) => {

    const style={
        col:{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            height:"25rem",
            marginTop:"0.7rem",
        },
        cardBody:{
          padding:"1rem",
        }
    }

    const toggleFullscreen = (elem) => {
        elem = elem || document.documentElement;
        if (!document.fullscreenElement && !document.mozFullScreenElement &&
          !document.webkitFullscreenElement && !document.msFullscreenElement) {
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
          } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
          }

        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          }
        }
    }

    const saveChart = async () => {
      const png = await props.getpng();
      
      // Verify that png is not undefined
      if (png) {
        fetch(png)
        .then(res => res.blob())
        .then(pngBlob => saveAs(pngBlob, `${moment().format("YYYY_MM_DD_hh_mm_ss")}.png`))
      }      
    }

    return (
        
        <Card id={props.CardId+"Card"} className="shadow">
            <CardHeader>
                <CardText className="mb-0 mt-0" style={props.style.cardHeaderText}>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        {props.textCardHeader} {props.typeContainer == "serieHistorica" ? "(Série Histórica)" : ""}
                        <div style={{display:"flex"}}>
                            {props.typeContainer == "serieHistorica" 
                            ? 
                            <button className="btn btn-primary" style={{marginRight: "1rem", opacity:1, color:"#000"}} close onClick={() => {props.setTypeContainer("comparativo")}}>
                              Mudar Gráfico
                            </button>
                            :
                                props.typeContainer 
                                ?
                                <button className="btn btn-primary" style={{marginRight: "1rem", opacity:1, color:"#000"}} close onClick={() => {props.setTypeContainer("serieHistorica")}}>
                                  Mudar Gráfico
                                </button>
                                :
                                <></>
                            }

                            {props.getpng && 
                              <Button style={{marginRight: "1rem", opacity:1, color:"#000"}} close onClick={() =>{saveChart()}}>
                                <i className={"fas fa-download"}/>
                              </Button>
                            }
                            <Button style={{opacity:1, color:"#000"}} close onClick={()=>{toggleFullscreen(document.getElementById(props.CardId+"Card"))}}>
                              <i className={"fas fa-expand-arrows-alt"}/>
                            </Button>
                        </div>
                    </div>
                </CardText>
            </CardHeader>
            <CardBody style={style.cardBody}>
                <Row>
                    <Col style={style.col}>
                        {props.element}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}


export default CardPresentational;