import React from "react";
// react component that copies the given text inside your clipboard
import { Link } from "react-router-dom";
// reactstrap components

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import "../../../assets/css/style.css";
import FormDemanda from "../../../components/Forms/FormDemanda";
import { checkPermissionComponent } from "../../../services/utils/permission";
import "./AddDemand.css";

class AddDemand extends React.Component {
  state = {
    problemas: [],
    tipoDemanda: "interna",
  };

  constructor(props){
    super(props);
    if(!checkPermissionComponent("da149579-6cd3-425c-bdfa-04e968d44a69", "create")){
      alert("Você não tem permissão para acessar essa funcionalidade!");
      props.history.goBack(); 
    }
  }
  addProblema() {
    this.setState({ problemas: [...this.state.problemas, ""] })
  }
  removerProblema(index) {
    this.state.problemas.splice(index, 1)
    this.setState({ problemas: this.state.problemas })
  }

  handleChange(e, index) {
    this.state.problemas[index] = e.target.value;
    this.setState({ problemas: this.state.problemas })
  }

  checarTipoDemanda(event) {
    this.setState({ tipoDemanda: event.target.value })
  }

  render() {
    return (
      <>
        <Container className="responsive-container" fluid>
          <Row className="mt-12">
            <Col className="mb-12 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        <Link to="/admin/index"> <i className=" ni ni-bold-left bnt-voltar" /></Link>
                        Nova Demanda</h3>
                    </div>
                  </Row>
                </CardHeader>

                {/* Formulario demandas */}
                <FormDemanda />

              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  
  }
}

export default AddDemand;