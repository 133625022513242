
import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Select } from "../../Wrappers/SelectAll";
import "./VisitOperacionalFilter.css"

// reactstrap components

const VisitOperacionalFilterPresentational = (props) => {

    async function change(name, value) {
        if (props.isEntomologico) {
            props.handleChangeFilter(`${name}ENTOMOLOGICO`, value);
        }
        else if (props.isOperacional) {
            props.handleChangeFilter(`${name}OPERACIONAL`, value);
        }
        else {
            props.handleChangeFilter(name, value);
        }
    }

    const handleEmployeeChange = (employeeData) => {
        props.handleChangeFilter("employeeId", employeeData);
        change("employeeId", employeeData)
    } 
    
    return (
        <Row className="mb-2">
            <Col className="col-md-4 visit-responsive-filter">
                <span className="h4 text-white">Demandas</span>
                <Select
                    styles={{
                        control: styles => ({ ...styles, minHeight: "2.90rem", borderRadius: "0.370rem" }),
                        option: styles => ({ ...styles, fontSize: "0.875rem" }),
                        valueContainer: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        singleValue: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        placeholder: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" })
                    }}
                    placeholder={"Selecione"}
                    isClearable={true}
                    options={props.demandIdList}
                    value={props.demandIdList.filter(({ value }) => value == props.demandId)}
                    onChange={(e) => { change("demandId", e) }}
                />
            </Col>
            <Col className="col-md-4 visit-responsive-filter">
                <span className="h4 text-white">Equipes</span>
                <Select
                    styles={{
                        control: styles => ({ ...styles, minHeight: "2.90rem", borderRadius: "0.370rem" }),
                        option: styles => ({ ...styles, fontSize: "0.875rem" }),
                        valueContainer: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        singleValue: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        placeholder: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" })
                    }}
                    placeholder={"Selecione"}
                    isClearable={true}
                    options={props.teamIdList}
                    value={props.teamId}
                    onChange={(e) => { change("teamId", e) }}
                />
            </Col>
            <Col className="col-md-4 visit-responsive-filter">
                <span className="h4 text-white">Funcionário</span>
                <Select
                    styles={{
                        control: styles => ({ ...styles, minHeight: "2.90rem", borderRadius: "0.370rem" }),
                        option: styles => ({ ...styles, fontSize: "0.875rem" }),
                        valueContainer: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        singleValue: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        placeholder: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" })
                    }}
                    placeholder={"Selecione"}
                    isClearable={true}
                    options={props.employeeIdList}
                    value={props.employeeIdList.filter(({ value }) => value == props.employeeId)}
                    onChange={(e) => { handleEmployeeChange(e) }}
                />
            </Col>
        </Row>

    )
}


export default VisitOperacionalFilterPresentational;
