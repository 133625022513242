import React from "react";
import { Col, Row } from "reactstrap";
import { SINAN_DESEASES } from "../../../constants/SinanDeseasesConstant";
import { SINAN_TYPE_DATE_FILTERS } from "../../../constants/SinanTypeDateFilters";
import { Select } from "../../Wrappers/SelectAll";

// reactstrap components

const SinanFilterPresentational = (props) => {    
    return (
        <>
            <Col className="col-4 linha-form col-md-4 geo-responsive-filter ">
                <span className="h4 text-white">Data início</span>
                <input value={props.startDate} name={"filterDateStart"} onChange={(e) => {props.changeStartDate(e.target.value)}} type="date" className="form-control" />
            </Col>
            <Col className="col-4 linha-form col-md-4 geo-responsive-filter ">
                
                <span className="h4 text-white">Data Fim</span>
                <input value={props.endDate} name={"filterDateEnd"} onChange={(e) => {props.changeEndDate(e.target.value)}} type="date" className="form-control" />
            </Col>
            <Col className="col-4 linha-form col-md-4 geo-responsive-filter ">
                <span className="h4 text-white">Data de Referência</span>
                <Select
                    options={SINAN_TYPE_DATE_FILTERS}
                    value={SINAN_TYPE_DATE_FILTERS.filter(({value}) => value === props.selectedTypeDate)}
                    styles={{
                        control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                        option: styles =>({...styles, fontSize:"0.875rem"}),
                        valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                        singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                        placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                        menuPortal: styles => ({...styles, zIndex:9999})
                    }}
                    onChange={({value}) => {props.changeTypeDate(value)}} 
                    menuPortalTarget={document.body}
                    placeholder={"Selecione"}
                />
            </Col>
            <Col className="col-4 linha-form col-md-4 geo-responsive-filter ">
                <span className="h4 text-white">Doença</span>
                <Select
                    options={SINAN_DESEASES}
                    value={SINAN_DESEASES.filter(({value}) => value === props.selectedDesease)}
                    styles={{
                        control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                        option: styles =>({...styles, fontSize:"0.875rem"}),
                        valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                        singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                        placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                        menuPortal: styles => ({...styles, zIndex:9999})
                    }}
                    onChange={({value}) => {props.changeDesease(value)}} 
                    menuPortalTarget={document.body}
                    placeholder={"Selecione"}
                />
            </Col>
        </>  
    )
}


export default SinanFilterPresentational;