import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import "./PgsApp.css";
import VersionPgs from "./Options/VersionPgs";
import FormTerritorializationPgs from "./Options/FormTerritorializationPgs";
import AppScreenPgs from "./Options/AppScreenPgs";
import FormsTrapPgs from "./Options/FormsTrapPgs";
import FormTerritorializationPreviewApp from "./Preview/FormTerritorializationPreviewApp";

const PgsApp = React.FC = () => {
    const [pageApp, setPageApp] = useState(null);

    const renderPageApp = () => {
        if(pageApp == "ft") {
            return <FormTerritorializationPreviewApp />
        }
    }
    return (
        <Row>
            <Col className="col-8">
                <div className="container">
                    <p className="h3 title">Versionamento <i className="fas fa-eye-slash text-white"></i></p>
                    
                    <VersionPgs />
                    <p className="h3 title">Formulário Inserção de Dados Residênciais <i className="fas fa-eye text-white"></i></p>
                    
                    <FormTerritorializationPgs setPageApp={setPageApp}/>
                    <p className="h3 title">Tela inicial App <i className="fas fa-eye text-white"></i></p>
                    <AppScreenPgs />
                    <p className="h3 title">Formulário de armadilhas <i className="fas fa-eye text-white"></i></p>
                    <FormsTrapPgs />
                </div>
            </Col>
            <Col className="col-4">
                <div class="mobile-device">
                    <div class="screen">
                        {
                            pageApp
                            ?
                            <>
                                {renderPageApp()}
                            </>
                            :
                            <div class="content_logo">
                                <img src={require('../../../assets/img/brand/vitec.png')} width="150px"/>
                            </div>
                        }
                        
                    </div>
                </div>
            </Col>
        </Row>
        
    )
}

export default PgsApp;