import React, {Component} from "react";
import CardPresentational from "../../../Presentational/CardPresentational";

class PalhetasExaminadasContainer extends Component {
    state = {
        examinated:0,
    }

    async componentDidUpdate(prevProps, prevState){
        const {data} = this.props; 
        if(data !== undefined && data !== prevProps.data){
            this.buildDataToCard();
        }
    }

    componentDidMount(){
        const {data} = this.props;
        if(data !== undefined && data.length > 0){
            this.buildDataToCard();
        }
    }


    buildDataToCard = () => {
        const {data} = this.props;
        let examinated = 0;
        
        for(const j in data){
            for(const i in data[j]){
                if(i !== "label"){
                    examinated += data[j][i].examinated;
                }
            }
        }
            
        this.setState({examinated});
        
    }

    render(){
        //construindo propriedades do presentational
        const {examinated} = this.state;
        let textCard = "Total de amostras examinadas";
        
        
        return(
            <CardPresentational 
                number={examinated}
                text={textCard}
            />
        );
    }
}

export default PalhetasExaminadasContainer;