import request from '../utils/request';

export function fetchAnimalTypeGroups() {
    return request({
      url: 'IdentificationGroups/TypeIdentificationGroup',
      method: 'get',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
    });
  }

export function fetchAnimalGroups(typeGroup) {
  return request({
    url: '/IdentificationGroups/'+ typeGroup,
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
  });
}

export function fetchAnimalGenres(group) {
    return request({
      url: '/IdentificationGroups/Genres/'+group,
      method: 'get',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
    });
}

export function fetchAnimalSpecies(genre) {
    return request({
        url: '/IdentificationGroups/Species/'+genre,
        method: 'get',
        headers: new Headers({
        'Content-Type': 'application/json'
        }),
    });
}

export function fetchGenreVirusType(genre) {
  return request({
      url: '/IdentificationGroups/Virus/'+genre,
      method: 'get',
      headers: new Headers({
      'Content-Type': 'application/json'
      }),
  });
}
