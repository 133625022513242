import React, {Component} from "react";
import { getRainNotifications } from "../../../../../services/api/Inmet";
import LineCardPresentational from "../../../../Presentational/Inmet/LineCardPresentational";
import {timeStampFormated} from "../../../../../services/utils/format";
class NotificationsByDayContainer extends Component {
    state = {
        chartDataSets:[{}],
    }

    async componentDidUpdate(prevProps, prevState){
        if((prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) && !this.props.data){
            await this.fetchData();    
        }else if(this.props.data && (this.props.data !== prevProps.data)){
            const chartDataSets = this.buildChartDataset(this.props.data);
            chartDataSets.then(responseDataSet=> {
                this.setState({chartDataSets: responseDataSet});
            })
        }
    }

    async fetchData(){
        const filter = {
            startDate: this.props.startDate,
            endDate:this.props.endDate,
        }
        const reqNotification = getRainNotifications(filter);
        reqNotification.then(responseRequest => {
            const chartDataSets = this.buildChartDataset(responseRequest.data);
            chartDataSets.then(responseDataSet=> {
                this.setState({chartDataSets: responseDataSet});
            })
        })

    }

    async buildChartDataset(data){
        const obj = [];

        for(let index = 0; index < data.length; index++){
            const date = data[index]["data"];
            const temp = parseFloat(data[index]["temperaturaDoAr"]);
            
            const indexInObj = obj.findIndex(o => o.date === timeStampFormated(date));
            if(indexInObj == -1){
                obj.push({date:timeStampFormated(date), quantity:1, avaregeTemperature:temp, sumTemp:temp,timeStamp:Date.parse(date)});
            }else{
                var qtd = obj[indexInObj]["quantity"];
                qtd++;
                var sumtemp = obj[indexInObj]["sumTemp"];
                var avgtemp = (temp+sumtemp)/qtd;
                obj[indexInObj]["quantity"] = qtd;
                obj[indexInObj]["sumTemp"] = temp+sumtemp;
                obj[indexInObj]["avaregeTemperature"] = avgtemp;
            }
        }

        const ordered = obj.sort((a, b) => (a.timeStamp - b.timeStamp));      
        const labels = [];
        const dataToChart = [];

        for(let index = 0; index < ordered.length; index++){
            labels.push(ordered[index]["date"]);
            dataToChart.push(ordered[index]["avaregeTemperature"].toFixed(1));
        }

        const dataSet = {

            labels:labels,
            datasets:[{
                label:"Temp (ºC)",
                data: dataToChart,
                borderWidth:2,
                lineTension:0,
                pointRadius:4,
                fill:false,
            }]
        }

        return dataSet;
    }

    render(){
        //construindo propriedades do presentational
        const {chartDataSets} = this.state;

        const style={
            cardHeaderText: {
                color:"black",
                fontWeight:"bold",
                fontSize:"0.97rem"
            },
        }

        const textCardHeader = "Temperatura por Dia";
        return(
            <>
            <LineCardPresentational
                style={style}
                textCardHeader={textCardHeader}
                data={chartDataSets}
            />
            </>
        );
    }
}

export default NotificationsByDayContainer;