import React from "react";
import EmployeesTable from "../../../components/Lists/Employees/EmployeesTable";
// reactstrap components
import FilterEmployees from "../../../components/Navbars/FilterEmployees"
// core components
import Header from "components/Headers/Header.jsx";
import "../../../assets/css/style.css";
import { fetchEmployees } from "../../../services/api/Employee";
import { checkPermissionComponent } from "../../../services/utils/permission";

class Employee extends React.Component {
  state = {
    Employees : [],
    EmployeesFilter:[]
  };

  constructor (props) {
    super(props);
    if(!checkPermissionComponent("ba56119d-ac43-4ec1-858e-31f2e12235e8", "read")){
      alert("Você não tem permissão para acessar essa funcionalidade!");
      props.history.goBack(); 
    }
  }

  componentDidMount() {
     const EmployeesRequest = fetchEmployees();
        EmployeesRequest.then(response => {
           if(response) {
               return response.data
           }
           throw new Error("Erro ao contatar servidor");
       }).then(async data => {
          this.setState({Employees : data});
          this.setState({EmployeesFilter : data});
       })
       
  }
  callbackFunction = (EmployeeFilteredArray)=>{
    this.setState({EmployeesFilter: EmployeeFilteredArray})
  }
  render() {

    return (      
      <>
        <FilterEmployees Employees={this.state.Employees} parentCallback = {this.callbackFunction}/>

        <EmployeesTable Employees={this.state.EmployeesFilter} />

      </>
    );
  }
}

export default Employee;