import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ConfirmGeoreferenceTrapsModal = ({
  isModalOpen,
  toggle,
  handleGeoreferenceTrapsWithError,
  isGeoreferenceTrapsByAdressLoading,
}) => {
  return (
    <Modal isOpen={isModalOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Georreferenciar armadilhas sem coordenadas
      </ModalHeader>
      <ModalBody>
        As armadilhas <b>sem coordenadas</b> representam aquelas
        que, durante o processo de instalação, manutenção ou desinstalação, o{" "}
        <b>
          aplicativo não conseguiu colher a latitude e longitude do agente de
          campo por falta de sinal com o satélite após três tentativas.
        </b>{" "}
        Para o agente não perder a visita e os dados coletados, o sistema enviou
        essa armadilha para um ponto fixo, que no caso é Brasília, e você poderá
        corrigir sua posição através do botão "Editar armadilha" na "Tabela de
        Armadilhas" manualmente e uma por uma.
        <br />
        <br />
        Clicando no botão "Georreferenciar por endereço" abaixo,{" "}
        <b>
          {" "}
          você concorda com o sistema ajustar automaticamente a posição de todas
          as armadilhas sem coordenadas, a partir do endereço informado pelo agente
          em campo.
        </b>
        <br />
        <br />
        As armadilhas georreferenciadas pelo endereço são identificadas pelo
        ícone <i className="fa fa-exclamation-triangle"></i> na "Tabela de
        Armadilhas"
        <br />
        <br />
        <span style={{ color: "red" }}>
          Recomendamos que verifique cada armadilha georreferenciada por
          endereço, utilizando o filtro "Exibir armadilhas" na "Tabela de
          armadilhas", para confirmar se sua localização geográfica está
          correta. Em alguns casos, o georreferenciamento automático pode não
          encontrar o endereço informado ou não representar a realidade.
        </span>
      </ModalBody>
      <ModalFooter>
        {isGeoreferenceTrapsByAdressLoading ? (
          <Button color="primary" disabled={true}>
            Carregando...
          </Button>
        ) : (
          <Button
            color="primary"
            onClick={() => handleGeoreferenceTrapsWithError()}
          >
            Georreferenciar por endereço
          </Button>
        )}
        <Button color="secondary" onClick={toggle}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmGeoreferenceTrapsModal;
