import moment from "moment";

export const getYearsOptions = () => {
  const yearsOptions = [];

  for (let i = 0; i <= 7; i++)
    yearsOptions.push(moment().subtract(i, "year").year());

  const yearsOptionsFormated = yearsOptions.map((year) => {
    return {
      label: year,
      value: year,
    };
  });

  return yearsOptionsFormated
};

export const getMonthsOptions = () => {
  return [
    {
      label: "Janeiro",
      value: "01",
      periods: {
        beginDate: "01-01",
        finalDate: "01-31",
      },
    },
    {
      label: "Fevereiro",
      value: "02",
      periods: {
        beginDate: "02-01",
        finalDate: "02-29",
      },
    },
    {
      label: "Março",
      value: "03",
      periods: {
        beginDate: "03-01",
        finalDate: "03-31",
      },
    },
    {
      label: "Abril",
      value: "04",
      periods: {
        beginDate: "04-01",
        finalDate: "04-30",
      },
    },
    {
      label: "Maio",
      value: "05",
      periods: {
        beginDate: "05-01",
        finalDate: "05-31",
      },
    },
    {
      label: "Junho",
      value: "06",
      periods: {
        beginDate: "06-01",
        finalDate: "06-30",
      },
    },
    {
      label: "Julho",
      value: "07",
      periods: {
        beginDate: "07-01",
        finalDate: "07-31",
      },
    },
    {
      label: "Agosto",
      value: "08",
      periods: {
        beginDate: "08-01",
        finalDate: "08-30",
      },
    },
    {
      label: "Setembro",
      value: "09",
      periods: {
        beginDate: "09-01",
        finalDate: "09-30",
      },
    },
    {
      label: "Outubro",
      value: "10",
      periods: {
        beginDate: "10-01",
        finalDate: "10-31",
      },
    },
    {
      label: "Novembro",
      value: "11",
      periods: {
        beginDate: "11-01",
        finalDate: "11-30",
      },
    },
    {
      label: "Dezembro",
      value: "12",
      periods: {
        beginDate: "12-01",
        finalDate: "12-31",
      },
    },
  ];
};