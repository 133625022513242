import React from "react";

import DataTable from "react-data-table-component";
import { Card, CardBody, CardHeader, CardText, Col, Row } from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment";
import { Button } from "react-bootstrap";
import { toPng } from "html-to-image";
import { toast } from "react-toastify";
import { center } from "@turf/turf";

const ManagementTable = ({
  data,
  filtersValues,
  isTerritorializationsReportDataLoading,
  headerText,
}) => {
  const [dataToExport, setDataToExport] = React.useState([]);
  const [generatedAt, setGeneratedAt] = React.useState("");

  const contentToPrintRef = React.useRef(null);

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const splitedActualDate = moment().format("DD/MM/YYYY HH:mm").split(" ");

    setGeneratedAt(`${splitedActualDate[0]} às ${splitedActualDate[1]}`);
  }, [data]);

  const handleDownloadGraphicImage = async () => {
    try {
      if (contentToPrintRef.current === null) return;

      const imageToDownloadString = await toPng(contentToPrintRef.current, {
        quality: 1,
        pixelRatio: 2,
      });

      if (!imageToDownloadString || imageToDownloadString === "")
        throw new Error("Ocorreu um erro ao gerar a imagem do gráfico.");

      const link = document.createElement("a");
      link.download = `${headerText.replaceAll(
        " ",
        ""
      )}GeneratedAt${generatedAt}.png`;
      link.href = imageToDownloadString;
      link.click();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const formatedDataToExport = data.map((report) => {
      return {
        AREA_INTERVENCAO: report.territorializationName,
        ADIS_INSTALADAS: report.activeTraps,
        KM2: report.squareKilometre,
        "DENSIDADE_ARMADILHAS/KM2": report.trapsDensityBySquareKilometre,
        ATE_30_DIAS: report.trapsOnFirstPeriod,
        "ATE_30_DIAS(%)": report.trapsOnFirstPeriodPercentage,
        "31_A_60_DIAS": report.trapsOnSecondPeriod,
        "31_A_60_DIAS(%)": report.trapsOnSecondPeriodPercentage,
        ACIMA_60_DIAS: report.trapsOnThirdPeriod,
        "ACIMA_60_DIAS(%)": report.trapsOnThirdPeriodPercentage,
        AREA_PROTEGIDA: report.trapsProtectingAreas,
        "AREA_PROTEGIDA(%)": report.trapsProtectingAreasPercentage,
        AREA_RISCO: report.trapsNotProtectingAreas,
        "AREA_RISCO(%)": report.trapsNotProtectingAreasPercentage,
      };
    });

    setDataToExport(formatedDataToExport);
  }, [data]);

  const defaultColumnStyles = {
    fontSize: "16px",
  }

  const tableColumns = [
    {
      name: "Área de Intervenção",
      selector: "territorializationName",
      sortable: true,
      grow: 2,
      style: defaultColumnStyles,
    },
    {
      name: "ADI's Instaladas",
      selector: "activeTraps",
      sortable: true,
      style: defaultColumnStyles,
      center: true
    },
    {
      name: "KM²",
      selector: "squareKilometre",
      sortable: true,
      style: defaultColumnStyles,
      center: true
    },
    {
      name: <span>Densidade <br></br>Armadilhas/KM²</span>,
      selector: "trapsDensityBySquareKilometre",
      sortable: true,
      style: defaultColumnStyles,
      center: true
    },
    {
      name: "Até 30 Dias",
      selector: "trapsOnFirstPeriod",
      sortable: true,
      style: defaultColumnStyles,
      center: true
    },
    {
      name: "Até 30 Dias (%)",
      selector: "trapsOnFirstPeriodPercentage",
      sortable: true,
      style: defaultColumnStyles,
      center: true
    },
    {
      name: "De 31 Até 60 Dias",
      selector: "trapsOnSecondPeriod",
      sortable: true,
      style: defaultColumnStyles,
      center: true
    },
    {
      name: "De 31 Até 60 Dias (%)",
      selector: "trapsOnSecondPeriodPercentage",
      sortable: true,
      style: defaultColumnStyles,
      center: true
    },
    {
      name: "Acima de 60 Dias",
      selector: "trapsOnThirdPeriod",
      sortable: true,
      style: defaultColumnStyles,
      center: true
    },
    {
      name: "Acima de 60 Dias (%)",
      selector: "trapsOnThirdPeriodPercentage",
      sortable: true,
      style: defaultColumnStyles,
      center: true
    },
    {
      name: "Área Protegida",
      selector: "trapsProtectingAreas",
      sortable: true,
      style: defaultColumnStyles,
      center: true
    },
    {
      name: "Área Protegida (%)",
      selector: "trapsProtectingAreasPercentage",
      sortable: true,
      style: defaultColumnStyles,
      center: true
    },
    {
      name: "Área de Risco",
      selector: "trapsNotProtectingAreas",
      sortable: true,
      style: defaultColumnStyles,
      center: true
    },
    {
      name: "Área de Risco (%)",
      selector: "trapsNotProtectingAreasPercentage",
      sortable: true,
      style: defaultColumnStyles,
      center: true
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: '13px',
        fontWeight: "bold",
        textAlign: 'center'
      },
    },
  };

  const style = {
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    cardBody: {
      padding: "1rem",
    },
    cardHeaderText: {
      color: "black",
      fontWeight: "bold",
      fontSize: "0.97rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };

  return (
    <div ref={contentToPrintRef}>
      <Row className="mt-12 mb-12" style={{ paddingBottom: "1.5rem" }}>
        <Col className="mb-12 mb-xl-0" xl="12">
          <Card className="shadow">
            <CardHeader>
              <CardText className="mb-0 mt-0" style={style.cardHeaderText}>
                <div>
                  <span>{headerText}</span>
                </div>
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <span style={{ fontSize: "13px", fontWeight: "normal" }}>
                    Gerado em {generatedAt}
                  </span>
                  <span>
                    <CsvDownloadButton
                      className="btn btn-primary"
                      data={dataToExport}
                      filename={`${headerText.replaceAll(" ", "")}${generatedAt}`}
                      style={{ width: "55px" }}
                    >
                      <i className="fa fa-download" />
                    </CsvDownloadButton>
                  </span>
                  <span>
                    <Button
                      onClick={() => handleDownloadGraphicImage()}
                      color="primary"
                    >
                      <i className={"fa fa-image"} />
                    </Button>
                  </span>
                </div>
              </CardText>
            </CardHeader>
            <CardBody style={style.cardBody}>
              <Row>
                <Col>
                  <DataTable
                    noHeader
                    defaultSortField="name"
                    defaultSortAsc={true}
                    pagination
                    highlightOnHover
                    responsive
                    customStyles={customStyles}
                    columns={tableColumns}
                    data={data}
                    noDataComponent={"Nenhum registro encontrado."}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ManagementTable;
