import React from "react";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
  } from "reactstrap";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import {timeStampFormatedMongo} from "../../../services/utils/format";


const columns = [
    {
        name: 'Nome',
        selector: 'dadosPrincipais.name',
        sortable: true,
    },
    {
        name: 'Telefone',
        selector: 'dadosPrincipais.phone',
        sortable: true,
      
    },
    {
        name: 'Genero',
        selector: 'dadosPrincipais.gender',
        sortable: true,
        
    },
    {
        name: 'Endereço',
        selector: 'address.logradouro',
        sortable: true, 
    },
    {
        name: 'Número',
        selector: 'address.numero',
        sortable: true, 
    },
    {
        name: 'Data',
        selector: 'activityDate',
        sortable: true,
    },
    {
        name: 'Idade',
        selector: 'dadosPrincipais.age',
        sortable: true,
        
    },
    {
        name: 'Estuda',
        selector: d => d.study.doStudy?"Sim":"Não",
        sortable: true,
          
    },
    {
        
        name: 'Trabalha',
        selector: d => d.work.doWork?"Sim":"Não",
        sortable: true,
          
    },
    {
        name: 'Febre',
        selector: d => d.symptoms.suspected.febre?"Sim":"Não",
        sortable: true,
    },
    {
        name: 'Espirro',
        selector: d => d.symptoms.suspected.espirro?"Sim":"Não",
        sortable: true,
            
    },
    {
        name: 'Tosse',
        selector: d => d.symptoms.suspected.tosse?"Sim":"Não",
        sortable: true,
              
    },
    {
        name: 'Perda de Olfato',
        selector:d => d.symptoms.suspected.perdaOlfato?"Sim":"Não",
        sortable: true,
              
    },
    {
        name: 'Perda de Paladar',
        selector: d => d.symptoms.suspected.perdaPaladar?"Sim":"Não",
        sortable: true,
    },
    {
        name: 'Dor de Garganta',
        selector: d => d.symptoms.suspected.dorGarganta?"Sim":"Não",
        sortable: true,
            
    },
    {
        name: 'Dificulda de Respiração',
        selector: d => d.symptoms.suspected.dificuldadeRespiracao?"Sim":"Não",
        sortable: true,
                  
    },
    {
        name: 'Arrepios',
        selector: d => d.symptoms.suspected.arrepios?"Sim":"Não",
        sortable: true,
                  
    },
    {
        name: 'Tremor',
        selector: d => d.symptoms.suspected.tremor?"Sim":"Não",
        sortable: true,
    },
    {
        name: 'Dor Muscular',
        selector: d => d.symptoms.suspected.dorMuscular?"Sim":"Não",
        sortable: true,
                    
    },
    {
        name: 'Sintomas Gerais',
        selector: d => d.symptoms.suspected.simtomasGerais?"Sim":"Não",
        sortable: true,
                    
    },
    {
        name: 'Outros',
        selector: d => d.symptoms.suspected.outros?d.symptoms.suspected.otherSuspected:"Não",
        sortable: true,

    },
    {
        name: 'Preenchido Por',
        selector: 'collectorName',
        sortable: true,
                    
    },
    {
        name: 'Relatório',
        selector: 'typeForm',
        sortable: true,
                    
    },

  ];
  var data;

class CovidTable extends React.Component {
    state = {
        
        modal:false,
        selectedCovid:"",
        selectedCovidId:""
    }
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        }); 
    }
    render() {
        data = this.props.Residents;
        data.map((resident, index) => {
            resident.activityDate = timeStampFormatedMongo(resident.activityDate);

        })
        const tableData = {
            columns,
            data
          };
        return (
            
            <Container className="mt--7" fluid>
                
            <Row className="mt-12">
                <Col className="mb-12 mb-xl-0" xl="12">
                <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                        <h3 className="mb-0">Visitas</h3>
                        </div>
                    </Row>
                    </CardHeader>
                    <DataTableExtensions {...tableData}> 
                        <DataTable
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                            columns={columns}
                            data={data}
                        />
                   </DataTableExtensions>
                   
                                    {/*<<td style={"text-allign:center"}>
                                        
                                        button
                                            className="btn-icon-clipboard btn-acao-amostra"
                                            data-clipboard-text="check-bold"
                                            id="tooltip204269497"
                                            type="button"
                                            onClick={() => this.toggle}
                                        >
                                            <div>
                                            <i className="ni ni-check-bold" />
                                            </div>
                                        </button>
                                    </td>*/}
                            
                     
                </Card>
                </Col>
            </Row>
            </Container>
        )
    }
}

export default CovidTable
