import React from "react";
import {timeStampFormated} from "../../../services/utils/format";
import { Link } from "react-router-dom";
import {deleteDemand} from "../../../services/api/Demand";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
function DemandsList({Demand}) {

    //formata o timestamp que vem do json
    var DemandDateformatted = timeStampFormated(Demand.registrationDate);
    var modal, selectedDemandId, selectedDemand;
    function confirmDelete (DemandName, DemandId) {
        selectedDemandId=DemandId;
        selectedDemand=DemandName;
        this.toggle(false);
    }
    function toggle (willDeleteDemand) {
        modal = !this.modal;
        if(willDeleteDemand){
            var deleted = deleteDemand(selectedDemandId);
        }
    }
    return (
        <tr>
            <Modal size="lg" isOpen={modal} fade="true"  toggle={toggle(false)}>
                    <ModalHeader toggle={toggle(false)}>Remoção de demanda</ModalHeader>
                    <ModalBody>           
                        <div className="row"> 
                            <div className="col-9">
                                <span className="h2 text-black">Deseja remover a deamanda {selectedDemand}?</span>
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row" >                          
                        
                            <div className="col-6">
                                <button onClick={toggle(false)} className="form-control">CANCELAR</button>
                            </div>
                            <div className="col-6">
                                <button onClick={toggle(true)} className="btn btn-primary">REMOVER</button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
            <th scope="row">{Demand.serviceOrder}</th>
            <td>{DemandDateformatted}</td>
            <td>{Demand.problems.map((problem) => {
                return <div>{problem.name}</div>
            })}</td>
            <td>
            <td>{Demand.problems.map((problem) => {                
                return <div><i className="fas fa-arrow-up text-success mr-3" />Normal</div>
            })}</td>
            </td>
            <td>            
            Aberta
            </td>
            <td>
                <button
                    className="btn-icon-clipboard btn-acao-amostra"
                    data-clipboard-text="check-bold"
                    id="tooltip204269497"
                    type="button">
                    <div>
                        <i className="ni ni-check-bold" />
                    </div>
                </button>

                <button
                    className="btn-icon-clipboard btn-acao-amostra"
                    data-clipboard-text=" ni ni-fat-remove"
                    onClick={confirmDelete(Demand.name,Demand.id)}
                    id="tooltip204269497"
                    type="button">
                    <div>
                        <i className=" ni ni-fat-remove" />
                    </div>
                </button>
                <Link to="/admin/EditDemand">
                    <button
                        className="btn-icon-clipboard btn-acao-amostra"
                        data-clipboard-text="ni ni-fat-edit"
                        id="tooltip204269497"
                        onClick={() => localStorage.setItem('DemandItem', Demand.id)}
                        type="button">
                        <div>
                            <i className="ni ni-ruler-pencil" />
                        </div>
                    </button>
                </Link>
            </td>
        </tr>        
    )
}

export default DemandsList;