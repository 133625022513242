import React, { Component } from "react";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import CardPresentational from "../../../Presentational/CardPresentational";
import { getUserData } from "../../../../services/utils/auth";

class ArmadilhasVistoriadasContainer extends Component{

    state = {
        vistoryNumber:0,
        textCard:"Total de armadilhas vistoriadas",
        showDefaultNumbers: false,
        defaultNumber: null,
    }

    componentDidMount(){
        const {data} = this.props;
        if(data !== undefined && data.length > 0){
            this.buildDataToCard()
        }
        
    }

    async buildDataToCard(){
        const {trapTypeName} = this.props;
        const {data} = this.props;

        let textCard;
        const organizationId = getUserData("organizationId");

        if(organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8")
        {
            textCard = "Total de ADI instaladas (Etapa 1)";
            var defaultNumber = 20000;
            this.setState({showDefaultNumbers : true, defaultNumber});
        } else if(organizationId === "24d125ef-15e6-4bd9-9111-c0b3d50a8009")
        {
            textCard = "Total de ADI disponíveis para instalação";
            var defaultNumber = 500;
            this.setState({showDefaultNumbers : true, defaultNumber});
        }
        else textCard = `Total de ${ARMADILHA_INFO[trapTypeName].apelido} vistoriada`;
        
        const lastYearData = data[data.length - 1];
        const vistoryNumber = lastYearData[lastYearData.length - 1].inspected;

        this.setState({vistoryNumber, textCard});
    }

    componentDidUpdate(prevProps){
        const {data} = this.props; 
        if(data !== undefined && data !== prevProps.data){
            this.buildDataToCard();
        }
    }

    

    render(){
        const {vistoryNumber, textCard} = this.state
        return(
            <CardPresentational 
                number={this.state.showDefaultNumbers ? this.state.defaultNumber : vistoryNumber}
                text={textCard}
            />
        )
    }
}

export default ArmadilhasVistoriadasContainer