import React from "react";
import {timeStampFormated} from "../../../services/utils/format";
import "../../../assets/css/style.css";

class DemandInfo extends React.Component {
  state ={
      Demand : this.props.Demand,
  }
  getPriorityName (problemPriority){
    switch(problemPriority){
        case 1:
            return "Imediato";
        case 2:
            return "Até 24 horas";
        case 3:
            return "Até 48 horas";
        case 4:
            return "Até 72 horas";
        case 5:
            return "Acima 72 horas";
        default:
            return "Sem prioridade";            
    }
}
  render() {
    var DemandDateformatted = timeStampFormated(this.state.Demand.createDate);
    return (
      <>
        <div className="row linha-form">
            <div className="col-4">
                <span className="h5 text-black">Número da OS: {this.state.Demand.serviceOrder}</span>
            </div>
            <div className="col-4">
                <span className="h5 text-black">Atendente: {this.state.Demand.attendant}</span>
            </div>
            <div className="col-4">
                <span className="h5 text-black">Data de Registro: {DemandDateformatted}</span>
            </div>
        </div>
        <div className="row linha-form">
            <div className="col-4">
                <span className="h5 text-black">Tipo: {this.state.Demand.type}</span>
            </div>
            <div className="col-4">
                <span className="h5 text-black">Origem: {this.state.Demand.originName}</span>
            </div>
        </div>
        <div className="row linha-form">
            <div className="col-4">
                <span className="h5 text-black">Necessidades: {this.state.Demand.problems.map((problem) => {
                    return <div>{problem.name}</div>
                })}</span>
            </div>
            <div className="col-4">
                <span className="h5 text-black">Programas: {this.state.Demand.problems.map((problem) => {
                    return <div>{problem.program.name}</div>
                })}</span>
            </div>
            <div className="col-4">
                <span className="h5 text-black">Prioridades: {this.state.Demand.problems.map((problem) => {                
                    return <div><i className="mr-3" />{this.getPriorityName(problem.priority)}</div>
                 })}</span>
            </div>
        </div>
        <div className="row linha-form">
            <div className="col-4">
                <span className="h5 text-black">Nome das Territorializações: {this.state.Demand.territorializations.map(({name}) => name).join(", ")}</span>
            </div>
            <div className="col-4">
                <span className="h5 text-black">Serviços: {this.state.Demand.problems.map((problem) => {                
                    return <div><i className=" mr-3" />{problem.service.name}</div>
                 })}</span>
            </div>
        </div>
      </>
    );
  }
}

export default DemandInfo;