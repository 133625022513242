import React from "react";

import { Col, Row } from "reactstrap";
import { Bar, Rectangle } from "recharts";
import PieChartGraphic from "../../../components/Graphics/Pie/PieChartGraphic";
import BarChartGraphic from "../../../components/Graphics/Bar/BarChartGraphic";
import moment from "moment";
import HorizontalChartGraphic from "../../../components/Graphics/Composed/HorizontalChartGraphic";

const ManagementPageGraphicsWrapper = ({ data, filtersValues }) => {
  const [formatedDataForGraphics, setFormatedDataForGraphics] = React.useState(
    []
  );

  React.useEffect(() => {
    if (!data || data.lenght === 0) return;

    const formatedData = data.map((report) => {
      return {
        "Armadilhas Instaladas": report.activeTraps,
        "Área em KM²": report.squareKilometre,
        "Área de Intervenção": report.territorializationName,
        "Densidade Armadilhas/KM²": report.trapsDensityBySquareKilometre,
        "Área de Risco": report.trapsNotProtectingAreas,
        "Área de Risco (%)": report.trapsNotProtectingAreasPercentage,
        "Armadilhas 1º Período": report.trapsOnFirstPeriod,
        "Armadilhas 1º Período (%)": report.trapsOnFirstPeriodPercentage,
        "Armadilhas 2º Período": report.trapsOnSecondPeriod,
        "Armadilhas 2º Período (%)": report.trapsOnSecondPeriodPercentage,
        "Armadilhas 3º Período": report.trapsOnThirdPeriod,
        "Armadilhas 3º Período (%)": report.trapsOnThirdPeriodPercentage,
        "Área Protegida": report.trapsProtectingAreas,
        "Área Protegida (%)": report.trapsProtectingAreasPercentage,
      };
    });

    setFormatedDataForGraphics(formatedData);
  }, [data]);

  const beginDateFormated =
    filtersValues &&
    filtersValues.beginDate &&
    moment(filtersValues.beginDate).format("DD/MM/YYYY");
  const finalDateFormated =
    filtersValues &&
    filtersValues.finalDate &&
    moment(filtersValues.finalDate).format("DD/MM/YYYY");

  return (
    <>
      <Row className="mt-12 mb-12" style={{ paddingBottom: "1.5rem" }}>
        <Col className="mb-6 mb-xl-0" xl="6">
          <PieChartGraphic
            data={formatedDataForGraphics}
            dataKey="Armadilhas Instaladas"
            customTooltip={<InstalledTrapsCustomTooltip />}
            headerText={`Armadilhas Instaladas (${filtersValues.trapType.label}) em ${beginDateFormated} a ${finalDateFormated}`}
            legendsKey="Área de Intervenção"
          />
        </Col>
        <Col className="mb-6 mb-xl-0" xl="6">
          <PieChartGraphic
            data={formatedDataForGraphics}
            dataKey="Área em KM²"
            customTooltip={<SquareKilometersCustomTooltip />}
            headerText={`Áreas em KM² (${filtersValues.trapType.label}) em ${beginDateFormated} a ${finalDateFormated}`}
            legendsKey="Área de Intervenção"
          />
        </Col>
      </Row>
      <Row className="mt-12 mb-12" style={{ paddingBottom: "1.5rem" }}>
        <Col className="mb-6 mb-xl-0" xl="6">
          <BarChartGraphic
            data={formatedDataForGraphics}
            headerText={`Densidade de Armadilhas por KM² (${filtersValues.trapType.label}) em ${beginDateFormated} a ${finalDateFormated}`}
            xAxisDataKey="Área de Intervenção"
            bars={[
              <Bar
                dataKey="Densidade Armadilhas/KM²"
                fill="#8884d8"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
            ]}
          />
        </Col>
        <Col className="mb-6 mb-xl-0" xl="6">
          <BarChartGraphic
            data={formatedDataForGraphics}
            headerText={`Status da Manutenção em Valores Absolutos (${filtersValues.trapType.label}) em ${beginDateFormated} a ${finalDateFormated}`}
            xAxisDataKey="Área de Intervenção"
            bars={[
              <Bar
                dataKey="Armadilhas 1º Período"
                fill="#0dd649"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
              <Bar
                dataKey="Armadilhas 2º Período"
                fill="#dcac00"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
              <Bar
                dataKey="Armadilhas 3º Período"
                fill="#f10a0a"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
            ]}
          />
        </Col>
      </Row>
      <Row className="mt-12 mb-12" style={{ paddingBottom: "1.5rem" }}>
        <Col className="mb-6 mb-xl-0" xl="6">
          <HorizontalChartGraphic
            barsAndLines={[
              <Bar
                dataKey="Armadilhas 1º Período (%)"
                fill="#0dd649"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                stackId="a"
                barSize={20}
              />,
              <Bar
                dataKey="Armadilhas 2º Período (%)"
                fill="#dcac00"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                stackId="a"
                barSize={20}
              />,
              <Bar
                dataKey="Armadilhas 3º Período (%)"
                fill="#f10a0a"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                stackId="a"
                barSize={20}
              />,
            ]}
            data={formatedDataForGraphics}
            xAxisTickFormatter="%"
            yAxisDataKey="Área de Intervenção"
            headerText={`Status da Manutenção em Percentual (${filtersValues.trapType.label}) em ${beginDateFormated} a ${finalDateFormated}`}
          />
        </Col>
        <Col className="mb-6 mb-xl-0" xl="6">
          <BarChartGraphic
            data={formatedDataForGraphics}
            headerText={`Áreas de Risco x Áreas Protegidas (${filtersValues.trapType.label}) em ${beginDateFormated} a ${finalDateFormated}`}
            xAxisDataKey="Área de Intervenção"
            bars={[
              <Bar
                dataKey="Área Protegida (%)"
                fill="#0dd649"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
              <Bar
                dataKey="Área de Risco (%)"
                fill="#f10a0a"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

const InstalledTrapsCustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p className="label" style={{ fontWeight: "bold" }}>
          {data["Área de Intervenção"]}: {data["Armadilhas Instaladas"]} ADI's
        </p>
      </div>
    );
  }

  return null;
};

const SquareKilometersCustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p className="label" style={{ fontWeight: "bold" }}>
          {data["Área de Intervenção"]}: {data["Área em KM²"]}KM²
        </p>
      </div>
    );
  }

  return null;
};

export default ManagementPageGraphicsWrapper;
