import React from "react";
import "./../../assets/css/navbar.css";
import { upload } from "../../services/api/Inmet";


// reactstrap components
import {
    Container,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";

class InmetImporter extends React.Component {

    constructor(props) {

    super(props);
      this.state = {
        selectedFile: null
      }
    this.onClickHandler = this.onClickHandler.bind(this)
    }
    onChangeHandler=event=>{
        this.setState({
        selectedFile: event.target.files[0]
        })
    }
    async onClickHandler (e) {
        e.preventDefault();

        if (this.state.selectedFile == null) {
            toast.error("Erro: Nenhum arquivo selecionado!")
            return false;
        }

        const data = new FormData();
        data.append('file', this.state.selectedFile);

        const requestInmet = upload(data);
        requestInmet.then((response) => {           
        }).catch((error) => {
            console.log(error);
        }).then(() => {
            toast.success("Arquivo enviado com sucesso!");
        });
    }
    render() {

        return (            
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" >
                <ToastContainer/>
                <Container fluid>
                    <div className="header-body">
                        <form onSubmit={this.onClickHandler} method="post" enctype="application/octet-stream">
                            <div className="form-group files color">
                                <input type="file" name="file" onChange={this.onChangeHandler}/>
                            </div>

                            <div className="row">
                                <div className="col-4 center">
                                    <button type="submit" className="btn btn-primary">Enviar arquivo</button>
                                </div>
                            </div>
                        </form>
                        
                    </div>
                </Container>
            </div>
        );

    }

}

export default InmetImporter;