import request from '../utils/request';
//import request from '../utils/requestTest';

export function getTeam() {
  return request({
    url: '/Teams',
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });
}
export function getTeamById(id) {
  return request({
    url: '/Teams/'+id,
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });
}
export function deleteTeam(id) {
  return request({
    url: '/Teams/'+id,
    method: 'delete',
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });
}
export function postTeam(TeamData) {

  let teamPost = {
    "Name": TeamData.Name,
    "ProgramId" : TeamData.Program,
    "Members": [],
    "Description": TeamData.Description,
    "organizationId": TeamData.organizationId
  };

  let Comissioner = TeamData.Members;
  let Executors = TeamData.Executioner;
  let Superviser = TeamData.Superviser;
  let Coordinator = TeamData.Coordinator;


  let membersOld = [...Executors]; //precisa atribuir dessa forma para ser feita uma shalow copy e não fazer as alterações na referência do state.
  if(Comissioner){
    membersOld.push(Comissioner);
  }

  if(Superviser){
   membersOld.push(Superviser);
  }

  if(Coordinator){
    membersOld.push(Coordinator);
  }

  membersOld.forEach(member => {

    let IsComissioner = false;
    let IsExecutor = false;
    let IsSuperviser = false;
    let IsCoordinator = false;

    if(Comissioner && Comissioner.value === member.value) {
      IsComissioner = true;
    }

    if(Superviser && Superviser.value === member.value) {
      IsSuperviser = true;
    }

    if(Coordinator && Coordinator.value === member.value) {
      IsCoordinator = true;
    }

    for(var i=0; i<Executors.length; i++) {
      if(Executors[i].value === member.value) {
        IsExecutor = true;
      }
    }

    teamPost.Members.push({
      "EmployeeId": member.value,
			"IsComissioner": IsComissioner,
      "IsExecutor": IsExecutor,
      "IsSuperviser": IsSuperviser,
			"IsCoordinator": IsCoordinator
    })
  });

  teamPost.Members = teamPost.Members.filter(function (a) {
    return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
  }, Object.create(null));

  return request({
    url: '/Teams',
    method: 'post',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    data: teamPost,
  });
}

export function putTeam(id,TeamData) {

  let teamPost = {
    "Name": TeamData.Name,
    "ProgramId" : TeamData.Program,
    "Description": TeamData.Description,
    "Members": [],
    "OrganizationId": TeamData.OrganizationId,
  };

  let Comissioner = TeamData.Members;
  let Executors = TeamData.Executioner;
  let Superviser = TeamData.Superviser;
  let Coordinator = TeamData.Coordinator;

  let membersOld = [...Executors]; //precisa atribuir dessa forma para ser feita uma shalow copy e não fazer as alterações na referência do state.
  if(Comissioner){
    membersOld.push(Comissioner);
  }

  if(Superviser){
   membersOld.push(Superviser);
  }

  if(Coordinator){
    membersOld.push(Coordinator);
  }

  membersOld.forEach(member => {

    let IsComissioner = false;
    let IsExecutor = false;
    let IsSuperviser = false;
    let IsCoordinator = false;
    

    if(Comissioner && Comissioner.value === member.value) {
      IsComissioner = true;
    }

    if(Superviser && Superviser.value === member.value) {
      IsSuperviser = true;
    }

    if(Coordinator && Coordinator.value === member.value) {
      IsCoordinator = true;
    }

    for(var i=0; i<Executors.length; i++) {
      if(Executors[i].value === member.value) {
        IsExecutor = true;
      }
    }

    teamPost.Members.push({
      "EmployeeId": member.value,
      "IsComissioner": IsComissioner,
      "IsExecutor": IsExecutor,
      "IsSuperviser": IsSuperviser,
      "IsCoordinator": IsCoordinator
    })
  });


  teamPost.Members = teamPost.Members.filter(function (a) {
    return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
  }, Object.create(null));

  return request({
    url: '/Teams/'+id,
    method: 'put',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    data: teamPost,
  });
}