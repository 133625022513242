import React from "react";
import { login } from "../../../services/api/login";
// nodejs library to set properties for components
import {setPermissions, setToken, setOrganization ,setUserData} from "../../../services/utils/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from "./ForgotPassword.jsx";
import logo from "../../../assets/img/brand/arbotech.png";

// reactstrap components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { getPermissions, getOrganization } from "../../../services/api/Users";
import { getFirstPermissionRoute } from "../../../services/utils/permission";

class Login extends React.Component {
  state={
    modal:"",
    passwordShow: false,
  }

  signIn = async () => {
    if(this.username != '') {
        const ipData = await this.getUserIpInformations();

        const loginHistoryData = {
          browser: this.getBrowserName(),
          authenticatedOnApp: false,
          ...ipData,
        }
        
        const LoginRequest = login(this.username, this.password, loginHistoryData);        
        LoginRequest.then(response => {
          if(response) {
              return response.data
          }
          throw new Error("Login inválido...");
      })
      .then( async data => {        
          if (data.authenticated == true) {
            setToken(data.accessToken);
            setUserData(data);
            
            const OrganizationRequest = await getOrganization(data.organization);
            setOrganization(OrganizationRequest.data);

            const PermissionsRequest = await getPermissions(data.userId);
            setPermissions(PermissionsRequest.data);
            let route = getFirstPermissionRoute();

            const arrayDeIds = ['9773cf4d-38c5-4d99-a6f5-4bc3217a9ed6', 'a9c5666a-d20c-4a58-83b2-e1eea6096f3a',
             '7decc586-31a4-427b-ba6e-8ef2c4345862', '7ad50b00-82be-4607-8a45-295ce4300410', '84d4c661-f07d-4a3d-965c-c16f7ad7b51a', 'ef534314-2e8e-4273-b0fa-1c9c5c05e472'
            ];

            if(arrayDeIds.includes(data.userId))
            {
              this.props.history.push('/admin/webForm');
            } else {
                this.props.history.push(route);
            }

            
            
            return;
          } else {
            toast.error("Falha ao autenticar! Verifique Login e senha..");
          }        
      })      
    } else {
        toast.error("Login inválido!");
    }
  }

  getUserIpInformations = async () => {
    const request = await fetch("http://ipinfo.io/json");
    if(!request.ok)
      return {
        ip: "000.00.0.0",
        city: "Não encontrado",
        provider: "Não encontrado",
        latitude: 0,
        longitude: 0,
      }

    const responseInJson = await request.json();

    const result = {
      ip: responseInJson.ip ? responseInJson.ip : "000.00.0.0",
      city: responseInJson.city ? responseInJson.city : "Não encontrado",
      provider: responseInJson.org ? responseInJson.org : "Não encontrado",
      latitude: responseInJson.loc ? responseInJson.loc.split(",")[0] : 0,
      longitude: responseInJson.loc ? responseInJson.loc.split(",")[1] : 0,
    }

    return result;
  }

  getBrowserName = () => {
    const userAgent = navigator.userAgent;
    let browserName = 'Unknown';
  
    if (/firefox|fxios/i.test(userAgent)) {
      browserName = 'Firefox';
    } else if (/edg/i.test(userAgent)) {
      browserName = 'Microsoft Edge';
    } else if (/opr\//i.test(userAgent)) {
      browserName = 'Opera';
    } else if (/chrome|crios/i.test(userAgent)) {
      browserName = 'Chrome';
    } else if (/safari/i.test(userAgent)) {
      browserName = 'Safari';
    } else if (/msie|trident/i.test(userAgent)) {
      browserName = 'Internet Explorer';
    }
  
    return browserName;
  };

  keyPress =(e)=>{
    if(e.key=="Enter"){
      this.signIn();
    }
  }
  showPassword = () => {
    const { passwordShow } = this.state;

    this.setState({passwordShow : !passwordShow})
  }
  toggle =()=>{
    this.setState({modal:!this.state.modal});
  }

  componentWillMount() {
    require('../../../assets/css/style_mcs.css');
  }
  
  render() {

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">

          <ToastContainer  />
          <Modal size="lg" isOpen={this.state.modal} fade="true"  toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>Recuperar senha</ModalHeader>
                <ModalBody>           
                   <ForgotPassword/>
                    

                </ModalBody>

            </Modal>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>
                  <img alt="" className="navbar-brand-img"
                    src={logo} width="200" />
                </small>
              </div>
              <Form role="form"onKeyDown={(e)=>this.keyPress}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Login"  onKeyDown={(e)=>this.keyPress}  onChange={e => this.username = e.target.value} type="text" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    
                    {
                      this.state.passwordShow 
                      ?
                        <Input placeholder="Senha" onKeyDown={this.keyPress} onChange={e => this.password = e.target.value}  type="text" />
                      :
                        <Input placeholder="Senha" onKeyDown={this.keyPress} onChange={e => this.password = e.target.value}  type="password"/>
                    }
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        {
                          this.state.passwordShow 
                          ?
                          <i class="fas fa-eye-slash" onClick={this.showPassword}></i>
                          :
                          <i class="fas fa-eye" onClick={this.showPassword}></i>
                        }
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Lembra-me</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button className="my-4" onClick={this.signIn} color="primary-arbotech" type="button">
                    Entrar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                onClick={this.toggle}
                
              >
                <small>Esqueci minha senha</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"                
              >
                <small>Me ajuda!</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default Login;
