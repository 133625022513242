import React, {useEffect, useState} from "react";
import CloropleticMap from "../../../../views/pages/Reports/CloropleticMap";
import moment from "moment";
import { Card, CardBody, CardHeader } from "reactstrap";
import {polygon as CreatePolygon} from '@turf/turf'

const OperacionalMapContainer = ({data, territorializationList, territorializationFiltered}) => {
    const [pointsGroup, setPointsGroup] = useState(null);
    const [delimiterPolygonGroups, setDelimiterPolygonGroups] = useState([]);

    useEffect(() => {
        if(data){
            checkData();
        }

        if(territorializationList){
            buildMapDelimiter();
        }
    }, [data, territorializationList])    


    const checkData = async () => {
        let mapList = [];
        let visitTypes = data.reduce((arrayTypes, currentValue) => {
            if(arrayTypes.findIndex(type => type === currentValue.tipoImovel) === -1){
                arrayTypes.push(currentValue.tipoImovel);  
            }
            
            return arrayTypes;
            
        },[]);

        let visitSituations = data.reduce((arraySituations, currentValue) => {
            if(arraySituations.findIndex(type => type === currentValue.visitSituation) === -1){
                arraySituations.push(currentValue.visitSituation);  
            }
            
            return arraySituations;
            
        },[]);

        for(const type of visitTypes){
            mapList.push(await buildMapList(data.filter(visit => visit.tipoImovel === type), type));
        }

        for(const situation of visitSituations){
            mapList.push(await buildMapList(data.filter(visit => visit.visitSituation === situation), situation));
        }

        setPointsGroup(mapList);
    }

    const buildMapList = async (visit, key) => {
        const mapList = new Array();
        const heatMapTitle = `Calor - ${key}`;

        for(let i = 0; i < visit.length; i++){
            //adicionando 1 dia a mais por causa do dado do servidor
            const name = `Data da Visita: ${moment(visit[i].activityDate).add(1, "day").format("DD/MM/YYYY")}`;
            const position = [visit[i].latitude, visit[i].longitude];
            const icon = require('../../../../assets/img/icons/visita.png');
            const intensity = 1;

            const mapListObject = {
                name,
                position,
                intensity,
                icon
            }
            
            mapList.push(mapListObject);
        }

        const pointsGroupObject = {
            name: `Pontos - ${key}`,
            heatMapTitle,
            points: mapList,
        }

        return pointsGroupObject;
    }

    const buildMapDelimiter = () => {
        const delimiterGroups = [];

        for(const group of territorializationList){
            let list = [...group.territorializationList];
            if(territorializationFiltered.length > 0){
                list = list.filter((territorialization) => territorializationFiltered.some(({value}) => territorialization.id === value));
            }

            const delimiterGroup = {
                name: group.name,
                Polygons: []
            };

            for(const territorialization of list){
                if(territorialization.geometryType !== "POLYGON"){
                    continue;
                }
                
                const turfPolygon = CreatePolygon([territorialization.coordinates.map(({x, y}) => [x, y])])
                delimiterGroup.Polygons.push(turfPolygon)
            }
            
            if(delimiterGroup.Polygons.length > 0){
                delimiterGroups.push(delimiterGroup)
            }
        }

        setDelimiterPolygonGroups(delimiterGroups)
    }

    return(
        <Card>
            <CardHeader style={{color:"black", fontWeight:"bold", fontSize:"0.97rem"}}>
                {`Mapa de Visitas`}
            </CardHeader>
            <CardBody className="pb-0 pt-0 pl-0 pr-0">
                <CloropleticMap
                    PointsGroup={pointsGroup}
                    maxClusterRadius={10}
                    HeatMapGroups={pointsGroup}
                    HeatMapGroupped={true}
                    PolygonsGroup={delimiterPolygonGroups}
                    showCloropletic={false}
                />
                
            </CardBody>
        </Card>
        
    );
    
}

export default OperacionalMapContainer;