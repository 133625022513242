import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import "./_Options.css";


const VersionPgs = React.FC = () => {
    return (
        <div>
            <p className="h4 text-white">Escolha a versão:</p>
            <select className="form-control" >
                <option value="samp_version">São Paulo</option>
                <option value="normal_version">Foz</option>
                <option value="standard_version">Standard</option>
            </select>
        </div>
    );
}

export default VersionPgs;