import request from '../utils/request';
//import request from "../utils/requestTests/requestTestVisits";

export function getVisitsByDemandId(DemandId) {
  return request({
    url: "/Visits/GetAllByDemand/" + DemandId,
    method: "get",
  });
}

export function getVisitsForReport(filter) {
  return request({
    url: "/Visits/GetDataForReport",
    method: "post",
    data: filter,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function getVisitsVigilanciaByDemandId(DemandId) {
  return request({
    url: "/VigilanciaEntomologica/GetByDemand/" + DemandId,
    method: "get",
  });
}

export function getAllVigilanciasByFilter(filter) {
  return request({
    url: "/VigilanciaEntomologica/AllVigilanciasByFilter",
    method: "post",
    data: filter,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function fetchVisitsByStatus(filter) {
  return request({
    url: "/VigilanciaEntomologica/VisitsByStatus",
    method: "post",
    data: filter,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function fetchConsolidatedVisits(filter) {
  return request({
    url: "/VigilanciaEntomologica/ConsolidatedVisits",
    method: "post",
    data: filter,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}
