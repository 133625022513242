import React from "react";
import { Card, CardHeader, Col, Row } from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment";
import DataTable from "react-data-table-component";
import SamplesExportFilters from "./SamplesExportFilters";
import Skeleton from "../../../../components/ui/Skeleton";

const SamplesExportTable = ({
  samplesToTable,
  samplesToExport,
  isSamplesAnalysisDataLoading,
}) => {
  const [samplesToRenderOnTable, setSamplesToRenderOnTable] =
    React.useState(samplesToTable);
  const [filteredSamplesToExport, setFilteredSamplesToExport] =
    React.useState(samplesToExport);
  const [samplesFilters, setSamplesFilters] = React.useState({
    sampleNumber: "",
    demandNumber: "",
    trapNumber: "",
    beginCollectDate: "",
    endCollectDate: "",
    trapType: {
      label: "Todos",
      value: "all",
    },
    collector: {
      label: "Todos",
      value: "all",
    },
    situation: {
      label: "Todas",
      value: "all",
    },
    materialType: {
      label: "Todos",
      value: "all",
    },
  });

  React.useEffect(() => {
    if (!samplesToExport) return;

    filterSamples();
  }, [samplesFilters, samplesToExport]);

  const handleFiltersChange = (filterName, newValue) => {
    const newFiltersValues = { ...samplesFilters };

    newFiltersValues[filterName] = newValue;

    setSamplesFilters(newFiltersValues);
  };

  const filterSamples = () => {
    const filteredSamples = samplesToExport.filter((sample) => {
      if (
        samplesFilters.sampleNumber !== "" &&
        !sample.NUMERO_AMOSTRA.includes(samplesFilters.sampleNumber)
      )
        return false;

      if (
        samplesFilters.trapNumber !== "" &&
        !sample.NUMERO_ARMADILHA.includes(samplesFilters.trapNumber)
      )
        return false;

      if (
        samplesFilters.demandNumber !== "" &&
        !sample.NUMERO_DEMADA.includes(samplesFilters.demandNumber)
      )
        return false;

      if (
        samplesFilters.trapType.value !== "all" &&
        sample.TIPO_ARMADILHA !== samplesFilters.trapType.label
      )
        return false;

      if (
        samplesFilters.collector.value !== "all" &&
        sample.NOME_COLETOR !== samplesFilters.collector.label
      )
        return false;

      if (
        samplesFilters.situation.value !== "all" &&
        sample.SITUACAO_AMOSTRA !== samplesFilters.situation.label
      )
        return false;

      if (
        samplesFilters.materialType.value !== "all" &&
        sample.TIPO_MATERIAL !== samplesFilters.materialType.label
      )
        return false;

      if (
        samplesFilters.beginCollectDate !== "" &&
        samplesFilters.endCollectDate !== ""
      ) {
        const beginColletcDate = moment(samplesFilters.beginCollectDate);
        const endColletcDate = moment(samplesFilters.endCollectDate);
        const colletcTade = moment(sample.DATA_COLETA, "DD/MM/YYYY");

        return colletcTade.isBetween(
          beginColletcDate,
          endColletcDate,
          null,
          "[]"
        );
      }

      return true;
    });

    setFilteredSamplesToExport(filteredSamples);
    setSamplesToRenderOnTable(filteredSamples);
  };

  const tableColumns = [
    {
      name: "Tipo da armadilha",
      selector: "TIPO_ARMADILHA",
      sortable: true,
    },
    {
      name: "Número da armadilha",
      selector: "NUMERO_ARMADILHA",
      sortable: true,
    },
    {
      name: "Número da demanda",
      selector: "NUMERO_DEMADA",
      sortable: true,
    },
    {
      name: "Número da amostra",
      selector: "NUMERO_AMOSTRA",
      sortable: true,
    },
    {
      name: "Data da coleta",
      selector: "DATA_COLETA",
      sortable: true,
    },
    {
      name: "Coletor",
      selector: "NOME_COLETOR",
      sortable: true,
    },
    {
      name: "Situação da amostra",
      selector: "SITUACAO_AMOSTRA",
      sortable: true,
    },
    {
      name: "Tipo de material",
      selector: "TIPO_MATERIAL",
      sortable: true,
    },
  ];

  return (
    <Row className="mt-12">
      <Col className="mb-12 mb-xl-0" xl="12">
        <Card className="shadow">
          <CardHeader className="border-0" style={{ paddingBottom: "0px" }}>
            <Row className="align-items-center">
              <div className="col">
                <h2 className="mb-0">Amostras</h2>
                <br />
              </div>
            </Row>
          </CardHeader>
          <SamplesExportFilters
            samplesFilters={samplesFilters}
            handleFiltersChange={handleFiltersChange}
            isSamplesAnalysisDataLoading={isSamplesAnalysisDataLoading}
          />
          <Row style={{ textAlign: "center", marginBottom: "1rem" }}>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isSamplesAnalysisDataLoading ? (
                <Skeleton variant="rectangle" height="1rem" width="300px" />
              ) : (
                <CsvDownloadButton
                  className="btn btn-primary"
                  filename={`amostras${moment().format("YYYY-MM-DD")}`}
                  data={filteredSamplesToExport}
                  style={{ width: "330px" }}
                >
                  <i className="fa fa-download" /> Baixar CSV das Amostras
                </CsvDownloadButton>
              )}
            </Col>
          </Row>
          <DataTable
            noHeader
            defaultSortField="name"
            defaultSortAsc={true}
            pagination
            highlightOnHover
            responsive
            columns={tableColumns}
            data={samplesToRenderOnTable}
            noDataComponent={"Nenhum registro encontrado."}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default SamplesExportTable;
