import React from "react";
import { Card, CardHeader, Col, Row } from "reactstrap";
import Skeleton from "../Skeleton";
import InputLoadingSkeleton from "./InputLoadingSkeleton";
import { faThLarge } from "@fortawesome/free-solid-svg-icons";

const TableLoadingSkeleton = ({length = 10}) => {
  return (
      <Row className="mt-12">
        <Col className="mb-12 mb-xl-0" xl="12">
          <Card className="shadow">
            <CardHeader className="border-0" style={{ paddingBottom: "0px" }}>
              <Row
                className="align-items-center"
                style={{ marginLeft: "2px", marginBottom: "1rem" }}
              >
                <Skeleton variant="rectangle" height="1rem" width="200px" />
              </Row>
            </CardHeader>
            <Row
              className="align-items-center"
              style={{ marginBottom: "1rem", padding: "0px 1.5rem" }}
            >
              <Col xl="4">
                <InputLoadingSkeleton labelWidth="120px" inputWidth="200px" />
              </Col>
              <Col xl="4">
                <InputLoadingSkeleton labelWidth="100px" inputWidth="200px" />
              </Col>
              <Col xl="4">
                <InputLoadingSkeleton labelWidth="170px" inputWidth="200px" />
              </Col>
            </Row>
            <Row
              className="align-items-center"
              style={{ marginBottom: "2rem", padding: "0px 1.5rem" }}
            >
              <Col xl="4">
                <InputLoadingSkeleton labelWidth="90px" inputWidth="200px" />
              </Col>
              <Col xl="4">
                <InputLoadingSkeleton labelWidth="150px" inputWidth="200px" />
              </Col>
              <Col xl="4">
                <InputLoadingSkeleton labelWidth="120px" inputWidth="200px" />
              </Col>
            </Row>
            <Row style={{ textAlign: "center", marginBottom: "2rem" }}>
              <Col
                style={{
                  display: "grid",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Skeleton variant="rectangle" height="1rem" width="300px" />
              </Col>
            </Row>
            {Array.from({ length: length }).map((_, index) => (
              <Row
                key={index}
                className="align-items-center"
                style={{
                  margin: "0px 10px",
                  padding: "10px",
                  backgroundColor: index % 2 === 0 ? "white" : "#f3f3f3",
                }}
              >
                <Col>
                  <Skeleton
                    variant="rectangle"
                    height="1rem"
                    width={index % 2 === 0 ? "100px" : "200px"}
                  />
                </Col>
                <Col>
                  <Skeleton
                    variant="rectangle"
                    height="1rem"
                    width={index % 2 === 0 ? "100px" : "200px"}
                  />
                </Col>
                <Col>
                  <Skeleton
                    variant="rectangle"
                    height="1rem"
                    width={index % 2 === 0 ? "100px" : "200px"}
                  />
                </Col>
                <Col>
                  <Skeleton
                    variant="rectangle"
                    height="1rem"
                    width={index % 2 === 0 ? "100px" : "200px"}
                  />
                </Col>
                <Col>
                  <Skeleton
                    variant="rectangle"
                    height="1rem"
                    width={index % 2 === 0 ? "100px" : "200px"}
                  />
                </Col>
                <Col>
                  <Skeleton
                    variant="rectangle"
                    height="1rem"
                    width={index % 2 === 0 ? "100px" : "200px"}
                  />
                </Col>
              </Row>
            ))}
          </Card>
        </Col>
      </Row>
  );
};

export default TableLoadingSkeleton;
