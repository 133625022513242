import React from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import CsvDownloadButton from "react-json-to-csv";
import { toast, ToastContainer } from "react-toastify";
import {
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CardHeader,
    Container,
    Row,
    Col,
    Table,
  } from "reactstrap";
  import { timeStampFormated } from "../../../../services/utils/format";
import moment from "moment";
import { daysDifferenceBetweenTodayAndDate } from "../../../../services/utils/TrapStatus/trapStatus";
import TableLoadingSkeleton from "../../../../components/ui/Loading/TableLoadingSkeleton";
import { getUserData } from "../../../../services/utils/auth";
import { checkShowBiovec } from "../../../../services/utils/Inventory/checkShowBiovec";

class InventoryItensTable extends React.Component {
    state = {
        itensByPlace: [],
        showBiovec: false
    }

    componentDidUpdate(prevProps){
        if(prevProps.Itens != this.props.Itens || prevProps.filterByPrefix != this.props.filterByPrefix) {
            const itensByPlace = Object.values(
                this.props.Itens.reduce((acumulador, objeto) => {
                    if (objeto.place === "BIOVEC" && !this.state.showBiovec) return acumulador;
                
                    let placeName = objeto.place;
                
                    const filterByPrefix = this.props.filterByPrefix; // teste
                    if (filterByPrefix) {
                    const prefix = objeto.place.split(" ")[0];
                    placeName = prefix;
                    }
                
                    let key = `${placeName}-${objeto.name}`;
                
                    if (!acumulador[key]) {
                    acumulador[key] = {
                        id: objeto.id,
                        name: objeto.name,
                        place: placeName,
                        storage: []
                    };
                    }
                
                    // Procura pelo lote existente no storage
                    const existingLot = acumulador[key].storage.find(
                    item => item.lot === objeto.lot
                    );
                
                    if (existingLot) {
                    // Se o lote já existir, soma a quantidade
                    existingLot.quantity += objeto.quantity;
                    } else {
                    // Se o lote não existir, adiciona um novo item ao storage
                    acumulador[key].storage.push({
                        manufacturerName: objeto.manufacturerName,
                        lot: objeto.lot,
                        quantity: objeto.quantity,
                        hasExpiration: objeto.hasExpiration,
                        expiration: objeto.expiration,
                        refrigeration: objeto.refrigeration
                    });
                    }
                
                    return acumulador;
                }, {})
            );
            

            this.setState({itensByPlace: itensByPlace})
        }
    }

    componentDidMount() {
        var showBiovec = checkShowBiovec();

        this.setState({showBiovec})
    }


    generateCSVData = () => {
        const data = {};
        const allNames = [];
    
        this.props.Itens.forEach(item => {
          if (!data[item.place]) {
            data[item.place] = { Local: item.place, endereço: item.placeAddress };
          }
          data[item.place][item.name] = (data[item.place][item.name] || 0) + item.quantity;
          if (!allNames.includes(item.name)) {
            allNames.push(item.name);
          }
        });
    
        allNames.sort();
    
        const csvData = Object.values(data).map(placeData => {
          const row = { ...placeData };
          allNames.forEach(name => {
            row[name] = placeData[name] || ""; 
          });
          return row;
        });
    
        return csvData;
    };

    buildLotAndSituation(moviment) {
        // s=> s.expiration ? daysDifferenceBetweenTodayAndDate(s.expiration) : null

        return moviment.storage.map(s => {
            var newStyle = {marginTop: '5px', marginBottom: '5px', padding: '5px'};
            var color = "green";
            if(s.expiration) {
                var daysExpiration =  daysDifferenceBetweenTodayAndDate(s.expiration);
                
                if(daysExpiration < 0) {
                    daysExpiration = Math.abs(daysExpiration);
                    if(daysExpiration <= 30) {
                        color = "red";
                    }else if(daysExpiration <= 60) {
                        color = "yellow";
                    }
                } else {
                    color = "red";
                }
                
                newStyle = {...newStyle, border: "1px solid black", background: color, color: color == "yellow" ? "black" : "white", borderRadius: 5}
            } else {
                newStyle = {...newStyle, border: "1px solid black", background: "green", color: "white", borderRadius: 5}
            }

            return <div style={newStyle}>{s.lot == "" ? "Nenhum" : s.lot}</div>
        })
    }

    render() {
        const columns = [
            {
                name:'Nome',
                selector: 'name',
                sortable:true,
                
            },
            {
                name:'Local de armazenamento',
                selector: 'place',
                sortable:true,
            },
            {
                name: 'Lote',
                selector: (i => i.storage && this.buildLotAndSituation(i))
            }, 
            {
                name: 'Quantidade',
                selector: (i => i.storage && i.storage.map(s => <div style={{marginTop: '5px', marginBottom: '5px', padding: '5px'}}>{s.quantity}</div>))
            }, 
            {
                name: 'Fabricante',
                selector: (i => i.storage && i.storage.map(s => <div style={{marginTop: '5px', marginBottom: '5px', padding: '5px'}}>{s.manufacturerName}</div>))
            }, 
            {
                name: 'Validade',
                selector: (i => i.storage && i.storage.map(s => <div style={{marginTop: '5px', marginBottom: '5px', padding: '5px'}}>{s.hasExpiration || s.expiration ? timeStampFormated(s.expiration) : "Sem Expiração"}</div>))
            }, 
            {
                name: 'Sob refrigeração',
                selector: (i => i.storage && i.storage.map(s => <div style={{marginTop: '5px', marginBottom: '5px', padding: '5px'}}>{s.refrigeration ? "Sim" : "Não"}</div>))
            }
        ];
        
        return (
            <Container className="mt--7" fluid>
                <Row className="mt-12">
                    <Col className="mb-12 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                    <h3 className="mb-0">Estoque na data {timeStampFormated(new Date())}</h3>
                                    </div>
                                </Row>
                                <Row style={{ display: "flex", justifyContent: "center" }}>
                                    <CsvDownloadButton
                                        className="btn btn-primary"
                                        filename={`estoque-${moment().format("YYYY-MM-DD")}`}
                                        data={this.generateCSVData()}
                                        style={{ width: "330px" }}
                                    >
                                        <i className="fa fa-download" /> Baixar CSV do estoque
                                    </CsvDownloadButton>
                                </Row>
                            </CardHeader>
                            {
                                !this.props.loadedInventoryData &&
                                <TableLoadingSkeleton length={3} />
                            }
                            {
                                this.state.itensByPlace && this.props.loadedInventoryData
                                ?
                                <DataTable
                                    noHeader
                                    defaultSortField="name"
                                    defaultSortAsc={true}
                                    pagination
                                    highlightOnHover
                                    columns={columns}
                                    // conditionalRowStyles={[
                                    //     {
                                    //         when: row => row.name == "ADI",
                                    //         style: {
                                    //             backgroundColor: 'green',
                                    //             color: 'white'
                                    //         }
                                    //     }
                                    // ]}
                                    data={this.state.itensByPlace}
                                    noDataComponent={'Nenhum registro encontrado.'}
                                />
                                :
                                <></>
                            }
                            
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default InventoryItensTable