import React, { useEffect, useState } from "react";
import Chart from "chart.js";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.jsx";

import NotificationsByDayContainer from "../components/Containers/Inmet/Charts/Line/NotificationsByDayContainer";
import InmetFilterPresentational from "../components/Presentational/Inmet/InmetFilterPresentational";
import { getRainNotifications } from "../services/api/Inmet";



const DashboardInmet = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [dataToChart, setDataToChart] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(()=> {
    const date = new Date();
    const endDateString = date.getFullYear()+"-"+(date.getMonth()+1 < 10 ? "0"+(date.getMonth()+1) : date.getMonth()+1)+"-"+(date.getDate() < 10 ? "0"+date.getDate() :date.getDate());
    date.setDate((date.getDate() - 15));

    const startDateString = date.getFullYear()+"-"+(date.getMonth()+1 < 10 ? "0"+(date.getMonth()+1) : date.getMonth()+1)+"-"+(date.getDate() < 10 ? "0"+date.getDate():date.getDate());
    
    setStartDate(startDateString);
    setEndDate(endDateString);
  },[]);

  useEffect(() => {

    if(startDate !== "" && endDate !== ""){
      async function fetchData(){
        const filter = {
          startDate: startDate,
          endDate:endDate,
        }
    
        const reqNotification = await getRainNotifications(filter);
        setDataToChart(reqNotification.data);
      }
      fetchData();
    }
    
  }, [startDate, endDate])

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };

  const styles = {
    grid: {
      paddingLeft: 0,
      paddingRight: 0
    },
    row: {
      marginLeft: 0,
      marginRight: 0
    },
    col: {
      paddingLeft: 0,
      paddingRight: "0.2vw"
    }
  };
  return (
    <>
      {/* Page content */}
      <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8">
        <InmetFilterPresentational 
          startDate={startDate} 
          endDate={endDate} 
          changeStartDate={setStartDate} 
          changeEndDate={setEndDate}
        />
      </Container>
      <Container fluid className="mt--7">
        <Row className="mb-5" >
          <Col xl="12" className="mb-5 mb-xl-0">
            <NotificationsByDayContainer
              startDate={startDate}
              endDate={endDate}
              data={dataToChart}
            />
          </Col>
        </Row>
        
      </Container>
    </>
  );
};

export default DashboardInmet;
