import React, { useEffect, useState, useRef } from "react";
import Chart from "chart.js";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.jsx";

import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { buildEpidemiologicalLabels } from "../../../services/utils/Entomologico/Labels";
import { getEpidemiologicalPeriod } from "../../../services/utils/Entomologico/Period";
import MainPeriodComponent from "../../../components/Inputs/MainPeriodComponent";
import getWeeks from "../../../services/utils/epidemiologicalWeek";
import LoadingSpin from "react-loading-spin";
import { getDepositMapInfo, getIB, getIIP, getITR } from "../../../services/api/Deposits";
import { getUserData } from "../../../services/utils/auth";
import LineIIPChartContainer from "../../../components/Containers/Deposit/LineIIPChartContainer";
import LineIBChartContainer from "../../../components/Containers/Deposit/LineIBChartContainer";
import PieITRChartContainer from "../../../components/Containers/Deposit/PieITRChartContainer";
import VisitOperacionalFilterPresentational from "../../../components/Presentational/Operacional/VisitOperacionalFilterPresentational";
import GeographicFilterPresentational from "../../../components/Presentational/Filters/GeographicFilterPresentational";
import DepositoFilterPresentational from "../../../components/Presentational/Deposito/DepositoFilterPresentational";
import IIPMapContainer from "../../../components/Containers/Deposit/IIPMapContainer";
import IBMapContainer from "../../../components/Containers/Deposit/IBMapContainer";

const DashboardDepositos = ({demandList, employeeList, teamList, territorializationList}) => {
  const [isIIPChartLoading, setIIPChartLoading] = useState(false);
  const [isIIPMapLoading, setIIPMapLoading] = useState(false);
  const [isIBChartLoading, setIBChartLoading] = useState(false);
  const [isITRChartLoading, setITRChartLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [chartLabels, setChartLabels] = useState([]);
  const [chartPeriods, setChartPeriods] = useState([]);
  const [isFilterVisible, setFilterVisibility] = useState(true);
  const [IIP, setIIP] = useState([]);
  const [IB, setIB] = useState([]);
  const [ITR, setITR] = useState([]);
  const [DepositMapInfo, setDepositMapInfo] = useState(null);
  const [demand, setFilterDemand] = useState("00000000-0000-0000-0000-000000000000");
  const [employee, setFilterEmployee] = useState("00000000-0000-0000-0000-000000000000");
  const [team, setFilterTeam] = useState("00000000-0000-0000-0000-000000000000");
  const [selectedTerritorializations, setSelectedTerritorializations] = useState([]);
  const [depositType, setDepositType] = useState("");
  const [months, setFilterMonths] = useState([]);
  const [year, setFilterYear] = useState([]);
  const [weeks, setFilterWeeks] = useState([]);
  const [weekValue1, setWeekValue1] = useState([]);
  const [weekValue2, setWeekValue2] = useState([]);
  const organizationId = useRef(null);
  const teamEmployees = useRef([]);

  useEffect(()=> {
    
    organizationId.current = getUserData("organizationId");
    
    const date = new Date();

    //comentário para atualização
    const endDateString = date.getFullYear()+"-"+(date.getMonth()+1 < 10 ? "0"+(date.getMonth()+1) : date.getMonth()+1)+"-"+(date.getDate() < 10 ? "0"+date.getDate() :date.getDate());

    const startDateString = date.getFullYear()+"-"+"01"+"-"+"01";
    
    setStartDate(startDateString);
    setEndDate(endDateString);

    const year = [{label:date.getFullYear().toString(), value:date.getFullYear().toString()}];
    setFilterYear(year);

    
    buildYearsLabelsAndPeriods(year);


  },[]);

  useEffect(() => {
    if(chartLabels.length > 0){
      fetchData();
    }
    
  }, [chartLabels]);

  function applyFilter(){
    if(year.length > 0){
        buildYearsLabelsAndPeriods(year);
    }else{
        buildLabelsAndPeriods(startDate, endDate);
    }
  }

  function changePeriod(e, field){
    switch(field){
      case 'Year': 
        if(e === null){
          setFilterYear([]);
          setFilterMonths([]);
          setFilterWeeks([]);
          break;
        }else{
          e = e.sort((a,b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterYear(e !== null ? e : []);
      break;

      case 'Month':
        if(e !== null){
          setFilterWeeks([]);
          e = e.sort((a,b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterMonths(e !== null ? e : []);
      break;

      case 'EpiWeek':
        if(e !== null){
          setFilterMonths([]);
          e = e.sort((a,b) => parseInt(a.value) - parseInt(b.value));
        }
        
        setFilterWeeks(e !== null ? e : []);
      break;
      case 'Week1':
        if (e !== null) {
          setFilterMonths([]);
        }
        setWeekValue1(e !== null ? e : []);
      break;

      case 'Week2':
        if (e !== null) {
          setFilterMonths([]);
        }
        setWeekValue2(e !== null ? e : []);
      break;
    }
  }

  function handleChangeFilter(field, value){
    switch(field){
      case "startDate":
          if(moment(value).isAfter(endDate)){
            toast.error("Período de data inválido!");
          }else{
            setStartDate(value);
          }
      break;
      case "endDate":
        if(moment(value).isBefore(startDate)){
          toast.error("Período de data inválido!");
        }else{
          setEndDate(value);
        }
      break;
      case"depositType":
        setDepositType(value);
      break;
      case"demandId":
        if(value){
          setFilterDemand(value.value);
        }else{
          setFilterDemand("00000000-0000-0000-0000-000000000000")
        }
      break;
      case "teamId":
        if(value){
          let employeeUserIds = new Array();
          for(const member of value.members){
            if(member.userId){
              employeeUserIds.push(member.userId);
            }
          }
          teamEmployees.current = employeeUserIds;
        }else{
          teamEmployees.current = new Array();
        }

        setFilterTeam(value);
      break;
      case "employeeId":
        if(value){
          setFilterEmployee(value.value);
        }else{
          setFilterEmployee("00000000-0000-0000-0000-000000000000")
        }
      break;
      case "territorialization":
        if(value !== null){
          //colocando as territorializações para ficarem selecionadas no filtro.
          setSelectedTerritorializations(value);
        }else{
          setSelectedTerritorializations([]);
        }
      break;
    }
  }

  
    async function fetchData(){
      setIIPChartLoading(true);
      setIBChartLoading(true);
      setITRChartLoading(true); 
      setIIPMapLoading(true);

      const IIPRequest = getIIP(chartPeriods);
      IIPRequest.then((response) => {
        const iips = response.data;

        for(const index in iips){
          iips[index].label =`IIP ${chartPeriods[index].year}`;
        }

        setIIP(iips);
        setIIPChartLoading(false);
      })
      
      const IIBRequest = getIB(chartPeriods);
      IIBRequest.then((response) => {
        const ibs = response.data
        for (const index in ibs){
          ibs[index].label = `IB ${chartPeriods[index].year}`;
        }
        setIB(ibs);
        setIBChartLoading(false);
      })

      const cpLast = chartPeriods.length - 1;
      const cpLastPeriod = chartPeriods[cpLast].periods.length -1
      const singlePeriodFilter = {...chartPeriods[0], periods:{beginDate:chartPeriods[0].periods[0].beginDate, finalDate: chartPeriods[cpLast].periods[cpLastPeriod].finalDate}}
      

      const ITRRequest = getITR(singlePeriodFilter);
      ITRRequest.then((response) => {
        const itr = response.data;
        const formatedStartDate = moment(singlePeriodFilter.periods.beginDate, "YYYY-MM-DD").format("DD/MM/YYYY");
        const formatedEndDate = moment(singlePeriodFilter.periods.finalDate, "YYYY-MM-DD").format("DD/MM/YYYY");
        itr.label = `ITR ${formatedStartDate} - ${formatedEndDate}`
        setITR(itr);
        setITRChartLoading(false)
      })

      const MapInfoRequest = getDepositMapInfo(singlePeriodFilter);
      MapInfoRequest.then((response) => {
        const MapInfo = response.data;
        setDepositMapInfo(MapInfo);
        setIIPMapLoading(false);
      })
    }

    async function buildLabelsAndPeriods (startDate, endDate){
        const territorializations = selectedTerritorializations.map(({value}) => value);

        const employees = teamEmployees.current;
        if(employee != "00000000-0000-0000-0000-000000000000" && !employees.includes(employee)){
          employees.push(employee)
        }

        const labels = await buildEpidemiologicalLabels({startDate, endDate}); //semana epidemiológica
        const periods = new Array({year:"", organizationId:organizationId.current, periods:[], demand, employees, territorializations, depositType});
        let labelsToCharts = new Array();
        for(let i = 0; i < labels.length; i++){
            for(let labelIndex = 0; labelIndex < labels[i].labels.length; labelIndex++){
                const {finalDate, beginDate} = await getEpidemiologicalPeriod({labels:labels[i].labels, labelIndex, startDate, endDate, year:labels[i].year, lastYear: labels[labels.length - 1].year, firstYear:labels[0].year}); //semana epidemiológica
                labelsToCharts.push(labels[i].labels[labelIndex] + "/"+ labels[i].year);
                periods[0].periods.push({finalDate, beginDate});
            }
        }
        setChartPeriods(periods);
        setChartLabels(labelsToCharts);
    }

    async function buildYearsLabelsAndPeriods(years = new Array()){
        const territorializations = selectedTerritorializations.map(({value}) => value);

        const employees = teamEmployees.current;
        if(employee != "00000000-0000-0000-0000-000000000000" && !employees.includes(employee)){
          employees.push(employee)
        }

        const labels = new Array();
        const periods = new Array();

        if(months.length === 0 && weeks.length ===0){
            for(let i = 1; i <= 53; i++){
                labels.push(i);
            }
      
            for(let i = 0; i < years.length; i++){
                const epidemiologicalWeek = getWeeks().filter(ew => ew.year === years[i].value);
                periods.push({year:years[i].value, organizationId:organizationId.current, demand, employees, territorializations, depositType, periods: epidemiologicalWeek[0].weeks.map((ew) => {return {finalDate:moment(ew.endDate, "DD/MM/YYYY").format("YYYY-MM-DD"), beginDate:moment(ew.beginDate, "DD/MM/YYYY").format("YYYY-MM-DD")}})});  
            }
        }else if(weeks.length === 0){
            months.forEach(month => {
                labels.push(month.label);
            })

            for(let i = 0; i < years.length; i++){
                const monthPeriods = [];

                for(let j = 0; j < months.length; j++){
                const beginDate = moment(new Date(years[i].value, months[j].value, 1)).format("YYYY-MM-DD");
                const finalDate = moment(beginDate).add(1, 'month').subtract(1, 'day').format("YYYY-MM-DD");
                monthPeriods.push({beginDate, finalDate});
                }

                periods.push({year:years[i].value, organizationId:organizationId.current, periods:monthPeriods, demand, employees, territorializations, team, depositType});
            }
        }else{
            weeks.forEach(weeks => {
                labels.push(weeks.label);
            })

            for(let i = 0; i < years.length; i++){
                const weeksPeriods = [];
                const epiWeeks = getWeeks().filter(ew => ew.year === years[i].value);

                for(let j = 0; j < weeks.length; j++){
                    if(epiWeeks[0].weeks[weeks[j].value] !== undefined){
                        const beginDate = moment(epiWeeks[0].weeks[weeks[j].value].beginDate, "DD/MM/YYYY").format("YYYY-MM-DD");
                        const finalDate = moment(epiWeeks[0].weeks[weeks[j].value].endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
                        weeksPeriods.push({beginDate, finalDate});
                    }
                }
                periods.push({year:years[i].value, organizationId:organizationId.current, periods:weeksPeriods, demand, employees, territorializations, team, depositType});
            }
        }

        setChartPeriods(periods);
        setChartLabels(labels);
    }  
    return (
        <>
            {/* Page content */}
        
            <ToastContainer/>
            <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8 mb-5">
                <Row className="mb-4">
                  <Col className="col-2">
                      <button className="btn btn-primary" onClick={() => {setFilterVisibility(!isFilterVisible)}}>{isFilterVisible ? "Esconder Filtro" : "Mostrar Filtro"}</button>
                  </Col>
                  <Col className="col-2"></Col>
                  <Col className="col-6"></Col>
                  {isFilterVisible ? 
                      <Col className="linha form col-2">
                      <button  className="btn btn-primary" onClick={applyFilter}>Filtrar</button>
                      </Col>
                  :null}
                </Row>
                {isFilterVisible ? 
                <>
                  <VisitOperacionalFilterPresentational
                    handleChangeFilter = {handleChangeFilter}
                    employeeId = {employee}
                    employeeIdList={employeeList}
                    demandId = {demand}
                    demandIdList = {demandList}
                    teamId = {team}
                    teamIdList = {teamList}
                  />
                  <Row className="mb-4">
                  <MainPeriodComponent 
                      months={months}
                      years={year}
                      weeks={weeks}
                      weekValue1={weekValue1}
                      weekValue2={weekValue2}
                      showWeekToWeek
                      changePeriod = {changePeriod}
                  />
                  <DepositoFilterPresentational 
                      startDate={startDate} 
                      endDate={endDate} 
                      handleChangeFilter={handleChangeFilter}
                      applyFilter={applyFilter}
                  />
                  
                    <GeographicFilterPresentational
                      Territorializations={territorializationList}
                      handleChangeFilter={handleChangeFilter}
                      SelectedTerritorializations={selectedTerritorializations}
                    />
                  </Row>
                </>
                :null}
            </Container>
            <Container fluid className="mt--7">
                <Row className="mb-5" >
                  <Col xl="6" className="mb-5 mb-xl-0">
                    {isIIPChartLoading ? 
                        <div className="mb-4" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%"}}>
                          <LoadingSpin primaryColor="#000" size={28}/>
                        </div>
                    : 
                        <LineIIPChartContainer
                          data={IIP}
                          labels={chartLabels}
                        />
                    }
                  </Col>
                  <Col xl="6" className="mb-5 mb-xl-0">
                    {isIIPMapLoading ? 
                      <div className="mb-4" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%"}}>
                        <LoadingSpin primaryColor="#000" size={28}/>
                      </div>
                    : 
                      <IIPMapContainer
                        DepositMapInfo={DepositMapInfo}
                        territorializationList = {territorializationList}
                      />
                    }
                  </Col>
                </Row>
                <Row className="mb-5" >
                    <Col xl="6" className="mb-5 mb-xl-0">
                    {isIBChartLoading ? 
                        <div className="mb-4" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%"}}>
                        <LoadingSpin primaryColor="#000" size={28}/>
                        </div>
                    : 
                        <LineIBChartContainer
                          data={IB}
                          labels={chartLabels}
                        />
                    }
                    </Col>
                    <Col xl="6" className="mb-5 mb-xl-0">
                      {isIIPMapLoading ? 
                        <div className="mb-4" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%"}}>
                          <LoadingSpin primaryColor="#000" size={28}/>
                        </div>
                      : 
                        <IBMapContainer
                          DepositMapInfo={DepositMapInfo}
                          territorializationList = {territorializationList}
                        />
                      }
                    </Col>
                </Row>
                <Row className="mb-5" >
                    <Col xl="6" className="mb-5 mb-xl-0">
                    {isITRChartLoading ? 
                        <div className="mb-4" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%"}}>
                        <LoadingSpin primaryColor="#000" size={28}/>
                        </div>
                    : 
                        <PieITRChartContainer
                          data={ITR}
                          labels={chartLabels}
                        />
                    }
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DashboardDepositos;
