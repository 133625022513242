import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";
import "./_Options.css";

const FormTerritorializationPgs = React.FC = ({setPageApp}) => {
    const [extraField, setExtraField] = useState(false);
    const [qttExtraField, setQttExtraField] = useState(0);
    const [extraFieldValues, setExtraFieldValues] = useState({
        1: "",
        2: "",
        3: "",
    });

    const handleChangeExtraFieldName = (numberExtraField, value) => {
        var extraFieldValuesAux = {...extraFieldValues};

        extraFieldValuesAux[numberExtraField] = value;


        setExtraFieldValues(extraFieldValuesAux);
    }

    const renderExtraFields = () => {
        const extraFields = [];
        for (let i = 1; i <= qttExtraField; i++) {
          extraFields.push(
            <>
                <p className="h4 text-white">Nome Campo Extra {i}</p>
                <input className="form-control mb-20-px" value={extraFieldValues[i]} onChange={(e) => {setPageApp("ft"); handleChangeExtraFieldName(i, e.target.value)}} />
            </>
          );
        }
        return extraFields;
    };

    return (
        <div>
            <p className="h4 text-white">Campos extras</p>
            <select className="form-control" onChange={e => {setPageApp("ft");setExtraField(e.target.value == "true")}}>
                <option value={false}>Não</option>
                <option value={true}>Sim</option>
            </select>
            {
                extraField
                ?
                <>
                    <p className="h4 text-white">Quantidade campos extras</p>
                    <select className="form-control" onChange={e => {
                        setPageApp("ft")
                        setQttExtraField(parseInt(e.target.value)); 
                        if(e.target.value == 1) {
                            extraFieldValues[2] = "";
                            extraFieldValues[3] = "";

                            setExtraFieldValues(extraFieldValues);
                        } else if(e.target.value == 2) {
                            extraFieldValues[3] = "";

                            setExtraFieldValues(extraFieldValues);
                        }
                    }}>
                        {qttExtraField == 0 ? <option value={0}>Selecione</option> : <></>}
                        <option value={1}>1</option> 
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                    </select>
                    {
                        qttExtraField && qttExtraField > 0
                        ?
                        <>
                            {renderExtraFields()}
                        </>
                        :
                        <></>
                    }
                </>
                :
                <></>
            }
            <p className="h4 text-white">Campos obrigatórios</p>
            <Select
                className="mb-20-px"
                isMulti                                                                                                  
                options={[{label: "Cep", value: "cep"}, {label: "Logradouro", value: "logradouro"}, {label: "Número imovel", value: "numero_imovel"}, {label: "Complemento", value: "complemento"}, {label: "Sequencial", value: "sequencial"}, {label: "Tipo de Imóvel", value: "tipo_de_imovel"}]}                                                    
                placeholder="Selecione"
            />
        </div>
    );
}

export default FormTerritorializationPgs;