import React from "react";
import "./../../assets/css/navbar.css";
import {downloadCsv} from "../../services/api/Covid";
import {getSymptomaticsByDay} from "../../services/api/Covid";
import {getUserData} from "../../services/utils/auth";

// reactstrap components
import {
    Container,
} from "reactstrap";
import {fetchResidents} from "../../services/api/Covid";

class FilterReportCovid extends React.Component {

    state = {
        filterName:"",
        filter:{
            onlySymptomatics:false,
            typeReport:"",
            startDate:null,
            endDate:null,
            typeForm:"",
            organizationId:getUserData("organizationId"),
            TeamId:"00000000-0000-0000-0000-000000000000",
            CollectorId:"00000000-0000-0000-0000-000000000000"
          }
    }
    
    downloadCsvData = () =>{
        var requestData = downloadCsv(this.state.filter);
        const FileDownload = require("js-file-download");
        requestData.then(response =>{
            FileDownload(response.data,"results.csv");
        })
    }
    handleChange = e => {
        let {name,value} = e.target;
        this.setState({
            [name]: value
        });
      };
    applyFilter = () => {
        const ResidentRequest = fetchResidents(this.state.filter);
        ResidentRequest.then(response => {
            if(response) {
                return response.data
            }
            throw new Error("Erro ao contatar servidor");
        }).then(data => {
            this.props.parentCallback(data);
        });
        if(this.props.parentChartCallback != null){
            const getSymptomaticsByDayRequest = getSymptomaticsByDay(this.state.filter);
            getSymptomaticsByDayRequest.then(response => {
                if(response) {
                    return response.data
                }
                throw new Error("Erro ao contatar servidor");
              }).then(data => {
                this.props.parentChartCallback(data.symptomaticsByDayData);
              });
        }
        
    }
    render() {
        return (
            <div className="header bg-gradient-info pt-5" >
                <Container fluid>
                    <div className="header-body">

                        return(
                           
                           
                           <div className="row">
                                <div className="col-4">
                                    <span className="h5 text-white">DATA DE INÍCIO</span>                                
                                    <input type="date" className="form-control"onChange ={e=>this.state.filter.startDate = e.target.value} name="" id="" placeholder="Período de início" />                                
                                </div>
                                <div className="col-4">
                                    <span className="h5 text-white">DATA DE TÉRMINO</span> 
                                    <input type="date" className="form-control" onChange ={e=>this.state.filter.endDate = e.target.value}name="" id="" placeholder="Período de término" />
                                </div>
                                <div className="col-4">
                                    <span className="h5 text-white">Somente Sintomáticos?</span> 
                                    <select className="form-control" onChange ={e=>this.state.filter.onlySymptomatics = e.target.value}name="" id="">
                                        <option value={true}>Sim</option>
                                        <option value={false} selected>Não</option>
                                    </select>
                                </div>
                                {/*<div className="col-2">
                                    <span className="h5 text-white">CAMADA</span>                                
                                    <select className="form-control">
                                        <option>Nível</option>
                                        <option>Imóvel</option>
                                        <option>Lote</option>
                                        <option>Quadra</option>
                                        <option>Micro Área</option>
                                        <option>Bairro</option>
                                        <option>Setor Censitario</option>
                                        <option>Área</option>
                                        <option>Território</option>
                                        <option>Setor Censitario</option>
                                        <option>Estrato</option>
                                        <option>Distrito sanitário</option>
                                        <option>Município</option>
                                        <option>Regional saúde</option>
                                        <option>Macro regional saúde</option>
                                        <option>Estado</option>
                                        <option>País</option>
                                        <option>Continente</option>
                                        <option>Continente</option>
                                        <option>Hemísferio</option>
                                        <option>Mundo</option> 
                                    </select>                           
                                </div>
                                <div className="col-2">
                                    <span className="h5 text-white">Localização</span>                                
                                    <input type="text" className="form-control" name="" id="" placeholder="Localização" />                                
                        </div>*/}
                            </div>
                             {/* <div className="row">
                                    <div className="col-4">
                                        <span className="h5 text-white">Preenchido por</span> 
                                        <select className="form-control" onChange ={e=>this.state.filter.CollectorId = e.target.value}name="" id="">
                                            <option selected value={"00000000-0000-0000-0000-000000000000"}>{"Todos"}</option>
                                            {this.props.CollectorList.map((collector) => {
                                                return (
                                                    <option value={collector.id}>{collector.name}</option>
                                                )
                                            })}      
                                        </select>
                                </div>
                            </div>  */}
                        )              

                        <div className="row">
                            <div className="col-4">
                                
                                <button  onClick={()=>this.applyFilter()} className="btn btn-primary">Pesquisar</button>
                            </div>
                            {/*<div className="col-4">
                                <button className="btfloat-right btn btn-defaultn">Baixar Pdf</button>
                    </div>  */}          
                            <div className="col-4">
                                <button onClick={()=>this.downloadCsvData()} className="btfloat-right btn btn-defaultn">Baixar Csv</button>
                            </div>   
                           <div className="col-4">
                                {/* <Link to="/admin/CovidChart">
                                    <button
                                        className="btfloat-right btn btn-defaultn"
                                        type="button">
                                        Gráficos
                                    </button>
                </Link>   */}
                            </div>               
                        </div>
                    </div>
                </Container>
            </div>
        );

    }

}

export default FilterReportCovid;
