import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// reactstrap components
import DemandInfo from "./DemandInfo";
import DemandVisits from "./DemandVisits";

import "../../../assets/css/style.css";

class ShowDemand extends React.Component {
  

  render() {
    var Demand = typeof this.props.Demand != "undefined" ? this.props.Demand : "Vazio";
    return (
      <>
        <Tabs>
            <TabList>
            <Tab>Demanda</Tab>
            <Tab>Visitas</Tab>
            </TabList>

            <TabPanel>
                <DemandInfo Demand={Demand}/>
            </TabPanel>
            <TabPanel>
                {
                (typeof Demand != "undefined") ?
                <DemandVisits DemandId={Demand.id}/> :
                <div>                 
                
                <span>Erro de demanda</span>
                </div>                                    
            }
                
            </TabPanel>
        </Tabs>
      </>
    );
  }
}

export default ShowDemand;