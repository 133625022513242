import React from "react";
import { Col, Row } from "reactstrap";
import CardPresentational from "../../../../components/Presentational/CardPresentational";
import ReactTooltip from "react-tooltip";
import CardLoadingSkeleton from "../../../../components/ui/Loading/CardLoadingSkeleton";
import InfoIcon from "../../../../assets/img/icons/common/circle-info-solid.svg";

const ProductivityCardsWrapper = ({
  fieldProductivityData,
  typingProductivityData,
  visitsByStatusData,
}) => {
  const [visitsByStatus, setVisitsByStatus] = React.useState(null);

  React.useEffect(() => {
    if (!visitsByStatusData || visitsByStatusData.lenght === 0) return;

    const result = getVisitsByStatusObject();

    visitsByStatusData.forEach(({ situation, quantity }) => {
      if (result[situation]) result[situation].quantity = quantity;
    });

    setVisitsByStatus(result);
  }, [visitsByStatusData]);

  function getVisitsByStatusObject() {
    return {
      Aberto: {
        quantity: 0,
      },
      Fechado: {
        quantity: 0,
      },
      "Endereço Não Encontrado": {
        quantity: 0,
      },
      Recusa: {
        quantity: 0,
      },
      Orientação: {
        quantity: 0,
      },
    };
  }

  const styles = {
    row: {
      marginLeft: 0,
      marginRight: 0,
    },
    col: {
      paddingLeft: 0,
      paddingRight: "0.2vw",
      width: "100%",
    },
  };

  return (
    <>
      <Row className="mb-2" style={styles.row}>
        <Col xl={2} style={styles.col}>
          <CardPresentational
            number={
              fieldProductivityData
                ? fieldProductivityData.totalInstalledTrapsOnField
                : 0
            }
            text={
              <span>
                Instalações de armadilhas <br />
                no campo{" "}
                <img src={InfoIcon} width={16} className="fa fa-info" data-tip data-for="fieldInstalls"></img>
                <ReactTooltip effect="solid" type="info" id="fieldInstalls">
                  Representa o número de históricos de instalação de armadilhas
                  que NÃO foram gerados por digitação nos formulários WEB.
                </ReactTooltip>
              </span>
            }
          />
        </Col>
        <Col xl={2} style={styles.col}>
          <CardPresentational
            number={
              fieldProductivityData
                ? fieldProductivityData.totalMaintenancesTrapsOnField
                : 0
            }
            text={
              <span>
                Manutenções de armadilhas <br />
                no campo{" "}
                <img src={InfoIcon} width={16}
                  className="fa fa-info"
                  data-tip
                  data-for="fieldMaintenances"
                ></img>
                <ReactTooltip effect="solid" type="info" id="fieldMaintenances">
                  Representa o número de históricos de manutenções de armadilhas
                  que NÃO foram gerados por digitação nos formulários WEB.
                </ReactTooltip>
              </span>
            }
          />
        </Col>
        <Col xl={2} style={styles.col}>
          <CardPresentational
            number={
              fieldProductivityData
                ? fieldProductivityData.totalUninstalledTrapsOnField
                : 0
            }
            text={
              <span>
                Desinstalações de armadilhas <br />
                no campo{" "}
                <img src={InfoIcon} width={16}
                  className="fa fa-info"
                  data-tip
                  data-for="fieldUninstalls"
                ></img>
                <ReactTooltip effect="solid" type="info" id="fieldUninstalls">
                  Representa o número de históricos de desinstalações de
                  armadilhas que NÃO foram gerados por digitação nos formulários
                  WEB.
                </ReactTooltip>
              </span>
            }
          />
        </Col>
        <Col xl={2} style={styles.col}>
          <CardPresentational
            number={
              typingProductivityData ? typingProductivityData.totalInstalls : 0
            }
            text={
              <span>
                Instalações de armadilhas <br />
                por digitação{" "}
                <img src={InfoIcon} width={16} className="fa fa-info" data-tip data-for="webInstalls"></img>
                <ReactTooltip effect="solid" type="info" id="webInstalls">
                  Representa o número de históricos de INSTALAÇÕES de armadilhas
                  que foram digitadas, no período do filtro de data, pelos
                  formulários WEB. Não necessariamente significa que a
                  manutenção ocorreu no período, pois pode referir-se a um
                  lançamento retroativo.
                </ReactTooltip>
              </span>
            }
          />
        </Col>
        <Col xl={2} style={styles.col}>
          <CardPresentational
            number={
              typingProductivityData
                ? typingProductivityData.totalMaintenances
                : 0
            }
            text={
              <span>
                Manutenções de armadilhas <br />
                por digitação{" "}
                <img src={InfoIcon} width={16}
                  className="fa fa-info"
                  data-tip
                  data-for="webMaintenances"
                ></img>
                <ReactTooltip effect="solid" type="info" id="webMaintenances">
                  Representa o número de históricos de MANUTENÇÕES de armadilhas
                  que foram digitadas, no período do filtro de data, pelos
                  formulários WEB. Não necessariamente significa que a
                  manutenção ocorreu no período, pois pode referir-se a um
                  lançamento retroativo.
                </ReactTooltip>
              </span>
            }
          />
        </Col>
        <Col xl={2} style={styles.col}>
          <CardPresentational
            number={
              typingProductivityData
                ? typingProductivityData.totalUninstalls
                : 0
            }
            text={
              <span>
                Desinstalações de armadilhas <br />
                por digitação{" "}
                <img src={InfoIcon} width={16} className="fa fa-info" data-tip data-for="webUninstalls"></img>
                <ReactTooltip effect="solid" type="info" id="webUninstalls">
                  Representa o número de históricos de DESINTALAÇÕES de
                  armadilhas que foram digitadas, no período do filtro de data,
                  pelos formulários WEB. Não necessariamente significa que a
                  desinstalação ocorreu no período, pois pode referir-se a um
                  lançamento retroativo.
                </ReactTooltip>
              </span>
            }
          />
        </Col>
      </Row>
      <Row className="mb-2" style={styles.row}>
        <Col xl={3} style={styles.col}>
          <CardPresentational
            number={
              visitsByStatus && visitsByStatus.Aberto
                ? visitsByStatus.Aberto.quantity
                : 0
            }
            text={
              <span>
                Total de visitas na situação
                <br /> "Aberto"
              </span>
            }
          />
        </Col>
        <Col xl={3} style={styles.col}>
          <CardPresentational
            number={
              visitsByStatus && visitsByStatus.Fechado
                ? visitsByStatus.Fechado.quantity
                : 0
            }
            text={
              <span>
                Total de visitas na situação
                <br /> "Fechado"
              </span>
            }
          />
        </Col>
        <Col xl={3} style={styles.col}>
          <CardPresentational
            number={visitsByStatus && visitsByStatus.Recusa ? visitsByStatus.Recusa.quantity : 0}
            text={
              <span>
                Total de visitas na situação
                <br /> "Recusa"
              </span>
            }
          />
        </Col>
        <Col xl={3} style={styles.col}>
          <CardPresentational
            number={
              visitsByStatus && visitsByStatus["Endereço Não Encontrado"]
                ? visitsByStatus["Endereço Não Encontrado"].quantity
                : 0
            }
            text={
              <span>
                Total de visitas na situação
                <br /> "Endereço não encontrado"
              </span>
            }
          />
        </Col>
      </Row>
    </>
  );
};

const FieldAndTypingCardsLoadingWrapper = () => {
  const styles = {
    row: {
      marginLeft: 0,
      marginRight: 0,
    },
    col: {
      paddingLeft: 0,
      paddingRight: "0.2vw",
      width: "100%",
    },
  };

  return (
    <>
      <Col xl={2} style={styles.col}>
        <CardLoadingSkeleton
          topBarWidth="50px"
          middleBarWidth="150px"
          bottomBarWidth="100px"
        />
      </Col>
      <Col xl={2} style={styles.col}>
        <CardLoadingSkeleton
          topBarWidth="50px"
          middleBarWidth="150px"
          bottomBarWidth="100px"
        />
      </Col>
      <Col xl={2} style={styles.col}>
        <CardLoadingSkeleton
          topBarWidth="50px"
          middleBarWidth="150px"
          bottomBarWidth="100px"
        />
      </Col>
    </>
  );
};

const VisitsSituationCardsLoadingWrapper = () => {
  const styles = {
    row: {
      marginLeft: 0,
      marginRight: 0,
    },
    col: {
      paddingLeft: 0,
      paddingRight: "0.2vw",
      width: "100%",
    },
  };

  return (
    <>
      <Col xl={3} style={styles.col}>
        <CardLoadingSkeleton
          topBarWidth="50px"
          middleBarWidth="180px"
          bottomBarWidth="120px"
        />
      </Col>
      <Col xl={3} style={styles.col}>
        <CardLoadingSkeleton
          topBarWidth="50px"
          middleBarWidth="180px"
          bottomBarWidth="120px"
        />
      </Col>
      <Col xl={3} style={styles.col}>
        <CardLoadingSkeleton
          topBarWidth="50px"
          middleBarWidth="180px"
          bottomBarWidth="120px"
        />
      </Col>
      <Col xl={3} style={styles.col}>
        <CardLoadingSkeleton
          topBarWidth="50px"
          middleBarWidth="180px"
          bottomBarWidth="120px"
        />
      </Col>
    </>
  );
};

export default ProductivityCardsWrapper;
