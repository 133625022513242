import React, { Component } from 'react';
import {Select} from "../Wrappers/SelectAll";

class MonthsInput extends Component {
    state = {
        monthsOptions: [
            {label:"Janeiro", value: "0"},
            {label:"Fevereiro", value: "1"},
            {label:"Março", value: "2"},
            {label:"Abril", value: "3"},
            {label:"Maio", value: "4"},
            {label:"Junho", value: "5"},
            {label:"Julho", value: "6"},
            {label:"Agosto", value: "7"},
            {label:"Setembro", value: "8"},
            {label:"Outubro", value: "9"},
            {label:"Novembro", value: "10"},
            {label:"Dezembro", value: "11"}
        ],
    }

    handleChange = (e) => {
        if (this.props.isSinan == true)
            this.props.changePeriod(e,"MonthSINAN");
        else if (this.props.isEntomologico == true)
            this.props.changePeriod(e,"MonthENTOMOLOGICO");
        else if (this.props.isOperacional == true)
            this.props.changePeriod(e,"MonthOPERACIONAL");
        else
            this.props.changePeriod(e,"Month");

    }

    render() {

        return (
            <div>
                <span className="h4 text-white">Mês</span>
                <Select
                    styles={{
                        control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                        option: styles =>({...styles, fontSize:"0.875rem"}),
                        valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                        singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                        placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                        menuPortal: styles => ({...styles, zIndex:9999})
                    }}
                    value={this.props.months}
                    placeholder={"Selecione"}
                    isClearable={true}
                    isMulti={true}
                    blurInputOnSelect={false}
                    closeMenuOnSelect={false}
                    allowSelectAll={true}
                    allOption={{label: "Todos", value: "*"}}
                    isDisabled={this.props.isDisabled}
                    onChange={this.handleChange}
                    options={this.state.monthsOptions}
                    menuPortalTarget={document.body}
                />              
            </div>
        );
    }
}

export default MonthsInput;