import React from "react";
import { Col, Row } from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";

const PlanningFiltersWrapper = ({
  filtersValues,
  employees,
  teams,
  typeGoalsList,
  handleFiltersChange,
  fetchAllComponentsData,
}) => {
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
  };

  return (
    <>
      <Row className="mb-2">
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Equipe</span>
          <Select
            styles={selectStyles}
            placeholder={"Selecione"}
            isClearable={true}
            options={teams}
            value={filtersValues.team}
            onChange={(e) =>
              handleFiltersChange(
                "team",
                !e || e.length === 0
                  ? {
                      label: "Todos",
                      value: "00000000-0000-0000-0000-000000000000",
                    }
                  : e
              )
            }
          />
        </Col>
        <Col className="col-md-4 visit-responsive-filter me-5">
          <span className="h4 text-white">Data (Período)</span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
            }}
          >
            <div style={{ width: "45%" }}>
              <input
                type="date"
                name="beginDate"
                id="beginDate"
                className="form-control"
                value={filtersValues.beginDate}
                onChange={({ target }) =>
                  handleFiltersChange("beginDate", target.value)
                }
              />
            </div>
            <div
              className="h4 text-white"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span>Até</span>
            </div>
            <div style={{ width: "45%" }}>
              <input
                type="date"
                name="endDate"
                id="endDate"
                className="form-control"
                value={filtersValues.finalDate}
                onChange={({ target }) =>
                  handleFiltersChange("finalDate", target.value)
                }
              />
            </div>
          </div>
        </Col>
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Funcionários</span>
          <Select
            styles={selectStyles}
            isMulti={true}
            placeholder={"Selecione"}
            isClearable={true}
            options={employees}
            value={filtersValues.employee}
            onChange={(e) =>
              handleFiltersChange("employees", !e || e.length === 0 ? [] : e)
            }
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col className="col-md-4 visit-responsive-filter">
         {/*  <span className="h4 text-white">Tipo</span>
          <Select
            styles={selectStyles}
            isMulti={false}
            placeholder={"Selecione"}
            isClearable={false}
            options={typeGoalsList}
            value={filtersValues.type}
            onChange={(e) =>
              handleFiltersChange(
                "type",
                !e || e.length === 0
                  ? {
                      label: "Selecione uma opção",
                      value: "00000000-0000-0000-0000-000000000000",
                    }
                  : e
              )
            }
          /> */}
        </Col>
        <Col className="col-md-4 visit-responsive-filter me-5"></Col>
        <Col className="col-md-4 visit-responsive-filter"></Col>
      </Row>
      <Row className="mt-5">
        <Col className="col-md-4 mx-auto">
          <button
            className="btn btn-primary"
            onClick={() => fetchAllComponentsData()}
          >
            Filtrar
          </button>
        </Col>
      </Row>
    </>
  );
};

export default PlanningFiltersWrapper;
