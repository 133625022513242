import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';
import 'react-select-2/dist/css/react-select-2.css';
import {
    Container,
} from "reactstrap";
import "../../assets/css/style.css";
import { postDemand, putDemand } from "../../services/api/Demand";
import { toast, ToastContainer } from "react-toastify";
import { fetchOrigins } from "../../services/api/Origins";
import { fetchPrograms } from "../../services/api/Program";
import { getTeam } from "../../services/api/Team";
import { fetchTerritorializations, getAllTypeTerritorializations, getTypeLayer } from "../../services/api/territorialization";
import { fetchProblems } from "../../services/api/Problems";
import { findCep as ApiCep } from "../../services/api/cep";
import { fetchEmployees } from "../../services/api/Employee";
import TerritorializationModalComponent from "../../views/pages/Territorialization/TerritorializationModalComponent";
import { brazilianStates } from "../../services/utils/brazilianStates";
import Geocode from "react-geocode";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import "./FormDemanda.css";

const PROBLEM_STATE_DEFAULT = {
    id: "00000000-0000-0000-0000-000000000000",
    name: "",
    description: "",
    priority: "",
    programId: "",
    observation: "",
    serviceId:"00000000-0000-0000-0000-000000000000",
    serviceSpecification: "",
    servicesFiltered:[],
}

const PROGRAM_STATE_DEFAULT = {
    programId: "00000000-0000-0000-0000-000000000000",
    problems: [{...PROBLEM_STATE_DEFAULT}],
    TypeProblems:[],
}

const PERSON_STATE_DEFAULT = {
    Name: "",
    Phone: "",
    TypeDocument:"",
    Document: "",
    Nacionality:"",
    OtherNacionality:"",
    Naturality:"",
}

const FORM_DEMANDA_DEFAULT = {
    demandTempId: null,
    programs: [JSON.parse(JSON.stringify(PROGRAM_STATE_DEFAULT))],
    responsibleId: "00000000-0000-0000-0000-000000000000",
    DemandProblemEmployeesId:"",
    beginDate:"",
    endDate:"",
    teamId:[],
    Type: "externa",    
    Description: "",
    SampleDescription:"",
    OrderNumber: "",
    DemandName: "",
    SolicitationType: "",
    territorializationId: [],
    Origin: "",
    Person: {...PERSON_STATE_DEFAULT},
    Address: {
        Route: "",
        StreetNumber: "",
        SubLocality: "",
        SubPremise: "",
        Area: "",
        Block: "",
        postalCode: "",
        ReferencePoint: "",
    },
    
    isDisableOrganization:false
}

const TERRITORIALIZATION_DATA = {
    territoText: '',
    TerritorializationGeometry:"Point",
    previousTerritorialization: null,
    selectedTerritorializations:[],
    territorializations: [],
    modal:false,
    map: {
        displayMapExt:false,
        displayMapInt:false,
        latLong: "",
    },
}

const documentTypes = [
    {tipo:"CPF"},
    {tipo:"RG"},
    {tipo:"Cartão SUS"},
    {tipo:"CadÚnico"},
    {tipo:"Passaporte"},  
    {tipo:"Não Possui"}   
]

const states = brazilianStates()

const priorityOptions = [
    {label: "Imediato", value:"1"},
    {label: "Até 24 horas", value: "2"},
    {label: "Até 48 horas", value:"3"},
    {label: "Até 72 horas", value:"4"},
    {label: "Acima de 72 horas", value:"5"}
]

const FormDemanda = ({demand}) => {
    const [formDemanda, setFormDemanda] = useState({...FORM_DEMANDA_DEFAULT})
    const [territorializationData, setTerritorializationData] = useState({...TERRITORIALIZATION_DATA})
    const [employees, setEmployees] = useState([])
    const [programsOpt, setProgramsOpt] = useState([])
    const [typeProblems, setTypeProblems] = useState([])
    const [teams, setTeams] = useState([])
    const [origins, setOrigins] = useState([])

    useEffect(() => {
        //select de origens        
        const requestOrigins = fetchOrigins();
        let origins = [];
        requestOrigins.then(({data}) => {
            data.map((origin) => origin.id !== '8a9e5785-037c-4980-b465-4f2e844a0070' && origins.push(origin))
            setOrigins(origins);
        });

        //select de programas
        const requestPrograms = fetchPrograms();
        let programsOpt = [];
        requestPrograms.then(({data}) => {
            data.map(({name, id}) => programsOpt.push({label: name, value: id }))
            setProgramsOpt(programsOpt);
        })

        //select de equipe
        const requestTeams = getTeam();
        requestTeams.then(({data}) => setTeams(data.map(({id, name}) => ({label: name, value: id}))))

        const requestEmployees = fetchEmployees();
        requestEmployees.then(({data}) => setEmployees(data.map(({id, name}) => ({value: id, label: name}))))

        //Checkbox de problemas
        const requestTypeProblems = fetchProblems();
        requestTypeProblems.then(({data}) => {
            const typeProblemsObject = data.map(({name, id, programId, services}) => ({
                label:name,
                value:id,
                programId: programId,
                services: services,
            }))

            setTypeProblems(typeProblemsObject)
            
            if(demand){
                // converte o objeto de demanda que vem na edição para o objeto do form de demanda
                setFormDemanda(convertDemandObject(demand, typeProblemsObject)) 
                setTerritorializationData({
                    ...territorializationData,
                    selectedTerritorializations: demand.territorializations.map(({id, name}) =>  ({id, name})),
                    territoText: demand.territorializations.map(({name}) => name).join(", "),

                })
            }
        })
    }, [])

    function convertDemandObject(demand, typeProblemsObject){
        return {
            ...formDemanda,
            beginDate: demand.startDate.substring(0, 10),
            DemandName: demand.name,
            Description: demand.description,
            DemandProblemEmployeesId: demand.demandProblemEmployees.map(({employeeId}) => employeeId), 
            endDate: demand.endDate.substring(0, 10),
            Origin: demand.originId,
            OrderNumber: demand.serviceOrder,
            responsibleId: demand.responsibleId,
            SampleDescription: demand.samplesObservations,
            territorializationId: demand.territorializations.map(({id}) => id),
            Type: demand.type.toLowerCase(),
            programs: JSON.parse(JSON.stringify(mountPrograms(demand.problems, typeProblemsObject))),
            Address: {
                postalCode: demand.postalCode || formDemanda.Address.postalCode,
                Route: demand.route || formDemanda.Address.Route,
                SubPremise: demand.subpremisse || formDemanda.Address.SubPremise,
                SubLocality: demand.subLocality || formDemanda.Address.SubLocality,
                StreetNumber: demand.streetNumber || formDemanda.Address.ReferencePoint,
                ReferencePoint: demand.referencePoint || formDemanda.Address.ReferencePoint,
                Area: demand.area || formDemanda.Address.Area,
                Block: demand.block || formDemanda.Address.Block,
            },
            Person: {
                Name: demand.personName,
                Phone: demand.personPhone,
                TypeDocument: demand.personTypeDocument,
                Document: demand.personDocument,
                Nacionality: demand.personNacionality,
                OtherNacionality: demand.personOtherNacionality,
                Naturality: demand.personNaturality,
            },
            SolicitationType: demand.solicitationType,
            teamId: demand.teams.map(({id}) => id) || formDemanda.teamId
        }
    }

    function mountPrograms(problems, typeProblemsObject){
        let programs = [];
        let programsTemp = problems.reduce(function (acc, obj) {
            let key = obj["programId"];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            
            return acc;
        }, Object.create(null));

        for(let programId in programsTemp){
            let program = {
                programId,
                problems:[],
                TypeProblems: typeProblemsObject.filter(po => po.programId == programId),
            };
            
            for(const problem of programsTemp[programId]){
                problem.servicesFiltered = problem.services.map(service => ({
                    label: service.name,
                    value: service.id,
                }))

                problem.observation = problem.observation;
                problem.serviceId = problem.service.id;
                program.problems.push(problem)
            }

            programs.push(program);
        }
        
        return programs
    }

    function addProblema(programaId){
        let programs = [...formDemanda.programs]
        programs[programaId].problems.push({...PROBLEM_STATE_DEFAULT})
        setFormDemanda({...formDemanda, programs})
    }

    function changeProgram(index, e){
        let ProgramId = e.value;
        let programs = JSON.parse(JSON.stringify(formDemanda.programs));

        programs[index].programId = ProgramId;
        programs[index].TypeProblems = typeProblems.filter(problem => problem.programId == ProgramId)

        setFormDemanda({...formDemanda, programs})
    }

    function handleChangeType({target: {value}}){
        setFormDemanda({
            ...formDemanda, 
            Type: value, 
            Origin: value === 'interna' ? '8a9e5785-037c-4980-b465-4f2e844a0070' : formDemanda.Origin,
            Person: value === 'interna' ? {...PERSON_STATE_DEFAULT} : {...formDemanda.Person},
            DemandName: '',
            Description: '',
            Address: {...formDemanda.Address, Area: '', Block: ''},
        })
    }

    function removerProblema(indexProgram) {
        let programs = [...formDemanda.programs];
        programs[indexProgram].problems.pop();
        setFormDemanda({...formDemanda, programs})
    }

    async function findCep(){
        try{
            const {logradouro, bairro} = await ApiCep(formDemanda.Address.postalCode);
            setFormDemanda({...formDemanda, Address:{...formDemanda.Address, Route: logradouro, SubLocality: bairro}})

        }catch(error){
        }
    }

    async function toggle(type, changeTerritorialization){
        if(!changeTerritorialization){
            setTerritorializationData({...territorializationData, modal: !territorializationData.modal})
            return;
        }

        const typeLayerRequest = type !== null ? (await getTypeLayer(type)).data :  (await getAllTypeTerritorializations()).data[0];
        setTerritorializationData({...territorializationData, TerritorializationGeometry: typeLayerRequest.typeGeometry})

        if (!territorializationData.territorializations.length > 0||territorializationData.previousTerritorialization !== typeLayerRequest.id) {

            const {data} = await fetchTerritorializations(typeLayerRequest.id);
            let territorializations = data.filter(t => t.status == "Ativa");

            setTerritorializationData({...territorializationData, territorializations, previousTerritorialization: typeLayerRequest.id});
        }
    }

    function checkOpt(selectedTerritorializations){
        const territoText = selectedTerritorializations.map(({name}) => name).join(", ")
        const territorializationId = selectedTerritorializations.map(({id}) => id)
        
        setTerritorializationData({...territorializationData, selectedTerritorializations, territoText})
        setFormDemanda({...formDemanda, territorializationId})
    }

    function handleChangeProblemsFields (programIndex, e, index, field = ''){
        let {programs} = {...formDemanda};

        if(e.target){
            programs[programIndex].problems[index][e.target.name] = e.target.value;
        }else{
            programs[programIndex].problems[index][field] = e.value;
        }

        if(field == "id"){
            programs[programIndex].problems[index].serviceId = "00000000-0000-0000-0000-000000000000";
            programs[programIndex].problems[index].priority = "";
            
            let problem = programs[programIndex].TypeProblems.filter(tp => tp.value == programs[programIndex].problems[index].id);
            programs[programIndex].problems[index].servicesFiltered = problem[0].services.map(({id, name}) => ({label: name, value: id}));
        }

        setFormDemanda({...formDemanda, programs})
    }

    function handleChangeDate(e){
        if(e.target.name == "endDate"){
            let beginDate = formDemanda.beginDate.split("-");
            let finishDate = e.target.value.split("-");

            if(beginDate > finishDate){
                toast.error("Erro: A data de término não pode ser anterior à data de início");
                
                return
            }
                
            setFormDemanda({...formDemanda, endDate: e.target.value})
            
            return
        }

        setFormDemanda({...formDemanda, beginDate: e.target.value})
    }

    async function renderMap(display){
        let mapState = {...territorializationData.map};
        //se não tiver latLong no state, buscar a localização 
        if(!mapState.latLong){
            const latLong = await getLatLon();
            if(!latLong){
                toast.error("Erro: Não foi possível localizar este endereço no mapa");

                return;
            }

            mapState.latLong = latLong;
            mapState[display] = true;
            setTerritorializationData({...territorializationData, map: mapState});

            return;
        }
        
        mapState[display] = true;
        setTerritorializationData({...territorializationData, map: mapState});
    }

    async function getLatLon() {
        Geocode.setApiKey("AIzaSyCdkHjfiiztTeKSSuvfauQERlAJGFONeKo");
        Geocode.setLanguage("pt-BR");
        Geocode.setRegion("br");

        return (
            await Geocode.fromAddress(formDemanda.Address.Route + ", " + formDemanda.Address.StreetNumber + " - " + formDemanda.Address.SubLocality + ", " + formDemanda.Address.postalCode).then(
                response => {
                    return response.results[0].geometry.location;

                },
                error => {
                    return false;
                }
            )
        )
    }

    function saveDemand(){
        var errors = false;

        if (formDemanda.demandTempId) {
            toast.error("Erro: Essa demanda já foi cadastrada");
            errors = true;
        }

        if (!formDemanda.Origin) {
            toast.error("Erro: Origem da demanda é obrigatória");
            errors = true;
        }

        if (!formDemanda.territorializationId) {
            toast.error("Erro: É necessário selecionar uma territorialização");
            errors = true;
        }

        formDemanda.programs.forEach(program => {
            if(program.programId === "00000000-0000-0000-0000-000000000000"){
                toast.error("Erro: É necessário selecionar um programa para todas as adicionadas");
                errors = true;
                return;
            }else{
                program.problems.forEach(problem => {
                    if(problem.id === "00000000-0000-0000-0000-000000000000"){
                        toast.error("Erro: É necessário selecionar uma necessidade para todos os adicionados");
                        errors = true;
                        return;
                    }

                    if(problem.serviceId === "00000000-0000-0000-0000-000000000000"){
                        toast.error("Erro: É necessário selecionar um serviço para todas as necessidades adicionados");
                        errors = true;
                        return;
                    }
                })

                if(errors){
                    return;
                }
            }
        });
        
        if (!errors) {
            const requestDemand =  demand ? putDemand(demand.id, formDemanda) : postDemand(formDemanda) ;
            requestDemand.then(response => {          
                if(response.data.id) {                    
                    toast.success("Demanda cadastrada com sucesso!");
                    setFormDemanda({...formDemanda, demandTempId: response.data.id });
                }
            });
        }
    }

    return (
        <Container fluid className="responsive-form-container">
            <ToastContainer />
            <TerritorializationModalComponent 
                territorializations={territorializationData.territorializations} 
                selecteds={territorializationData.selectedTerritorializations}
                openModal={territorializationData.modal}
                toggleCallback={toggle} 
                checkOpt={checkOpt}
            />  
            
            <div className="row linha-form">
                <div className="col-4 responsive-field-form-full">
                    <span className="h5 text-black">Tipo demanda</span>
                    <select className="form-control" value={formDemanda.Type} onChange={e => handleChangeType(e)}>
                        <option value="externa">Externa</option>
                        <option value="interna">Interna</option>                                    
                    </select>
                </div>
            </div>
            <div className="row linha-form">
                {formDemanda.Type === 'externa' ? 
                    <>
                        <div className="col-4 responsive-field-form-medium">
                            <span className="h5 text-black">Solicitante</span>
                            <input type="text" 
                                value={formDemanda.Person.Name}
                                onChange={e => setFormDemanda({...formDemanda, Person: {...formDemanda.Person, Name: e.target.value}})} 
                                className="form-control" 
                                placeholder="Solicitante" 
                            />
                        </div>
                        <div className="col-4 responsive-field-form-medium">
                            <span className="h5 text-black">Telefone</span>
                            <input type="text" 
                                value={formDemanda.Person.Phone}
                                onChange={e => setFormDemanda({...formDemanda, Person: {...formDemanda.Person, Phone: e.target.value}})} 
                                className="form-control" 
                                placeholder="Telefone" 
                            />
                        </div>
                        <div className="col-4 responsive-field-form-medium">
                            <span className="h5 text-black">Tipo de Documento</span>
                            <select value={formDemanda.Person.TypeDocument} 
                                className="form-control" 
                                onChange={(e) => setFormDemanda({...formDemanda, Person:{...formDemanda.Person, TypeDocument: e.target.value}})}
                            >
                                <option value={''}>Selecione</option>
                                {documentTypes.map((documento, id) => <option key={id} value={documento.tipo}>{documento.tipo}</option>)}                               
                            </select>
                        </div>
                        <div className="col-4 responsive-field-form-medium">
                            <span className="h5 text-black">Documento</span>
                            <input type="text" 
                                value={formDemanda.Person.Document}
                                disabled={formDemanda.Person.TypeDocument == 'Não Possui' || formDemanda.Person.TypeDocument == ''} 
                                onChange={(e) => setFormDemanda({...formDemanda, Person:{...formDemanda.Person, Document:e.target.value}})} 
                                className="form-control" 
                                placeholder="Documento" 
                            />
                        </div>
                        <div className="col-4 responsive-field-form-full">
                            <span className="h5 text-black">Nacionalidade</span>
                            <select value={formDemanda.Person.Nacionality} 
                                className="form-control" 
                                onChange={(e) => setFormDemanda({...formDemanda, Person:{...formDemanda.Person, Nacionality: e.target.value}})}
                            >
                                <option value={''}>Selecione</option>
                                <option value={'Brasileiro'}>Brasileiro(a)</option>(
                                <option value={'Outra'}>Outra</option>
                            </select>
                        </div>
                                
                        {formDemanda.Person.Nacionality === 'Brasileiro' || formDemanda.Person.Nacionality === '' ? 
                            <div className="col-4 responsive-field-form-full">
                                <span className="h5 text-black">Naturalidade</span>
                                <select 
                                    value={formDemanda.Person.Naturality} 
                                    className="form-control" 
                                    onChange={(e) => setFormDemanda({...formDemanda, Person:{...formDemanda.Person, Naturality: e.target.value}})}
                                >
                                    <option value={''}>Selecione</option>
                                    {states.map(({nome}, id) => <option key={id} value={nome}>{nome}</option>)}
                                </select>
                            </div>
                        : 
                            <>
                                <div className={"col-2 responsive-field-form-medium"}>
                                    <span className="h5 text-black">Nacionalidade</span>
                                    <input type="text" 
                                        value={formDemanda.Person.Nacionality}
                                        onChange={e => setFormDemanda({...formDemanda, Person:{...formDemanda.Person, OtherNacionality: e.target.value}})} 
                                        className="form-control" 
                                        placeholder="Outra Nacionalidade" 
                                    />
                                </div>
                                <div className={"col-2 responsive-field-form-medium"}>
                                    <span className="h5 text-black">Naturalidade</span>
                                    <input 
                                        value={formDemanda.Person.Naturality}
                                        type="text" 
                                        onChange={e => setFormDemanda({...formDemanda, Person:{...formDemanda.Person, Naturality: e.target.value}})}
                                        className="form-control" 
                                        placeholder="Naturalidade" 
                                    />
                                </div>
                            </>
                        } 
                    </>
                :
                    <>
                        <div className="col-4 responsive-field-form-full">
                            <span className="h5 text-black">Nome</span>
                            <input 
                                type="text" 
                                value={formDemanda.DemandName} 
                                onChange={e => setFormDemanda({...formDemanda, DemandName: e.target.value })} 
                                className="form-control" 
                                placeholder="Nome" 
                            />
                        </div>
                        <div className="col-12 responsive-field-form-full">
                            <span className="h5 text-black">Descrição</span>
                            <textarea 
                                value={formDemanda.Description} 
                                onChange={e => setFormDemanda({...formDemanda, Description: e.target.value })} 
                                placeholder="Descrição" 
                                className="form-control"
                            >
                            </textarea>
                        </div>
                    </>
                }
                    
                <div className="col-4 responsive-field-form-full">
                    <span className="h5 text-black">
                        Territorialização  
                        <button onClick={() => {toggle(null)}} className="fa fa-search"/>
                    </span>  
                    <input 
                        type="text" 
                        defaultValue={territorializationData.territoText} 
                        className="form-control" 
                        onClick={() => {toggle(null)}} 
                        placeholder="Territorialização" 
                    />                                
                </div>
                {territorializationData.TerritorializationGeometry === "Point" &&
                    <>
                        <div className="col-4 responsive-field-form-medium">
                            <span className="h5 text-black">CEP</span>
                            <input  
                                type="text" 
                                value={formDemanda.Address.postalCode} 
                                onBlur={() => findCep()} 
                                onChange={(e) => setFormDemanda({...formDemanda, Address:{...formDemanda.Address, postalCode: e.target.value}})} 
                                className="form-control" 
                                placeholder="CEP" 
                            />
                        </div>
                        <div className="col-4 responsive-field-form-medium">
                            <span className="h5 text-black">Rua</span>
                            <input 
                                type="text" 
                                value={formDemanda.Address.Route} 
                                onChange={(e) => setFormDemanda({...formDemanda, Address:{...formDemanda.Address, Route: e.target.value}})}
                                className="form-control" 
                                placeholder="Rua" 
                            />
                        </div>
                    </>
                }
                
                {territorializationData.TerritorializationGeometry === "Point"?
                    <div className="container-flex">
                        <div className="linha-form col-4 responsive-field-form-medium">
                            <span className="h5 text-black">Número</span>
                            <input  
                                type="text" 
                                value={formDemanda.Address.StreetNumber} 
                                onChange={(e) => setFormDemanda({...formDemanda, Address:{...formDemanda.Address, StreetNumber: e.target.value}})}
                                className="form-control" 
                                placeholder="Número" 
                            />
                        </div>
                        <div className="linha-form col-4 responsive-field-form-medium">
                            <span className="h5 text-black">Complemento</span>
                            <input 
                                type="text" 
                                value={formDemanda.Address.SubPremise} 
                                onChange={(e) => setFormDemanda({...formDemanda, Address:{...formDemanda.Address, SubPremise: e.target.value}})}
                                className="form-control" 
                                placeholder="Complemento" 
                            />
                        </div>
                        <div className="linha-form col-4 responsive-field-form-medium">
                            <span className="h5 text-black">Bairro</span>
                            <input 
                                type="text" 
                                value={formDemanda.Address.SubLocality} 
                                onChange={(e) => setFormDemanda({...formDemanda, Address:{...formDemanda.Address, SubLocality: e.target.value}})}
                                className="form-control" 
                                placeholder="Bairro" 
                            />
                        </div>
                        <div className="linha-form col-4 responsive-field-form-medium">
                            <span className="h5 text-black">Ponto de Referência</span>
                            <input 
                                type="text" 
                                value={formDemanda.Address.ReferencePoint} 
                                onChange={(e) => setFormDemanda({...formDemanda, Address:{...formDemanda.Address, ReferencePoint: e.target.value}})}
                                className="form-control" 
                                placeholder="Ponto de Referência" 
                            />
                        </div>
                        { formDemanda.Type === 'interna' && 
                            <>
                                <div className="linha-form col-4 responsive-field-form-medium">
                                    <span className="h5 text-black">Área</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Área" 
                                        value={formDemanda.Address.Area} 
                                        onChange={(e) => setFormDemanda({...formDemanda, Address:{...formDemanda.Address, Area: e.target.value}})}
                                    />
                                </div>
                                <div className="linha-form col-4 responsive-field-form-medium">
                                    <span className="h5 text-black">Quadra</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Quadra" 
                                        value={formDemanda.Address.Block} 
                                        onChange={(e) => setFormDemanda({...formDemanda, Address:{...formDemanda.Address, Block: e.target.value}})}
                                    />
                                </div>
                            </>
                        }
                        <div className="linha-form col-4 responsive-map-button">
                            <button 
                                className="btn btn-primary mt-4" 
                                disabled={!formDemanda.Address.Route || !formDemanda.Address.SubLocality || !formDemanda.Address.StreetNumber || !formDemanda.Address.postalCode} 
                                onClick={() => {
                                    territorializationData.map.displayMapExt ? 
                                        setTerritorializationData({...territorializationData, map: {...territorializationData, displayMapExt: false}}) 
                                    : 
                                        renderMap("displayMapExt")
                                }}>
                                {!territorializationData.map.displayMapExt ? "Ver no Mapa" : "Fechar Mapa"}
                            </button>
                        </div>
                    </div>
                :
                    <>
                        <div className="linha-form col-4 responsive-field-form-full">
                            <span className="h5 text-black">Ponto de Referência</span>
                            <input 
                                type="text"
                                value={formDemanda.Address.ReferencePoint} 
                                onChange={(e) => setFormDemanda({...formDemanda, Address:{...formDemanda.Address, ReferencePoint: e.target.value}})}
                                className="form-control" 
                                placeholder="Ponto de Referência" 
                            />
                        </div>
                        <div className="container-flex"></div>
                    </>
                }
                {territorializationData.map.displayMapExt &&
                    <div className="mb-2 col-12">
                        <LoadScript googleMapsApiKey="AIzaSyCdkHjfiiztTeKSSuvfauQERlAJGFONeKo">
                            <GoogleMap
                                mapContainerStyle={{width:'100%', height:'400px'}}
                                center={territorializationData.map.latLong}
                                zoom={15}>

                                <Marker position={territorializationData.map.latLong} />
                            </GoogleMap>
                        </LoadScript>
                    </div>
                }

                <div className="linha-form col-4 responsive-field-form-full">
                    <span className="h5 text-black"><b>Origem da solicitação*</b></span>
                    {formDemanda.Type === 'externa' ?
                        <select 
                            value={formDemanda.Origin} 
                            className="form-control" 
                            onChange={e => setFormDemanda({...formDemanda, Origin: e.target.value})}
                            >
                            <option value={''}>Origem de solicitação</option>
                            {origins.map(({id, name}, index) => <option key={index} value={id}>{name}</option> )}                                        
                        </select>
                    : 
                        <select className="form-control" value={"8a9e5785-037c-4980-b465-4f2e844a0070"} disabled>                    
                            <option value={"8a9e5785-037c-4980-b465-4f2e844a0070"}>O próprio</option>
                        </select>
                    }
                </div>
                {formDemanda.Type === 'externa' && 
                    <>
                        <div className="linha-form col-4 responsive-field-form-full">
                            <span className="h5 text-black">Responsável pela validação </span>
                            <Select
                                onChange={(e) => setFormDemanda({...formDemanda, responsibleId: e.value})}                                                                                                       
                                options={employees}
                                value={employees.filter(e => e.value == formDemanda.responsibleId)}                                                    
                                placeholder="Selecione"
                            />
                        </div>
                    
                        <div className="linha-form col-4 responsive-field-form-full">
                            <span className="h5 text-black">Tipo da solicitação</span>
                            <select 
                                className="form-control"
                                value={formDemanda.SolicitationType} 
                                onChange={e => setFormDemanda({...formDemanda, SolicitationType: e.target.value})}
                            >
                                <option value="">Tipo da solicitação</option>
                                <option value="Serviço">Serviço</option>
                                <option value="Denúncia">Denúncia</option>
                                <option value="Notificação de agravo">Notificação de agravo</option>
                            </select>
                        </div>
                        <div className="col-12 responsive-field-form-full">
                            <span className="h5 text-black">Amostra - Material entregue (Descrever)</span>
                            <textarea 
                                value={formDemanda.SampleDescription} 
                                onChange={e => setFormDemanda({...formDemanda, SampleDescription: e.target.value})} 
                                placeholder="Descrição da amostra" 
                                className="form-control">
                            </textarea>
                        </div>
                    </>
                }

                {formDemanda.programs.map((program, indexProgram) => {
                    return (
                        <div key={indexProgram} className="programa-principal-container">
                            <div className="linha-form col-4 responsive-field-form-full">
                                <span className="h5 text-black"><b>Programa*</b></span>
                                <Select
                                    options={programsOpt.filter((prg) => !formDemanda.programs.some((p) => p.programId === prg.value))}
                                    value={programsOpt.filter(p => p.value == program.programId)}
                                    onChange={(e) => changeProgram(indexProgram, e)}
                                    isDisabled={formDemanda.isDisableOrganization}
                                />
                            </div>
                            {program.programId !== "00000000-0000-0000-0000-000000000000" &&
                                program.problems.map((problem, indexProblem) => (   
                                    <div key={indexProblem} className="container-flex">                       
                                        <div className="linha-form col-3 responsive-field-form-full">
                                            <span className="h5 text-black"><b>Necessidade*</b></span>
                                            <Select
                                                options={program.TypeProblems}
                                                value ={program.TypeProblems.filter(tp => tp.value == problem.id)}
                                                onChange={(e) => handleChangeProblemsFields(indexProgram, e,indexProblem, "id")}
                                            />
                                        </div>
                                        <div className="linha-form col-3 responsive-field-form-full">
                                            <span className="h5 text-black">Especificação do serviço</span>
                                            <Select
                                                onChange={(e) => handleChangeProblemsFields(indexProgram, e, indexProblem, "serviceId")}     
                                                isDisabled={problem.id === "00000000-0000-0000-0000-000000000000"}    
                                                options={problem.servicesFiltered.filter(sf => !program.problems.some(prob => prob.serviceId === sf.value))}
                                                value={problem.servicesFiltered.filter(sf => sf.value == problem.serviceId)}
                                            />
                                        </div>
                                        <div className="linha-form col-3 responsive-field-form-full">
                                            <span className="h5 text-black">Tempo programado para atendimento</span>
                                            <Select
                                                onChange={(e) => handleChangeProblemsFields(indexProgram, e, indexProblem, "priority")}
                                                isDisabled={problem.id === "00000000-0000-0000-0000-000000000000"}
                                                options={priorityOptions}
                                                value={priorityOptions.filter(po => po.value == problem.priority)}
                                            />
                                        </div>
                                        <div className="linha-form col-3 responsive-field-form-full">
                                            <span className="h5 text-black">Observação</span>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="observation" 
                                                value={problem.observation}
                                                onChange={(e) => handleChangeProblemsFields(indexProgram, e, indexProblem)} 
                                                placeholder="Observação" 
                                                disabled={problem.id === "00000000-0000-0000-0000-000000000000"} 
                                            />
                                        </div>   
                                        {indexProblem === program.problems.length -1 &&
                                            <>
                                                {program.problems.length > 1 ? 
                                                <>
                                                    <div className="col-6 linha-form responsive-field-form-full">
                                                        <button onClick={() => addProblema(indexProgram)} className="btn btn-info">+ Adicionar Necessidade</button>
                                                    </div>
                                                    <div className="col-6 linha-form responsive-field-form-full" style={{marginTop: 'auto'}}>
                                                        <button className="btn btn-danger" onClick={(e) => removerProblema(indexProgram)}>- Remover Necessidade</button>
                                                    </div>
                                                </>
                                                :
                                                    <div className="col-12 linha-form responsive-field-form-full">
                                                        <button onClick={() => addProblema(indexProgram)} className="btn btn-info">+ Adicionar Necessidade</button>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                    )
                                )
                            }
                            {indexProgram === formDemanda.programs.length -1 &&
                                <>
                                    {formDemanda.programs.length > 1 ? 
                                        <div className="container-flex">
                                            <div className="col-6 linha-form responsive-field-form-full responsive-form-programa-button">
                                                <button 
                                                    onClick={() => setFormDemanda({...formDemanda, programs: [...formDemanda.programs, JSON.parse(JSON.stringify(PROGRAM_STATE_DEFAULT))]})} 
                                                    className="btn btn-info"
                                                >
                                                    + Adicionar Programa
                                                </button>
                                            </div>
                                            <div className="col-6 linha-form responsive-field-form-full">
                                                <button 
                                                    className="btn btn-danger" 
                                                    onClick={(e) => setFormDemanda({...formDemanda, programs: formDemanda.programs.slice(0, -1)})}
                                                >
                                                    - Remover Programa
                                                </button>
                                            </div>
                                        </div>
                                    :
                                        <div className="col-12 linha-form responsive-field-form-full responsive-form-programa-button">
                                            <button 
                                                onClick={() => setFormDemanda({...formDemanda, programs: [...formDemanda.programs, JSON.parse(JSON.stringify(PROGRAM_STATE_DEFAULT))]})} 
                                                className="btn btn-info">
                                                + Adicionar Programa
                                            </button>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    )
                })}

                <div className="container-flex">
                    <div className="col-4 responsive-field-form-medium">
                        <span className="h5 text-black">Previsão início</span>
                        <input 
                            type="date" 
                            name="beginDate" 
                            value={formDemanda.beginDate} 
                            onChange={(e) => handleChangeDate(e)} 
                            className="form-control" 
                            placeholder="Previsão início" 
                        />
                    </div>

                    <div className="col-4 responsive-field-form-medium">
                        <span className="h5 text-black">Previsão término</span>
                        <input 
                            name="endDate"
                            value={formDemanda.endDate} 
                            type="date" 
                            onChange={(e) => handleChangeDate(e)} 
                            className="form-control" 
                            placeholder="Previsão término" 
                        />
                    </div>
                </div>
                        
                <div className="linha-form col-4 responsive-field-form-full">
                    <span className="h5 text-black">Equipe responsável pela demanda</span>                                                 
                    <Select
                        value = {teams.filter((teams) => (formDemanda.teamId.includes(teams.value)))}
                        onChange={e => setFormDemanda({...formDemanda, teamId: e ? e.map(({value}) => (value)) : []})}
                        options={teams}
                        isMulti
                        placeholder="Equipes"
                        blurInputOnSelect={false}
                        closeMenuOnSelect={false}
                    />
                </div>
                <div className="col-8 responsive-field-form-full">
                    <span className="h5 text-black">Executores</span>
                    <Select
                        value={employees.filter(({value}) => formDemanda.DemandProblemEmployeesId.includes(value))}
                        onChange={e => setFormDemanda({...formDemanda, DemandProblemEmployeesId: e ? e.map(({value}) => value) : []})}
                        options={employees}
                        isMulti
                        placeholder="Executores"
                        blurInputOnSelect={false}
                        closeMenuOnSelect={false}
                    />
                </div> 
            </div>

            <div className="col-4 mb-2 responsive-field-form-full">                      
                <small>Campos com (*) são obrigatórios</small>
            </div>

            <div className="row mb-6">
                <div className="col-4 responsive-field-form-medium">
                    <button className="btn btn-primary" onClick={saveDemand}>
                        {demand ? 'Salvar' : 'Cadastrar'}
                    </button>
                </div>

                <div className="col-4 responsive-field-form-medium">
                    <button className="btn" onClick={() => {
                        setFormDemanda(JSON.parse(JSON.stringify(FORM_DEMANDA_DEFAULT))) 
                        setTerritorializationData({...TERRITORIALIZATION_DATA})
                    }}>Limpar</button>
                </div>

                <div className="col-4 responsive-field-form-full">
                    <Link to="/admin/index">                                
                        <button className="form-control btn btn-warning">Voltar</button>
                    </Link>
                </div>
            </div>
        </Container>
    )
}

export default FormDemanda
