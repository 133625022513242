import React from "react";
import { Card, CardBody } from "reactstrap";
import Skeleton from "../Skeleton";

const CardLoadingSkeleton = ({ topBarWidth, middleBarWidth, bottomBarWidth }) => {
  const style = {
    cardBody: {
      height: "8rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center"
    },
  };

  return (
    <Card className="card-stats mb-4">
      <CardBody height="100%" style={style.cardBody}>
        <Skeleton variant="rectangle" height="1rem" width={topBarWidth ? topBarWidth : "100px"} />
        <Skeleton variant="rectangle" height="1rem" width={middleBarWidth ? middleBarWidth : "350px"} />
        <Skeleton variant="rectangle" height="1rem" width={bottomBarWidth ? bottomBarWidth : "200px"} />
      </CardBody>
    </Card>
  );
};

export default CardLoadingSkeleton;
