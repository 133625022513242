import React, { Component } from "react";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";
import { timeStampFormated } from "../../../services/utils/format";
import moment from "moment";

export function withBasicListGraficMap(WrappedComponent) {
  return class extends Component {
    buildGraficMapList = async (traps, key, colorIcon, selectedSpecie = null) => {
      const trapList = new Array();

      for(let i = 0; i < traps.length; i++){
          const position = [traps[i].latitude, traps[i].longitude];
          
          var name = `${ARMADILHA_INFO[traps[i].trapTypeName].apelido} Nº ${traps[i].number ? traps[i].number : ""} \n${timeStampFormated(traps[i].dateInstalled)}`;
          var intensity = 0.5;
          var typeQtt = null;
          if(selectedSpecie) {
              typeQtt = "mosquitos";
              intensity += (traps[i].eggs * 0.05);
              name += "\nQuantidade de mosquitos:" + traps[i].eggs;
          } else {
              typeQtt = "ovos";
              intensity += (traps[i].eggs * 0.005);
              name += "\nQuantidade de ovos:" + traps[i].eggs;
          }
          var nameWithoutFormat = {
            trapType: ARMADILHA_INFO[traps[i].trapTypeName].apelido,
            number: traps[i].number ? traps[i].number : "",
            dateInstalled :timeStampFormated(traps[i].dateInstalled),
            qtt:  traps[i].eggs,
            typeQtt: typeQtt
          };
          const type = "basictrap";
          const icon = ARMADILHA_INFO[traps[i].trapTypeName].icons[colorIcon];
          const trapListObject = {
              name,
              position,
              type,
              intensity,
              icon,
              nameWithoutFormat
          }
          
          trapList.push(trapListObject);
      }

      const mapListObject = {
          name: key,
          checked: false,
          points: trapList,
      }

      return mapListObject;
  }

    render() {
      return (
        <WrappedComponent {...this.props} buildGraficMapList={this.buildGraficMapList} />
      );
    }
  };
}
