import React from "react";
import { ToastContainer } from "react-toastify";
import { Col, Container, Row } from "reactstrap";

const ContactUs = () => {
  return (
    <div>
      <ToastContainer />
      <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8 mb-5">
        <Row>
          <Col>
            <h1 style={{color: "#f75c5c"}}>Instruções para nos contactar</h1>
            <ul style={{color: "#ffffff"}}>
              <li>Informe os seus dados pessoais, como nome, e-mail e telefone.</li>
              <li>No campo "Tipo de Mensagem", informe se o seu chamado seria uma dúvida, uma sugestão para o sistema, etc.</li>
              <li>Após selecionar o tipo de mensagem, informe o assunto do chamado, a UVIS de origem e a mensagem.</li>
              <li>No campo de "Mensagem" forneça o máximo de detalhes possíveis sobre a sua ocorrência, como procedimentos que foram realizados até chegar ao resultado, a mensagem de erro, a dúvida. Isso nos ajudará a respondê-lo com maior agilidade e precisão;</li>
              <li>Ao finalizar, clique no botão "Enviar". Com isso, o seu chamado é adicionado a uma base de solicitações e nossos atendentes farão o retorno o mais rápido possível. As atualizações sobre o chamado serão enviadas através do seu E-MAIL, então fique atento!</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt--7">
        <Row>
          <Col>
            <iframe
              title="tomticket"
              src="https://vitec.tomticket.com/formulario?id=EP64691&account=4140224P26012024113116"
              style={{width: "100%", height: "1000px", borderWidth: "0px", overflow: "auto", marginTop: "55px"}}
            ></iframe>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
