import React, {Component, useEffect, useState} from "react";
import { withBasicListMap } from "../../../../HOC/Entomologico/Map/BasicListMap";
import {point as CreatePoint, polygon as CreatePolygon, pointsWithinPolygon} from "@turf/turf"
import { withBasicListGraficMap } from "../../../../HOC/Entomologico/Map/BasicListGraficMap";
import CloropleticMap from "../../../../views/pages/Reports/CloropleticMap";

let territorializations = [];

const IMOMapContainer = ({data, trapData, labels, trapMapInfo, territorializationList, territorializationFiltered, previousTrapMapInfo, buildGraficMapList, trapTypeName}) => {
    const [mapList, setMapList] = useState([]);
    const [labelData, setLabelData] = useState("");
    const [CloropleticPolygonGroups, setCloropleticPolygonGroups] = useState(null);
    const [HeatMapPoints, setHeatMapPoints] = useState(null);
    const [eggsEqualZero, setEggsEqualZero] = useState();

    // useEffect(() => {
    //     if(territorializationList && territorializationList.length > 0){
    //         const groups = [...territorializationList];
    //         if(territorializationFiltered && territorializationFiltered.length >0){
    //             for(const group of groups){
    //                 const list = [...group.territorializationList].filter((territorialization) => territorializationFiltered.some(({value}) => territorialization.id === value));
    //                 group.territorializationList = list;
    //             }
    //         }
            
    //         territorializations = groups;
    //     }
    // }, [territorializationList, territorializationFiltered]);

    // useEffect(() => {
    //     if((trapMapInfo) || (previousTrapMapInfo)){
    //         var response = getDataSets(); 
    //         buildMap(response.eggsEqualZero);  
    //     }

    // }, [trapMapInfo, previousTrapMapInfo])

    useEffect(() => {
        if(trapMapInfo && eggsEqualZero != undefined){
            buildMap();
        }
    }, [trapMapInfo, territorializationList, eggsEqualZero])

    useEffect(() => {
        if(data && data.length){
            getDataSets(data,previousTrapMapInfo)
        }
    }, [data, previousTrapMapInfo])

    const buildMap = async () => {
        var inspectedList = null;
        const pointsGroupped = new Array();

        if ((trapMapInfo.positives.length == 0 || eggsEqualZero) && previousTrapMapInfo != undefined){
            trapMapInfo.inspected = previousTrapMapInfo.inspected;
            trapMapInfo.actives = previousTrapMapInfo.actives;
        }

        if(trapMapInfo && trapMapInfo.inspected !== undefined){
            inspectedList = await buildGraficMapList(trapMapInfo.inspected, "Armadilhas diagnosticadas", "blue");
            if(inspectedList.points.length > 0){
                setHeatMapPoints(inspectedList.points);
            }
            pointsGroupped.push(inspectedList);
        }

        if(trapMapInfo && trapMapInfo.actives !== undefined){
            const activeList = await buildGraficMapList(trapMapInfo.actives, "Armadilhas instaladas", "black");
            
            pointsGroupped.push(activeList);
        }

        
        buildCloropleticInfo();

        setMapList(pointsGroupped);
    }

    const buildCloropleticInfo  = () => {
        const cloropleticGroups = [];

        const allVistoriedTraps = {
            type: "FeatureCollection",
            features: []
        };

        for(const inspectedTrap of trapMapInfo.inspected){
            allVistoriedTraps.features.push(CreatePoint([inspectedTrap.longitude, inspectedTrap.latitude], {eggs: inspectedTrap.eggs}))
        }

        for(const group of territorializationList){
            var range = [99.99, 199.99];

            const cloropleticGroup = {
                name: group.name,
                Polygons: new Array()
            };

            let legends = [];
            for(const territorialization of group.territorializationList){
                if(territorialization.geometryType == "POLYGON"){
                    
                    const turfPolygon = CreatePolygon([territorialization.coordinates.map(({x, y}) => [x, y])])
                    const filteredVistoriedTraps = pointsWithinPolygon(allVistoriedTraps, turfPolygon).features

                    if(filteredVistoriedTraps.length > 0){
                        const eggsSum = filteredVistoriedTraps.reduce((acumulador, valorAtual) => {
                            return acumulador + valorAtual.properties.eggs
                        }, 0);

                        const ido =  eggsSum / filteredVistoriedTraps.length;
                        switch (trapTypeName) {
                            case "Ovitrampa":
                                range = [99.99, 199.99];
                                break;
                        
                            case "Adultrap":
                                range = [1, 3];
                                break;

                            default:
                                range = [19.99, 59.99];
                                break;
                        }

                        if(ido <= range[0]){
                            turfPolygon.color = "green";
                            
                        }else if( ido <= range[1]){
                            turfPolygon.color = "yellow";
                            
                        }else{
                            turfPolygon.color = "red";
                            
                        }
                    }else{
                        turfPolygon.color = "transparent";
                    }

                    cloropleticGroup.Polygons.push(turfPolygon)
                }
            }

            legends.push({
                firstItem: 4,
                lastItem: null,
                color: "red",
                text: range[1] + "+"
            });

            legends.push({
                firstItem: 1,
                lastItem: 3.99,
                color: "yellow",
                text: range[0] + " - " + range[1]
            });

            legends.push({
                firstItem: 0.0,
                lastItem: 0.99,
                color: "green",
                text: `0 - ` + range[0]
            });

            cloropleticGroup.legends = legends;

            cloropleticGroups.push(cloropleticGroup)
        }

        setCloropleticPolygonGroups(cloropleticGroups);
    }

    const getDataSets = () => {
        let eggs;
        const dataset = [];
        let labelDayDataSet = "";

        for(const index in labels){
            const dataSetObject = {
                label: labels[index]
            }

            for(let ano in data){
                if(data[ano][index]){
                    eggs = data[ano][index].ovos;
                    let vistoried = trapData[ano][index].inspections;

                    // (Número de ovos/total de armadilhas vistoriadas)
                    
                    const imo = vistoried != 0 ? (eggs/vistoried).toFixed(1) : 0; //indice médio de ovos;

                    if(data[ano].label){
                        dataSetObject[data[ano].label] = imo;
                    }else{
                        dataSetObject["IM"] = imo;
                    }
                }
            }
            dataset.push(dataSetObject);

            labelDayDataSet = dataset[index].label;

            if (eggs === 0 && previousTrapMapInfo != undefined && index != 0){
                labelDayDataSet = dataset[index-1].label;
            }
            else if (labelDayDataSet == 53 && data[data.length - 1].length == 52){
                labelDayDataSet = dataset[index-1].label;
            }
        }

        setEggsEqualZero(eggs === 0)
        setLabelData(labelDayDataSet);
    }

    return(
        <CloropleticMap
            PointsGroup={mapList}
            PolygonsGroup = {CloropleticPolygonGroups}
            HeatMap
            QuantityLegend={
                <div>
                    Exibindo Dados da <br />
                    Semana {typeof(labelData) === "string" ? labelData.replace("Semana", "") : labelData.toString().replace("Semana", "")}
                </div>
            }
            showCloropletic
            PointsHeatMap={HeatMapPoints}
        />
    );
}

export default withBasicListGraficMap(IMOMapContainer);