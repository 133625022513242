import React, { Component } from 'react';
import { Select } from "../Wrappers/SelectAll";
import getWeeks from '../../services/utils/epidemiologicalWeek';

class EpidemiologicalWeekInput extends Component {
    state = {
        weeks: [],
    }

    handleChange = (e) => {
        if (this.props.isSinan == true)
            this.props.changePeriod(e, "EpiWeekSINAN");
        else if (this.props.isEntomologico == true)
            this.props.changePeriod(e, "EpiWeekENTOMOLOGICO");
        else if (this.props.isOperacional == true)
            this.props.changePeriod(e, "EpiWeekOPERACIONAL");
        else
            this.props.changePeriod(e, "EpiWeek");
        this.props.setChangedWeek();
    }

    componentDidMount() {
        let weeks = new Array();
        for (let i = 0; i < 53; i++) {
            weeks.push({
                label: "Semana " + (i + 1),
                value: i
            })
        }

        this.setState({ weeks });
    }
    stringToDate(_date, _format, _delimiter) {
        var formatLowerCase = _format.toLowerCase();
        var formatItems = formatLowerCase.split(_delimiter);
        var dateItems = _date.split(_delimiter);
        var monthIndex = formatItems.indexOf("mm");
        var dayIndex = formatItems.indexOf("dd");
        var yearIndex = formatItems.indexOf("yyyy");
        var month = parseInt(dateItems[monthIndex]);
        month -= 1;
        var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
        return formatedDate;
    }
    render() {
        return (
            <div>
                <span className="h4 text-white">Semana epidemiológica (Manual)</span>

                <Select
                    styles={{
                        control: styles => ({ ...styles, minHeight: "2.90rem", borderRadius: "0.370rem" }),
                        option: styles => ({ ...styles, fontSize: "0.875rem" }),
                        valueContainer: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        singleValue: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        placeholder: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        menuPortal: styles => ({ ...styles, zIndex: 9999 })
                    }}
                    value={this.props.weeks}
                    isMulti={true}
                    placeholder={"Selecione"}
                    isClearable={true}
                    isDisabled={this.props.isDisabled}
                    blurInputOnSelect={false}
                    closeMenuOnSelect={false}
                    allowSelectAll={true}
                    allOption={{ label: "Todas", value: "*" }}
                    onChange={this.handleChange}
                    options={this.state.weeks}
                    menuPortalTarget={document.body}
                />

            </div>
        );
    }
}

export default EpidemiologicalWeekInput;
