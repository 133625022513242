import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import Skeleton from "../Skeleton";

const DonutGraphicLoadingSkeleton = ({ height }) => {
  return (
    <Card>
      <CardHeader>
        <Skeleton variant="rectangle" height="1rem" width="200px" />
      </CardHeader>
      <CardBody
        className="pb-0 pt-0 pl-0 pr-0"
        style={{
          height: height ? height : "300px",
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <div style={{ paddingLeft: "1rem" }}>
          <Skeleton variant="rectangle" height="1rem" width="150px" />
          <Skeleton variant="rectangle" height="1rem" width="150px" />
          <Skeleton variant="rectangle" height="1rem" width="150px" />
          <Skeleton variant="rectangle" height="1rem" width="150px" />
        </div>
        <div style={{ justifySelf: "center" }}>
          <Skeleton variant="circle" height="180px" width="180px" />
        </div>
      </CardBody>
    </Card>
  );
};

export default DonutGraphicLoadingSkeleton;
