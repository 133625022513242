import React from "react";
import InventoryMovementRecordTable from "./InventoryMovementRecordTable.jsx";
import FilterInventoryMovementRecord from "./FilterInventoryMovementRecord.jsx"
import Header from "components/Headers/Header.jsx";
import { getInventoryRecord } from "../../../../services/api/InventoryMovementRecord";
import { getPerson } from "../../../../services/api/Person";
import { fetchEmployeeData } from "../../../../services/api/Employee";
import { getUser, getUserEmployeeDataByOrganization } from "../../../../services/api/Users";

class InventoryMovementRecord extends React.Component {
  state = {
    InventoryRecord : [],
    InventoryRecordFiltered:[],
    loaded:false,
    columsCondicional: []
  };

  constructor (props) {
    super(props);
  }

  async componentDidMount() {
    const InventoryRequest = getInventoryRecord();
    const InventoryResponse = await InventoryRequest;
  
    const userEmployeesRequest = await getUserEmployeeDataByOrganization();
    const userEmployees = userEmployeesRequest.data;
  
    const updatedRecords = InventoryResponse.data.map((record) => {
      const updatedRecord = { ...record };
      updatedRecord["WhoModified"] = "";
      updatedRecord["WhoMoved"] = "";
  
      const user = userEmployees.find((u) => u.userId === record.userId);
      const employee = userEmployees.find((u) => u.employeeId === record.employeeId);
      const userName = user ? user.employeeName : "N/A";
      const employeeName = employee ? employee.employeeName : "N/A";
  
      updatedRecord["WhoModified"] = userName;
      updatedRecord["WhoMoved"] = record.employeeId !== '00000000-0000-0000-0000-000000000000' ? employeeName : "N/A";
  
      return updatedRecord;
    });
  
    this.setState({
      InventoryRecord: updatedRecords,
      InventoryRecordFiltered: updatedRecords,
      loaded: true,
    });
  }


  fetchInventoryMovements = async () => {
    var listMovements = []
    
       return  listMovements;
  }
  callbackFunction = (InventoryRecordFilteredArray)=>{
    this.setState({InventoryRecordFiltered: InventoryRecordFilteredArray})
  }

  handleChangeMultiSelect = e => {
      this.setState({columsCondicional: e})
  };
  render() {

    return (      
      <>
        <Header />
        <>
        <FilterInventoryMovementRecord columsCondicional={this.state.columsCondicional} handleChangeMultiSelect={this.handleChangeMultiSelect.bind(this)} Movement={this.state.InventoryRecord} parentCallback = {this.callbackFunction}/>

        <InventoryMovementRecordTable loaded={this.state.loaded} columsCondicional={this.state.columsCondicional} Movements={this.state.InventoryRecordFiltered} />
        </>
      </>
    );
  }
}

export default InventoryMovementRecord;