import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
// import 'bootstrap/dist/css/bootstrap.min.css';
//import Button from 'react-bootstrap/Button';
//import Modal from 'react-bootstrap/Modal';

import 'react-select-2/dist/css/react-select-2.css';
import { fetchPrograms } from "../../services/api/Program";


import {
    Container,
} from "reactstrap";
// core components
import "../../assets/css/style.css";
import { ToastContainer } from "react-toastify";

class FormSample extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
           
            program:"",
            programs: [{
                programId: "",
            }],
            types: [{
                typeName: "",
            }],
            type:"",
            state:"",
            deposit:"",
            adultAlive:0,
            adultDead:0,
            modal:false,
            materialDelivered:"",
            homeVisitForm:"",
            qtProductsL2:"",
            qtDepositsL2:"",
            qtProductsL1:"",
            qtDepositsL1:"",
            bats:"",
            batStatus:"",
            batContact:"",
            batLevelContact:"",
            batContactSpecies:"",
            contactSpecies:"",
            otherSpecies:"",
            accident:"",
            responsible:"",
            accidentedName:"",
            accidentedTel:"",
            qtSpecies:0,
            numberSample:"",
            arbovirosisSymptons:"",
            arbovirosisPeopleSymptons:"",
            lookedHealthcare:"",
            notified:"",
            qtDeposits:0,
            larvicidaTreatment:"",
            foundPlace:"",
            otherPlace:"",
        };
      }
    

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    changeSample = e => {
        this.setState({fromDemand:true});
        let field = e.target.name;
        let value = e.target.value;
        this.setState({ [field]: value });
        
        if(typeof(this.props.changeSample) === 'function') {
            this.props.changeSample(e);
            
        }
    }
    componentDidMount() {
        var requestPrograms = fetchPrograms();
        requestPrograms.then(response => {
            this.setState({programs: response.data});
        })

    }

    render() {       
      
        return (
            <>  
            
            <ToastContainer />

                <Container fluid>
                    <div className="row">
                        
                            
                        <div className="linha-form col-4">
                            <span className="h5 text-black"><b>Programa</b></span>
                            {/* <Select className="form-control"/> */}
                            <select className="form-control" name="program" onChange={this.changeSample.bind(this)}>
                                <option>Programa</option>
                                {this.state.programs.map((program) => {
                                    return (
                                        <option value={program.id}>{program.name}</option>
                                    )
                                })} 
                            </select>
                        </div> 
                        <div className="linha-form col-4">
                            <span className="h5 text-black"><b>Tipo de amostra</b></span>
                            {/* <Select className="form-control"/> */}
                            <select className="form-control"name="type" onChange={this.changeSample.bind(this)}>
                                <option>Tipos</option>
                                {this.state.types.map((type) => {
                                    return (
                                        <option value={type.id}>{type.name}</option>
                                    )
                                })} 
                            </select>
                        </div>                      
                    </div>
                    <div className="row">
                        <div className="linha-form col-4">
                            <span className="h5 text-black">Fase</span>
                            <select className="form-control"name="state" onChange={this.changeSample.bind(this)}>
                                <option value="Ovo">Ovo</option>
                                <option value="Larva">Larva</option>
                                <option value="Ninfa">Ninfa</option>
                                <option value="Adulto">Adulto</option>
                            </select>
                        </div>
                        <div className="linha-form col-4">
                            
                            <span className="h5 text-black">Deposito</span>
                            <select className="form-control"name="deposit" onChange={this.changeSample.bind(this)}>
                                <option value="A1">A1</option>
                                <option value="A2">A2</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D1">D1</option>
                                <option value="D2">D2</option>
                                <option value="E">E</option>
                            </select>
                        </div>

                    </div>
                    <div className="row">
                        <div className="linha-form col-4">
                                <span className="h5 text-black">Adultos Vivos</span>
                                <input type="number" name="adultAlive" onChange={this.changeSample.bind(this)} className="form-control" placeholder="1,2,3,4" />
                        </div>
                        <div className="linha-form col-4">                  
                                <span className="h5 text-black">Adultos Mortos</span>
                                <input type="number" name="adultDead" onChange={this.changeSample.bind(this)} className="form-control" placeholder="1,2,3,4" />
                        </div>
                    </div>
                    <div style={{ display: this.state.type == "Morcego" ?'block':  'none'  }}>
                        <div className="row" style={{ display: this.state.type == "Morcego" ?'block':  'none'  }}>
                            <div className="linha-form col-4">
                                    <span className="h5 text-black">Morcegos</span>
                                    <input type="text" name="bats" onChange={this.changeSample.bind(this)} className="form-control" placeholder="1,2,3,4" />
                            </div>
                            <div className="linha-form col-4">
                                
                                <span className="h5 text-black">Estado do morcego</span>
                                <select className="form-control"name="batStatus" onChange={this.changeSample.bind(this)}>
                                    <option value="Vivo">Vivo</option>
                                    <option value="Morto">Morto</option>
                                </select>
                            </div>
                            <div className="linha-form col-4">
                                
                                <span className="h5 text-black">Contato com morcego</span>
                                <select className="form-control"name="batContact" onChange={this.changeSample.bind(this)}>
                                    <option value="Sim">Sim</option>
                                    <option value="Não">Não</option>
                                    <option value="Ignorado">Ignorado</option>
                                </select>
                            </div>
                        </div>
    
                        <div className="row">
                            
                            <div className="linha-form col-4">
                                
                                <span className="h5 text-black">Nível de Contato</span>
                                <select className="form-control"name="batLevelContact" onChange={this.changeSample.bind(this)}>
                                    <option value="Direto">Direto</option>
                                    <option value="Indireto">Indireto</option>
                                    <option value="Sem Contato">Sem Contato</option>
                                </select>
                            </div>
                            <div className="linha-form col-4">
                                
                                <span className="h5 text-black">Contato com Outras Espécies</span>
                                <select className="form-control"name="batContactSpecies" onChange={this.changeSample.bind(this)}>
                                    <option value="Sim">Sim</option>
                                    <option value="Não">Não</option>
                                    <option value="Ignorado">Ignorado</option>
                                </select>
                            </div>
                            <div className="linha-form col-4">
                                    <span className="h5 text-black">Se sim, qual Espécie?</span>
                                    <select className="form-control"name="contactSpecies" onChange={this.changeSample.bind(this)}>
                                    <option value="Gato">Gato</option>
                                    <option value="Cachorro">Cachorro</option>
                                    <option value="Humano">Humano</option>
                                    <option value="Outro">Outro</option>
                                </select>
                            </div>
                        </div>
                        <div className="row" style={{ display: this.state.contactSpecies == "Outro" ?'block':  'none'  }}>
                            <div className="linha-form col-4">
                                    <span className="h5 text-black">Se Outro, Qual Espécie?</span>
                                    <input type="text" name="otherSpecies" onChange={this.changeSample.bind(this)} className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        
                        <div className="linha-form col-4">
                            
                            <span className="h5 text-black">Local Encontrado</span>
                            <select className="form-control"name="foundPlace" onChange={this.changeSample.bind(this)}>
                                <option value="Sala">Sala</option>
                                <option value="Banheiro">Banheiro</option>
                                <option value="Quarto">Quarto</option>
                                <option value="Cozinha">Cozinha</option>
                                <option value="Área">Área</option>
                                <option value="Quintal">Quintal</option>
                                <option value="Pátio">Pátio</option>
                                <option value="Outro">Outro</option>
                            </select>
                        </div>
                        <div className="linha-form col-4">
                            
                            <span className="h5 text-black">Houve Acidente Relacionado?</span>
                            <select className="form-control"name="accident" onChange={this.changeSample.bind(this)}>
                                <option value="Sim">Sim</option>
                                <option value="Não">Não</option>
                            </select>
                        </div>
                        <div className="linha-form col-4">
                                <span className="h5 text-black">Responsável ou Acidentado</span>
                                <input type="text" name="responsible" onChange={this.changeSample.bind(this)} className="form-control"/>
                        </div>
                    </div>
                     <div className="row" style={{ display: this.state.foundPlace == "Outro" ?'block':  'none'  }}>
                        
                    
                        <div className="linha-form col-4">
                                <span className="h5 text-black">Descreva Quando Outro Local Encontrado</span>
                                <input type="text" name="otherPlace" onChange={this.changeSample.bind(this)} className="form-control"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="linha-form col-12">
                                <span className="h5 text-black">Nesta seção será inserido os dados de quem sofreu o acidente
                                e telefone ao qual possamos contatar o responsável ou o próprio acidentado.
                                Orientar que o acidentado procure Atendimento Médico.</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="linha-form col-4">
                                <span className="h5 text-black">Nome do Acidentado</span>
                                <input type="text" name="accidentedName" onChange={this.changeSample.bind(this)} className="form-control" placeholder="1,2,3,4" />
                        </div>
                        <div className="linha-form col-4">
                                <span className="h5 text-black">Telefone (WhatsApp)</span>
                                <input type="text" name="accidentedTel" onChange={this.changeSample.bind(this)} className="form-control" placeholder="1,2,3,4" />
                        </div>
                        <div className="linha-form col-4">
                                <span className="h5 text-black">Quantidade de Espécies Capturadas</span>
                                <input type="number" name="qtSpecies" onChange={this.changeSample.bind(this)} className="form-control" placeholder="1,2,3,4" />
                        </div>
                    </div>   
                    <div className="row">
                        
                        
                        <div className="linha-form col-8">
                                <span className="h5 text-black">Para a Amostra de Vetores a forma de controle para o preenchimento será:
                                ANO.MATRÍCULA.SEQUÊNCIA Exemplo: 19.19968.001 19.19968.002 19.19968.003 Para as demais amostras
                                (sinantrópicos e zoonoses), manter o número da ficha de amostra.</span>
                        </div>
                        <div className="linha-form col-4">
                                <span className="h5 text-black">Número da Amostra</span>
                                <input type="text" name="numberSample" onChange={this.changeSample.bind(this)} className="form-control" placeholder="1,2,3,4" />
                        </div>
                    </div>
                    <div className="row">
                        
                        <div className="linha-form col-4">
                            
                            <span className="h5 text-black">Pessoas com sintomas de Arboviroses?</span>
                            <select className="form-control"name="arbovirosisSymptons" onChange={this.changeSample.bind(this)}>
                                <option value="Sim">Sim</option>
                                <option value="Não">Não</option>
                            </select>
                        </div>
                        <div className="linha-form col-4">
                                <span className="h5 text-black">Caso suspeito de arbovirose Todo indivíduo residente em Foz do Iguaçu que tenha
                                apresentado um ou mais sintomas compatíveis
                                (febre, dor de cabeça, dor no corpo, dor nas articulações,
                                manchas vermelhas na pele, enjoo ou náusea, vômitos, dor atrás dos olhos, mal estar, coceira,
                                conjuntivite) nos últimos 14 dias.</span>
                        </div>
                        <div className="linha-form col-4">
                            
                            <span className="h5 text-black">No imóvel possui pessoas que APRESENTARAM febre ou um dos sintomas nos últimos 15 dias?</span>
                            <select className="form-control"name="arbovirosisPeopleSymptons" onChange={this.changeSample.bind(this)}>
                                <option value="Sim">Sim</option>
                                <option value="Não">Não</option>
                            </select>
                        </div>
                    </div>
                            
                    <div className="row">
                        
                        <div className="linha-form col-4">
                            
                            <span className="h5 text-black">Procurou Posto de Saúde?</span>
                            <select className="form-control"name="lookedHealthcare" onChange={this.changeSample.bind(this)}>
                                <option value="Sim">Sim</option>
                                <option value="Não">Não</option>
                            </select>
                        </div>
                        <div className="linha-form col-4">
                            
                            <span className="h5 text-black">Foi Notificado?</span>
                            <select className="form-control"name="notified" onChange={this.changeSample.bind(this)}>
                                <option value="Sim">Sim</option>
                                <option value="Não">Não</option>
                            </select>
                        </div>
                        <div className="linha-form col-4">
                                <span className="h5 text-black">Quantidade de Depósito(s) Eliminado(s)</span>
                                <input type="number" name="qtDeposits" onChange={this.changeSample.bind(this)} className="form-control" placeholder="1,2,3,4" />
                        </div>
                    </div>

                    <div className="row">
                        
                        <div className="linha-form col-6">
                            <span className="h5 text-black">Depósito(s) Positivo(s)</span><br/>
                            <input type="checkbox" id="A1" name="A1" value="A1"/>
                            <label for="A1">  A1 - Caixa d'água (elevado)</label><br/>
                            <input type="checkbox" id="A2" name="A2" value="A2"/>
                            <label for="A2"> A2 - Outros depósitos de armazenamento de água (baixo)</label><br/>
                            <input type="checkbox" id="B" name="B" value="B"/>
                            <label for="B"> B - Pequenos depósitos móveis</label><br/>
                            <input type="checkbox" id="C" name="C" value="C"/>
                            <label for="C"> C - Depósitos fixos</label><br/>
                            <input type="checkbox" id="D1" name="D1" value="D1"/>
                            <label for="D1"> D1 - Pneus e outros materiais rodantes</label><br/>
                            <input type="checkbox" id="D2" name="D2" value="D2"/>
                            <label for="D2"> D2 - Lixo (recipientes plásticos, latas) sucatas, entulhos</label><br/>
                            <input type="checkbox" id="E" name="E" value="E"/>
                            <label for="E"> E - Depósitos naturais</label>
                        </div>
                        <div className="linha-form col-2">
                            
                            <span className="h5 text-black">Foi realizado tratamento com larvicida?</span>
                            <select className="form-control"name="larvicidaTreatment" onChange={this.changeSample.bind(this)}>
                                <option value="Sim">Sim</option>
                                <option value="Não">Não</option>
                            </select>
                        </div>
                    </div>

        
                    <div className="row">
                        <span className="h5 text-black">Depósito(s) Tratado(s)</span>
                        <div className="linha-form col-4">
                                <span className="h5 text-black">L1 - Quantidade de Depósitos</span>
                                <input type="number" name="qtDepositsL1" onChange={this.changeSample.bind(this)} className="form-control" placeholder="1,2,3,4" />
                        </div>
                        <div className="linha-form col-4">
                                <span className="h5 text-black">L1 - Quantidade de Produto (mg)</span>
                                <input type="text" name="qtProductsL1" onChange={this.changeSample.bind(this)} className="form-control" placeholder="1,2,3,4" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="linha-form col-4">
                                <span className="h5 text-black">L2 - Quantidade de Depósitos</span>
                                <input type="number" name="qtDepositsL2" onChange={this.changeSample.bind(this)} className="form-control" placeholder="1,2,3,4" />
                        </div>
                        <div className="linha-form col-4">
                                <span className="h5 text-black">L2 - Quantidade de Produto (mg)</span>
                                <input type="text" name="qtProductsL2" onChange={this.changeSample.bind(this)} className="form-control" placeholder="1,2,3,4" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="linha-form col-4">
                            
                            <span className="h5 text-black">Imóvel Possui Ficha de Visita Domiciliar</span>
                            <select className="form-control"name="homeVisitForm" onChange={this.changeSample.bind(this)}>
                                <option value="Sim">Sim</option>
                                <option value="Não">Não</option>
                            </select>
                        </div>
                        <div className="linha-form col-4">
                            
                            <span className="h5 text-black">Houve Entrega de Material</span>
                            <select className="form-control"name="materialDelivered" onChange={this.changeSample.bind(this)}>
                                <option value="Sim">Sim</option>
                                <option value="Não">Não</option>
                            </select>
                        </div>
                    </div>
                           
                    <div style={{ display: (typeof(this.props.changeSample) === 'function') ? 'none' : 'block' }}>
                        <div className="row">
                            <div className="linha-form col-4">
                                <button className="btn btn-primary" >Cadastrar</button>
                            </div>
                            <div className="col-4">
                                <Link to="/admin/index">                                
                                <button className="form-control btn btn-warning">Voltar</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Container>

            </>
        );
    }
}

export default FormSample;
