import React from "react";
import InventoryItensTable from "./IntentoryItensTable.jsx";
import FilterInventoryItens from "./FilterInventoryItens.jsx"
import Header from "components/Headers/Header.jsx";
import { getInventoryItens } from "../../../../services/api/Itens";
import TableLoadingSkeleton from "../../../../components/ui/Loading/TableLoadingSkeleton.jsx";


class InventoryItens extends React.Component {
  state = {
    InventoryItens : [],
    InventoryItensFiltered:[],
    loadedInventoryData : false,
    filterByPrefix: false
  };

  constructor (props) {
    super(props);
    /*if(!checkPermissionComponent("ba56119d-ac43-4ec1-858e-31f2e12235e8", "read")){
      alert("Você não tem permissão para acessar essa funcionalidade!");
      props.history.goBack(); 
    }*/
  }

  componentDidMount() {
     const InventoryRequest = getInventoryItens();
     this.setState({loadedInventoryData: false})

     InventoryRequest.then(response => {
          this.setState({loadedInventoryData: true})

           if(response) {
               return response.data
           }
           throw new Error("Erro ao contatar servidor");
       }).then(async data => {
          this.setState({InventoryItens : data});
          this.setState({InventoryItensFiltered : data});
       })
       
  }
  callbackFunction = (InventoryItensFilteredArray)=>{
    this.setState({InventoryItensFiltered: InventoryItensFilteredArray})
  }
  setFilterByPrefix = () => {
    this.setState({filterByPrefix: !this.state.filterByPrefix})
  }
  render() {

    return (      
      <>
        <Header />

        <FilterInventoryItens Itens={this.state.InventoryItens} filterByPrefix={this.state.filterByPrefix} setFilterByPrefix={this.setFilterByPrefix} parentCallback = {this.callbackFunction}/>

        <InventoryItensTable filterByPrefix={this.state.filterByPrefix} loadedInventoryData={this.state.loadedInventoryData} Itens={this.state.InventoryItensFiltered} />

      </>
    );
  }
}

export default InventoryItens;