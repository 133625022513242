import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import Skeleton from "../Skeleton";

const PyramidGraphicLoadingSkeleton = () => {
  return (
    <Card>
      <CardHeader style={{ display: "flex", justifyContent: "space-between" }}>
        <Skeleton variant="rectangle" height="1rem" width="100px" />
        <Skeleton variant="rectangle" height="1rem" width="50px" />
      </CardHeader>
      <CardBody className="pb-0 pt-0 pl-0 pr-0">
        <section
          style={{
            height: "430px",
            display: "grid",
            alignItems: "center",
            justifyContent: "center",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <div
            style={{
              justifySelf: "right",
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
            }}
          >
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="40px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="50px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="60px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="70px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="80px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="90px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="100px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="110px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="120px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="135px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="130px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="125px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="120px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="115px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="110px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="105px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="100px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="95px"
              padding="2px 2px"
            />
          </div>
          <div style={{ justifySelf: "left" }}>
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="40px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="50px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="60px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="70px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="80px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="90px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="100px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="110px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="120px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="135px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="130px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="125px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="120px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="115px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="110px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="105px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="100px"
              padding="2px 2px"
            />
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="95px"
              padding="2px 2px"
            />
          </div>
        </section>
      </CardBody>
    </Card>
  );
};

export default PyramidGraphicLoadingSkeleton;
