import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Marker, Popup } from "react-leaflet";
import PopUpInformationsComponent from "../../components/PopUpInformationsComponent";
import { toast } from "react-toastify";
import { putTrapByNumber } from "../../services/api/Trap";

const MarkerTrap = ({
  name,
  index,
  myIcon,
  position,
  paragrafo,
  number,
  handleShowOperatingRangeChange,
  handleDeleteTrapOperatingRange,
  organizationId,
  indexPointsGroup,
  PointsGroup, 
  setModal2,
  modal2,
  setTrapToUpdateData,
  trapToUpdateData
}) => {
  const [draggableTrap, setDraggableTrap] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const handleDragEnd = (target) => {
    const newLatitude = target._latlng.lat.toFixed(7);
    const newLongitude = target._latlng.lng.toFixed(7);

    console.log("teste latlong hde", newLatitude, newLongitude);

    setLatitude(newLatitude);
    setLongitude(newLongitude);
  };

  const saveLocation = (trap) => {
    toast.success("Localização da armadilha alterada");
    setDraggableTrap(false);

    putTrapByNumber({
      OrganizationId: organizationId,
      TrapNumber: number,
      Latitude: parseFloat(latitude),
      Longitude: parseFloat(longitude),
    });



    console.log("trap", trap);
  };

  return (
    <>
      <Marker
        draggable={draggableTrap}
        ondragend={({ target }) => handleDragEnd(target)}
        key={name + index + draggableTrap}
        icon={myIcon}
        position={latitude && longitude ? [latitude, longitude] : position}
        title={name}
      >
        <Popup key={name + index}>
          <div key={"popupDataPoint"} className="popUp">
            <PopUpInformationsComponent
              setModal2={setModal2}
              modal2={modal2}
              setTrapToUpdateData={setTrapToUpdateData}
              trapToUpdateData={trapToUpdateData}
              key={name + index}
              paragrafo={paragrafo}
              number={number ? number : null}
              trapType={name.split(" ")[0]}
              trapPosition={position}
              handleShowOperatingRangeChange={handleShowOperatingRangeChange}
              handleDeleteTrapOperatingRange={handleDeleteTrapOperatingRange}
              organizationId={organizationId}
              draggableTrap={draggableTrap}
              saveLocation={() => {
                saveLocation(PointsGroup[indexPointsGroup].points[index]);
              }}
              changeLocation={() => {
                setDraggableTrap(index, indexPointsGroup);
              }}
            />
          </div>
        </Popup>
      </Marker>
    </>
  );
};

export default MarkerTrap;
