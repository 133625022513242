import moment from "moment";
import getWeeks from "../epidemiologicalWeek";

export async function getPeriod({labels, labelIndex}, {startDate, endDate}){
    let aux = labels[labelIndex].split("/");
    let finalDate = "";
    let beginDate = ""

    if(labelIndex == 0){
        beginDate = moment(aux[1]+"-"+aux[0]+"-"+moment(startDate).format("DD")).format("YYYY-MM-DD");
        if(labelIndex + 1 <labels.length){
            let aux1 = labels[labelIndex+1].split("/");
            finalDate = moment(aux1[1]+"-"+aux1[0]+"-01").subtract(1, "day").format("YYYY-MM-DD");
        }else{
            finalDate = moment(aux[1]+"-"+aux[0]+"-"+moment(endDate).format("DD")).format("YYYY-MM-DD");
        }

    }else{
        beginDate = moment(aux[1]+"-"+aux[0]+"-01").format("YYYY-MM-DD");
        if(labelIndex + 1 < labels.length){
            let aux1 = labels[labelIndex+1].split("/");
            finalDate = moment(aux1[1]+"-"+aux1[0]+"-01").subtract(1, "day").format("YYYY-MM-DD");
        }else{
            finalDate = moment(aux[1]+"-"+aux[0]+"-"+moment(endDate).format("DD")).format("YYYY-MM-DD");
        }
    }
    return {finalDate, beginDate}
}

export async function getEpidemiologicalPeriod({labels, labelIndex, startDate, endDate, year, lastYear, firstYear}){
    const epidemiologicalWeeks = getWeeks();
    const epiIndex = epidemiologicalWeeks.findIndex(ew => ew.year === year);

    let epidemiologicalBeginDate = moment(epidemiologicalWeeks[epiIndex].weeks[labels[labelIndex] - 1].beginDate, "DD-MM-YYYY");
    let epidemiologicalEndDate = moment(epidemiologicalWeeks[epiIndex].weeks[labels[labelIndex] - 1].endDate, "DD-MM-YYYY");
    let finalDate = "";
    let beginDate = "";


    if(lastYear === year && labels.length - 1 == labelIndex){
        finalDate = endDate;
    }else{
        finalDate = epidemiologicalEndDate.format("YYYY-MM-DD");
    }

    if(firstYear === year && labelIndex == 0){
        beginDate = startDate;
    }else{
        beginDate = epidemiologicalBeginDate.format("YYYY-MM-DD");
    }

    return {finalDate, beginDate};
}