import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "../FormManutencao.css";
import { toast } from "react-toastify";
import CsvDownloadButton from "react-json-to-csv";
import {
  getTrapByNumber,
  postTrap,
  postTrapHistory,
} from "../../../../services/api/Trap";
import { postVigilanciaEntomologica } from "../../../../services/api/VigilanciaEntomologica";
import {
  checkIfRequiredFieldsAreEmpty,
  getAddressInformations,
} from "../../../../services/utils/WebForms/validations";
import { getEntomologicVigilanceObjectForMaintenanceAdi } from "../../../../services/utils/WebForms/EntomologicVigilance";
import {
  getTrapHistoryObject,
  getTrapToPostObject,
} from "../../../../services/utils/WebForms/Trap";
import MaintenanceAdiFormPrintModal from "../../../../components/Modal/WebForms/MaintenanceAdiFormPrintModal";
import HeaderFormInputs from "../HeaderFormInputs";
import FooterFormButtons from "../FooterFormButtons";
import { Select } from "../../../../components/Wrappers/SelectAll";

const MaintenanceAdiForm = ({ employeesOptions, organizationId }) => {
  const [headerInputs, setHeaderInputs] = React.useState({
    uvisName: {
      label: "Selecione a UVIS",
      value: "",
    },
    administrativeDistrict: {
      label: "Selecione o Distrito Administrativo",
      value: "",
    },
    sanitaryDistrict: "",
    environmentalSector: "",
    block: "",
    teamsAndEmployees: [],
    activityDate: "",
  });
  const [tableRowsData, setTableRowsData] = React.useState([
    { ...getTableRowsObject() },
  ]);
  const [isSelectModalOpen, setIsSelectModalOpen] = React.useState(false);
  const [selectValueInsideModal, setSelectValueInsideModal] =
    React.useState("");
  const [selectedInputData, setSelectedInputData] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [isPrintFormModalOpen, setIsPrintFormModalOpen] = React.useState(false);
  const [isSubmitingForm, setIsSubmitingForm] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const MAX_ROWS_PER_FORM = 20;
  const MIN_ROWS_PER_FORM = 1;
  const EMPTY_FIELD = "";

  const handleTrapWasReadChange = (e, rowIndex) => {
    if(e === null){
      handleInputChange("trapWasReadSelectedOptions", "", rowIndex);
      e = [];
    }
    
    if(e !== null && e.length > 0)
      handleInputChange("trapWasReadSelectedOptions", "Preenchido", rowIndex);
    
    handleInputChange("trapWasReadOptions", e, rowIndex);
  }

  const handleHeaderInputsChange = (inputName, inputValue) => {
    const newHeaderInputs = { ...headerInputs };
    newHeaderInputs[inputName] = inputValue;

    setHeaderInputs(newHeaderInputs);
  };

  function getTableRowsObject() {
    return {
      address: "",
      addressNumber: "",
      addressComplement: "",
      propertyType: "",
      responsablePersonName: "",
      responsablePersonCellPhoneNumber: "",
      visitStatus: "",
      trapNumber: "",
      isTrapInUsableConditions: "sim",
      whyIsTrapInUnusableConditions: "",
      whyIsTrapInUnusableConditionsSelectedOptions: "",
      trapWasRead: "não",
      trapWasReadSelectedOptions: "",
      trapWasReadOptions: [],
      wereFoundLarvaeInTrap: "não",
      aliveLarvaeNumberSelectedInTrap: "999",
      deadLarvaeNumberSelectedInTrap: "999",
      wereFoundPupaInTrap: "não",
      alivePupaNumberSelectedInTrap: "999",
      deadPupaNumberSelectedInTrap: "999",
      noticedMosquitoInTrap: "não",
      aliveMosquitoNumberSelectedInTrap: "999",
      deadMosquitoNumberSelectedInTrap: "999",
      wasSampleCreated: "não",
      sampleNumber: "",
      sampleType: "",
    };
  }

  const toggleSelectModal = () => {
    setIsSelectModalOpen(!isSelectModalOpen);

    if (!isSelectModalOpen) setSelectedInputData(null);
  };

  const handleSelectModalClose = () => {
    setIsSelectModalOpen(false);
    setSelectedInputData(null);
    setSelectValueInsideModal("");
  };

  const handleInputChange = (inputName, inputValue, rowIndex) => {
    const newTableRow = [...tableRowsData];
    newTableRow[rowIndex][inputName] = inputValue;

    setTableRowsData(newTableRow);
  };

  const handleRepeatAddress = () => {
    if (tableRowsData.length >= MAX_ROWS_PER_FORM) {
      toast.error("Não é possível adicionar mais que 20 linhas por formulário");
      return;
    }

    setTableRowsData((previousRows) => {
      const lastRow = previousRows[previousRows.length - 1];
      const newRow = { ...getTableRowsObject(), address: lastRow.address };
      return [...previousRows, newRow];
    });
  };

  const handleAddNewAddress = () => {
    if (tableRowsData.length >= MAX_ROWS_PER_FORM) {
      toast.error("Não é possível adicionar mais que 20 linhas por formulário");
      return;
    }

    setTableRowsData((previousRows) => {
      return [...previousRows, { ...getTableRowsObject() }];
    });
  };

  const handleDeleteRow = (rowIndex) => {
    if (tableRowsData.length === MIN_ROWS_PER_FORM) {
      toast.error("Não é possível ter menos que uma linha por formulário");
      return;
    }

    setTableRowsData((previousRows) => {
      const newTableRows = [...previousRows];
      newTableRows.splice(rowIndex, 1);

      return newTableRows;
    });
  };

  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === "r") {
      event.preventDefault();
      handleRepeatAddress();

      return;
    }

    if (event.ctrlKey && event.key === "a") {
      event.preventDefault();
      handleAddNewAddress();

      return;
    }
  };

  const handleSelectClick = (inputName, inputValue, indexRow) => {
    setSelectedInputData({
      inputName,
      inputValue,
      indexRow,
    });

    setIsSelectModalOpen(true);
  };

  const handleSelectChangeInsideModal = (newValue) => {
    setSelectValueInsideModal(newValue);
    handleInputChange(
      selectedInputData.inputName,
      newValue,
      selectedInputData.indexRow
    );
  };

  const handleSearchTrapClick = async (trapNumber, rowIndex) => {
    if (!trapNumber && !rowIndex) return;

    const { data, status } = await getTrapByNumber(trapNumber);

    if (status !== 200) {
      toast.error(
        `A armadilha de número '${trapNumber}' não foi encontrado na base de dados. Verifique se o número está correto e tente novamente`
      );

      return;
    }

    if (data.trapTypeName !== getTrapTypeName["ADI"]) {
      toast.error(
        `A armadilha de número '${trapNumber}' existe na base de dados, entretanto, trata-se de uma ${
          getTrapTypeSurname[data.trapTypeName]
        }. Esse formulário é para a manutenção somente de ADI`
      );

      return;
    } else {
      toast.success(
        `Dados da armadilha ADI de número '${trapNumber}' encontrados com sucesso.`
      );
    }

    const [trapAddressName, trapAddressNumber] = data.address.split(",");

    handleInputChange("address", trapAddressName, rowIndex);
    handleInputChange("addressNumber", trapAddressNumber, rowIndex);
  };

  const checkIfDependentSelectsAreEmpty = (tableRows) => {
    let errorMessage = "";

    for (let i = 0; i < tableRows.length; i++) {
      const {
        isTrapInUsableConditions,
        whyIsTrapInUnusableConditions,
        whyIsTrapInUnusableConditionsSelectedOptions,
        visitStatus,
        trapWasRead,
        trapWasReadSelectedOptions,
        wereFoundLarvaeInTrap,
        aliveLarvaeNumberSelectedInTrap,
        deadLarvaeNumberSelectedInTrap,
        wereFoundPupaInTrap,
        alivePupaNumberSelectedInTrap,
        deadPupaNumberSelectedInTrap,
        noticedMosquitoInTrap,
        aliveMosquitoNumberSelectedInTrap,
        deadMosquitoNumberSelectedInTrap,
        wasSampleCreated,
        sampleNumber,
        sampleType,
      } = tableRows[i];

      if (
        visitStatus === "Fechado" ||
        visitStatus === "Recusa" ||
        visitStatus === "Endereço Não Encontrado"
      )
        return false;

      if (
        isTrapInUsableConditions === "não" &&
        whyIsTrapInUnusableConditions === EMPTY_FIELD
      ) {
        errorMessage = `Informe o motivo de a ARMADILHA NÃO estar em CONDIÇÕES de uso na linha ${
          i + 1
        }`;
        break;
      }
      if (
        whyIsTrapInUnusableConditions === "Peça com defeito" &&
        whyIsTrapInUnusableConditionsSelectedOptions === EMPTY_FIELD
      ) {
        errorMessage = `Informe a peça que está com DEFEITO na ARMADILHA na linha ${
          i + 1
        }`;
        break;
      }
      if (trapWasRead === "sim" && trapWasReadSelectedOptions === "") {
        errorMessage = `Informe a atividade de MANUTENÇÃO realizada na ARMADILHA na linha ${
          i + 1
        }`;
        break;
      }
      if (
        (wereFoundLarvaeInTrap === "sim" &&
          aliveLarvaeNumberSelectedInTrap === "999") ||
        (wereFoundLarvaeInTrap === "sim" &&
          deadLarvaeNumberSelectedInTrap === "999")
      ) {
        errorMessage = `Informe a QUANTIDADE aproximada de LARVAS VIVAS e MORTAS na linha ${
          i + 1
        }`;
        break;
      }
      if (
        (wereFoundPupaInTrap === "sim" &&
          alivePupaNumberSelectedInTrap === "999") ||
        (wereFoundPupaInTrap === "sim" &&
          deadPupaNumberSelectedInTrap === "999")
      ) {
        errorMessage = `Informe a QUANTIDADE aproximada de PUPAS VIVAS e MORTAS na linha ${
          i + 1
        }`;
        break;
      }
      if (
        (noticedMosquitoInTrap === "sim" &&
          aliveMosquitoNumberSelectedInTrap === "999") ||
        (noticedMosquitoInTrap === "sim" &&
          deadMosquitoNumberSelectedInTrap === "999")
      ) {
        errorMessage = `Informe a QUANTIDADE aproximada de MOSQUITOS VIVOS e MORTOS na linha ${
          i + 1
        }`;
        break;
      }
      if (
        (wasSampleCreated === "sim" && sampleNumber === EMPTY_FIELD) ||
        (wasSampleCreated === "sim" && sampleType === EMPTY_FIELD)
      ) {
        errorMessage = `Informe o NÚMERO e o TIPO da AMOSTRA ${i + 1}`;
        break;
      }
    }

    return errorMessage !== "" ? errorMessage : false;
  };

  const checkIfTrapAreAlreadyInstalled = async (trapNumber) => {
    const { data: responseData, status: responseStatus } =
      await getTrapByNumber(trapNumber);

    return responseStatus === 200 ? responseData : null;
  };

  const checkIfAtLeastOneTrapIsNotInstalled = async (tableRows) => {
    let trapNotInstalledError;

    for (const tableRow of tableRows) {
      const { trapNumber } = tableRow;

      const isTrapInstalled = await checkIfTrapAreAlreadyInstalled(trapNumber);

      if (!isTrapInstalled) {
        trapNotInstalledError = `A ARMADILHA de número '${trapNumber}' NÃO está INSTALADA. Verifique se o número preenchido está correto ou faça sua instalação antes de realizar a desinstalação.`;
        break;
      }

      if (
        isTrapInstalled &&
        isTrapInstalled.trapTypeName !== getTrapTypeName["ADI"]
      ) {
        trapNotInstalledError = `A ARMADILHA de número '${trapNumber}' está INSTALADA, porém, ela se trata de uma ${
          getTrapTypeSurname[isTrapInstalled.trapTypeName]
        }. Não será possível prosseguir com o salvamento dos dados enquanto essa armadilha estiver no formulário. Considere excluir a linha.`;
        break;
      }
    }

    return trapNotInstalledError;
  };

  const handleSubmit = async () => {
    let occurredErrorWhileSubmitingForm = false;

    const requiredFieldsErrorMessage = checkIfRequiredFieldsAreEmpty(
      headerInputs,
      tableRowsData,
      organizationId
    );
    const dependentSelectsErrorMessage =
      checkIfDependentSelectsAreEmpty(tableRowsData);

    if (requiredFieldsErrorMessage) {
      toast.error(requiredFieldsErrorMessage);
      return;
    }

    if (dependentSelectsErrorMessage) {
      toast.error(dependentSelectsErrorMessage);
      return;
    }

    const trapNotInstalledErrorMessage =
      await checkIfAtLeastOneTrapIsNotInstalled(tableRowsData);

    if (trapNotInstalledErrorMessage) {
      toast.error(trapNotInstalledErrorMessage);
      return;
    }

    setIsSubmitingForm(true);

    for (const tableRow of tableRowsData) {
      try {
        const { geometry } = await getAddressInformations(
          tableRow.address,
          tableRow.addressNumber
        );
        const latitude =
          geometry && geometry.location.lat
            ? geometry.location.lat.toFixed(7)
            : null;
        const longitude =
          geometry && geometry.location.lng
            ? geometry.location.lng.toFixed(7)
            : null;

        const trapInformations = {
          latitude,
          longitude,
          trapName: "ADI",
          install: false,
          maintenance: true,
          uninstall: false,
        };

        const entomologicVigilanceObject =
          getEntomologicVigilanceObjectForMaintenanceAdi(
            tableRow,
            headerInputs,
            trapInformations
          );

        const { data: entomologicVigilanceRequestResponse } =
          await postVigilanciaEntomologica(entomologicVigilanceObject);

        const trapToPostObject = getTrapToPostObject(
          tableRow,
          headerInputs,
          trapInformations
        );

        const { data: trapRequestReponse } = await postTrap(trapToPostObject);

        const trapHistoryObject = getTrapHistoryObject(
          tableRow,
          headerInputs,
          trapInformations,
          trapRequestReponse.id,
          entomologicVigilanceRequestResponse.vigilanciaId
        );

        await postTrapHistory(trapHistoryObject);
      } catch (error) {
        occurredErrorWhileSubmitingForm = true;
        console.error(error);

        setIsSubmitingForm(false);
        toast.error(
          "Ocorreu um erro inesperado ao enviar os dados do formulário. Salve as digitações clicando no botão 'Baixar CSV das digitações' e entre em contato com nossa equipe"
        );
      }
    }

    if (!occurredErrorWhileSubmitingForm) {
      toast.success("Dados do formulário enviados com sucesso.");
      setTableRowsData([{ ...getTableRowsObject() }]);
      setHeaderInputs({
        uvisName: "",
        administrativeDistrict: "",
        environmentalSector: "",
        block: "",
        teamsAndEmployees: [],
        activityDate: "",
      });
    }

    setIsSubmitingForm(false);
  };

  const getSelectName = {
    propertyType: "Selecione o tipo de imóvel",
    visitStatus: "Selecione o status da visita",
    whyIsTrapInUnusableConditions:
      "Se a armadilha NÃO está em condições de uso, informe o motivo",
    whyIsTrapInUnusableConditionsSelectedOptions:
      "Se o motivo for 'ACU5 - Peça com defeito', informe o componente comprometido",
    trapWasReadSelectedOptions:
      "Se foi realizada manutenção da Armadilha, informe a(s) atividade(s)",
    aliveLarvaeNumberSelectedInTrap:
      "Se foi encontrada Larva na armadilha, selecione a quantidade aproximada de Larvas VIVAS",
    deadLarvaeNumberSelectedInTrap:
      "Se foi encontrada Larva na armadilha, selecione a quantidade aproximada de Larvas MORTAS",
    aliveMosquitoNumberSelectedInTrap:
      "Se foi encontrado Mosquito na armadilha, selecione a quantidade aproximada de mosquitos VIVOS",
    deadMosquitoNumberSelectedInTrap:
      "Se foi encontrado Mosquito na armadilha, selecione a quantidade aproximada de mosquitos MORTOS",
    sampleType: "Se foi gerada amostra, selecione seu tipo",
  };

  const getSelectOptions = {
    propertyType: [
      {
        label: "Selecione uma opção",
        value: "",
      },
      {
        label: "R - Residência",
        value: "Residência",
      },
      {
        label: "C - Comércio",
        value: "Comércio",
      },
      {
        label: "TB - Terreno Baldio",
        value: "Terreno Baldio",
      },
      {
        label: "PE - Ponto Estratégico",
        value: "Ponto Estratégico",
      },
      {
        label: "PP - Próprio Público",
        value: "Próprio Público",
      },
      {
        label: "IE - Imóvel Especial",
        value: "Imóvel Especial",
      },
      {
        label: "VP - Via Pública",
        value: "Via Pública",
      },
      {
        label: "O - Outros",
        value: "Outros",
      },
    ],
    visitStatus: [
      {
        label: "Selecione uma opção",
        value: "",
      },
      {
        label: "T - Trabalhado",
        value: "Aberto",
      },
      {
        label: "F - Fechado",
        value: "Fechado",
      },
      {
        label: "R - Recusa",
        value: "Recusa",
      },
      {
        label: "O - Orientação",
        value: "Orientação",
      },
      {
        label: "EN - Endereço Não Encontrado",
        value: "Endereço Não Encontrado",
      },
    ],
    whyIsTrapInUnusableConditions: [
      {
        label: "Selecione uma opção",
        value: "",
      },
      {
        label: "FPA - Faltam partes da armadilha",
        value: "Faltam partes da armadilha",
      },
      {
        label: "ANL - Armadilha não localizada no local",
        value: "Armadilha não localizada no local",
      },
      {
        label: "E - Extraviada",
        value: "Extraviada",
      },
      {
        label: "AD - Armadilha danificada",
        value: "Armadilha danificada",
      },
      {
        label: "PD - Peça com defeito",
        value: "Peça com defeito",
      },
    ],
    whyIsTrapInUnusableConditionsSelectedOptions: [
      {
        label: "Selecione uma opção",
        value: "",
      },
      {
        label: "IT - Indicador de tempo",
        value: "Indicador de tempo",
      },
      {
        label: "T - Tampa",
        value: "Tampa",
      },
      {
        label: "F - Flutuador",
        value: "Flutuador",
      },
      {
        label: "B - Balde",
        value: "Balde",
      },
    ],
    trapWasReadSelectedOptions: [
      /* {
        label: "Preenchido",
        value: "Preenchido",
      }, */
      {
        label: "RL - Realizada limpeza",
        value: "Realizada limpeza",
      },
      {
        label: "TA - Trocada a água",
        value: "Trocada a água",
      },
      {
        label: "TR - Trocado Refil",
        value: "Trocado Refil",
      },
      {
        label: "AA - Adicionada água",
        value: "Adicionada água",
      },
      {
        label: "ED - Etiqueta deteriorada",
        value: "Etiqueta deteriorada",
      },
      {
        label: "TP - Troca de Peça",
        value: "Troca de Peça",
      },
    ],
    aliveLarvaeNumberSelectedInTrap: [
      {
        label: "Selecione uma opção",
        value: "999",
      },
      {
        label: "LV1 - 1 a 20",
        value: "9",
      },
      {
        label: "LV2 - 21 a 50",
        value: "10",
      },
      {
        label: "LV3 - 51 a 100",
        value: "11",
      },
      {
        label: "LV4 - Mais de 100",
        value: "12",
      },
    ],
    deadLarvaeNumberSelectedInTrap: [
      {
        label: "Selecione uma opção",
        value: "999",
      },
      {
        label: "LM1 - 1 a 20",
        value: "9",
      },
      {
        label: "LM2 - 21 a 50",
        value: "10",
      },
      {
        label: "LM3 - 51 a 100",
        value: "11",
      },
      {
        label: "LM4 - Mais de 100",
        value: "12",
      },
    ],
    alivePupaNumberSelectedInTrap: [
      {
        label: "Selecione uma opção",
        value: "999",
      },
      {
        label: "PV1 - 1 a 10",
        value: "5",
      },
      {
        label: "PV2 - 11 a 20",
        value: "6",
      },
      {
        label: "PV3 - 21 a 50",
        value: "7",
      },
      {
        label: "PV4 - Mais de 50",
        value: "8",
      },
    ],
    deadPupaNumberSelectedInTrap: [
      {
        label: "Selecione uma opção",
        value: "999",
      },
      {
        label: "PM1 - 1 a 10",
        value: "5",
      },
      {
        label: "PM2 - 11 a 20",
        value: "6",
      },
      {
        label: "PM3 - 21 a 50",
        value: "7",
      },
      {
        label: "PM4 - Mais de 50",
        value: "8",
      },
    ],
    aliveMosquitoNumberSelectedInTrap: [
      {
        label: "Selecione uma opção",
        value: "999",
      },
      {
        label: "MV1 - 1 a 5",
        value: "1",
      },
      {
        label: "MV2 - 6 a 10",
        value: "2",
      },
      {
        label: "MV3 - 11 a 20",
        value: "3",
      },
      {
        label: "MV4 - Mais de 20",
        value: "4",
      },
    ],
    deadMosquitoNumberSelectedInTrap: [
      {
        label: "Selecione uma opção",
        value: "999",
      },
      {
        label: "MM1 - 1 a 5",
        value: "1",
      },
      {
        label: "MM2 - 6 a 10",
        value: "2",
      },
      {
        label: "MM3 - 11 a 20",
        value: "3",
      },
      {
        label: "MM4 - Mais de 20",
        value: "4",
      },
    ],
    sampleType: [
      {
        label: "Selecione uma opção",
        value: "",
      },
      {
        label: "AM1 - Água",
        value: "Água",
      },
      {
        label: "AM2 - Larva",
        value: "Larva",
      },
      {
        label: "AM3 - Pupa",
        value: "Pupa",
      },
      {
        label: "AM4 - Larva e Pupa",
        value: "Larva e Pupa",
      },
    ],
  };

  const getTrapTypeName = {
    ADI: "armadilhaDisseminadoraInseticida",
    OVITRAMPA: "armadilhaOvos",
    "BG-GAT": "armadilhaMosquitoAdulto2",
  };

  const getTrapTypeSurname = {
    armadilhaDisseminadoraInseticida: "ADI",
    armadilhaOvos: "OVITRAMPA",
    armadilhaMosquitoAdulto2: "BG-GAT",
  };

  return (
    <>
      <Modal
        size="lg"
        fade
        isOpen={isSelectModalOpen}
        toggle={() => toggleSelectModal()}
        onClosed={() => handleSelectModalClose()}
      >
        <ModalHeader>
          <span>Dados da visita</span>
        </ModalHeader>
        <ModalBody>
          {selectedInputData && (
            <>
              <h1 style={{ textAlign: "center" }}>
                {getSelectName[selectedInputData.inputName]}
              </h1>
              <Select
                onChange={(e) => handleTrapWasReadChange(e, selectedInputData.indexRow)}
                value={tableRowsData[selectedInputData.indexRow].trapWasReadOptions}
                options={getSelectOptions.trapWasReadSelectedOptions}
                placeholder="Selecione as manutenções"
                isMulti={true}
                allowSelectAll={false}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                isClearable={true}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="row">
            <div className="col-6" style={{ maxWidth: "100%" }}>
              <button
                className="btn btn-primary"
                onClick={() => handleSelectModalClose()}
                style={{ cursor: "pointer" }}
              >
                FECHAR
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
      <MaintenanceAdiFormPrintModal
        isModalOpen={isPrintFormModalOpen}
        setIsModalOpen={setIsPrintFormModalOpen}
        trapTypeName={"ADI"}
      />
      <HeaderFormInputs
        headerInputs={headerInputs}
        handleHeaderInputsChange={handleHeaderInputsChange}
        employeesOptions={employeesOptions}
        organizationId={organizationId}
      />
      <Row className="mt-4" style={{ justifyContent: "center" }}>
        <Col className="col-3">
          <div className="filter-container container">
            <button
              onClick={() => setIsPrintFormModalOpen(true)}
              className="btn btn-primary"
            >
              <i className="fa fa-file" style={{ marginRight: ".5rem" }}></i>
              Gerar Formulário Vazio
            </button>
          </div>
        </Col>
        <Col className="col-3 ">
          <CsvDownloadButton
            className="btn btn-primary"
            data={tableRowsData}
            filename={"manutencao"}
          >
            <i className="fa fa-download" /> Baixar CSV das digitações
          </CsvDownloadButton>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <div>
            <table
              className="table_form_vistoria"
              style={{
                borderCollapse: "collapse",
                width: "100%",
                fontSize: 13,
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: "#4f8bb3",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th
                    style={{ width: "10px" }}
                    rowSpan="2"
                    className="vertical-header"
                  >
                    ORDEM
                  </th>
                  <th colSpan="5">ENDEREÇO</th>
                  <th colSpan="2">
                    RESPONSÁVEL
                    <br /> (Morador)
                  </th>
                  <th colSpan="20">ARMADILHA</th>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#4f8bb3",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>(*)Número da armadilha</th>
                  <th style={{ minWidth: "150px" }}>(*)Logradouro</th>
                  <th>(*)Número</th>
                  <th>Complemento</th>
                  <th>
                    (*)Tipo do <br /> Imóvel
                  </th>
                  <th>Nome</th>
                  <th>Telefone</th>
                  <th className="vertical-header align-left">
                    Selecione o status da visita
                  </th>
                  <th className="vertical-header align-left">
                    A armadilha está em condições de uso? <br />
                  </th>
                  <th className="vertical-header align-left">
                    Informe o motivo de NÃO estar em condições de uso
                  </th>
                  <th className="vertical-header align-left">
                    Se for "Peça com defeito", informe o componente comprometido
                  </th>
                  <th className="vertical-header align-left">
                    Foi realizada manutenção da Armadilha?
                  </th>
                  <th className="vertical-header align-left">
                    Informe a manutenção realizada
                  </th>
                  <th className="vertical-header align-left">
                    Foi encontrada Larva na armadilha?
                  </th>
                  <th className="vertical-header align-left">
                    Quantidade aproximada de larvas VIVAS
                  </th>
                  <th className="vertical-header align-left">
                    Quantidade aproximada de larvas MORTAS
                  </th>
                  <th className="vertical-header align-left">
                    Foi encontrada Pupa na armadilha?
                  </th>
                  <th className="vertical-header align-left">
                    Quantidade aproximada de pupas VIVAS
                  </th>
                  <th className="vertical-header align-left">
                    Quantidade aproximada de pupas MORTAS
                  </th>
                  <th className="vertical-header align-left">
                    Foi encontrado Mosquito na armadilha? <br />
                  </th>
                  <th className="vertical-header align-left">
                    Quantidade aproximada de mosquitos VIVOS
                  </th>
                  <th className="vertical-header align-left">
                    Quantidade aproximada de mosquitos MORTOS
                  </th>
                  <th className="vertical-header align-left">
                    Foi gerada amostra?
                  </th>
                  <th className="vertical-header align-left">
                    Informe o número da amostra
                  </th>
                  <th className="vertical-header align-left">
                    Selecione o tipo de amostra
                  </th>
                  <th
                    rowSpan="2"
                    className="vertical-header"
                    style={{ width: "50px" }}
                  >
                    EXCLUIR
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableRowsData.map((row, index) => (
                  <tr
                    data-testid={`maintenance${index + 1}`}
                    key={index}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "lightgrey" : "darkgrey",
                    }}
                  >
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        data-testid={`rowIndex${index + 1}`}
                        value={index + 1}
                        style={{ textAlign: "center" }}
                      />
                    </td>
                    <td>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          className="input_coluna"
                          style={{ width: "140px" }}
                          type="text"
                          value={row.trapNumber}
                          onChange={({ target }) =>
                            handleInputChange("trapNumber", target.value, index)
                          }
                        />
                        <button
                          className="btn btn-primary"
                          style={{ padding: "5px", width: "50px" }}
                          onClick={() =>
                            handleSearchTrapClick(row.trapNumber, index)
                          }
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </td>
                    <td>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          className="input_coluna"
                          type="text"
                          data-testid={`addressInput${index + 1}`}
                          value={row.address}
                          onChange={({ target }) =>
                            handleInputChange("address", target.value, index)
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.addressNumber}
                        onChange={({ target }) =>
                          handleInputChange(
                            "addressNumber",
                            target.value,
                            index
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.addressComplement}
                        onChange={({ target }) =>
                          handleInputChange(
                            "addressComplement",
                            target.value,
                            index
                          )
                        }
                      />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <select
                        name="propertyType"
                        id="propertyType"
                        value={row.propertyType}
                        className="select"
                        onClick={({ target }) => {
                          if (showModal)
                            handleSelectClick(
                              "propertyType",
                              target.value,
                              index
                            );
                        }}
                        onChange={({ target }) => {
                          if (!showModal)
                            handleInputChange(
                              "propertyType",
                              target.value,
                              index
                            );
                        }}
                      >
                        <option value=""></option>
                        <option value="Residência">R - Residência</option>
                        <option value="Comércio">C - Comércio</option>
                        <option value="Terreno Baldio">
                          TB - Terreno Baldio
                        </option>
                        <option value="Ponto Estratégico">
                          PE - Ponto Estratégico
                        </option>
                        <option value="Próprio Público">
                          PP - Próprio Público
                        </option>
                        <option value="Imóvel Especial">
                          IE - Imóvel Especial
                        </option>
                        <option value="Via Pública">VP - Via Pública</option>
                        <option value="Outros">O - Outros</option>
                      </select>
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.responsablePersonName}
                        onChange={({ target }) =>
                          handleInputChange(
                            "responsablePersonName",
                            target.value,
                            index
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.responsablePersonCellPhoneNumber}
                        onChange={({ target }) =>
                          handleInputChange(
                            "responsablePersonCellPhoneNumber",
                            target.value,
                            index
                          )
                        }
                      />
                    </td>
                    <td>
                      <select
                        name="visitStatus"
                        id="visitStatus"
                        value={row.visitStatus}
                        className="select"
                        onClick={({ target }) => {
                          if (showModal)
                            handleSelectClick(
                              "visitStatus",
                              target.value,
                              index
                            );
                        }}
                        onChange={({ target }) => {
                          if (!showModal)
                            handleInputChange(
                              "visitStatus",
                              target.value,
                              index
                            );
                        }}
                      >
                        <option value=""></option>
                        <option value="Aberto">T - Trabalhado</option>
                        <option value="Fechado">F - Fechado</option>
                        <option value="Recusa">R - Recusa</option>
                        <option value="Orientação">O - Orientação</option>
                        <option value="Endereço Não Encontrado">
                          EN - Endereço Não Encontrado
                        </option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="isTrapInUsableConditions"
                        id="isTrapInUsableConditions"
                        value={row.isTrapInUsableConditions}
                        onChange={({ target }) =>
                          handleInputChange(
                            "isTrapInUsableConditions",
                            target.value,
                            index
                          )
                        }
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado"
                            ? true
                            : false
                        }
                        className="select"
                      >
                        <option value="sim">S</option>
                        <option value="não">N</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="whyIsTrapInUnusableConditions"
                        id="whyIsTrapInUnusableConditions"
                        value={row.whyIsTrapInUnusableConditions}
                        className="select"
                        onClick={({ target }) => {
                          if (showModal)
                            handleSelectClick(
                              "whyIsTrapInUnusableConditions",
                              target.value,
                              index
                            );
                        }}
                        onChange={({ target }) => {
                          if (!showModal)
                            handleInputChange(
                              "whyIsTrapInUnusableConditions",
                              target.value,
                              index
                            );
                        }}
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado" ||
                          tableRowsData[index].isTrapInUsableConditions ===
                            "sim"
                            ? true
                            : false
                        }
                      >
                        <option value=""></option>
                        <option value="Faltam partes da armadilha">
                          FPA - Faltam partes da armadilha
                        </option>
                        <option value="Armadilha não localizada no local">
                          ANL - Armadilha não localizada no local
                        </option>
                        <option value="Extraviada">E - Extraviada</option>
                        <option value="Armadilha danificada">
                          AD - Armadilha danificada
                        </option>
                        <option value="Peça com defeito">
                          PD - Peça com defeito
                        </option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="whyIsTrapInUnusableConditionsSelectedOptions"
                        id="whyIsTrapInUnusableConditionsSelectedOptions"
                        value={row.whyIsTrapInUnusableConditionsSelectedOptions}
                        className="select"
                        onClick={({ target }) => {
                          if (showModal)
                            handleSelectClick(
                              "whyIsTrapInUnusableConditionsSelectedOptions",
                              target.value,
                              index
                            );
                        }}
                        onChange={({ target }) => {
                          if (!showModal)
                            handleInputChange(
                              "whyIsTrapInUnusableConditionsSelectedOptions",
                              target.value,
                              index
                            );
                        }}
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado" ||
                          tableRowsData[index].whyIsTrapInUnusableConditions !==
                            "Peça com defeito"
                            ? true
                            : false
                        }
                      >
                        <option value=""></option>
                        <option value="Indicador de tempo">
                          IT - Indicador de tempo
                        </option>
                        <option value="Tampa">T - Tampa</option>
                        <option value="Flutuador">F - Flutuador</option>
                        <option value="Balde">B - Balde</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="trapWasRead"
                        id="trapWasRead"
                        value={row.trapWasRead}
                        onChange={({ target }) =>
                          handleInputChange("trapWasRead", target.value, index)
                        }
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado"
                            ? true
                            : false
                        }
                        className="select"
                      >
                        <option value="sim">S</option>
                        <option value="não">N</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="trapWasReadSelectedOptions"
                        id="trapWasReadSelectedOptions"
                        value={row.trapWasReadSelectedOptions}
                        style={{width: "90px"}}
                        className="select"
                        onClick={({ target }) => {
                          handleSelectClick(
                            "trapWasReadSelectedOptions",
                            target.value,
                            index
                          );
                        }}
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado" ||
                          tableRowsData[index].trapWasRead === "não"
                            ? true
                            : false
                        }
                      >
                        <option value=""></option>
                        <option value="Preenchido">
                          Preenchido
                        </option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="wereFoundLarvaeInTrap"
                        id="wereFoundLarvaeInTrap"
                        value={row.wereFoundLarvaeInTrap}
                        onChange={({ target }) =>
                          handleInputChange(
                            "wereFoundLarvaeInTrap",
                            target.value,
                            index
                          )
                        }
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado"
                            ? true
                            : false
                        }
                        className="select"
                      >
                        <option value="sim">S</option>
                        <option value="não">N</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="aliveLarvaeNumberSelectedInTrap"
                        id="aliveLarvaeNumberSelectedInTrap"
                        value={row.aliveLarvaeNumberSelectedInTrap}
                        className="select"
                        onClick={({ target }) => {
                          if (showModal)
                            handleSelectClick(
                              "aliveLarvaeNumberSelectedInTrap",
                              target.value,
                              index
                            );
                        }}
                        onChange={({ target }) => {
                          if (!showModal)
                            handleInputChange(
                              "aliveLarvaeNumberSelectedInTrap",
                              target.value,
                              index
                            );
                        }}
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado" ||
                          tableRowsData[index].wereFoundLarvaeInTrap === "não"
                            ? true
                            : false
                        }
                      >
                        <option value="999"></option>
                        <option value="9">LV1 - 1 a 20</option>
                        <option value="10">LV2 - 21 a 50</option>
                        <option value="11">LV3- 51 a 100</option>
                        <option value="12">LV4 - Mais de 100</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="deadLarvaeNumberSelectedInTrap"
                        id="deadLarvaeNumberSelectedInTrap"
                        value={row.deadLarvaeNumberSelectedInTrap}
                        className="select"
                        onClick={({ target }) => {
                          if (showModal)
                            handleSelectClick(
                              "deadLarvaeNumberSelectedInTrap",
                              target.value,
                              index
                            );
                        }}
                        onChange={({ target }) => {
                          if (!showModal)
                            handleInputChange(
                              "deadLarvaeNumberSelectedInTrap",
                              target.value,
                              index
                            );
                        }}
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado" ||
                          tableRowsData[index].wereFoundLarvaeInTrap === "não"
                            ? true
                            : false
                        }
                      >
                        <option value="999"></option>
                        <option value="9">LM1 - 1 a 20</option>
                        <option value="10">LM2 - 21 a 50</option>
                        <option value="11">LM3- 51 a 100</option>
                        <option value="12">LM4 - Mais de 100</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="wereFoundPupaInTrap"
                        id="wereFoundPupaInTrap"
                        value={row.wereFoundPupaInTrap}
                        onChange={({ target }) =>
                          handleInputChange(
                            "wereFoundPupaInTrap",
                            target.value,
                            index
                          )
                        }
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado"
                            ? true
                            : false
                        }
                        className="select"
                      >
                        <option value="sim">S</option>
                        <option value="não">N</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="alivePupaNumberSelectedInTrap"
                        id="alivePupaNumberSelectedInTrap"
                        value={row.alivePupaNumberSelectedInTrap}
                        className="select"
                        onClick={({ target }) => {
                          if (showModal)
                            handleSelectClick(
                              "alivePupaNumberSelectedInTrap",
                              target.value,
                              index
                            );
                        }}
                        onChange={({ target }) => {
                          if (!showModal)
                            handleInputChange(
                              "alivePupaNumberSelectedInTrap",
                              target.value,
                              index
                            );
                        }}
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado" ||
                          tableRowsData[index].wereFoundPupaInTrap === "não"
                            ? true
                            : false
                        }
                      >
                        <option value="999"></option>
                        <option value="5">PV1 - 1 a 10</option>
                        <option value="6">PV2 - 11 a 20</option>
                        <option value="7">PV3 - 21 a 50</option>
                        <option value="8">PV4 - Mais de 50</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="deadPupaNumberSelectedInTrap"
                        id="deadPupaNumberSelectedInTrap"
                        value={row.deadPupaNumberSelectedInTrap}
                        className="select"
                        onClick={({ target }) => {
                          if (showModal)
                            handleSelectClick(
                              "deadPupaNumberSelectedInTrap",
                              target.value,
                              index
                            );
                        }}
                        onChange={({ target }) => {
                          if (!showModal)
                            handleInputChange(
                              "deadPupaNumberSelectedInTrap",
                              target.value,
                              index
                            );
                        }}
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado" ||
                          tableRowsData[index].wereFoundPupaInTrap === "não"
                            ? true
                            : false
                        }
                      >
                        <option value="999"></option>
                        <option value="5">PM1 - 1 a 10</option>
                        <option value="6">PM2 - 11 a 20</option>
                        <option value="7">PM3 - 21 a 50</option>
                        <option value="8">PM4 - Mais de 50</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="noticedMosquitoInTrap"
                        id="noticedMosquitoInTrap"
                        value={row.noticedMosquitoInTrap}
                        onChange={({ target }) =>
                          handleInputChange(
                            "noticedMosquitoInTrap",
                            target.value,
                            index
                          )
                        }
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado"
                            ? true
                            : false
                        }
                        className="select"
                      >
                        <option value="sim">S</option>
                        <option value="não">N</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="aliveMosquitoNumberSelectedInTrap"
                        id="aliveMosquitoNumberSelectedInTrap"
                        value={row.aliveMosquitoNumberSelectedInTrap}
                        className="select"
                        onClick={({ target }) => {
                          if (showModal)
                            handleSelectClick(
                              "aliveMosquitoNumberSelectedInTrap",
                              target.value,
                              index
                            );
                        }}
                        onChange={({ target }) => {
                          if (!showModal)
                            handleInputChange(
                              "aliveMosquitoNumberSelectedInTrap",
                              target.value,
                              index
                            );
                        }}
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado" ||
                          tableRowsData[index].noticedMosquitoInTrap === "não"
                            ? true
                            : false
                        }
                      >
                        <option value="999"></option>
                        <option value="1">MV1 - 1 a 5</option>
                        <option value="2">MV2 - 6 a 10</option>
                        <option value="3">MV3 - 11 a 20</option>
                        <option value="4">MV4 - Mais de 20</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="deadMosquitoNumberSelectedInTrap"
                        id="deadMosquitoNumberSelectedInTrap"
                        value={row.deadMosquitoNumberSelectedInTrap}
                        className="select"
                        onClick={({ target }) => {
                          if (showModal)
                            handleSelectClick(
                              "deadMosquitoNumberSelectedInTrap",
                              target.value,
                              index
                            );
                        }}
                        onChange={({ target }) => {
                          if (!showModal)
                            handleInputChange(
                              "deadMosquitoNumberSelectedInTrap",
                              target.value,
                              index
                            );
                        }}
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado" ||
                          tableRowsData[index].noticedMosquitoInTrap === "não"
                            ? true
                            : false
                        }
                      >
                        <option value="999"></option>
                        <option value="1">MM1 - 1 a 5</option>
                        <option value="2">MM2 - 6 a 10</option>
                        <option value="3">MM3 - 11 a 20</option>
                        <option value="4">MM4 - Mais de 20</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="wasSampleCreated"
                        id="wasSampleCreated"
                        value={row.wasSampleCreated}
                        onChange={({ target }) =>
                          handleInputChange(
                            "wasSampleCreated",
                            target.value,
                            index
                          )
                        }
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado"
                            ? true
                            : false
                        }
                        className="select"
                      >
                        <option value="sim">S</option>
                        <option value="não">N</option>
                      </select>
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.sampleNumber}
                        onChange={({ target }) =>
                          handleInputChange("sampleNumber", target.value, index)
                        }
                        disabled={
                          tableRowsData[index].wasSampleCreated === "não"
                            ? true
                            : false
                        }
                      />
                    </td>
                    <td>
                      <select
                        name="sampleType"
                        id="sampleType"
                        value={row.sampleType}
                        className="select"
                        onClick={({ target }) => {
                          if (showModal)
                            handleSelectClick(
                              "sampleType",
                              target.value,
                              index
                            );
                        }}
                        onChange={({ target }) => {
                          if (!showModal)
                            handleInputChange(
                              "sampleType",
                              target.value,
                              index
                            );
                        }}
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado" ||
                          tableRowsData[index].wasSampleCreated === "não"
                            ? true
                            : false
                        }
                      >
                        <option value=""></option>
                        <option value="Água">AM1 - Água</option>
                        <option value="Larva">AM2 - Larva</option>
                        <option value="Pupa">AM3 - Pupa</option>
                        <option value="Larva e Pupa">AM4 - Lurva e Pupa</option>
                      </select>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleDeleteRow(index)}
                        data-testid={`removeRowButton${index + 1}`}
                        style={{
                          padding: "5px",
                          width: "50px",
                          cursor: "pointer",
                          backgroundColor: "transparent",
                          border: "0px",
                        }}
                      >
                        <i
                          style={{
                            color: "red",
                            fontSize: "21px",
                          }}
                          className="fa fa-times"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <FooterFormButtons
              handleRepeatAddress={handleRepeatAddress}
              handleAddNewAddress={handleAddNewAddress}
              handleSubmit={handleSubmit}
              isSubmitingForm={isSubmitingForm}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h1 style={{ color: "#f75c5c" }}>
            Instruções para preenchimento do formulário
          </h1>
          <ul style={{ color: "white" }}>
            <li>
              Caso o formulário impresso NÃO possua a informação dos AGENTES que
              realizaram a MANUTENÇÃO da armadilha ou não encontre na lista de
              funcionários seu nome, selecione o funcionário "Não informado -
              RF: 13212312";
            </li>
            <li>
              Caso o formulário impresso NÃO possua o NÚMERO do ENDEREÇO,
              preencha com "S/N" no lugar do número;
            </li>
            <li>
              Caso o formulário impresso NÃO possua o TIPO DE IMÓVEL, selecione
              a opção "Outros (O)";
            </li>
            <li>
              Clicando no botão{" "}
              <i
                className="fa fa-arrow-left"
                style={{ color: "white", fontSize: "23px" }}
              ></i>{" "}
              que encontra-se no canto superior esquerdo da tela, é possível
              ocultar o menu lateral esquerdo com a listagem das telas, gerando
              mais espaço para o preenchimento e visualização das questões no
              formulário;
            </li>
            <li>
              Ao informar o número da armadilha e clicar no botão{"  "}
              <button
                className="btn btn-primary"
                style={{ padding: "5px", width: "50px" }}
              >
                <i className="fa fa-search"></i>
              </button>{" "}
              é possível buscar na base de dados as informações da armadilha.
              Caso o número informado exista, o sistema automaticamente preenche
              os dados do endereço;
            </li>
            <li>
              Apertando a combinação de teclas "CTRL + - (menos)", é possível
              reduzir o tamanho do zoom na página, gerando mais espaço para o
              preenchimento e visualização das questões no formulário;
            </li>
            <li>
              O formulário apenas aceitará a manutenção de armadilhas que já
              encontram-se instaladas na base de dados. Caso queira realizar o
              procedimento, procure pelo formulário de instalação de ADI;
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
};

export default MaintenanceAdiForm;