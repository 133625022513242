import React from "react";
import { Container, Row, Col } from "reactstrap";
//import QRCode from 'qrcode.react';
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";
import "./TicketModel.css";

const TicketModel = ({
  formatedOrganizationName,
  trapType,
  organization,
  styleTicketModel,
  divToPrintRef,
  numberInsideTicket = 1,
}) => {
  React.useEffect(() => {
    console.log("formatedOrganizationName", formatedOrganizationName);
  }, []);

  const label = () => {
    if (styleTicketModel && styleTicketModel.showOnlyNumber) {
      return <span className="numberOfTicket">{numberInsideTicket}</span>;
    } else if (styleTicketModel && styleTicketModel.pageBreakBeforeNumber) {
      return (
        <>
          {ARMADILHA_INFO[trapType].apelido} Nº
          <br />
          <span className="numberOfTicket">{numberInsideTicket}</span>
        </>
      );
    } else if (styleTicketModel && styleTicketModel.pageBreakAfterTrapType) {
      return (
        <>
          {ARMADILHA_INFO[trapType].apelido}
          <br />
          Nº <span className="numberOfTicket">{numberInsideTicket}</span>
        </>
      );
    } else {
      return (
        <>
          {ARMADILHA_INFO[trapType].apelido} Nº{" "}
          <span className="numberOfTicket">{numberInsideTicket}</span>
        </>
      );
    }
  };

  const qrCodeStyle = () => {
    var style = {};
    if (styleTicketModel) {
      style = {
        position: "absolute",
        bottom: styleTicketModel.qrCodeHeight + "px",
        zIndex: "1",
        width: "100%",
        display: "flex",
      };
    }

    if (styleTicketModel.orientation == "right") {
      style.justifyContent = "flex-end";
      style.paddingRight = styleTicketModel.qrCodeWidth + "px";
    } else if (styleTicketModel.orientation == "left") {
      style.justifyContent = "flex-start";
      style.paddingLeft = styleTicketModel.qrCodeWidth + "px";
    }

    console.log("style", style);

    return style;
  };

  const textDivStyle = () => {
    if (styleTicketModel) {
      const style = {
        position: "absolute",
        bottom: styleTicketModel.textBottom + "%",
        zIndex: "1",
      };

      if (styleTicketModel.textOrientation == "right") {
        style.left = styleTicketModel.textWidth + "%";
      } else {
        style.right = styleTicketModel.textWidth + "%";
      }

      if (styleTicketModel.textCenter) {
        style.textAlign = "center";
      }

      return style;
    }
    return {};
  };

  const textStyle = () => {
    if (styleTicketModel) {
      return {
        fontSize: styleTicketModel.textFontSize + "px",
      };
    }
    return {};
  };

  const ribeirao_adi = require("../../../assets/img/others/bg_cities/fundo_ribeiraopires_armadilhaDisseminadoraInseticida.png");
  const paulinia_adi = require("../../../assets/img/others/bg_cities/fundo_paulinia_armadilhaDisseminadoraInseticida.png");
  const goiania_adi = require("../../../assets/img/others/bg_cities/fundo_goiania_armadilhaDisseminadoraInseticida.png");
  const saopaulo_adi = require("../../../assets/img/others/bg_cities/fundo_saopaulo_armadilhaDisseminadoraInseticida.png");
  const saopaulo_ovitrampa = require("../../../assets/img/others/bg_cities/fundo_saopaulo_armadilhaOvos.png");
  const desintec_adi = require("../../../assets/img/others/bg_cities/fundo_desintec_armadilhaDisseminadoraInseticida.png");
  const ribeirao_ovitrampa = require("../../../assets/img/others/bg_cities/fundo_ribeiraopires_armadilhaOvos.png");
  const paulinia_ovitrampa = require("../../../assets/img/others/bg_cities/fundo_paulinia_armadilhaOvos.png");
  const goiania_ovitrampa = require("../../../assets/img/others/bg_cities/fundo_goiania_armadilhaOvos.png");
  const desintec_gat = require("../../../assets/img/others/bg_cities/fundo_desintec_armadilhaMosquitoAdulto2.png");

  const getImageSrc = (organizationName, trapType) => {
    const images = {
      desintec: {
        armadilhaMosquitoAdulto2: desintec_gat,
        armadilhaDisseminadoraInseticida: desintec_adi,
      },
      ribeiraopires: {
        armadilhaOvos: ribeirao_ovitrampa,
        armadilhaDisseminadoraInseticida: ribeirao_adi,
      },
      paulinia: {
        armadilhaOvos: paulinia_ovitrampa,
        armadilhaDisseminadoraInseticida: paulinia_adi,
      },
      goiania: {
        armadilhaOvos: goiania_ovitrampa,
        armadilhaDisseminadoraInseticida: goiania_adi,
      },
      saopaulo: {
        armadilhaOvos: saopaulo_ovitrampa,
        armadilhaDisseminadoraInseticida: saopaulo_adi,
      },
    };

    return images[organizationName] && images[organizationName][trapType]
      ? images[organizationName][trapType]
      : null;
  };

  const ticket = () => {
    console.log("formatedOrganizationName", formatedOrganizationName);
    const imgSrc = getImageSrc(formatedOrganizationName, trapType);

    if (imgSrc) {
      return (
        <>
          <div style={textDivStyle()}>
            <span className="text-black h3" style={textStyle()}>
              {label()}
            </span>
          </div>
          <div style={qrCodeStyle()}>
            {/* <QRCode
              value={numberInsideTicket.toString()}
              size={styleTicketModel && styleTicketModel.qrCodeSize}
              style={{
                height: styleTicketModel && styleTicketModel.qrCodeSize + "px",
                width: styleTicketModel && styleTicketModel.qrCodeSize + "px",
              }}
              level={"H"}
              includeMargin={true}
            /> */}
          </div>
          <img
            src={imgSrc}
            style={{ width: "100%" }}
            alt="Imagem da Armadilha"
          />
        </>
      );
    } else {
      return (
        <span className="h4 text-white">
          Não foi possível buscar o background, verifique se o mesmo já foi
          importado na pasta do sistema
        </span>
      );
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="etiqueta">
            <div
              ref={divToPrintRef}
              style={{
                position: "relative",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              {ticket()}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TicketModel;
