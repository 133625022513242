import React from "react";
import CardPresentational from "../../../../Presentational/CardPresentational";

const TrapsBeingInstalledCard = ({
  installedTraps,
  totalInstalledTrapNumber,
  cardCustomText,
  cardCustomInstalledTrapsNumber,
  cardCustomNumber,
  reduceCardCustomNumberFromInstalledTraps,
}) => {
  const [trapsBeingInstalledNumber, setTrapsBeingInstalledNumber] =
    React.useState(0);

  React.useEffect(() => {
    let installedTrapsYearsData;
    let lastYearInstalledTrapsNumber;

    if (!cardCustomInstalledTrapsNumber) {
      installedTrapsYearsData = installedTraps
        ? installedTraps[installedTraps.length - 1]
        : null;
      lastYearInstalledTrapsNumber = installedTrapsYearsData
        ? installedTrapsYearsData[installedTrapsYearsData.length - 1].actives
        : null;
    } else lastYearInstalledTrapsNumber = cardCustomInstalledTrapsNumber;

    if (cardCustomNumber && !reduceCardCustomNumberFromInstalledTraps)
      setTrapsBeingInstalledNumber(totalInstalledTrapNumber - cardCustomNumber);
    else if (cardCustomNumber && reduceCardCustomNumberFromInstalledTraps)
      setTrapsBeingInstalledNumber(
        totalInstalledTrapNumber -
          (lastYearInstalledTrapsNumber - cardCustomNumber)
      );
    else
      setTrapsBeingInstalledNumber(
        totalInstalledTrapNumber - lastYearInstalledTrapsNumber
      );
  }, [cardCustomInstalledTrapsNumber, cardCustomNumber]);

  return (
    <CardPresentational
      number={trapsBeingInstalledNumber}
      text={cardCustomText}
    />
  );
};

export default TrapsBeingInstalledCard;
