import React from "react";
import CardPresentational from "../../../../components/Presentational/CardPresentational";
import { Col, Row } from "reactstrap";
import CardLoadingSkeleton from "../../../../components/ui/Loading/CardLoadingSkeleton";

const GlobalTrapsNumbersCards = ({
  trapsByColorStatusData,
  organizationsData,
  selectedOrganizationData,
}) => {
  const [trapsData, setTrapsData] = React.useState(null);

  React.useEffect(() => {
    if (!trapsByColorStatusData || trapsByColorStatusData.length === 0) return;

    const { upToThirtyDays, betweenThirtyOneAndSixtyDays, afterSixtyDays } =
      trapsByColorStatusData.reduce(
        (accumulator, current) => {
          return {
            upToThirtyDays: accumulator.upToThirtyDays + current.upToThirtyDays,
            betweenThirtyOneAndSixtyDays:
              accumulator.betweenThirtyOneAndSixtyDays +
              current.betweenThirtyOneAndSixtyDays,
            afterSixtyDays: accumulator.afterSixtyDays + current.afterSixtyDays,
          };
        },
        {
          upToThirtyDays: 0,
          betweenThirtyOneAndSixtyDays: 0,
          afterSixtyDays: 0,
        }
      );

    let totalTrapsSold;

    if (selectedOrganizationData.label === "Todos")
      totalTrapsSold = organizationsData.reduce((accumulator, current) => {
        return accumulator + current.totalSoldTraps;
      }, 0);
    else {
      totalTrapsSold = selectedOrganizationData.totalSoldTraps;
    }

    const totalInstalledTraps =
      upToThirtyDays + betweenThirtyOneAndSixtyDays + afterSixtyDays;

    const totalAvailableTraps = totalTrapsSold - totalInstalledTraps;

    setTrapsData({
      totalInstalled: totalInstalledTraps,
      totalAvailable: totalAvailableTraps,
      totalSold: totalTrapsSold,
    });
  }, [trapsByColorStatusData]);

  const styles = {
    row: {
      marginLeft: 0,
      marginRight: 0,
    },
    col: {
      paddingLeft: 0,
      paddingRight: "0.2vw",
      width: "100%",
    },
  };

  return (
    <>
      {!trapsData ? (
        <Row style={styles.row}>
          <Col style={styles.col} xl={4}>
            <CardLoadingSkeleton />
          </Col>
          <Col style={styles.col} xl={4}>
            <CardLoadingSkeleton />
          </Col>
          <Col style={styles.col} xl={4}>
            <CardLoadingSkeleton />
          </Col>
        </Row>
      ) : (
        <Row style={styles.row}>
          <Col style={styles.col} xl={4}>
            <CardPresentational
              number={trapsData ? trapsData.totalInstalled : 0}
              text={"Total de ADI's instaladas"}
            />
          </Col>
          <Col style={styles.col} xl={4}>
            <CardPresentational
              number={trapsData ? trapsData.totalAvailable : 0}
              text={"Total de ADI's disponíveis para instalação"}
            />
          </Col>
          <Col style={styles.col} xl={4}>
            <CardPresentational
              number={trapsData ? trapsData.totalSold : 0}
              text={"Total de ADI's comercializadas"}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default GlobalTrapsNumbersCards;
