import React from "react";

import DataTable from "react-data-table-component";
import { Card, CardBody, CardHeader, CardText, Col, Row } from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment";
import { Button } from "react-bootstrap";
import { toPng } from "html-to-image";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import VisitDetailsModal from "../Modals/VisitDetailsModal";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import { formatTrapDataFromVisitsToExport } from "../../../../services/utils/VisitsFunctions";
import EditVisitModal from "../Modals/EditVisitModal";

const VisitsPageTable = ({
  data,
  employeesList,
  setVisitsList,
  headerText,
}) => {
  const [dataToExport, setDataToExport] = React.useState([]);
  const [formatedVisitsToTable, setFormatedVisitsToTable] = React.useState([]);
  const [generatedAt, setGeneratedAt] = React.useState("");
  const [isVisitDetailsModalOpen, setIsVisitDetailsModalOpen] =
    React.useState(false);
  const [isEditVisitModalOpen, setIsEditVisitModalOpen] = React.useState(false);
  const [selectedVisit, setSelectedVisit] = React.useState(null);

  const contentToPrintRef = React.useRef(null);

  React.useEffect(() => {
    if (
      !data ||
      data.length === 0 ||
      !employeesList ||
      employeesList.length === 0
    )
      return;

    const splitedActualDate = moment().format("DD/MM/YYYY HH:mm").split(" ");

    setGeneratedAt(`${splitedActualDate[0]} às ${splitedActualDate[1]}`);

    const formatedVisitsToTable = data.map((visit) => {
      const visitTraps = generateTrapsData(visit.armadilha);

      return {
        date: moment(visit.activityDate).format("DD/MM/YYYY"),
        fullAddress: visit.address,
        address: `${visit.address.localDoImovel} ${visit.address.logradouro}, ${visit.address.numero}`,
        username: getUserName(visit.collectorId),
        demandId: visit.demandId,
        finalTime: moment(visit.finalTime).format("HH:mm"),
        id: visit.vigilanciaId,
        initialTime: moment(visit.initialTime).format("HH:mm"),
        installedTraps: visitTraps.installedTraps,
        readedTraps: visitTraps.readedTraps,
        uninstalledTraps: visitTraps.uninstalledTraps,
        typeForm: visit.typeForm,
        midias: visit.midias,
        visitSituation: visit.visitSituation,
        latitude: visit.latitude,
        longitude: visit.longitude,
        originalVisitObject: visit,
        residentName: !visit.residents.dadosPrincipais.name
          ? ""
          : visit.residents.dadosPrincipais.name,
        residentPhone: !visit.residents.dadosPrincipais.phone
          ? ""
          : visit.residents.dadosPrincipais.phone,
        residentEmail: !visit.residents.dadosPrincipais.email
          ? ""
          : visit.residents.dadosPrincipais.email,
      };
    });

    const filteredTrapsData = {
      armadilhaDisseminadoraInseticida: [],
      armadilhaOvos: [],
      armadilhaMosquitoAdulto: [],
      armadilhaMosquitoAdulto2: [],
      estacaoDisseminadoraLarvicida: [],
    };

    data.forEach((visit) => {
      Object.keys(ARMADILHA_INFO).forEach((trapType) => {
        if (visit.armadilha[trapType]) {
          visit.armadilha[trapType].forEach((_, index) => {
            const trapData = formatTrapDataFromVisitsToExport(
              visit,
              "-",
              trapType,
              index,
              employeesList
            );

            if (trapData) filteredTrapsData[trapType].push(trapData);
          });
        }
      });
    });

    setFormatedVisitsToTable(formatedVisitsToTable);
    setDataToExport([
      ...filteredTrapsData.armadilhaDisseminadoraInseticida,
      ...filteredTrapsData.armadilhaOvos,
      ...filteredTrapsData.armadilhaMosquitoAdulto,
      ...filteredTrapsData.armadilhaMosquitoAdulto2,
      ...filteredTrapsData.estacaoDisseminadoraLarvicida,
    ]);
  }, [data, employeesList]);

  const handleDownloadComponentImage = async () => {
    try {
      if (contentToPrintRef.current === null) return;

      const imageToDownloadString = await toPng(contentToPrintRef.current, {
        quality: 1,
        pixelRatio: 2,
      });

      if (!imageToDownloadString || imageToDownloadString === "")
        throw new Error("Ocorreu um erro ao gerar a imagem do gráfico.");

      const link = document.createElement("a");
      link.download = `${headerText}GeneratedAt${generatedAt}.png`;
      link.href = imageToDownloadString;
      link.click();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  const generateTrapsData = (traps) => {
    if (!traps) return;

    const installedTraps = [];
    const readedTraps = [];
    const uninstalledTraps = [];

    Object.keys(ARMADILHA_INFO).forEach((trapType) => {
      if (traps[trapType] && traps[trapType].length > 0) {
        for (let i = 0; i < traps[trapType].length; i++) {
          const actualTrap = traps[trapType][i];

          if (!actualTrap.numeroArmadilha || actualTrap.numeroArmadilha === "")
            continue;

          const trapData = {
            number: actualTrap.numeroArmadilha,
            wasSamplesGenerated: actualTrap.amostras,
            generatedSamplesNumbers: !actualTrap.amostras
              ? []
              : actualTrap.amostrasColetadas.map(
                  ({ numeroDaAmostra }) => numeroDaAmostra
                ),
            isReadableCondition: actualTrap.isReadableCondition,
            trapType: ARMADILHA_INFO[trapType]
              ? ARMADILHA_INFO[trapType].apelido
              : "Não encontrado",
            trapTypeName: trapType,
          };

          if (actualTrap.armadilhaInstaladaNaVisita)
            installedTraps.push(trapData);

          if (actualTrap.armadilhaLidaNaVisita) readedTraps.push(trapData);

          if (actualTrap.armadilhaRetirada) uninstalledTraps.push(trapData);
        }
      }
    });

    return {
      installedTraps,
      readedTraps,
      uninstalledTraps,
    };
  };

  const generateColumnButtons = (row) => {
    return (
      <>
        <div style={{ marginRight: "5px" }}>
          <Button
            type="primary"
            onClick={() => {
              setIsVisitDetailsModalOpen(true);
              setSelectedVisit(row);
            }}
            data-tip
            data-for="viewVisit"
          >
            <i className="fa fa-search" />
          </Button>
          <ReactTooltip effect="solid" type="info" id="viewVisit">
            Visualizar detalhes
          </ReactTooltip>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => {
              setIsEditVisitModalOpen(true);
              setSelectedVisit(row);
            }}
            data-tip
            data-for="editVisit"
          >
            <i className="ni ni-ruler-pencil" />
          </Button>
          <ReactTooltip effect="solid" type="info" id="editVisit">
            Editar
          </ReactTooltip>
        </div>
      </>
    );
  };

  const getUserName = (id) => {
    if (
      id === "00000000-0000-0000-0000-000000000000" ||
      id === null ||
      id === undefined ||
      !employeesList ||
      employeesList.length === 0
    )
      return "Não encontrado";

    const userData = employeesList.find(
      (employee) => employee.employeeId === id || employee.userId === id
    );

    return userData ? userData.employeeName : "Não encontrado";
  };

  const buildVisitTypeText = (visit) => {
    if (!visit) return;

    const result = [];

    if (visit.installedTraps.length > 0) result.push("Instalação");
    if (visit.readedTraps.length > 0) result.push("Manutenção");
    if (visit.uninstalledTraps.length > 0) result.push("Desinstalação");

    return result.join(", ");
  };

  const tableColumns = [
    {
      name: "Situação",
      selector: "visitSituation",
      sortable: true,
    },
    {
      name: "Atividades na Visita",
      cell: (row) => buildVisitTypeText(row),
      sortable: true,
    },
    {
      name: "Endereço",
      selector: "address",
      sortable: true,
      grow: 2,
    },
    {
      name: "Data da Visita",
      selector: "date",
      sortable: true,
    },
    {
      name: "Início",
      selector: "initialTime",
      sortable: true,
    },
    {
      name: "Término",
      selector: "finalTime",
      sortable: true,
    },
    {
      name: "Usuário",
      selector: "username",
      sortable: true,
      grow: 2,
    },
    {
      name: "Tipo de Formulário",
      selector: "typeForm",
      sortable: true,
    },
    {
      cell: (row) => generateColumnButtons(row),
      ignoreRowClick: true,
    },
  ];

  const style = {
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    cardBody: {
      padding: "1rem",
    },
    cardHeaderText: {
      color: "black",
      fontWeight: "bold",
      fontSize: "0.97rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };

  return (
    <>
      <VisitDetailsModal
        isOpen={isVisitDetailsModalOpen}
        selectedVisit={selectedVisit}
        toggle={setIsVisitDetailsModalOpen}
      />
      <EditVisitModal
        isOpen={isEditVisitModalOpen}
        selectedVisit={selectedVisit}
        setVisitsList={setVisitsList}
        toggle={setIsEditVisitModalOpen}
      />
      <div ref={contentToPrintRef}>
        <Row className="mt-12 mb-12" style={{ paddingBottom: "1.5rem" }}>
          <Col className="mb-12 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader>
                <CardText className="mb-0 mt-0" style={style.cardHeaderText}>
                  <span>
                    <span>{headerText}</span>
                  </span>
                  <span
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: "13px", fontWeight: "normal" }}>
                      Gerado em {generatedAt}
                    </span>
                    <span>
                      <CsvDownloadButton
                        className="btn btn-primary"
                        data={dataToExport}
                        filename={`${headerText.replaceAll(
                          " ",
                          ""
                        )}${generatedAt}`}
                        style={{ width: "55px", marginRight: "0px" }}
                        data-tip
                        data-for="dataDownload"
                      >
                        <i className="fa fa-download" />
                      </CsvDownloadButton>
                      <ReactTooltip
                        effect="solid"
                        type="info"
                        id="dataDownload"
                      >
                        Baixar dados
                      </ReactTooltip>
                    </span>
                    <span>
                      <Button
                        onClick={() => handleDownloadComponentImage()}
                        color="primary"
                        data-tip
                        data-for="viewDownload"
                        style={{ width: "55px", marginRight: "0px" }}
                      >
                        <i className={"fa fa-image"} />
                      </Button>
                      <ReactTooltip
                        effect="solid"
                        type="info"
                        id="viewDownload"
                      >
                        Baixar visualização
                      </ReactTooltip>
                    </span>
                  </span>
                </CardText>
              </CardHeader>
              <CardBody style={style.cardBody}>
                <Row>
                  <Col>
                    <DataTable
                      noHeader
                      defaultSortField="name"
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                      responsive
                      columns={tableColumns}
                      data={formatedVisitsToTable}
                      noDataComponent={"Nenhum registro encontrado."}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default VisitsPageTable;
