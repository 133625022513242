import React, {useEffect, useRef, useState} from 'react'
import {
  Map,
  TileLayer,
  FeatureGroup,
  LayersControl,
  withLeaflet,
  Polygon,
  Marker,
  Popup
} from'react-leaflet';
import FullscreenControl from 'react-leaflet-fullscreen';
import PrintControlDefault  from 'react-leaflet-easyprint';
import 'react-leaflet-fullscreen/dist/styles.css';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import Control from 'react-leaflet-control';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';

const key = 'AIzaSyCdkHjfiiztTeKSSuvfauQERlAJGFONeKo';
const { BaseLayer, Overlay } = LayersControl;
const PrintControl = withLeaflet(PrintControlDefault); 

const L = require('leaflet');

const CloropleticMap = ({PolygonsGroup, PolygonsGroupEntomologico, PointsGroup,PointsGroupOperacional, PointsGroupTrap,showCloropletic = true, HeatMapGroupped = false, HeatMap = null, PointsHeatMap = null, HeatMapGroups = null, HeatMapEntomologico = null,HeatMapOperacional = null, HeatMapSinan = null,HeatMapTitle = "Mapa de Calor", HeatMapGradient = null, maxClusterRadius = 80, QuantityLegend = null, titles = null}) => {
    const [mapInitialCenter, setMapInitialCenter] = useState(null);
    const [activeLegend, setActiveLegend] = useState(null);
    const legendsOrder = useRef([]);
    const cloropleticRefs = useRef([]);
    const mapRef = useRef(null);
    useEffect(() => {
        //criando lógica para definir como centro do mapa o primeiro polígono
        if(!mapInitialCenter && PolygonsGroup && PolygonsGroup.length > 0){
            for(const index in PolygonsGroup){
                if(PolygonsGroup[index].Polygons.length > 0){
                    let firstPolygonCoordinate = PolygonsGroup[index].Polygons[0].geometry.coordinates[0][0]
                    setMapInitialCenter([firstPolygonCoordinate[1], firstPolygonCoordinate[0]]);
                    break;
                }
            }
        }

        if(!mapInitialCenter && PolygonsGroupEntomologico && PolygonsGroupEntomologico.length > 0){
            for(const index in PolygonsGroupEntomologico){
                if(PolygonsGroupEntomologico[index].Polygons.length > 0){
                    let firstPolygonCoordinate = PolygonsGroupEntomologico[index].Polygons[0].geometry.coordinates[0][0]
                    setMapInitialCenter([firstPolygonCoordinate[1], firstPolygonCoordinate[0]]);
                    break;
                }
            }
        }

        //criando lógica para definir como centro do mapa o primeiro ponto do primeiro grupo de pontos
        if(!mapInitialCenter && PointsGroup && PointsGroup.length > 0){
            if(PointsGroup[0].points.length > 0){
                let lastIndex = PointsGroup[0].points.length - 1;
                let lastPoint = PointsGroup[0].points[lastIndex].position;
                setMapInitialCenter(lastPoint);
            }
        }

        const map = mapRef.current.leafletElement;
        map.on('overlayadd', (e) => {
            if(PolygonsGroup && PolygonsGroup.length > 0){
                let cloropleticLayer = cloropleticRefs.current.find((l) => l._leaflet_id === e.layer._leaflet_id);
                if(cloropleticLayer){
                    let groupLegends = PolygonsGroup.find((g) => g.name === e.name.replace("Coropletico - ", "")).legends;
                    if(groupLegends){
                        let legendObject = {
                            group:e.name,
                            leaflet_id: e.layer._leaflet_id,
                            legends: groupLegends   
                        }
                        
                        legendsOrder.current.push(legendObject);
                        setActiveLegend(legendObject);
                    }
                }
            }
        })

        map.on('overlayadd', (e) => {
            if(PolygonsGroupEntomologico && PolygonsGroupEntomologico.length > 0){
                let cloropleticLayer = cloropleticRefs.current.find((l) => l._leaflet_id === e.layer._leaflet_id);
                if(cloropleticLayer){
                    let groupLegends = PolygonsGroupEntomologico.find((g) => g.name === e.name.replace("Coropletico - ", "")).legends;
                    if(groupLegends){
                        let legendObject = {
                            group:e.name,
                            leaflet_id: e.layer._leaflet_id,
                            legends: groupLegends   
                        }
                        
                        legendsOrder.current.push(legendObject);
                        setActiveLegend(legendObject);
                    }
                }
            }
        })

        map.on("overlayremove", (e) => {
            
            let removeIndex = legendsOrder.current.findIndex((l) => l.leaflet_id === e.layer._leaflet_id);
            if(removeIndex != -1){
                legendsOrder.current.splice(removeIndex, 1);
                let legendsRemaining = legendsOrder.current.length;
                if(legendsRemaining > 0){
                    let previousLegend = legendsOrder.current[legendsOrder.current.length -1];
                    setActiveLegend(previousLegend);
                }else{
                    setActiveLegend(null);
                }
            }
            
        })

    }, [PolygonsGroup,PolygonsGroupEntomologico, PointsGroup, PointsGroupTrap, PointsGroupOperacional])

    const renderPolygonsGroup = ({LayerTitleText = "", PolygonFillColor = true, PolygonColor = true, PolygonKey}) => (
        <LayersControl position="topright">
            {LayerTitleText !== "" && 
                <BaseLayer checked name={LayerTitleText}>
                    <TileLayer
                        attribution=''
                        url=""
                    />
                </BaseLayer>
            }
            
            {PolygonsGroup.map((Group) => {
                return (
                    <Overlay key={`${PolygonKey}-${Group.name}`} name={Group.name} >
                        <FeatureGroup fillOpacity={PolygonFillColor ? 0.4 : 0.3} name={Group.name} ref={(r) => PolygonKey === 'cloropletic-polygon' ? addCloropleticRef(r) : null}>
                            {Group.Polygons.map((polygon, index) => {
                                const coordinates = polygon.geometry.coordinates[0].map(([latitude, longitude]) => [longitude, latitude])
                                
                                return(
                                    <Polygon key={PolygonKey+index} color={PolygonColor ? polygon.color || "#03a1fc" : "#000"} opacity={PolygonFillColor ? 0.5 : 0.3} fillColor={PolygonFillColor ? polygon.color || "#03a1fc" : "transparent"} positions={coordinates} />
                                )
                            })}
                        </FeatureGroup>
                    </Overlay>
                )
            })}
        </LayersControl>
    )

    const renderPolygonsGroupEntomologico = ({LayerTitleText = "", PolygonFillColor = true, PolygonColor = true, PolygonKey}) => (
        <LayersControl position="topright">
            {LayerTitleText !== "" && 
                <BaseLayer checked name={LayerTitleText}>
                    <TileLayer
                        attribution=''
                        url=""
                    />
                </BaseLayer>
            }
            
            {PolygonsGroupEntomologico.map((Group) => {
                return (
                    <Overlay key={`${PolygonKey}-${Group.name}`} name={Group.name} >
                        <FeatureGroup fillOpacity={PolygonFillColor ? 0.4 : 0.3} name={Group.name} ref={(r) => PolygonKey === 'cloropletic-polygon' ? addCloropleticRef(r) : null}>
                            {Group.Polygons.map((polygon, index) => {
                                const coordinates = polygon.geometry.coordinates[0].map(([latitude, longitude]) => [longitude, latitude])
                                
                                return(
                                    <Polygon key={PolygonKey+index} color={PolygonColor ? polygon.color || "#03a1fc" : "#000"} opacity={PolygonFillColor ? 0.5 : 0.3} fillColor={PolygonFillColor ? polygon.color || "#03a1fc" : "transparent"} positions={coordinates} />
                                )
                            })}
                        </FeatureGroup>
                    </Overlay>
                )
            })}
        </LayersControl>
    )

    const addCloropleticRef = (el) => {
        if(el && !cloropleticRefs.current.includes(el.leafletElement)){
            cloropleticRefs.current.push(el.leafletElement);
        }
    }

    const renderPointsGroup = () => (
        PointsGroup.map((Group, index) => (
            <Overlay key={Group.name+index} name={`Pontos - ${Group.name}`} checked={Group.checked}>
                <MarkerClusterGroup>
                    {Group.points.map(({icon, position, name}, index) => {
                        const myIcon = L.icon({
                            iconUrl: icon,
                            iconSize: [20,20]
                        })

                        return(
                            <Marker key={name+index} icon={myIcon} position={position} title={name}>
                                <Popup>{name}</Popup>
                            </Marker>
                        )
                    })}
                </MarkerClusterGroup>
            </Overlay>
        ))
    )

    const renderPointsGroupTrap = () => (
        PointsGroupTrap.map((Group, index) => (
            <Overlay key={Group.name+index} name={`Pontos - ${Group.name}`} checked={Group.checked}>
                <MarkerClusterGroup>
                    {Group.points.map(({icon, position, name}, index) => {
                        const myIcon = L.icon({
                            iconUrl: icon,
                            iconSize: [20,20]
                        })

                        return(
                            <Marker key={name+index} icon={myIcon} position={position} title={name}>
                                <Popup>{name}</Popup>
                            </Marker>
                        )
                    })}
                </MarkerClusterGroup>
            </Overlay>
        ))
    )
    
    const renderPointsGroupOperacional = PointsGroupOperacional != null ? () => (
        PointsGroupOperacional.map((Group, index) => (
            <Overlay key={Group.name+index} name={Group.name} checked={Group.checked}>
                <MarkerClusterGroup>
                    {Group.points.map(({icon, position, name}, index) => {
                        const myIcon = L.icon({
                            iconUrl: icon,
                            iconSize: [20,20]
                        })

                        return(
                            <Marker key={name+index} icon={myIcon} position={position} title={name}>
                                <Popup>{name}</Popup>
                            </Marker>
                        )
                    })}
                </MarkerClusterGroup>
            </Overlay>
        ))
    ) : null;
    const buildQuantityLegend = () => {
        return(
        <Control position={"bottomleft"}>
            <div style={{ maxWidth: 250, maxHeight: 300, backgroundColor: "#fff", paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}>
                <div style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>
                    <span style={{ fontSize: '17px' }}>{QuantityLegend}</span>
                </div>
            </div>
        </Control>
        )
    }

    const buildCloropleticLegend = () => {
        if(activeLegend){
            return(
                <Control position={"bottomright"}>
                    <div style={{maxWidth:250, maxHeight:300, backgroundColor: "#fff", paddingTop:5, paddingBottom:5, paddingLeft:10, paddingRight:10}}>
                        {/* title */}
                        <div style={{width:"100%", textAlign: "center", fontWeight:"bold"}}>
                            {activeLegend.group}
                        </div>

                        {/* legends */}
                        {activeLegend.legends.map(legend => 
                            <div style={{width:"100%", fontSize:10, display:"flex", alignContent:"center", paddingBottom:2}}>
                                <div style={{width:15, height: 15, backgroundColor: legend.color, display:"inline-block", marginRight:5}}></div>
                                <span>{legend.text}</span>
                            </div>    
                        )}
                    </div>
                </Control>
            )
        }
    }

    return (
        <div id="mapid">
            <Map 
                style={{'height' : '32.3rem', "borderRadius":4}} 
                center={mapInitialCenter || [-22.848046, -43.321953]} zoom={12}
                ref={mapRef}
            >                
                <FullscreenControl 
                    position="topleft"
                    title="Tela Cheia"
                    titleCancel="Sair da Tela Cheia"
                />
                <PrintControl position="topleft" sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={false} title="Export as PNG" exportOnly />

                <LayersControl position="topright">
                    <BaseLayer checked name="Normal">
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </BaseLayer>
                    {/* <BaseLayer name="Preto e Branco">
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                        />
                    </BaseLayer> */}
                    <BaseLayer name="Satélite">
                        <ReactLeafletGoogleLayer googleMapsLoaderConf={{KEY: key}} type={'hybrid'} />
                    </BaseLayer>
                    
                </LayersControl>  
                {
                titles != null
                ?
                <>
                    <LayersControl position={"topright"}>
                        <BaseLayer checked name={titles[0]}>
                            <TileLayer
                                attribution=''
                                url=""
                            />
                        </BaseLayer>
                        {PointsGroup && renderPointsGroup()}

                        {HeatMapSinan != null ? HeatMapSinan.map((group, index) => (
                            <Overlay key={group.heatMapTitle+index} name={group.heatMapTitle}>
                                <HeatmapLayer
                                    points = {group.points}
                                    longitudeExtractor = {m=> m.position[1]}
                                    latitudeExtractor = {m=> m.position[0]}
                                    intensityExtractor={m => parseFloat(m.intensity)}
                                />
                            </Overlay>
                        )): <></>}

                        {PolygonsGroup && PolygonsGroup.map((Group) => {
                            let PolygonKey = "cloropletic-polygon";
                            let PolygonFillColor = true;
                            let PolygonColor = true;
                            return (
                                <Overlay key={`${PolygonKey}-${Group.name}`} name={`Coropletico - ${Group.name}`} >
                                    <FeatureGroup fillOpacity={PolygonFillColor ? 0.4 : 0.3} name={Group.name} ref={(r) => PolygonKey === 'cloropletic-polygon' ? addCloropleticRef(r) : null}>
                                        {Group.Polygons.map((polygon, index) => {
                                            const coordinates = polygon.geometry.coordinates[0].map(([latitude, longitude]) => [longitude, latitude])
                                            
                                            return(
                                                <Polygon key={PolygonKey+index} color={PolygonColor ? polygon.color || "#03a1fc" : "#000"} opacity={PolygonFillColor ? 0.5 : 0.3} fillColor={PolygonFillColor ? polygon.color || "#03a1fc" : "transparent"} positions={coordinates} />
                                            )
                                        })}
                                    </FeatureGroup>
                                </Overlay>
                            )
                        })}
                    </LayersControl> 
                    <LayersControl position={"topright"} >
                        <BaseLayer checked name={titles[1]}>
                            <TileLayer
                                attribution=''
                                url=""
                            />
                        </BaseLayer>
                        {PointsGroupTrap && renderPointsGroupTrap()}
                        {HeatMapEntomologico != null ? HeatMapEntomologico.map((group, index) => (
                            <Overlay key={group.heatMapTitle+index} name={group.heatMapTitle} >
                                <HeatmapLayer
                                    points = {group.points}
                                    longitudeExtractor = {m=> m.position[1]}
                                    latitudeExtractor = {m=> m.position[0]}
                                    intensityExtractor={m => parseFloat(m.intensity)}
                                />
                            </Overlay>
                        )): <></>}

                        {PolygonsGroupEntomologico && PolygonsGroupEntomologico.map((Group) => {
                            let PolygonKey = "cloropletic-polygon";
                            let PolygonFillColor = true;
                            let PolygonColor = true;
                            return (
                                <Overlay key={`${PolygonKey}-${Group.name}`} name={`Coropletico - ${Group.name}`} >
                                    <FeatureGroup fillOpacity={PolygonFillColor ? 0.4 : 0.3} name={Group.name} ref={(r) => PolygonKey === 'cloropletic-polygon' ? addCloropleticRef(r) : null}>
                                        {Group.Polygons.map((polygon, index) => {
                                            const coordinates = polygon.geometry.coordinates[0].map(([latitude, longitude]) => [longitude, latitude])
                                            
                                            return(
                                                <Polygon key={PolygonKey+index} color={PolygonColor ? polygon.color || "#03a1fc" : "#000"} opacity={PolygonFillColor ? 0.5 : 0.3} fillColor={PolygonFillColor ? polygon.color || "#03a1fc" : "transparent"} positions={coordinates} />
                                            )
                                        })}
                                    </FeatureGroup>
                                </Overlay>
                            )
                        })}
                    </LayersControl> 
                    <LayersControl position={"topright"} >
                        <BaseLayer checked name={titles[2]}>
                            <TileLayer
                                attribution=''
                                url=""
                            />
                        </BaseLayer>
                        {PointsGroupOperacional && renderPointsGroupOperacional()}
                        {HeatMapOperacional != null ? HeatMapOperacional.map((group, index) => (
                            <Overlay key={group.heatMapTitle+index} name={group.heatMapTitle}>
                                <HeatmapLayer
                                    points = {group.points}
                                    longitudeExtractor = {m=> m.position[1]}
                                    latitudeExtractor = {m=> m.position[0]}
                                    intensityExtractor={m => parseFloat(m.intensity)}
                                />
                            </Overlay>
                        )): <></>}
                    </LayersControl> 
                    
                </>
                :
                <LayersControl position={"topright"}>
                    {PointsGroup && renderPointsGroup()}
                </LayersControl>
                }
                {/* Colocando layer delimitador */}
                {PolygonsGroup && renderPolygonsGroup({
                    LayerTitleText:"Delimitador de Áreas",
                    PolygonKey:"delimiter-area-polygon",
                    PolygonFillColor:false,
                    PolygonColor:false,
                })}

                {showCloropletic && buildCloropleticLegend()}

                {QuantityLegend && buildQuantityLegend()}
            </Map>        
        </div>
    );
}
export default CloropleticMap
