import React from "react";

// core components
import Header from "components/Headers/Header.jsx";
import "../../../../assets/css/style.css";
import FilterTrapReport from "../../../../components/Navbars/FilterTrapReport";
import {getUserData} from "../../../../services/utils/auth";
import {getTraps,getTrapsTypes, getAllTrapHistory} from "../../../../services/api/Trap";
import {getAllTypeTerritorializations,fetchTerritorializations} from "../../../../services/api/territorialization";
import TrapMap from"./TrapMap.jsx";
import { fetchIdentifications } from "../../../../services/api/Identification";
import { getSampleResult } from "../../../../services/api/SampleResult";
import { getVisitsForReport } from "../../../../services/api/Visits";
import { checkPermissionComponent } from "../../../../services/utils/permission";
import {fetchResidents} from "../../../../services/api/Covid";
import Loader from "../../../../components/Misc/loader";
import GenericMap from "../GenericMap.jsx";
import { timeStampFormated,timeStampFormatedMongo } from "../../../../services/utils/format";
class TrapReport extends React.Component {

    state={
      loading:true,
      loading2:true,
      Traps:[],
      TrapFiltered:[],
      Identifications:[],
      TrapTypes:[],
      Visits:[],
      VisitsFiltered:[],
      Residents:[],
      ResidentsFiltered:[],
      TrapSamplesResult:[],
      TrapsHistory:[],
      CollectorsList:[],
      List:[],
      isOpen1 : false,
      isOpen2 : true,
      isOpen3 : false,
      filter:{
            startDate:null,
            endDate:null,
            startVisitDate:null,
            endVisitDate:null,
            trapStatusType:"",
            organizationId:getUserData("organizationId"),
            InstallerId:"00000000-0000-0000-0000-000000000000",
            TrapTypeId:"00000000-0000-0000-0000-000000000000",
      },
      filterVisit:{
        organizationId:getUserData("organizationId"),
        collectorId: "00000000-0000-0000-0000-000000000000",
        teamId: "00000000-0000-0000-0000-000000000000",
        demandId: "00000000-0000-0000-0000-000000000000"
      },
       filterResidents:{
        onlySymptomatics:false,
        typeReport:"",
        startDate:null,
        endDate:null,
        typeForm:"",
        organizationId:getUserData("organizationId"),
        TeamId:"00000000-0000-0000-0000-000000000000",
        CollectorId:"00000000-0000-0000-0000-000000000000"
      },
    }
    constructor(props){
      super(props);
      if(!checkPermissionComponent("a8318169-2dbe-4f91-b247-cb3fc05207c8", "read")){
        alert("Você não tem permissão para acessar essa funcionalidade!");
        props.history.goBack(); 
      }
      
    }
    async componentDidMount(){
      this.fetchIdentifications();
      this.fetchTrapSampleResult();
      this.fetchTrapHistory();
      let listTerritorializationTypes = await this.fetchTerritorialization();
      this.setState({TerritorializationTypes:listTerritorializationTypes});
      var trapRequest = getTraps(this.state.filter);
      trapRequest.then(response => {
        if(response) {
          var listtemp = this.state.List;
          var traplist = [];
          response.data.map(Trap =>{
            traplist.push({name:Trap.trapTypeName+"\n"+timeStampFormated(Trap.dateInstalled),
              position:[Trap.latitude,Trap.longitude],type:"basictrap"});
          });
          listtemp.push({key:"Todas as armadilhas",checked:true,value:traplist});
          this.setState({
            Traps : response.data,
            loading:false,
            TrapFiltered: response.data,
            List:listtemp});
            return response.data
        }
        
        throw new Error("Erro ao contatar servidor");
      });
      
      
      var typesrequest = getTrapsTypes();
      typesrequest.then(response => {
            this.setState({TrapTypes:response.data, loading2:false});
        });
      
      var visitsForReportRequest = getVisitsForReport(this.state.filterVisit);
      visitsForReportRequest.then(response=>{
        
        var visits = [];
        var visits2 = [];
        var coordenadas = []; 
        response.data.map(visit =>{
          coordenadas.push([visit.latitude,visit.longitude,"300"]);
          visits.push({date:visit.activityDate,situation:visit.visitSituation,
                          position:[visit.latitude,visit.longitude]});
          visits2.push({name:timeStampFormated(visit.activityDate),
          position:[visit.latitude,visit.longitude],type:"visit"});
        });
        this.setState({Visits:visits,VisitsFiltered:visits});
        this.setState({VisitsFilteredHeat : coordenadas});
        var listtemp = this.state.List;
        listtemp.push({key:"Visitas",checked:false,value:visits2});
        this.setState({List:listtemp});
      });
      const ResidentRequest = fetchResidents(this.state.filterResidents);
      ResidentRequest.then(response => {
          var residents = [];
          var residents2 = [];
          response.data.map(resident =>{
            residents.push({data:resident,date:resident.activityDate,
                            position:[resident.latitude,resident.longitude]});
            residents2.push({name:timeStampFormated(resident.activityDate),
                              position:[resident.latitude,resident.longitude],type:"resident"})
          }); 
          
          this.setState({Residents : residents});
          this.setState({ResidentsFiltered : residents});
          var listtemp = this.state.List;
          listtemp.push({key:"Residentes",checked:false,value:residents2});
          this.setState({List:listtemp});
          if(response) {
              return response.data
          }
          throw new Error("Erro ao contatar servidor");
        });
    }

    callbackFunction = async (TrapFilteredArray)=>{
      this.fetchIdentifications();
      this.fetchTrapSampleResult();
      this.fetchTrapHistory();
      this.setState({
        TrapFiltered: TrapFilteredArray.traps
      });
    }
    callbackFunctionVisit = async (VisitFilteredArray)=>{
      this.setState({
        VisitsFiltered: VisitFilteredArray
      });
      var coordenadas = [];  
        VisitFilteredArray.map((visit) =>{
          coordenadas.push([visit.position[0],visit.position[1],"300"]);
        });
        this.state.VisitsFilteredHeat = coordenadas;

    }
    callbackFunctionResident = async (ResidentFilteredArray)=>{
      this.setState({
        ResidentsFiltered: ResidentFilteredArray
      });
    }
    toggle1 = () => this.setState({isOpen1 : !this.state.isOpen1,
                                  isOpen2:false,
                                  isOpen3:false});
    toggle2 = () => this.setState({isOpen2 : !this.state.isOpen2,
                                  isOpen1:false,
                                  isOpen3:false});
    toggle3 = () => this.setState({isOpen3 : !this.state.isOpen3,
                                  isOpen2:false,
                                  isOpen1:false});

    fetchIdentifications = async () => {
      let identificationsType = [];
      const labRequest = fetchIdentifications();
        await labRequest.then(response => {
            if(response){
                return response.data
            }
            
        }).then(identifications => {
            identificationsType = identifications;
        })
        this.setState({
          Identifications:identificationsType
     });
    }

    fetchTrapSampleResult = async () => {
      let trapSampleResults = [];
      const resultRequest = getSampleResult();
      await resultRequest.then((response) => {
          if(response){
              return response.data
          }
      }).then((results) => {
        trapSampleResults = results.filter((result) => {
          return result.trapId !== "00000000-0000-0000-0000-000000000000";
        })
      });

      this.setState({
        TrapSamplesResult: trapSampleResults});

    }
    fetchTerritorialization = async () => {

      var listTerritorialization = [];
      var territorializationTypeRequest = getAllTypeTerritorializations();
      
      await territorializationTypeRequest.then(response=>{
        response.data.map((type)=>{
          
          type.territorializationList = [];
          var territorializationRequest = fetchTerritorializations(type.id);
          
          territorializationRequest.then(response=>{
            type.territorializationList=response.data;
            listTerritorialization.push(type);
          });
          
        })

      });
     return  listTerritorialization;
    }
    fetchTrapHistory = async () => {
      let trapsHistory = [];
      let filter = {organizationId: this.state.filter.organizationId}
      const request = getAllTrapHistory(filter);
      await request.then((response) => {
        if(response){
          return response.data
        }
      }).then((results) => {
        trapsHistory = results;
      });
     
      this.setState({
        TrapsHistory: trapsHistory});
    }

    render() {
      
      return (      
        <>
          <Header />
          {(this.state.loading || this.state.loading2) ?
            <Loader />
             :
            <div>         

              <FilterTrapReport  
              Traps={this.state.Traps} 
              Visits={this.state.Visits} 
              TrapTypes = {this.state.TrapTypes} 
              Identifications={this.state.Identifications}
                parentCallback = {this.callbackFunction} 
                parentCallbackVisit = {this.callbackFunctionVisit} 
                parentCallbackResident = {this.callbackFunctionResident} 
              />
              {this.state.TrapFiltered.length > 0 ?
                <>
                {/*<TrapMap 
                  key={setInterval(()=>{ return new Date()},1000)}
                  traps={this.state.TrapFiltered}
                  identifications={this.state.Identifications}
                  trapsSamplesResults={this.state.TrapSamplesResult}  
                  trapsType={this.state.TrapTypes}
                  trapHistory = {this.state.TrapsHistory}
                  territorializations = {this.state.TerritorializationTypes}
                  visits = {this.state.VisitsFiltered}
                  visitsHeat = {this.state.VisitsFilteredHeat}
                  residents = {this.state.ResidentsFiltered}
                />*/}
                <GenericMap
                  List={this.state.List}
                  territorializations = {this.state.TerritorializationTypes}
                />
                </>
              :null}
                           
            </div>                                    
          }
        </>
      );
    }
  }
  export default TrapReport;
  