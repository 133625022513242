import React from "react";

import Cookies from "js-cookie";
import {
  FeatureGroup,
  LayersControl,
  Map,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  Tooltip,
} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { GOOGLE_MAPS_API_KEY } from "../../../../constants/WebFormConstants";
import { Card, CardBody, CardHeader, CardText, Col, Row } from "reactstrap";
import MarkerClusterGroup from "react-leaflet-markercluster";
import moment from "moment";
import L from "leaflet";
import { polygon } from "@turf/turf";

const { BaseLayer, Overlay } = LayersControl;

const VisitsMap = ({ headerText, data, employees, territorializations }) => {
  const [mapInitialCenterPosition, setMapInitialCenterPosition] =
    React.useState([-15.814951, -47.903183]);
  const [isMapLoaded, setIsMapLoaded] = React.useState(false);
  const [generatedAt, setGeneratedAt] = React.useState("");

  React.useEffect(() => {
    if(!data || data.length === 0)
      return;

    const actualDate = moment().format("DD/MM/YYYY HH:mm");

    setGeneratedAt(actualDate);

  }, [data]);

  const mapRef = React.useRef();

  React.useEffect(() => {
    if (!Cookies.get("Admin-Organization")) return;

    const organizationCookies = JSON.parse(Cookies.get("Admin-Organization"));

    if (!organizationCookies.stateSub) return;

    const [organizationLatitude, organizationLongitude] =
      organizationCookies.stateSub.replace(" ", "").split(",");

    if (!validadeLatitudeLongitude(organizationLatitude, organizationLongitude))
      return;

    setMapInitialCenterPosition([organizationLatitude, organizationLongitude]);
  }, []);

  const validadeLatitudeLongitude = (latitude, longitude) => {
    if (!latitude || !longitude) return false;

    const latitudeInNumber = +latitude;
    const longitudeInNumber = +longitude;

    if (!(latitudeInNumber >= -90 && latitudeInNumber <= 90)) return false;
    if (!(longitudeInNumber >= -180 && longitudeInNumber <= 180)) return false;

    return true;
  };

  const style = {
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
    },
    cardHeaderText: {
      color: "black",
      fontWeight: "bold",
      fontSize: "0.97rem",
      display: "flex",
      justifyContent: "space-between"
    },
  };

  return (
    <>
      <Card className="shadow">
        <CardHeader>
          <CardText className="mb-0 mt-0" style={style.cardHeaderText}>
          <span>{headerText}</span>
          <span style={{fontSize: "13px", fontWeight: "normal"}}>Gerado em {generatedAt}</span>
          </CardText>
        </CardHeader>
        <CardBody style={{ padding: "25px 0px" }}>
          <Row>
            <Col style={style.col}>
              <Map
                center={mapInitialCenterPosition}
                zoom={8}
                ref={mapRef}
                fullscreenControl={true}
                style={{ height: "447.5px" }}
                whenReady={() => setIsMapLoaded(true)}
              >
                {isMapLoaded && (
                  <>
                    <MapVisualizationLayersControl />
                    <MapVisitsPointsLayersControl
                      visitsSituations={data}
                      employees={employees}
                    />
                    <MapPolygonsLayersControl
                      data={territorializations}
                      layerTitle="Delimitador de Área"
                    />
                  </>
                )}
              </Map>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

const MapVisualizationLayersControl = () => {
  return (
    <LayersControl>
      <LayersControl.BaseLayer checked name="Normal">
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Satélite">
        <ReactLeafletGoogleLayer
          googleMapsLoaderConf={{ KEY: GOOGLE_MAPS_API_KEY }}
          type={"hybrid"}
        />
      </LayersControl.BaseLayer>
    </LayersControl>
  );
};

const MapPolygonsLayersControl = ({ data, layerTitle }) => {
  const [typeLayersPolygons, setTypeLayersPolygons] = React.useState([]);

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const typeLayersPolygonsGroup = [];

    data.forEach(({ name, territorializations }) => {
      const typeLayerData = {
        name,
        polygons: [],
      };

      territorializations.forEach((territorialization) => {
        const turfPolygon = polygon([
          territorialization.coordinates.map(({ x, y }) => [x, y]),
        ]);

        turfPolygon.name = territorialization.name;
        turfPolygon.territorialization = territorialization;

        typeLayerData.polygons.push(turfPolygon);
      });

      typeLayersPolygonsGroup.push(typeLayerData);
    });

    setTypeLayersPolygons(typeLayersPolygonsGroup);
  }, [data]);

  return (
    <LayersControl position="topright">
      {layerTitle !== "" && (
        <BaseLayer checked name={layerTitle}>
          <TileLayer attribution="" url="" />
        </BaseLayer>
      )}

      {typeLayersPolygons &&
        typeLayersPolygons.length > 0 &&
        typeLayersPolygons.map(({ name, polygons }, index) => {
          return (
            <Overlay key={`${name}-overlay-${index}`} name={name}>
              <FeatureGroup
                fillOpacity={0.3}
                name={name}
                key={`${name}-featureGroup-${index}`}
              >
                {polygons &&
                  polygons.length > 0 &&
                  polygons.map(({ geometry, name }, index) => {
                    const coordinates = geometry.coordinates[0].map(
                      ([latitude, longitude]) => [longitude, latitude]
                    );

                    return (
                      <Polygon
                        key={index}
                        color={"#000000"}
                        fillColor={"#03a1fc"}
                        opacity={1}
                        positions={coordinates}
                      >
                        {name ? <Tooltip sticky>{name}</Tooltip> : null}
                      </Polygon>
                    );
                  })}
              </FeatureGroup>
            </Overlay>
          );
        })}
    </LayersControl>
  );
};

const MapVisitsPointsLayersControl = ({ visitsSituations, employees }) => {
  const VISIT_SITUATION_COLORS = {
    Aberto: "#0088FE",
    Fechado: "#00C49F",
    Recusa: "#FFBB28",
    "Endereço Não Encontrado": "#FF8042",
    Orientação: "#a19e9c",
  };

  const formatDateInUTC = (date) => {
    return date
      ? moment(date).subtract(3, "hours").format("DD/MM/YYYY hh:mm")
      : "-";
  };

  const visitIcon = (situation) => {
    return L.divIcon({
      html: `<div style="background-color: ${VISIT_SITUATION_COLORS[situation]}; width: 10px; height: 10px; border-radius: 50%;"></div>`,
      className: "",
      iconSize: [10, 10],
    });
  };

  const getUserName = (id) => {
    if (
      id === "00000000-0000-0000-0000-000000000000" ||
      id === null ||
      id === undefined ||
      !employees ||
      employees.length === 0
    )
      return "";

    if (id === "5a868c02-7a8d-47a0-b1b1-bb55383ad55d") return "Não informado";

    const userData = employees.find(
      (employee) => employee.employeeId === id || employee.userId === id
    );

    return userData ? userData.employeeName : "";
  };

  return (
    <LayersControl position="topright">
      <BaseLayer checked name="Situação das visitas">
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {visitsSituations &&
        visitsSituations.length > 0 &&
        visitsSituations.map(({ situation, visitsData }, index) => {
          return (
            <Overlay
              key={index}
              name={situation}
              checked={situation === "Aberto" ? true : false}
            >
              <MarkerClusterGroup removeOutsideVisibleBounds={true}>
                {visitsData &&
                  visitsData.length > 0 &&
                  visitsData.map(
                    (
                      {
                        address,
                        collectorId,
                        finalTime,
                        initialTime,
                        latitude,
                        longitude,
                      },
                      index
                    ) => {
                      return (
                        <Marker
                          key={situation + index}
                          position={[latitude, longitude]}
                          icon={visitIcon(situation)}
                        >
                          <Popup key={index}>
                            <span style={{ fontWeight: "bold" }}>
                              Situação:
                            </span>{" "}
                            {situation}
                            <br />
                            <span style={{ fontWeight: "bold" }}>
                              Funcionário:
                            </span>{" "}
                            {getUserName(collectorId)}
                            <br />
                            <span style={{ fontWeight: "bold" }}>
                              Tipo de imóvel:
                            </span>{" "}
                            {address.tipoImovel}
                            <br />
                            <span style={{ fontWeight: "bold" }}>
                              Endereço:
                            </span>{" "}
                            {`${address.logradouro}, ${address.numero}`}
                            <br />
                            <span style={{ fontWeight: "bold" }}>
                              Complemento:
                            </span>{" "}
                            {address.complemento}
                            <br />
                            <span style={{ fontWeight: "bold" }}>
                              Hora de início:
                            </span>{" "}
                            {formatDateInUTC(initialTime)}
                            <br />
                            <span style={{ fontWeight: "bold" }}>
                              Hora de término:
                            </span>{" "}
                            {formatDateInUTC(finalTime)}
                            <br />
                          </Popup>
                        </Marker>
                      );
                    }
                  )}
              </MarkerClusterGroup>
            </Overlay>
          );
        })}
    </LayersControl>
  );
};

export default VisitsMap;
