import React from "react";

import { Row, Col } from "reactstrap";
import { postTrap, postTrapHistory } from "../../../../services/api/Trap";
import { getUserData } from "../../../../services/utils/auth";
import { postVigilanciaEntomologica } from "../../../../services/api/VigilanciaEntomologica";
import { toast } from "react-toastify";
import CsvDownloadButton from "react-json-to-csv";
import {
  checkIfRequiredFieldsAreEmpty,
  getAddressInformations,
} from "../../../../services/utils/WebForms/validations";
import { getEntomologicVigilanceObjectForInstall } from "../../../../services/utils/WebForms/EntomologicVigilance";
import {
  getTrapHistoryObject,
  getTrapToPostObject,
} from "../../../../services/utils/WebForms/Trap";
import ReactTooltip from "react-tooltip";
import ConfirmTrapPositionModal from "../../../../components/Modal/ConfirmTrapPositionModal";
import InstallFormPrintModal from "../../../../components/Modal/WebForms/InstallFormPrintModal";
import HeaderFormInputs from "../HeaderFormInputs";
import FooterFormButtons from "../FooterFormButtons";

import termo_autorizacao_sp from "../Termo_Autorizacao_Armadilha_Disseminadora.pdf";
import termo_autorizacao_go from "../termo_autorizacao_instalacao_go.pdf";

const InstallForm = ({ employeesOptions, trapToInstall, organizationId }) => {
  const [headerInputs, setHeaderInputs] = React.useState({
    uvisName: {
      label: "Selecione a UVIS",
      value: "",
    },
    administrativeDistrict: {
      label: "Selecione o Distrito Administrativo",
      value: "",
    },
    sanitaryDistrict: "",
    environmentalSector: "",
    block: "",
    teamsAndEmployees: [],
    activityDate: "",
  });
  const [tableRowsData, setTableRowsData] = React.useState([
    { ...getTableRowsObject() },
  ]);
  const [isConfirmTrapPositionModalOpen, setIsConfirmTrapPositionModalOpen] =
    React.useState(false);
  const [trapToConfirmPositionData, setTrapToConfirmPositionData] =
    React.useState(null);
  const [isPrintFormModalOpen, setIsPrintFormModalOpen] = React.useState(false);
  const [isSubmitingForm, setIsSubmitingForm] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const MAX_ROWS_PER_FORM = 20;
  const MIN_ROWS_PER_FORM = 1;

  function getTableRowsObject() {
    return {
      address: "",
      addressNumber: "",
      addressComplement: "",
      trapLatitude: "",
      trapLongitude: "",
      addressSequential: "",
      propertyType: "",
      visitStatus: "",
      trapNumber: "",
      responsablePersonName: "",
      responsablePersonCellPhoneNumber: "",
    };
  }

  const handleHeaderInputsChange = (inputName, inputValue) => {
    const newHeaderInputs = { ...headerInputs };
    newHeaderInputs[inputName] = inputValue;

    setHeaderInputs(newHeaderInputs);
  };

  const handleConfirmTrapPositionButton = async (
    addressName,
    addressNumber,
    rowIndex
  ) => {
    if (!addressName && !addressNumber) {
      toast.error(
        "Informe o LOGRADOURO e o NÚMERO para buscar pelos dados da residência"
      );
      return;
    }

    const { geometry } = await getAddressInformations(
      addressName,
      addressNumber
    );

    const latitude =
      geometry && geometry.location.lat
        ? geometry.location.lat.toFixed(7)
        : null;
    const longitude =
      geometry && geometry.location.lng
        ? geometry.location.lng.toFixed(7)
        : null;

    handleInputChange("trapLatitude", latitude, rowIndex);
    handleInputChange("trapLongitude", longitude, rowIndex);

    setTrapToConfirmPositionData({
      rowIndex,
      addressName,
      addressNumber,
      latitude,
      longitude,
    });

    toggleConfirmTrapPositionModal();
  };

  const toggleConfirmTrapPositionModal = () => {
    setIsConfirmTrapPositionModalOpen(!isConfirmTrapPositionModalOpen);
  };

  const closeConfirmTrapPositionModal = () => {
    setIsConfirmTrapPositionModalOpen(false);
    setTrapToConfirmPositionData(null);
  };

  const handleInputChange = (inputName, inputValue, rowIndex) => {
    const newTableRow = [...tableRowsData];
    newTableRow[rowIndex][inputName] = inputValue;

    setTableRowsData(newTableRow);
  };

  const handleRepeatAddress = () => {
    if (tableRowsData.length >= MAX_ROWS_PER_FORM) {
      toast.error("Não é possível adicionar mais que 20 linhas por formulário");
      return;
    }

    setTableRowsData((previousRows) => {
      const lastRow = previousRows[previousRows.length - 1];
      const newRow = { ...getTableRowsObject(), address: lastRow.address };
      return [...previousRows, newRow];
    });
  };

  const handleAddNewAddress = () => {
    if (tableRowsData.length >= MAX_ROWS_PER_FORM) {
      toast.error("Não é possível adicionar mais que 20 linhas por formulário");
      return;
    }

    setTableRowsData((previousRows) => {
      const newRow = { ...getTableRowsObject() };
      return [...previousRows, newRow];
    });
  };

  const handleDeleteRow = (rowIndex) => {
    if (tableRowsData.length === MIN_ROWS_PER_FORM) {
      toast.error("Não é possível ter menos que uma linha por formulário");
      return;
    }

    setTableRowsData((previousRows) => {
      const newTableRows = [...previousRows];
      newTableRows.splice(rowIndex, 1);

      return newTableRows;
    });
  };

  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === "r") {
      event.preventDefault();
      handleRepeatAddress();

      return;
    }

    if (event.ctrlKey && event.key === "a") {
      event.preventDefault();
      handleAddNewAddress();

      return;
    }
  };

  const handleGenerateRandomTrapNumber = (
    addressName,
    addressNumber,
    rowIndex
  ) => {
    if (addressName === "" || addressNumber === "") {
      toast.error(
        "É necessário informar o LOGRADOURO e o NÚMERO para gerar o código aleatório"
      );
      return;
    }

    const randomNumber = `#${addressName
      .trim()
      .replaceAll(" ", "")}${addressNumber}-${rowIndex + 1}`;

    handleInputChange("trapNumber", randomNumber, rowIndex);
  };

  const handleSubmit = async () => {
    let occurredErrorWhileSubmitingForm = false;

    const mandatoryColumnsError = checkIfRequiredFieldsAreEmpty(
      headerInputs,
      tableRowsData,
      getUserData("organizationId")
    );

    if (mandatoryColumnsError) {
      toast.error(mandatoryColumnsError);
      return;
    }

    setIsSubmitingForm(true);

    for (const tableRow of tableRowsData) {
      try {
        let latitude, longitude;

        if (tableRow.trapLatitude !== "" && tableRow.trapLongitude !== "") {
          latitude = tableRow.trapLatitude;
          longitude = tableRow.trapLongitude;
        } else {
          const { geometry } = await getAddressInformations(
            tableRow.address,
            tableRow.addressNumber
          );

          latitude =
            geometry && geometry.location.lat
              ? geometry.location.lat.toFixed(7)
              : null;
          longitude =
            geometry && geometry.location.lng
              ? geometry.location.lng.toFixed(7)
              : null;
        }

        const trapInformations = {
          latitude,
          longitude,
          trapName: trapToInstall,
          install: true,
          maintenance: false,
          uninstall: false,
        };

        const entomologicVigilanceObject =
          getEntomologicVigilanceObjectForInstall(
            tableRow,
            headerInputs,
            trapInformations
          );

        const { data: entomologicVigilanceRequestResponse } =
          await postVigilanciaEntomologica(entomologicVigilanceObject);

        const trapToPostObject = getTrapToPostObject(
          tableRow,
          headerInputs,
          trapInformations
        );

        const { data: trapRequestReponse } = await postTrap(trapToPostObject);

        const trapHistoryObject = getTrapHistoryObject(
          tableRow,
          headerInputs,
          trapInformations,
          trapRequestReponse.id,
          entomologicVigilanceRequestResponse.vigilanciaId
        );

        await postTrapHistory(trapHistoryObject);
      } catch (error) {
        occurredErrorWhileSubmitingForm = true;
        console.error(error);

        setIsSubmitingForm(false);
        toast.error(
          "Ocorreu um erro inesperado ao enviar os dados do formulário. Salve as digitações clicando no botão 'Baixar CSV das digitações' e entre em contato com nossa equipe"
        );
      }
    }

    if (!occurredErrorWhileSubmitingForm) {
      toast.success("Dados do formulário enviados com sucesso.");
      setTableRowsData([{ ...getTableRowsObject() }]);
      setHeaderInputs({
        uvisName: "",
        administrativeDistrict: "",
        environmentalSector: "",
        block: "",
        teamsAndEmployees: [],
        activityDate: "",
      });
    }

    setIsSubmitingForm(false);
  };

  return (
    <>
      <ConfirmTrapPositionModal
        isOpen={isConfirmTrapPositionModalOpen}
        closeModal={closeConfirmTrapPositionModal}
        toggleModal={toggleConfirmTrapPositionModal}
        trapInformations={trapToConfirmPositionData}
        setUpdatedPosition={handleInputChange}
      />
      <InstallFormPrintModal
        isModalOpen={isPrintFormModalOpen}
        setIsModalOpen={setIsPrintFormModalOpen}
        trapTypeName={trapToInstall}
      />
      <HeaderFormInputs
        headerInputs={headerInputs}
        handleHeaderInputsChange={handleHeaderInputsChange}
        employeesOptions={employeesOptions}
        organizationId={organizationId}
      />
      <Row className="mt-4" style={{ justifyContent: "center" }}>
        <Col className="col-3">
          <div className="filter-container container">
            <button
              onClick={() => setIsPrintFormModalOpen(true)}
              className="btn btn-primary"
            >
              <i className="fa fa-file" style={{ marginRight: ".5rem" }}></i>
              Gerar Formulário Vazio
            </button>
          </div>
        </Col>
        <Col className="col-3 ">
          <CsvDownloadButton
            className="btn btn-primary"
            data={tableRowsData}
            filename={"instalacao"}
          >
            <i className="fa fa-download" /> Baixar CSV das digitações
          </CsvDownloadButton>
        </Col>
        {organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8" && (
          <Col className="col-3 ">
            <div className="filter-container container">
              <button
                onClick={() => {
                  window.open(termo_autorizacao_sp);
                }}
                className="btn btn-primary"
              >
                <i
                  className="fa fa-thumbs-up"
                  style={{ marginRight: ".5rem" }}
                ></i>
                Imprimir Termo de Autorização
              </button>
            </div>
          </Col>
          )
        }
        {organizationId === "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30" && (
          <Col className="col-3 ">
            <div className="filter-container container">
              <button
                onClick={() => {
                  window.open(termo_autorizacao_go);
                }}
                className="btn btn-primary"
              >
                <i
                  className="fa fa-thumbs-up"
                  style={{ marginRight: ".5rem" }}
                ></i>
                Imprimir Termo de Autorização
              </button>
            </div>
          </Col>
          )
        }
      </Row>
      <Row className="mt-4">
        <Col>
          <div>
            <table
              className="table_form_vistoria"
              style={{
                borderCollapse: "collapse",
                width: "100%",
                fontSize: 13,
              }}
              data-testid={"installFormTable"}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: "#4f8bb3",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th
                    rowSpan="2"
                    className="vertical-header"
                    style={{ width: "50px" }}
                  ></th>
                  <th colSpan="7">ENDEREÇO</th>
                  <th colSpan={2}>ARMADILHA</th>
                  <th colSpan="2">RESPONSÁVEL</th>
                  <th
                    rowSpan="2"
                    className="vertical-header"
                    style={{ width: "50px" }}
                  ></th>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#4f8bb3",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th style={{ width: "260px" }}>(*) Logradouro</th>
                  <th>(*) Número</th>
                  <th>Complemento</th>
                  <th>Sequencial</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>(*) Tipo do Imóvel</th>
                  <th>(*) Selecione o status da visita</th>
                  <th>(*) Número da Armadilha</th>
                  <th>Nome do Responsável</th>
                  <th>Telefone de Contato</th>
                </tr>
              </thead>
              <tbody>
                {tableRowsData.map((row, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "lightgrey" : "darkgrey",
                    }}
                  >
                    <td style={{ textAlign: "center" }}>
                      <span>{`L${index + 1}`}</span>
                    </td>
                    <td>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          className="input_coluna"
                          type="text"
                          value={row.address}
                          onChange={({ target }) =>
                            handleInputChange("address", target.value, index)
                          }
                          data-testid={`addressInput${index + 1}`}
                          required
                        />
                      </div>
                    </td>
                    <td>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          className="input_coluna"
                          type="text"
                          value={row.addressNumber}
                          onChange={({ target }) =>
                            handleInputChange(
                              "addressNumber",
                              target.value,
                              index
                            )
                          }
                          data-testid={`addressNumberInput${index + 1}`}
                          required
                        />
                        <button
                          data-tip
                          data-for="trapPosition"
                          style={{ padding: "5px", width: "70px" }}
                          className="btn btn-primary"
                          onClick={() =>
                            handleConfirmTrapPositionButton(
                              row.address,
                              row.addressNumber,
                              index
                            )
                          }
                        >
                          <i className="fa fa-map-pin"></i>
                        </button>
                        <ReactTooltip
                          effect="solid"
                          type="success"
                          id="trapPosition"
                        >
                          Confirmar posição da armadilha
                        </ReactTooltip>
                      </div>
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.addressComplement}
                        onChange={({ target }) =>
                          handleInputChange(
                            "addressComplement",
                            target.value,
                            index
                          )
                        }
                        data-testid={`addressComplementInput${index + 1}`}
                      />
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.addressSequential}
                        onChange={({ target }) =>
                          handleInputChange(
                            "addressSequential",
                            target.value,
                            index
                          )
                        }
                        data-testid={`addressSequentialInput${index + 1}`}
                      />
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.trapLatitude}
                        onChange={({ target }) =>
                          handleInputChange("trapLatitude", target.value, index)
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.trapLongitude}
                        onChange={({ target }) =>
                          handleInputChange(
                            "trapLongitude",
                            target.value,
                            index
                          )
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <select
                        name="propertyType"
                        id="propertyType"
                        value={row.propertyType}
                        onChange={({ target }) =>
                          handleInputChange("propertyType", target.value, index)
                        }
                        data-testid={`propertyTypeInput${index + 1}`}
                      >
                        <option value="">Selecione</option>
                        <option value="Residência">Residência (R)</option>
                        <option value="Comércio">Comércio (C)</option>
                        <option value="Terreno Baldio">
                          Terreno Baldio (TB)
                        </option>
                        <option value="Ponto Estratégico">
                          Ponto Estratégico (PE)
                        </option>
                        <option value="Próprio Público">
                          Próprio Público (PP)
                        </option>
                        <option value="Imóvel Especial">
                          Imóvel Especial (IE)
                        </option>
                        <option value="Via Pública">Via Pública (VP)</option>
                        <option value="Unidade de Saúde">Unidade de Saúde (US)</option>
                        <option value="Escola">Escola (E)</option>
                        <option value="Outros">Outros (O)</option>
                      </select>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <select
                        name="visitStatus"
                        id="visitStatus"
                        value={row.visitStatus}
                        onChange={({ target }) =>
                          handleInputChange("visitStatus", target.value, index)
                        }
                        data-testid={`visitStatusInput${index + 1}`}
                      >
                        <option value=""></option>
                        <option value="Aberto">T - Trabalhado</option>
                        <option value="Fechado">F - Fechado</option>
                        <option value="Recusa">R - Recusa</option>
                        <option value="Orientação">O - Orientação</option>
                        <option value="Endereço Não Encontrado">
                          EN - Endereço Não Encontrado
                        </option>
                      </select>
                    </td>
                    <td>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          className="input_coluna"
                          type="text"
                          value={row.trapNumber}
                          onChange={({ target }) =>
                            handleInputChange("trapNumber", target.value, index)
                          }
                          data-testid={`trapNumberInput${index + 1}`}
                        />
                        <button
                          data-tip
                          data-for="trapRandomNumber"
                          style={{ padding: "5px", width: "70px" }}
                          className="btn btn-primary"
                          onClick={() =>
                            handleGenerateRandomTrapNumber(
                              row.address,
                              row.addressNumber,
                              index
                            )
                          }
                        >
                          <i className="fa fa-random"></i>
                        </button>
                        <ReactTooltip
                          effect="solid"
                          type="success"
                          id="trapRandomNumber"
                        >
                          Gerar número automático da armadilha
                        </ReactTooltip>
                      </div>
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.responsablePersonName}
                        onChange={({ target }) =>
                          handleInputChange(
                            "responsablePersonName",
                            target.value,
                            index
                          )
                        }
                        data-testid={`responsablePersonNameInput${index + 1}`}
                      />
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.responsablePersonCellPhoneNumber}
                        onChange={({ target }) =>
                          handleInputChange(
                            "responsablePersonCellPhoneNumber",
                            target.value,
                            index
                          )
                        }
                        data-testid={`responsablePersonCellPhoneNumberInput${index + 1}`}
                      />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <button
                        data-tip
                        data-for="removeTrapRow"
                        className="btn btn-primary"
                        onClick={() => handleDeleteRow(index)}
                        data-testid={`removeRowButton${index + 1}`}
                        style={{
                          padding: "5px",
                          width: "50px",
                          cursor: "pointer",
                          backgroundColor: "red",
                          border: "0px",
                          marginRight: "0px",
                        }}
                      >
                        <i
                          style={{
                            color: "white",
                            fontSize: "21px",
                          }}
                          className="fa fa-times"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <ReactTooltip
                        effect="solid"
                        type="error"
                        id="removeTrapRow"
                      >
                        Excluir linha
                      </ReactTooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <FooterFormButtons
              handleRepeatAddress={handleRepeatAddress}
              handleAddNewAddress={handleAddNewAddress}
              handleSubmit={handleSubmit}
              isSubmitingForm={isSubmitingForm}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h1 style={{ color: "#f75c5c" }}>
            Instruções para preenchimento do formulário
          </h1>
          <ul style={{ color: "white" }}>
            <li>
              Caso o formulário impresso NÃO possua a informação dos AGENTES que
              INSTALARAM a armadilha, selecione o funcionário "Não informado -
              RF: 13212312";
            </li>
            <li>
              Caso o formulário impresso NÃO possua a informação da DATA DE
              INSTALAÇÃO, informe a data de HOJE;
            </li>
            <li>
              Caso o formulário impresso NÃO possua o TIPO DE IMÓVEL, selecione
              a opção "Outros (O)";
            </li>
            <li>
              Caso o formulário impresso NÃO possua o NÚMERO DA ARMADILHA,
              informe o LOGRADOURO e NÚMERO do endereço e clique no botão{" "}
              <button
                style={{ padding: "5px", width: "50px" }}
                className="btn btn-primary"
              >
                <i className="fa fa-random"></i>
              </button>{" "}
              que será gerado um código aleatório;
            </li>
            <li>
              Clicando no botão{" "}
              <i className="fa fa-bars fa-inverse fa-2x" color="white"></i> que
              encontra-se no canto superior esquerdo da tela, é possível ocultar
              o menu lateral esquerdo com a listagem das telas, gerando mais
              espaço para o preenchimento e visualização das questões no
              formulário;
            </li>
            <li>
              Apertando a combinação de teclas "CTRL + - (menos)", é possível
              reduzir o tamanho do zoom na página, gerando mais espaço para o
              preenchimento e visualização das questões no formulário;
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
};

export default InstallForm;
