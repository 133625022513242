import React from "react";
import { Link } from "react-router-dom";
import "./../../assets/css/navbar.css";
import CsvDownloadButton from 'react-json-to-csv'
// reactstrap components
import {
    Container,
    Col
} from "reactstrap";
import { checkPermissionComponent } from "../../services/utils/permission";
import "./FilterTraps.css";
import {  fetchEmployee,fetchEmployeeData } from "../../services/api/Employee";
import { fetchModules } from "../../services/api/Modules";
import { getPermissions, getUserByEmployeeId } from "../../services/api/Users";
import { ARMADILHA_INFO } from "../../constants/ArmadilhaConstant";



class FilterTraps extends React.Component {
    state ={
        filterName:"",
        filterModule:"",
        filterStatus:"",
        EmployeesFormated: [],
        Modules: null,
    }  

    render() {
        return (
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" >
                <Container fluid>
                    <div className="header-body">
                        <div className="row linha-form">
                            {/* <div className="col-4 responsive-field-form-full">
                                <input type="text" name="filterName" className="form-control" placeholder="Nome" />
                            </div> */}
                            <div className="col-4 responsive-field-form-full">
                                <span className="h4 text-white">Tipo de armadilha</span>
                                <select value={this.props.trapTypeId} name={"trapType"} className="form-control" onChange={(e) => this.props.handleChangeFilter("trapType", e.target.value)}>
                                    {this.props.trapTypeOptions.map(trapType => {
                                        return (
                                            <option key={trapType.id} value={trapType.id}>{ARMADILHA_INFO[trapType.name].apelido}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 responsive-field-form-full">
                                <button className="btn btn-primary" >Pesquisar</button>
                            </div>
                            <div className="col-4 responsive-field-form-full">
                                
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );

    }

}

export default FilterTraps;