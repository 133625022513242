
import React from "react";
import { Col } from "reactstrap";
import { Select } from "../../Wrappers/SelectAll";
import "./VisitSituationsFilter.css";

// reactstrap components

const VisitSituationFilterPresentational = (props) => {
    return (
        <Col className="linha-form col-md-4 visit-situation-responsive-filter">
            <span className="h4 text-white">Situação da Visita</span>
            <Select 
                styles={{
                    control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                    option: styles =>({...styles, fontSize:"0.875rem"}),
                    valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                    singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                    placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                    menuPortal: styles => ({...styles, zIndex:9999})
                }}
                placeholder={"Selecione"}
                isClearable={true}
                options={props.VisitSituations}
                value={props.SelectedVisitSituations}
                isMulti={true}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                onChange={(e) => {props.handleChangeFilter("visitSituation", e)}}
                menuPortalTarget={document.body}
            />
        </Col>
                    
    )
}


export default VisitSituationFilterPresentational;