import React from "react";

import "../../../../assets/css/style.css";
import { fetchEmployees } from "../../../../services/api/Employee";
import EmployeesExportTable from "./EmployeesExportTable";
import EmployeesExportFilters from "./EmployeesExportFilters";
import { Container } from "reactstrap";

const EmployeesExport = () => {
  const [Employees, setEmployees] = React.useState([]);
  const [EmployeesFilter, setEmployeesFilter] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchEmployees();
        if (response) {
          const data = response.data;
          setEmployees(data);
          setEmployeesFilter(data);
        } else {
          throw new Error("Erro ao contatar servidor");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const callbackFunction = (EmployeeFilteredArray) => {
    setEmployeesFilter(EmployeeFilteredArray);
  };

  return (
    <Container fluid style={{ marginTop: "2rem" }}>
      <EmployeesExportTable
        employees={EmployeesFilter}
        employeesFetch={Employees}
        parentCallback={callbackFunction}
      />
    </Container>
  );
};

export default EmployeesExport;
