import React, {memo, useEffect, useState} from "react";
import { Bar, BarChart, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useCurrentPng } from "recharts-to-png";
import { getUnidadesNotificadoras } from "../../../../../services/api/Sinan";
import CardPresentational from "../../../../Presentational/Sinan/CardPresentational";

const ReportingUnityContainer = ({data}) => {
    const [dataSet, setDataSet] = useState();
    const [getpng, {ref}] = useCurrentPng();

    useEffect(() => {
        if(data){
            buildChartDataSet(data).then(responseBuild => {
                setDataSet(responseBuild);
            });
        }
    }, [data])


    async function buildChartDataSet(data){
        const dataSet = [];
        const unidades = await getUnidadesNotificadoras();
        const arr_unidades = [];
        let counter = 1;
        const limit = 20;
  
        unidades.data.forEach(unidade => {
            arr_unidades[unidade.number] = unidade.name;
        });

        for (let notificationNumbers of data){

            const dataSetObject = {
                unidade: arr_unidades[notificationNumbers.reportingUnity] ? arr_unidades[notificationNumbers.reportingUnity] : notificationNumbers.reportingUnity,
                Notificados: notificationNumbers.notifications,
                Confirmados: notificationNumbers.confirmedNotifications
            }

            if(counter <= limit)
            {
                dataSet.push(dataSetObject);
                counter ++;
            } else {
                break;
            }
        }

        return dataSet;
    }

    const buildChartElement = () => (
        <ResponsiveContainer width={"100%"} height={"100%"}>
            <BarChart
                data={dataSet}
                layout={"vertical"}
                stackOffset="sign"
                margin={{
                    top:30,
                    right:15
                }}
                ref={ref}
            >
                <text x={"50%"} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
                    <tspan fontSize="16" fontWeight={"bolder"}>Casos por Unidade </tspan>
                </text>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type={"number"} style={{fontSize:12}} tickFormatter={(v) => v < 0 ? -v : v}/>
                <YAxis width={400} height={200} dataKey="unidade" type={"category"} style={{fontSize:11}} interval={0}/>
                <Tooltip formatter={(v) => v < 0 ? -v : v}/>
                <Legend align="center" verticalAlign="top" />
                <ReferenceLine x={0} stroke="#000" />
                <Bar dataKey="Notificados" stackId='a' fill="rgba(87, 175, 224, 88)" />
                <Bar dataKey="Confirmados" stackId='a' />
            </BarChart>
        </ResponsiveContainer>
    )

    //construindo propriedades do presentational

    const style={
        cardHeaderText: {
            color:"black",
            fontWeight:"bold",
            fontSize:"0.97rem"
        },
    }

    const textCardHeader = "Casos por Unidade Notificadora";
    return(
        <CardPresentational
            CardId="ReportingUnityChart"
            style={style}
            textCardHeader={textCardHeader}
            element={buildChartElement()}
            getpng={getpng}
        />
    );
    
}

export default ReportingUnityContainer;