import moment from "moment";

export function timeStampFormated(timestamp) {
    var data = new Date(timestamp);
    var dia  = (data.getDate()).toString();
    var diaF = (dia.length == 1) ? '0'+dia : dia;
    var mes  = (data.getMonth()+1).toString(); //+1 pois no getMonth Janeiro começa com zero.
    var mesF = (mes.length == 1) ? '0'+mes : mes;
    var anoF = data.getFullYear();
    return diaF+"/"+mesF+"/"+anoF;
}

export function formatTodayDateBody (date) {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export function timeStampFormatedMongo(timestamp) {
    var data = new Date(timestamp);
    data = new Date(data.getTime() + data.getTimezoneOffset() * 60000);
    var dia  = (data.getDate()).toString();
    var diaF = (dia.length == 1) ? '0'+dia : dia;
    var mes  = (data.getMonth()+1).toString(); //+1 pois no getMonth Janeiro começa com zero.
    var mesF = (mes.length == 1) ? '0'+mes : mes;
    var anoF = data.getFullYear();
    
    return diaF+"/"+mesF+"/"+anoF;
}

export function toISOFormat(dateTimeString, padraoEua = false) {
    // Primeiro, dividimos a data completa em duas partes:
    const [date, time] = dateTimeString.split(' ');
  
    // Dividimos a data em dia, mês e ano:
    if (padraoEua)
    {
        var [MM, DD, YYYY] = date.split('/');
    } else {
        var [DD, MM, YYYY] = date.split('/');
    }    
  
    // Dividimos o tempo em hora e minutos:
    const [HH, mm] = time.split(':');
  
    // Retornamos a data formatada em um padrão compatível com ISO:
    return `${YYYY}-${MM}-${DD}T${HH}:${mm}`;
}

export function daysBetweenTwoDates(date1, date2) {
    date2 = new Date(date2);
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    var diffDays = Math.round(timeDiff / (1000 * 3600 * 24));
    return diffDays; 
}

export function calculateMinutesDifference(startTime, endTime) {
    if (!startTime || !endTime) return "-";

    // Remove segundos e milissegundos para comparação precisa
    const start = moment(startTime).add(3, "hours").seconds(0).milliseconds(0);
    const end = moment(endTime).add(3, "hours").seconds(0).milliseconds(0);

    const difference = end.diff(start, "minutes");
    return difference;
}
