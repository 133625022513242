import React, {useState} from "react";
import { useEffect } from "react";
import { Brush, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Surface, Symbols, Tooltip, XAxis, YAxis } from "recharts";
import { useCurrentPng } from "recharts-to-png";
import CardPresentational from "../../../../Presentational/Sinan/CardPresentational";
import CustomTooltip from "../../../../Utils/CustomTooltip";
import RenderCustomizedLegend from "../../../../Utils/RenderCustomizedLegend";
import loadGraphicInformations from "../../../../Utils/LoadGraphicInformations";

const colors = ["#E60017", "#FC6D0D", "#B10FFF", "#0C28F2", "#D44D13"];

const IMAChartContainer = ({chartDataSets, maxDomain, data}) => {
    const [getpng, {ref}] = useCurrentPng();
    const [elementData, setElementData] = useState();

    useEffect(() => {
        var graphicInfo = loadGraphicInformations({data: data});

        setElementData(graphicInfo ? graphicInfo.elementData : null);
    }, [chartDataSets,data])

    const buildChartElement = () => {
        var hasYear = false;
        data.map((ano, index) => {
            if(ano.label) {
                hasYear = true;
            }
        })
        return (
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    id={"ImaChartContainer"}
                    data={chartDataSets}
                    ref={ref}
                    margin={{top:30, right:15}}
                >
                    <text x={"50%"} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
                        <tspan fontSize="16" fontWeight={"bolder"}>IMA </tspan>
                    </text>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label" style={{fontSize:12}}/>
                    <YAxis style={{fontSize:12}} domain={[0, maxDomain]} tickCount={101} domain={[0, 100]} />
                    <Tooltip content={<CustomTooltip type={"IMA"} />} />
                    {
                        hasYear
                        ?
                        <Legend 
                            align="center" 
                            verticalAlign="top" 
                            content={() => RenderCustomizedLegend({
                                elementData: elementData,
                                colors: colors, 
                                setElementData: setElementData,
                                type: "line"
                            })}
                        />
                        :
                        <Legend 
                            align="center" 
                            verticalAlign="top" 
                        />
                    }
                    <Brush dataKey={"label"}/>
                    {elementData && elementData.map((ano, index) => (
                        !hasYear  || (hasYear && ano.checked)
                        ?
                        <Line dataKey={ano.label || "IM"} stroke={colors[index]} />
                        :
                        <></>
                    ))}
                </LineChart>
            </ResponsiveContainer>
        )
    }

    //construindo propriedades do presentational
    const style={
        cardHeaderText: {
            color:"black",
            fontWeight:"bold",
            fontSize:"0.97rem"
        },
    }

    const textCardHeader = "Índice Médio de Mosquitos";
    
    return(
        <CardPresentational
            CardId = {"IMAChartContainer"}
            style={style}
            textCardHeader={textCardHeader}
            element={buildChartElement()}
            getpng={getpng}
        />
    );
}

export default IMAChartContainer;