import React, { useState, useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { Alert, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Select } from "../../Wrappers/SelectAll";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { LayersControl, Map, Marker, TileLayer } from "react-leaflet";
import Leaflet from "leaflet";
import { GOOGLE_MAPS_API_KEY } from "../../../constants/WebFormConstants";
import { updateHistories } from "../../../services/api/Trap";
import { checkPermissionComponent } from "../../../services/utils/permission";
import { toast } from "react-toastify";

const TrapHistoryModal = ({
  modalType,
  modal,
  toggle,
  selectedTrapData,
  dateFormatOptions,
  getTrapTypeSurname,
  selectedTrap,
  selectComponentStyles,
  consolidatedTrapTypeOptions,
}) => {
  const [selectedToEdit, setSelectedToEdit] = useState(false);
  const [originalTrapData, setOriginalTrapData] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [modalTrapData, setModalTrapData] = useState(null);
  const [trapPositions, setTrapPositions] = useState([]);
  const [mapZoom, setMapZoom] = useState(16);
  const [showMap, setShowMap] = useState(false);

  const booleanOptions = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  const handleHistoryChanges = (key, index, value, secondKey = null) => {
    const copyOfTrapData = JSON.parse(JSON.stringify(modalTrapData));

    if (secondKey) {
      copyOfTrapData.histories[index][key][secondKey] = value;
    } else {
      if (key == "latlong") {
        copyOfTrapData.histories[index]["latitude"] = value[0];
        copyOfTrapData.histories[index]["longitude"] = value[1];
      } else {
        copyOfTrapData.histories[index][key] = value;
      }
    }

    setModalTrapData(copyOfTrapData);
  };

  const buildTrapPositions = (trapData) => {
    const trapPositionsAux = trapData.histories.map((trap) => [
      trap.latitude,
      trap.longitude,
    ]);
    setTrapPositions(trapPositionsAux);
  };

  useEffect(() => {
    if (modalTrapData) {
      buildTrapPositions(modalTrapData);
    }
  }, [modalTrapData]);

  useEffect(() => {
    if (selectedTrapData) {
      const copyOfSelectedTrapData = JSON.parse(
        JSON.stringify(selectedTrapData)
      );

      if (!selectedToEdit) {
        buildTrapPositions(copyOfSelectedTrapData);
      }
      console.log("copyOfSelectedTrapData", copyOfSelectedTrapData);
      copyOfSelectedTrapData.histories.sort((a, b) => {
        return (
          new Date(b.statusDate).getTime() - new Date(a.statusDate).getTime()
        );
      });

      setModalTrapData(copyOfSelectedTrapData);
    }
  }, [selectedTrapData]);

  const DraggableMarker = ({ initialPosition, setPositions, index }) => {
    const markerRef = useRef();

    const handleMarkerOnDragEnd = (target) => {
      if (!target) return;

      const newLatitude = target._latlng.lat.toFixed(7);
      const newLongitude = target._latlng.lng.toFixed(7);

      const trapPositionsAux = [...trapPositions];
      trapPositionsAux[index] = [newLatitude, newLongitude];

      handleHistoryChanges("latlong", index, [newLatitude, newLongitude]);
      setPositions(trapPositionsAux);
    };

    const trapIcon = Leaflet.icon({
      iconUrl: "https://i.ibb.co/q09c9H7/g-int2care.png",
      iconSize: [20, 20],
    });

    return (
      <Marker
        draggable={true}
        ondragend={({ target }) => handleMarkerOnDragEnd(target)}
        position={initialPosition}
        ref={markerRef}
        icon={trapIcon}
      />
    );
  };

  return (
    <Modal size="lg" fade isOpen={modal} toggle={() => toggle(false)}>
      <ModalHeader>
        {modalType === "details" ? (
          <h2>Histórico da Armadilha</h2>
        ) : (
          <span>Remoção de Armadilha</span>
        )}
      </ModalHeader>
      <ModalBody>
        {modalType === "details" && modalTrapData ? (
          <div className="row">
            <div className="col-9" style={{ flex: "100%", maxWidth: "100%" }}>
              {modalTrapData.histories &&
                modalTrapData.histories.map((history, index) => {
                  const historyDate = new Date(history.statusDate);
                  const historyDateCreate = new Date(history.createAt);
                  historyDateCreate.setHours(historyDateCreate.getHours() - 3);
                  const dateCreateStatusFormated = historyDateCreate.toLocaleString(
                    "pt-BR",
                    dateFormatOptions
                  );

                  const historyDateString = history.statusDate
                    ? historyDate.toISOString().split("T")[0]
                    : "";
                  const dateFormated = historyDate.toLocaleString(
                    "pt-BR",
                    dateFormatOptions
                  );
                  const defaultTrapIcon = Leaflet.icon({
                    iconUrl: "https://i.ibb.co/q09c9H7/g-int2care.png",
                    iconSize: [20, 20],
                  });

                  var projectString = "";
                  
                  if(history.trapHistoryProject) {
                    history.trapHistoryProject.map(projectObj => {
                      projectString += projectObj.project.name;
                    })
                  }

                  console.log("projectString", projectString)

                  const trapToEdit = selectedToEdit && selectedIndex === index;
                  const anotherTrapToEdit =
                    selectedToEdit && selectedIndex !== index;

                  return (
                    <>
                      {trapToEdit ? (
                        <div className="row">
                          <div className="col-10" style={{ maxWidth: "100%" }}>
                            <span className="h3 text-black">
                              Data do status:
                            </span>{" "}
                            <input
                              type="date"
                              className="form-control"
                              value={historyDateString}
                              onChange={({ target }) =>
                                handleHistoryChanges(
                                  "statusDate",
                                  index,
                                  new Date(target.value)
                                    .toISOString()
                                    .slice(0, 19)
                                )
                              }
                              placeholder="Digite o data do histórico"
                            />
                            <br />
                            <span className="h3 text-black">
                              Tipo de armadilha:
                            </span>{" "}
                            {
                              consolidatedTrapTypeOptions.find(
                                (obj) => obj.value === history.trap.trapTypeId
                              ).label
                            }
                            <br />
                            {
                              projectString && projectString != ""
                              ?
                              <>
                                <span className="h3 text-black">
                                  Atividades:
                                </span>{" "}
                                  {projectString}
                                <br />
                              </>
                              :
                              <></>
                            }
                            
                            <br />
                            <span className="h3 text-black">
                              Armadilha foi instalada:
                            </span>
                            <Select
                              styles={selectComponentStyles}
                              placeholder={"Selecione"}
                              isClearable={false}
                              options={booleanOptions}
                              value={booleanOptions.find(
                                (obj) => obj.value === history.trapWasInstalled
                              )}
                              onChange={(obj) =>
                                handleHistoryChanges(
                                  "trapWasInstalled",
                                  index,
                                  obj.value
                                )
                              }
                            />
                            <br />
                            <span className="h3 text-black">
                              Armadilha foi lida:
                            </span>
                            <Select
                              styles={selectComponentStyles}
                              placeholder={"Selecione"}
                              isClearable={false}
                              options={booleanOptions}
                              value={booleanOptions.find(
                                (obj) => obj.value === history.trapWasRead
                              )}
                              onChange={(obj) =>
                                handleHistoryChanges(
                                  "trapWasRead",
                                  index,
                                  obj.value
                                )
                              }
                            />
                            <br />
                            <span className="h3 text-black">
                              Armadilha foi desinstalada:
                            </span>
                            <Select
                              styles={selectComponentStyles}
                              placeholder={"Selecione"}
                              isClearable={false}
                              options={booleanOptions}
                              value={booleanOptions.find(
                                (obj) => obj.value === history.trapWasRemoved
                              )}
                              onChange={(obj) =>
                                handleHistoryChanges(
                                  "trapWasRemoved",
                                  index,
                                  obj.value
                                )
                              }
                            />
                            <br />
                            <span className="h3 text-black">
                              Foi coletada amostra:
                            </span>{" "}
                            {history.wasCollectedSample ? "Sim" : "Não"}
                            <br />
                            <br />
                          </div>
                          <div className="col-2" style={{ maxWidth: "100%" }}>
                            <div className="row">
                              <div className="col-12">
                                <button
                                  data-tip
                                  data-for="editTrapHistory"
                                  className="btn btn-primary"
                                  style={{
                                    width: "40px",
                                    margin: "5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  id="tooltip204269497"
                                  onClick={() => {
                                    const copyOfHistory = JSON.parse(
                                      JSON.stringify(history)
                                    );
                                    copyOfHistory.latitude =
                                      trapPositions[index][0];
                                    copyOfHistory.longitude =
                                      trapPositions[index][1];

                                    setSelectedIndex(null);
                                    setSelectedToEdit(false);

                                    updateHistories([copyOfHistory]).then(
                                      (response) => {
                                        if (
                                          response &&
                                          response.status >= 200 &&
                                          response.status < 300
                                        ) {
                                          toast.success(
                                            "O histórico foi salvo com sucesso."
                                          );
                                        }
                                      }
                                    );
                                  }}
                                  type="button"
                                >
                                  <i
                                    className="fa fa-save"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <ReactTooltip
                                  effect="solid"
                                  type="info"
                                  id="editTrapHistory"
                                >
                                  Salvar histórico da armadilha
                                </ReactTooltip>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <button
                                  data-tip
                                  data-for="editTrapHistory"
                                  className="btn btn-primary"
                                  style={{
                                    width: "40px",
                                    margin: "5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  id="tooltip204269497"
                                  onClick={() => {
                                    setSelectedIndex(null);
                                    setSelectedToEdit(false);

                                    const copyOfOriginalTrapData = JSON.parse(
                                      JSON.stringify(originalTrapData)
                                    );

                                    setModalTrapData(copyOfOriginalTrapData);
                                    buildTrapPositions(copyOfOriginalTrapData);
                                  }}
                                  type="button"
                                >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <ReactTooltip
                                  effect="solid"
                                  type="info"
                                  id="editTrapHistory"
                                >
                                  Cancelar edição do histórico da armadilha
                                </ReactTooltip>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-10" style={{ maxWidth: "100%" }}>
                            <span className="h3 text-black">
                              Data do status:
                            </span>{" "}
                            {dateFormated}
                            <br />
                            <span className="h3 text-black">
                              Tipo de armadilha:
                            </span>{" "}
                            {
                              consolidatedTrapTypeOptions.find(
                                (obj) => obj.value === history.trap.trapTypeId
                              ).label
                            }
                            <br />
                            {
                              projectString && projectString != ""
                              ?
                              <>
                                <span className="h3 text-black">
                                  Atividades:
                                </span>{" "}
                                  {projectString}
                                <br />
                              </>
                              :
                              <></>
                            }
                            <span className="h3 text-black">
                              Armadilha foi instalada:
                            </span>{" "}
                            {history.trapWasInstalled ? "Sim" : "Não"}
                            <br />
                            <span className="h3 text-black">
                              Armadilha foi lida:
                            </span>{" "}
                            {history.trapWasRead ? "Sim" : "Não"}
                            <br />
                            <span className="h3 text-black">
                              Armadilha foi desinstalada:
                            </span>{" "}
                            {history.trapWasRemoved ? "Sim" : "Não"}
                            <br />
                            <span className="h3 text-black">
                              Foi coletada amostra:
                            </span>{" "}
                            {history.wasCollectedSample ? "Sim" : "Não"}
                            <br />
                            <span className="h3 text-black">
                              Latitude:
                            </span>{" "}
                            {typeof history.latitude === "string"
                              ? history.latitude
                              : history.latitude.toFixed(7)}
                            <br />
                            <span className="h3 text-black">
                              Longitude:
                            </span>{" "}
                            {typeof history.longitude === "string"
                              ? history.longitude
                              : history.longitude.toFixed(7)}
                              <br />
                              <span className="h3 text-black">Data do envio das informações:</span>{" "}
                              {dateCreateStatusFormated + " "}
                              <i className="fa fa-info" data-tip
                                data-for="info_data"></i>
                              <ReactTooltip
                                effect="solid"
                                type="info"
                                id="info_data"
                              >
                                Representa quando o histórico da armadilha entrou no sistema <br />
                                por sincronização do aplicativo ou pela digitação do formulário.
                              </ReactTooltip>
                              <br />
                            </div>
                          <div className="col-2" style={{ maxWidth: "100%" }}>
                            {checkPermissionComponent(
                              "bb5b6faa-ba61-4e67-87d9-bb30dfe5c8ab",
                              "update"
                            ) ? (
                              anotherTrapToEdit ? (
                                <></>
                              ) : (
                                <>
                                  <button
                                    data-tip
                                    data-for="editTrapHistory"
                                    className="btn btn-primary"
                                    style={{
                                      width: "40px",
                                      margin: "5px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    id="tooltip204269497"
                                    onClick={() => {
                                      setSelectedIndex(index);
                                      setSelectedToEdit(true);
                                      const copyOfSelectedTrapData = JSON.parse(
                                        JSON.stringify(modalTrapData)
                                      );
                                      setOriginalTrapData(
                                        copyOfSelectedTrapData
                                      );
                                    }}
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-pen"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <ReactTooltip
                                    effect="solid"
                                    type="info"
                                    id="editTrapHistory"
                                  >
                                    Editar histórico da armadilha
                                  </ReactTooltip>
                                </>
                              )
                            ) : null}

                            {/* {checkPermissionComponent(
                              "bb5b6faa-ba61-4e67-87d9-bb30dfe5c8ab",
                              "delete"
                            ) ? (
                              history.trapWasInstalled ||
                              history.trapWasRemoved ? (
                                ""
                              ) : (
                                <>
                                  <button
                                    data-tip
                                    data-for="deleteTrapHistory"
                                    className="btn btn-warning"
                                    style={{
                                      width: "40px",
                                      margin: "5px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    id="tooltip204269497"
                                    onClick={() => {
                                      setSelectedIndex(index);
                                      setSelectedToEdit(true);
                                      const copyOfSelectedTrapData = JSON.parse(
                                        JSON.stringify(modalTrapData)
                                      );
                                      setOriginalTrapData(
                                        copyOfSelectedTrapData
                                      );
                                    }}
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <ReactTooltip
                                    effect="solid"
                                    type="info"
                                    id="deleteTrapHistory"
                                  >
                                    Deletar histórico da armadilha
                                  </ReactTooltip>
                                </>
                              )
                            ) : null} */}
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-12" style={{ maxWidth: "100%" }}>
                          {trapToEdit && (
                            <div>{`Lat: ${trapPositions[index][0]}, Long: ${trapPositions[index][1]}`}</div>
                          )}
                          {showMap && (
                            <Map
                              center={[history.latitude, history.longitude]}
                              zoom={mapZoom}
                              onzoomend={(event) =>
                                setMapZoom(event.target.getZoom())
                              }
                              minZoom={10}
                              maxZoom={20}
                              maxBounds={[
                                [85, -180],
                                [-85, 180],
                              ]}
                            >
                              <LayersControl>
                                <LayersControl.BaseLayer checked name="Normal">
                                  <TileLayer
                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                  />
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer name="Satélite">
                                  <ReactLeafletGoogleLayer
                                    googleMapsLoaderConf={{
                                      KEY: GOOGLE_MAPS_API_KEY,
                                    }}
                                    type={"hybrid"}
                                  />
                                </LayersControl.BaseLayer>
                              </LayersControl>
                              {trapToEdit ? (
                                <DraggableMarker
                                  initialPosition={trapPositions[index]}
                                  setPositions={setTrapPositions}
                                  index={index}
                                />
                              ) : (
                                <Marker
                                  draggable={false}
                                  position={trapPositions[index]}
                                  icon={defaultTrapIcon}
                                />
                              )}
                            </Map>
                          )}
                          <button
                            className="form-control mt-3"
                            onClick={() => setShowMap(!showMap)}
                            style={{ cursor: "pointer" }}
                          >
                            <i
                              className="fa fa-eye"
                              aria-hidden="true"
                              style={{ marginRight: "10px" }}
                            ></i>
                            MOSTRAR / OCULTAR MAPA
                          </button>
                        </div>
                      </div>
                      <hr />
                    </>
                  );
                })}
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-9">
              <span className="h2 text-black">
                Deseja desinstalar a armadilha {selectedTrap}?
              </span>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {modalType === "details" ? (
          <div className="row" style={{ justifyContent: "end", width: "50%" }}>
            <div className="col-6" style={{ maxWidth: "100%" }}>
              <button
                className="form-control"
                onClick={() => toggle(false)}
                style={{ cursor: "pointer" }}
              >
                FECHAR
              </button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-6">
              <button className="form-control" onClick={() => toggle(false)}>
                CANCELAR
              </button>
            </div>
            <div className="col-6">
              <button className="btn btn-primary" onClick={() => toggle(true)}>
                REMOVER
              </button>
            </div>
          </div>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default TrapHistoryModal;
