import request from '../utils/request';

export function fetchResidents(filter) {
  return request({
    url: '/Residents/All',
    method: 'post',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    data: filter
  });
}

export function downloadCsv(filter) {
  return request({
    url: '/Csv',
    method: 'post',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    data: filter
  });
}

export function getSymptomaticsByDay(filter) {
  return request({
    url: '/Residents/SymptomaticsByDay',
    method: 'post',
    data: filter
  });
}

export function getActiveSearchByDemandId(DemandId){
  return request({
    url: '/ActiveSearch/GetAllByDemand/'+DemandId,
    method: 'get'
  });
}