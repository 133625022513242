import React from 'react'
import {
  Circle,
  Map,
  Popup,
  TileLayer,
  FeatureGroup,
  LayersControl,
  withLeaflet
} from'react-leaflet';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import PrintControlDefault  from 'react-leaflet-easyprint';
import FullscreenControl from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/dist/styles.css'
const { BaseLayer, Overlay } = LayersControl;
const PrintControl = withLeaflet(PrintControlDefault);


const MyPopupMarker = ({ content, position }) => (
  <Circle color ="red"center={position} radius={8}>
    <Popup>{content}</Popup>
  </Circle>
)

const MyMarkersList = ({ markers }) => {
  const items = markers.map(({ key, ...props }) => (
    
    <MyPopupMarker key={key} {...props} />
  ))
  return <React.Fragment>{items}</React.Fragment>
}

export default class ResidentMap extends React.Component{
    state  = {
        coordenadas : [],
        residents:[]
      }
  
      constructor(props){
        super(props);
        var coordenadas = [];  
        var residents = [];
        this.props.residents.map((resident, id) =>{
          coordenadas.push([resident.latitude,resident.longitude,"300"]);
          residents.push(({
            position: [resident.latitude,resident.longitude],
            content:resident.dadosPrincipais.name+"\r\t"+resident.address.logradouro + " "+ resident.address.numero+", "+resident.address.complemento,
            key: resident.residente,
          }));
        });
        this.state.residents = residents;
        this.state.coordenadas = coordenadas;
    }
    render() {
      
        return (
          <div id="mapid">
             <Map center={[-22.848046, -43.321953]} zoom={13}>
                <FullscreenControl 
                  position="topleft"
                  title="Tela Cheia"
                  titleCancel="Sair da Tela Cheia"
                />
              <LayersControl position="topright">
                  <BaseLayer checked name="Normal">
                    <TileLayer
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                  </BaseLayer>
                  <BaseLayer name="Preto e Branco">
                    <TileLayer
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                    />
                  </BaseLayer>
                  <Overlay name="Pontos">
                    <FeatureGroup color="black">
                      <MyMarkersList markers={this.state.residents} />
                    </FeatureGroup>
                  </Overlay>
                  <Overlay name="Calor">
                      
                      <HeatmapLayer
                        points={this.state.coordenadas}
                        longitudeExtractor={m => m[1]}
                        latitudeExtractor={m => m[0]}
                        intensityExtractor={m => parseFloat(m[2])}
                      />
                  
                     </Overlay>
              </LayersControl>
              <PrintControl position="topleft" sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={false} title="Export as PNG" exportOnly />

            </Map>
          </div>
        );
      }
  }
