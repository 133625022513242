import React from "react";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { getUserData } from "../../../services/utils/auth";
import { useReactToPrint } from "react-to-print";
import UninstallAdiFormPrint from "../../../views/pages/FormsWeb/FormsToPrint/Uninstall/UninstallAdiFormPrint";

const UninstallAdiFormPrintModal = ({isModalOpen, setIsModalOpen, trapTypeName}) => {
  const contentToPrintRef = React.useRef(null);

  const handleFormPrint = useReactToPrint({
    documentTitle: "Print this out!",
    removeAfterPrint: true,
    content: () => contentToPrintRef.current,
  });

  return (
    <Modal
      size="lg"
      fade
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(!isModalOpen)}
      style={{ maxWidth: window.innerWidth > 1300 ? "1200px" : "" }}
    >
      <ModalHeader>
        <span>Formulário de desinstalação</span>
      </ModalHeader>
      <ModalBody>
        <UninstallAdiFormPrint
          organizationId={getUserData("organizationId")}
          trapTypeName={trapTypeName}
          ref={contentToPrintRef}
        />
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col style={{ display: "flex" }}>
            <button
              className="btn btn-primary"
              onClick={handleFormPrint}
              style={{ cursor: "pointer" }}
            >
              IMPRIMIR
            </button>
            <button
              className="form-control"
              onClick={() => setIsModalOpen(false)}
              style={{ cursor: "pointer" }}
            >
              FECHAR
            </button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default UninstallAdiFormPrintModal;
