/*  */

export const SCHOOLS_DATA = [
  {
    label: "CCI/CIPS - Participantes",
    value: "1522cb14-b49b-40fe-a0e3-1af3b1c1ef75",
  },
  {
    label: "CECI - Participantes",
    value: "348c05f8-59cf-44c7-a0e2-ad5c5f639b42",
  },
  {
    label: "CEI DIRET - Participantes",
    value: "5aa24ec5-e518-44e9-b30b-6a59411cd06c",
  },
  {
    label: "CEI INDIR - Participantes",
    value: "99b1ec24-f331-43de-96e1-3463b3f7051b",
  },
  {
    label: "CEMEI - Participantes",
    value: "01db2626-07e6-460d-9e20-daaad138d118",
  },
  {
    label: "CEU AT COM - Participantes",
    value: "4149e258-deb3-4b8e-a755-fe41ba47c877",
  },
  {
    label: "CEU CEI - Participantes",
    value: "095ddce0-c55a-4c9b-ac42-ce3a7e1ac108",
  },
  {
    label: "CEU CEMEI - Participantes",
    value: "2c16e8d2-47ac-4537-96e2-f3c6f7c9a75a",
  },
  {
    label: "CEU EMEF - Participantes",
    value: "7f4e822e-099b-408a-b19b-48913a61f29c",
  },
  {
    label: "CEU EMEI - Participantes",
    value: "12cb831e-4979-41dd-b27b-f4fe030f6677",
  },
  {
    label: "CIEJA - Participantes",
    value: "0718a261-020e-4597-bb50-1147aba97b64",
  },
  {
    label: "CMCT - Participantes",
    value: "3e4f5ab5-962a-43d4-a9b2-c815338773f4",
  },
  {
    label: "CR.P.CONV - Participantes",
    value: "4a10f2f6-c950-441e-84b5-94a951983d9e",
  },
  {
    label: "DIR EDUC - Participantes",
    value: "8eeda6a8-7b3c-4bb1-9cb1-8d38061d9f5d",
  },
  {
    label: "EMEBS - Participantes",
    value: "fe50db1e-e49e-4934-a4ba-13d2ef615dec",
  },
  {
    label: "EMEF - Participantes",
    value: "93942436-7221-4359-8764-75467c424298",
  },
  {
    label: "EMEF P FOM - Participantes",
    value: "c1ee43b8-70f7-4cbf-ad30-5908254c4f0c",
  },
  {
    label: "EMEFM - Participantes",
    value: "7f901edc-8d4b-432f-b424-a0f0fe5d1336",
  },
  {
    label: "EMEI - Participantes",
    value: "25cb55fa-4d6b-4ab6-9e1d-00b9b0a5cc42",
  },
  {
    label: "EMEI P FOM - Participantes",
    value: "3d4b4831-8de0-44f6-831e-4b42ec4897ca",
  },
  {
    label: "ESP CONV - Participantes",
    value: "e8289128-9675-48d9-8f3a-0cbcdca2e180",
  },
  {
    label: "MOVA - Participantes",
    value: "2c79bbaa-674c-4179-b8c1-5df1dea4d266",
  },
  {
    label: "CCI/CIPS - Não Participantes",
    value: "0518d349-feb6-4130-a25d-92147e82c40d",
  },
  {
    label: "CECI - Não Participantes",
    value: "cfb97fe4-245a-4f91-8912-2bf92dfbb7b8",
  },
  {
    label: "CEI DIRET - Não Participantes",
    value: "9d715196-d591-41bc-8505-02e0ae42a689",
  },
  {
    label: "CEI INDIR - Não Participantes",
    value: "9cfe05d8-2c8e-41ad-8434-cc072e999124",
  },
  {
    label: "CEMEI - Não Participantes",
    value: "c1c4e5f8-576f-44bf-b5c9-7f9388cfcded",
  },
  {
    label: "CEU AT COM - Não Participantes",
    value: "831ab589-1567-4c31-8982-d9c50fad1a16",
  },
  {
    label: "CEU CEI - Não Participantes",
    value: "f73cd9d4-2878-4246-82ad-50d923cea7fb",
  },
  {
    label: "CEU CEMEI - Não Participantes",
    value: "9475e608-d34b-48b5-9fcb-8ebf38b54139",
  },
  {
    label: "CEU EMEF - Não Participantes",
    value: "b40c4a48-1568-457f-8088-17428cd01a41",
  },
  {
    label: "CEU EMEI - Não Participantes",
    value: "ddf5d21d-6c75-4c61-b9c5-649a23c4520f",
  },
  {
    label: "CIEJA - Não Participantes",
    value: "89cb2d2b-aa59-42f3-bb58-1a44826dcb0b",
  },
  {
    label: "CMCT - Não Participantes",
    value: "cbe0c93a-221b-4f14-830f-352e4d540109",
  },
  {
    label: "CR.P.CONV - Não Participantes",
    value: "84373f59-ab7b-4ec5-b747-cb671834564a",
  },
  {
    label: "DIR EDUC - Não Participantes",
    value: "20956780-7a56-43c0-baad-1b47f3a33266",
  },
  {
    label: "EMEBS - Não Participantes",
    value: "f57ba68c-a82d-4b0f-98b2-6b21046d90ca",
  },
  {
    label: "EMEF - Não Participantes",
    value: "feb7043b-e45f-4199-9326-21441ee7737b",
  },
  {
    label: "EMEF P FOM - Não Participantes",
    value: "00bdd242-1d4d-4dea-82d0-aad79337d54a",
  },
  {
    label: "EMEFM - Não Participantes",
    value: "6e2c6b8c-babb-4f08-a4e4-e751d3f69045",
  },
  {
    label: "EMEI - Não Participantes",
    value: "f99c3bcc-eb69-4c4d-8c23-433eafcfab0e",
  },
  {
    label: "EMEI P FOM - Não Participantes",
    value: "6a6eff8a-9d16-46e1-938a-cb6596f5a76d",
  },
  {
    label: "ESP CONV - Não Participantes",
    value: "738307af-e3bc-458a-9d95-1599f97b3284",
  },
  {
    label: "MOVA - Não Participantes",
    value: "c3d71435-534a-42b6-b650-88c2389bfa22",
  },
];

export const PARTICIPANTS_SCHOOLS_DATA = [
  {
    label: "CCI/CIPS - Participantes",
    value: "1522cb14-b49b-40fe-a0e3-1af3b1c1ef75",
  },
  {
    label: "CECI - Participantes",
    value: "348c05f8-59cf-44c7-a0e2-ad5c5f639b42",
  },
  {
    label: "CEI DIRET - Participantes",
    value: "5aa24ec5-e518-44e9-b30b-6a59411cd06c",
  },
  {
    label: "CEI INDIR - Participantes",
    value: "99b1ec24-f331-43de-96e1-3463b3f7051b",
  },
  {
    label: "CEMEI - Participantes",
    value: "01db2626-07e6-460d-9e20-daaad138d118",
  },
  {
    label: "CEU AT COM - Participantes",
    value: "4149e258-deb3-4b8e-a755-fe41ba47c877",
  },
  {
    label: "CEU CEI - Participantes",
    value: "095ddce0-c55a-4c9b-ac42-ce3a7e1ac108",
  },
  {
    label: "CEU CEMEI - Participantes",
    value: "2c16e8d2-47ac-4537-96e2-f3c6f7c9a75a",
  },
  {
    label: "CEU EMEF - Participantes",
    value: "7f4e822e-099b-408a-b19b-48913a61f29c",
  },
  {
    label: "CEU EMEI - Participantes",
    value: "12cb831e-4979-41dd-b27b-f4fe030f6677",
  },
  {
    label: "CIEJA - Participantes",
    value: "0718a261-020e-4597-bb50-1147aba97b64",
  },
  {
    label: "CMCT - Participantes",
    value: "3e4f5ab5-962a-43d4-a9b2-c815338773f4",
  },
  {
    label: "CR.P.CONV - Participantes",
    value: "4a10f2f6-c950-441e-84b5-94a951983d9e",
  },
  {
    label: "DIR EDUC - Participantes",
    value: "8eeda6a8-7b3c-4bb1-9cb1-8d38061d9f5d",
  },
  {
    label: "EMEBS - Participantes",
    value: "fe50db1e-e49e-4934-a4ba-13d2ef615dec",
  },
  {
    label: "EMEF - Participantes",
    value: "93942436-7221-4359-8764-75467c424298",
  },
  {
    label: "EMEF P FOM - Participantes",
    value: "c1ee43b8-70f7-4cbf-ad30-5908254c4f0c",
  },
  {
    label: "EMEFM - Participantes",
    value: "7f901edc-8d4b-432f-b424-a0f0fe5d1336",
  },
  {
    label: "EMEI - Participantes",
    value: "25cb55fa-4d6b-4ab6-9e1d-00b9b0a5cc42",
  },
  {
    label: "EMEI P FOM - Participantes",
    value: "3d4b4831-8de0-44f6-831e-4b42ec4897ca",
  },
  {
    label: "ESP CONV - Participantes",
    value: "e8289128-9675-48d9-8f3a-0cbcdca2e180",
  },
  {
    label: "MOVA - Participantes",
    value: "2c79bbaa-674c-4179-b8c1-5df1dea4d266",
  },
];

export const NOT_PARTICIPANTS_SCHOOLS_DATA = [
  {
    label: "CCI/CIPS - Não Participantes",
    value: "0518d349-feb6-4130-a25d-92147e82c40d",
  },
  {
    label: "CECI - Não Participantes",
    value: "cfb97fe4-245a-4f91-8912-2bf92dfbb7b8",
  },
  {
    label: "CEI DIRET - Não Participantes",
    value: "9d715196-d591-41bc-8505-02e0ae42a689",
  },
  {
    label: "CEI INDIR - Não Participantes",
    value: "9cfe05d8-2c8e-41ad-8434-cc072e999124",
  },
  {
    label: "CEMEI - Não Participantes",
    value: "c1c4e5f8-576f-44bf-b5c9-7f9388cfcded",
  },
  {
    label: "CEU AT COM - Não Participantes",
    value: "831ab589-1567-4c31-8982-d9c50fad1a16",
  },
  {
    label: "CEU CEI - Não Participantes",
    value: "f73cd9d4-2878-4246-82ad-50d923cea7fb",
  },
  {
    label: "CEU CEMEI - Não Participantes",
    value: "9475e608-d34b-48b5-9fcb-8ebf38b54139",
  },
  {
    label: "CEU EMEF - Não Participantes",
    value: "b40c4a48-1568-457f-8088-17428cd01a41",
  },
  {
    label: "CEU EMEI - Não Participantes",
    value: "ddf5d21d-6c75-4c61-b9c5-649a23c4520f",
  },
  {
    label: "CIEJA - Não Participantes",
    value: "89cb2d2b-aa59-42f3-bb58-1a44826dcb0b",
  },
  {
    label: "CMCT - Não Participantes",
    value: "cbe0c93a-221b-4f14-830f-352e4d540109",
  },
  {
    label: "CR.P.CONV - Não Participantes",
    value: "84373f59-ab7b-4ec5-b747-cb671834564a",
  },
  {
    label: "DIR EDUC - Não Participantes",
    value: "20956780-7a56-43c0-baad-1b47f3a33266",
  },
  {
    label: "EMEBS - Não Participantes",
    value: "f57ba68c-a82d-4b0f-98b2-6b21046d90ca",
  },
  {
    label: "EMEF - Não Participantes",
    value: "feb7043b-e45f-4199-9326-21441ee7737b",
  },
  {
    label: "EMEF P FOM - Não Participantes",
    value: "00bdd242-1d4d-4dea-82d0-aad79337d54a",
  },
  {
    label: "EMEFM - Não Participantes",
    value: "6e2c6b8c-babb-4f08-a4e4-e751d3f69045",
  },
  {
    label: "EMEI - Não Participantes",
    value: "f99c3bcc-eb69-4c4d-8c23-433eafcfab0e",
  },
  {
    label: "EMEI P FOM - Não Participantes",
    value: "6a6eff8a-9d16-46e1-938a-cb6596f5a76d",
  },
  {
    label: "ESP CONV - Não Participantes",
    value: "738307af-e3bc-458a-9d95-1599f97b3284",
  },
  {
    label: "MOVA - Não Participantes",
    value: "c3d71435-534a-42b6-b650-88c2389bfa22",
  },
]