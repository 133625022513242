import React from "react";
import { Link } from "react-router-dom";
import "./../../assets/css/navbar.css";
import CsvDownloadButton from 'react-json-to-csv'

// reactstrap components
import {
    Container,
} from "reactstrap";
import { checkPermissionComponent } from "../../services/utils/permission";
import "./FilterEmployees.css";
import {  fetchEmployee,fetchEmployeeData, fetchPersons } from "../../services/api/Employee";
import { fetchModules } from "../../services/api/Modules";
import { getPermissions, getUserByEmployeeId } from "../../services/api/Users";
import { getPermissionsToken } from "../../services/utils/auth";



class FilterEmployees extends React.Component {
    state ={
        filterName:"",
        filterModule:"",
        filterStatus:"",
        EmployeesFormated: [],
        Modules: null,
    }
    handleChange = e => {
        let {name,value} = e.target;
        this.setState({
            [name]: value
        });
      };

      formatDate = (dataString) => {
        const dataObjeto = new Date(dataString);

        const ano = dataObjeto.getFullYear();
        const mes = (dataObjeto.getMonth() + 1).toString().padStart(2, '0'); // +1 porque os meses começam do zero
        const dia = dataObjeto.getDate().toString().padStart(2, '0');

        const dataFormatada = `${dia}/${mes}/${ano}`;

        return dataFormatada;
      }

      formatEmployees = (modules) => {
        var Employees = this.props.Employees;
    
        fetchPersons().then(persons => {
            var promises = Employees.map(employee => {
                var requestPerson = persons.data.filter(rp => rp.id == employee.personId)[0];
                employee.dadosFuncionario = {
                    nome: requestPerson.name,
                    dataAniversario: this.formatDate(requestPerson.birthDate.substring(0, 10)),
                    email: requestPerson.email,
                    cpf: requestPerson.cpf,
                    cnh: requestPerson.cnh,
                    categoriaCnh: requestPerson.cnhCategory,
                    celular: requestPerson.mobile,
                    telefone: requestPerson.telephone,
                    rg: requestPerson.rg,
                    dataCriacao: this.formatDate(requestPerson.createAt.substring(0, 10))
                };

                var requestEmployeeData = requestPerson.employee;
                employee.formation = requestEmployeeData.formation;
                employee.admissionDate = requestEmployeeData.admissionDate.substring(0, 10);

                var requestUser = requestEmployeeData.users;
                if(requestUser && requestUser.permissions) {
                    var responsePermissions = requestUser.permissions;

                    var permissionsFormated = responsePermissions.reduce(function (acc, obj) {
                        var actualModule = modules.filter(module => module.id == obj['moduleId']);
    
                        let key = actualModule[0].name;                                
    
                        var objToInsert = {
                            atualizar: obj.update,
                            deletar: obj.delete,
                            criar: obj.create,
                            ler: obj.read,
                        }
    
                        var stringCRUD = "";
                        Object.keys(objToInsert).map((object) => {
                            if(objToInsert[object] == true){
                                stringCRUD += ` ${object},`
                                objToInsert[object] = "Sim";
                            } else {
                                objToInsert[object] = "Não";
                            }
                        })
    
                        if(stringCRUD.length > 0){
                            stringCRUD = stringCRUD.substring(0, stringCRUD.length - 1);;
                        }
    
                        obj = stringCRUD;
    
                        
    
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        acc[key] = obj;
                        return acc;
                    }, {});

                    if (Object.keys(permissionsFormated).length === 0) {
                        employee.permissoes = "Nenhuma permissão";
                    } else {
                        var stringPermissoes = "";
                        Object.keys(permissionsFormated).map((obj) => {
                            stringPermissoes += `${obj} => ${permissionsFormated[obj]} ; `;
                        })
                        employee.permissoes = stringPermissoes;
                    }
                    
                }

                if (requestUser && requestUser.userId !== "00000000-0000-0000-0000-000000000000") {
                    employee.login = requestUser.username;
                } else {
                    employee.login = "";
                    employee.permissoes = "";
                }
               

                

               
                // return Promise.all([
                //     getUserByEmployeeId(employee.id).then(response => {
                        
                //     })
                // ]).then(() => {
                    
                // });
            });
        
            Promise.all(promises).then(() => {
                var newEmployeesArray = Employees.map((objetos) => {
                    
                    return {
                        identificador: objetos.id,
                        nome: objetos.name,
                        cargo: objetos.place,
                        matricula: objetos.registration,
                        dataAdmissao: this.formatDate(objetos.admissionDate),
                        formacao: objetos.formation,
                        funcao: objetos.function ? objetos.function.name : objetos.function,
                        ...Object.keys(objetos.dadosFuncionario).reduce((acc, obj) => {
                            acc[obj] =  objetos.dadosFuncionario[obj];
                            return acc;
                        }, {}),
                        login: objetos.login,
                        permissoes: objetos.permissoes
                    };
                })
                
    
                this.setState({ EmployeesFormated: newEmployeesArray });
                this.setState({ loadingCsv: false });
            });
        })
    };
    
    

    componentDidUpdate(prevProps) {
        if(prevProps.Employees != this.props.Employees){
            this.setState({loadingCsv: true})
            var modules = fetchModules();
        
            modules.then((response) => {
                this.formatEmployees(response.data);
            })
        }
    }

    render() {
        let filteredEmployee2 = this.props.Employees;
        let filteredEmployee = filteredEmployee2.filter(
            (Employee)=>{
                return Employee.name.toLowerCase().indexOf(
                    this.state.filterName.toLowerCase()) !== -1;
            }
        );
        return (
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" >
                <Container fluid>
                    <div className="header-body">
                        <div className="row linha-form">
                            <div className="col-4 responsive-field-form-full">
                                <input type="text" name="filterName" onChange={this.handleChange} className="form-control" placeholder="Nome" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4 responsive-field-form-full">
                                <button className="btn btn-primary" onClick={()=> this.props.parentCallback(filteredEmployee)}>Pesquisar</button>
                            </div>
                            {checkPermissionComponent("ba56119d-ac43-4ec1-858e-31f2e12235e8", "create") ? 
                                <div className="col-4 responsive-field-form-full">
                                    <Link to="/admin/AddEmployee">
                                        <button className="float-right btn btn-default">Novo funcionário</button>
                                    </Link>
                                </div>
                            :null}
                            <div className="col-4 responsive-field-form-full">
                                {this.state.loadingCsv
                                ?
                                <button className="float-right btn btn-default">Carregando...</button>
                                :
                                <CsvDownloadButton className="btn btn-primary" data={this.state.EmployeesFormated} filename={'funcionarios_arquivo'}> <i className="fa fa-download" /> Baixar CSV de Funcionários</CsvDownloadButton>
                                }
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );

    }

}

export default FilterEmployees;