import React from "react";

import moment from "moment";
import LineChartGraphic from "../../../../../components/Graphics/Line/LineChartGraphic";
import { Line } from "recharts";

const BasicLineChartGraphicWrapper = ({
  data,
  employees,
  productivityFilters,
  headerText,
}) => {
  const [formatedDataForGraphic, setFormatedDataForGraphic] = React.useState(
    []
  );

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const formatedVisitsByActivityDates = formatVisitsByActivityDates(data);
    const formatedDataByDailyActivities = calculateDailyAverageTrapsData(
      formatedVisitsByActivityDates
    );
    const dataForGraphic = formatDataForGraphic(formatedDataByDailyActivities);

    setFormatedDataForGraphic(dataForGraphic);
  }, [data]);

  const formatVisitsByActivityDates = (visitsData) => {
    if (!visitsData || visitsData.length === 0) return;

    const COLLECTOR_NOT_FOUND = -1;

    const startDate = moment(productivityFilters.beginDate, "YYYY-MM-DD");
    const endDate = moment(productivityFilters.finalDate, "YYYY-MM-DD");

    const datesInBetween = [];
    const visitsByDates = [];

    for (
      let date = moment(startDate);
      date.isSameOrBefore(endDate);
      date.add(1, "days")
    )
      datesInBetween.push(date.format("YYYY-MM-DD"));

    datesInBetween.pop();

    datesInBetween.forEach((date) => {
      visitsData.forEach(({ collectorId, visits }) => {
        const collectorName = getUserName(collectorId);

        const filteredVisitsByDate = visits.filter(
          ({ activityDate }) => activityDate === date
        );
        const collectorResultIndex = visitsByDates.findIndex(
          (result) => result.collectorId === collectorId
        );
        const collectorData = visitsByDates.find(
          (visit) => visit.collectorId === collectorId
        );
        let collectorPreviousResults = collectorData
          ? collectorData.visitsByDate
          : [];

        if (filteredVisitsByDate && filteredVisitsByDate.length > 0) {
          const formatedDateData = filteredVisitsByDate.reduce(
            (accumulator, current) => {
              accumulator.installedTraps = accumulator.installedTraps.concat(
                current.installedTraps
              );
              accumulator.readedTraps = accumulator.readedTraps.concat(
                current.readedTraps
              );
              accumulator.uninstalledTraps =
                accumulator.uninstalledTraps.concat(current.uninstalledTraps);
              return accumulator;
            },
            { installedTraps: [], readedTraps: [], uninstalledTraps: [] }
          );

          const visitData = {
            activityDate: date,
            installedTraps: formatedDateData.installedTraps.length,
            readedTraps: formatedDateData.readedTraps.length,
            uninstalledTraps: formatedDateData.uninstalledTraps.length,
          };

          if (collectorResultIndex !== COLLECTOR_NOT_FOUND)
            visitsByDates[collectorResultIndex].visitsByDate = [
              ...collectorPreviousResults,
              { ...visitData },
            ];
          else
            visitsByDates.push({
              collectorId,
              name: collectorName,
              visitsByDate: [{ ...visitData }],
            });
        } else {
          const visitData = {
            activityDate: date,
            installedTraps: 0,
            readedTraps: 0,
            uninstalledTraps: 0,
          };

          if (collectorResultIndex !== COLLECTOR_NOT_FOUND)
            visitsByDates[collectorResultIndex].visitsByDate = [
              ...collectorPreviousResults,
              { ...visitData },
            ];
          else
            visitsByDates.push({
              collectorId,
              name: collectorName,
              visitsByDate: [{ ...visitData }],
            });
        }
      });
    });

    return visitsByDates;
  };

  const calculateDailyAverageTrapsData = (visitsData) => {
    const dailyInstallations = {};

    visitsData.forEach(({ visitsByDate }) => {
      visitsByDate.forEach(
        ({ activityDate, installedTraps, readedTraps, uninstalledTraps }) => {
          const actualDate = moment(activityDate).format("DD/MM/YYYY");

          if (!dailyInstallations[actualDate]) {
            dailyInstallations[actualDate] = {
              Instalações: 0,
              Manutenções: 0,
              Desinstalações: 0,
            };
          }

          dailyInstallations[actualDate]["Instalações"] += installedTraps;
          dailyInstallations[actualDate]["Manutenções"] += readedTraps;
          dailyInstallations[actualDate]["Desinstalações"] += uninstalledTraps;
        }
      );
    });

    return dailyInstallations;
  };

  const formatDataForGraphic = (visitsData) => {
    const resultado = [];

    for (const data in visitsData) {
      let object = { date: data };

      Object.assign(object, visitsData[data]);

      resultado.push(object);
    }

    return resultado;
  };

  const getUserName = (id) => {
    if (
      id === "00000000-0000-0000-0000-000000000000" ||
      id === null ||
      id === undefined ||
      !employees ||
      employees.length === 0
    )
      return "";

    if (id === "5a868c02-7a8d-47a0-b1b1-bb55383ad55d") return "Não informado";

    const userData = employees.find(
      (employee) => employee.employeeId === id || employee.userId === id
    );

    return userData ? userData.employeeName : "";
  };

  return (
    <LineChartGraphic
      data={formatedDataForGraphic}
      headerText={headerText}
      lines={[
        <Line
          type="monotone"
          dataKey="Instalações"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />,
        <Line type="monotone" dataKey="Manutenções" stroke="#82ca9d" />,
        <Line type="monotone" dataKey="Desinstalações" stroke="#cca847" />,
      ]}
      xAxisDataKey="date"
    />
  );
};

export default BasicLineChartGraphicWrapper;
