import React from "react";

import FiltroAmostras from "../components/Navbars/FiltroAmostras";
import { fetchSamples } from "../services/api/Sample";

// core components
import Header from "components/Headers/Header.jsx";
import "../assets/css/style.css";
import SampleTable from "../components/Lists/Sample/SampleTable";
import { checkPermissionComponent } from "../services/utils/permission";
import moment from "moment";
import { getUserData } from "../services/utils/auth";
import { getUserEmployeeDataByOrganization } from "../services/api/Users";

class AmostrasColetadas extends React.Component {
  state = {
    Samples : [],
    SampleFilter:[],
    filterParams:{
      organizationId:"",
      employeeId:"",
      userId:"00000000-0000-0000-0000-000000000000",
      sampleNumber:"",
      startDate:"",
      status:"all",
      endDate:"",
      demandId:"00000000-0000-0000-0000-000000000000",
      programId:"00000000-0000-0000-0000-000000000000",
    },
    loading:true
  };

  constructor(props){
    super(props);
    if(!checkPermissionComponent("7d306cb1-0df5-4fdc-a658-8f7492292172", "read")){
      alert("Você não tem permissão para acessar essa funcionalidade!");
      props.history.goBack(); 
    }
  }

  async componentDidMount() {
    const {filterParams} = this.state;
    filterParams.organizationId = await getUserData("organizationId");
    filterParams.endDate = moment().format("YYYY-MM-DD");
    filterParams.startDate = moment().subtract(3, "month").format("YYYY-MM-DD");

    this.setState({filterParams});
    
    const SamplesRequest = fetchSamples(filterParams);
      SamplesRequest.then(response => {
        if(response) {
            return response.data
        }
      
        throw new Error("Erro ao contatar servidor");
      }).then(data => {
        this.setState({Samples: data, SampleFilter:data});
    })
  }

  changeFilterParams = (field, value) => {
    const {filterParams} = this.state;
    filterParams[field] = value;

    this.setState({filterParams});
  }

  clearFilterParams = async () => {
    await this.setState({filterParams:{
      organizationId:"",
      employeeId:"",
      userId:"00000000-0000-0000-0000-000000000000",
      sampleNumber:"",
      startDate:"",
      status:"all",
      endDate:"",
      demandId:"00000000-0000-0000-0000-000000000000",
      programId: "00000000-0000-0000-0000-000000000000"
    }});

    const {filterParams} = this.state;

    filterParams.organizationId = await getUserData("organizationId");
    filterParams.endDate = moment().format("YYYY-MM-DD");
    filterParams.startDate = moment().subtract(3, "month").format("YYYY-MM-DD");

    await this.setState({filterParams});
  }

  callbackFunction = async () => {
    const {filterParams} = this.state; 

    const userRequest = await getUserEmployeeDataByOrganization();
    const Employees = userRequest.data.reduce((acc, obj) => {
        acc[obj["employeeId"]] = {"userId" : obj["userId"]};
        return acc;            
    }, [])  
    
    if(filterParams.employeeId !== ""){
      filterParams.userId = Employees[filterParams.employeeId] !== undefined ? Employees[filterParams.employeeId]["userId"] : "00000000-0000-0000-0000-000000000000"; 
    }

    const SamplesRequest = await fetchSamples(filterParams);
    const Samples = SamplesRequest.data.filter(
    (Sample)=>{
      return (Sample.demandNumber!=null ? Sample.demandNumber.indexOf(this.state.filterDemandNumber) !== -1 : true &&
        filterParams.status === "all" ? true : Sample.status == filterParams.status
      );
    });

    this.setState({Samples, SampleFilter:Samples});
      
  }

  render() {
    return (
      <>
        <Header />

        <FiltroAmostras Samples = {this.state.Samples} filterParams = {this.state.filterParams} parentCallback = {this.callbackFunction} changeFilterParams={this.changeFilterParams} clearFilterParams={this.clearFilterParams}/>

        <SampleTable Samples = {this.state.SampleFilter}/>
        
      </>
    );
  }
}

export default AmostrasColetadas;
