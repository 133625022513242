import React from "react";
// react component that copies the given text inside your clipboard
import SinanImporter from "../../../components/Navbars/SinanImporter";

// core components
import Header from "components/Headers/Header.jsx";
import "../../../assets/css/style.css";
import { checkPermissionComponent } from "../../../services/utils/permission";

class ImportSinan extends React.Component {
  state = {Samples : []};

  constructor(props){
    super(props);
    if(!checkPermissionComponent("fde5db7d-0cf3-4ddd-8737-708f259d4e1c", "read")){
      alert("Você não tem permissão para acessar essa funcionalidade!");
      props.history.goBack(); 
    }
  }

  render() {
    return (
      <>
        <Header />

        <SinanImporter />
      </>
    );
  }
}

export default ImportSinan;
