
import React from "react";
import {
    Modal,
    ModalHeader,
    ModalFooter,
  } from "reactstrap";
  import {discardSample} from "../../../services/api/Sample";
  import { toast } from "react-toastify";

export default class SelectedSampleModal extends React.Component {


discard(){
    var request = discardSample(this.props.sample.id);
    request.then(response =>{
        if(response.data.status == "Discarded"){
            toast.success("Amostra descartada");
        }else{
            toast.error("Amostra não foi descartada");
        }
    });
    this.props.toggleCallback()
}

render (){

    return(
        <>
        {}
            <Modal size="lg" isOpen={this.props.OpenSampleModal} fade="true"  toggle={()=>this.props.toggleCallback()}
            >
                <ModalHeader toggle={()=>this.props.toggleCallback()}>Descartar amostra?</ModalHeader>

                <ModalFooter>
                    <div className="row" >                          
                        <div className="col-6">
                            <button onClick={()=>this.discard()} className="btn btn-primary">Sim</button>
                        </div>
                        <div className="col-6">
                            <button onClick={()=>this.props.toggleCallback()} className="btn">Não</button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
            

        </>
    )
}


}
