import React from "react";
import { Col, Container, Row } from "reactstrap";
import EmployeesExport from "./Employees/EmployeesExport";
import TeamsExport from "./Teams/TeamsExport";
import DemandsExport from "./Demands/DemandsExport";
import TrapsExport from "./Traps/TrapsExport";
import { Select } from "../../../components/Wrappers/SelectAll";
import VisitsExport from "./Visits/VisitsExport";
import SamplesExport from "./Samples/SamplesExport";

const Export = () => {
  const [selectedExportOption, setSelectedExportOption] = React.useState({
    label: "Selecione um tipo de exportação",
    value: "",
  });

  const exportDataOptions = [
    {
      label: "Selecione um tipo de exportação",
      value: "",
    },
    {
      label: "Armadilhas",
      value: "traps",
    },
    {
      label: "Amostras",
      value: "samples",
    },
    {
      label: "Demandas",
      value: "demands",
    },
    {
      label: "Equipes",
      value: "teams",
    },
    {
      label: "Funcionários",
      value: "employees",
    },
    {
      label: "Visitas",
      value: "visits",
    },
  ];

  const exportDataComponents = {
    traps: <TrapsExport />,
    samples: <SamplesExport />,
    demands: <DemandsExport />,
    employees: <EmployeesExport />,
    teams: <TeamsExport />,
    visits: <VisitsExport />,
  };

  const selectComponentStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
  };

  return (
    <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      <Container fluid>
        <div>
          <Row>
            <Col xl={4}>
              <Select
                styles={selectComponentStyles}
                placeholder={"Selecione"}
                isClearable={false}
                options={exportDataOptions}
                value={selectedExportOption}
                onChange={(selectedOptionObject) =>
                  setSelectedExportOption(selectedOptionObject)
                }
              />
            </Col>
          </Row>
        </div>
      </Container>
      {selectedExportOption &&
        selectedExportOption.value !== "" &&
        exportDataComponents[selectedExportOption.value]}
    </div>
  );
};

export default Export;
