import React from "react";

import "../Maintenance/MaintenanceAdiFormPrint.styles.css";

const UninstallGatFormPrint = React.forwardRef(
  ({ organizationId, trapTypeName }, ref) => {
    const getFormLogoInformations = {
      "559a16ab-5da8-41ba-96b8-6e9f55feddd8": {
        path: "https://i.ibb.co/0MT2jbZ/sp.png",
        styles: { width: "150px" },
      },
      "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30": {
        path: "https://i.ibb.co/m014LrJ/goiania.png",
        styles: { width: "150px" },
      },
      "24d125ef-15e6-4bd9-9111-c0b3d50a8009": {
        path: "https://i.ibb.co/W6PfRCG/cotia-logo.png",
        styles: { width: "150px" },
      },
      "d67ef2b0-92b1-40bf-aba6-0e06070e6091": {
        path: "https://i.ibb.co/wdRDctz/dracena-logo.png",
        styles: { width: "70px" },
      },
      "4d4b0460-c8de-474a-9bb9-44e2ffa272b6": {
        path: "https://i.ibb.co/cx4yhw3/ribeirao-pires-logo.png",
        styles: { width: "60px" },
      },
    };

    const logoInformations = getFormLogoInformations[organizationId];

    return (
      <section className="document--container--maintenance" ref={ref}>
        <header className="document__header--maintenance">
          <div className="document__header--maintenance--image">
            <p>FORMULÁRIO DE DESINSTALAÇÃO DE {trapTypeName}</p>
            <img
              src={logoInformations ? logoInformations.path : ""}
              style={logoInformations ? logoInformations.styles : {}}
              alt="Logo do município"
            />
          </div>
          <div className="document__header--maintenance--content">
            <table class="document__table document__table--territorialization">
              <thead>
                <tr>
                  <th colspan="2">INFORMAÇÕES</th>
                </tr>
              </thead>
              <tbody>
                {organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8" ?
                  <>
                    <tr>
                      <td class="detalhes">
                        Região do Monitoramento (Nome da UVIS):
                        <br />
                        <br />
                        &nbsp;
                      </td>
                      <td class="detalhes">
                        Setor Ambiental:
                        <br />
                        <br />
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td class="detalhes">
                        Quadra:
                        <br />
                        <br />
                        &nbsp;
                      </td>
                      <td class="detalhes">
                        Distrito Administrativo:
                        <br />
                        <br />
                        &nbsp;
                      </td>
                    </tr>
                  </>
                  : organizationId === "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30" ?
                    <>
                      <tr>
                        <td class="detalhes">
                          Distrito Sanitário:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                        <td class="detalhes">
                          Setor Ambiental:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td class="detalhes">
                          Quarteirão:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                        <td class="detalhes">
                          Distrito Administrativo:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                      </tr>
                    </>
                    :
                    <>
                      <tr>
                        <td class="detalhes">
                          Distrito Sanitário:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                        <td class="detalhes">
                          Setor Ambiental:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td class="detalhes">
                          Quarteirão:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                        <td class="detalhes">
                          Distrito Administrativo:
                          <br />
                          <br />
                          &nbsp;
                        </td>
                      </tr>
                    </>
                }
              </tbody>
            </table>
            <table class="document__table document__table--agents">
              <thead>
                <tr>
                  <th colspan="2">SERVIDOR DE SAÚDE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Matrícula: <div style={{ marginRight: "20px" }}></div>
                    Nome:
                  </td>
                  <td>
                    Matrícula: <div style={{ marginRight: "20px" }}></div> Nome:
                  </td>
                </tr>
                <tr>
                  <td>
                    Matrícula: <div style={{ marginRight: "20px" }}></div> Nome:
                  </td>
                  <td>
                    Matrícula: <div style={{ marginRight: "20px" }}></div> Nome:
                  </td>
                </tr>
                <tr>
                  <td>
                    Matrícula: <div style={{ marginRight: "20px" }}></div> Nome:
                  </td>
                  <td>
                    Matrícula: <div style={{ marginRight: "20px" }}></div> Nome:
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="document__table document__table--date">
              <thead>
                <tr>
                  <th colSpan="2">DATA DA ATIVIDADE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>_____/_____/_____</td>
                </tr>
              </tbody>
            </table>
          </div>
        </header>
        <main>
          <table class="document__table document__table--visit">
            <thead>
              <tr>
                <th></th>
                <th style={{ marginRight: "100px", width: "140px" }}>
                  (*) Número da armadilha
                </th>
                <th style={{ marginRight: "200px", width: "210px" }}>
                  (*) Logradouro
                </th>
                <th style={{ marginRight: "70px" }}>(*) Número</th>
                <th style={{ marginRight: "80px" }}>Complemento</th>
                <th style={{ marginRight: "35px" }}>(*) Tipo do Imóvel</th>
                <th style={{ marginRight: "200px", width: "205px" }}>Nome</th>
                <th style={{ marginRight: "150px", width: "100px" }}>
                  Telefone
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 20 }).map((_, index) => (
                <tr class="tbody__row">
                  <td
                    class="numeration_column"
                    style={{ textAlign: "center", color: "black" }}
                  >
                    {index + 1}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Continua no verso</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                paddingTop: "10px",
                rowGap: "10px",
              }}
            >
              <div class="item">
                <table className="document__table--legend">
                  <thead>
                    <tr>
                      <th colSpan={2}>Tipo de imóvel</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>R - Residência</td>
                      <td>C - Comércio</td>
                    </tr>
                    <tr>
                      <td>TB - Terreno Baldio</td>
                      <td>PE - Ponto Estratégico</td>
                    </tr>
                    <tr>
                      <td>PP - Próprio Público</td>
                      <td>IE - Imóvel Especial</td>
                    </tr>
                    <tr>
                      <td>VP - Via Pública</td>
                      <td>E - Escola</td>
                    </tr>
                    <tr>
                      <td>US - Unidade de Saúde</td>
                      <td>O - Outros</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="item">
                <table className="document__table--legend">
                  <thead>
                    <tr>
                      <th>Status da Visita</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>T - Trabalhado</td>
                    </tr>
                    <tr>
                      <td>F - Fechado</td>
                    </tr>
                    <tr>
                      <td>R - Recusa</td>
                    </tr>
                    <tr>
                      <td>O - Orientação</td>
                    </tr>
                    <tr>
                      <td>EN - Endereço Não Encontrado</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="item">
                <table className="document__table--legend">
                  <thead>
                    <tr>
                      <th>Condições de uso</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>FPA - Faltam partes da armadilha</td>
                    </tr>
                    <tr>
                      <td>ANL - Armadilha não localizada no local</td>
                    </tr>
                    <tr>
                      <td>E - Extraviada</td>
                    </tr>
                    <tr>
                      <td>AD - Armadilha danificada</td>
                    </tr>
                    <tr>
                      <td>PD - Peça com defeito</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="item">
                <table className="document__table--legend">
                  <thead>
                    <tr>
                      <th>Peças com defeito</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>IT - Indicador de tempo</td>
                    </tr>
                    <tr>
                      <td>T - Tampa</td>
                    </tr>
                    <tr>
                      <td>F - Flutuador</td>
                    </tr>
                    <tr>
                      <td>B - Balde</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="item">
                <table className="document__table--legend">
                  <thead>
                    <tr>
                      <th colspan={2}>Manutenção</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>RL - Realizada limpeza</td>
                      <td>TA - Trocada a água</td>
                    </tr>
                    <tr>
                      <td>TR - Trocado Refil</td>
                      <td>AA - Adicionada água</td>
                    </tr>
                    <tr>
                      <td>ED - Etiqueta deteriorada</td>
                      <td>TP - Troca de Peça</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="item">
                <table className="document__table--legend">
                  <thead>
                    <tr>
                      <th>Estimativa de quantidade de larvas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Vivas ={">"} LV1 - 1 a 20 / Mortas ={">"} LM1 - 1 a 20
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Vivas ={">"} LV2 - 21 a 50 / Mortas ={">"} LM2 - 21 a 50
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Vivas ={">"} LV3 - 51 a 100 / Mortas ={">"} LM3 - 51 a
                        100{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Vivas ={">"} LV4 - Mais de 100 / Mortas ={">"} LM4 -
                        Mais de 100
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="item">
                <table className="document__table--legend">
                  <thead>
                    <tr>
                      <th>Estimativa de quantidade de pupa</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Vivas ={">"} PV1 - 1 a 10 / Mortas ={">"} PM1 - 1 a 10
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Vivas ={">"} PV2 - 11 a 20 / Mortas ={">"} PM2 - 11 a 20
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Vivas ={">"} PV3 - 21 a 50 / Mortas ={">"} PM3 - 21 a 50{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Vivas ={">"} PV4 - Mais de 50 / Mortas ={">"} PM4 - Mais
                        de 50
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="item">
                <table className="document__table--legend">
                  <thead>
                    <tr>
                      <th>Estimativa de quantidade de mosquitos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Vivas ={">"} MV1 - 1 a 5 / Mortas ={">"} MM1 - 1 a 5
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Vivas ={">"} MV2 - 6 a 10 / Mortas ={">"} MM2 - 6 a 10
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Vivas ={">"} MV3 - 11 a 20 / Mortas ={">"} MM3 - 11 a 20
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Vivas ={">"} MV4 - Mais de 20 / Mortas ={">"} MM4 - Mais
                        de 20
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="item">
                <table className="document__table--legend">
                  <thead>
                    <tr>
                      <th>Amostras</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>AM1 - Água</td>
                    </tr>
                    <tr>
                      <td>AM2 - Larva</td>
                    </tr>
                    <tr>
                      <td>AM3 - Pupa</td>
                    </tr>
                    <tr>
                      <td>AM4 - Lurva e Pupa</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <table
              className="document__table--visit--verse"
              style={{ marginTop: "16px" }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th class="vertical--header--maintenance  align-left">
                    Selecione o status da visita
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    A armadilha está em condições de uso?
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Informe o motivo de NÃO estar em condições de uso
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Se for "Peça com defeito", informe o componente comprometido
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Foi realizada manutenção da Armadilha?
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Informe a manutenção realizada
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Foi encontrada Larva na armadilha?
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Quantidade aproximada de larvas VIVAS
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Quantidade aproximada de larvas MORTAS
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Foi encontrada Pupa na armadilha?
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Quantidade aproximada de pupas VIVAS
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Quantidade aproximada de pupas MORTAS
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Capturou <i>Aedes aegypti</i>?
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Quantidade de <i>Aedes aegypti</i> capturados
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Capturou <i>Aedes albopictus</i>?
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Quantidade de <i>Aedes albopictus</i> capturados
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Capturou outros mosquitos?
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Quantidade de outros mosquitos capturados
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Foi gerada amostra?
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Informe o número da amostra
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Selecione o tipo de amostra
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Se a amostra for de "Mosquitos Adultos", preencha a quantidade
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    A armadilha foi desinstalada do imóvel?
                  </th>
                  <th class="vertical--header--maintenance  align-left">
                    Selecione o motivo da desinstalação
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 20 }).map((_, index) => (
                  <tr class="tbody__row">
                    <td
                      class="numeration_column"
                      style={{ textAlign: "center", color: "black" }}
                    >
                      {index + 1}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </section>
    );
  }
);

export default UninstallGatFormPrint;
