import React from "react";

import DataTable from "react-data-table-component";
import { Row, Col, Card, CardHeader, Button } from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment";
import { toast } from "react-toastify";
import { insertMultiplesGoals } from "../../../../services/api/Goals";
import { getUserData } from "../../../../services/utils/auth";

const PlanningTable = ({ goalsData, filtersValues, employeesList }) => {
  const [usersGoals, setUsersGoals] = React.useState([]);
  const [usersGoalsToExport, setUsersGoalsToExport] = React.useState([]);
  const [tableColumns, setTableColumns] = React.useState([]);
  const [changeGoals, setChangeGoals] = React.useState(false);

  React.useEffect(() => {
    if (generateUsersIdsToSendOnRequest(filtersValues).length === 0) return;

    const usersGoalsFormated = formatUsersGoals(goalsData);
    const goalsTableColumns = getGoalsColumnsForTable(
      filtersValues.beginDate,
      filtersValues.finalDate
    );
    const usersGoalsToExport = formatUsersGoalsToExport(usersGoalsFormated);

    setUsersGoals([
      ...usersGoalsFormated,
      generateTotalRowByDayForTable(usersGoalsFormated),
    ]);
    setTableColumns(goalsTableColumns);
    setUsersGoalsToExport(usersGoalsToExport);
  }, [goalsData]);

  React.useEffect(() => {
    if (changeGoals) {
      const totalRow = generateTotalRowByDayForTable(usersGoals);
      setUsersGoals((prevUsersGoals) => [
        ...prevUsersGoals.slice(0, -1),
        totalRow,
      ]);
      setChangeGoals(false);
    }
  }, [changeGoals]);

  const getGoalsColumnsForTable = (beginDate, finalDate) => {
    if (!beginDate || beginDate === "" || !finalDate || finalDate === "")
      return;

    const beginPeriod = moment(beginDate, "YYYY-MM-DD");
    const finalPeriod = moment(finalDate, "YYYY-MM-DD");

    const datesInBetween = [];

    for (
      let date = moment(beginPeriod);
      date.isSameOrBefore(finalPeriod);
      date.add(1, "days")
    )
      datesInBetween.push(date.format("YYYY-MM-DD"));

    return [
      {
        name: <span style={{ fontWeight: "bold" }}>Usuário</span>,
        selector: "name",
        sortable: false,
        grow: 6,
      },
      ...datesInBetween.flatMap((date, index) => [
        {
          name: (
            <span
              style={{
                textAlign: "center",
                fontWeight: "bold",
                display: "block",
              }}
            >
              {moment(date).format("DD/MM/YYYY")} <br /> Instalações
            </span>
          ),
          selector: `${date}-Armadilhas Instaladas`,
          sortable: false,
          center: true,
          style: {
            backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F2F2F2",
          },
          cell: (row) =>
            row.name === "Total" ? (
              <span style={{ fontWeight: "bold" }}>
                {row[`${date}-Armadilhas Instaladas`]}
              </span>
            ) : (
              <input
                type="text"
                className="form-control"
                style={{
                  textAlign: "center",
                  height: "35px",
                  width: "65px",
                  padding: "1px",
                }}
                onChange={({ target }) => [
                  handleInputChange(
                    row.userId,
                    date,
                    "Armadilhas Instaladas",
                    target.value
                  ),
                ]}
                value={row[`${date}-Armadilhas Instaladas`]}
              />
            ),
        },
        {
          name: (
            <span
              style={{
                textAlign: "center",
                fontWeight: "bold",
                display: "block",
              }}
            >
              {moment(date).format("DD/MM/YYYY")} <br /> Manutenções
            </span>
          ),
          selector: `${date}-Armadilhas Manutenção`,
          sortable: false,
          center: true,
          style: {
            backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F2F2F2",
          },
          cell: (row) =>
            row.name === "Total" ? (
              <span style={{ fontWeight: "bold" }}>
                {row[`${date}-Armadilhas Manutenção`]}
              </span>
            ) : (
              <input
                type="text"
                className="form-control"
                style={{
                  textAlign: "center",
                  height: "35px",
                  width: "65px",
                  padding: "1px",
                }}
                onChange={({ target }) => [
                  handleInputChange(
                    row.userId,
                    date,
                    "Armadilhas Manutenção",
                    target.value
                  ),
                ]}
                value={row[`${date}-Armadilhas Manutenção`]}
              />
            ),
        },
        {
          name: (
            <span
              style={{
                textAlign: "center",
                fontWeight: "bold",
                display: "block",
              }}
            >
              {moment(date).format("DD/MM/YYYY")} <br /> Desinstalações
            </span>
          ),
          selector: `${date}-Armadilhas Desinstalação`,
          sortable: false,
          center: true,
          style: {
            backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F2F2F2",
          },
          cell: (row) =>
            row.name === "Total" ? (
              <span style={{ fontWeight: "bold" }}>
                {row[`${date}-Armadilhas Desinstalação`]}
              </span>
            ) : (
              <input
                type="text"
                className="form-control"
                style={{
                  textAlign: "center",
                  height: "35px",
                  width: "65px",
                  padding: "1px",
                }}
                onChange={({ target }) => [
                  handleInputChange(
                    row.userId,
                    date,
                    "Armadilhas Desinstalação",
                    target.value
                  ),
                ]}
                value={row[`${date}-Armadilhas Desinstalação`]}
              />
            ),
        },
        {
          name: (
            <span
              style={{
                textAlign: "center",
                fontWeight: "bold",
                display: "block",
              }}
            >
              {moment(date).format("DD/MM/YYYY")} <br /> Visitas
            </span>
          ),
          selector: `${date}-Visitas`,
          sortable: false,
          center: true,
          style: {
            backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F2F2F2",
          },
          cell: (row) =>
            row.name === "Total" ? (
              <span style={{ fontWeight: "bold" }}>
                {row[`${date}-Visitas`]}
              </span>
            ) : (
              <input
                type="text"
                className="form-control"
                style={{
                  textAlign: "center",
                  height: "35px",
                  width: "65px",
                  padding: "1px",
                }}
                onChange={({ target }) => [
                  handleInputChange(row.userId, date, "Visitas", target.value),
                ]}
                value={row[`${date}-Visitas`]}
              />
            ),
        },
      ]),
      {
        name: (
          <span
            style={{
              textAlign: "center",
              fontWeight: "bold",
              display: "block",
            }}
          >
            Total Instalações
          </span>
        ),
        selector: "Total Armadilhas",
        center: true,
        sortable: false,
        cell: (row) => (
          <span
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {row.name === "Total"
              ? calculateTotalTraps(row, "Armadilhas Instaladas")
              : totalGoalsByUser(row, "Armadilhas Instaladas")}
          </span>
        ),
      },
      {
        name: (
          <span
            style={{
              textAlign: "center",
              fontWeight: "bold",
              display: "block",
            }}
          >
            Total Manutenções
          </span>
        ),
        selector: "Total Manutenções",
        center: true,
        sortable: false,
        cell: (row) => (
          <span
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {row.name === "Total"
              ? calculateTotalTraps(row, "Armadilhas Manutenção")
              : totalGoalsByUser(row, "Armadilhas Manutenção")}
          </span>
        ),
      },
      {
        name: (
          <span
            style={{
              textAlign: "center",
              fontWeight: "bold",
              display: "block",
            }}
          >
            Total Desinstalações
          </span>
        ),
        selector: "Total Desinstalações",
        center: true,
        sortable: false,
        cell: (row) => (
          <span
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {row.name === "Total"
              ? calculateTotalTraps(row, "Armadilhas Desinstalação")
              : totalGoalsByUser(row, "Armadilhas Desinstalação")}
          </span>
        ),
      },
      {
        name: (
          <span
            style={{
              textAlign: "center",
              fontWeight: "bold",
              display: "block",
            }}
          >
            Total Visitas
          </span>
        ),
        selector: "Total Visitas",
        center: true,
        sortable: false,
        cell: (row) => (
          <span
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {row.name === "Total"
              ? calculateTotalVisits(row)
              : totalGoalsByUser(row, "Visitas")}
          </span>
        ),
      },
    ];
  };

  const formatUsersGoals = (goalsData) => {
    const datesInBetween = generateDatesBetweenTwoDates(
      filtersValues.beginDate,
      filtersValues.finalDate
    );
    const usersIdsSentOnRequest =
      generateUsersIdsToSendOnRequest(filtersValues);

    const usersGoalsMap = new Map();

    const organizationId = getUserData("organizationId");

    usersIdsSentOnRequest.forEach((userId) => {
      const initalGoals = {};
      const initalGoalsToSend = [];
      const userData = employeesList.find(
        (employee) => employee.userId === userId
      );

      datesInBetween.forEach((date) => {
        const trapInstallEmptyGoal = {
          comment: "",
          date: date,
          employee: {
            id: userData
              ? userData.employeeId
              : "00000000-0000-0000-0000-000000000000",
          },
          organizationId: organizationId,
          id: "00000000-0000-0000-0000-000000000000",
          status: 1,
          type: {
            id: "c3add1cd-3d79-4472-9052-082216e88904",
            name: "Armadilhas Instaladas",
          },
          userId: userId,
          value: 0,
        };

        const trapMaintenanceEmptyGoal = {
          comment: "",
          date: date,
          employee: {
            id: userData
              ? userData.employeeId
              : "00000000-0000-0000-0000-000000000000",
          },
          organizationId: organizationId,
          id: "00000000-0000-0000-0000-000000000000",
          status: 1,
          type: {
            id: "75b27f05-01bb-4607-b7a6-445954bf1097",
            name: "Armadilhas Manutenção",
          },
          userId: userId,
          value: 0,
        };

        const trapUninstallEmptyGoal = {
          comment: "",
          date: date,
          employee: {
            id: userData
              ? userData.employeeId
              : "00000000-0000-0000-0000-000000000000",
          },
          organizationId: organizationId,
          id: "00000000-0000-0000-0000-000000000000",
          status: 1,
          type: {
            id: "c5dcc526-13e7-49c5-ad81-26532ebbb742",
            name: "Armadilhas Desinstalação",
          },
          userId: userId,
          value: 0,
        };

        const visitEmptyGoal = {
          comment: "",
          date: date,
          employee: {
            id: userData
              ? userData.employeeId
              : "00000000-0000-0000-0000-000000000000",
          },
          organizationId: organizationId,
          id: "00000000-0000-0000-0000-000000000000",
          status: 1,
          type: {
            id: "2e60d68e-2282-4870-8228-607c67844332",
            name: "Visitas",
          },
          userId: userId,
          value: 0,
        };

        initalGoals[`${date}-Armadilhas Instaladas`] = 0;
        initalGoalsToSend.push(trapInstallEmptyGoal);

        initalGoals[`${date}-Armadilhas Manutenção`] = 0;
        initalGoalsToSend.push(trapMaintenanceEmptyGoal);

        initalGoals[`${date}-Armadilhas Desinstalação`] = 0;
        initalGoalsToSend.push(trapUninstallEmptyGoal);

        initalGoals[`${date}-Visitas`] = 0;
        initalGoalsToSend.push(visitEmptyGoal);
      });

      const result = {
        name: userData ? userData.employeeName : "Usuário não encontrado",
        userId: userId,
        employeeId: userData
          ? userData.employeeId
          : "00000000-0000-0000-0000-000000000000",
        ...initalGoals,
        goals: initalGoalsToSend,
      };

      usersGoalsMap.set(userId, result);
    });

    goalsData.forEach(({ day, dailyGoals }) => {
      dailyGoals.forEach(({ userId, goals }) => {
        goals.forEach((goal) => {
          if (usersGoalsMap.has(userId)) {
            const userData = usersGoalsMap.get(userId);

            const formatedDate = moment(day).format("YYYY-MM-DD");
            const goalIndex = userData.goals.findIndex(
              ({ date, type }) =>
                formatedDate === moment(date).format("YYYY-MM-DD") &&
                type.id === goal.type.id
            );

            userData[`${formatedDate}-${goal.type.name}`] = goal.value;

            if (goalIndex !== -1) userData.goals[goalIndex] = goal;
          }
        });
      });
    });

    const result = Array.from(usersGoalsMap.values()).sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;

      return 0;
    });

    return result;
  };

  const formatUsersGoalsToExport = (goalsData) => {
    if (!goalsData || goalsData.length === 0) return [];

    const result = goalsData.map(({ employeeId, goals, userId, ...rest }) => ({
      ...rest,
    }));

    return result;
  };

  const generateTotalRowByDayForTable = (goalsData) => {
    const goalsByDayMap = {
      name: "Total",
    };
    const datesInBetween = generateDatesBetweenTwoDates(
      filtersValues.beginDate,
      filtersValues.finalDate
    );

    datesInBetween.forEach((date) => {
      const trapsInstallGoal = `${date}-Armadilhas Instaladas`;
      const trapsMaintenanceGoal = `${date}-Armadilhas Manutenção`;
      const trapsUninstallGoal = `${date}-Armadilhas Desinstalação`;
      const visitsGoal = `${date}-Visitas`;

      goalsByDayMap[trapsInstallGoal] = 0;
      goalsByDayMap[trapsMaintenanceGoal] = 0;
      goalsByDayMap[trapsUninstallGoal] = 0;
      goalsByDayMap[visitsGoal] = 0;

      if (goalsData && goalsData.length > 0) {
        goalsData.forEach((goal) => {
          if (goal.name !== "Total") {
            goalsByDayMap[trapsInstallGoal] += goal[trapsInstallGoal];
            goalsByDayMap[trapsMaintenanceGoal] += goal[trapsMaintenanceGoal];
            goalsByDayMap[trapsUninstallGoal] += goal[trapsUninstallGoal];
            goalsByDayMap[visitsGoal] += goal[visitsGoal];
          }
        });
      }

      goalsByDayMap["goals"] = [];
    });

    return goalsByDayMap;
  };

  const totalGoalsByUser = (goalsData, goalType) => {
    if (!goalsData) return 0;

    let total = 0;
    for (const key in goalsData) {
      if (
        key.includes(goalType) &&
        key.includes("-") &&
        !key.includes("Total")
      ) {
        total += parseInt(goalsData[key]);
      }
    }

    return total;
  };

  const calculateTotalTraps = (row, goalType) => {
    let totalTraps = 0;
    const datesInBetween = generateDatesBetweenTwoDates(
      filtersValues.beginDate,
      filtersValues.finalDate
    );
    datesInBetween.forEach((date) => {
      totalTraps += parseInt(row[`${date}-${goalType}`]);
    });

    return totalTraps;
  };

  const calculateTotalVisits = (row) => {
    let totalVisits = 0;
    const datesInBetween = generateDatesBetweenTwoDates(
      filtersValues.beginDate,
      filtersValues.finalDate
    );

    datesInBetween.forEach((date) => {
      totalVisits += parseInt(row[`${date}-Visitas`]);
    });

    return totalVisits;
  };

  const generateUsersIdsToSendOnRequest = (filtersValues) => {
    if (!filtersValues) return [];

    const usersIds = [];

    if (filtersValues.team.label !== "Todos")
      filtersValues.team.members.forEach(({ userId }) => usersIds.push(userId));

    if (filtersValues.employees && filtersValues.employees.length > 0)
      filtersValues.employees.forEach(({ value }) => usersIds.push(value));

    return usersIds;
  };

  const generateDatesBetweenTwoDates = (beginDate, finalDate) => {
    if (!beginDate || beginDate === "" || !finalDate || finalDate === 0) return;

    const datesInBetween = [];

    const beginPeriod = moment(filtersValues.beginDate, "YYYY-MM-DD");
    const finalPeriod = moment(filtersValues.finalDate, "YYYY-MM-DD");

    for (
      let date = moment(beginPeriod);
      date.isSameOrBefore(finalPeriod);
      date.add(1, "days")
    )
      datesInBetween.push(date.format("YYYY-MM-DD"));

    return datesInBetween;
  };

  const handleInputChange = (userId, date, goalType, newValue) => {
    setUsersGoals((previousValues) => {
      const newValues = previousValues.map((user) => {
        if (user.userId === userId) {
          const goalToUpdateIndex = user.goals.findIndex(
            (goal) =>
              moment(goal.date).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD") && goal.type.name === goalType
          );

          if (goalToUpdateIndex !== -1)
            user.goals[goalToUpdateIndex].value = +newValue;

          const result = {
            ...user,
            [`${date}-${goalType}`]: +newValue.replace(/\D/g, ""),
          };

          return result;
        }

        setChangeGoals(true);

        return user;
      });

      return newValues;
    });
  };

  const handleSubmit = async () => {
    if (
      generateUsersIdsToSendOnRequest(filtersValues).length === 0 ||
      filtersValues.beginDate === "" ||
      filtersValues.finalDate === ""
    ) {
      toast.error(
        "É necessário informar uma equipe ou no mínimo um funcionário e o período de data para enviar os dados"
      );

      return;
    }

    const goalsToInsert = usersGoals.flatMap((item) =>
      item.goals.filter(
        (goal) => goal.id === "00000000-0000-0000-0000-000000000000"
      )
    );

    const goalsToUpdate = usersGoals.flatMap((item) =>
      item.goals.filter(
        (goal) => goal.id !== "00000000-0000-0000-0000-000000000000"
      )
    );

    const formatedGoalsToInsert = goalsToInsert.map(
      ({ employee, type, ...rest }) => ({
        ...rest,
        typeId: type.id,
        employeeId: employee.id,
      })
    );
    const formatedGoalsToUpdate = goalsToUpdate.map(
      ({ employee, type, ...rest }) => ({
        ...rest,
        typeId: type.id,
        employeeId: employee.id,
      })
    );

    const goalsToPost = [...formatedGoalsToInsert, ...formatedGoalsToUpdate];

    try {
      const { status } = await insertMultiplesGoals(goalsToPost);

      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao salvar os dados de meta. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato conosco."
        );

      toast.success("Dados de metas salvos com sucesso.");
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  return (
    <Row className="mt-12" style={{ marginBottom: "1.5rem" }}>
      <Col className="mb-12 mb-xl-0" xl="12">
        <Card className="shadow">
          <CardHeader
            className="border-0"
            style={{ paddingBottom: "0px", marginBottom: "2rem" }}
          >
            <Row className="align-items-center">
              <Col className="col-md-8">
                <h2 className="mb-0">
                  Meta
                </h2>
                <br />
              </Col>
              <Col className="col-md-4">
                <div className="d-flex">
                  <Button
                    color="primary"
                    onClick={() => handleSubmit()}
                    disabled={
                      !usersGoals || usersGoals.length === 0 ? true : false
                    }
                  >
                    <i
                      className="fa fa-save"
                      style={{ marginRight: "1rem" }}
                    ></i>{" "}
                    Salvar
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <DataTable
            defaultSortField=""
            defaultSortAsc={true}
            pagination={false}
            highlightOnHover
            responsive
            columns={tableColumns}
            data={usersGoals}
            noDataComponent={"Nenhum registro encontrado."}
          />
          <Row
            style={{
              textAlign: "center",
              margin: "2rem",
              justifyContent: "center",
              gap: "4rem",
            }}
          >
            <Col xl={2}>
              <CsvDownloadButton
                className="btn btn-primary"
                data={usersGoalsToExport}
                filename={`META_VITEC`}
                style={{ width: "330px" }}
              >
                <i className="fa fa-download" /> Baixar CSV das informações
              </CsvDownloadButton>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default PlanningTable;
