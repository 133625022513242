import React, { Component } from 'react';
import {Select} from "../Wrappers/SelectAll";
import getWeeks from '../../services/utils/epidemiologicalWeek';

class YearsInput extends Component {

    state={
        yearsOptions:[],
    }

    componentDidMount(){
        const epidemiologicalWeeks = getWeeks();
        const yearsOptions = epidemiologicalWeeks.map((obj) => {return {
            label:obj.year,
            value:obj.year,
        }})

        const yearsOptionsResult = yearsOptions ? yearsOptions.reverse() : null;

        this.setState({yearsOptions: yearsOptionsResult});
    }

    handleChange = (e) => {
        if (this.props.isSinan == true)
            this.props.changePeriod(e,"YearSINAN");
        else if (this.props.isEntomologico == true)
            this.props.changePeriod(e,"YearENTOMOLOGICO");
        else if (this.props.isOperacional == true)
            this.props.changePeriod(e,"YearOPERACIONAL");
        else
            this.props.changePeriod(e,"Year");
    }

    render() {

        return (
            <div>
                <span className="h4 text-white">Ano</span>
                <Select
                    styles={{
                        control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                        option: styles =>({...styles, fontSize:"0.875rem"}),
                        valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                        singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                        placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                        menuPortal: styles => ({...styles, zIndex:9999})
                    }}
                    value={this.props.years}
                    isMulti={true}
                    placeholder={"Selecione"}
                    isClearable={true}
                    onChange={this.handleChange}
                    allowSelectAll={true}
                    allOption={{label: "Todos", value: "*"}}
                    blurInputOnSelect={false}
                    closeMenuOnSelect={false}
                    options={this.state.yearsOptions}    
                    menuPortalTarget={document.body}  
                />        
            </div>
        );
    }
}

export default YearsInput;