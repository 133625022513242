import React from "react";

import { ToastContainer, toast } from "react-toastify";
import { Container } from "reactstrap";
import PlanningFiltersWrapper from "./Filters/PlanningFiltersWrapper";
import { getUserData } from "../../../services/utils/auth";
import moment from "moment";
import PlanningTable from "./Tables/PlanningTable";
import { getTeam } from "../../../services/api/Team";
import { getUserEmployeeDataByOrganization } from "../../../services/api/Users";
import { fetchTypeGoalsByFilters, fetchGoalsByFilters } from "../../../services/api/Goals";

const PlanningPage = () => {
  const [employeesList, setEmployeesList] = React.useState([]);
  const [goalsData, setGoalsData] = React.useState([]);
  const [goalsFilters, setGoalsFilters] = React.useState({
    employees: [],
    team: {
      label: "Todos",
      value: "00000000-0000-0000-0000-000000000000",
    },
    /* type: {
      label: "Selecione uma opção",
      value: "00000000-0000-0000-0000-000000000000",
    }, */
    beginDate: moment().format("YYYY-MM-DD"),
    finalDate: moment().add(5, "days").format("YYYY-MM-DD"),
  });
  const [teamsList, setTeamsList] = React.useState([]);
  const [typeGoalsList, setTypeGoalsList] = React.useState([]);

  React.useEffect(() => {
    fetchEmployeesAndTeamsData();
    //fetchTypeGoalsData();
  }, []);

  const fetchAllComponentsData = async () => {
    await Promise.all([fetchGoalsData()]);
  };

  const fetchGoalsData = async () => {
    const usersIdsToSend = [];

    /* if (goalsFilters.type.value === "00000000-0000-0000-0000-000000000000") {
      toast.error("É necessário selecionar um tipo de meta para prosseguir.");
      return;
    } */

    if (goalsFilters.team.label !== "Todos")
      goalsFilters.team.members.forEach(({ userId }) =>
        usersIdsToSend.push(userId)
      );

    if (goalsFilters.employees && goalsFilters.employees.length > 0)
      goalsFilters.employees.forEach(({ value }) => usersIdsToSend.push(value));

    if (usersIdsToSend.length === 0) {
      toast.error(
        "É necessário selecionar uma equipe ou pelo menos um funcionário para definir suas metas."
      );
      return;
    }

    const requestFilters = {
      includeEmployee: true,
      includeConsolidatedResult: false,
      organizationId: getUserData("organizationId"),
      periods: {
        beginDate: goalsFilters.beginDate,
        finalDate: moment(goalsFilters.finalDate)
          .add(1, "day")
          .format("YYYY-MM-DD"),
      },
      usersIds: usersIdsToSend,
      //typeId: goalsFilters.type.value,
    };

    try {
      const { data, status } = await fetchGoalsByFilters(requestFilters);

      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pela lista de metas. Verifique sua conexão com a internet e caso o erro persista entre em contato consco."
        );

      setGoalsData(data);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        toast.error(error.message);
      }
    }
  };

  const fetchEmployeesAndTeamsData = async () => {
    const employeesData = await fetchEmployeesData();
    const teamsData = await fetchTeamsData(employeesData);

    return [employeesData, teamsData];
  };

  const fetchEmployeesData = async () => {
    try {
      const { data, status } = await getUserEmployeeDataByOrganization();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pela lista de funcionários. Verifique sua conexão com a internet e caso o erro persista entre em contato consco."
        );

      const formatedEmployees = data.map(
        ({ userId, employeeId, employeeName }) => {
          return {
            employeeId,
            employeeName,
            userId,
            label: employeeName,
            value: userId,
          };
        }
      );

      setEmployeesList(formatedEmployees);

      return formatedEmployees;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        toast.error(error.message);
      }
    }
  };

  const fetchTeamsData = async (employeesData) => {
    try {
      const { data, status } = await getTeam();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pela lista de equipes. Verifique sua conexão com a internet e caso o erro persista entre em contato consco."
        );

      const formatedTeams = data.map(({ id, members, name }) => {
        const formatedTeamMembers = members.map((member) => {
          const userData = employeesData.find(
            (employee) => employee.employeeId === member.employeeId
          );

          if (!userData)
            return {
              employeeId: "00000000-0000-0000-0000-000000000000",
              employeeName: "Usuário não encontrado",
              label: "Usuário não encontrado",
              userId: "00000000-0000-0000-0000-000000000000",
              uvis: null,
              value: "00000000-0000-0000-0000-000000000000",
            };

          return {
            ...member,
            userId: userData.userId
              ? userData.userId
              : "00000000-0000-0000-0000-000000000000",
            employeeId: userData.employeeId,
          };
        });

        return {
          value: id,
          label: name,
          members: formatedTeamMembers,
        };
      });

      setTeamsList([
        {
          label: "Todos",
          value: "00000000-0000-0000-0000-000000000000",
        },
        ...formatedTeams,
      ]);

      return formatedTeams;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        toast.error(error.message);
      }
    }
  };

  const fetchTypeGoalsData = async () => {
    const filtersToSend = {
      organizationId: getUserData("organizationId"),
    };

    try {
      const { data, status } = await fetchTypeGoalsByFilters(filtersToSend);

      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pela lista de metas. Verifique sua conexão com a internet e caso o erro persista entre em contato consco."
        );

      const formatedTypeGoalsList = data.map(({ id, name }) => {
        return {
          label: name,
          value: id,
        };
      });

      setTypeGoalsList(formatedTypeGoalsList);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        toast.error(error.message);
      }
    }
  };

  const handleFiltersChange = (filterName, newValue) => {
    const newFiltersValues = { ...goalsFilters };

    newFiltersValues[filterName] = newValue;

    setGoalsFilters(newFiltersValues);
  };

  return (
    <main>
      <ToastContainer />
      <Container fluid className="bg-gradient-info pt-5 pb-2 mb-5">
        <div style={{ paddingTop: "10px !important" }}>&nbsp;</div>
        <PlanningFiltersWrapper
          filtersValues={goalsFilters}
          employees={employeesList}
          teams={teamsList}
          typeGoalsList={typeGoalsList}
          handleFiltersChange={handleFiltersChange}
          fetchAllComponentsData={fetchAllComponentsData}
        />
      </Container>
      <Container fluid className="pb-2 mt-2">
        <PlanningTable
          goalsData={goalsData}
          filtersValues={goalsFilters}
          employeesList={employeesList}
        />
      </Container>
    </main>
  );
};

export default PlanningPage;
