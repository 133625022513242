import React from "react";
import { Col, Row } from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";
import { toast } from "react-toastify";
import { fetchPrograms } from "../../../../services/api/Program";
import { fetchOrigins } from "../../../../services/api/Origins";

const DemandsExportFilters = ({ demandsFilters, handleFiltersChange }) => {
  const [programsOptions, setProgramsOptions] = React.useState(null);
  const [originsOptions, setOriginsOptions] = React.useState(null);

  const fetchProgramOptions = async () => {
    try {
      const { data, status } = await fetchPrograms();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos programas. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      const formatedPrograms = data.map(({ name, id }) => {
        return {
          label: name,
          value: id,
        };
      });

      setProgramsOptions([
        {
          label: "Todos",
          value: "all",
        },
        ...formatedPrograms,
      ]);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const fetchOriginsOptions = async () => {
    try {
      const { data, status } = await fetchOrigins();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelas origens. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      const formatedOrigins = data.map(({ name, id }) => {
        return {
          label: name,
          value: id,
        };
      });

      setOriginsOptions([
        {
          label: "Todos",
          value: "all",
        },
        ...formatedOrigins,
      ]);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  React.useState(() => {
    fetchProgramOptions();
    fetchOriginsOptions();
  }, []);

  const demandSituationsOptions = [
    { label: "Todas", value: "all" },
    { label: "Aguardando validação", value: "Aguardando validação" },
    { label: "Aguardando Execução", value: "Aguardando Execução" },
    { label: "Aguardando finalização", value: "Aguardando finalização" },
    { label: "Descartada", value: "Descartada" },
    { label: "Finalizada", value: "Finalizada" },
  ];

  const demandTypeOptions = [
    { label: "Todos", value: "all" },
    { label: "Interna", value: "Interna" },
    { label: "Externa", value: "Externa" },
  ];

  const selectComponentStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
  };

  return (
    <>
      <Row
        className="align-items-center"
        style={{ marginBottom: "1rem", padding: "0px 1.5rem" }}
      >
        <Col xl="4">
          <span style={{ fontWeight: "bold" }}>Número</span>
          <input
            type="text"
            className="form-control"
            placeholder="Digite o número da demanda"
            value={demandsFilters.number}
            onChange={({ target }) =>
              handleFiltersChange("number", target.value)
            }
          />
        </Col>
        <Col xl="4">
          <span style={{ fontWeight: "bold" }}>Tipo</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            options={demandTypeOptions}
            value={demandsFilters.type}
            onChange={(selectedOptionObject) =>
              handleFiltersChange("type", selectedOptionObject)
            }
          />
        </Col>
        <Col xl="4">
          <span style={{ fontWeight: "bold" }}>Situação</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            options={demandSituationsOptions}
            value={demandsFilters.situation}
            onChange={(selectedOptionObject) =>
              handleFiltersChange("situation", selectedOptionObject)
            }
          />
        </Col>
      </Row>
      <Row
        className="align-items-center"
        style={{ marginBottom: "2rem", padding: "0px 1.5rem" }}
      >
        <Col xl="4">
          <span style={{ fontWeight: "bold" }}>Programa</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            options={programsOptions}
            value={demandsFilters.program}
            onChange={(selectedOptionObject) =>
              handleFiltersChange("program", selectedOptionObject)
            }
          />
        </Col>
        <Col xl="4">
          <span style={{ fontWeight: "bold" }}>Origem</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            options={originsOptions}
            value={demandsFilters.origin}
            onChange={(selectedOptionObject) =>
              handleFiltersChange("origin", selectedOptionObject)
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default DemandsExportFilters;
