import React, {useState, useEffect, useRef} from "react";
import { Bar, BarChart, XAxis, Legend, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, LabelList } from "recharts";
import { useCurrentPng } from "recharts-to-png";
import CardPresentational from "../../Presentational/Sinan/CardPresentational";

const colors = ["#5351F0", "#F0905D", "#469DF0", "#F0CD2E", "#3AF0E7", "#F57D51", "#C4F56A", "#F5BC5D"];
const deposityTypes = ["A1", "A2", "B", "C", "D1", "D2", "E"];

const DepositSISPNCDBar  = ({data}) => {
    const [chartDataSets, setChartDataSets] = useState([{}]);
    const textCardHeader = useRef('Nº de depósitos inspecionados por tipo');
    const [getpng, {ref}] = useCurrentPng();

    useEffect(() => {
        if(data){
            buildDataSets(data);
        }
    }, [data])

    async function buildDataSets (data){
    
        
        const dataset = [];

        for(let type of deposityTypes){
            let dataObject = {
                label: type,
                Quantidade: data[type.toLowerCase()] ? parseInt(data[type.toLowerCase()]) : 0
            };  
            
        dataset.push(dataObject);
        }

        
        setChartDataSets(dataset)
    }

    const style={
        cardHeaderText: {
            color:"black",
            fontWeight:"bold",
            fontSize:"0.97rem"
        },
    }

    const renderCustomizedLabel = (props) => {
        const { x, y, width, value } = props;
        const radius = 10;
      
        return (
            <text x={x + width / 2} y={y - radius} fill="#000" textAnchor="middle" dominantBaseline="middle">
              {value}
            </text>
        );
      };

    const buildChartElement = () => (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={chartDataSets}
                barSize={60}
                ref={ref}
                margin={{top:30, right:15}}
            >
                <text x={"50%"} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
                    <tspan fontSize="16" fontWeight={"bolder"}>Depósitos inspecionados </tspan>
                </text>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="category" dataKey="label" style={{fontSize:14}} />
                <YAxis style={{fontSize:14}} allowDecimals={false} tickCount={101} domain={[0, 100]} />
                <Tooltip/>
                <Bar dataKey={"Quantidade"}>
                    <LabelList dataKey={"Quantidade"} content={renderCustomizedLabel}/>
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
    
    return(
        <CardPresentational
            CardId = {"DepositSISPNCDContainer"}
            style={style}
            textCardHeader={textCardHeader.current}
            element={buildChartElement()}
            getpng = {getpng}
        />
    );
}


export default DepositSISPNCDBar;