import React, { useEffect, useState, useRef } from "react";

import Chart from "chart.js";
import moment from "moment";
import CsvDownloadButton from "react-json-to-csv";
import { chartOptions, parseOptions } from "variables/charts.jsx";
import { toast, ToastContainer } from "react-toastify";
import { Container, Row, Col } from "reactstrap";
import {
  getTrapsTypes,
  getActiveTrapsToMap,
  getTrapsStatusByColor,
} from "../../../services/api/Trap";
import { getUserData } from "../../../services/utils/auth";
import EntomologicoFilterPresentational from "../../../components/Presentational/Entomologico/EntomologicoFilterPresentational";
import { buildEpidemiologicalLabels } from "../../../services/utils/Entomologico/Labels";
import { getEpidemiologicalPeriod } from "../../../services/utils/Entomologico/Period";
import ActiveMapContainer from "../../../components/Containers/Entomologico/Maps/ActiveMapContainer";
import MainPeriodComponent from "../../../components/Inputs/MainPeriodComponent";
import getWeeks from "../../../services/utils/epidemiologicalWeek";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";
import {
  fetchAnimalGenres,
  fetchAnimalSpecies,
} from "../../../services/api/AnimalGroups";
import { getTodayEpidemiologicalWeek } from "../../../services/utils/todayEpidemiologicalWeek";
import TodayEpidemiologicalWeekComponent from "../../../components/Containers/Entomologico/todayEpidemiologicalWeek/TodayEpidemiologicalWeekComponent";
import MapLoadingSkeleton from "../../../components/ui/Loading/MapLoadingSkeleton";
import { getVersion } from "../../../constants/VersionConstant";
import { Select } from "../../../components/Wrappers/SelectAll";
import { getAllTypeTerritorializations } from "../../../services/api/territorialization";
import "../../Traps.css";
import GlobalTrapsNumbersCards from "./Cards/GlobalTrapsNumbersCards";
import GlobalTrapsByColorStatusCards from "./Cards/GlobalTrapsByColorStatusCards";
import GlobalVisionTable from "./Tables/GlobalVisionTable";

const GlobalVisionPage = ({ demandsList,
  teamsList,
  employeesList,
  territorializationsList,}) => {
  const organizationId = getUserData("organizationId");
  const [pgs, setPgs] = useState(null);
  const [isActiveTrapMapLoading, setActiveTrapMapLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState("");
  const [trapTypeAUX, setTrapTypeAUX] = useState({
    trapTypeId: "00000000-0000-0000-0000-000000000000",
    trapTypeName: "Ovitrampa",
    trapName: "armadilhaOvos",
  });
  const [trapType, setTrapType] = useState({
    trapTypeId: "00000000-0000-0000-0000-000000000000",
    trapTypeName: "Ovitrampa",
    trapName: "armadilhaOvos",
  });
  const [trapTypes, setTrapTypes] = useState([
    {
      trapTypeId: "00000000-0000-0000-0000-000000000000",
      trapTypeName: "Ovitrampa",
      trapName: "armadilhaOvos",
    },
  ]);
  const [trapTypeOptions, setTrapTypeOptions] = useState([]);
  const [speciesOptions, setSpeciesOptions] = useState([]);
  const [selectedSpecie, setSelectedSpecie] = useState(null);
  const [ActiveTrapMapInfo, setActiveTrapMapInfo] = useState(null);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartPeriods, setChartPeriods] = useState([]);
  const [isFilterVisible, setFilterVisibility] = useState(true);
  const [months, setFilterMonths] = useState([]);
  const [year, setFilterYear] = useState([]);
  const [weeks, setFilterWeeks] = useState([]);
  const [weekValue1, setWeekValue1] = useState([]);
  const [weekValue2, setWeekValue2] = useState([]);
  const [demand, setFilterDemand] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [employee, setFilterEmployee] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [team, setFilterTeam] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [selectedTerritorializations, setSelectedTerritorializations] =
    useState([]);
  const teamEmployees = useRef([]);
  const [todayEpidemiologicalWeek, setTodayEpidemiologicalWeek] = useState("1");
  const [trapsList, setTrapsList] = React.useState(null);
  const [isTrapsListLoading, setIsTrapsListLoading] = React.useState(false);
  const [updatedData, setUpdatedData] = useState(false);
  const [trapToCenterOnMapPosition, setTrapToCenterOnMapPosition] =
    React.useState([]);
  const [trapToCenterNumber, setTrapToCenterNumber] = React.useState([]);
  const [selectedOrganization, setSelectedOrganization] = React.useState({
    label: "Todos",
    value: "00000000-0000-0000-0000-000000000000",
    name: "Todos",
    organizationId: "00000000-0000-0000-0000-000000000000",
    adiTrapTypeId: "00000000-0000-0000-0000-000000000000",
  });
  const [trapsByStatusColorData, setTrapsByStatusColorData] = React.useState(
    []
  );

  React.useEffect(() => {
    getAllTypeLayers();
    getAllTrapTypes();
  }, []);

  const BIOVEC_ORGANIZATIONS_DATA_OPTIONS = [
    {
      label: "Cotia",
      value: "24d125ef-15e6-4bd9-9111-c0b3d50a8009",
      name: "Cotia",
      organizationId: "24d125ef-15e6-4bd9-9111-c0b3d50a8009",
      adiTrapTypeId: "9f580dfa-e496-4709-b00b-6513ff82d71a",
      totalSoldTraps: 1000,
    },
    {
      label: "Dracena",
      value: "d67ef2b0-92b1-40bf-aba6-0e06070e6091",
      name: "Dracena",
      organizationId: "d67ef2b0-92b1-40bf-aba6-0e06070e6091",
      adiTrapTypeId: "029a8a5d-0d52-4319-bd4b-dc78193c0e83",
      totalSoldTraps: 1100,
    },
    {
      label: "Goiânia",
      value: "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30",
      name: "Goiânia",
      organizationId: "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30",
      adiTrapTypeId: "84faeba2-9d06-40c1-9808-b97457e1261f",
      totalSoldTraps: 10800,
    },
    {
      label: "Ribeirão Pires",
      value: "4d4b0460-c8de-474a-9bb9-44e2ffa272b6",
      name: "Ribeirão Pires",
      organizationId: "4d4b0460-c8de-474a-9bb9-44e2ffa272b6",
      adiTrapTypeId: "547c3398-1dda-4e7a-a394-4d5bab5f8588",
      totalSoldTraps: 1000,
    },
    {
      label: "São Paulo",
      value: "559a16ab-5da8-41ba-96b8-6e9f55feddd8",
      name: "São Paulo",
      organizationId: "559a16ab-5da8-41ba-96b8-6e9f55feddd8",
      adiTrapTypeId: "b935d0a1-9dbb-4e08-9672-f7205605530f",
      totalSoldTraps: 40000,
    },
  ];

  const getAllTypeLayers = async () => {
    try {
      const { data, status } = await getAllTypeTerritorializations();
      if (status !== 200)
        throw new Error("Ocorreu um erro ao buscar pelos tipos de camadas");

      const formatedTypeLayers = data.map(({ name, id }) => {
        return {
          label: name,
          value: id,
        };
      });

      /* setConsolidatedTypeLayersOptions(formatedTypeLayers); */
    } catch (error) {
      console.error(error);
    }
  };

  const getAllTrapTypes = async () => {
    try {
      const { data, status } = await getTrapsTypes();
      if (status !== 200)
        throw new Error("Ocorreu um erro ao buscar pelos tipos de armadilhas");

      const formatedTrapTypes = data.map(({ id, name }) => {
        return {
          label: ARMADILHA_INFO[name].apelido,
          value: id,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const getStartDate = () => {
    const date = new Date();

    date.setMonth(date.getMonth() - 1);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return year + "-" + month + "-" + day;
  };

  const getEndDate = (isFilter = false) => {
    let date = new Date();

    if (!isFilter) date.setDate(date.getDate() + 1);

    const endDateString =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "-" +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());

    return endDateString;
  };

  useEffect(() => {
    const Pgs = {
      showRemovedTrapsInTable: true,
    };

    setPgs(Pgs);

    setStartDate(getStartDate());

    setTodayEpidemiologicalWeek(getTodayEpidemiologicalWeek());

    setEndDate(getEndDate(true));

    const reqTrapTypes = getTrapsTypes();
    reqTrapTypes.then((response) => {
      setTrapTypeOptions(response.data);
      if (response.data.length > 0) {
        let index;

        const organizationId = getUserData("organizationId");

        if (
          organizationId === "24d125ef-15e6-4bd9-9111-c0b3d50a8009" ||
          organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8" ||
          organizationId === "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30" ||
          organizationId === "4d4b0460-c8de-474a-9bb9-44e2ffa272b6" ||
          organizationId === "d67ef2b0-92b1-40bf-aba6-0e06070e6091" ||
          organizationId === "7479f4e9-1706-41d4-8402-0bae72ef71ec" 
        )
          index = response.data.findIndex(
            (tpType) => tpType.name === "armadilhaDisseminadoraInseticida"
          );
        else {
          index = response.data.findIndex(
            (tpType) => tpType.name === "armadilhaOvos"
          );
        }

        var trap = {
          trapTypeId: response.data[index].id,
          trapTypeName: ARMADILHA_INFO[response.data[index].name].apelido,
          trapName: response.data[index].name,
        };
        setTrapType(trap);

        let traps = [];
        response.data.map((trap) => {
          if (trap.name) {
            traps.push({
              trapTypeId: trap.id,
              trapTypeName: ARMADILHA_INFO[trap.name].apelido,
              trapName: trap.name,
            });
          }
        });
        setTrapTypes(traps);
      }

      applyFilter();
    });

    const species = [];
    fetchAnimalGenres("178886c4-a41c-4d15-ac3c-a58806bb8df9").then(
      async (response) => {
        for (const genre of response.data) {
          let speciesRequest = await fetchAnimalSpecies(genre.id);
          for (const specie of speciesRequest.data) {
            species.push({
              label: specie.name,
              value: specie.id,
            });
          }
        }

        setSpeciesOptions(species);
      }
    );
  }, []);

  const fetchTrapsStatusByColorData = async () => {
    try {
      let trapsStatusPromises;

      if (selectedOrganization.label === "Todos") {
        trapsStatusPromises = BIOVEC_ORGANIZATIONS_DATA_OPTIONS.map(
          async ({ organizationId, adiTrapTypeId }) => {
            const requestParams = {
              organizationId,
              trapTypeId: adiTrapTypeId,
            };

            const { data } = await getTrapsStatusByColor(requestParams);

            return data;
          }
        );
      } else {
        const requestParams = {
          organizationId: selectedOrganization.organizationId,
          trapTypeId: selectedOrganization.adiTrapTypeId,
        };

        trapsStatusPromises = await getTrapsStatusByColor(requestParams);

        return [trapsStatusPromises.data];
      }

      const results = await Promise.all(trapsStatusPromises);

      return results;
    } catch (error) {
      console.error(error);
      toast.error(
        "Ocorreu um erro ao buscar pelos status de armadilhas por cores. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com nossa equipe"
      );
    }
  };

  useEffect(() => {
    if (chartLabels.length > 0) {
      fetchData();
    }
  }, [chartLabels]);

  async function applyFilter() {
    if (year.length > 0) {
      setActiveTrapMapLoading(true);
      buildYearsLabelsAndPeriods(year);
    } else {
      setActiveTrapMapLoading(true);
      var startDateAux = getStartDate();
      var endDateAux = getEndDate();
      buildLabelsAndPeriods(startDateAux, endDateAux);
    }
  }

  function changePeriod(e, field) {
    switch (field) {
      case "Year":
        if (e === null) {
          setFilterYear([]);
          setFilterMonths([]);
          setFilterWeeks([]);
          break;
        } else {
          e = e.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterYear(e !== null ? e : []);
        break;

      case "Month":
        if (e !== null) {
          setFilterWeeks([]);
          e = e.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterMonths(e !== null ? e : []);
        break;

      case "EpiWeek":
        if (e !== null) {
          setFilterMonths([]);
          e = e.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterWeeks(e !== null ? e : []);
        break;

      case "Week1":
        if (e !== null) {
          setFilterMonths([]);
        }
        setWeekValue1(e !== null ? e : []);
        break;

      case "Week2":
        if (e !== null) {
          setFilterMonths([]);
        }
        setWeekValue2(e !== null ? e : []);
        break;
    }
  }

  function handleChangeFilter(field, value) {
    switch (field) {
      case "startDate":
        if (moment(value).isAfter(endDate)) {
          toast.error("Período de data inválido!");
        } else {
          setStartDate(value);
        }
        break;
      case "endDate":
        if (moment(value).isBefore(startDate)) {
          toast.error("Período de data inválido!");
        } else {
          setEndDate(value);
        }
        break;
      case "trapType":
        const selectedTrapType = trapTypeOptions.filter((tp) => tp.id == value);

        //setando a especie para aedes aegypti se marcar adultrap
        if (ARMADILHA_INFO[selectedTrapType[0].name].apelido == "Adultrap") {
          setSelectedSpecie("3ce3a48f-9555-4b58-8bb9-18c8a4c7549c");
        } else {
          setSelectedSpecie(null);
        }

        setTrapTypeAUX({
          trapTypeId: selectedTrapType[0].id,
          trapTypeName: ARMADILHA_INFO[selectedTrapType[0].name].apelido,
          trapName: selectedTrapType[0].name,
        });
        toast.info(
          "Tipo de armadilha alterado! Clique no botão para filtrar novamente."
        );
        break;
      case "demandId":
        if (value) {
          setFilterDemand(value.value);
        } else {
          setFilterDemand("00000000-0000-0000-0000-000000000000");
        }
        break;
      case "teamId":
        if (value) {
          let employeeUserIds = new Array();
          for (const member of value.members) {
            if (member.userId) {
              employeeUserIds.push(member.userId);
            }
          }
          teamEmployees.current = employeeUserIds;
        } else {
          teamEmployees.current = new Array();
        }

        setFilterTeam(value);
        break;
      case "employeeId":
        if (value) {
          setFilterEmployee(value.value);
          toast.info(
            "Funcionário alterado! Clique no botão para filtrar novamente."
          );
        } else {
          setFilterEmployee("00000000-0000-0000-0000-000000000000");
        }
        break;
      case "territorialization":
        if (value !== null) {
          //colocando as territorializações para ficarem selecionadas no filtro.
          setSelectedTerritorializations(value);
        } else {
          setSelectedTerritorializations([]);
        }
        break;
      case "specie":
        setSelectedSpecie(value);
        break;
    }
  }

  async function fetchActiveTrapToMap(employees, endDate) {
    let filterPeriod = null;
    let previousFilterPeriod = null;

    let currentDate = moment.utc(endDate);

    for (let i = chartPeriods.length - 1; i >= 0; i--) {
      if (filterPeriod === null) {
        const periods = chartPeriods[i].periods;
        for (let j = periods.length - 1; j >= 0; j--) {
          if (
            currentDate.isBetween(
              moment(periods[j].beginDate),
              moment(periods[j].finalDate)
            ) ||
            currentDate.isSameOrAfter(moment(periods[j].finalDate))
          ) {
            filterPeriod = periods[j];
            previousFilterPeriod = periods[j - 1];
            break;
          }
        }
      } else {
        break;
      }

      // aqui
      if (filterPeriod === null && i === 0) {
        filterPeriod = {
          beginDate: currentDate.format("YYYY-MM-DD"),
          finalDate: currentDate.format("YYYY-MM-DD"),
        };
        break;
      }
    }

    let activeTrapPromises;

    if (selectedOrganization.label === "Todos") {
      activeTrapPromises = BIOVEC_ORGANIZATIONS_DATA_OPTIONS.map(
        async ({ name, organizationId, adiTrapTypeId }) => {
          var userIds = [];
          if (team && team != "00000000-0000-0000-0000-000000000000") {
            team.members.map((emp) => {
              if (emp.userId != "00000000-0000-0000-0000-000000000000") {
                userIds.push(emp.userId);
              }
            });
          } else if (
            employee &&
            employee != "00000000-0000-0000-0000-000000000000"
          ) {
            userIds.push(employee);
          }

          const filterMap = {
            organizationId,
            period: filterPeriod,
            trapTypeId: adiTrapTypeId,
            usersIds: userIds,
            demandId: demand,
            territorializations: selectedTerritorializations.map(
              ({ value }) => value
            ),
          };

          try {
            const response = await getActiveTrapsToMap(filterMap);
            return { data: response.data, trapType: `ADI - ${name}`, organizationId };
          } catch (error) {
            console.error(error);
            throw error;
          }
        }
      );
    } else {
      activeTrapPromises = Array.from({ length: 1 }).map(async (_) => {
        var userIds = [];
        if (team && team != "00000000-0000-0000-0000-000000000000") {
          team.members.map((emp) => {
            if (emp.userId != "00000000-0000-0000-0000-000000000000") {
              userIds.push(emp.userId);
            }
          });
        } else if (
          employee &&
          employee != "00000000-0000-0000-0000-000000000000"
        ) {
          userIds.push(employee);
        }

        const filterMap = {
          organizationId,
          period: filterPeriod,
          trapTypeId: selectedOrganization.adiTrapTypeId,
          usersIds: userIds,
          demandId: demand,
          territorializations: selectedTerritorializations.map(
            ({ value }) => value
          ),
        };

        try {
          const response = await getActiveTrapsToMap(filterMap);
          return {
            data: response.data,
            trapType: `ADI - ${selectedOrganization.name}`,
            organizationId,
          };
        } catch (error) {
          console.error(error);
          throw error;
        }
      });
    }

    const activeTrap = await Promise.all(activeTrapPromises);

    setActiveTrapMapInfo(activeTrap);
    setActiveTrapMapLoading(false);
  }

  const fetchAllComponentData = async () => {
    const employees = teamEmployees.current;

    const endDateFunctional = new Date(
      Date.UTC(
        parseInt(endDate.substring(0, 4)),
        parseInt(endDate.substring(5, 7)) - 1,
        parseInt(endDate.substring(8, 10))
      )
    );
    endDateFunctional.setDate(endDateFunctional.getDate() + 1);

    if (
      employee != "00000000-0000-0000-0000-000000000000" &&
      !employees.includes(employee)
    ) {
      employees.push(employee);
    }

    const trapsStatusCache = fetchTrapsStatusByColorData();
    const activeTrapsToMap = fetchActiveTrapToMap(
      employees,
      endDateFunctional.toISOString().slice(0, 10)
    );

    const promises = await Promise.all([trapsStatusCache, activeTrapsToMap]);

    setTrapsByStatusColorData(promises[0]);

    return promises;
  };

  const fetchData = async () => {
    const [formatedTrapsList] = await fetchAllComponentData();

    setTrapsList(formatedTrapsList);
    setUpdatedData(true);
  };

  async function buildLabelsAndPeriods(startDate, endDate) {
    const labels = await buildEpidemiologicalLabels({ startDate, endDate }); //semana epidemiológica
    const periods = new Array({ year: "", periods: [] });
    let labelsToCharts = new Array();
    for (let i = 0; i < labels.length; i++) {
      for (
        let labelIndex = 0;
        labelIndex < labels[i].labels.length;
        labelIndex++
      ) {
        const { finalDate, beginDate } = await getEpidemiologicalPeriod({
          labels: labels[i].labels,
          labelIndex,
          startDate,
          endDate,
          year: labels[i].year,
          lastYear: labels[labels.length - 1].year,
          firstYear: labels[0].year,
        }); //semana epidemiológica
        labelsToCharts.push(
          labels[i].labels[labelIndex] + "/" + labels[i].year
        );
        periods[0].periods.push({ finalDate, beginDate });
      }
    }

    setChartPeriods(periods);
    setChartLabels(labelsToCharts);
  }

  async function buildYearsLabelsAndPeriods(years = new Array()) {
    const labels = new Array();
    const periods = new Array();

    if (months.length === 0 && weeks.length === 0) {
      for (let i = 1; i <= 53; i++) {
        labels.push(i);
      }

      for (let i = 0; i < years.length; i++) {
        const epidemiologicalWeek = getWeeks().filter(
          (ew) => ew.year === years[i].value
        );
        periods.push({
          year: years[i].value,
          periods: epidemiologicalWeek[0].weeks.map((ew) => {
            return {
              finalDate: moment(ew.endDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
              beginDate: moment(ew.beginDate, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ),
            };
          }),
        });
      }
    } else if (weeks.length === 0) {
      months.forEach((month) => {
        labels.push(month.label);
      });

      for (let i = 0; i < years.length; i++) {
        const monthPeriods = [];

        for (let j = 0; j < months.length; j++) {
          const beginDate = moment(
            new Date(years[i].value, months[j].value, 1)
          ).format("YYYY-MM-DD");
          const finalDate = moment(beginDate)
            .add(1, "month")
            .subtract(1, "day")
            .format("YYYY-MM-DD");
          monthPeriods.push({ beginDate, finalDate });
        }

        periods.push({ year: years[i].value, periods: monthPeriods });
      }
    } else {
      weeks.forEach((weeks) => {
        labels.push(weeks.label);
      });

      for (let i = 0; i < years.length; i++) {
        const weeksPeriods = [];
        const epiWeeks = getWeeks().filter((ew) => ew.year === years[i].value);

        for (let j = 0; j < weeks.length; j++) {
          if (epiWeeks[0].weeks[weeks[j].value] !== undefined) {
            const beginDate = moment(
              epiWeeks[0].weeks[weeks[j].value].beginDate,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD");
            const finalDate = moment(
              epiWeeks[0].weeks[weeks[j].value].endDate,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD");
            weeksPeriods.push({ beginDate, finalDate });
          }
        }
        periods.push({ year: years[i].value, periods: weeksPeriods });
      }
    }

    setChartPeriods(periods);
    setChartLabels(labels);
  }

  const styles = {
    grid: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    row: {
      marginLeft: 0,
      marginRight: 0,
    },
    col: {
      paddingLeft: 0,
      paddingRight: "0.2vw",
    },
  };

  const selectComponentStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
  };

  const consolidatedReportTableColumns = [
    {
      name: "Nome da territorialização",
      selector: "name",
      sortable: true,
    },
    {
      name: "Quantidade de armadilhas",
      selector: "quantity",
      sortable: true,
    },
  ];

  return (
    <>
      <ToastContainer />
      <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8 mb-5">
        <Container className="filter-container">
          <button
            className="btn btn-primary hide-btn"
            onClick={() => {
              setFilterVisibility(!isFilterVisible);
            }}
          >
            {isFilterVisible ? "Esconder Filtro" : "Mostrar Filtro"}
          </button>
          {isFilterVisible ? (
            <button
              className="btn btn-primary hide-btn"
              onClick={() => {
                if (
                  trapTypeAUX.trapTypeId !=
                  "00000000-0000-0000-0000-000000000000"
                ) {
                  setTrapType(trapTypeAUX);
                  applyFilter();
                } else {
                  applyFilter();
                }
              }}
            >
              Filtrar
            </button>
          ) : null}
        </Container>

        {isFilterVisible ? (
          <>
            <Row style={{ display: "flex", gapTop: "5px" }}>
              <MainPeriodComponent
                months={months}
                years={year}
                weeks={weeks}
                weekValue1={weekValue1}
                weekValue2={weekValue2}
                showWeekToWeek
                changePeriod={changePeriod}
              />
              <EntomologicoFilterPresentational
                startDate={startDate}
                endDate={endDate}
                trapTypeId={trapType.trapTypeId}
                trapTypeIdAUX={trapTypeAUX.trapTypeId}
                trapTypeOptions={trapTypeOptions}
                handleChangeFilter={handleChangeFilter}
                speciesOptions={speciesOptions}
                selectedSpecie={selectedSpecie}
                applyFilter={applyFilter}
                hideTrapRelatedInputs={true}
              />
            </Row>
            <Row>
              <Col className="col-4 col-md-4 col">
                <span className="h4 text-white">Município:</span>
                <Select
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      minHeight: "2.90rem",
                      borderRadius: "0.370rem",
                    }),
                    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
                    valueContainer: (styles) => ({
                      ...styles,
                      fontSize: "0.875rem",
                      color: "#8898aa",
                    }),
                    singleValue: (styles) => ({
                      ...styles,
                      fontSize: "0.875rem",
                      color: "#8898aa",
                    }),
                    placeholder: (styles) => ({
                      ...styles,
                      fontSize: "0.875rem",
                      color: "#8898aa",
                    }),
                    menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
                  }}
                  placeholder={"Selecione"}
                  options={[
                    {
                      label: "Todos",
                      value: "00000000-0000-0000-0000-000000000000",
                      name: "Todos",
                      organizationId: "00000000-0000-0000-0000-000000000000",
                      adiTrapTypeId: "00000000-0000-0000-0000-000000000000",
                    },
                    ...BIOVEC_ORGANIZATIONS_DATA_OPTIONS,
                  ]}
                  value={selectedOrganization}
                  onChange={(e) => setSelectedOrganization(e)}
                />
              </Col>
              <TodayEpidemiologicalWeekComponent
                todayEpidemiologicalWeek={todayEpidemiologicalWeek}
              />
              <Col className="col-4 col-md-4 col"></Col>
            </Row>
            <br />
          </>
        ) : null}

        <GlobalTrapsNumbersCards
          trapsByColorStatusData={trapsByStatusColorData}
          organizationsData={BIOVEC_ORGANIZATIONS_DATA_OPTIONS}
          selectedOrganizationData={selectedOrganization}
        />
        <GlobalTrapsByColorStatusCards
          trapsByColorStatusData={trapsByStatusColorData}
        />
      </Container>
      <Container fluid className="mt--7">
        <Row className="mb-5">
          <Col xl="12" className="mb-5 mb-xl-0">
            {isActiveTrapMapLoading ? (
              <MapLoadingSkeleton />
            ) : (
              <ActiveMapContainer
                trapMapInfo={ActiveTrapMapInfo}
                territorializationsList={territorializationsList}
                territorializationFiltered={selectedTerritorializations}
                trapTypeName={trapType.trapTypeName}
                trapToCenterOnMapPosition={trapToCenterOnMapPosition}
                setTrapToCenterOnMapPosition={setTrapToCenterOnMapPosition}
                trapToCenterNumber={trapToCenterNumber}
                setTrapToCenterNumber={setTrapToCenterNumber}
              />
            )}
          </Col>
        </Row>
        <GlobalVisionTable
          trapsByColorStatusData={trapsByStatusColorData}
          organizationsData={BIOVEC_ORGANIZATIONS_DATA_OPTIONS}
        />
      </Container>
    </>
  );
};

export default GlobalVisionPage;
