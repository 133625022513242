import React from "react";
import Skeleton from "../Skeleton";

const InputLoadingSkeleton = ({ labelWidth, inputWidth, inputHeight = "1rem", labelHeight = "1rem", padding = null}) => {
  return (
    <>
      <Skeleton variant="rectangle" padding={padding} height={labelHeight} width={labelWidth} />
      <Skeleton variant="rectangle" padding={padding} height={inputHeight} width={inputWidth} />
    </>
  );
};

export default InputLoadingSkeleton;
