import { GOOGLE_MAPS_API_KEY } from "../../../constants/WebFormConstants";
import { getUserData } from "../auth";

export const checkIfRequiredFieldsAreEmpty = (headerInputs, tableRows, organizationId) => {
  const EMPTY_FIELD = "";
  let errorMessage = "";

  if(organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8"){
    if (headerInputs.uvisName.value === EMPTY_FIELD) return "Informe o nome da UVIS";
    if (headerInputs.administrativeDistrict.value === EMPTY_FIELD)
      return "Informe o Distrito Administrativo";
  }

  if (headerInputs.teamsAndEmployees.length === 0)
    return "Informe os funcionários que participaram da atividade";
  if (headerInputs.activityDate === EMPTY_FIELD)
    return "Informe a data da atividade";

  for (let i = 0; i < tableRows.length; i++) {
    const { address, addressNumber, propertyType, trapNumber, visitStatus } =
      tableRows[i];
    if (address === EMPTY_FIELD) {
      errorMessage = `Informe o LOGRADOURO da residência na linha ${i + 1}`;
      break;
    }
    if (addressNumber === EMPTY_FIELD) {
      errorMessage = `Informe o NÚMERO da residência na linha ${i + 1}`;
      break;
    }
    if (propertyType === EMPTY_FIELD) {
      errorMessage = `Informe o TIPO DE IMÓVEL da residência na linha ${i + 1}`;
      break;
    }
    if (trapNumber === EMPTY_FIELD) {
      errorMessage = `Informe o NÚMERO da armadilha na linha ${i + 1}`;
      break;
    }
    if (visitStatus === EMPTY_FIELD) {
      errorMessage = `Informe o STATUS da visita na linha ${i + 1}`;
      break;
    }
  }

  return errorMessage !== "" ? errorMessage : false;
};

export const getAddressInformations = async (address, addressNumber) => {
  const getUserCityAndStateByOrganizationId = {
    "559a16ab-5da8-41ba-96b8-6e9f55feddd8": "São Paulo - SP, Brasil",
    "24d125ef-15e6-4bd9-9111-c0b3d50a8009": "Cotia - SP, Brasil",
    "37601ec4-d2ef-4f86-aeac-fd261e8fded4": "Cotia - SP, Brasil",
    "d67ef2b0-92b1-40bf-aba6-0e06070e6091": "Dracena - SP, Brasil",
    "4d4b0460-c8de-474a-9bb9-44e2ffa272b6": "Ribeirão Pires - SP, Brasil",
    "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30": "Goiânia - GO, Brasil",
    "8e34767e-3673-4606-9e47-dd67edd2677d": "Foz do Iguaçu - PR, Brasil",
    "42a4ea68-1e69-428c-9498-e4727d30dd20": "Rio de Janeiro - RJ, Brasil",
  };
  const fullAddress = `${address}, ${addressNumber}, ${
    getUserCityAndStateByOrganizationId[getUserData("organizationId")]
  }`;

  const addressApiRequest = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAPS_API_KEY}&address=${fullAddress}`
  );
  const requestResponseInJson = await addressApiRequest.json();

  if (!requestResponseInJson) return false;

  return requestResponseInJson.results[0];
};