import React from "react";

import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Row,
} from "reactstrap";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts";
import CsvDownloadButton from "react-json-to-csv";
import { toast } from "react-toastify";
import { toPng } from "html-to-image";
import ReactTooltip from "react-tooltip";

const PieChartGraphic = ({
  data,
  dataToDownload,
  customTooltip,
  dataKey,
  legendsKey,
  headerText,
}) => {
  const [generatedAt, setGeneratedAt] = React.useState("");

  const contentToPrintRef = React.useRef(null);

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const splitedActualDate = moment().format("DD/MM/YYYY HH:mm").split(" ");

    setGeneratedAt(`${splitedActualDate[0]} às ${splitedActualDate[1]}`);
  }, [data]);

  const handleDownloadViewImage = async () => {
    try {
      if (contentToPrintRef.current === null) return;

      const imageToDownloadString = await toPng(contentToPrintRef.current, {
        quality: 1,
        pixelRatio: 2,
      });

      if (!imageToDownloadString || imageToDownloadString === "")
        throw new Error("Ocorreu um erro ao gerar a imagem do gráfico.");

      const link = document.createElement("a");
      link.download = `${headerText.replaceAll(
        " ",
        ""
      )}GeneratedAt${generatedAt}.png`;
      link.href = imageToDownloadString;
      link.click();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  const calculatePiePercentage = (pieQuantity, data) => {
    const total = data.reduce((sum, object) => sum + object[dataKey], 0);

    return ((pieQuantity / total) * 100).toFixed(2);
  };

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#a19e9c",
    "#865838",
    "#6d6966",
    "#8a8703",
    "#ff0000",
    "#f700ff",
    "#4400ff",
    "#00ccff",
    "#064f58",
  ];

  const style = {
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    cardBody: {
      padding: "1rem",
    },
    cardHeaderText: {
      color: "black",
      fontWeight: "bold",
      fontSize: "0.97rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };

  return (
    <div ref={contentToPrintRef}>
      <Card className="shadow">
        <CardHeader>
          <CardText className="mb-0 mt-0" style={style.cardHeaderText}>
            <div>
              <span>{headerText}</span>
            </div>
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <span style={{ fontSize: "13px", fontWeight: "normal" }}>
                Gerado em {generatedAt}
              </span>
              <span>
                <CsvDownloadButton
                  className="btn btn-primary"
                  data={!dataToDownload ? data : dataToDownload}
                  filename={`${headerText.replaceAll(" ", "")}${generatedAt}`}
                  style={{ width: "55px", marginRight: "0px" }}
                  data-tip
                  data-for="dataDownload"
                >
                  <i className="fa fa-download" />
                </CsvDownloadButton>
                <ReactTooltip effect="solid" type="info" id="dataDownload">
                  Baixar dados
                </ReactTooltip>
              </span>
              <span>
                <Button
                  onClick={() => handleDownloadViewImage()}
                  color="primary"
                  data-tip
                  data-for="viewDownload"
                  style={{ width: "55px", marginRight: "0px" }}
                >
                  <i className={"fa fa-image"} />
                </Button>
                <ReactTooltip effect="solid" type="info" id="viewDownload">
                  Baixar visualização
                </ReactTooltip>
              </span>
            </div>
          </CardText>
        </CardHeader>
        <CardBody style={style.cardBody}>
          <Row>
            <Col style={style.col}>
              <section>
                <ul>
                  {data &&
                    data.length > 0 &&
                    data.map((paramData, index) => {
                      return (
                        <li
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <div
                            style={{
                              width: "14px",
                              height: "10px",
                              backgroundColor: COLORS[index % COLORS.length],
                            }}
                          ></div>
                          <span
                            style={{ color: COLORS[index % COLORS.length] }}
                          >
                            {paramData[legendsKey]}:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              ({paramData[dataKey]}){" "}
                              {calculatePiePercentage(paramData[dataKey], data)}
                              %
                            </span>
                          </span>
                        </li>
                      );
                    })}
                </ul>
              </section>
            </Col>
            <Col style={style.col}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={900} height={900}>
                  <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey={dataKey}
                  >
                    {data &&
                      data.length > 0 &&
                      data.map((_, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                  </Pie>
                  <Tooltip content={customTooltip} />
                </PieChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default PieChartGraphic;
