import React from "react";
import { Card, CardHeader, Col, Row } from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment";
import DataTable from "react-data-table-component";
import exportVisitToCsv from "../../../../services/utils/exportVisitToCsv";
import { getUserData } from "../../../../services/utils/auth";
import { getVersion } from "../../../../constants/VersionConstant";
import LoadingSpin from "react-loading-spin";

const VisitsExportTable = ({
  visitsForTable,
  visitsToExport,
  completeVisitData,
}) => {
  const [formatedTrapsToExport, setFormatedTrapsToExport] = React.useState([]);
  const organizationId = getUserData("organizationId");
  const webVersion = getVersion(organizationId);

  React.useEffect(() => {
    if (
      !visitsToExport ||
      !formatedTrapsToExport ||
      formatedTrapsToExport.length > 0
    )
      return;

    setFormatedTrapsToExport([
      ...visitsToExport.traps.armadilhaDisseminadoraInseticida,
      ...visitsToExport.traps.armadilhaOvos,
      ...visitsToExport.traps.armadilhaMosquitoAdulto,
      ...visitsToExport.traps.armadilhaMosquitoAdulto2,
      ...visitsToExport.traps.estacaoDisseminadoraLarvicida,
    ]);
  }, [visitsToExport]);


  const tableColumns = [
    {
      name: "Data da atividade",
      selector: "activityDate",
      sortable: true,
    },
    {
      name: "Número demanda",
      selector: "demand",
      sortable: true,
    },
    {
      name: "UVIS",
      selector: "uvis",
      sortable: true,
    },
    {
      name: "Tipo formulário",
      selector: "typeForm",
      sortable: true,
    },
    {
      name: "Agente",
      selector: "collector",
      sortable: true,
    },
    {
      name: "Situação visita",
      selector: "situation",
      sortable: true,
    },
  ];



  return (
    <Row className="mt-12">
      <Col className="mb-12 mb-xl-0" xl="12">
        <Card className="shadow">
          <CardHeader className="border-0" style={{ paddingBottom: "0px" }}>
            <Row className="align-items-center">
              <div className="col">
                <h2 className="mb-0">Visitas</h2>
                <br />
              </div>
            </Row>
          </CardHeader>
          {/* <DemandsExportFilters
            demandsFilters={demandsFilters}
            handleFiltersChange={handleFiltersChange}
          /> */}
          <Row
            style={{
              textAlign: "center",
              marginBottom: "1rem",
              padding: "1rem",
            }}
          >
            <Col>
              <CsvDownloadButton
                className="btn btn-primary"
                filename={`visitas_depositos${moment().format("YYYY-MM-DD")}`}
                data={visitsToExport ? visitsToExport.deposits : []}
              >
                <i className="fa fa-download" /> Baixar CSV dos Depósitos
              </CsvDownloadButton>
            </Col>
            <Col>
              {formatedTrapsToExport && formatedTrapsToExport.length > 0 && (
                <CsvDownloadButton
                  className="btn btn-primary"
                  filename={`visitas_armadilhas${moment().format(
                    "YYYY-MM-DD"
                  )}`}
                  data={formatedTrapsToExport ? formatedTrapsToExport : []}
                >
                  <i className="fa fa-download" /> Baixar CSV das visitas
                </CsvDownloadButton>
              )}
            </Col>
          </Row>
          <DataTable
            noHeader
            defaultSortField="name"
            defaultSortAsc={true}
            pagination
            highlightOnHover
            responsive
            columns={tableColumns}
            data={visitsForTable}
            noDataComponent={"Nenhum registro encontrado."}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default VisitsExportTable;
