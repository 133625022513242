import React from "react";
import { Col, Row } from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";
import { getUserEmployeeDataByOrganization } from "../../../../services/api/Users";
import { getTrapsTypes } from "../../../../services/api/Trap";
import InputLoadingSkeleton from "../../../../components/ui/Loading/InputLoadingSkeleton";

const SamplesExportFilters = ({
  samplesFilters,
  handleFiltersChange,
  isSamplesAnalysisDataLoading,
}) => {
  const [employeesOptions, setEmployeesOptions] = React.useState(null);
  const [trapTypesOptions, setTrapTypesOptions] = React.useState(null);

  React.useEffect(() => {
    fetchAllComponentDatas();
  }, []);

  const fetchEmployees = async () => {
    try {
      const { data, status } = await getUserEmployeeDataByOrganization();
      if (status !== 200)
        throw new Error("Ocorreu um erro ao buscar pelos dados");

      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTrapTypes = async () => {
    try {
      const { data, status } = await getTrapsTypes();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos tipos de armadilhas. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllComponentDatas = async () => {
    try {
      const employeesRequest = fetchEmployees();
      const trapTypesRequest = fetchTrapTypes();
      const [employeesResult, TrapTypesResult] = await Promise.all([
        employeesRequest,
        trapTypesRequest,
      ]);

      const formatedEmployees = employeesResult.map(
        ({ userId, employeeId, employeeName }) => {
          return {
            userId,
            employeeId,
            name: employeeName,
            label: employeeName,
            value: employeeName,
          };
        }
      );

      const formatedTrapTypes = TrapTypesResult.map(({ name }) => {
        return {
          label: getTrapTypeSurname[name],
          value: name,
        };
      });

      setEmployeesOptions([
        {
          label: "Todos",
          value: "all",
        },
        ...formatedEmployees,
      ]);
      setTrapTypesOptions([
        {
          label: "Todos",
          value: "all",
        },
        ...formatedTrapTypes,
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const trapSituationsOptions = [
    {
      label: "Todas",
      value: "all",
    },
    {
      label: "Criada",
      value: "Created",
    },
    {
      label: "Analisada",
      value: "Analyzed",
    },
  ];

  const sampleMaterialTypesOptions = [
    {
      label: "Todos",
      value: "all",
    },
    {
      label: "Água",
      value: "Água",
    },
    {
      label: "Larvas",
      value: "Larvas",
    },
    {
      label: "Larvas e Pupas",
      value: "Larvas e Pupas",
    },
    {
      label: "Mosquito Adulto",
      value: "Mosquito Adulto",
    },
    {
      label: "Palhetas",
      value: "Palhetas",
    },
    {
      label: "Pupas",
      value: "Pupas",
    },
  ];

  const getTrapTypeSurname = {
    armadilhaDisseminadoraInseticida: "ADI",
    armadilhaOvos: "OVITRAMPA",
    armadilhaMosquitoAdulto: "ADULTRAP",
    armadilhaMosquitoAdulto2: "BG-GAT",
    estacaoDisseminadoraLarvicida: "EDL",
  };

  const selectComponentStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
  };

  return (
    <>
      {isSamplesAnalysisDataLoading ? (
        <div>
          <Row
            className="align-items-center"
            style={{ marginBottom: "1rem", padding: "0px 1.5rem" }}
          >
            <Col xl="4">
              <InputLoadingSkeleton labelWidth="120px" inputWidth="300px" />
            </Col>
            <Col xl="4">
              <InputLoadingSkeleton labelWidth="100px" inputWidth="300px" />
            </Col>
            <Col xl="4">
              <InputLoadingSkeleton labelWidth="170px" inputWidth="300px" />
            </Col>
          </Row>
          <Row
            className="align-items-center"
            style={{ marginBottom: "2rem", padding: "0px 1.5rem" }}
          >
            <Col xl="4">
              <InputLoadingSkeleton labelWidth="90px" inputWidth="300px" />
            </Col>
            <Col xl="4">
              <InputLoadingSkeleton labelWidth="150px" inputWidth="300px" />
            </Col>
            <Col xl="4">
              <InputLoadingSkeleton labelWidth="120px" inputWidth="300px" />
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <Row
            className="align-items-center"
            style={{ marginBottom: "1rem", padding: "0px 1.5rem" }}
          >
            <Col xl="4">
              <span style={{ fontWeight: "bold" }}>Número da amostra</span>
              <input
                type="text"
                className="form-control"
                placeholder="Digite o número da demanda"
                value={samplesFilters.sampleNumber}
                onChange={({ target }) =>
                  handleFiltersChange("sampleNumber", target.value)
                }
              />
            </Col>
            <Col xl="4">
              <span style={{ fontWeight: "bold" }}>Número da armadilha</span>
              <input
                type="text"
                className="form-control"
                placeholder="Digite o número da demanda"
                value={samplesFilters.trapNumber}
                onChange={({ target }) =>
                  handleFiltersChange("trapNumber", target.value)
                }
              />
            </Col>
            <Col xl="4">
              <span style={{ fontWeight: "bold" }}>Número da demanda</span>
              <input
                type="text"
                className="form-control"
                placeholder="Digite o número da demanda"
                value={samplesFilters.demandNumber}
                onChange={({ target }) =>
                  handleFiltersChange("demandNumber", target.value)
                }
              />
            </Col>
          </Row>
          <Row
            className="align-items-center"
            style={{ marginBottom: "1rem", padding: "0px 1.5rem" }}
          >
            <Col xl="4">
              <span style={{ fontWeight: "bold" }}>Tipo da armadilha</span>
              <Select
                styles={selectComponentStyles}
                placeholder={"Todos"}
                isClearable={false}
                options={trapTypesOptions}
                value={samplesFilters.trapType}
                onChange={(selectedOptionObject) =>
                  handleFiltersChange("trapType", selectedOptionObject)
                }
              />
            </Col>
            <Col xl="4">
              <span style={{ fontWeight: "bold" }}>Coletor</span>
              <Select
                styles={selectComponentStyles}
                placeholder={"Todos"}
                isClearable={false}
                options={employeesOptions}
                value={samplesFilters.collector}
                onChange={(selectedOptionObject) =>
                  handleFiltersChange("collector", selectedOptionObject)
                }
              />
            </Col>
            <Col xl="4">
              <span style={{ fontWeight: "bold" }}>Situação da amostra</span>
              <Select
                styles={selectComponentStyles}
                placeholder={"Todas"}
                isClearable={false}
                options={trapSituationsOptions}
                value={samplesFilters.situation}
                onChange={(selectedOptionObject) =>
                  handleFiltersChange("situation", selectedOptionObject)
                }
              />
            </Col>
          </Row>
          <Row
            className="align-items-center"
            style={{ marginBottom: "1.5rem", padding: "0px 1.5rem" }}
          >
            <Col xl="4">
              <span style={{ fontWeight: "bold" }}>Tipo de material</span>
              <Select
                styles={selectComponentStyles}
                placeholder={"Todas"}
                isClearable={false}
                options={sampleMaterialTypesOptions}
                value={samplesFilters.materialType}
                onChange={(selectedOptionObject) =>
                  handleFiltersChange("materialType", selectedOptionObject)
                }
              />
            </Col>
            <Col xl="4">
              <span style={{ fontWeight: "bold" }}>
                Data de coleta (Período)
              </span>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "5%",
                  }}
                >
                  <div style={{ width: "45%" }}>
                    <input
                      type="date"
                      value={samplesFilters.beginCollectDate}
                      onChange={({ target }) =>
                        handleFiltersChange("beginCollectDate", target.value)
                      }
                      className="form-control"
                      name="beginCollectDate"
                      id="beginCollectDate"
                    />
                  </div>
                  <div
                    className="h4 text-white"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold", color: "#525f7f" }}>
                      Até
                    </span>
                  </div>
                  <div style={{ width: "45%" }}>
                    <input
                      type="date"
                      value={samplesFilters.endCollectDate}
                      onChange={({ target }) =>
                        handleFiltersChange("endCollectDate", target.value)
                      }
                      className="form-control"
                      name="endCollectDate"
                      id="endCollectDate"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default SamplesExportFilters;
