import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import "../../../assets/css/style.css";
import { editEmployee, fetchEmployee, fetchEmployeeData, fetchEmployeeFunctions } from "../../../services/api/Employee";
import { getUserData } from "../../../services/utils/auth";
import { checkPermissionComponent } from "../../../services/utils/permission";
import { getPermissions, getUserByEmployeeId, updatePermissions } from "../../../services/api/Users";
import { fetchModules } from "../../../services/api/Modules";
import { Select } from "../../../components/Wrappers/SelectAll";
import "./Employee.css";
import { forgotPassword } from "../../../services/api/login";

const educationOptions = [
  {label: "Ensino fundamental incompleto", value: "Fundamental Incompleto"},
  {label: "Ensino fundamental completo", value: "Fundamental Completo"},
  {label: "Ensino médio incompleto", value: "Médio Incompleto"},
  {label: "Ensino médio completo", value: "Médio Completo"},
  {label: "Graduação incompleto", value: "Graduação Incompleto"},
  {label: "Graduação completo", value: "Graduação Completo"},
  {label: "Mestrado", value: "Mestrado"},
  {label: "Doutorado", value: "Doutorado"},
  {label: "Pós Doutorado", value:"Pós Doutorado"},
]

const placeOptions = [
  {label: "Médico Veterinário", value: "Médico Veterinário"},
  {label: "Agente de Endemias", value: "Agente de Endemias"},
  {label: "Analista de Sistemas", value: "Analista de Sistemas"},
  {label: "Agente de Endemias Educador", value: "Agente de Endemias Educador"},
  {label: "Fiscal de Vigilância Sanitária", value: "Fiscal de Vigilância Sanitária"},
  {label: "Técnico em Zoonoses", value: "Técnico em Zoonoses"},
  {label: "Outro", value: "Outro"}
]

const cnhCategory = [
  {label: "A", value: "A"},
  {label: "B", value: "B"},
  {label: "AB", value: "AB"},
  {label: "ABD", value: "ABD"},
  {label: "C", value: "C"},
  {label: "D", value: "D"},
  {label: "E", value: "E"}
]

class EditEmployee extends React.Component {

  constructor(props){
    super(props);
    if(!checkPermissionComponent("ba56119d-ac43-4ec1-858e-31f2e12235e8", "update")){
      alert("Você não tem permissão para acessar essa funcionalidade!");
      props.history.goBack(); 
    }
  }

  state = {
    resetModal : false,
    createUser: false,
    EmployeeFunctions:[],
    Employee : {
      id:"",
      organizationId: getUserData('organizationId'),
      registration:"",
      place:"00000000-0000-0000-0000-000000000000",
      formation:"",
      admissionDate:"",
      functionId: "00000000-0000-0000-0000-000000000000",
    },
    Person : {
      id:"",
      name:"",
      email:"",
      cpf: "",
      rg:"",
      birthDate:"",
      telephone:"",
      mobile:"",
      cnh:"",
      cnhCategory: "",
    },
    modules:[],
    Permissions:[],    
  };
   
  
  handlePersonChange = (name, e) => {
    let {value} = e;
    let p = this.state.Person;
    p[name] = value;
    this.setState({
      Person: p
    });
  };

  handleEmployeeChange = (name, e) => {
    let {value} = e;
    let em = this.state.Employee;
    em[name] = value;
    this.setState({
      Employee: em
    });
  };

  resetPassword = async (willReset) => {

        if(willReset) {
          this.setState({loading: true});
          var request = forgotPassword({email : this.state.Person.email});
          request.then(response=>{
            if(response.data){
              toast.success("E-mail enviado com sucesso!");
              this.setState({loading: false});
            }else{
              this.setState({loading: false});
              toast.error("Seu e-mail não foi encontrado ou não há um usuário associado.");
            }
          })
        }

        this.setState({resetModal : false});

  }
  
  editEmployee = async () => {
    var person = this.state.Person;
    var employee = this.state.Employee;
    let error = false;
    if (!this.state.Person.birthDate) {
      toast.error("Erro: A data de nascimento é obrigatória");
      error=true;
    }
    if (!this.state.Employee.admissionDate) {
      toast.error("Erro: A data de admissão é obrigatória");
      error=true;
    }
    if (!this.state.Person.email) {
      toast.error("Erro: O e-mail é obrigatório");
      error=true;
    }
    if (!this.state.Employee.place) {
      toast.error("Erro: O cargo é obrigatório");
      error=true;
    }
    if (!this.state.Person.name) {
        toast.error("Erro: É necessário preencher o nome");
        error=true;
    }

    if (!this.state.Person.cpf) {
        toast.error("Erro: É necessário preencher o CPF");
        error=true;
    }

    if (!this.state.Employee.registration) {
      toast.error("Erro: É necessário preencher a Matrícula");
      error=true;
    }

    if(error){
      return;
    }

    //preparando permissões para enviar.
    var permissions = this.state.Permissions;
    var perm = [];
    for(var module in permissions){
      perm.push(permissions[module]);
    }

    const requestPermissions = await updatePermissions(perm);
    if(requestPermissions.status == 200){
      toast.success("Permissões alteradas com sucesso!");
    }else{
      toast.error("Não foi possível alterar as permissões!");
    }

    const requestEmployee = editEmployee(employee, person);
 
    requestEmployee.then(response => {
      if(response) {
          return response.data
      }
      throw new Error("Erro de requisição...");
    })
    .then(data => {        
        if (data.id != null) {
          toast.success("Funcionário alterado com sucesso!");
        } else {
          toast.error("Não foi possível alter o funcionário");
        }        
    });
  }
  componentDidMount() {
    //select de origens  
    var employeeItem = localStorage.getItem("EmployeeItem");
    var personItem = localStorage.getItem("PersonItem");

    var employee = fetchEmployeeData(employeeItem);
    
    employee.then( async response => {
      this.setState({ requestEmployee : response.data});
      var employee = this.state.Employee;
      employee.id = employeeItem;
      employee.registration = this.state.requestEmployee.registration;
      employee.place = this.state.requestEmployee.place;
      employee.admissionDate = this.state.requestEmployee.admissionDate.substring(0,10);
      employee.formation = this.state.requestEmployee.formation;
      employee.functionId = this.state.requestEmployee.functionId;
      this.setState({Employee:employee});
      var personData = fetchEmployee(response.data.personId);
      personData.then(response=>{
        this.setState({ requestPerson : response.data});
        var person = this.state.Person;
        person.id = personItem;
        person.name = this.state.requestPerson.name
        person.birthDate = this.state.requestPerson.birthDate.substring(0,10);
        person.email= this.state.requestPerson.email;
        person.cpf= this.state.requestPerson.cpf;
        person.cnh= this.state.requestPerson.cnh;
        person.cnhCategory= this.state.requestPerson.cnhCategory;
        person.mobile= this.state.requestPerson.mobile;
        person.telephone= this.state.requestPerson.telephone;
        person.rg= this.state.requestPerson.rg;
        this.setState({Person:person});
      });
      
      let employeeFunctionsRequest = fetchEmployeeFunctions(getUserData("organizationId"));
      employeeFunctionsRequest.then(response => {
        this.setState({EmployeeFunctions: response.data.map(({name, id}) => ({label: name, value:id}))});
      })
      localStorage.removeItem("DemandItem");

      const UserRequest = await getUserByEmployeeId(response.data.id);
      if(UserRequest.data.userId != "00000000-0000-0000-0000-000000000000"){
        const Modules = await fetchModules();
        const Permissions = await getPermissions(UserRequest.data.userId);

        var permissionsFormated = Permissions.data.reduce(function (acc, obj) {
          let key = obj['moduleId'];
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key] = obj;
          return acc;
        }, {});
        
        this.setState({
          modules:Modules.data,
          Permissions: permissionsFormated,
          createUser: true,
          UserId: UserRequest.data.userId,
        });
      }

    });
    
  }
  handlePermissionChange = (ModuleId, TypePermission, Value) => {

    let Permissions = this.state.Permissions;
    if(!Permissions[ModuleId]){
      Permissions[ModuleId] = {
        create:false,
        read:false,
        update:false,
        delete:false,
        id: "00000000-0000-0000-0000-000000000000",
        ModuleId: ModuleId,
        userId: this.state.UserId
      }
    }
    
    Permissions[ModuleId][TypePermission] = Value;

    if(Value === true){
      Permissions[ModuleId].read = true;
    }
      
      this.setState({Permissions: Permissions});    
  }
  handleAllPermissionChange = (ModuleId, Value) => {
    let Permissions = this.state.Permissions;
    if(!Permissions[ModuleId]){
      Permissions[ModuleId] = {
        create:false,
        read:false,
        update:false,
        delete:false,
        id: "00000000-0000-0000-0000-000000000000",
        ModuleId: ModuleId,
        userId: this.state.UserId
      }
    }
    
    Permissions[ModuleId]['read'] = Value;
    Permissions[ModuleId]['delete'] = Value;
    Permissions[ModuleId]['update'] = Value;
    Permissions[ModuleId]['create'] = Value;

    if(Value === true){
      Permissions[ModuleId].read = true;
    }
      
      this.setState({Permissions: Permissions});    
  }
  render() {
    const educationOptions = [
      {label: "Não sabe ler e escrever", value: "Analfabeto"},   
      {label: "Ensino fundamental incompleto", value: "Fundamental Incompleto"},
      {label: "Ensino fundamental completo", value: "Fundamental Completo"},
      {label: "Ensino médio incompleto", value: "Médio Incompleto"},
      {label: "Ensino médio completo", value: "Médio Completo"},
      {label: "Graduação incompleto", value: "Graduação Incompleto"},
      {label: "Graduação completo", value: "Graduação Completo"},
      {label: "Mestrado", value: "Mestrado"},
      {label: "Doutorado", value: "Doutorado"},
      {label: "Pós Doutorado", value:"Pós Doutorado"},
    ]
    return (
      <>
        <Header />

        <ToastContainer />

        <Modal size="lg" isOpen={this.state.resetModal} fade="true" toggle={() => this.resetPassword(false)}>
          <ModalHeader toggle={() => this.resetPassword(false)}>Redefinição de senha</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-9">
                <span className="h2 text-black">Tem certeza que deseja redefinir a senha desse usuário?</span>
                <br/><br/>
                <span className="h4 text-black">Um e-mail com a instrução para redefinição de senha será enviado para {this.state.Person.email}</span>
              </div>

            </div>
          </ModalBody>
          <ModalFooter>
            <div className="row" >
              <div className="col-6">
                <button onClick={() => this.resetPassword(false)} className="form-control">Cancelar</button>
              </div>
              <div className="col-6">
                <button onClick={() => this.resetPassword(true)} className="btn btn-danger">Redefinir</button>
              </div>
            </div>
          </ModalFooter>
        </Modal>

        <Container className="container-flex-employee" fluid>
          <Row className="mt-12">
            <Col className="mb-12 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        <Link to="/admin/employees"> <i className=" ni ni-bold-left bnt-voltar" /></Link>
                        Editar Funcion&aacute;rio</h3>
                    </div>
                  </Row>
                </CardHeader>

                <Container fluid>

                    <div className="row linha-form">
                      <div className="col-4 responsive-field-form-employee-medium">
                        <span className="h5 text-black"><b>Nome</b></span>
                        <input type="text" className="form-control" value={this.state.Person.name  } name="name"onChange={(e) => {this.handlePersonChange("name", e.target)}} placeholder="Nome" />
                      </div>
                      <div className="col-4 responsive-field-form-employee-medium">
                      <span className="h5 text-black"><b>Matrícula*</b></span>
                        <input type="text" className="form-control" value={this.state.Employee.registration}name="registration"onChange={(e) => {this.handleEmployeeChange("registration", e.target)}} placeholder="Matrícula" />
                      </div>
                      <div className="col-2 responsive-field-form-employee-medium">
                        <span className="h5 text-black"><b>Cargo</b></span>
                        <Select
                          styles={{
                              control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                              option: styles =>({...styles, fontSize:"0.875rem"}),
                              valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"})
                          }}
                          value={placeOptions.filter(t => this.state.Employee.place === t.value)}
                          placeholder={"Selecione"}
                          onChange={(e) => {this.handleEmployeeChange("place", e)}}
                          options={placeOptions}
                        />
                      </div>

                      <div className="col-2 responsive-field-form-employee-medium">
                        <span className="h5 text-black"><b>Função*</b></span>
                        <Select
                          styles={{
                              control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                              option: styles =>({...styles, fontSize:"0.875rem"}),
                              valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"})
                          }}
                          value={this.state.EmployeeFunctions.filter(t => this.state.Employee.functionId === t.value)}
                          placeholder={"Selecione"}
                          onChange={(e) => {this.handleEmployeeChange("functionId", e)}}
                          options={this.state.EmployeeFunctions}
                        />
                      </div>
                    </div>

                    <div className="row linha-form">
                      <div className="col-4 responsive-field-form-employee-medium">
                        <span className="h5 text-black">Formação</span>
                        <Select
                          styles={{
                              control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                              option: styles =>({...styles, fontSize:"0.875rem"}),
                              valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"})
                          }}
                          value={educationOptions.filter(t => this.state.Employee.formation === t.value)}
                          placeholder={"Selecione"}
                          onChange={(e) => {this.handleEmployeeChange("formation", e)}}
                          options={educationOptions}
                        />
                      </div>
                      <div className="col-4 responsive-field-form-employee-medium">
                        <span className="h5 text-black">RG (Sem pontuação)</span>
                        <input type="text" className="form-control"value={this.state.Person.rg} name="rg"onChange={(e) => {this.handlePersonChange("rg", e.target)}}  placeholder="RG" />
                      </div>
                      <div className="col-4 responsive-field-form-employee-full">
                        <span className="h5 text-black"><b>CPF (Sem pontuação)</b></span>
                        <input type="text" className="form-control" value={this.state.Person.cpf}name="cpf"onChange={(e) => {this.handlePersonChange("cpf", e.target)}} placeholder="CPF" />
                      </div>
                    </div>

                    <div className="row">
                      <div className="linha-form col-4 responsive-field-form-employee-full">
                        <span className="h5 text-black">Data de Admissão</span>
                        <input type="date" className="form-control" value={this.state.Employee.admissionDate} name="admissionDate" onChange={(e) => {this.handleEmployeeChange("admissionDate", e.target)}} placeholder="Data de Admissão" />
                      </div>
                      <div className="linha-form col-4 responsive-field-form-employee-full">
                        <span className="h5 text-black"><b>Data de Nascimento</b></span>
                        <input type="date" className="form-control"value={this.state.Person.birthDate} name="birthDate"onChange={(e) => {this.handlePersonChange("birthDate", e.target)}} />
                      </div>

                      <div className="linha-form col-2 responsive-field-form-employee-medium">
                        <span className="h5 text-black">CNH</span>
                        <input type="text" className="form-control"value={this.state.Person.cnh} name="cnh"onChange={(e) => {this.handlePersonChange("cnh", e.target)}} placeholder="Número da CNH" />
                      </div> 

                      <div className="linha-form col-2 responsive-field-form-employee-medium">
                        <span className="h5 text-black">Categoria da CNH</span>
                        <Select
                          styles={{
                              control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                              option: styles =>({...styles, fontSize:"0.875rem"}),
                              valueContainer: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              singleValue: styles => ({...styles, fontSize:"0.875rem", color:"#8898aa"}),
                              placeholder: styles=>({...styles, fontSize:"0.875rem", color:"#8898aa"})
                          }}
                          value={cnhCategory.filter(t => this.state.Person.cnhCategory === t.value)}
                          placeholder={"Selecione"}
                          onChange={(e) => {this.handlePersonChange("cnhCategory", e)}}
                          options={cnhCategory}
                        />
                      </div>

                    </div>

                    <div className="row">

                      <div className="linha-form col-4 responsive-field-form-employee-medium">
                        <span className="h5 text-black">Telefone</span>
                        <input type="text" className="form-control" value={this.state.Person.telephone}name="telephone"onChange={(e) => {this.handlePersonChange("telephone", e.target)}} placeholder="Telefone" />
                      </div>

                      <div className="linha-form col-4 responsive-field-form-employee-medium">
                        <span className="h5 text-black">Celular</span>
                        <input type="text" className="form-control"value={this.state.Person.mobile} name="mobile"onChange={(e) => {this.handlePersonChange("mobile", e.target)}} placeholder="Celular" />
                      </div>                                

                    
                      <div className="linha-form col-4 responsive-field-form-employee-full">
                        <span className="h5 text-black">E-mail</span>
                        <input type="text" className="form-control" value={this.state.Person.email}name="email"onChange={(e) => {this.handlePersonChange("email", e.target)}} placeholder="E-mail" />
                      </div>

                    </div> 
                    
                    {this.state.createUser ? 
                      <div className="row">
                        <div className="linha-form col-12 responsive-field-form-employee-full">
                          <span className="h5 text-black">Permissões</span>    
                          <div className="table_specification">
                          {this.state.modules.map((module) => {   
                            if(module.id == '56f7c7a2-4dbd-4dfb-8d93-59b2b38d2256'){
                              return
                            }                         
                              return (
                                <dl>
                                  <dd>{module.name}</dd>
                                  <dd><input type="checkbox" onChange={(e) => this.handleAllPermissionChange(module.id,e.target.checked)} name="" id=""/> Todos</dd>
                                  <dd><input type="checkbox" checked={this.state.Permissions[module.id] ? this.state.Permissions[module.id]['read'] :false} onChange={() => this.handlePermissionChange(module.id, 'read', this.state.Permissions[module.id] ? !this.state.Permissions[module.id]['read'] : true)} name="" id=""/> Ler</dd>
                                  <dd><input type="checkbox" checked={this.state.Permissions[module.id] ? this.state.Permissions[module.id]['create'] :false} onChange={() => this.handlePermissionChange(module.id, 'create', this.state.Permissions[module.id] ? !this.state.Permissions[module.id]['create'] : true)} name="" id=""/> Cadastrar</dd>
                                  <dd><input type="checkbox" checked={this.state.Permissions[module.id] ? this.state.Permissions[module.id]['update'] :false} onChange={() => this.handlePermissionChange(module.id, 'update', this.state.Permissions[module.id] ? !this.state.Permissions[module.id]['update'] : true)} name="" id=""/> Alterar</dd>
                                  <dd><input type="checkbox" checked={this.state.Permissions[module.id] ? this.state.Permissions[module.id]['delete'] :false} onChange={() => this.handlePermissionChange(module.id, 'delete', this.state.Permissions[module.id] ? !this.state.Permissions[module.id]['delete'] : true)} name="" id=""/> Excluir</dd>                              
                                </dl>
                              )
                          })}                  
                          </div>                                     
                        </div>
                      </div>
                    :null}

                    <div className="row">
                      <div className="linha-form col-4 responsive-field-form-employee-full">
                        <button className="btn btn-primary" onClick={this.editEmployee}><i className="fa fa-save" /> Salvar</button>
                      </div>
                    {this.state.modules.length > 0 ?
                      <div className="linha-form col-4 responsive-field-form-employee-full">
                        <button className="btn btn-danger" onClick={() => this.setState({resetModal : true})}><i className="fa fa-key" /> Redefinir senha</button>
                      </div>
                      : null}
                      
                  </div>
                </Container>

              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default EditEmployee;