import React, {useEffect, useState} from "react";
import { timeStampFormated } from "../../../../services/utils/format";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import CloropleticMap from "../../../../views/pages/Reports/CloropleticMap";
import {point as CreatePoint, points as CreatePoints, polygon as CreatePolygon, pointsWithinPolygon} from "@turf/turf"
import { withBasicListGraficMap } from "../../../../HOC/Entomologico/Map/BasicListGraficMap";

const IDOMapContainer = ({
    data= undefined, 
    labels= undefined,
    trapMapInfo, 
    previousTrapMapInfo = undefined, 
    territorializationList, 
    trapTypeName, 
    selectedSpecie, 
    buildGraficMapList
}) => {
    const [pointsGroup, setPointsGroup] = useState(null)
    const [DataSets, setDataSets] = useState("");
    const [CloropleticPolygonGroups, setCloropleticPolygonGroups] = useState(null);
    const [HeatMapPoints, setHeatMapPoints] = useState(null);
    const [eggsEqualZero, setEggsEqualZero] = useState();

    useEffect(() => {
        if(trapMapInfo && eggsEqualZero != undefined){
            checkData();
        }
    }, [trapMapInfo, territorializationList, eggsEqualZero])

    useEffect(() => {
        if(data && data.length){
            getDataSets(data,previousTrapMapInfo)
        }
    }, [data, previousTrapMapInfo])

    const getDataSets = async (data, previousTrapMapInfo) => {
        const dataset = [];
        let eggs;
        let labelDayDataSet = "";
        let positives;

        for(const index in labels){
            const dataSetObject = {
                label: labels[index]
            }

            for(let ano in data){
                if(data[ano][index]){
                    positives = data[ano][index].positive;
                    eggs = data[ano][index].ovos;

                    const ido = positives != 0 ? (eggs / positives).toFixed(1) : 0; //indice densidade de ovos;
                    
                    if(data[ano].label){
                        dataSetObject[data[ano].label] = ido;
                    }else{
                        dataSetObject["ID"] = ido;
                    }

                }
            }
            
            dataset.push(dataSetObject);   
            labelDayDataSet = dataset[index].label;
           
            if (positives == 0 && previousTrapMapInfo != undefined && index != 0){
                labelDayDataSet = dataset[index-1].label;
            }
            else if (labelDayDataSet == 53 && data[data.length - 1].length == 52){
                labelDayDataSet = dataset[index-1].label;
            }
        }
        
        setEggsEqualZero(eggs === 0)
        setDataSets(labelDayDataSet);
    }

    const checkData = async () => {
        const pointsGroupped = new Array();

        if ((trapMapInfo.positives.length == 0 || eggsEqualZero) && previousTrapMapInfo != undefined){
            trapMapInfo.positives = previousTrapMapInfo.positives;
            trapMapInfo.actives = previousTrapMapInfo.actives;
            trapMapInfo.negatives = previousTrapMapInfo.negatives;
        }

        if(trapMapInfo && trapMapInfo.actives !== undefined){
            const activeList = await buildGraficMapList(trapMapInfo.actives, "Armadilhas instaladas", "black", selectedSpecie);
            pointsGroupped.push(activeList);
        }

        if(trapMapInfo && trapMapInfo.positives !== undefined){
            const positiveList = await buildGraficMapList(trapMapInfo.positives, "Armadilhas positivas", "red", selectedSpecie);
            if(positiveList.points.length > 0){
                setHeatMapPoints(positiveList.points);
            }
            pointsGroupped.push(positiveList);
        }

        if(trapMapInfo && trapMapInfo.negatives !== undefined){
            const negativeList = await buildGraficMapList(trapMapInfo.negatives, "Armadilhas negativas", "green");
            pointsGroupped.push(negativeList);
        }

        buildCloropleticInfo();

        
        setPointsGroup(pointsGroupped);

    }

    

    const buildCloropleticInfo = () => {
        const cloropleticGroups = new Array();

        const allPositivesTraps = {
            type: "FeatureCollection",
            features: new Array()
        };
        for(const positiveTrap of trapMapInfo.positives){
            allPositivesTraps.features.push(CreatePoint([positiveTrap.longitude, positiveTrap.latitude], {eggs: positiveTrap.eggs}))
        }

        for(const group of territorializationList){

            var range = [99.99, 199.99];

            const cloropleticGroup = {
                name: group.name,
                Polygons: new Array()
            };
            let legends = [];
            for(const territorialization of group.territorializationList){
                if(territorialization.geometryType == "POLYGON"){
                    
                    const turfPolygon = CreatePolygon([territorialization.coordinates.map(({x, y}) => [x, y])])
                    const filteredPositivesTraps = pointsWithinPolygon(allPositivesTraps, turfPolygon).features

                    if(filteredPositivesTraps.length > 0){
                        const eggsSum = filteredPositivesTraps.reduce((acumulador, valorAtual) => {
                            return acumulador + valorAtual.properties.eggs
                        }, 0)

                        const ido =  eggsSum / filteredPositivesTraps.length;

                        switch (trapTypeName) {
                            case "Ovitrampa":
                                range = [99.99, 199.99];
                                break;
                        
                            case "Adultrap":
                                range = [1, 3];
                                break;

                            default:
                                range = [19.99, 59.99];
                                break;
                        }

                        if(ido <= range[0]){
                            turfPolygon.color = "green";
                            
                        }else if( ido <= range[1]){
                            turfPolygon.color = "yellow";
                            
                        }else{
                            turfPolygon.color = "red";
                            
                        }
                    }else{
                        turfPolygon.color = "transparent";
                    }

                    cloropleticGroup.Polygons.push(turfPolygon)
                }
            }

            legends.push({
                firstItem: 4,
                lastItem: null,
                color: "red",
                text: range[1] + "+"
            });

            legends.push({
                firstItem: 1,
                lastItem: 3.99,
                color: "yellow",
                text: range[0] + " - " + range[1]
            });

            legends.push({
                firstItem: 0.0,
                lastItem: 0.99,
                color: "green",
                text: `0 - ` + range[0]
            });

            cloropleticGroup.legends = legends;

            cloropleticGroups.push(cloropleticGroup)
        }


        setCloropleticPolygonGroups(cloropleticGroups)
    }
    
    return(
        <CloropleticMap
            PointsGroup={pointsGroup}
            PolygonsGroup = {CloropleticPolygonGroups}
            HeatMap
            QuantityLegend={
                <div>
                    Exibindo Dados da <br />
                    Semana {typeof(DataSets) === "string" ? DataSets.replace("Semana", "") : DataSets.toString().replace("Semana", "")}
                </div>
            }
            showCloropletic
            PointsHeatMap={HeatMapPoints}
        />
    );
}

export default withBasicListGraficMap(IDOMapContainer);