import React from "react";
import { Link } from "react-router-dom";
import "./../../assets/css/navbar.css";
import CsvDownloadButton from 'react-json-to-csv'
import { fetchPrograms } from "../../services/api/Program";
// reactstrap components
import {
    Container,
} from "reactstrap";
import { getPermissionsToken } from "../../services/utils/auth";
import "./FiltroEquipes.css";

class FiltroEquipes extends React.Component {
    state ={
        programs: [],
        filterName:"",
        filterProgram:"",
        filterStatus:"",
        filterEmployee:"",
        filterPriority:"",
        loadingCsv: false,
    }

    handleChange = e => {
        let {name,value} = e.target;
        this.setState({
            [name]: value
        });
      };

      componentDidMount() {
        //select de programas
        var requestPrograms = fetchPrograms();
        requestPrograms.then(response => {
            this.setState({programs: response.data});
        })
        
    }

    formatDate = (dataString) => {
        const dataObjeto = new Date(dataString);

        const ano = dataObjeto.getFullYear();
        const mes = (dataObjeto.getMonth() + 1).toString().padStart(2, '0');
        const dia = (dataObjeto.getDate() + 1).toString().padStart(2, '0');

        const dataFormatada = `${dia}/${mes}/${ano}`;

        return dataFormatada;
    }

    formatTeams = teams => {
        var obj = [];

        teams.map((team) => {
            var coordenador = team.members ? team.members.find(obj => obj.isCoordinator === true) : null;
            var supervisor = team.members ? team.members.find(obj => obj.isSuperviser === true) : null;
            var encarregado = team.members ? team.members.find(obj => obj.isComissioner === true) : null;
            var executores = team.members ? team.members.find(obj => obj.isExecutor === true) : null;
            
            obj.push({
                identificador: team.id,
                nome: team.name,
                "Data Criação": this.formatDate(team.createAt.substring(0, 10)),
                programa:  team.program ? team.program.name : "",
                coordenador:  coordenador ? coordenador.name : "",
                supervisor: supervisor ? supervisor.name : "",
                encarregado:  encarregado ? encarregado.name : "",
                executores:  executores ? executores.name : "",
                "Descrição da equipe": team.program ?team.program.description : ""
            })    
        })

        return obj;
    }

    filterProgram= Team =>{
        var test = false;
        
        var test2 = Team.program.id.indexOf(this.state.filterProgram) !== -1;
        if(test2 !== test && !test){
            test = !test;
        }
        
        return test;
      }
    render() {
        let filteredTeam2 = this.props.Teams;
        let filteredTeam = filteredTeam2.filter(
            (Team)=>{
                return Team.name.toLowerCase().indexOf(
                    this.state.filterName.toLowerCase()) !== -1 &&
                    this.filterProgram(Team);
            }
        );
        const userPermissions = getPermissionsToken();
        return (
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" >
                <Container fluid>
                    <div className="header-body">

                        <div className="row linha-form">
                            <div className="col-4 responsive-field-form-equipe-full">
                                <input type="text" name="filterName" onChange={this.handleChange} className="form-control" placeholder="Nome ou descrição" />
                            </div>
                            <div className="col-4 responsive-field-form-equipe-full">
                                <select onChange={this.handleChange}name={"filterProgram"} className="form-control">
                                    <option value={""}>Todos os programas</option>
                                    {this.state.programs.map((program) => {
                                        return (
                                            <option value={program.id}>{program.name}</option>
                                        )
                                    })} 
                                </select>
                            </div>
                        </div>                    
                        <div className="row">
                            <div className="col-4 responsive-field-form-equipe-medium">
                                <button  onClick={()=> this.props.parentCallback(filteredTeam)} className="btn btn-primary">Pesquisar</button>
                            </div>
                            {userPermissions["217c876b-5f50-4bbf-bce4-325b83ea7787"] && userPermissions["217c876b-5f50-4bbf-bce4-325b83ea7787"].create ? 
                                <div className="col-4 responsive-field-form-equipe-medium">
                                    <Link to="AddTeam">
                                        <button className="float-right btn btn-default">Nova equipe</button>
                                    </Link>
                                </div>
                            :null}
                            <div className="col-4 responsive-field-form-full">
                                {this.state.loadingCsv
                                ?
                                <button className="float-right btn btn-default">Carregando...</button>
                                :
                                <CsvDownloadButton className="btn btn-primary" data={this.formatTeams(this.props.Teams)} filename={'equipes_arquivo'}> <i className="fa fa-download" /> Baixar CSV de Equipes</CsvDownloadButton>
                                }
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );

    }

}

export default FiltroEquipes;