import React, {useState, useEffect, useRef} from "react";
import { Bar, BarChart, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, LabelList } from "recharts";
import { useCurrentPng } from "recharts-to-png";
import CardPresentational from "../../Presentational/Sinan/CardPresentational";

const ImoveisSISPNCDBar  = ({data}) => {
    const [chartDataSets, setChartDataSets] = useState([{}]);
    const textCardHeader = useRef('Nº de imóveis inspecionados por tipo');
    const [getpng, {ref}] = useCurrentPng();

    useEffect(() => {
        if(data){
            buildDataSets(data);
        }
    }, [data])

    async function buildDataSets (data){
        const tipoImoveis = [
            {label: "Residência", field: "residencia"},
            {label: "Comércio", field: "comercio"},
            {label: "TB", field: "tb"},
            {label: "PE", field: "pe"},
            {label: "Outros", field: "outros"}
    ]
        
        const dataset = [];

        for(let tipo of tipoImoveis){
            let dataObject = {
                label: tipo.label,
                Quantidade: data[tipo.field] ? parseInt(data[tipo.field]) : 0
            };  
            
        dataset.push(dataObject);
        }

        
        setChartDataSets(dataset)
    }

    const style={
        cardHeaderText: {
            color:"black",
            fontWeight:"bold",
            fontSize:"0.97rem"
        },
    }

    const renderCustomizedLabel = (props) => {
        const { x, y, width, value } = props;
        const radius = 10;
      
        return (
            <text x={x + width / 2} y={y - radius} fill="#000" textAnchor="middle" dominantBaseline="middle">
              {value}
            </text>
        );
      };

    const buildChartElement = () => (
        <ResponsiveContainer  width="100%" height="100%">
            <BarChart
                data={chartDataSets}
                barSize={60}
                ref={ref}
                margin={{
                    top:30,
                    right:15
                }}
            >
                <text x={"50%"} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
                    <tspan fontSize="16" fontWeight={"bolder"}>Imóveis Inspecionados </tspan>
                </text>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="category" dataKey="label" style={{fontSize:14}} />
                <YAxis style={{fontSize:14}} allowDecimals={false} tickCount={101} domain={[0, 100]} />
                <Tooltip/>
                <Bar dataKey={"Quantidade"}>
                    <LabelList dataKey={"Quantidade"} content={renderCustomizedLabel}/>
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
    return(
        <CardPresentational
            CardId = {"ImoveisSISPNCDContainer"}
            style={style}
            textCardHeader={textCardHeader.current}
            element={buildChartElement()}
            getpng = {getpng}
        />
    );
}


export default ImoveisSISPNCDBar;