import { toast } from "react-toastify";
import { getInventoryItens, getInventoryPlaces, getStocables } from "../api/Itens";

export default async function checkQuantity(movement, inventoryPlacesList = null, stocableList = null, inventoryItens = null) {
    if(!inventoryItens && !stocableList && !inventoryPlacesList){
        const requestInventoryPlaces = await getInventoryPlaces();
        inventoryPlacesList = requestInventoryPlaces.data;

        const requestStocables = await getStocables();
        stocableList = requestStocables.data;

        
        const requestInventory = await getInventoryItens();
        inventoryItens = requestInventory.data;
    }


    let place = null; 
    let id = movement.PlaceId ? movement.PlaceId : movement.placeId;
  
    if (movement.Returned == 1 || movement.returned == true) {
        id = movement.OriginPlaceId ? movement.OriginPlaceId : movement.originPlaceId;
    } 

    inventoryPlacesList.forEach((inventoryPlace) => {
        if (inventoryPlace.id == id) {
            place = inventoryPlace.name;
        }
    });

    const stocableObj = {};

    var arrayStocables = movement.Stocables ? movement.Stocables : movement.stocables;

    for (const stocable of arrayStocables) {
          let stocableName = null;
          for (const stocableItem of stocableList) {
            var stocableId = stocable.StocableId ? stocable.StocableId : stocable.stocableId;              
            if (stocableItem.id == stocableId) {
                stocableName = stocableItem.name;
                break;
            }
          }
          
          const key = `${stocable.Lot ? stocable.Lot : stocable.lot}-${stocableName}`;
          var quantity = stocable.Quantity ? stocable.Quantity : stocable.quantity;
          if (stocableObj[key]) {
              stocableObj[key] += quantity;
          } else {
              stocableObj[key] = quantity;
          }
    }

    for (const stocable of arrayStocables) {
        let hasMatch = false;
        const stocableInList = stocableList.find(stocableItem => stocableItem.id === stocable.StocableId || stocableItem.id === stocable.stocableId);
        if (!stocableInList) {
            toast.error(`Erro: É necessário preencher o valor do estocável.`);
            return false;
        }
        const stocableName = stocableInList.name;
        let stocableLot = stocable.Lot != null ? stocable.Lot : stocable.lot;

        if(stocableLot == "") stocableLot = "Nenhum";


        for (const item of inventoryItens) {
            if (item.place === place && item.name === stocableName && item.lot === stocableLot) {
                hasMatch = true;
                break;
            }
        }
        
        if (!hasMatch) {
            toast.error(`Erro: "${stocableName}" com lote "${stocableLot}" não tem correspondência nos itens do inventário.`);
            return false;
        }
    }

    for (const item of inventoryItens) {
        if (item.place == place) {
            const key = `${item.lot}-${item.name}`;
            if (item.quantity < stocableObj[key]) {
                toast.error(`Erro: Não há ${stocableObj[key]} ${item.name} em ${place}.`);
                return false;
            }
        }
    }

    return true;
}