import React from "react";
// nodejs library to set properties for components
import {putPassword} from "../../../services/api/login.js"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from "react-router-dom";


// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col
} from "reactstrap";

class ChangePassword extends React.Component {
  state={
    email:"",
    codVerification:"",
    newPassword:"",
    confirmPassword:"",
    redirect: null
  }
  
  keyPress =(e)=>{
    if(e.key=="Enter"){
     this.changePassword();
    }
  }
  onChange = (e)=>{
    let {name,value} = e.target;
    this.setState({[name]:value});
  }
  changePassword =()=> {
    let error = false;
    if (this.state.confirmPassword != this.state.newPassword) {
      toast.error("Erro: As senhas não conferem");
      error=true;
  }

    if (error == true) {
      return;
    }
    var changeRequest = putPassword(this.state);
    changeRequest.then(response => {
        toast.success("Senha alterada com sucesso!",{
          onClose: () => this.setState({ redirect: "/auth/login" })
        });
    })
    
    
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">

          <ToastContainer autoClose={1500}/>

            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>
                  <img alt="" className="navbar-brand-img"
                    src="/static/media/vitec.9bd71d52.png" width="200" />
                </small>
              </div>
              <Form role="form"onKeyDown={(e)=>this.keyPress}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="E-mail"  onKeyDown={(e)=>this.keyPress}  onChange={e => this.onChange(e)} name ="email" type="text" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Código de verificação" onKeyDown={this.keyPress}  onChange={e => this.onChange(e)} name ="codVerification"type="text" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Nova senha" onKeyDown={this.keyPress}  onChange={e => this.onChange(e)} name ="newPassword"type="password" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Confirmar senha" onKeyDown={this.keyPress}  onChange={e => this.onChange(e)} name ="confirmPassword"type="password" />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" onClick={this.changePassword} color="primary" type="button">
                    Alterar senha
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default ChangePassword;
