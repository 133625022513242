import React from "react";

import ComposedChartGraphic from "../../../../../../components/Graphics/Composed/ComposedChartGraphic";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Bar, Line } from "recharts";

const GoalByUsersModal = ({
  isOpen,
  toggle,
  selectedDay,
  goalsByUsersData,
}) => {
  return (
    <Modal size="lg" style={{ maxWidth: "1300px" }} fade isOpen={isOpen} toggle={() => toggle()}>
      <ModalHeader></ModalHeader>
      <ModalBody>
        <ComposedChartGraphic
          barsAndLines={[
            <Bar
              dataKey="Realizado - Armadilhas Instaladas (%)"
              fill="#82CA9D"
            />,
            <Bar dataKey="Realizado - Visitas (%)" fill="#9370DB" />,
            <Line type="" dataKey="Meta" stroke="#FF0000" />,
          ]}
          customTooltip={<CustomTooltip />}
          data={
            goalsByUsersData && goalsByUsersData[selectedDay]
              ? goalsByUsersData[selectedDay]
              : []
          }
          headerText={`Metas por funcionário - Dia ${selectedDay}`}
          xAxisDataKey="firstName"
          yAxisTickFormatter="%"
        />
      </ModalBody>
      <ModalFooter>
        <div className="row">
          <div className="col-6" style={{ maxWidth: "100%" }}>
            <button
              className="form-control"
              onClick={() => toggle()}
              style={{ cursor: "pointer" }}
            >
              FECHAR
            </button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p
          className="label"
          style={{ fontWeight: "bold" }}
        >{`Nome: ${data["fullName"]}`}</p>
        <p
          style={{ color: "#82CA9D" }}
        >{`Meta - Armadilhas Instaladas: ${data["Meta - Armadilhas Instaladas"]}`}</p>
        <p
          style={{ color: "#82CA9D" }}
        >{`Realizado: ${data["Realizado - Armadilhas Instaladas"]}`}</p>
        <p
          style={{ color: "#82CA9D" }}
        >{`Conclusão: ${data["Realizado - Armadilhas Instaladas (%)"]}%`}</p>
        <p
          style={{ color: "#9370DB" }}
        >{`Meta - Visitas: ${data["Meta - Visitas"]}`}</p>
        <p
          style={{ color: "#9370DB" }}
        >{`Realizado: ${data["Realizado - Visitas"]}`}</p>
        <p
          style={{ color: "#9370DB" }}
        >{`Conclusão: ${data["Realizado - Visitas (%)"]}%`}</p>
      </div>
    );
  }

  return null;
};

export default GoalByUsersModal;
