import { getUserData } from '../utils/auth';

import request from '../utils/request';
//import request from '../utils/requestTests/requestTestOrganization';

export function fetchUsers() {
   return request({
     url: '/Users',
     method: 'get',
  });
}

export function getUser(userId){
  return request({
    url: '/Users/'+userId,
    method: 'get',
  })
}

export function getPermissions(userId){
  return request({
    url:'/Users/Permissions/'+userId,
    method:'get',
  })
}

export function getUserByEmployeeId(employeeId){
  return request({
    url:'Users/GetByEmployee/'+employeeId,
    method:'get',
  })
}

export function getUserEmployeeDataByOrganization(){
  const organizationId = getUserData('organizationId');
  return request({
    url:'Users/UsersEmployee/'+organizationId,
    method:'get',
  })
}

export function getOrganization(organizationId){
  return request({
    url:'/Organizations/'+ organizationId,
    method:'get',
  })
}

export function updatePermissions(permissions){
  return request({
    url:'Users/Permissions',
    method:'put',
    data: permissions,
  })
}

export function fetchLoginHistoriesByFilters(filters) {
  return request({
    url: "/Users/LoginHistory/FilteredSearch",
    method: "post",
    data: filters,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}