import React from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Row,
} from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";
import ReactTooltip from "react-tooltip";
import DataTable from "react-data-table-component";
import { toPng } from "html-to-image";
import { toast } from "react-toastify";
import moment from "moment";

const LoginReportPageTable = ({ headerText, data }) => {
  const [generatedAt, setGeneratedAt] = React.useState("");
  const [dataToExport, setDataToExport] = React.useState([]);

  const contentToPrintRef = React.useRef(null);

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const splitedActualDate = moment().format("DD/MM/YYYY HH:mm").split(" ");

    setGeneratedAt(`${splitedActualDate[0]} às ${splitedActualDate[1]}`);

    const formatedDataToExport = data.map((loginHistory) => {
      return {
        USUARIO: loginHistory.user.person.name,
        DATA_DE_ACESSO: moment(loginHistory.createAt)
          .subtract(3, "hours")
          .format("DD/MM/YYYY HH:mm"),
        LOGADO_PELO_APP: !loginHistory.authenticatedOnApp ? "Não" : "Sim",
        LATITUDE: loginHistory.latitude,
        LONGITUDE: loginHistory.longitude,
        IP:
          !loginHistory.ip ||
          loginHistory.ip === "" ||
          loginHistory.ip === "000.00.0.0"
            ? "Não encontrado"
            : loginHistory.ip,
        PROVEDOR:
          !loginHistory.provider || loginHistory.provider === ""
            ? "Não encontrado"
            : loginHistory.provider,
        HOSTNAME:
          !loginHistory.hostname || loginHistory.hostname === ""
            ? "Não encontrado"
            : loginHistory.hostname,
      };
    });

    setDataToExport(formatedDataToExport);
  }, [data]);

  const handleDownloadComponentImage = async () => {
    try {
      if (contentToPrintRef.current === null) return;

      const imageToDownloadString = await toPng(contentToPrintRef.current, {
        quality: 1,
        pixelRatio: 2,
      });

      if (!imageToDownloadString || imageToDownloadString === "")
        throw new Error("Ocorreu um erro ao gerar a imagem do gráfico.");

      const link = document.createElement("a");
      link.download = `${headerText}GeneratedAt${generatedAt}.png`;
      link.href = imageToDownloadString;
      link.click();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  const tableColumns = [
    {
      name: "Usuário",
      selector: (row) =>
        row.user.person ? row.user.person.name : "Não encontrado",
      sortable: true,
      grow: 2,
    },
    {
      name: "Data de Acesso",
      selector: (row) =>
        moment(row.createAt).subtract(3, "hours").format("DD/MM/YYYY HH:mm"),
      sortable: true,
    },
    {
      name: "Logado pelo App",
      selector: (row) => (row.authenticatedOnApp ? "Sim" : "Não"),
      sortable: true,
    },
    {
      name: "Latitude",
      selector: (row) => (row.latitude ? row.latitude : "Não encontrado"),
      sortable: true,
    },
    {
      name: "Longitude",
      selector: (row) => (row.longitude ? row.longitude : "Não encontrado"),
      sortable: true,
    },
    {
      name: "IP",
      selector: (row) =>
        !row.ip || row.ip === "" || row.ip === "000.00.0.0"
          ? "Não encontrado"
          : row.ip,
      sortable: true,
    },
    {
      name: "Provedor",
      selector: (row) =>
        !row.provider || row.provider === "" ? "Não encontrado" : row.provider,
      sortable: true,
    },
    {
      name: "Hostname",
      selector: (row) =>
        !row.hostname || row.hostname === "" ? "Não encontrado" : row.hostname,
      sortable: true,
    },
  ];

  const style = {
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    cardBody: {
      padding: "1rem",
    },
    cardHeaderText: {
      color: "black",
      fontWeight: "bold",
      fontSize: "0.97rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };

  return (
    <>
      <div ref={contentToPrintRef}>
        <Row className="mt-12 mb-12" style={{ paddingBottom: "1.5rem" }}>
          <Col className="mb-12 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader>
                <CardText className="mb-0 mt-0" style={style.cardHeaderText}>
                  <span>
                    <span>{headerText}</span>
                  </span>
                  <span
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: "13px", fontWeight: "normal" }}>
                      Gerado em {generatedAt}
                    </span>
                    <span>
                      <CsvDownloadButton
                        className="btn btn-primary"
                        data={dataToExport}
                        filename={`${headerText.replaceAll(
                          " ",
                          ""
                        )}${generatedAt}`}
                        style={{ width: "55px", marginRight: "0px" }}
                        data-tip
                        data-for="dataDownload"
                      >
                        <i className="fa fa-download" />
                      </CsvDownloadButton>
                      <ReactTooltip
                        effect="solid"
                        type="info"
                        id="dataDownload"
                      >
                        Baixar dados
                      </ReactTooltip>
                    </span>
                    <span>
                      <Button
                        onClick={() => handleDownloadComponentImage()}
                        color="primary"
                        data-tip
                        data-for="viewDownload"
                        style={{ width: "55px", marginRight: "0px" }}
                      >
                        <i className={"fa fa-image"} />
                      </Button>
                      <ReactTooltip
                        effect="solid"
                        type="info"
                        id="viewDownload"
                      >
                        Baixar visualização
                      </ReactTooltip>
                    </span>
                  </span>
                </CardText>
              </CardHeader>
              <CardBody style={style.cardBody}>
                <Row>
                  <Col>
                    <DataTable
                      noHeader
                      defaultSortField="name"
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                      responsive
                      columns={tableColumns}
                      data={data}
                      noDataComponent={"Nenhum registro encontrado."}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LoginReportPageTable;
