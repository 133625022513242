import React from "react";

import { Container } from "reactstrap";
import { Tabs, TabList, TabPanel } from "react-tabs";
import { getUserEmployeeDataByOrganization } from "../../../services/api/Users";
import { fetchDemands } from "../../../services/api/Demand";
import { getTeam } from "../../../services/api/Team";
import { fetchTerritorializations, getAllTypeTerritorializations, getTypeLayer } from "../../../services/api/territorialization";

import "../Dashboards/MainDashboard.css"
import "react-tabs/style/react-tabs.css";
import "../../../assets/css/navbar.css"
import "../../../assets/css/style.css";
import GlobalVisionPage from "./GlobalVisionPage";
import { toast } from "react-toastify";

const GlobalVisionPageWrapper = () => {
  const [demandsList, setDemandsList] = React.useState([]);
  const [employeesList, setEmployeesList] = React.useState([]);
  const [teamsList, setTeamsList] = React.useState([]);
  const [territorializationsList, setTerritorializationsList] = React.useState(
    []
  );

  React.useEffect(() => {
    fetchAllComponentData();
  }, []);

  const fetchAllBiovecTerritorializations = async () => {
    try {
      const typeLayersPromises = BIOVEC_ORGANIZATIONS_TYPE_LAYERS.map(async ({ typeLayerId, typeLayerName }) => {
        const { data } = await fetchTerritorializations(typeLayerId);

        return data;
      });

      const promises = await Promise.all(typeLayersPromises);

      const formatedTerritorializations = [];

      promises.forEach((territorialization, index) => {
        const typeLayerName = BIOVEC_ORGANIZATIONS_TYPE_LAYERS[index].typeLayerName;

        const formatedTerritorialization = {
          name: typeLayerName,
          label: typeLayerName,
          territorializationList: territorialization,
          options: territorialization.map(({ name, id }) => {
            return {
              label: name,
              value: id,
            };
          }),
        };

        formatedTerritorializations.push(formatedTerritorialization);
      });

      setTerritorializationsList(formatedTerritorializations);

    } catch (error) {
      console.error(error);
      toast.error("Ocorreu um erro ao buscar pelas camadas dos municípios. Verifique sua conexão com a internet e tente novamente. Se o erro persistir, entre em contato com nossa equipe.");
    }
  }

  const fetchDemandsData = async () => {
    try {
      const { data, status } = await fetchDemands();
      if (status !== 200)
        throw new Error("Ocorreu um erro ao buscar pelos dado de Demandas");

      const formatedDemands = data.map(({ id, serviceOrder }) => {
        return {
          label: serviceOrder,
          value: id,
        };
      });

      setDemandsList([
        {
          value: "00000000-0000-0000-0000-000000000000",
          label: "Selecione",
        },
        ...formatedDemands,
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEmployeesData = async () => {
    try {
      const { data, status } = await getUserEmployeeDataByOrganization();
      if (status !== 200)
        throw new Error("Ocorreu um erro ao buscar pela lista de funcionários");

      const formatedEmployees = data.map(
        ({ userId, employeeId, employeeName, uvis }) => {
          return {
            employeeId,
            employeeName,
            userId,
            label: employeeName,
            value: userId,
            uvis,
          };
        }
      );

      const employeesList = [
        {
          value: "00000000-0000-0000-0000-000000000000",
          label: "Selecione",
        },
        ...formatedEmployees,
      ];

      setEmployeesList(employeesList);

      return employeesList;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTeamsData = async (employeesData) => {
    try {
      const { data, status } = await getTeam();
      if (status !== 200)
        throw new Error("Ocorreu um erro ao buscar pela lista de funcionários");

      const formatedTeams = data.map(({ id, members, name }) => {
        const formatedTeamMembers = members.map((member) => {
          const userData = employeesData.find(
            (employee) => employee.employeeId === member.employeeId
          );

          return {
            ...member,
            userId: userData
              ? userData.userId
              : "00000000-0000-0000-0000-000000000000",
          };
        });

        return {
          value: id,
          label: name,
          members: formatedTeamMembers,
        };
      });

      setTeamsList([
        {
          value: "00000000-0000-0000-0000-000000000000",
          label: "Selecione",
        },
        ...formatedTeams,
      ]);

      return formatedTeams;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllEmployeesAndTeamsData = async () => {
    const employeesData = await fetchEmployeesData();
    const teamsData = await fetchTeamsData(employeesData);

    return [employeesData, teamsData];
  };

  const fetchAllComponentData = async () => {
    try {
      const demandsData = fetchDemandsData();
      const employeesAndTeamsData = fetchAllEmployeesAndTeamsData();
      const territorializationsData = fetchAllBiovecTerritorializations();

      await Promise.all([
        demandsData,
        employeesAndTeamsData,
        territorializationsData,
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const BIOVEC_ORGANIZATIONS_TYPE_LAYERS = [
    {
      typeLayerId: "c1ba061c-7594-45d5-9c72-845927981bb1",
      typeLayerName: "Cotia - Município",
    },
    {
      typeLayerId: "442eb24f-9f9a-4daf-95f8-b9608b478c89",
      typeLayerName: "Dracena - Município",
    },
    {
      typeLayerId: "c92c4831-7690-4fb3-8e7b-b22c01510898",
      typeLayerName: "Dracena - Área de Intervenção",
    },
    {
      typeLayerId: "7957c130-de07-45d3-adaa-85acaa7b8892",
      typeLayerName: "Goiânia - Município",
    },
    {
      typeLayerId: "2009176e-ee88-4093-a505-c82ac4e371d5",
      typeLayerName: "Goiânia - Área supressão de Aedes",
    },
    {
      typeLayerId: "fe6d07f0-f654-4484-9d32-486aef4f4bdd",
      typeLayerName: "Ribeirão Pires - Município",
    },
    {
      typeLayerId: "1967a1a0-95fd-4bf1-9a73-a166e97c6762",
      typeLayerName: "São Paulo - Município"
    },    
    {
      typeLayerId: "82ecc92b-d7d3-4ea7-ac07-29bfaa756c6b",
      typeLayerName: "São Paulo - Áreas Selecionadas Etapa 1 Ca",
    },
    {
      typeLayerId: "2e684cb4-87e7-42c2-bed5-89a8546794b3",
      typeLayerName: "São Paulo - Áreas Selecionadas Etapa 1 Co",
    },
    {
      typeLayerId: "fd70d5b1-37e9-4bc5-a246-aa189ff48efc",
      typeLayerName: "São Paulo - Áreas Selecionadas Etapa 2",
    },
  ];

  return (
    <Tabs>
      <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8">
        <TabList className="tab-container"></TabList>
      </Container>
      <div className="mt--7">
        <TabPanel>
          {employeesList && employeesList.length !== 0 && (
            <GlobalVisionPage
              demandsList={demandsList}
              teamsList={teamsList}
              employeesList={employeesList}
              territorializationsList={territorializationsList}
            />
          )}
        </TabPanel>
      </div>
    </Tabs>
  );
};

export default GlobalVisionPageWrapper;
