import React, { Component } from "react";
import CardPresentational from "../../../Presentational/CardPresentational";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";

class ArmadilhasPositivasContainer extends Component{

    state = {
        positiveNumber:0,
        textCard:"Total de armadilhas positivas",
    }

    componentDidMount(){
        const {data} = this.props;
        if(data !== undefined && data.length > 0){
            this.buildDataToCard();
        }
    }

    buildDataToCard(){
        const {data, trapTypeName} = this.props;
        const textCard = `Total de ${ARMADILHA_INFO[trapTypeName].apelido} positiva`;

        let positiveNumber = 0;

        for(const j in data){
            for(const i in data[j]){
                if(i !== "label"){
                    positiveNumber += data[j][i].positive;
                }
            }
        }

        this.setState({positiveNumber, textCard});
    }

    componentDidUpdate(prevProps){
        const {data} = this.props; 
        if(data !== undefined && data !== prevProps.data){
            this.buildDataToCard();
        }
    }

    render(){
        const {positiveNumber, textCard} = this.state
        return(
            <CardPresentational 
                number={positiveNumber}
                text={textCard}
            />
        )
    }
}

export default ArmadilhasPositivasContainer