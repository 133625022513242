import React from 'react'
import {
  CircleMarker,
  Map,
  Popup,
  TileLayer,
  FeatureGroup,
  LayersControl,
  withLeaflet,
  Marker,
  Polygon
} from'react-leaflet';
import FullscreenControl from 'react-leaflet-fullscreen';
import PrintControlDefault  from 'react-leaflet-easyprint';
import 'react-leaflet-fullscreen/dist/styles.css';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { timeStampFormated,timeStampFormatedMongo } from "../../../../services/utils/format";
import HeatmapLayer from 'react-leaflet-heatmap-layer';
const key = 'AIzaSyCdkHjfiiztTeKSSuvfauQERlAJGFONeKo';
const { BaseLayer, Overlay } = LayersControl;
const PrintControl = withLeaflet(PrintControlDefault);


const MyPopupMarker = ({ content, position, ReadDate}) => {

  var dataAtual = new Date();
  var date = new Date(ReadDate);
  var diff = Math.abs(dataAtual.getTime() - date.getTime());
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  if (days > 100) {
    var displayIcon = RedIn2CareIcon;
  } else if (days > 10 && days < 100) {
    var displayIcon = YellowIn2CareIcon;
  } else {
    var displayIcon = GreenIn2CareIcon;
  }  

  return <Marker icon={displayIcon} position={position} title={content}>
  <Popup>{content} - Leitura em {date.toLocaleDateString('pt-BR', options)}</Popup>
</Marker>
}  


const L = require('leaflet');

const BasicTrap = L.icon({
  iconUrl: require('../Traps/TrapIcons/Int2care/int2care1.png'),
  iconSize: [38,38],
  iconAnchor: [22,38],
  popupAnchor: [-3, -30],
  shadowUrl: null,
  shadowSize: [50, 64],
  shadowAnchor: [4, 62]
});

const GreenIn2CareIcon = L.icon({
  iconUrl: require('../Traps/TrapIcons/Int2care/int2care4.png'),
  iconSize: [38,95],
  iconAnchor: [22,94],
  popupAnchor: [-3, -76],
  shadowUrl: require('../Traps/TrapIcons/sombra.png'),
  shadowSize: [50, 64],
  shadowAnchor: [4, 62]
});

const RedIn2CareIcon = L.icon({
  iconUrl: require('../Traps/TrapIcons/Int2care/int2care7.png'),
  iconSize: [38,95],
  iconAnchor: [22,94],
  popupAnchor: [-3, -76],
  shadowUrl: require('../Traps/TrapIcons/sombra.png'),
  shadowSize: [50, 64],
  shadowAnchor: [4, 62]
});

const YellowIn2CareIcon = L.icon({
  iconUrl: require('../Traps/TrapIcons/Int2care/int2care6.png'),
  iconSize: [38,95],
  iconAnchor: [22,94],
  popupAnchor: [-3, -76],
  shadowUrl: require('../Traps/TrapIcons/sombra.png'),
  shadowSize: [50, 64],
  shadowAnchor: [4, 62]
});

const WhiteIn2CareIcon = L.icon({
  iconUrl: require('../Traps/TrapIcons/Int2care/int2care3.png'),
  iconSize: [38,95],
  iconAnchor: [22,94],
  popupAnchor: [-3, -76],
  shadowUrl: require('../Traps/TrapIcons/sombra.png'),
  shadowSize: [50, 64],
  shadowAnchor: [4, 62]
});

const VisitMarker = L.icon({
  iconUrl: require('../Traps/TrapIcons/visita.png'),
  iconSize: [38,95],
  iconAnchor: [22,94],
  popupAnchor: [-3, -76],
  shadowUrl: require('../Traps/TrapIcons/sombra.png'),
  shadowSize: [50, 64],
  shadowAnchor: [4, 62]
});

const ResidentMarker = L.icon({
  iconUrl: require('../Traps/MiscIcons/residentIcon.png'),
  iconSize: [38,95],
  iconAnchor: [22,94],
  popupAnchor: [-3, -76],
  shadowUrl: require('../Traps/TrapIcons/sombra.png'),
  shadowSize: [50, 64],
  shadowAnchor: [4, 62]
});

const MyMarkersList = ({ markers }) => {
  const items = markers.map(({ key, ...props }) => (
    <MyPopupMarker key={key} {...props} />
  ))
  return <React.Fragment>{items}</React.Fragment>
}

export default class TrapMap extends React.Component{
  state  = {
      isLoading:true,
      traps:[],
      identifications: [],
      trapsSamplesResults: [],
      trapsType:[],
      reads:[]
    }

  constructor(props){
    super(props);
    var traps = [];
    var reads = [];
    props.traps.map((trap) =>{
      traps.push({
        position: [trap.latitude,trap.longitude],
        content:trap.trapTypeName+"\r\t",
        key: trap.id,
        dateInstalled:timeStampFormated(trap.dateInstalled)
      })
    });
    props.trapHistory.map(history=>{
      traps.map(trap=>{
        if(history.trapId == trap.key && history.trapWasRead){
          trap['ReadDate'] = history.statusDate;
          reads.push(trap);
        }
      });
    });
    this.state = {
      reads:reads,
      traps:traps,
      identifications:props.identifications,
      trapsSamplesResults:props.trapsSamplesResults,
      trapsType: props.trapsType,
    }
    
  }        

  componentDidUpdate(prevProps){
    if(prevProps.traps !== this.props.traps){
      let reads = [];
      let traps = [];
      this.props.traps.map((trap) =>{
        traps.push({
          position: [trap.latitude,trap.longitude],
          content:trap.trapTypeName+"\r\t",
          dateInstalled:timeStampFormated(trap.dateInstalled),
          key: trap.id,
        })
      });
      this.props.trapHistory.map(history=>{
        traps.map(trap=>{
          if(history.trapId == trap.key && history.trapWasRead){
            reads.push(trap);
          }
        });
      });
      this.setState({
        traps: traps, 
        identifications:this.props.identifications,
        trapsType: this.props.trapsType, 
        trapsSamplesResults:this.props.trapsSamplesResults,
      });

    }
  }

  filterByIdentification = (identification) => {
    
    let filteredTrapSampleResults = this.state.trapsSamplesResults.filter((result) => {
      return (result.identification == identification && result.result);
    })

    let filteredTraps = this.state.traps.filter((trap) => {
        return filteredTrapSampleResults.some(result => result.trapId == trap.key);
    });

    return filteredTraps;
  }

  filterByTrapType = (type) => {
    
    let propsTraps = this.props.traps.filter((result) => {
      return (result.trapTypeId == type);
    })

    let filteredTraps = this.state.traps.filter((trap) => {
        return propsTraps.some(result => result.id == trap.key);
    });

    return filteredTraps;
  }

  render() {
    var count = 0;
    return (
      <div id="mapid">
          <Map style={{'height' : '52vh'}}  center={[-22.848046, -43.321953]} zoom={13}>
          <FullscreenControl 
            position="topleft"
            title="Tela Cheia"
            titleCancel="Sair da Tela Cheia"
          />
          <LayersControl position="topright">
            <BaseLayer checked name="Normal">
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <BaseLayer name="Preto e Branco">
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <BaseLayer name="Satélite">
                <ReactLeafletGoogleLayer googleMapsLoaderConf={{KEY: key}} type={'hybrid'} />
            </BaseLayer>
            
              <Overlay checked name="Todas as Armadilhas" >
                <FeatureGroup  color="black">
                  {this.state.traps.map((trap)=>{

                      return(
                        <Marker icon={BasicTrap} key={trap.key} position={trap.position}>
                          <Popup>
                           {trap.content}<br />{trap.dateInstalled}
                          </Popup>
                        </Marker>
                      )
                  })}
                </FeatureGroup>
              </Overlay>
              <Overlay name={"Armadilhas por leitura"}>
              <FeatureGroup>
                  <MyMarkersList markers={this.state.reads} />
              </FeatureGroup>
            </Overlay>
            <Overlay name="Residentes">
                <FeatureGroup >
                  
                  {this.props.residents.map((resident)=>{
                      count++;
                      return(
                        <Marker icon={ResidentMarker} key={resident.data.residente} position={resident.position}>
                          <Popup>
                          {timeStampFormatedMongo(resident.date)} <br /> {resident.data.dadosPrincipais.name}
                          </Popup>
                      </Marker>
                      )
                  })}
                </FeatureGroup>                
              </Overlay>
              <Overlay name="Visitas">
                <FeatureGroup >
                  
                  {this.props.visits.map((visit)=>{
                      count++;
                      return(
                        <Marker icon={VisitMarker} key={visit.date+count} position={visit.position}>
                          <Popup>
                          {timeStampFormated(visit.date)} <br /> {visit.situation}
                          </Popup>
                      </Marker>
                      )
                  })}
                </FeatureGroup>                
              </Overlay>
              <Overlay name="Visitas (Calor)">
                      
                      <HeatmapLayer
                        points={this.props.visitsHeat}
                        longitudeExtractor={m => m[1]}
                        latitudeExtractor={m => m[0]}
                        intensityExtractor={m => parseFloat(m[2])}
                      />
                  
                     </Overlay>              
            </LayersControl>
{/*
            <Overlay name="Todas as Armadilhas">
              <FeatureGroup color="black">
                <MyMarkersList markers={this.state.traps} />
              </FeatureGroup>
            </Overlay>
            <Overlay name={"Armadilhas lidas"}>
              <FeatureGroup color={'yellow'}>
                  <MyMarkersList markers={this.state.reads} />
              </FeatureGroup>
            </Overlay>

            {this.state.identifications.map((identification) => {
              return(
                <Overlay name={"Armadilha com presença de " + identification.name}>
                  <FeatureGroup color={'red'}>
                      <MyMarkersList markers={this.filterByIdentification(identification.name)} />
                  </FeatureGroup>
                </Overlay>
              )
            })}

            {this.state.trapsType.map((trapType) => {
              return(
                <Overlay name={trapType.name}>
                  <FeatureGroup color={'red'}>
                      <MyMarkersList markers={this.filterByTrapType(trapType.id)} />
                  </FeatureGroup>
                </Overlay>
              )
            })*/}
            <LayersControl position="topright">
            {this.props.territorializations!=null?this.props.territorializations.map((territorialization) => {
              if(territorialization.name!="Imóvel"){
                
                return(
                  <Overlay name={territorialization.name}>
                    <FeatureGroup fillOpacity={0.1} color={'#03a1fc'}>
                      {territorialization.territorializationList.map(terr=>{
                        if(terr.geo!=null){
                          let coordinates = [];
                          terr.geo.coordinates.forEach((coordinate) => {
                              coordinates.push([coordinate.y, coordinate.x]);
                          });
                      
                          return(
                            <Polygon color="black" positions={coordinates} />
                          )
                        }
                          
                      })}
                    </FeatureGroup>
                  </Overlay>
                )
              }
            }):""}
            
          </LayersControl>
          <PrintControl position="topleft" sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={false} title="Export as PNG" exportOnly />

        </Map>
      </div>
    );
  }
}
