import React from "react";
import LoadingSpin from "react-loading-spin";

const FooterFormButtons = ({
  handleRepeatAddress,
  handleAddNewAddress,
  handleSubmit,
  isSubmitingForm,
}) => {
  return (
    <>
      <button
        className="btn_form"
        style={{ cursor: "pointer" }}
        onClick={() => handleRepeatAddress()}
      >
        <i className="fa fa-redo" style={{ marginRight: ".5rem" }}></i>
        Repetir Logradouro (CTRL + R)
      </button>
      <button
        className="btn_form"
        style={{ cursor: "pointer" }}
        onClick={() => handleAddNewAddress()}
      >
        <i className="fa fa-plus" style={{ marginRight: ".5rem" }}></i>
        Adicionar Novo Logradouro (CTRL + A)
      </button>
      {isSubmitingForm ? (
        <button
          className="btn_form"
          style={{ opacity: "0.5", width: "88px" }}
          disabled={true}
        >
          <LoadingSpin primaryColor="#fff" size={18} />
        </button>
      ) : (
        <button
          className="btn_form"
          style={{ cursor: "pointer" }}
          onClick={() => handleSubmit()}
        >
          <i className="fa fa-save" style={{ marginRight: ".5rem" }}></i>
          Salvar
        </button>
      )}
    </>
  );
};

export default FooterFormButtons;
